import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  bigint: any
  float8: any
  json: any
  jsonb: any
  learning_can_retry_type: any
  learning_cme_type: any
  learning_learner_event: any
  learning_module_event: any
  learning_module_sort_order: any
  learning_module_status: any
  learning_pass_mark_type: any
  learning_question_scoring_method: any
  numeric: any
  timestamptz: any
  uuid: any
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>
  _gt?: InputMaybe<Scalars['Boolean']>
  _gte?: InputMaybe<Scalars['Boolean']>
  _in?: InputMaybe<Array<Scalars['Boolean']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Boolean']>
  _lte?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<Scalars['Boolean']>
  _nin?: InputMaybe<Array<Scalars['Boolean']>>
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>
  _gt?: InputMaybe<Scalars['Int']>
  _gte?: InputMaybe<Scalars['Int']>
  _in?: InputMaybe<Array<Scalars['Int']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Int']>
  _lte?: InputMaybe<Scalars['Int']>
  _neq?: InputMaybe<Scalars['Int']>
  _nin?: InputMaybe<Array<Scalars['Int']>>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>
  _gt?: InputMaybe<Scalars['bigint']>
  _gte?: InputMaybe<Scalars['bigint']>
  _in?: InputMaybe<Array<Scalars['bigint']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['bigint']>
  _lte?: InputMaybe<Scalars['bigint']>
  _neq?: InputMaybe<Scalars['bigint']>
  _nin?: InputMaybe<Array<Scalars['bigint']>>
}

/** columns and relationships of "categories" */
export type Categories = {
  __typename?: 'categories'
  created_at: Scalars['timestamptz']
  domain: Scalars['String']
  id: Scalars['Int']
  name: Scalars['String']
  /** An array relationship */
  page_categories: Array<Page_Categories>
  /** An aggregate relationship */
  page_categories_aggregate: Page_Categories_Aggregate
  parent: Scalars['Int']
  /** An array relationship */
  posts: Array<Post_Categories>
  /** An aggregate relationship */
  posts_aggregate: Post_Categories_Aggregate
  slug: Scalars['String']
}

/** columns and relationships of "categories" */
export type CategoriesPage_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Page_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Page_Categories_Order_By>>
  where?: InputMaybe<Page_Categories_Bool_Exp>
}

/** columns and relationships of "categories" */
export type CategoriesPage_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Page_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Page_Categories_Order_By>>
  where?: InputMaybe<Page_Categories_Bool_Exp>
}

/** columns and relationships of "categories" */
export type CategoriesPostsArgs = {
  distinct_on?: InputMaybe<Array<Post_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Categories_Order_By>>
  where?: InputMaybe<Post_Categories_Bool_Exp>
}

/** columns and relationships of "categories" */
export type CategoriesPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Categories_Order_By>>
  where?: InputMaybe<Post_Categories_Bool_Exp>
}

/** aggregated selection of "categories" */
export type Categories_Aggregate = {
  __typename?: 'categories_aggregate'
  aggregate?: Maybe<Categories_Aggregate_Fields>
  nodes: Array<Categories>
}

/** aggregate fields of "categories" */
export type Categories_Aggregate_Fields = {
  __typename?: 'categories_aggregate_fields'
  avg?: Maybe<Categories_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Categories_Max_Fields>
  min?: Maybe<Categories_Min_Fields>
  stddev?: Maybe<Categories_Stddev_Fields>
  stddev_pop?: Maybe<Categories_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Categories_Stddev_Samp_Fields>
  sum?: Maybe<Categories_Sum_Fields>
  var_pop?: Maybe<Categories_Var_Pop_Fields>
  var_samp?: Maybe<Categories_Var_Samp_Fields>
  variance?: Maybe<Categories_Variance_Fields>
}

/** aggregate fields of "categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Categories_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Categories_Avg_Fields = {
  __typename?: 'categories_avg_fields'
  id?: Maybe<Scalars['Float']>
  parent?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Categories_Bool_Exp>>
  _not?: InputMaybe<Categories_Bool_Exp>
  _or?: InputMaybe<Array<Categories_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  domain?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Int_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  page_categories?: InputMaybe<Page_Categories_Bool_Exp>
  page_categories_aggregate?: InputMaybe<Page_Categories_Aggregate_Bool_Exp>
  parent?: InputMaybe<Int_Comparison_Exp>
  posts?: InputMaybe<Post_Categories_Bool_Exp>
  posts_aggregate?: InputMaybe<Post_Categories_Aggregate_Bool_Exp>
  slug?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  CategoriesPkey = 'categories_pkey',
}

/** input type for incrementing numeric columns in table "categories" */
export type Categories_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>
  parent?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  domain?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  page_categories?: InputMaybe<Page_Categories_Arr_Rel_Insert_Input>
  parent?: InputMaybe<Scalars['Int']>
  posts?: InputMaybe<Post_Categories_Arr_Rel_Insert_Input>
  slug?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Categories_Max_Fields = {
  __typename?: 'categories_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  domain?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  parent?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Categories_Min_Fields = {
  __typename?: 'categories_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  domain?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  parent?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  __typename?: 'categories_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Categories>
}

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Categories_On_Conflict>
}

/** on_conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint
  update_columns?: Array<Categories_Update_Column>
  where?: InputMaybe<Categories_Bool_Exp>
}

/** Ordering options when selecting data from "categories". */
export type Categories_Order_By = {
  created_at?: InputMaybe<Order_By>
  domain?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  page_categories_aggregate?: InputMaybe<Page_Categories_Aggregate_Order_By>
  parent?: InputMaybe<Order_By>
  posts_aggregate?: InputMaybe<Post_Categories_Aggregate_Order_By>
  slug?: InputMaybe<Order_By>
}

/** primary key columns input for table: categories */
export type Categories_Pk_Columns_Input = {
  id: Scalars['Int']
}

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Parent = 'parent',
  /** column name */
  Slug = 'slug',
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  domain?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  parent?: InputMaybe<Scalars['Int']>
  slug?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Categories_Stddev_Fields = {
  __typename?: 'categories_stddev_fields'
  id?: Maybe<Scalars['Float']>
  parent?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Categories_Stddev_Pop_Fields = {
  __typename?: 'categories_stddev_pop_fields'
  id?: Maybe<Scalars['Float']>
  parent?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Categories_Stddev_Samp_Fields = {
  __typename?: 'categories_stddev_samp_fields'
  id?: Maybe<Scalars['Float']>
  parent?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "categories" */
export type Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Categories_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Categories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  domain?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  parent?: InputMaybe<Scalars['Int']>
  slug?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Categories_Sum_Fields = {
  __typename?: 'categories_sum_fields'
  id?: Maybe<Scalars['Int']>
  parent?: Maybe<Scalars['Int']>
}

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Parent = 'parent',
  /** column name */
  Slug = 'slug',
}

export type Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Categories_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Categories_Set_Input>
  /** filter the rows which have to be updated */
  where: Categories_Bool_Exp
}

/** aggregate var_pop on columns */
export type Categories_Var_Pop_Fields = {
  __typename?: 'categories_var_pop_fields'
  id?: Maybe<Scalars['Float']>
  parent?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Categories_Var_Samp_Fields = {
  __typename?: 'categories_var_samp_fields'
  id?: Maybe<Scalars['Float']>
  parent?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Categories_Variance_Fields = {
  __typename?: 'categories_variance_fields'
  id?: Maybe<Scalars['Float']>
  parent?: Maybe<Scalars['Float']>
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>
  _gt?: InputMaybe<Scalars['float8']>
  _gte?: InputMaybe<Scalars['float8']>
  _in?: InputMaybe<Array<Scalars['float8']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['float8']>
  _lte?: InputMaybe<Scalars['float8']>
  _neq?: InputMaybe<Scalars['float8']>
  _nin?: InputMaybe<Array<Scalars['float8']>>
}

/** columns and relationships of "form_responses" */
export type Form_Responses = {
  __typename?: 'form_responses'
  /** An object relationship */
  actor?: Maybe<Users>
  actor_id: Scalars['Int']
  created_at: Scalars['timestamptz']
  /** An object relationship */
  form?: Maybe<Forms>
  form_id: Scalars['uuid']
  id: Scalars['uuid']
  response: Scalars['jsonb']
  /** An object relationship */
  session?: Maybe<Learning_Learner_Sessions>
  session_id?: Maybe<Scalars['uuid']>
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "form_responses" */
export type Form_ResponsesResponseArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "form_responses" */
export type Form_Responses_Aggregate = {
  __typename?: 'form_responses_aggregate'
  aggregate?: Maybe<Form_Responses_Aggregate_Fields>
  nodes: Array<Form_Responses>
}

export type Form_Responses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Form_Responses_Aggregate_Bool_Exp_Count>
}

export type Form_Responses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Form_Responses_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Form_Responses_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "form_responses" */
export type Form_Responses_Aggregate_Fields = {
  __typename?: 'form_responses_aggregate_fields'
  avg?: Maybe<Form_Responses_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Form_Responses_Max_Fields>
  min?: Maybe<Form_Responses_Min_Fields>
  stddev?: Maybe<Form_Responses_Stddev_Fields>
  stddev_pop?: Maybe<Form_Responses_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Form_Responses_Stddev_Samp_Fields>
  sum?: Maybe<Form_Responses_Sum_Fields>
  var_pop?: Maybe<Form_Responses_Var_Pop_Fields>
  var_samp?: Maybe<Form_Responses_Var_Samp_Fields>
  variance?: Maybe<Form_Responses_Variance_Fields>
}

/** aggregate fields of "form_responses" */
export type Form_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Form_Responses_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "form_responses" */
export type Form_Responses_Aggregate_Order_By = {
  avg?: InputMaybe<Form_Responses_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Form_Responses_Max_Order_By>
  min?: InputMaybe<Form_Responses_Min_Order_By>
  stddev?: InputMaybe<Form_Responses_Stddev_Order_By>
  stddev_pop?: InputMaybe<Form_Responses_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Form_Responses_Stddev_Samp_Order_By>
  sum?: InputMaybe<Form_Responses_Sum_Order_By>
  var_pop?: InputMaybe<Form_Responses_Var_Pop_Order_By>
  var_samp?: InputMaybe<Form_Responses_Var_Samp_Order_By>
  variance?: InputMaybe<Form_Responses_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Form_Responses_Append_Input = {
  response?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "form_responses" */
export type Form_Responses_Arr_Rel_Insert_Input = {
  data: Array<Form_Responses_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Form_Responses_On_Conflict>
}

/** aggregate avg on columns */
export type Form_Responses_Avg_Fields = {
  __typename?: 'form_responses_avg_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "form_responses" */
export type Form_Responses_Avg_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "form_responses". All fields are combined with a logical 'AND'. */
export type Form_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Form_Responses_Bool_Exp>>
  _not?: InputMaybe<Form_Responses_Bool_Exp>
  _or?: InputMaybe<Array<Form_Responses_Bool_Exp>>
  actor?: InputMaybe<Users_Bool_Exp>
  actor_id?: InputMaybe<Int_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  form?: InputMaybe<Forms_Bool_Exp>
  form_id?: InputMaybe<Uuid_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  response?: InputMaybe<Jsonb_Comparison_Exp>
  session?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
  session_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "form_responses" */
export enum Form_Responses_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormResponsesPkey = 'form_responses_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Form_Responses_Delete_At_Path_Input = {
  response?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Form_Responses_Delete_Elem_Input = {
  response?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Form_Responses_Delete_Key_Input = {
  response?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "form_responses" */
export type Form_Responses_Inc_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "form_responses" */
export type Form_Responses_Insert_Input = {
  actor?: InputMaybe<Users_Obj_Rel_Insert_Input>
  actor_id?: InputMaybe<Scalars['Int']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>
  form_id?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  response?: InputMaybe<Scalars['jsonb']>
  session?: InputMaybe<Learning_Learner_Sessions_Obj_Rel_Insert_Input>
  session_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Form_Responses_Max_Fields = {
  __typename?: 'form_responses_max_fields'
  actor_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  form_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  session_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "form_responses" */
export type Form_Responses_Max_Order_By = {
  actor_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  form_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  session_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Form_Responses_Min_Fields = {
  __typename?: 'form_responses_min_fields'
  actor_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  form_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  session_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "form_responses" */
export type Form_Responses_Min_Order_By = {
  actor_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  form_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  session_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "form_responses" */
export type Form_Responses_Mutation_Response = {
  __typename?: 'form_responses_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Form_Responses>
}

/** on_conflict condition type for table "form_responses" */
export type Form_Responses_On_Conflict = {
  constraint: Form_Responses_Constraint
  update_columns?: Array<Form_Responses_Update_Column>
  where?: InputMaybe<Form_Responses_Bool_Exp>
}

/** Ordering options when selecting data from "form_responses". */
export type Form_Responses_Order_By = {
  actor?: InputMaybe<Users_Order_By>
  actor_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  form?: InputMaybe<Forms_Order_By>
  form_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  response?: InputMaybe<Order_By>
  session?: InputMaybe<Learning_Learner_Sessions_Order_By>
  session_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: form_responses */
export type Form_Responses_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Form_Responses_Prepend_Input = {
  response?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "form_responses" */
export enum Form_Responses_Select_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Response = 'response',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "form_responses" */
export type Form_Responses_Set_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  form_id?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  response?: InputMaybe<Scalars['jsonb']>
  session_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Form_Responses_Stddev_Fields = {
  __typename?: 'form_responses_stddev_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "form_responses" */
export type Form_Responses_Stddev_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Form_Responses_Stddev_Pop_Fields = {
  __typename?: 'form_responses_stddev_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "form_responses" */
export type Form_Responses_Stddev_Pop_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Form_Responses_Stddev_Samp_Fields = {
  __typename?: 'form_responses_stddev_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "form_responses" */
export type Form_Responses_Stddev_Samp_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "form_responses" */
export type Form_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Form_Responses_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Form_Responses_Stream_Cursor_Value_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  form_id?: InputMaybe<Scalars['uuid']>
  id?: InputMaybe<Scalars['uuid']>
  response?: InputMaybe<Scalars['jsonb']>
  session_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Form_Responses_Sum_Fields = {
  __typename?: 'form_responses_sum_fields'
  actor_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "form_responses" */
export type Form_Responses_Sum_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** update columns of table "form_responses" */
export enum Form_Responses_Update_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  Response = 'response',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Form_Responses_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Form_Responses_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Form_Responses_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Form_Responses_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Form_Responses_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Form_Responses_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Form_Responses_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Form_Responses_Set_Input>
  /** filter the rows which have to be updated */
  where: Form_Responses_Bool_Exp
}

/** aggregate var_pop on columns */
export type Form_Responses_Var_Pop_Fields = {
  __typename?: 'form_responses_var_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "form_responses" */
export type Form_Responses_Var_Pop_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Form_Responses_Var_Samp_Fields = {
  __typename?: 'form_responses_var_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "form_responses" */
export type Form_Responses_Var_Samp_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Form_Responses_Variance_Fields = {
  __typename?: 'form_responses_variance_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "form_responses" */
export type Form_Responses_Variance_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** columns and relationships of "forms" */
export type Forms = {
  __typename?: 'forms'
  allow_multiple_submissions: Scalars['Boolean']
  created_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  creator: Users
  creator_id: Scalars['Int']
  description?: Maybe<Scalars['String']>
  fields: Scalars['jsonb']
  /** An object relationship */
  group?: Maybe<Groups>
  group_id?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  is_feedback_form: Scalars['Boolean']
  name: Scalars['String']
  /** An array relationship */
  responses: Array<Form_Responses>
  /** An aggregate relationship */
  responses_aggregate: Form_Responses_Aggregate
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "forms" */
export type FormsFieldsArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "forms" */
export type FormsResponsesArgs = {
  distinct_on?: InputMaybe<Array<Form_Responses_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Form_Responses_Order_By>>
  where?: InputMaybe<Form_Responses_Bool_Exp>
}

/** columns and relationships of "forms" */
export type FormsResponses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Responses_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Form_Responses_Order_By>>
  where?: InputMaybe<Form_Responses_Bool_Exp>
}

/** aggregated selection of "forms" */
export type Forms_Aggregate = {
  __typename?: 'forms_aggregate'
  aggregate?: Maybe<Forms_Aggregate_Fields>
  nodes: Array<Forms>
}

/** aggregate fields of "forms" */
export type Forms_Aggregate_Fields = {
  __typename?: 'forms_aggregate_fields'
  avg?: Maybe<Forms_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Forms_Max_Fields>
  min?: Maybe<Forms_Min_Fields>
  stddev?: Maybe<Forms_Stddev_Fields>
  stddev_pop?: Maybe<Forms_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Forms_Stddev_Samp_Fields>
  sum?: Maybe<Forms_Sum_Fields>
  var_pop?: Maybe<Forms_Var_Pop_Fields>
  var_samp?: Maybe<Forms_Var_Samp_Fields>
  variance?: Maybe<Forms_Variance_Fields>
}

/** aggregate fields of "forms" */
export type Forms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Forms_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Forms_Append_Input = {
  fields?: InputMaybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Forms_Avg_Fields = {
  __typename?: 'forms_avg_fields'
  creator_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "forms". All fields are combined with a logical 'AND'. */
export type Forms_Bool_Exp = {
  _and?: InputMaybe<Array<Forms_Bool_Exp>>
  _not?: InputMaybe<Forms_Bool_Exp>
  _or?: InputMaybe<Array<Forms_Bool_Exp>>
  allow_multiple_submissions?: InputMaybe<Boolean_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  creator?: InputMaybe<Users_Bool_Exp>
  creator_id?: InputMaybe<Int_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  fields?: InputMaybe<Jsonb_Comparison_Exp>
  group?: InputMaybe<Groups_Bool_Exp>
  group_id?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  is_feedback_form?: InputMaybe<Boolean_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  responses?: InputMaybe<Form_Responses_Bool_Exp>
  responses_aggregate?: InputMaybe<Form_Responses_Aggregate_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "forms" */
export enum Forms_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormsPkey = 'forms_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Forms_Delete_At_Path_Input = {
  fields?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Forms_Delete_Elem_Input = {
  fields?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Forms_Delete_Key_Input = {
  fields?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "forms" */
export type Forms_Inc_Input = {
  creator_id?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "forms" */
export type Forms_Insert_Input = {
  allow_multiple_submissions?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>
  creator_id?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  fields?: InputMaybe<Scalars['jsonb']>
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>
  group_id?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  is_feedback_form?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  responses?: InputMaybe<Form_Responses_Arr_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Forms_Max_Fields = {
  __typename?: 'forms_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  group_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Forms_Min_Fields = {
  __typename?: 'forms_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  group_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "forms" */
export type Forms_Mutation_Response = {
  __typename?: 'forms_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Forms>
}

/** input type for inserting object relation for remote table "forms" */
export type Forms_Obj_Rel_Insert_Input = {
  data: Forms_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Forms_On_Conflict>
}

/** on_conflict condition type for table "forms" */
export type Forms_On_Conflict = {
  constraint: Forms_Constraint
  update_columns?: Array<Forms_Update_Column>
  where?: InputMaybe<Forms_Bool_Exp>
}

/** Ordering options when selecting data from "forms". */
export type Forms_Order_By = {
  allow_multiple_submissions?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  creator?: InputMaybe<Users_Order_By>
  creator_id?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  fields?: InputMaybe<Order_By>
  group?: InputMaybe<Groups_Order_By>
  group_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  is_feedback_form?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  responses_aggregate?: InputMaybe<Form_Responses_Aggregate_Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: forms */
export type Forms_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Forms_Prepend_Input = {
  fields?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "forms" */
export enum Forms_Select_Column {
  /** column name */
  AllowMultipleSubmissions = 'allow_multiple_submissions',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  Fields = 'fields',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsFeedbackForm = 'is_feedback_form',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "forms" */
export type Forms_Set_Input = {
  allow_multiple_submissions?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  creator_id?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  fields?: InputMaybe<Scalars['jsonb']>
  group_id?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  is_feedback_form?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Forms_Stddev_Fields = {
  __typename?: 'forms_stddev_fields'
  creator_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Forms_Stddev_Pop_Fields = {
  __typename?: 'forms_stddev_pop_fields'
  creator_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Forms_Stddev_Samp_Fields = {
  __typename?: 'forms_stddev_samp_fields'
  creator_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "forms" */
export type Forms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Forms_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Forms_Stream_Cursor_Value_Input = {
  allow_multiple_submissions?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  creator_id?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  fields?: InputMaybe<Scalars['jsonb']>
  group_id?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  is_feedback_form?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Forms_Sum_Fields = {
  __typename?: 'forms_sum_fields'
  creator_id?: Maybe<Scalars['Int']>
}

/** update columns of table "forms" */
export enum Forms_Update_Column {
  /** column name */
  AllowMultipleSubmissions = 'allow_multiple_submissions',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Description = 'description',
  /** column name */
  Fields = 'fields',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsFeedbackForm = 'is_feedback_form',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Forms_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Forms_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Forms_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Forms_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Forms_Set_Input>
  /** filter the rows which have to be updated */
  where: Forms_Bool_Exp
}

/** aggregate var_pop on columns */
export type Forms_Var_Pop_Fields = {
  __typename?: 'forms_var_pop_fields'
  creator_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Forms_Var_Samp_Fields = {
  __typename?: 'forms_var_samp_fields'
  creator_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Forms_Variance_Fields = {
  __typename?: 'forms_variance_fields'
  creator_id?: Maybe<Scalars['Float']>
}

/** columns and relationships of "groups" */
export type Groups = {
  __typename?: 'groups'
  access: Scalars['String']
  background_image?: Maybe<Scalars['jsonb']>
  categories?: Maybe<Scalars['jsonb']>
  created_at: Scalars['timestamptz']
  description: Scalars['String']
  id: Scalars['String']
  kind?: Maybe<Scalars['String']>
  learning_enabled: Scalars['Boolean']
  learning_subscriptions_enabled: Scalars['Boolean']
  logo?: Maybe<Scalars['jsonb']>
  /** An array relationship */
  members: Array<User_Group_Members>
  /** An aggregate relationship */
  members_aggregate: User_Group_Members_Aggregate
  pages_enabled: Scalars['Boolean']
  /** An array relationship */
  series: Array<Learning_Series>
  /** An aggregate relationship */
  series_aggregate: Learning_Series_Aggregate
  slug: Scalars['String']
  title: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "groups" */
export type GroupsBackground_ImageArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "groups" */
export type GroupsCategoriesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "groups" */
export type GroupsLogoArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "groups" */
export type GroupsMembersArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** columns and relationships of "groups" */
export type GroupsMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** columns and relationships of "groups" */
export type GroupsSeriesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Order_By>>
  where?: InputMaybe<Learning_Series_Bool_Exp>
}

/** columns and relationships of "groups" */
export type GroupsSeries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Order_By>>
  where?: InputMaybe<Learning_Series_Bool_Exp>
}

/** aggregated selection of "groups" */
export type Groups_Aggregate = {
  __typename?: 'groups_aggregate'
  aggregate?: Maybe<Groups_Aggregate_Fields>
  nodes: Array<Groups>
}

/** aggregate fields of "groups" */
export type Groups_Aggregate_Fields = {
  __typename?: 'groups_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Groups_Max_Fields>
  min?: Maybe<Groups_Min_Fields>
}

/** aggregate fields of "groups" */
export type Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Groups_Append_Input = {
  background_image?: InputMaybe<Scalars['jsonb']>
  categories?: InputMaybe<Scalars['jsonb']>
  logo?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "groups". All fields are combined with a logical 'AND'. */
export type Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Bool_Exp>>
  _not?: InputMaybe<Groups_Bool_Exp>
  _or?: InputMaybe<Array<Groups_Bool_Exp>>
  access?: InputMaybe<String_Comparison_Exp>
  background_image?: InputMaybe<Jsonb_Comparison_Exp>
  categories?: InputMaybe<Jsonb_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<String_Comparison_Exp>
  kind?: InputMaybe<String_Comparison_Exp>
  learning_enabled?: InputMaybe<Boolean_Comparison_Exp>
  learning_subscriptions_enabled?: InputMaybe<Boolean_Comparison_Exp>
  logo?: InputMaybe<Jsonb_Comparison_Exp>
  members?: InputMaybe<User_Group_Members_Bool_Exp>
  members_aggregate?: InputMaybe<User_Group_Members_Aggregate_Bool_Exp>
  pages_enabled?: InputMaybe<Boolean_Comparison_Exp>
  series?: InputMaybe<Learning_Series_Bool_Exp>
  series_aggregate?: InputMaybe<Learning_Series_Aggregate_Bool_Exp>
  slug?: InputMaybe<String_Comparison_Exp>
  title?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "groups" */
export enum Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Groups_Delete_At_Path_Input = {
  background_image?: InputMaybe<Array<Scalars['String']>>
  categories?: InputMaybe<Array<Scalars['String']>>
  logo?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Groups_Delete_Elem_Input = {
  background_image?: InputMaybe<Scalars['Int']>
  categories?: InputMaybe<Scalars['Int']>
  logo?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Groups_Delete_Key_Input = {
  background_image?: InputMaybe<Scalars['String']>
  categories?: InputMaybe<Scalars['String']>
  logo?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "groups" */
export type Groups_Insert_Input = {
  access?: InputMaybe<Scalars['String']>
  background_image?: InputMaybe<Scalars['jsonb']>
  categories?: InputMaybe<Scalars['jsonb']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  kind?: InputMaybe<Scalars['String']>
  learning_enabled?: InputMaybe<Scalars['Boolean']>
  learning_subscriptions_enabled?: InputMaybe<Scalars['Boolean']>
  logo?: InputMaybe<Scalars['jsonb']>
  members?: InputMaybe<User_Group_Members_Arr_Rel_Insert_Input>
  pages_enabled?: InputMaybe<Scalars['Boolean']>
  series?: InputMaybe<Learning_Series_Arr_Rel_Insert_Input>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Groups_Max_Fields = {
  __typename?: 'groups_max_fields'
  access?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  kind?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Groups_Min_Fields = {
  __typename?: 'groups_min_fields'
  access?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  kind?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "groups" */
export type Groups_Mutation_Response = {
  __typename?: 'groups_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Groups>
}

/** input type for inserting object relation for remote table "groups" */
export type Groups_Obj_Rel_Insert_Input = {
  data: Groups_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>
}

/** on_conflict condition type for table "groups" */
export type Groups_On_Conflict = {
  constraint: Groups_Constraint
  update_columns?: Array<Groups_Update_Column>
  where?: InputMaybe<Groups_Bool_Exp>
}

/** Ordering options when selecting data from "groups". */
export type Groups_Order_By = {
  access?: InputMaybe<Order_By>
  background_image?: InputMaybe<Order_By>
  categories?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  kind?: InputMaybe<Order_By>
  learning_enabled?: InputMaybe<Order_By>
  learning_subscriptions_enabled?: InputMaybe<Order_By>
  logo?: InputMaybe<Order_By>
  members_aggregate?: InputMaybe<User_Group_Members_Aggregate_Order_By>
  pages_enabled?: InputMaybe<Order_By>
  series_aggregate?: InputMaybe<Learning_Series_Aggregate_Order_By>
  slug?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: groups */
export type Groups_Pk_Columns_Input = {
  id: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Groups_Prepend_Input = {
  background_image?: InputMaybe<Scalars['jsonb']>
  categories?: InputMaybe<Scalars['jsonb']>
  logo?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "groups" */
export enum Groups_Select_Column {
  /** column name */
  Access = 'access',
  /** column name */
  BackgroundImage = 'background_image',
  /** column name */
  Categories = 'categories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  LearningEnabled = 'learning_enabled',
  /** column name */
  LearningSubscriptionsEnabled = 'learning_subscriptions_enabled',
  /** column name */
  Logo = 'logo',
  /** column name */
  PagesEnabled = 'pages_enabled',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "groups" */
export type Groups_Set_Input = {
  access?: InputMaybe<Scalars['String']>
  background_image?: InputMaybe<Scalars['jsonb']>
  categories?: InputMaybe<Scalars['jsonb']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  kind?: InputMaybe<Scalars['String']>
  learning_enabled?: InputMaybe<Scalars['Boolean']>
  learning_subscriptions_enabled?: InputMaybe<Scalars['Boolean']>
  logo?: InputMaybe<Scalars['jsonb']>
  pages_enabled?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "groups" */
export type Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Groups_Stream_Cursor_Value_Input = {
  access?: InputMaybe<Scalars['String']>
  background_image?: InputMaybe<Scalars['jsonb']>
  categories?: InputMaybe<Scalars['jsonb']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['String']>
  kind?: InputMaybe<Scalars['String']>
  learning_enabled?: InputMaybe<Scalars['Boolean']>
  learning_subscriptions_enabled?: InputMaybe<Scalars['Boolean']>
  logo?: InputMaybe<Scalars['jsonb']>
  pages_enabled?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** update columns of table "groups" */
export enum Groups_Update_Column {
  /** column name */
  Access = 'access',
  /** column name */
  BackgroundImage = 'background_image',
  /** column name */
  Categories = 'categories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  LearningEnabled = 'learning_enabled',
  /** column name */
  LearningSubscriptionsEnabled = 'learning_subscriptions_enabled',
  /** column name */
  Logo = 'logo',
  /** column name */
  PagesEnabled = 'pages_enabled',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Groups_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Groups_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Groups_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Groups_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Groups_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Groups_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Set_Input>
  /** filter the rows which have to be updated */
  where: Groups_Bool_Exp
}

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>
  _gt?: InputMaybe<Scalars['json']>
  _gte?: InputMaybe<Scalars['json']>
  _in?: InputMaybe<Array<Scalars['json']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['json']>
  _lte?: InputMaybe<Scalars['json']>
  _neq?: InputMaybe<Scalars['json']>
  _nin?: InputMaybe<Array<Scalars['json']>>
}

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>
  _eq?: InputMaybe<Scalars['jsonb']>
  _gt?: InputMaybe<Scalars['jsonb']>
  _gte?: InputMaybe<Scalars['jsonb']>
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>
  _in?: InputMaybe<Array<Scalars['jsonb']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['jsonb']>
  _lte?: InputMaybe<Scalars['jsonb']>
  _neq?: InputMaybe<Scalars['jsonb']>
  _nin?: InputMaybe<Array<Scalars['jsonb']>>
}

/** Boolean expression to compare columns of type "learning_can_retry_type". All fields are combined with logical 'AND'. */
export type Learning_Can_Retry_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_can_retry_type']>
  _gt?: InputMaybe<Scalars['learning_can_retry_type']>
  _gte?: InputMaybe<Scalars['learning_can_retry_type']>
  _in?: InputMaybe<Array<Scalars['learning_can_retry_type']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['learning_can_retry_type']>
  _lte?: InputMaybe<Scalars['learning_can_retry_type']>
  _neq?: InputMaybe<Scalars['learning_can_retry_type']>
  _nin?: InputMaybe<Array<Scalars['learning_can_retry_type']>>
}

/** Boolean expression to compare columns of type "learning_cme_type". All fields are combined with logical 'AND'. */
export type Learning_Cme_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_cme_type']>
  _gt?: InputMaybe<Scalars['learning_cme_type']>
  _gte?: InputMaybe<Scalars['learning_cme_type']>
  _in?: InputMaybe<Array<Scalars['learning_cme_type']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['learning_cme_type']>
  _lte?: InputMaybe<Scalars['learning_cme_type']>
  _neq?: InputMaybe<Scalars['learning_cme_type']>
  _nin?: InputMaybe<Array<Scalars['learning_cme_type']>>
}

/** columns and relationships of "learning_featured_modules" */
export type Learning_Featured_Modules = {
  __typename?: 'learning_featured_modules'
  display_frequency_clicked?: Maybe<Scalars['Int']>
  display_frequency_seen?: Maybe<Scalars['Int']>
  id: Scalars['uuid']
  /** An object relationship */
  module?: Maybe<Learning_Modules>
  module_id?: Maybe<Scalars['uuid']>
  position: Scalars['Int']
  segment_id?: Maybe<Scalars['Int']>
  /** An object relationship */
  series?: Maybe<Learning_Series>
  series_id?: Maybe<Scalars['uuid']>
  type?: Maybe<Scalars['String']>
}

/** aggregated selection of "learning_featured_modules" */
export type Learning_Featured_Modules_Aggregate = {
  __typename?: 'learning_featured_modules_aggregate'
  aggregate?: Maybe<Learning_Featured_Modules_Aggregate_Fields>
  nodes: Array<Learning_Featured_Modules>
}

/** aggregate fields of "learning_featured_modules" */
export type Learning_Featured_Modules_Aggregate_Fields = {
  __typename?: 'learning_featured_modules_aggregate_fields'
  avg?: Maybe<Learning_Featured_Modules_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Featured_Modules_Max_Fields>
  min?: Maybe<Learning_Featured_Modules_Min_Fields>
  stddev?: Maybe<Learning_Featured_Modules_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Featured_Modules_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Featured_Modules_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Featured_Modules_Sum_Fields>
  var_pop?: Maybe<Learning_Featured_Modules_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Featured_Modules_Var_Samp_Fields>
  variance?: Maybe<Learning_Featured_Modules_Variance_Fields>
}

/** aggregate fields of "learning_featured_modules" */
export type Learning_Featured_Modules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Featured_Modules_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Learning_Featured_Modules_Avg_Fields = {
  __typename?: 'learning_featured_modules_avg_fields'
  display_frequency_clicked?: Maybe<Scalars['Float']>
  display_frequency_seen?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_featured_modules". All fields are combined with a logical 'AND'. */
export type Learning_Featured_Modules_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Featured_Modules_Bool_Exp>>
  _not?: InputMaybe<Learning_Featured_Modules_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Featured_Modules_Bool_Exp>>
  display_frequency_clicked?: InputMaybe<Int_Comparison_Exp>
  display_frequency_seen?: InputMaybe<Int_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  position?: InputMaybe<Int_Comparison_Exp>
  segment_id?: InputMaybe<Int_Comparison_Exp>
  series?: InputMaybe<Learning_Series_Bool_Exp>
  series_id?: InputMaybe<Uuid_Comparison_Exp>
  type?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_featured_modules" */
export enum Learning_Featured_Modules_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningFeaturedModulesPkey = 'learning_featured_modules_pkey',
}

/** input type for incrementing numeric columns in table "learning_featured_modules" */
export type Learning_Featured_Modules_Inc_Input = {
  display_frequency_clicked?: InputMaybe<Scalars['Int']>
  display_frequency_seen?: InputMaybe<Scalars['Int']>
  position?: InputMaybe<Scalars['Int']>
  segment_id?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_featured_modules" */
export type Learning_Featured_Modules_Insert_Input = {
  display_frequency_clicked?: InputMaybe<Scalars['Int']>
  display_frequency_seen?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['uuid']>
  module?: InputMaybe<Learning_Modules_Obj_Rel_Insert_Input>
  module_id?: InputMaybe<Scalars['uuid']>
  position?: InputMaybe<Scalars['Int']>
  segment_id?: InputMaybe<Scalars['Int']>
  series?: InputMaybe<Learning_Series_Obj_Rel_Insert_Input>
  series_id?: InputMaybe<Scalars['uuid']>
  type?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Learning_Featured_Modules_Max_Fields = {
  __typename?: 'learning_featured_modules_max_fields'
  display_frequency_clicked?: Maybe<Scalars['Int']>
  display_frequency_seen?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  module_id?: Maybe<Scalars['uuid']>
  position?: Maybe<Scalars['Int']>
  segment_id?: Maybe<Scalars['Int']>
  series_id?: Maybe<Scalars['uuid']>
  type?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Learning_Featured_Modules_Min_Fields = {
  __typename?: 'learning_featured_modules_min_fields'
  display_frequency_clicked?: Maybe<Scalars['Int']>
  display_frequency_seen?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  module_id?: Maybe<Scalars['uuid']>
  position?: Maybe<Scalars['Int']>
  segment_id?: Maybe<Scalars['Int']>
  series_id?: Maybe<Scalars['uuid']>
  type?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "learning_featured_modules" */
export type Learning_Featured_Modules_Mutation_Response = {
  __typename?: 'learning_featured_modules_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Featured_Modules>
}

/** on_conflict condition type for table "learning_featured_modules" */
export type Learning_Featured_Modules_On_Conflict = {
  constraint: Learning_Featured_Modules_Constraint
  update_columns?: Array<Learning_Featured_Modules_Update_Column>
  where?: InputMaybe<Learning_Featured_Modules_Bool_Exp>
}

/** Ordering options when selecting data from "learning_featured_modules". */
export type Learning_Featured_Modules_Order_By = {
  display_frequency_clicked?: InputMaybe<Order_By>
  display_frequency_seen?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  segment_id?: InputMaybe<Order_By>
  series?: InputMaybe<Learning_Series_Order_By>
  series_id?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_featured_modules */
export type Learning_Featured_Modules_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "learning_featured_modules" */
export enum Learning_Featured_Modules_Select_Column {
  /** column name */
  DisplayFrequencyClicked = 'display_frequency_clicked',
  /** column name */
  DisplayFrequencySeen = 'display_frequency_seen',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Position = 'position',
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "learning_featured_modules" */
export type Learning_Featured_Modules_Set_Input = {
  display_frequency_clicked?: InputMaybe<Scalars['Int']>
  display_frequency_seen?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['uuid']>
  module_id?: InputMaybe<Scalars['uuid']>
  position?: InputMaybe<Scalars['Int']>
  segment_id?: InputMaybe<Scalars['Int']>
  series_id?: InputMaybe<Scalars['uuid']>
  type?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Learning_Featured_Modules_Stddev_Fields = {
  __typename?: 'learning_featured_modules_stddev_fields'
  display_frequency_clicked?: Maybe<Scalars['Float']>
  display_frequency_seen?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Featured_Modules_Stddev_Pop_Fields = {
  __typename?: 'learning_featured_modules_stddev_pop_fields'
  display_frequency_clicked?: Maybe<Scalars['Float']>
  display_frequency_seen?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Featured_Modules_Stddev_Samp_Fields = {
  __typename?: 'learning_featured_modules_stddev_samp_fields'
  display_frequency_clicked?: Maybe<Scalars['Float']>
  display_frequency_seen?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_featured_modules" */
export type Learning_Featured_Modules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Featured_Modules_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Featured_Modules_Stream_Cursor_Value_Input = {
  display_frequency_clicked?: InputMaybe<Scalars['Int']>
  display_frequency_seen?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['uuid']>
  module_id?: InputMaybe<Scalars['uuid']>
  position?: InputMaybe<Scalars['Int']>
  segment_id?: InputMaybe<Scalars['Int']>
  series_id?: InputMaybe<Scalars['uuid']>
  type?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Learning_Featured_Modules_Sum_Fields = {
  __typename?: 'learning_featured_modules_sum_fields'
  display_frequency_clicked?: Maybe<Scalars['Int']>
  display_frequency_seen?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Int']>
  segment_id?: Maybe<Scalars['Int']>
}

/** update columns of table "learning_featured_modules" */
export enum Learning_Featured_Modules_Update_Column {
  /** column name */
  DisplayFrequencyClicked = 'display_frequency_clicked',
  /** column name */
  DisplayFrequencySeen = 'display_frequency_seen',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Position = 'position',
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  Type = 'type',
}

export type Learning_Featured_Modules_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Featured_Modules_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Featured_Modules_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Featured_Modules_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Featured_Modules_Var_Pop_Fields = {
  __typename?: 'learning_featured_modules_var_pop_fields'
  display_frequency_clicked?: Maybe<Scalars['Float']>
  display_frequency_seen?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Featured_Modules_Var_Samp_Fields = {
  __typename?: 'learning_featured_modules_var_samp_fields'
  display_frequency_clicked?: Maybe<Scalars['Float']>
  display_frequency_seen?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Featured_Modules_Variance_Fields = {
  __typename?: 'learning_featured_modules_variance_fields'
  display_frequency_clicked?: Maybe<Scalars['Float']>
  display_frequency_seen?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to compare columns of type "learning_learner_event". All fields are combined with logical 'AND'. */
export type Learning_Learner_Event_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_learner_event']>
  _gt?: InputMaybe<Scalars['learning_learner_event']>
  _gte?: InputMaybe<Scalars['learning_learner_event']>
  _in?: InputMaybe<Array<Scalars['learning_learner_event']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['learning_learner_event']>
  _lte?: InputMaybe<Scalars['learning_learner_event']>
  _neq?: InputMaybe<Scalars['learning_learner_event']>
  _nin?: InputMaybe<Array<Scalars['learning_learner_event']>>
}

/** columns and relationships of "learning_learner_event_log" */
export type Learning_Learner_Event_Log = {
  __typename?: 'learning_learner_event_log'
  /** An object relationship */
  actor: Users
  actor_id: Scalars['Int']
  answers?: Maybe<Scalars['jsonb']>
  event: Scalars['learning_learner_event']
  id: Scalars['Int']
  marks?: Maybe<Scalars['Int']>
  module_id: Scalars['uuid']
  page_id?: Maybe<Scalars['String']>
  question_id?: Maybe<Scalars['uuid']>
  revision_id: Scalars['uuid']
  /** An object relationship */
  session: Learning_Learner_Sessions
  session_id: Scalars['uuid']
  time: Scalars['timestamptz']
}

/** columns and relationships of "learning_learner_event_log" */
export type Learning_Learner_Event_LogAnswersArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Aggregate = {
  __typename?: 'learning_learner_event_log_aggregate'
  aggregate?: Maybe<Learning_Learner_Event_Log_Aggregate_Fields>
  nodes: Array<Learning_Learner_Event_Log>
}

export type Learning_Learner_Event_Log_Aggregate_Bool_Exp = {
  count?: InputMaybe<Learning_Learner_Event_Log_Aggregate_Bool_Exp_Count>
}

export type Learning_Learner_Event_Log_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Learner_Event_Log_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Learner_Event_Log_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Aggregate_Fields = {
  __typename?: 'learning_learner_event_log_aggregate_fields'
  avg?: Maybe<Learning_Learner_Event_Log_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Learner_Event_Log_Max_Fields>
  min?: Maybe<Learning_Learner_Event_Log_Min_Fields>
  stddev?: Maybe<Learning_Learner_Event_Log_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Learner_Event_Log_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Learner_Event_Log_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Learner_Event_Log_Sum_Fields>
  var_pop?: Maybe<Learning_Learner_Event_Log_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Learner_Event_Log_Var_Samp_Fields>
  variance?: Maybe<Learning_Learner_Event_Log_Variance_Fields>
}

/** aggregate fields of "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Learner_Event_Log_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Learner_Event_Log_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Learner_Event_Log_Max_Order_By>
  min?: InputMaybe<Learning_Learner_Event_Log_Min_Order_By>
  stddev?: InputMaybe<Learning_Learner_Event_Log_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Learner_Event_Log_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Learner_Event_Log_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Learner_Event_Log_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Learner_Event_Log_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Learner_Event_Log_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Learner_Event_Log_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Learning_Learner_Event_Log_Append_Input = {
  answers?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Arr_Rel_Insert_Input = {
  data: Array<Learning_Learner_Event_Log_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Learner_Event_Log_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Learner_Event_Log_Avg_Fields = {
  __typename?: 'learning_learner_event_log_avg_fields'
  actor_id?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Avg_Order_By = {
  actor_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_learner_event_log". All fields are combined with a logical 'AND'. */
export type Learning_Learner_Event_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Learner_Event_Log_Bool_Exp>>
  _not?: InputMaybe<Learning_Learner_Event_Log_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Learner_Event_Log_Bool_Exp>>
  actor?: InputMaybe<Users_Bool_Exp>
  actor_id?: InputMaybe<Int_Comparison_Exp>
  answers?: InputMaybe<Jsonb_Comparison_Exp>
  event?: InputMaybe<Learning_Learner_Event_Comparison_Exp>
  id?: InputMaybe<Int_Comparison_Exp>
  marks?: InputMaybe<Int_Comparison_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  page_id?: InputMaybe<String_Comparison_Exp>
  question_id?: InputMaybe<Uuid_Comparison_Exp>
  revision_id?: InputMaybe<Uuid_Comparison_Exp>
  session?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
  session_id?: InputMaybe<Uuid_Comparison_Exp>
  time?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_learner_event_log" */
export enum Learning_Learner_Event_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningLearnerEventLogPkey = 'learning_learner_event_log_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Learning_Learner_Event_Log_Delete_At_Path_Input = {
  answers?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Learning_Learner_Event_Log_Delete_Elem_Input = {
  answers?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Learning_Learner_Event_Log_Delete_Key_Input = {
  answers?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Inc_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  marks?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Insert_Input = {
  actor?: InputMaybe<Users_Obj_Rel_Insert_Input>
  actor_id?: InputMaybe<Scalars['Int']>
  answers?: InputMaybe<Scalars['jsonb']>
  event?: InputMaybe<Scalars['learning_learner_event']>
  id?: InputMaybe<Scalars['Int']>
  marks?: InputMaybe<Scalars['Int']>
  module_id?: InputMaybe<Scalars['uuid']>
  page_id?: InputMaybe<Scalars['String']>
  question_id?: InputMaybe<Scalars['uuid']>
  revision_id?: InputMaybe<Scalars['uuid']>
  session?: InputMaybe<Learning_Learner_Sessions_Obj_Rel_Insert_Input>
  session_id?: InputMaybe<Scalars['uuid']>
  time?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Learning_Learner_Event_Log_Max_Fields = {
  __typename?: 'learning_learner_event_log_max_fields'
  actor_id?: Maybe<Scalars['Int']>
  event?: Maybe<Scalars['learning_learner_event']>
  id?: Maybe<Scalars['Int']>
  marks?: Maybe<Scalars['Int']>
  module_id?: Maybe<Scalars['uuid']>
  page_id?: Maybe<Scalars['String']>
  question_id?: Maybe<Scalars['uuid']>
  revision_id?: Maybe<Scalars['uuid']>
  session_id?: Maybe<Scalars['uuid']>
  time?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Max_Order_By = {
  actor_id?: InputMaybe<Order_By>
  event?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  session_id?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Learner_Event_Log_Min_Fields = {
  __typename?: 'learning_learner_event_log_min_fields'
  actor_id?: Maybe<Scalars['Int']>
  event?: Maybe<Scalars['learning_learner_event']>
  id?: Maybe<Scalars['Int']>
  marks?: Maybe<Scalars['Int']>
  module_id?: Maybe<Scalars['uuid']>
  page_id?: Maybe<Scalars['String']>
  question_id?: Maybe<Scalars['uuid']>
  revision_id?: Maybe<Scalars['uuid']>
  session_id?: Maybe<Scalars['uuid']>
  time?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Min_Order_By = {
  actor_id?: InputMaybe<Order_By>
  event?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  session_id?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Mutation_Response = {
  __typename?: 'learning_learner_event_log_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Learner_Event_Log>
}

/** on_conflict condition type for table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_On_Conflict = {
  constraint: Learning_Learner_Event_Log_Constraint
  update_columns?: Array<Learning_Learner_Event_Log_Update_Column>
  where?: InputMaybe<Learning_Learner_Event_Log_Bool_Exp>
}

/** Ordering options when selecting data from "learning_learner_event_log". */
export type Learning_Learner_Event_Log_Order_By = {
  actor?: InputMaybe<Users_Order_By>
  actor_id?: InputMaybe<Order_By>
  answers?: InputMaybe<Order_By>
  event?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  session?: InputMaybe<Learning_Learner_Sessions_Order_By>
  session_id?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_learner_event_log */
export type Learning_Learner_Event_Log_Pk_Columns_Input = {
  id: Scalars['Int']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Learning_Learner_Event_Log_Prepend_Input = {
  answers?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "learning_learner_event_log" */
export enum Learning_Learner_Event_Log_Select_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  Answers = 'answers',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  Marks = 'marks',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  PageId = 'page_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  RevisionId = 'revision_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Time = 'time',
}

/** input type for updating data in table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Set_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  answers?: InputMaybe<Scalars['jsonb']>
  event?: InputMaybe<Scalars['learning_learner_event']>
  id?: InputMaybe<Scalars['Int']>
  marks?: InputMaybe<Scalars['Int']>
  module_id?: InputMaybe<Scalars['uuid']>
  page_id?: InputMaybe<Scalars['String']>
  question_id?: InputMaybe<Scalars['uuid']>
  revision_id?: InputMaybe<Scalars['uuid']>
  session_id?: InputMaybe<Scalars['uuid']>
  time?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Learning_Learner_Event_Log_Stddev_Fields = {
  __typename?: 'learning_learner_event_log_stddev_fields'
  actor_id?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Stddev_Order_By = {
  actor_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Learner_Event_Log_Stddev_Pop_Fields = {
  __typename?: 'learning_learner_event_log_stddev_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Stddev_Pop_Order_By = {
  actor_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Learner_Event_Log_Stddev_Samp_Fields = {
  __typename?: 'learning_learner_event_log_stddev_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Stddev_Samp_Order_By = {
  actor_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Learner_Event_Log_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Learner_Event_Log_Stream_Cursor_Value_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  answers?: InputMaybe<Scalars['jsonb']>
  event?: InputMaybe<Scalars['learning_learner_event']>
  id?: InputMaybe<Scalars['Int']>
  marks?: InputMaybe<Scalars['Int']>
  module_id?: InputMaybe<Scalars['uuid']>
  page_id?: InputMaybe<Scalars['String']>
  question_id?: InputMaybe<Scalars['uuid']>
  revision_id?: InputMaybe<Scalars['uuid']>
  session_id?: InputMaybe<Scalars['uuid']>
  time?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Learning_Learner_Event_Log_Sum_Fields = {
  __typename?: 'learning_learner_event_log_sum_fields'
  actor_id?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  marks?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Sum_Order_By = {
  actor_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
}

/** update columns of table "learning_learner_event_log" */
export enum Learning_Learner_Event_Log_Update_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  Answers = 'answers',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  Marks = 'marks',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  PageId = 'page_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  RevisionId = 'revision_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Time = 'time',
}

export type Learning_Learner_Event_Log_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Learning_Learner_Event_Log_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Learning_Learner_Event_Log_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Learning_Learner_Event_Log_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Learning_Learner_Event_Log_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Learner_Event_Log_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Learning_Learner_Event_Log_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Learner_Event_Log_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Learner_Event_Log_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Learner_Event_Log_Var_Pop_Fields = {
  __typename?: 'learning_learner_event_log_var_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Var_Pop_Order_By = {
  actor_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Learner_Event_Log_Var_Samp_Fields = {
  __typename?: 'learning_learner_event_log_var_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Var_Samp_Order_By = {
  actor_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Learner_Event_Log_Variance_Fields = {
  __typename?: 'learning_learner_event_log_variance_fields'
  actor_id?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_learner_event_log" */
export type Learning_Learner_Event_Log_Variance_Order_By = {
  actor_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
}

/** columns and relationships of "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_Questions = {
  __typename?: 'learning_learner_module_rating_questions'
  answer_type: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  module_id?: Maybe<Scalars['uuid']>
  position: Scalars['Int']
  question_text: Scalars['String']
  /** An array relationship */
  responses: Array<Learning_Learner_Module_Rating_Responses>
  /** An aggregate relationship */
  responses_aggregate: Learning_Learner_Module_Rating_Responses_Aggregate
  series_id?: Maybe<Scalars['uuid']>
  updated_at: Scalars['timestamptz']
  updated_by?: Maybe<Scalars['Int']>
  /** An object relationship */
  updater?: Maybe<Users>
}

/** columns and relationships of "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_QuestionsResponsesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Order_By>
  >
  where?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
}

/** columns and relationships of "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_QuestionsResponses_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Order_By>
  >
  where?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
}

/** aggregated selection of "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_Questions_Aggregate = {
  __typename?: 'learning_learner_module_rating_questions_aggregate'
  aggregate?: Maybe<Learning_Learner_Module_Rating_Questions_Aggregate_Fields>
  nodes: Array<Learning_Learner_Module_Rating_Questions>
}

/** aggregate fields of "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_Questions_Aggregate_Fields = {
  __typename?: 'learning_learner_module_rating_questions_aggregate_fields'
  avg?: Maybe<Learning_Learner_Module_Rating_Questions_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Learner_Module_Rating_Questions_Max_Fields>
  min?: Maybe<Learning_Learner_Module_Rating_Questions_Min_Fields>
  stddev?: Maybe<Learning_Learner_Module_Rating_Questions_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Learner_Module_Rating_Questions_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Learner_Module_Rating_Questions_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Learner_Module_Rating_Questions_Sum_Fields>
  var_pop?: Maybe<Learning_Learner_Module_Rating_Questions_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Learner_Module_Rating_Questions_Var_Samp_Fields>
  variance?: Maybe<Learning_Learner_Module_Rating_Questions_Variance_Fields>
}

/** aggregate fields of "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_Questions_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Learning_Learner_Module_Rating_Questions_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
  }

/** aggregate avg on columns */
export type Learning_Learner_Module_Rating_Questions_Avg_Fields = {
  __typename?: 'learning_learner_module_rating_questions_avg_fields'
  position?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_learner_module_rating_questions". All fields are combined with a logical 'AND'. */
export type Learning_Learner_Module_Rating_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Learner_Module_Rating_Questions_Bool_Exp>>
  _not?: InputMaybe<Learning_Learner_Module_Rating_Questions_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Learner_Module_Rating_Questions_Bool_Exp>>
  answer_type?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  position?: InputMaybe<Int_Comparison_Exp>
  question_text?: InputMaybe<String_Comparison_Exp>
  responses?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
  responses_aggregate?: InputMaybe<Learning_Learner_Module_Rating_Responses_Aggregate_Bool_Exp>
  series_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
  updater?: InputMaybe<Users_Bool_Exp>
}

/** unique or primary key constraints on table "learning_learner_module_rating_questions" */
export enum Learning_Learner_Module_Rating_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningLearnerModuleRatingQuestionsPkey = 'learning_learner_module_rating_questions_pkey',
}

/** input type for incrementing numeric columns in table "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_Questions_Inc_Input = {
  position?: InputMaybe<Scalars['Int']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_Questions_Insert_Input = {
  answer_type?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  module_id?: InputMaybe<Scalars['uuid']>
  position?: InputMaybe<Scalars['Int']>
  question_text?: InputMaybe<Scalars['String']>
  responses?: InputMaybe<Learning_Learner_Module_Rating_Responses_Arr_Rel_Insert_Input>
  series_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  updater?: InputMaybe<Users_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Learning_Learner_Module_Rating_Questions_Max_Fields = {
  __typename?: 'learning_learner_module_rating_questions_max_fields'
  answer_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  module_id?: Maybe<Scalars['uuid']>
  position?: Maybe<Scalars['Int']>
  question_text?: Maybe<Scalars['String']>
  series_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** aggregate min on columns */
export type Learning_Learner_Module_Rating_Questions_Min_Fields = {
  __typename?: 'learning_learner_module_rating_questions_min_fields'
  answer_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  module_id?: Maybe<Scalars['uuid']>
  position?: Maybe<Scalars['Int']>
  question_text?: Maybe<Scalars['String']>
  series_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** response of any mutation on the table "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_Questions_Mutation_Response = {
  __typename?: 'learning_learner_module_rating_questions_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Learner_Module_Rating_Questions>
}

/** input type for inserting object relation for remote table "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_Questions_Obj_Rel_Insert_Input = {
  data: Learning_Learner_Module_Rating_Questions_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Learner_Module_Rating_Questions_On_Conflict>
}

/** on_conflict condition type for table "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_Questions_On_Conflict = {
  constraint: Learning_Learner_Module_Rating_Questions_Constraint
  update_columns?: Array<Learning_Learner_Module_Rating_Questions_Update_Column>
  where?: InputMaybe<Learning_Learner_Module_Rating_Questions_Bool_Exp>
}

/** Ordering options when selecting data from "learning_learner_module_rating_questions". */
export type Learning_Learner_Module_Rating_Questions_Order_By = {
  answer_type?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  question_text?: InputMaybe<Order_By>
  responses_aggregate?: InputMaybe<Learning_Learner_Module_Rating_Responses_Aggregate_Order_By>
  series_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  updater?: InputMaybe<Users_Order_By>
}

/** primary key columns input for table: learning_learner_module_rating_questions */
export type Learning_Learner_Module_Rating_Questions_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "learning_learner_module_rating_questions" */
export enum Learning_Learner_Module_Rating_Questions_Select_Column {
  /** column name */
  AnswerType = 'answer_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Position = 'position',
  /** column name */
  QuestionText = 'question_text',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** input type for updating data in table "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_Questions_Set_Input = {
  answer_type?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  module_id?: InputMaybe<Scalars['uuid']>
  position?: InputMaybe<Scalars['Int']>
  question_text?: InputMaybe<Scalars['String']>
  series_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Learning_Learner_Module_Rating_Questions_Stddev_Fields = {
  __typename?: 'learning_learner_module_rating_questions_stddev_fields'
  position?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Learner_Module_Rating_Questions_Stddev_Pop_Fields = {
  __typename?: 'learning_learner_module_rating_questions_stddev_pop_fields'
  position?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Learner_Module_Rating_Questions_Stddev_Samp_Fields = {
  __typename?: 'learning_learner_module_rating_questions_stddev_samp_fields'
  position?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_learner_module_rating_questions" */
export type Learning_Learner_Module_Rating_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Learner_Module_Rating_Questions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Learner_Module_Rating_Questions_Stream_Cursor_Value_Input =
  {
    answer_type?: InputMaybe<Scalars['String']>
    created_at?: InputMaybe<Scalars['timestamptz']>
    id?: InputMaybe<Scalars['uuid']>
    module_id?: InputMaybe<Scalars['uuid']>
    position?: InputMaybe<Scalars['Int']>
    question_text?: InputMaybe<Scalars['String']>
    series_id?: InputMaybe<Scalars['uuid']>
    updated_at?: InputMaybe<Scalars['timestamptz']>
    updated_by?: InputMaybe<Scalars['Int']>
  }

/** aggregate sum on columns */
export type Learning_Learner_Module_Rating_Questions_Sum_Fields = {
  __typename?: 'learning_learner_module_rating_questions_sum_fields'
  position?: Maybe<Scalars['Int']>
  updated_by?: Maybe<Scalars['Int']>
}

/** update columns of table "learning_learner_module_rating_questions" */
export enum Learning_Learner_Module_Rating_Questions_Update_Column {
  /** column name */
  AnswerType = 'answer_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Position = 'position',
  /** column name */
  QuestionText = 'question_text',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Learning_Learner_Module_Rating_Questions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Learner_Module_Rating_Questions_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Learner_Module_Rating_Questions_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Learner_Module_Rating_Questions_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Learner_Module_Rating_Questions_Var_Pop_Fields = {
  __typename?: 'learning_learner_module_rating_questions_var_pop_fields'
  position?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Learner_Module_Rating_Questions_Var_Samp_Fields = {
  __typename?: 'learning_learner_module_rating_questions_var_samp_fields'
  position?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Learner_Module_Rating_Questions_Variance_Fields = {
  __typename?: 'learning_learner_module_rating_questions_variance_fields'
  position?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** columns and relationships of "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses = {
  __typename?: 'learning_learner_module_rating_responses'
  created_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  /** An object relationship */
  question: Learning_Learner_Module_Rating_Questions
  question_id: Scalars['uuid']
  /** An object relationship */
  rating: Learning_Learner_Module_Ratings
  rating_id: Scalars['uuid']
  response?: Maybe<Scalars['Int']>
  response_text?: Maybe<Scalars['String']>
}

/** aggregated selection of "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Aggregate = {
  __typename?: 'learning_learner_module_rating_responses_aggregate'
  aggregate?: Maybe<Learning_Learner_Module_Rating_Responses_Aggregate_Fields>
  nodes: Array<Learning_Learner_Module_Rating_Responses>
}

export type Learning_Learner_Module_Rating_Responses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Learning_Learner_Module_Rating_Responses_Aggregate_Bool_Exp_Count>
}

export type Learning_Learner_Module_Rating_Responses_Aggregate_Bool_Exp_Count =
  {
    arguments?: InputMaybe<
      Array<Learning_Learner_Module_Rating_Responses_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
    filter?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
    predicate: Int_Comparison_Exp
  }

/** aggregate fields of "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Aggregate_Fields = {
  __typename?: 'learning_learner_module_rating_responses_aggregate_fields'
  avg?: Maybe<Learning_Learner_Module_Rating_Responses_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Learner_Module_Rating_Responses_Max_Fields>
  min?: Maybe<Learning_Learner_Module_Rating_Responses_Min_Fields>
  stddev?: Maybe<Learning_Learner_Module_Rating_Responses_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Learner_Module_Rating_Responses_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Learner_Module_Rating_Responses_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Learner_Module_Rating_Responses_Sum_Fields>
  var_pop?: Maybe<Learning_Learner_Module_Rating_Responses_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Learner_Module_Rating_Responses_Var_Samp_Fields>
  variance?: Maybe<Learning_Learner_Module_Rating_Responses_Variance_Fields>
}

/** aggregate fields of "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Learning_Learner_Module_Rating_Responses_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
  }

/** order by aggregate values of table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Learner_Module_Rating_Responses_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Learner_Module_Rating_Responses_Max_Order_By>
  min?: InputMaybe<Learning_Learner_Module_Rating_Responses_Min_Order_By>
  stddev?: InputMaybe<Learning_Learner_Module_Rating_Responses_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Learner_Module_Rating_Responses_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Learner_Module_Rating_Responses_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Learner_Module_Rating_Responses_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Learner_Module_Rating_Responses_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Learner_Module_Rating_Responses_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Learner_Module_Rating_Responses_Variance_Order_By>
}

/** input type for inserting array relation for remote table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Arr_Rel_Insert_Input = {
  data: Array<Learning_Learner_Module_Rating_Responses_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Learner_Module_Rating_Responses_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Learner_Module_Rating_Responses_Avg_Fields = {
  __typename?: 'learning_learner_module_rating_responses_avg_fields'
  response?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Avg_Order_By = {
  response?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_learner_module_rating_responses". All fields are combined with a logical 'AND'. */
export type Learning_Learner_Module_Rating_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Learner_Module_Rating_Responses_Bool_Exp>>
  _not?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Learner_Module_Rating_Responses_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  question?: InputMaybe<Learning_Learner_Module_Rating_Questions_Bool_Exp>
  question_id?: InputMaybe<Uuid_Comparison_Exp>
  rating?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
  rating_id?: InputMaybe<Uuid_Comparison_Exp>
  response?: InputMaybe<Int_Comparison_Exp>
  response_text?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_learner_module_rating_responses" */
export enum Learning_Learner_Module_Rating_Responses_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningLearnerModuleRatingResponsesPkey = 'learning_learner_module_rating_responses_pkey',
}

/** input type for incrementing numeric columns in table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Inc_Input = {
  response?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  question?: InputMaybe<Learning_Learner_Module_Rating_Questions_Obj_Rel_Insert_Input>
  question_id?: InputMaybe<Scalars['uuid']>
  rating?: InputMaybe<Learning_Learner_Module_Ratings_Obj_Rel_Insert_Input>
  rating_id?: InputMaybe<Scalars['uuid']>
  response?: InputMaybe<Scalars['Int']>
  response_text?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Learning_Learner_Module_Rating_Responses_Max_Fields = {
  __typename?: 'learning_learner_module_rating_responses_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  question_id?: Maybe<Scalars['uuid']>
  rating_id?: Maybe<Scalars['uuid']>
  response?: Maybe<Scalars['Int']>
  response_text?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  rating_id?: InputMaybe<Order_By>
  response?: InputMaybe<Order_By>
  response_text?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Learner_Module_Rating_Responses_Min_Fields = {
  __typename?: 'learning_learner_module_rating_responses_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  question_id?: Maybe<Scalars['uuid']>
  rating_id?: Maybe<Scalars['uuid']>
  response?: Maybe<Scalars['Int']>
  response_text?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  rating_id?: InputMaybe<Order_By>
  response?: InputMaybe<Order_By>
  response_text?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Mutation_Response = {
  __typename?: 'learning_learner_module_rating_responses_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Learner_Module_Rating_Responses>
}

/** on_conflict condition type for table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_On_Conflict = {
  constraint: Learning_Learner_Module_Rating_Responses_Constraint
  update_columns?: Array<Learning_Learner_Module_Rating_Responses_Update_Column>
  where?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
}

/** Ordering options when selecting data from "learning_learner_module_rating_responses". */
export type Learning_Learner_Module_Rating_Responses_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  question?: InputMaybe<Learning_Learner_Module_Rating_Questions_Order_By>
  question_id?: InputMaybe<Order_By>
  rating?: InputMaybe<Learning_Learner_Module_Ratings_Order_By>
  rating_id?: InputMaybe<Order_By>
  response?: InputMaybe<Order_By>
  response_text?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_learner_module_rating_responses */
export type Learning_Learner_Module_Rating_Responses_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "learning_learner_module_rating_responses" */
export enum Learning_Learner_Module_Rating_Responses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  RatingId = 'rating_id',
  /** column name */
  Response = 'response',
  /** column name */
  ResponseText = 'response_text',
}

/** input type for updating data in table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  question_id?: InputMaybe<Scalars['uuid']>
  rating_id?: InputMaybe<Scalars['uuid']>
  response?: InputMaybe<Scalars['Int']>
  response_text?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Learning_Learner_Module_Rating_Responses_Stddev_Fields = {
  __typename?: 'learning_learner_module_rating_responses_stddev_fields'
  response?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Stddev_Order_By = {
  response?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Learner_Module_Rating_Responses_Stddev_Pop_Fields = {
  __typename?: 'learning_learner_module_rating_responses_stddev_pop_fields'
  response?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Stddev_Pop_Order_By = {
  response?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Learner_Module_Rating_Responses_Stddev_Samp_Fields = {
  __typename?: 'learning_learner_module_rating_responses_stddev_samp_fields'
  response?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Stddev_Samp_Order_By = {
  response?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Learner_Module_Rating_Responses_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Learner_Module_Rating_Responses_Stream_Cursor_Value_Input =
  {
    created_at?: InputMaybe<Scalars['timestamptz']>
    id?: InputMaybe<Scalars['uuid']>
    question_id?: InputMaybe<Scalars['uuid']>
    rating_id?: InputMaybe<Scalars['uuid']>
    response?: InputMaybe<Scalars['Int']>
    response_text?: InputMaybe<Scalars['String']>
  }

/** aggregate sum on columns */
export type Learning_Learner_Module_Rating_Responses_Sum_Fields = {
  __typename?: 'learning_learner_module_rating_responses_sum_fields'
  response?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Sum_Order_By = {
  response?: InputMaybe<Order_By>
}

/** update columns of table "learning_learner_module_rating_responses" */
export enum Learning_Learner_Module_Rating_Responses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  RatingId = 'rating_id',
  /** column name */
  Response = 'response',
  /** column name */
  ResponseText = 'response_text',
}

export type Learning_Learner_Module_Rating_Responses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Learner_Module_Rating_Responses_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Learner_Module_Rating_Responses_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Learner_Module_Rating_Responses_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Learner_Module_Rating_Responses_Var_Pop_Fields = {
  __typename?: 'learning_learner_module_rating_responses_var_pop_fields'
  response?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Var_Pop_Order_By = {
  response?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Learner_Module_Rating_Responses_Var_Samp_Fields = {
  __typename?: 'learning_learner_module_rating_responses_var_samp_fields'
  response?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Var_Samp_Order_By = {
  response?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Learner_Module_Rating_Responses_Variance_Fields = {
  __typename?: 'learning_learner_module_rating_responses_variance_fields'
  response?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_learner_module_rating_responses" */
export type Learning_Learner_Module_Rating_Responses_Variance_Order_By = {
  response?: InputMaybe<Order_By>
}

/** columns and relationships of "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings = {
  __typename?: 'learning_learner_module_ratings'
  created_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  /** An object relationship */
  module?: Maybe<Learning_Modules>
  module_id?: Maybe<Scalars['uuid']>
  /** An array relationship */
  responses: Array<Learning_Learner_Module_Rating_Responses>
  /** An aggregate relationship */
  responses_aggregate: Learning_Learner_Module_Rating_Responses_Aggregate
  /** An object relationship */
  session?: Maybe<Learning_Learner_Sessions>
  session_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  user: Users
  user_id: Scalars['Int']
}

/** columns and relationships of "learning_learner_module_ratings" */
export type Learning_Learner_Module_RatingsResponsesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Order_By>
  >
  where?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
}

/** columns and relationships of "learning_learner_module_ratings" */
export type Learning_Learner_Module_RatingsResponses_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Order_By>
  >
  where?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
}

/** aggregated selection of "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Aggregate = {
  __typename?: 'learning_learner_module_ratings_aggregate'
  aggregate?: Maybe<Learning_Learner_Module_Ratings_Aggregate_Fields>
  nodes: Array<Learning_Learner_Module_Ratings>
}

export type Learning_Learner_Module_Ratings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Learning_Learner_Module_Ratings_Aggregate_Bool_Exp_Count>
}

export type Learning_Learner_Module_Ratings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Learner_Module_Ratings_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Aggregate_Fields = {
  __typename?: 'learning_learner_module_ratings_aggregate_fields'
  avg?: Maybe<Learning_Learner_Module_Ratings_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Learner_Module_Ratings_Max_Fields>
  min?: Maybe<Learning_Learner_Module_Ratings_Min_Fields>
  stddev?: Maybe<Learning_Learner_Module_Ratings_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Learner_Module_Ratings_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Learner_Module_Ratings_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Learner_Module_Ratings_Sum_Fields>
  var_pop?: Maybe<Learning_Learner_Module_Ratings_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Learner_Module_Ratings_Var_Samp_Fields>
  variance?: Maybe<Learning_Learner_Module_Ratings_Variance_Fields>
}

/** aggregate fields of "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Learner_Module_Ratings_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Learner_Module_Ratings_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Learner_Module_Ratings_Max_Order_By>
  min?: InputMaybe<Learning_Learner_Module_Ratings_Min_Order_By>
  stddev?: InputMaybe<Learning_Learner_Module_Ratings_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Learner_Module_Ratings_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Learner_Module_Ratings_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Learner_Module_Ratings_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Learner_Module_Ratings_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Learner_Module_Ratings_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Learner_Module_Ratings_Variance_Order_By>
}

/** input type for inserting array relation for remote table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Arr_Rel_Insert_Input = {
  data: Array<Learning_Learner_Module_Ratings_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Learner_Module_Ratings_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Learner_Module_Ratings_Avg_Fields = {
  __typename?: 'learning_learner_module_ratings_avg_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Avg_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_learner_module_ratings". All fields are combined with a logical 'AND'. */
export type Learning_Learner_Module_Ratings_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Learner_Module_Ratings_Bool_Exp>>
  _not?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Learner_Module_Ratings_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  responses?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
  responses_aggregate?: InputMaybe<Learning_Learner_Module_Rating_Responses_Aggregate_Bool_Exp>
  session?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
  session_id?: InputMaybe<Uuid_Comparison_Exp>
  user?: InputMaybe<Users_Bool_Exp>
  user_id?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_learner_module_ratings" */
export enum Learning_Learner_Module_Ratings_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningLearnerModuleRatingsPkey = 'learning_learner_module_ratings_pkey',
  /** unique or primary key constraint on columns "session_id" */
  LearningLearnerModuleRatingsSessionIdKey = 'learning_learner_module_ratings_session_id_key',
}

/** input type for incrementing numeric columns in table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Inc_Input = {
  user_id?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  module?: InputMaybe<Learning_Modules_Obj_Rel_Insert_Input>
  module_id?: InputMaybe<Scalars['uuid']>
  responses?: InputMaybe<Learning_Learner_Module_Rating_Responses_Arr_Rel_Insert_Input>
  session?: InputMaybe<Learning_Learner_Sessions_Obj_Rel_Insert_Input>
  session_id?: InputMaybe<Scalars['uuid']>
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>
  user_id?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Learning_Learner_Module_Ratings_Max_Fields = {
  __typename?: 'learning_learner_module_ratings_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  module_id?: Maybe<Scalars['uuid']>
  session_id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  session_id?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Learner_Module_Ratings_Min_Fields = {
  __typename?: 'learning_learner_module_ratings_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  module_id?: Maybe<Scalars['uuid']>
  session_id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  session_id?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Mutation_Response = {
  __typename?: 'learning_learner_module_ratings_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Learner_Module_Ratings>
}

/** input type for inserting object relation for remote table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Obj_Rel_Insert_Input = {
  data: Learning_Learner_Module_Ratings_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Learner_Module_Ratings_On_Conflict>
}

/** on_conflict condition type for table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_On_Conflict = {
  constraint: Learning_Learner_Module_Ratings_Constraint
  update_columns?: Array<Learning_Learner_Module_Ratings_Update_Column>
  where?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
}

/** Ordering options when selecting data from "learning_learner_module_ratings". */
export type Learning_Learner_Module_Ratings_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  responses_aggregate?: InputMaybe<Learning_Learner_Module_Rating_Responses_Aggregate_Order_By>
  session?: InputMaybe<Learning_Learner_Sessions_Order_By>
  session_id?: InputMaybe<Order_By>
  user?: InputMaybe<Users_Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_learner_module_ratings */
export type Learning_Learner_Module_Ratings_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "learning_learner_module_ratings" */
export enum Learning_Learner_Module_Ratings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  module_id?: InputMaybe<Scalars['uuid']>
  session_id?: InputMaybe<Scalars['uuid']>
  user_id?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Learning_Learner_Module_Ratings_Stddev_Fields = {
  __typename?: 'learning_learner_module_ratings_stddev_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Stddev_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Learner_Module_Ratings_Stddev_Pop_Fields = {
  __typename?: 'learning_learner_module_ratings_stddev_pop_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Stddev_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Learner_Module_Ratings_Stddev_Samp_Fields = {
  __typename?: 'learning_learner_module_ratings_stddev_samp_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Stddev_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Learner_Module_Ratings_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Learner_Module_Ratings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  module_id?: InputMaybe<Scalars['uuid']>
  session_id?: InputMaybe<Scalars['uuid']>
  user_id?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Learning_Learner_Module_Ratings_Sum_Fields = {
  __typename?: 'learning_learner_module_ratings_sum_fields'
  user_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Sum_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** update columns of table "learning_learner_module_ratings" */
export enum Learning_Learner_Module_Ratings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  UserId = 'user_id',
}

export type Learning_Learner_Module_Ratings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Learner_Module_Ratings_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Learner_Module_Ratings_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Learner_Module_Ratings_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Learner_Module_Ratings_Var_Pop_Fields = {
  __typename?: 'learning_learner_module_ratings_var_pop_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Var_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Learner_Module_Ratings_Var_Samp_Fields = {
  __typename?: 'learning_learner_module_ratings_var_samp_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Var_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Learner_Module_Ratings_Variance_Fields = {
  __typename?: 'learning_learner_module_ratings_variance_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_learner_module_ratings" */
export type Learning_Learner_Module_Ratings_Variance_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** Records each rating given to a module by a user. Overall module rating is learning_module_star_ratings */
export type Learning_Learner_Module_Star_Ratings = {
  __typename?: 'learning_learner_module_star_ratings'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  module_id?: Maybe<Scalars['uuid']>
  rating: Scalars['Int']
  user_id: Scalars['bigint']
}

/** aggregated selection of "learning_learner_module_star_ratings" */
export type Learning_Learner_Module_Star_Ratings_Aggregate = {
  __typename?: 'learning_learner_module_star_ratings_aggregate'
  aggregate?: Maybe<Learning_Learner_Module_Star_Ratings_Aggregate_Fields>
  nodes: Array<Learning_Learner_Module_Star_Ratings>
}

/** aggregate fields of "learning_learner_module_star_ratings" */
export type Learning_Learner_Module_Star_Ratings_Aggregate_Fields = {
  __typename?: 'learning_learner_module_star_ratings_aggregate_fields'
  avg?: Maybe<Learning_Learner_Module_Star_Ratings_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Learner_Module_Star_Ratings_Max_Fields>
  min?: Maybe<Learning_Learner_Module_Star_Ratings_Min_Fields>
  stddev?: Maybe<Learning_Learner_Module_Star_Ratings_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Learner_Module_Star_Ratings_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Learner_Module_Star_Ratings_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Learner_Module_Star_Ratings_Sum_Fields>
  var_pop?: Maybe<Learning_Learner_Module_Star_Ratings_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Learner_Module_Star_Ratings_Var_Samp_Fields>
  variance?: Maybe<Learning_Learner_Module_Star_Ratings_Variance_Fields>
}

/** aggregate fields of "learning_learner_module_star_ratings" */
export type Learning_Learner_Module_Star_Ratings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Learning_Learner_Module_Star_Ratings_Select_Column>
  >
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Learning_Learner_Module_Star_Ratings_Avg_Fields = {
  __typename?: 'learning_learner_module_star_ratings_avg_fields'
  rating?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_learner_module_star_ratings". All fields are combined with a logical 'AND'. */
export type Learning_Learner_Module_Star_Ratings_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Learner_Module_Star_Ratings_Bool_Exp>>
  _not?: InputMaybe<Learning_Learner_Module_Star_Ratings_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Learner_Module_Star_Ratings_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  rating?: InputMaybe<Int_Comparison_Exp>
  user_id?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_learner_module_star_ratings" */
export enum Learning_Learner_Module_Star_Ratings_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningLearnerModuleStarRatingsPkey = 'learning_learner_module_star_ratings_pkey',
}

/** input type for incrementing numeric columns in table "learning_learner_module_star_ratings" */
export type Learning_Learner_Module_Star_Ratings_Inc_Input = {
  rating?: InputMaybe<Scalars['Int']>
  user_id?: InputMaybe<Scalars['bigint']>
}

/** input type for inserting data into table "learning_learner_module_star_ratings" */
export type Learning_Learner_Module_Star_Ratings_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  module_id?: InputMaybe<Scalars['uuid']>
  rating?: InputMaybe<Scalars['Int']>
  user_id?: InputMaybe<Scalars['bigint']>
}

/** aggregate max on columns */
export type Learning_Learner_Module_Star_Ratings_Max_Fields = {
  __typename?: 'learning_learner_module_star_ratings_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  module_id?: Maybe<Scalars['uuid']>
  rating?: Maybe<Scalars['Int']>
  user_id?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Learner_Module_Star_Ratings_Min_Fields = {
  __typename?: 'learning_learner_module_star_ratings_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  module_id?: Maybe<Scalars['uuid']>
  rating?: Maybe<Scalars['Int']>
  user_id?: Maybe<Scalars['bigint']>
}

/** response of any mutation on the table "learning_learner_module_star_ratings" */
export type Learning_Learner_Module_Star_Ratings_Mutation_Response = {
  __typename?: 'learning_learner_module_star_ratings_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Learner_Module_Star_Ratings>
}

/** on_conflict condition type for table "learning_learner_module_star_ratings" */
export type Learning_Learner_Module_Star_Ratings_On_Conflict = {
  constraint: Learning_Learner_Module_Star_Ratings_Constraint
  update_columns?: Array<Learning_Learner_Module_Star_Ratings_Update_Column>
  where?: InputMaybe<Learning_Learner_Module_Star_Ratings_Bool_Exp>
}

/** Ordering options when selecting data from "learning_learner_module_star_ratings". */
export type Learning_Learner_Module_Star_Ratings_Order_By = {
  created_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  rating?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_learner_module_star_ratings */
export type Learning_Learner_Module_Star_Ratings_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "learning_learner_module_star_ratings" */
export enum Learning_Learner_Module_Star_Ratings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "learning_learner_module_star_ratings" */
export type Learning_Learner_Module_Star_Ratings_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  module_id?: InputMaybe<Scalars['uuid']>
  rating?: InputMaybe<Scalars['Int']>
  user_id?: InputMaybe<Scalars['bigint']>
}

/** aggregate stddev on columns */
export type Learning_Learner_Module_Star_Ratings_Stddev_Fields = {
  __typename?: 'learning_learner_module_star_ratings_stddev_fields'
  rating?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Learner_Module_Star_Ratings_Stddev_Pop_Fields = {
  __typename?: 'learning_learner_module_star_ratings_stddev_pop_fields'
  rating?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Learner_Module_Star_Ratings_Stddev_Samp_Fields = {
  __typename?: 'learning_learner_module_star_ratings_stddev_samp_fields'
  rating?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_learner_module_star_ratings" */
export type Learning_Learner_Module_Star_Ratings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Learner_Module_Star_Ratings_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Learner_Module_Star_Ratings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  module_id?: InputMaybe<Scalars['uuid']>
  rating?: InputMaybe<Scalars['Int']>
  user_id?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Learning_Learner_Module_Star_Ratings_Sum_Fields = {
  __typename?: 'learning_learner_module_star_ratings_sum_fields'
  rating?: Maybe<Scalars['Int']>
  user_id?: Maybe<Scalars['bigint']>
}

/** update columns of table "learning_learner_module_star_ratings" */
export enum Learning_Learner_Module_Star_Ratings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  UserId = 'user_id',
}

export type Learning_Learner_Module_Star_Ratings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Learner_Module_Star_Ratings_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Learner_Module_Star_Ratings_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Learner_Module_Star_Ratings_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Learner_Module_Star_Ratings_Var_Pop_Fields = {
  __typename?: 'learning_learner_module_star_ratings_var_pop_fields'
  rating?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Learner_Module_Star_Ratings_Var_Samp_Fields = {
  __typename?: 'learning_learner_module_star_ratings_var_samp_fields'
  rating?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Learner_Module_Star_Ratings_Variance_Fields = {
  __typename?: 'learning_learner_module_star_ratings_variance_fields'
  rating?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** columns and relationships of "learning_learner_reflections" */
export type Learning_Learner_Reflections = {
  __typename?: 'learning_learner_reflections'
  body: Scalars['String']
  created_at: Scalars['timestamptz']
  /** An object relationship */
  module: Learning_Modules
  module_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['Int']
}

/** aggregated selection of "learning_learner_reflections" */
export type Learning_Learner_Reflections_Aggregate = {
  __typename?: 'learning_learner_reflections_aggregate'
  aggregate?: Maybe<Learning_Learner_Reflections_Aggregate_Fields>
  nodes: Array<Learning_Learner_Reflections>
}

export type Learning_Learner_Reflections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Learning_Learner_Reflections_Aggregate_Bool_Exp_Count>
}

export type Learning_Learner_Reflections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Learner_Reflections_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Learner_Reflections_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_learner_reflections" */
export type Learning_Learner_Reflections_Aggregate_Fields = {
  __typename?: 'learning_learner_reflections_aggregate_fields'
  avg?: Maybe<Learning_Learner_Reflections_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Learner_Reflections_Max_Fields>
  min?: Maybe<Learning_Learner_Reflections_Min_Fields>
  stddev?: Maybe<Learning_Learner_Reflections_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Learner_Reflections_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Learner_Reflections_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Learner_Reflections_Sum_Fields>
  var_pop?: Maybe<Learning_Learner_Reflections_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Learner_Reflections_Var_Samp_Fields>
  variance?: Maybe<Learning_Learner_Reflections_Variance_Fields>
}

/** aggregate fields of "learning_learner_reflections" */
export type Learning_Learner_Reflections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Learner_Reflections_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Learner_Reflections_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Learner_Reflections_Max_Order_By>
  min?: InputMaybe<Learning_Learner_Reflections_Min_Order_By>
  stddev?: InputMaybe<Learning_Learner_Reflections_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Learner_Reflections_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Learner_Reflections_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Learner_Reflections_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Learner_Reflections_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Learner_Reflections_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Learner_Reflections_Variance_Order_By>
}

/** input type for inserting array relation for remote table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Arr_Rel_Insert_Input = {
  data: Array<Learning_Learner_Reflections_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Learner_Reflections_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Learner_Reflections_Avg_Fields = {
  __typename?: 'learning_learner_reflections_avg_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Avg_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_learner_reflections". All fields are combined with a logical 'AND'. */
export type Learning_Learner_Reflections_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Learner_Reflections_Bool_Exp>>
  _not?: InputMaybe<Learning_Learner_Reflections_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Learner_Reflections_Bool_Exp>>
  body?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_id?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_learner_reflections" */
export enum Learning_Learner_Reflections_Constraint {
  /** unique or primary key constraint on columns "module_id", "user_id" */
  LearningLearnerReflectionsPkey = 'learning_learner_reflections_pkey',
}

/** input type for incrementing numeric columns in table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Inc_Input = {
  user_id?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Insert_Input = {
  body?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  module?: InputMaybe<Learning_Modules_Obj_Rel_Insert_Input>
  module_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Learning_Learner_Reflections_Max_Fields = {
  __typename?: 'learning_learner_reflections_max_fields'
  body?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  module_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Max_Order_By = {
  body?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Learner_Reflections_Min_Fields = {
  __typename?: 'learning_learner_reflections_min_fields'
  body?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  module_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Min_Order_By = {
  body?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Mutation_Response = {
  __typename?: 'learning_learner_reflections_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Learner_Reflections>
}

/** on_conflict condition type for table "learning_learner_reflections" */
export type Learning_Learner_Reflections_On_Conflict = {
  constraint: Learning_Learner_Reflections_Constraint
  update_columns?: Array<Learning_Learner_Reflections_Update_Column>
  where?: InputMaybe<Learning_Learner_Reflections_Bool_Exp>
}

/** Ordering options when selecting data from "learning_learner_reflections". */
export type Learning_Learner_Reflections_Order_By = {
  body?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_learner_reflections */
export type Learning_Learner_Reflections_Pk_Columns_Input = {
  module_id: Scalars['uuid']
  user_id: Scalars['Int']
}

/** select columns of table "learning_learner_reflections" */
export enum Learning_Learner_Reflections_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Set_Input = {
  body?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  module_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Learning_Learner_Reflections_Stddev_Fields = {
  __typename?: 'learning_learner_reflections_stddev_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Stddev_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Learner_Reflections_Stddev_Pop_Fields = {
  __typename?: 'learning_learner_reflections_stddev_pop_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Stddev_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Learner_Reflections_Stddev_Samp_Fields = {
  __typename?: 'learning_learner_reflections_stddev_samp_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Stddev_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Learner_Reflections_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Learner_Reflections_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  module_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Learning_Learner_Reflections_Sum_Fields = {
  __typename?: 'learning_learner_reflections_sum_fields'
  user_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Sum_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** update columns of table "learning_learner_reflections" */
export enum Learning_Learner_Reflections_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Learning_Learner_Reflections_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Learner_Reflections_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Learner_Reflections_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Learner_Reflections_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Learner_Reflections_Var_Pop_Fields = {
  __typename?: 'learning_learner_reflections_var_pop_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Var_Pop_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Learner_Reflections_Var_Samp_Fields = {
  __typename?: 'learning_learner_reflections_var_samp_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Var_Samp_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Learner_Reflections_Variance_Fields = {
  __typename?: 'learning_learner_reflections_variance_fields'
  user_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_learner_reflections" */
export type Learning_Learner_Reflections_Variance_Order_By = {
  user_id?: InputMaybe<Order_By>
}

/** columns and relationships of "learning_learner_session_events" */
export type Learning_Learner_Session_Events = {
  __typename?: 'learning_learner_session_events'
  actor_id?: Maybe<Scalars['Int']>
  event?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  session_id?: Maybe<Scalars['uuid']>
  time?: Maybe<Scalars['timestamptz']>
}

/** aggregated selection of "learning_learner_session_events" */
export type Learning_Learner_Session_Events_Aggregate = {
  __typename?: 'learning_learner_session_events_aggregate'
  aggregate?: Maybe<Learning_Learner_Session_Events_Aggregate_Fields>
  nodes: Array<Learning_Learner_Session_Events>
}

/** aggregate fields of "learning_learner_session_events" */
export type Learning_Learner_Session_Events_Aggregate_Fields = {
  __typename?: 'learning_learner_session_events_aggregate_fields'
  avg?: Maybe<Learning_Learner_Session_Events_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Learner_Session_Events_Max_Fields>
  min?: Maybe<Learning_Learner_Session_Events_Min_Fields>
  stddev?: Maybe<Learning_Learner_Session_Events_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Learner_Session_Events_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Learner_Session_Events_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Learner_Session_Events_Sum_Fields>
  var_pop?: Maybe<Learning_Learner_Session_Events_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Learner_Session_Events_Var_Samp_Fields>
  variance?: Maybe<Learning_Learner_Session_Events_Variance_Fields>
}

/** aggregate fields of "learning_learner_session_events" */
export type Learning_Learner_Session_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Learner_Session_Events_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Learning_Learner_Session_Events_Avg_Fields = {
  __typename?: 'learning_learner_session_events_avg_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_learner_session_events". All fields are combined with a logical 'AND'. */
export type Learning_Learner_Session_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Learner_Session_Events_Bool_Exp>>
  _not?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Learner_Session_Events_Bool_Exp>>
  actor_id?: InputMaybe<Int_Comparison_Exp>
  event?: InputMaybe<String_Comparison_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  session_id?: InputMaybe<Uuid_Comparison_Exp>
  time?: InputMaybe<Timestamptz_Comparison_Exp>
}

export type Learning_Learner_Session_Events_By_Period_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  periodfield?: InputMaybe<Scalars['String']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Learning_Learner_Session_Events_Max_Fields = {
  __typename?: 'learning_learner_session_events_max_fields'
  actor_id?: Maybe<Scalars['Int']>
  event?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  session_id?: Maybe<Scalars['uuid']>
  time?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Learning_Learner_Session_Events_Min_Fields = {
  __typename?: 'learning_learner_session_events_min_fields'
  actor_id?: Maybe<Scalars['Int']>
  event?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  session_id?: Maybe<Scalars['uuid']>
  time?: Maybe<Scalars['timestamptz']>
}

/** Ordering options when selecting data from "learning_learner_session_events". */
export type Learning_Learner_Session_Events_Order_By = {
  actor_id?: InputMaybe<Order_By>
  event?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  session_id?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** select columns of table "learning_learner_session_events" */
export enum Learning_Learner_Session_Events_Select_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  Event = 'event',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  SessionId = 'session_id',
  /** column name */
  Time = 'time',
}

/** aggregate stddev on columns */
export type Learning_Learner_Session_Events_Stddev_Fields = {
  __typename?: 'learning_learner_session_events_stddev_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Learner_Session_Events_Stddev_Pop_Fields = {
  __typename?: 'learning_learner_session_events_stddev_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Learner_Session_Events_Stddev_Samp_Fields = {
  __typename?: 'learning_learner_session_events_stddev_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_learner_session_events" */
export type Learning_Learner_Session_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Learner_Session_Events_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Learner_Session_Events_Stream_Cursor_Value_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  event?: InputMaybe<Scalars['String']>
  module_id?: InputMaybe<Scalars['uuid']>
  session_id?: InputMaybe<Scalars['uuid']>
  time?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Learning_Learner_Session_Events_Sum_Fields = {
  __typename?: 'learning_learner_session_events_sum_fields'
  actor_id?: Maybe<Scalars['Int']>
}

/** aggregate var_pop on columns */
export type Learning_Learner_Session_Events_Var_Pop_Fields = {
  __typename?: 'learning_learner_session_events_var_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Learner_Session_Events_Var_Samp_Fields = {
  __typename?: 'learning_learner_session_events_var_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Learner_Session_Events_Variance_Fields = {
  __typename?: 'learning_learner_session_events_variance_fields'
  actor_id?: Maybe<Scalars['Float']>
}

export type Learning_Learner_Session_Events_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

/** columns and relationships of "learning_learner_session_feedback" */
export type Learning_Learner_Session_Feedback = {
  __typename?: 'learning_learner_session_feedback'
  answers?: Maybe<Scalars['json']>
  feedback?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  page_id?: Maybe<Scalars['String']>
  /** An object relationship */
  question?: Maybe<Learning_Questions>
  question_id?: Maybe<Scalars['uuid']>
  revision_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  session?: Maybe<Learning_Learner_Sessions>
  session_id?: Maybe<Scalars['uuid']>
}

/** columns and relationships of "learning_learner_session_feedback" */
export type Learning_Learner_Session_FeedbackAnswersArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "learning_learner_session_feedback" */
export type Learning_Learner_Session_Feedback_Aggregate = {
  __typename?: 'learning_learner_session_feedback_aggregate'
  aggregate?: Maybe<Learning_Learner_Session_Feedback_Aggregate_Fields>
  nodes: Array<Learning_Learner_Session_Feedback>
}

export type Learning_Learner_Session_Feedback_Aggregate_Bool_Exp = {
  count?: InputMaybe<Learning_Learner_Session_Feedback_Aggregate_Bool_Exp_Count>
}

export type Learning_Learner_Session_Feedback_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Learner_Session_Feedback_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Learner_Session_Feedback_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_learner_session_feedback" */
export type Learning_Learner_Session_Feedback_Aggregate_Fields = {
  __typename?: 'learning_learner_session_feedback_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Learning_Learner_Session_Feedback_Max_Fields>
  min?: Maybe<Learning_Learner_Session_Feedback_Min_Fields>
}

/** aggregate fields of "learning_learner_session_feedback" */
export type Learning_Learner_Session_Feedback_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Learner_Session_Feedback_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_learner_session_feedback" */
export type Learning_Learner_Session_Feedback_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Learner_Session_Feedback_Max_Order_By>
  min?: InputMaybe<Learning_Learner_Session_Feedback_Min_Order_By>
}

/** input type for inserting array relation for remote table "learning_learner_session_feedback" */
export type Learning_Learner_Session_Feedback_Arr_Rel_Insert_Input = {
  data: Array<Learning_Learner_Session_Feedback_Insert_Input>
}

/** Boolean expression to filter rows from the table "learning_learner_session_feedback". All fields are combined with a logical 'AND'. */
export type Learning_Learner_Session_Feedback_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Learner_Session_Feedback_Bool_Exp>>
  _not?: InputMaybe<Learning_Learner_Session_Feedback_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Learner_Session_Feedback_Bool_Exp>>
  answers?: InputMaybe<Json_Comparison_Exp>
  feedback?: InputMaybe<String_Comparison_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  page_id?: InputMaybe<String_Comparison_Exp>
  question?: InputMaybe<Learning_Questions_Bool_Exp>
  question_id?: InputMaybe<Uuid_Comparison_Exp>
  revision_id?: InputMaybe<Uuid_Comparison_Exp>
  session?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
  session_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** input type for inserting data into table "learning_learner_session_feedback" */
export type Learning_Learner_Session_Feedback_Insert_Input = {
  answers?: InputMaybe<Scalars['json']>
  feedback?: InputMaybe<Scalars['String']>
  module_id?: InputMaybe<Scalars['uuid']>
  page_id?: InputMaybe<Scalars['String']>
  question?: InputMaybe<Learning_Questions_Obj_Rel_Insert_Input>
  question_id?: InputMaybe<Scalars['uuid']>
  revision_id?: InputMaybe<Scalars['uuid']>
  session?: InputMaybe<Learning_Learner_Sessions_Obj_Rel_Insert_Input>
  session_id?: InputMaybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Learning_Learner_Session_Feedback_Max_Fields = {
  __typename?: 'learning_learner_session_feedback_max_fields'
  feedback?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  page_id?: Maybe<Scalars['String']>
  question_id?: Maybe<Scalars['uuid']>
  revision_id?: Maybe<Scalars['uuid']>
  session_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "learning_learner_session_feedback" */
export type Learning_Learner_Session_Feedback_Max_Order_By = {
  feedback?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  session_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Learner_Session_Feedback_Min_Fields = {
  __typename?: 'learning_learner_session_feedback_min_fields'
  feedback?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  page_id?: Maybe<Scalars['String']>
  question_id?: Maybe<Scalars['uuid']>
  revision_id?: Maybe<Scalars['uuid']>
  session_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "learning_learner_session_feedback" */
export type Learning_Learner_Session_Feedback_Min_Order_By = {
  feedback?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  session_id?: InputMaybe<Order_By>
}

/** Ordering options when selecting data from "learning_learner_session_feedback". */
export type Learning_Learner_Session_Feedback_Order_By = {
  answers?: InputMaybe<Order_By>
  feedback?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
  question?: InputMaybe<Learning_Questions_Order_By>
  question_id?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  session?: InputMaybe<Learning_Learner_Sessions_Order_By>
  session_id?: InputMaybe<Order_By>
}

/** select columns of table "learning_learner_session_feedback" */
export enum Learning_Learner_Session_Feedback_Select_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  PageId = 'page_id',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  RevisionId = 'revision_id',
  /** column name */
  SessionId = 'session_id',
}

/** Streaming cursor of the table "learning_learner_session_feedback" */
export type Learning_Learner_Session_Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Learner_Session_Feedback_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Learner_Session_Feedback_Stream_Cursor_Value_Input = {
  answers?: InputMaybe<Scalars['json']>
  feedback?: InputMaybe<Scalars['String']>
  module_id?: InputMaybe<Scalars['uuid']>
  page_id?: InputMaybe<Scalars['String']>
  question_id?: InputMaybe<Scalars['uuid']>
  revision_id?: InputMaybe<Scalars['uuid']>
  session_id?: InputMaybe<Scalars['uuid']>
}

/** columns and relationships of "learning_learner_sessions" */
export type Learning_Learner_Sessions = {
  __typename?: 'learning_learner_sessions'
  active: Scalars['Boolean']
  /** An object relationship */
  actor: Users
  actor_id: Scalars['Int']
  /** An array relationship */
  events: Array<Learning_Learner_Event_Log>
  /** An aggregate relationship */
  events_aggregate: Learning_Learner_Event_Log_Aggregate
  finished_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  /** A computed field, executes function "learning_learner_sessions_is_latest_session" */
  is_latest_session?: Maybe<Scalars['Boolean']>
  last_event_at: Scalars['timestamptz']
  marks?: Maybe<Scalars['Int']>
  marks_available?: Maybe<Scalars['Int']>
  /** An object relationship */
  module?: Maybe<Learning_Modules>
  module_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  rating?: Maybe<Learning_Learner_Module_Ratings>
  /** An array relationship */
  responses: Array<Learning_Learner_Session_Feedback>
  /** An aggregate relationship */
  responses_aggregate: Learning_Learner_Session_Feedback_Aggregate
  /** An object relationship */
  revision: Learning_Module_Revisions
  revision_id: Scalars['uuid']
  started_at: Scalars['timestamptz']
  /** A computed field, executes function "learning_series_session_get_pass_status" */
  status?: Maybe<Scalars['String']>
}

/** columns and relationships of "learning_learner_sessions" */
export type Learning_Learner_SessionsEventsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Learner_Event_Log_Bool_Exp>
}

/** columns and relationships of "learning_learner_sessions" */
export type Learning_Learner_SessionsEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Learner_Event_Log_Bool_Exp>
}

/** columns and relationships of "learning_learner_sessions" */
export type Learning_Learner_SessionsResponsesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Session_Feedback_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Session_Feedback_Order_By>>
  where?: InputMaybe<Learning_Learner_Session_Feedback_Bool_Exp>
}

/** columns and relationships of "learning_learner_sessions" */
export type Learning_Learner_SessionsResponses_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Session_Feedback_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Session_Feedback_Order_By>>
  where?: InputMaybe<Learning_Learner_Session_Feedback_Bool_Exp>
}

/** aggregated selection of "learning_learner_sessions" */
export type Learning_Learner_Sessions_Aggregate = {
  __typename?: 'learning_learner_sessions_aggregate'
  aggregate?: Maybe<Learning_Learner_Sessions_Aggregate_Fields>
  nodes: Array<Learning_Learner_Sessions>
}

export type Learning_Learner_Sessions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Learning_Learner_Sessions_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Learning_Learner_Sessions_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Learning_Learner_Sessions_Aggregate_Bool_Exp_Count>
}

export type Learning_Learner_Sessions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Learning_Learner_Sessions_Select_Column_Learning_Learner_Sessions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Learning_Learner_Sessions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Learning_Learner_Sessions_Select_Column_Learning_Learner_Sessions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Learning_Learner_Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Learner_Sessions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_learner_sessions" */
export type Learning_Learner_Sessions_Aggregate_Fields = {
  __typename?: 'learning_learner_sessions_aggregate_fields'
  avg?: Maybe<Learning_Learner_Sessions_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Learner_Sessions_Max_Fields>
  min?: Maybe<Learning_Learner_Sessions_Min_Fields>
  stddev?: Maybe<Learning_Learner_Sessions_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Learner_Sessions_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Learner_Sessions_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Learner_Sessions_Sum_Fields>
  var_pop?: Maybe<Learning_Learner_Sessions_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Learner_Sessions_Var_Samp_Fields>
  variance?: Maybe<Learning_Learner_Sessions_Variance_Fields>
}

/** aggregate fields of "learning_learner_sessions" */
export type Learning_Learner_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Learner_Sessions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Learner_Sessions_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Learner_Sessions_Max_Order_By>
  min?: InputMaybe<Learning_Learner_Sessions_Min_Order_By>
  stddev?: InputMaybe<Learning_Learner_Sessions_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Learner_Sessions_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Learner_Sessions_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Learner_Sessions_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Learner_Sessions_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Learner_Sessions_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Learner_Sessions_Variance_Order_By>
}

/** input type for inserting array relation for remote table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Learning_Learner_Sessions_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Learner_Sessions_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Learner_Sessions_Avg_Fields = {
  __typename?: 'learning_learner_sessions_avg_fields'
  actor_id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
  marks_available?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Avg_Order_By = {
  actor_id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  marks_available?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_learner_sessions". All fields are combined with a logical 'AND'. */
export type Learning_Learner_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Learner_Sessions_Bool_Exp>>
  _not?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Learner_Sessions_Bool_Exp>>
  active?: InputMaybe<Boolean_Comparison_Exp>
  actor?: InputMaybe<Users_Bool_Exp>
  actor_id?: InputMaybe<Int_Comparison_Exp>
  events?: InputMaybe<Learning_Learner_Event_Log_Bool_Exp>
  events_aggregate?: InputMaybe<Learning_Learner_Event_Log_Aggregate_Bool_Exp>
  finished_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  is_latest_session?: InputMaybe<Boolean_Comparison_Exp>
  last_event_at?: InputMaybe<Timestamptz_Comparison_Exp>
  marks?: InputMaybe<Int_Comparison_Exp>
  marks_available?: InputMaybe<Int_Comparison_Exp>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  rating?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
  responses?: InputMaybe<Learning_Learner_Session_Feedback_Bool_Exp>
  responses_aggregate?: InputMaybe<Learning_Learner_Session_Feedback_Aggregate_Bool_Exp>
  revision?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
  revision_id?: InputMaybe<Uuid_Comparison_Exp>
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>
  status?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_learner_sessions" */
export enum Learning_Learner_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningLearnerSessionsPkey = 'learning_learner_sessions_pkey',
}

/** input type for incrementing numeric columns in table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Inc_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  marks?: InputMaybe<Scalars['Int']>
  marks_available?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>
  actor?: InputMaybe<Users_Obj_Rel_Insert_Input>
  actor_id?: InputMaybe<Scalars['Int']>
  events?: InputMaybe<Learning_Learner_Event_Log_Arr_Rel_Insert_Input>
  finished_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  last_event_at?: InputMaybe<Scalars['timestamptz']>
  marks?: InputMaybe<Scalars['Int']>
  marks_available?: InputMaybe<Scalars['Int']>
  module?: InputMaybe<Learning_Modules_Obj_Rel_Insert_Input>
  module_id?: InputMaybe<Scalars['uuid']>
  rating?: InputMaybe<Learning_Learner_Module_Ratings_Obj_Rel_Insert_Input>
  responses?: InputMaybe<Learning_Learner_Session_Feedback_Arr_Rel_Insert_Input>
  revision?: InputMaybe<Learning_Module_Revisions_Obj_Rel_Insert_Input>
  revision_id?: InputMaybe<Scalars['uuid']>
  started_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Learning_Learner_Sessions_Max_Fields = {
  __typename?: 'learning_learner_sessions_max_fields'
  actor_id?: Maybe<Scalars['Int']>
  finished_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_event_at?: Maybe<Scalars['timestamptz']>
  marks?: Maybe<Scalars['Int']>
  marks_available?: Maybe<Scalars['Int']>
  module_id?: Maybe<Scalars['uuid']>
  revision_id?: Maybe<Scalars['uuid']>
  started_at?: Maybe<Scalars['timestamptz']>
  /** A computed field, executes function "learning_series_session_get_pass_status" */
  status?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Max_Order_By = {
  actor_id?: InputMaybe<Order_By>
  finished_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  last_event_at?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  marks_available?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  started_at?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Learner_Sessions_Min_Fields = {
  __typename?: 'learning_learner_sessions_min_fields'
  actor_id?: Maybe<Scalars['Int']>
  finished_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_event_at?: Maybe<Scalars['timestamptz']>
  marks?: Maybe<Scalars['Int']>
  marks_available?: Maybe<Scalars['Int']>
  module_id?: Maybe<Scalars['uuid']>
  revision_id?: Maybe<Scalars['uuid']>
  started_at?: Maybe<Scalars['timestamptz']>
  /** A computed field, executes function "learning_series_session_get_pass_status" */
  status?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Min_Order_By = {
  actor_id?: InputMaybe<Order_By>
  finished_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  last_event_at?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  marks_available?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  started_at?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Mutation_Response = {
  __typename?: 'learning_learner_sessions_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Learner_Sessions>
}

/** input type for inserting object relation for remote table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Obj_Rel_Insert_Input = {
  data: Learning_Learner_Sessions_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Learner_Sessions_On_Conflict>
}

/** on_conflict condition type for table "learning_learner_sessions" */
export type Learning_Learner_Sessions_On_Conflict = {
  constraint: Learning_Learner_Sessions_Constraint
  update_columns?: Array<Learning_Learner_Sessions_Update_Column>
  where?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
}

/** Ordering options when selecting data from "learning_learner_sessions". */
export type Learning_Learner_Sessions_Order_By = {
  active?: InputMaybe<Order_By>
  actor?: InputMaybe<Users_Order_By>
  actor_id?: InputMaybe<Order_By>
  events_aggregate?: InputMaybe<Learning_Learner_Event_Log_Aggregate_Order_By>
  finished_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  is_latest_session?: InputMaybe<Order_By>
  last_event_at?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  marks_available?: InputMaybe<Order_By>
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  rating?: InputMaybe<Learning_Learner_Module_Ratings_Order_By>
  responses_aggregate?: InputMaybe<Learning_Learner_Session_Feedback_Aggregate_Order_By>
  revision?: InputMaybe<Learning_Module_Revisions_Order_By>
  revision_id?: InputMaybe<Order_By>
  started_at?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_learner_sessions */
export type Learning_Learner_Sessions_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "learning_learner_sessions" */
export enum Learning_Learner_Sessions_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastEventAt = 'last_event_at',
  /** column name */
  Marks = 'marks',
  /** column name */
  MarksAvailable = 'marks_available',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  RevisionId = 'revision_id',
  /** column name */
  StartedAt = 'started_at',
}

/** select "learning_learner_sessions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "learning_learner_sessions" */
export enum Learning_Learner_Sessions_Select_Column_Learning_Learner_Sessions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** select "learning_learner_sessions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "learning_learner_sessions" */
export enum Learning_Learner_Sessions_Select_Column_Learning_Learner_Sessions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** input type for updating data in table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>
  actor_id?: InputMaybe<Scalars['Int']>
  finished_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  last_event_at?: InputMaybe<Scalars['timestamptz']>
  marks?: InputMaybe<Scalars['Int']>
  marks_available?: InputMaybe<Scalars['Int']>
  module_id?: InputMaybe<Scalars['uuid']>
  revision_id?: InputMaybe<Scalars['uuid']>
  started_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Learning_Learner_Sessions_Stddev_Fields = {
  __typename?: 'learning_learner_sessions_stddev_fields'
  actor_id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
  marks_available?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Stddev_Order_By = {
  actor_id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  marks_available?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Learner_Sessions_Stddev_Pop_Fields = {
  __typename?: 'learning_learner_sessions_stddev_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
  marks_available?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Stddev_Pop_Order_By = {
  actor_id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  marks_available?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Learner_Sessions_Stddev_Samp_Fields = {
  __typename?: 'learning_learner_sessions_stddev_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
  marks_available?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Stddev_Samp_Order_By = {
  actor_id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  marks_available?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Learner_Sessions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Learner_Sessions_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>
  actor_id?: InputMaybe<Scalars['Int']>
  finished_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['uuid']>
  last_event_at?: InputMaybe<Scalars['timestamptz']>
  marks?: InputMaybe<Scalars['Int']>
  marks_available?: InputMaybe<Scalars['Int']>
  module_id?: InputMaybe<Scalars['uuid']>
  revision_id?: InputMaybe<Scalars['uuid']>
  started_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Learning_Learner_Sessions_Sum_Fields = {
  __typename?: 'learning_learner_sessions_sum_fields'
  actor_id?: Maybe<Scalars['Int']>
  marks?: Maybe<Scalars['Int']>
  marks_available?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Sum_Order_By = {
  actor_id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  marks_available?: InputMaybe<Order_By>
}

/** update columns of table "learning_learner_sessions" */
export enum Learning_Learner_Sessions_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  FinishedAt = 'finished_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastEventAt = 'last_event_at',
  /** column name */
  Marks = 'marks',
  /** column name */
  MarksAvailable = 'marks_available',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  RevisionId = 'revision_id',
  /** column name */
  StartedAt = 'started_at',
}

export type Learning_Learner_Sessions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Learner_Sessions_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Learner_Sessions_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Learner_Sessions_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Learner_Sessions_Var_Pop_Fields = {
  __typename?: 'learning_learner_sessions_var_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
  marks_available?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Var_Pop_Order_By = {
  actor_id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  marks_available?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Learner_Sessions_Var_Samp_Fields = {
  __typename?: 'learning_learner_sessions_var_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
  marks_available?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Var_Samp_Order_By = {
  actor_id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  marks_available?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Learner_Sessions_Variance_Fields = {
  __typename?: 'learning_learner_sessions_variance_fields'
  actor_id?: Maybe<Scalars['Float']>
  marks?: Maybe<Scalars['Float']>
  marks_available?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_learner_sessions" */
export type Learning_Learner_Sessions_Variance_Order_By = {
  actor_id?: InputMaybe<Order_By>
  marks?: InputMaybe<Order_By>
  marks_available?: InputMaybe<Order_By>
}

/** Boolean expression to compare columns of type "learning_module_event". All fields are combined with logical 'AND'. */
export type Learning_Module_Event_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_module_event']>
  _gt?: InputMaybe<Scalars['learning_module_event']>
  _gte?: InputMaybe<Scalars['learning_module_event']>
  _in?: InputMaybe<Array<Scalars['learning_module_event']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['learning_module_event']>
  _lte?: InputMaybe<Scalars['learning_module_event']>
  _neq?: InputMaybe<Scalars['learning_module_event']>
  _nin?: InputMaybe<Array<Scalars['learning_module_event']>>
}

/** columns and relationships of "learning_module_event_log" */
export type Learning_Module_Event_Log = {
  __typename?: 'learning_module_event_log'
  /** An object relationship */
  actor: Users
  actor_id: Scalars['Int']
  event: Scalars['learning_module_event']
  id: Scalars['uuid']
  message?: Maybe<Scalars['String']>
  /** An object relationship */
  module?: Maybe<Learning_Modules>
  module_id?: Maybe<Scalars['uuid']>
  next_state: Scalars['learning_module_status']
  /** An object relationship */
  revision?: Maybe<Learning_Module_Revisions>
  revision_id?: Maybe<Scalars['uuid']>
  state: Scalars['learning_module_status']
  time: Scalars['timestamptz']
}

/** aggregated selection of "learning_module_event_log" */
export type Learning_Module_Event_Log_Aggregate = {
  __typename?: 'learning_module_event_log_aggregate'
  aggregate?: Maybe<Learning_Module_Event_Log_Aggregate_Fields>
  nodes: Array<Learning_Module_Event_Log>
}

export type Learning_Module_Event_Log_Aggregate_Bool_Exp = {
  count?: InputMaybe<Learning_Module_Event_Log_Aggregate_Bool_Exp_Count>
}

export type Learning_Module_Event_Log_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Module_Event_Log_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_module_event_log" */
export type Learning_Module_Event_Log_Aggregate_Fields = {
  __typename?: 'learning_module_event_log_aggregate_fields'
  avg?: Maybe<Learning_Module_Event_Log_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Module_Event_Log_Max_Fields>
  min?: Maybe<Learning_Module_Event_Log_Min_Fields>
  stddev?: Maybe<Learning_Module_Event_Log_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Module_Event_Log_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Module_Event_Log_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Module_Event_Log_Sum_Fields>
  var_pop?: Maybe<Learning_Module_Event_Log_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Module_Event_Log_Var_Samp_Fields>
  variance?: Maybe<Learning_Module_Event_Log_Variance_Fields>
}

/** aggregate fields of "learning_module_event_log" */
export type Learning_Module_Event_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Module_Event_Log_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_module_event_log" */
export type Learning_Module_Event_Log_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Module_Event_Log_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Module_Event_Log_Max_Order_By>
  min?: InputMaybe<Learning_Module_Event_Log_Min_Order_By>
  stddev?: InputMaybe<Learning_Module_Event_Log_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Module_Event_Log_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Module_Event_Log_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Module_Event_Log_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Module_Event_Log_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Module_Event_Log_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Module_Event_Log_Variance_Order_By>
}

/** input type for inserting array relation for remote table "learning_module_event_log" */
export type Learning_Module_Event_Log_Arr_Rel_Insert_Input = {
  data: Array<Learning_Module_Event_Log_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Module_Event_Log_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Module_Event_Log_Avg_Fields = {
  __typename?: 'learning_module_event_log_avg_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_module_event_log" */
export type Learning_Module_Event_Log_Avg_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_module_event_log". All fields are combined with a logical 'AND'. */
export type Learning_Module_Event_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Module_Event_Log_Bool_Exp>>
  _not?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Module_Event_Log_Bool_Exp>>
  actor?: InputMaybe<Users_Bool_Exp>
  actor_id?: InputMaybe<Int_Comparison_Exp>
  event?: InputMaybe<Learning_Module_Event_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  message?: InputMaybe<String_Comparison_Exp>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  next_state?: InputMaybe<Learning_Module_Status_Comparison_Exp>
  revision?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
  revision_id?: InputMaybe<Uuid_Comparison_Exp>
  state?: InputMaybe<Learning_Module_Status_Comparison_Exp>
  time?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_module_event_log" */
export enum Learning_Module_Event_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningModuleEventLogPkey = 'learning_module_event_log_pkey',
}

/** input type for incrementing numeric columns in table "learning_module_event_log" */
export type Learning_Module_Event_Log_Inc_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_module_event_log" */
export type Learning_Module_Event_Log_Insert_Input = {
  actor?: InputMaybe<Users_Obj_Rel_Insert_Input>
  actor_id?: InputMaybe<Scalars['Int']>
  event?: InputMaybe<Scalars['learning_module_event']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  module?: InputMaybe<Learning_Modules_Obj_Rel_Insert_Input>
  module_id?: InputMaybe<Scalars['uuid']>
  next_state?: InputMaybe<Scalars['learning_module_status']>
  revision?: InputMaybe<Learning_Module_Revisions_Obj_Rel_Insert_Input>
  revision_id?: InputMaybe<Scalars['uuid']>
  state?: InputMaybe<Scalars['learning_module_status']>
  time?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Learning_Module_Event_Log_Max_Fields = {
  __typename?: 'learning_module_event_log_max_fields'
  actor_id?: Maybe<Scalars['Int']>
  event?: Maybe<Scalars['learning_module_event']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  next_state?: Maybe<Scalars['learning_module_status']>
  revision_id?: Maybe<Scalars['uuid']>
  state?: Maybe<Scalars['learning_module_status']>
  time?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "learning_module_event_log" */
export type Learning_Module_Event_Log_Max_Order_By = {
  actor_id?: InputMaybe<Order_By>
  event?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  next_state?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  state?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Module_Event_Log_Min_Fields = {
  __typename?: 'learning_module_event_log_min_fields'
  actor_id?: Maybe<Scalars['Int']>
  event?: Maybe<Scalars['learning_module_event']>
  id?: Maybe<Scalars['uuid']>
  message?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  next_state?: Maybe<Scalars['learning_module_status']>
  revision_id?: Maybe<Scalars['uuid']>
  state?: Maybe<Scalars['learning_module_status']>
  time?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "learning_module_event_log" */
export type Learning_Module_Event_Log_Min_Order_By = {
  actor_id?: InputMaybe<Order_By>
  event?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  next_state?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  state?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_module_event_log" */
export type Learning_Module_Event_Log_Mutation_Response = {
  __typename?: 'learning_module_event_log_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Module_Event_Log>
}

/** input type for inserting object relation for remote table "learning_module_event_log" */
export type Learning_Module_Event_Log_Obj_Rel_Insert_Input = {
  data: Learning_Module_Event_Log_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Module_Event_Log_On_Conflict>
}

/** on_conflict condition type for table "learning_module_event_log" */
export type Learning_Module_Event_Log_On_Conflict = {
  constraint: Learning_Module_Event_Log_Constraint
  update_columns?: Array<Learning_Module_Event_Log_Update_Column>
  where?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
}

/** Ordering options when selecting data from "learning_module_event_log". */
export type Learning_Module_Event_Log_Order_By = {
  actor?: InputMaybe<Users_Order_By>
  actor_id?: InputMaybe<Order_By>
  event?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  message?: InputMaybe<Order_By>
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  next_state?: InputMaybe<Order_By>
  revision?: InputMaybe<Learning_Module_Revisions_Order_By>
  revision_id?: InputMaybe<Order_By>
  state?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_module_event_log */
export type Learning_Module_Event_Log_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "learning_module_event_log" */
export enum Learning_Module_Event_Log_Select_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  NextState = 'next_state',
  /** column name */
  RevisionId = 'revision_id',
  /** column name */
  State = 'state',
  /** column name */
  Time = 'time',
}

/** input type for updating data in table "learning_module_event_log" */
export type Learning_Module_Event_Log_Set_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  event?: InputMaybe<Scalars['learning_module_event']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  module_id?: InputMaybe<Scalars['uuid']>
  next_state?: InputMaybe<Scalars['learning_module_status']>
  revision_id?: InputMaybe<Scalars['uuid']>
  state?: InputMaybe<Scalars['learning_module_status']>
  time?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Learning_Module_Event_Log_Stddev_Fields = {
  __typename?: 'learning_module_event_log_stddev_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_module_event_log" */
export type Learning_Module_Event_Log_Stddev_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Module_Event_Log_Stddev_Pop_Fields = {
  __typename?: 'learning_module_event_log_stddev_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_module_event_log" */
export type Learning_Module_Event_Log_Stddev_Pop_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Module_Event_Log_Stddev_Samp_Fields = {
  __typename?: 'learning_module_event_log_stddev_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_module_event_log" */
export type Learning_Module_Event_Log_Stddev_Samp_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_module_event_log" */
export type Learning_Module_Event_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Module_Event_Log_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Module_Event_Log_Stream_Cursor_Value_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  event?: InputMaybe<Scalars['learning_module_event']>
  id?: InputMaybe<Scalars['uuid']>
  message?: InputMaybe<Scalars['String']>
  module_id?: InputMaybe<Scalars['uuid']>
  next_state?: InputMaybe<Scalars['learning_module_status']>
  revision_id?: InputMaybe<Scalars['uuid']>
  state?: InputMaybe<Scalars['learning_module_status']>
  time?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Learning_Module_Event_Log_Sum_Fields = {
  __typename?: 'learning_module_event_log_sum_fields'
  actor_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_module_event_log" */
export type Learning_Module_Event_Log_Sum_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** update columns of table "learning_module_event_log" */
export enum Learning_Module_Event_Log_Update_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  NextState = 'next_state',
  /** column name */
  RevisionId = 'revision_id',
  /** column name */
  State = 'state',
  /** column name */
  Time = 'time',
}

export type Learning_Module_Event_Log_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Module_Event_Log_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Module_Event_Log_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Module_Event_Log_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Module_Event_Log_Var_Pop_Fields = {
  __typename?: 'learning_module_event_log_var_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_module_event_log" */
export type Learning_Module_Event_Log_Var_Pop_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Module_Event_Log_Var_Samp_Fields = {
  __typename?: 'learning_module_event_log_var_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_module_event_log" */
export type Learning_Module_Event_Log_Var_Samp_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Module_Event_Log_Variance_Fields = {
  __typename?: 'learning_module_event_log_variance_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_module_event_log" */
export type Learning_Module_Event_Log_Variance_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** columns and relationships of "learning_module_event_transitions" */
export type Learning_Module_Event_Transitions = {
  __typename?: 'learning_module_event_transitions'
  event: Scalars['learning_module_event']
  next_state: Scalars['learning_module_status']
  state: Scalars['learning_module_status']
}

/** aggregated selection of "learning_module_event_transitions" */
export type Learning_Module_Event_Transitions_Aggregate = {
  __typename?: 'learning_module_event_transitions_aggregate'
  aggregate?: Maybe<Learning_Module_Event_Transitions_Aggregate_Fields>
  nodes: Array<Learning_Module_Event_Transitions>
}

/** aggregate fields of "learning_module_event_transitions" */
export type Learning_Module_Event_Transitions_Aggregate_Fields = {
  __typename?: 'learning_module_event_transitions_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Learning_Module_Event_Transitions_Max_Fields>
  min?: Maybe<Learning_Module_Event_Transitions_Min_Fields>
}

/** aggregate fields of "learning_module_event_transitions" */
export type Learning_Module_Event_Transitions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Module_Event_Transitions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "learning_module_event_transitions". All fields are combined with a logical 'AND'. */
export type Learning_Module_Event_Transitions_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Module_Event_Transitions_Bool_Exp>>
  _not?: InputMaybe<Learning_Module_Event_Transitions_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Module_Event_Transitions_Bool_Exp>>
  event?: InputMaybe<Learning_Module_Event_Comparison_Exp>
  next_state?: InputMaybe<Learning_Module_Status_Comparison_Exp>
  state?: InputMaybe<Learning_Module_Status_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_module_event_transitions" */
export enum Learning_Module_Event_Transitions_Constraint {
  /** unique or primary key constraint on columns "state", "event" */
  LearningModuleEventTransitionsPkey = 'learning_module_event_transitions_pkey',
}

/** input type for inserting data into table "learning_module_event_transitions" */
export type Learning_Module_Event_Transitions_Insert_Input = {
  event?: InputMaybe<Scalars['learning_module_event']>
  next_state?: InputMaybe<Scalars['learning_module_status']>
  state?: InputMaybe<Scalars['learning_module_status']>
}

/** aggregate max on columns */
export type Learning_Module_Event_Transitions_Max_Fields = {
  __typename?: 'learning_module_event_transitions_max_fields'
  event?: Maybe<Scalars['learning_module_event']>
  next_state?: Maybe<Scalars['learning_module_status']>
  state?: Maybe<Scalars['learning_module_status']>
}

/** aggregate min on columns */
export type Learning_Module_Event_Transitions_Min_Fields = {
  __typename?: 'learning_module_event_transitions_min_fields'
  event?: Maybe<Scalars['learning_module_event']>
  next_state?: Maybe<Scalars['learning_module_status']>
  state?: Maybe<Scalars['learning_module_status']>
}

/** response of any mutation on the table "learning_module_event_transitions" */
export type Learning_Module_Event_Transitions_Mutation_Response = {
  __typename?: 'learning_module_event_transitions_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Module_Event_Transitions>
}

/** on_conflict condition type for table "learning_module_event_transitions" */
export type Learning_Module_Event_Transitions_On_Conflict = {
  constraint: Learning_Module_Event_Transitions_Constraint
  update_columns?: Array<Learning_Module_Event_Transitions_Update_Column>
  where?: InputMaybe<Learning_Module_Event_Transitions_Bool_Exp>
}

/** Ordering options when selecting data from "learning_module_event_transitions". */
export type Learning_Module_Event_Transitions_Order_By = {
  event?: InputMaybe<Order_By>
  next_state?: InputMaybe<Order_By>
  state?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_module_event_transitions */
export type Learning_Module_Event_Transitions_Pk_Columns_Input = {
  event: Scalars['learning_module_event']
  state: Scalars['learning_module_status']
}

/** select columns of table "learning_module_event_transitions" */
export enum Learning_Module_Event_Transitions_Select_Column {
  /** column name */
  Event = 'event',
  /** column name */
  NextState = 'next_state',
  /** column name */
  State = 'state',
}

/** input type for updating data in table "learning_module_event_transitions" */
export type Learning_Module_Event_Transitions_Set_Input = {
  event?: InputMaybe<Scalars['learning_module_event']>
  next_state?: InputMaybe<Scalars['learning_module_status']>
  state?: InputMaybe<Scalars['learning_module_status']>
}

/** Streaming cursor of the table "learning_module_event_transitions" */
export type Learning_Module_Event_Transitions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Module_Event_Transitions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Module_Event_Transitions_Stream_Cursor_Value_Input = {
  event?: InputMaybe<Scalars['learning_module_event']>
  next_state?: InputMaybe<Scalars['learning_module_status']>
  state?: InputMaybe<Scalars['learning_module_status']>
}

/** update columns of table "learning_module_event_transitions" */
export enum Learning_Module_Event_Transitions_Update_Column {
  /** column name */
  Event = 'event',
  /** column name */
  NextState = 'next_state',
  /** column name */
  State = 'state',
}

export type Learning_Module_Event_Transitions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Module_Event_Transitions_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Module_Event_Transitions_Bool_Exp
}

/** columns and relationships of "learning_module_exists" */
export type Learning_Module_Exists = {
  __typename?: 'learning_module_exists'
  id?: Maybe<Scalars['uuid']>
  is_published?: Maybe<Scalars['Boolean']>
  slug?: Maybe<Scalars['String']>
}

/** aggregated selection of "learning_module_exists" */
export type Learning_Module_Exists_Aggregate = {
  __typename?: 'learning_module_exists_aggregate'
  aggregate?: Maybe<Learning_Module_Exists_Aggregate_Fields>
  nodes: Array<Learning_Module_Exists>
}

/** aggregate fields of "learning_module_exists" */
export type Learning_Module_Exists_Aggregate_Fields = {
  __typename?: 'learning_module_exists_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Learning_Module_Exists_Max_Fields>
  min?: Maybe<Learning_Module_Exists_Min_Fields>
}

/** aggregate fields of "learning_module_exists" */
export type Learning_Module_Exists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Module_Exists_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "learning_module_exists". All fields are combined with a logical 'AND'. */
export type Learning_Module_Exists_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Module_Exists_Bool_Exp>>
  _not?: InputMaybe<Learning_Module_Exists_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Module_Exists_Bool_Exp>>
  id?: InputMaybe<Uuid_Comparison_Exp>
  is_published?: InputMaybe<Boolean_Comparison_Exp>
  slug?: InputMaybe<String_Comparison_Exp>
}

/** input type for inserting data into table "learning_module_exists" */
export type Learning_Module_Exists_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>
  is_published?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Learning_Module_Exists_Max_Fields = {
  __typename?: 'learning_module_exists_max_fields'
  id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Learning_Module_Exists_Min_Fields = {
  __typename?: 'learning_module_exists_min_fields'
  id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "learning_module_exists" */
export type Learning_Module_Exists_Mutation_Response = {
  __typename?: 'learning_module_exists_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Module_Exists>
}

/** Ordering options when selecting data from "learning_module_exists". */
export type Learning_Module_Exists_Order_By = {
  id?: InputMaybe<Order_By>
  is_published?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
}

/** select columns of table "learning_module_exists" */
export enum Learning_Module_Exists_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  Slug = 'slug',
}

/** input type for updating data in table "learning_module_exists" */
export type Learning_Module_Exists_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>
  is_published?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "learning_module_exists" */
export type Learning_Module_Exists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Module_Exists_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Module_Exists_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>
  is_published?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
}

export type Learning_Module_Exists_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Module_Exists_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Module_Exists_Bool_Exp
}

/** columns and relationships of "learning_module_history" */
export type Learning_Module_History = {
  __typename?: 'learning_module_history'
  /** An object relationship */
  actor?: Maybe<Users>
  actor_id?: Maybe<Scalars['Int']>
  event: Scalars['String']
  id: Scalars['uuid']
  /** An object relationship */
  module: Learning_Modules
  module_id: Scalars['uuid']
  properties: Scalars['jsonb']
  /** An object relationship */
  revision: Learning_Module_Revisions
  revision_id: Scalars['uuid']
  time: Scalars['timestamptz']
}

/** columns and relationships of "learning_module_history" */
export type Learning_Module_HistoryPropertiesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "learning_module_history" */
export type Learning_Module_History_Aggregate = {
  __typename?: 'learning_module_history_aggregate'
  aggregate?: Maybe<Learning_Module_History_Aggregate_Fields>
  nodes: Array<Learning_Module_History>
}

export type Learning_Module_History_Aggregate_Bool_Exp = {
  count?: InputMaybe<Learning_Module_History_Aggregate_Bool_Exp_Count>
}

export type Learning_Module_History_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Module_History_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Module_History_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_module_history" */
export type Learning_Module_History_Aggregate_Fields = {
  __typename?: 'learning_module_history_aggregate_fields'
  avg?: Maybe<Learning_Module_History_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Module_History_Max_Fields>
  min?: Maybe<Learning_Module_History_Min_Fields>
  stddev?: Maybe<Learning_Module_History_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Module_History_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Module_History_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Module_History_Sum_Fields>
  var_pop?: Maybe<Learning_Module_History_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Module_History_Var_Samp_Fields>
  variance?: Maybe<Learning_Module_History_Variance_Fields>
}

/** aggregate fields of "learning_module_history" */
export type Learning_Module_History_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Module_History_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_module_history" */
export type Learning_Module_History_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Module_History_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Module_History_Max_Order_By>
  min?: InputMaybe<Learning_Module_History_Min_Order_By>
  stddev?: InputMaybe<Learning_Module_History_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Module_History_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Module_History_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Module_History_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Module_History_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Module_History_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Module_History_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Learning_Module_History_Append_Input = {
  properties?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "learning_module_history" */
export type Learning_Module_History_Arr_Rel_Insert_Input = {
  data: Array<Learning_Module_History_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Module_History_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Module_History_Avg_Fields = {
  __typename?: 'learning_module_history_avg_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_module_history" */
export type Learning_Module_History_Avg_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_module_history". All fields are combined with a logical 'AND'. */
export type Learning_Module_History_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Module_History_Bool_Exp>>
  _not?: InputMaybe<Learning_Module_History_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Module_History_Bool_Exp>>
  actor?: InputMaybe<Users_Bool_Exp>
  actor_id?: InputMaybe<Int_Comparison_Exp>
  event?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  properties?: InputMaybe<Jsonb_Comparison_Exp>
  revision?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
  revision_id?: InputMaybe<Uuid_Comparison_Exp>
  time?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_module_history" */
export enum Learning_Module_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningModuleHistoryPkey = 'learning_module_history_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Learning_Module_History_Delete_At_Path_Input = {
  properties?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Learning_Module_History_Delete_Elem_Input = {
  properties?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Learning_Module_History_Delete_Key_Input = {
  properties?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "learning_module_history" */
export type Learning_Module_History_Inc_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_module_history" */
export type Learning_Module_History_Insert_Input = {
  actor?: InputMaybe<Users_Obj_Rel_Insert_Input>
  actor_id?: InputMaybe<Scalars['Int']>
  event?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  module?: InputMaybe<Learning_Modules_Obj_Rel_Insert_Input>
  module_id?: InputMaybe<Scalars['uuid']>
  properties?: InputMaybe<Scalars['jsonb']>
  revision?: InputMaybe<Learning_Module_Revisions_Obj_Rel_Insert_Input>
  revision_id?: InputMaybe<Scalars['uuid']>
  time?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Learning_Module_History_Max_Fields = {
  __typename?: 'learning_module_history_max_fields'
  actor_id?: Maybe<Scalars['Int']>
  event?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  module_id?: Maybe<Scalars['uuid']>
  revision_id?: Maybe<Scalars['uuid']>
  time?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "learning_module_history" */
export type Learning_Module_History_Max_Order_By = {
  actor_id?: InputMaybe<Order_By>
  event?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Module_History_Min_Fields = {
  __typename?: 'learning_module_history_min_fields'
  actor_id?: Maybe<Scalars['Int']>
  event?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  module_id?: Maybe<Scalars['uuid']>
  revision_id?: Maybe<Scalars['uuid']>
  time?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "learning_module_history" */
export type Learning_Module_History_Min_Order_By = {
  actor_id?: InputMaybe<Order_By>
  event?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_module_history" */
export type Learning_Module_History_Mutation_Response = {
  __typename?: 'learning_module_history_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Module_History>
}

/** on_conflict condition type for table "learning_module_history" */
export type Learning_Module_History_On_Conflict = {
  constraint: Learning_Module_History_Constraint
  update_columns?: Array<Learning_Module_History_Update_Column>
  where?: InputMaybe<Learning_Module_History_Bool_Exp>
}

/** Ordering options when selecting data from "learning_module_history". */
export type Learning_Module_History_Order_By = {
  actor?: InputMaybe<Users_Order_By>
  actor_id?: InputMaybe<Order_By>
  event?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  properties?: InputMaybe<Order_By>
  revision?: InputMaybe<Learning_Module_Revisions_Order_By>
  revision_id?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_module_history */
export type Learning_Module_History_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Learning_Module_History_Prepend_Input = {
  properties?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "learning_module_history" */
export enum Learning_Module_History_Select_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Properties = 'properties',
  /** column name */
  RevisionId = 'revision_id',
  /** column name */
  Time = 'time',
}

/** input type for updating data in table "learning_module_history" */
export type Learning_Module_History_Set_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  event?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  module_id?: InputMaybe<Scalars['uuid']>
  properties?: InputMaybe<Scalars['jsonb']>
  revision_id?: InputMaybe<Scalars['uuid']>
  time?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Learning_Module_History_Stddev_Fields = {
  __typename?: 'learning_module_history_stddev_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_module_history" */
export type Learning_Module_History_Stddev_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Module_History_Stddev_Pop_Fields = {
  __typename?: 'learning_module_history_stddev_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_module_history" */
export type Learning_Module_History_Stddev_Pop_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Module_History_Stddev_Samp_Fields = {
  __typename?: 'learning_module_history_stddev_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_module_history" */
export type Learning_Module_History_Stddev_Samp_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_module_history" */
export type Learning_Module_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Module_History_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Module_History_Stream_Cursor_Value_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  event?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  module_id?: InputMaybe<Scalars['uuid']>
  properties?: InputMaybe<Scalars['jsonb']>
  revision_id?: InputMaybe<Scalars['uuid']>
  time?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Learning_Module_History_Sum_Fields = {
  __typename?: 'learning_module_history_sum_fields'
  actor_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_module_history" */
export type Learning_Module_History_Sum_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** update columns of table "learning_module_history" */
export enum Learning_Module_History_Update_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  Event = 'event',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Properties = 'properties',
  /** column name */
  RevisionId = 'revision_id',
  /** column name */
  Time = 'time',
}

export type Learning_Module_History_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Learning_Module_History_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Learning_Module_History_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Learning_Module_History_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Learning_Module_History_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Module_History_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Learning_Module_History_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Module_History_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Module_History_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Module_History_Var_Pop_Fields = {
  __typename?: 'learning_module_history_var_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_module_history" */
export type Learning_Module_History_Var_Pop_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Module_History_Var_Samp_Fields = {
  __typename?: 'learning_module_history_var_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_module_history" */
export type Learning_Module_History_Var_Samp_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Module_History_Variance_Fields = {
  __typename?: 'learning_module_history_variance_fields'
  actor_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_module_history" */
export type Learning_Module_History_Variance_Order_By = {
  actor_id?: InputMaybe<Order_By>
}

/** columns and relationships of "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages = {
  __typename?: 'learning_module_revision_pages'
  is_preview: Scalars['Boolean']
  /** An object relationship */
  page: Learning_Pages
  page_id: Scalars['String']
  position: Scalars['Int']
  /** An object relationship */
  revision: Learning_Module_Revisions
  revision_id: Scalars['uuid']
}

/** aggregated selection of "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Aggregate = {
  __typename?: 'learning_module_revision_pages_aggregate'
  aggregate?: Maybe<Learning_Module_Revision_Pages_Aggregate_Fields>
  nodes: Array<Learning_Module_Revision_Pages>
}

export type Learning_Module_Revision_Pages_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Learning_Module_Revision_Pages_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Learning_Module_Revision_Pages_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Learning_Module_Revision_Pages_Aggregate_Bool_Exp_Count>
}

export type Learning_Module_Revision_Pages_Aggregate_Bool_Exp_Bool_And = {
  arguments: Learning_Module_Revision_Pages_Select_Column_Learning_Module_Revision_Pages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Learning_Module_Revision_Pages_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Learning_Module_Revision_Pages_Select_Column_Learning_Module_Revision_Pages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Learning_Module_Revision_Pages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Module_Revision_Pages_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Aggregate_Fields = {
  __typename?: 'learning_module_revision_pages_aggregate_fields'
  avg?: Maybe<Learning_Module_Revision_Pages_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Module_Revision_Pages_Max_Fields>
  min?: Maybe<Learning_Module_Revision_Pages_Min_Fields>
  stddev?: Maybe<Learning_Module_Revision_Pages_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Module_Revision_Pages_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Module_Revision_Pages_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Module_Revision_Pages_Sum_Fields>
  var_pop?: Maybe<Learning_Module_Revision_Pages_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Module_Revision_Pages_Var_Samp_Fields>
  variance?: Maybe<Learning_Module_Revision_Pages_Variance_Fields>
}

/** aggregate fields of "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Module_Revision_Pages_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Module_Revision_Pages_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Module_Revision_Pages_Max_Order_By>
  min?: InputMaybe<Learning_Module_Revision_Pages_Min_Order_By>
  stddev?: InputMaybe<Learning_Module_Revision_Pages_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Module_Revision_Pages_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Module_Revision_Pages_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Module_Revision_Pages_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Module_Revision_Pages_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Module_Revision_Pages_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Module_Revision_Pages_Variance_Order_By>
}

/** input type for inserting array relation for remote table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Arr_Rel_Insert_Input = {
  data: Array<Learning_Module_Revision_Pages_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Module_Revision_Pages_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Module_Revision_Pages_Avg_Fields = {
  __typename?: 'learning_module_revision_pages_avg_fields'
  position?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Avg_Order_By = {
  position?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_module_revision_pages". All fields are combined with a logical 'AND'. */
export type Learning_Module_Revision_Pages_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Module_Revision_Pages_Bool_Exp>>
  _not?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Module_Revision_Pages_Bool_Exp>>
  is_preview?: InputMaybe<Boolean_Comparison_Exp>
  page?: InputMaybe<Learning_Pages_Bool_Exp>
  page_id?: InputMaybe<String_Comparison_Exp>
  position?: InputMaybe<Int_Comparison_Exp>
  revision?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
  revision_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_module_revision_pages" */
export enum Learning_Module_Revision_Pages_Constraint {
  /** unique or primary key constraint on columns "revision_id", "page_id" */
  LearningModuleRevisionPagesPkey = 'learning_module_revision_pages_pkey',
}

/** input type for incrementing numeric columns in table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Inc_Input = {
  position?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Insert_Input = {
  is_preview?: InputMaybe<Scalars['Boolean']>
  page?: InputMaybe<Learning_Pages_Obj_Rel_Insert_Input>
  page_id?: InputMaybe<Scalars['String']>
  position?: InputMaybe<Scalars['Int']>
  revision?: InputMaybe<Learning_Module_Revisions_Obj_Rel_Insert_Input>
  revision_id?: InputMaybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Learning_Module_Revision_Pages_Max_Fields = {
  __typename?: 'learning_module_revision_pages_max_fields'
  page_id?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['Int']>
  revision_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Max_Order_By = {
  page_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Module_Revision_Pages_Min_Fields = {
  __typename?: 'learning_module_revision_pages_min_fields'
  page_id?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['Int']>
  revision_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Min_Order_By = {
  page_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  revision_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Mutation_Response = {
  __typename?: 'learning_module_revision_pages_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Module_Revision_Pages>
}

/** on_conflict condition type for table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_On_Conflict = {
  constraint: Learning_Module_Revision_Pages_Constraint
  update_columns?: Array<Learning_Module_Revision_Pages_Update_Column>
  where?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
}

/** Ordering options when selecting data from "learning_module_revision_pages". */
export type Learning_Module_Revision_Pages_Order_By = {
  is_preview?: InputMaybe<Order_By>
  page?: InputMaybe<Learning_Pages_Order_By>
  page_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  revision?: InputMaybe<Learning_Module_Revisions_Order_By>
  revision_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_module_revision_pages */
export type Learning_Module_Revision_Pages_Pk_Columns_Input = {
  page_id: Scalars['String']
  revision_id: Scalars['uuid']
}

/** select columns of table "learning_module_revision_pages" */
export enum Learning_Module_Revision_Pages_Select_Column {
  /** column name */
  IsPreview = 'is_preview',
  /** column name */
  PageId = 'page_id',
  /** column name */
  Position = 'position',
  /** column name */
  RevisionId = 'revision_id',
}

/** select "learning_module_revision_pages_aggregate_bool_exp_bool_and_arguments_columns" columns of table "learning_module_revision_pages" */
export enum Learning_Module_Revision_Pages_Select_Column_Learning_Module_Revision_Pages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPreview = 'is_preview',
}

/** select "learning_module_revision_pages_aggregate_bool_exp_bool_or_arguments_columns" columns of table "learning_module_revision_pages" */
export enum Learning_Module_Revision_Pages_Select_Column_Learning_Module_Revision_Pages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPreview = 'is_preview',
}

/** input type for updating data in table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Set_Input = {
  is_preview?: InputMaybe<Scalars['Boolean']>
  page_id?: InputMaybe<Scalars['String']>
  position?: InputMaybe<Scalars['Int']>
  revision_id?: InputMaybe<Scalars['uuid']>
}

/** aggregate stddev on columns */
export type Learning_Module_Revision_Pages_Stddev_Fields = {
  __typename?: 'learning_module_revision_pages_stddev_fields'
  position?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Stddev_Order_By = {
  position?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Module_Revision_Pages_Stddev_Pop_Fields = {
  __typename?: 'learning_module_revision_pages_stddev_pop_fields'
  position?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Module_Revision_Pages_Stddev_Samp_Fields = {
  __typename?: 'learning_module_revision_pages_stddev_samp_fields'
  position?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Module_Revision_Pages_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Module_Revision_Pages_Stream_Cursor_Value_Input = {
  is_preview?: InputMaybe<Scalars['Boolean']>
  page_id?: InputMaybe<Scalars['String']>
  position?: InputMaybe<Scalars['Int']>
  revision_id?: InputMaybe<Scalars['uuid']>
}

/** aggregate sum on columns */
export type Learning_Module_Revision_Pages_Sum_Fields = {
  __typename?: 'learning_module_revision_pages_sum_fields'
  position?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Sum_Order_By = {
  position?: InputMaybe<Order_By>
}

/** update columns of table "learning_module_revision_pages" */
export enum Learning_Module_Revision_Pages_Update_Column {
  /** column name */
  IsPreview = 'is_preview',
  /** column name */
  PageId = 'page_id',
  /** column name */
  Position = 'position',
  /** column name */
  RevisionId = 'revision_id',
}

export type Learning_Module_Revision_Pages_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Module_Revision_Pages_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Module_Revision_Pages_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Module_Revision_Pages_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Module_Revision_Pages_Var_Pop_Fields = {
  __typename?: 'learning_module_revision_pages_var_pop_fields'
  position?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Module_Revision_Pages_Var_Samp_Fields = {
  __typename?: 'learning_module_revision_pages_var_samp_fields'
  position?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Module_Revision_Pages_Variance_Fields = {
  __typename?: 'learning_module_revision_pages_variance_fields'
  position?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_module_revision_pages" */
export type Learning_Module_Revision_Pages_Variance_Order_By = {
  position?: InputMaybe<Order_By>
}

/** columns and relationships of "learning_module_revisions" */
export type Learning_Module_Revisions = {
  __typename?: 'learning_module_revisions'
  /** An object relationship */
  card_thumbnail?: Maybe<Learning_Uploads>
  card_thumbnail_id?: Maybe<Scalars['Int']>
  categories?: Maybe<Scalars['jsonb']>
  cme_accreditor?: Maybe<Scalars['String']>
  cme_credits_description?: Maybe<Scalars['String']>
  cme_expires_after_seconds?: Maybe<Scalars['Int']>
  cme_expires_at?: Maybe<Scalars['timestamptz']>
  cme_reference?: Maybe<Scalars['String']>
  /** A computed field, executes function "learning_module_revisions_contains_mcq" */
  contains_mcq?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "learning_module_revisions_contains_poll" */
  contains_poll?: Maybe<Scalars['Boolean']>
  cover_author?: Maybe<Scalars['String']>
  /** An object relationship */
  cover_image?: Maybe<Learning_Uploads>
  cover_image_id?: Maybe<Scalars['Int']>
  /** An object relationship */
  cover_logo?: Maybe<Learning_Uploads>
  cover_logo_id?: Maybe<Scalars['Int']>
  cover_theme?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  created_by: Scalars['Int']
  credit_type?: Maybe<Scalars['learning_cme_type']>
  description?: Maybe<Scalars['String']>
  expected_completion_time_minutes?: Maybe<Scalars['Int']>
  /** An object relationship */
  feedback_form?: Maybe<Forms>
  feedback_form_id?: Maybe<Scalars['uuid']>
  from_live_event: Scalars['Boolean']
  id: Scalars['uuid']
  mcq_settings_enabled: Scalars['Boolean']
  mcq_settings_feedback?: Maybe<Scalars['String']>
  mcq_settings_labels: Scalars['String']
  mcq_settings_pass_mark?: Maybe<Scalars['Int']>
  mcq_settings_pass_mark_type: Scalars['learning_pass_mark_type']
  mcq_settings_retry: Scalars['learning_can_retry_type']
  mcq_settings_retry_limit?: Maybe<Scalars['numeric']>
  mcq_settings_retry_question_enabled: Scalars['Boolean']
  mcq_settings_show_score: Scalars['Boolean']
  /** An object relationship */
  module: Learning_Modules
  module_id: Scalars['uuid']
  objectives?: Maybe<Scalars['jsonb']>
  /** An array relationship */
  pages: Array<Learning_Module_Revision_Pages>
  /** An aggregate relationship */
  pages_aggregate: Learning_Module_Revision_Pages_Aggregate
  redirect_url?: Maybe<Scalars['String']>
  rich_description?: Maybe<Scalars['String']>
  /** An object relationship */
  series?: Maybe<Learning_Series>
  series_id?: Maybe<Scalars['uuid']>
  settings?: Maybe<Scalars['jsonb']>
  slug: Scalars['String']
  /** An object relationship */
  star_rating?: Maybe<Learning_Module_Star_Ratings>
  status: Scalars['learning_module_status']
  title: Scalars['String']
  want_page_titles: Scalars['Boolean']
}

/** columns and relationships of "learning_module_revisions" */
export type Learning_Module_RevisionsCategoriesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "learning_module_revisions" */
export type Learning_Module_RevisionsObjectivesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "learning_module_revisions" */
export type Learning_Module_RevisionsPagesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revision_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revision_Pages_Order_By>>
  where?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
}

/** columns and relationships of "learning_module_revisions" */
export type Learning_Module_RevisionsPages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revision_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revision_Pages_Order_By>>
  where?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
}

/** columns and relationships of "learning_module_revisions" */
export type Learning_Module_RevisionsSettingsArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "learning_module_revisions" */
export type Learning_Module_Revisions_Aggregate = {
  __typename?: 'learning_module_revisions_aggregate'
  aggregate?: Maybe<Learning_Module_Revisions_Aggregate_Fields>
  nodes: Array<Learning_Module_Revisions>
}

export type Learning_Module_Revisions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Learning_Module_Revisions_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Learning_Module_Revisions_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Learning_Module_Revisions_Aggregate_Bool_Exp_Count>
}

export type Learning_Module_Revisions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Learning_Module_Revisions_Select_Column_Learning_Module_Revisions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Learning_Module_Revisions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Learning_Module_Revisions_Select_Column_Learning_Module_Revisions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Learning_Module_Revisions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Module_Revisions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_module_revisions" */
export type Learning_Module_Revisions_Aggregate_Fields = {
  __typename?: 'learning_module_revisions_aggregate_fields'
  avg?: Maybe<Learning_Module_Revisions_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Module_Revisions_Max_Fields>
  min?: Maybe<Learning_Module_Revisions_Min_Fields>
  stddev?: Maybe<Learning_Module_Revisions_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Module_Revisions_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Module_Revisions_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Module_Revisions_Sum_Fields>
  var_pop?: Maybe<Learning_Module_Revisions_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Module_Revisions_Var_Samp_Fields>
  variance?: Maybe<Learning_Module_Revisions_Variance_Fields>
}

/** aggregate fields of "learning_module_revisions" */
export type Learning_Module_Revisions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Module_Revisions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_module_revisions" */
export type Learning_Module_Revisions_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Module_Revisions_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Module_Revisions_Max_Order_By>
  min?: InputMaybe<Learning_Module_Revisions_Min_Order_By>
  stddev?: InputMaybe<Learning_Module_Revisions_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Module_Revisions_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Module_Revisions_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Module_Revisions_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Module_Revisions_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Module_Revisions_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Module_Revisions_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Learning_Module_Revisions_Append_Input = {
  categories?: InputMaybe<Scalars['jsonb']>
  objectives?: InputMaybe<Scalars['jsonb']>
  settings?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "learning_module_revisions" */
export type Learning_Module_Revisions_Arr_Rel_Insert_Input = {
  data: Array<Learning_Module_Revisions_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Module_Revisions_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Module_Revisions_Avg_Fields = {
  __typename?: 'learning_module_revisions_avg_fields'
  card_thumbnail_id?: Maybe<Scalars['Float']>
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  cover_image_id?: Maybe<Scalars['Float']>
  cover_logo_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  expected_completion_time_minutes?: Maybe<Scalars['Float']>
  mcq_settings_pass_mark?: Maybe<Scalars['Float']>
  mcq_settings_retry_limit?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_module_revisions" */
export type Learning_Module_Revisions_Avg_Order_By = {
  card_thumbnail_id?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cover_image_id?: InputMaybe<Order_By>
  cover_logo_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  expected_completion_time_minutes?: InputMaybe<Order_By>
  mcq_settings_pass_mark?: InputMaybe<Order_By>
  mcq_settings_retry_limit?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_module_revisions". All fields are combined with a logical 'AND'. */
export type Learning_Module_Revisions_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Module_Revisions_Bool_Exp>>
  _not?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Module_Revisions_Bool_Exp>>
  card_thumbnail?: InputMaybe<Learning_Uploads_Bool_Exp>
  card_thumbnail_id?: InputMaybe<Int_Comparison_Exp>
  categories?: InputMaybe<Jsonb_Comparison_Exp>
  cme_accreditor?: InputMaybe<String_Comparison_Exp>
  cme_credits_description?: InputMaybe<String_Comparison_Exp>
  cme_expires_after_seconds?: InputMaybe<Int_Comparison_Exp>
  cme_expires_at?: InputMaybe<Timestamptz_Comparison_Exp>
  cme_reference?: InputMaybe<String_Comparison_Exp>
  contains_mcq?: InputMaybe<Boolean_Comparison_Exp>
  contains_poll?: InputMaybe<Boolean_Comparison_Exp>
  cover_author?: InputMaybe<String_Comparison_Exp>
  cover_image?: InputMaybe<Learning_Uploads_Bool_Exp>
  cover_image_id?: InputMaybe<Int_Comparison_Exp>
  cover_logo?: InputMaybe<Learning_Uploads_Bool_Exp>
  cover_logo_id?: InputMaybe<Int_Comparison_Exp>
  cover_theme?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  credit_type?: InputMaybe<Learning_Cme_Type_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  expected_completion_time_minutes?: InputMaybe<Int_Comparison_Exp>
  feedback_form?: InputMaybe<Forms_Bool_Exp>
  feedback_form_id?: InputMaybe<Uuid_Comparison_Exp>
  from_live_event?: InputMaybe<Boolean_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  mcq_settings_enabled?: InputMaybe<Boolean_Comparison_Exp>
  mcq_settings_feedback?: InputMaybe<String_Comparison_Exp>
  mcq_settings_labels?: InputMaybe<String_Comparison_Exp>
  mcq_settings_pass_mark?: InputMaybe<Int_Comparison_Exp>
  mcq_settings_pass_mark_type?: InputMaybe<Learning_Pass_Mark_Type_Comparison_Exp>
  mcq_settings_retry?: InputMaybe<Learning_Can_Retry_Type_Comparison_Exp>
  mcq_settings_retry_limit?: InputMaybe<Numeric_Comparison_Exp>
  mcq_settings_retry_question_enabled?: InputMaybe<Boolean_Comparison_Exp>
  mcq_settings_show_score?: InputMaybe<Boolean_Comparison_Exp>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  objectives?: InputMaybe<Jsonb_Comparison_Exp>
  pages?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
  pages_aggregate?: InputMaybe<Learning_Module_Revision_Pages_Aggregate_Bool_Exp>
  redirect_url?: InputMaybe<String_Comparison_Exp>
  rich_description?: InputMaybe<String_Comparison_Exp>
  series?: InputMaybe<Learning_Series_Bool_Exp>
  series_id?: InputMaybe<Uuid_Comparison_Exp>
  settings?: InputMaybe<Jsonb_Comparison_Exp>
  slug?: InputMaybe<String_Comparison_Exp>
  star_rating?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
  status?: InputMaybe<Learning_Module_Status_Comparison_Exp>
  title?: InputMaybe<String_Comparison_Exp>
  want_page_titles?: InputMaybe<Boolean_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_module_revisions" */
export enum Learning_Module_Revisions_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningModuleRevisionsPkey = 'learning_module_revisions_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Learning_Module_Revisions_Delete_At_Path_Input = {
  categories?: InputMaybe<Array<Scalars['String']>>
  objectives?: InputMaybe<Array<Scalars['String']>>
  settings?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Learning_Module_Revisions_Delete_Elem_Input = {
  categories?: InputMaybe<Scalars['Int']>
  objectives?: InputMaybe<Scalars['Int']>
  settings?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Learning_Module_Revisions_Delete_Key_Input = {
  categories?: InputMaybe<Scalars['String']>
  objectives?: InputMaybe<Scalars['String']>
  settings?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "learning_module_revisions" */
export type Learning_Module_Revisions_Inc_Input = {
  card_thumbnail_id?: InputMaybe<Scalars['Int']>
  cme_expires_after_seconds?: InputMaybe<Scalars['Int']>
  cover_image_id?: InputMaybe<Scalars['Int']>
  cover_logo_id?: InputMaybe<Scalars['Int']>
  created_by?: InputMaybe<Scalars['Int']>
  expected_completion_time_minutes?: InputMaybe<Scalars['Int']>
  mcq_settings_pass_mark?: InputMaybe<Scalars['Int']>
  mcq_settings_retry_limit?: InputMaybe<Scalars['numeric']>
}

/** input type for inserting data into table "learning_module_revisions" */
export type Learning_Module_Revisions_Insert_Input = {
  card_thumbnail?: InputMaybe<Learning_Uploads_Obj_Rel_Insert_Input>
  card_thumbnail_id?: InputMaybe<Scalars['Int']>
  categories?: InputMaybe<Scalars['jsonb']>
  cme_accreditor?: InputMaybe<Scalars['String']>
  cme_credits_description?: InputMaybe<Scalars['String']>
  cme_expires_after_seconds?: InputMaybe<Scalars['Int']>
  cme_expires_at?: InputMaybe<Scalars['timestamptz']>
  cme_reference?: InputMaybe<Scalars['String']>
  cover_author?: InputMaybe<Scalars['String']>
  cover_image?: InputMaybe<Learning_Uploads_Obj_Rel_Insert_Input>
  cover_image_id?: InputMaybe<Scalars['Int']>
  cover_logo?: InputMaybe<Learning_Uploads_Obj_Rel_Insert_Input>
  cover_logo_id?: InputMaybe<Scalars['Int']>
  cover_theme?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  credit_type?: InputMaybe<Scalars['learning_cme_type']>
  description?: InputMaybe<Scalars['String']>
  expected_completion_time_minutes?: InputMaybe<Scalars['Int']>
  feedback_form?: InputMaybe<Forms_Obj_Rel_Insert_Input>
  feedback_form_id?: InputMaybe<Scalars['uuid']>
  from_live_event?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  mcq_settings_enabled?: InputMaybe<Scalars['Boolean']>
  mcq_settings_feedback?: InputMaybe<Scalars['String']>
  mcq_settings_labels?: InputMaybe<Scalars['String']>
  mcq_settings_pass_mark?: InputMaybe<Scalars['Int']>
  mcq_settings_pass_mark_type?: InputMaybe<Scalars['learning_pass_mark_type']>
  mcq_settings_retry?: InputMaybe<Scalars['learning_can_retry_type']>
  mcq_settings_retry_limit?: InputMaybe<Scalars['numeric']>
  mcq_settings_retry_question_enabled?: InputMaybe<Scalars['Boolean']>
  mcq_settings_show_score?: InputMaybe<Scalars['Boolean']>
  module?: InputMaybe<Learning_Modules_Obj_Rel_Insert_Input>
  module_id?: InputMaybe<Scalars['uuid']>
  objectives?: InputMaybe<Scalars['jsonb']>
  pages?: InputMaybe<Learning_Module_Revision_Pages_Arr_Rel_Insert_Input>
  redirect_url?: InputMaybe<Scalars['String']>
  rich_description?: InputMaybe<Scalars['String']>
  series?: InputMaybe<Learning_Series_Obj_Rel_Insert_Input>
  series_id?: InputMaybe<Scalars['uuid']>
  settings?: InputMaybe<Scalars['jsonb']>
  slug?: InputMaybe<Scalars['String']>
  star_rating?: InputMaybe<Learning_Module_Star_Ratings_Obj_Rel_Insert_Input>
  status?: InputMaybe<Scalars['learning_module_status']>
  title?: InputMaybe<Scalars['String']>
  want_page_titles?: InputMaybe<Scalars['Boolean']>
}

/** aggregate max on columns */
export type Learning_Module_Revisions_Max_Fields = {
  __typename?: 'learning_module_revisions_max_fields'
  card_thumbnail_id?: Maybe<Scalars['Int']>
  cme_accreditor?: Maybe<Scalars['String']>
  cme_credits_description?: Maybe<Scalars['String']>
  cme_expires_after_seconds?: Maybe<Scalars['Int']>
  cme_expires_at?: Maybe<Scalars['timestamptz']>
  cme_reference?: Maybe<Scalars['String']>
  cover_author?: Maybe<Scalars['String']>
  cover_image_id?: Maybe<Scalars['Int']>
  cover_logo_id?: Maybe<Scalars['Int']>
  cover_theme?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  credit_type?: Maybe<Scalars['learning_cme_type']>
  description?: Maybe<Scalars['String']>
  expected_completion_time_minutes?: Maybe<Scalars['Int']>
  feedback_form_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  mcq_settings_feedback?: Maybe<Scalars['String']>
  mcq_settings_labels?: Maybe<Scalars['String']>
  mcq_settings_pass_mark?: Maybe<Scalars['Int']>
  mcq_settings_pass_mark_type?: Maybe<Scalars['learning_pass_mark_type']>
  mcq_settings_retry?: Maybe<Scalars['learning_can_retry_type']>
  mcq_settings_retry_limit?: Maybe<Scalars['numeric']>
  module_id?: Maybe<Scalars['uuid']>
  redirect_url?: Maybe<Scalars['String']>
  rich_description?: Maybe<Scalars['String']>
  series_id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['learning_module_status']>
  title?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "learning_module_revisions" */
export type Learning_Module_Revisions_Max_Order_By = {
  card_thumbnail_id?: InputMaybe<Order_By>
  cme_accreditor?: InputMaybe<Order_By>
  cme_credits_description?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cme_expires_at?: InputMaybe<Order_By>
  cme_reference?: InputMaybe<Order_By>
  cover_author?: InputMaybe<Order_By>
  cover_image_id?: InputMaybe<Order_By>
  cover_logo_id?: InputMaybe<Order_By>
  cover_theme?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  credit_type?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  expected_completion_time_minutes?: InputMaybe<Order_By>
  feedback_form_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  mcq_settings_feedback?: InputMaybe<Order_By>
  mcq_settings_labels?: InputMaybe<Order_By>
  mcq_settings_pass_mark?: InputMaybe<Order_By>
  mcq_settings_pass_mark_type?: InputMaybe<Order_By>
  mcq_settings_retry?: InputMaybe<Order_By>
  mcq_settings_retry_limit?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  redirect_url?: InputMaybe<Order_By>
  rich_description?: InputMaybe<Order_By>
  series_id?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Module_Revisions_Min_Fields = {
  __typename?: 'learning_module_revisions_min_fields'
  card_thumbnail_id?: Maybe<Scalars['Int']>
  cme_accreditor?: Maybe<Scalars['String']>
  cme_credits_description?: Maybe<Scalars['String']>
  cme_expires_after_seconds?: Maybe<Scalars['Int']>
  cme_expires_at?: Maybe<Scalars['timestamptz']>
  cme_reference?: Maybe<Scalars['String']>
  cover_author?: Maybe<Scalars['String']>
  cover_image_id?: Maybe<Scalars['Int']>
  cover_logo_id?: Maybe<Scalars['Int']>
  cover_theme?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  credit_type?: Maybe<Scalars['learning_cme_type']>
  description?: Maybe<Scalars['String']>
  expected_completion_time_minutes?: Maybe<Scalars['Int']>
  feedback_form_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  mcq_settings_feedback?: Maybe<Scalars['String']>
  mcq_settings_labels?: Maybe<Scalars['String']>
  mcq_settings_pass_mark?: Maybe<Scalars['Int']>
  mcq_settings_pass_mark_type?: Maybe<Scalars['learning_pass_mark_type']>
  mcq_settings_retry?: Maybe<Scalars['learning_can_retry_type']>
  mcq_settings_retry_limit?: Maybe<Scalars['numeric']>
  module_id?: Maybe<Scalars['uuid']>
  redirect_url?: Maybe<Scalars['String']>
  rich_description?: Maybe<Scalars['String']>
  series_id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['learning_module_status']>
  title?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "learning_module_revisions" */
export type Learning_Module_Revisions_Min_Order_By = {
  card_thumbnail_id?: InputMaybe<Order_By>
  cme_accreditor?: InputMaybe<Order_By>
  cme_credits_description?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cme_expires_at?: InputMaybe<Order_By>
  cme_reference?: InputMaybe<Order_By>
  cover_author?: InputMaybe<Order_By>
  cover_image_id?: InputMaybe<Order_By>
  cover_logo_id?: InputMaybe<Order_By>
  cover_theme?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  credit_type?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  expected_completion_time_minutes?: InputMaybe<Order_By>
  feedback_form_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  mcq_settings_feedback?: InputMaybe<Order_By>
  mcq_settings_labels?: InputMaybe<Order_By>
  mcq_settings_pass_mark?: InputMaybe<Order_By>
  mcq_settings_pass_mark_type?: InputMaybe<Order_By>
  mcq_settings_retry?: InputMaybe<Order_By>
  mcq_settings_retry_limit?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  redirect_url?: InputMaybe<Order_By>
  rich_description?: InputMaybe<Order_By>
  series_id?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_module_revisions" */
export type Learning_Module_Revisions_Mutation_Response = {
  __typename?: 'learning_module_revisions_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Module_Revisions>
}

/** input type for inserting object relation for remote table "learning_module_revisions" */
export type Learning_Module_Revisions_Obj_Rel_Insert_Input = {
  data: Learning_Module_Revisions_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Module_Revisions_On_Conflict>
}

/** on_conflict condition type for table "learning_module_revisions" */
export type Learning_Module_Revisions_On_Conflict = {
  constraint: Learning_Module_Revisions_Constraint
  update_columns?: Array<Learning_Module_Revisions_Update_Column>
  where?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
}

/** Ordering options when selecting data from "learning_module_revisions". */
export type Learning_Module_Revisions_Order_By = {
  card_thumbnail?: InputMaybe<Learning_Uploads_Order_By>
  card_thumbnail_id?: InputMaybe<Order_By>
  categories?: InputMaybe<Order_By>
  cme_accreditor?: InputMaybe<Order_By>
  cme_credits_description?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cme_expires_at?: InputMaybe<Order_By>
  cme_reference?: InputMaybe<Order_By>
  contains_mcq?: InputMaybe<Order_By>
  contains_poll?: InputMaybe<Order_By>
  cover_author?: InputMaybe<Order_By>
  cover_image?: InputMaybe<Learning_Uploads_Order_By>
  cover_image_id?: InputMaybe<Order_By>
  cover_logo?: InputMaybe<Learning_Uploads_Order_By>
  cover_logo_id?: InputMaybe<Order_By>
  cover_theme?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  credit_type?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  expected_completion_time_minutes?: InputMaybe<Order_By>
  feedback_form?: InputMaybe<Forms_Order_By>
  feedback_form_id?: InputMaybe<Order_By>
  from_live_event?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  mcq_settings_enabled?: InputMaybe<Order_By>
  mcq_settings_feedback?: InputMaybe<Order_By>
  mcq_settings_labels?: InputMaybe<Order_By>
  mcq_settings_pass_mark?: InputMaybe<Order_By>
  mcq_settings_pass_mark_type?: InputMaybe<Order_By>
  mcq_settings_retry?: InputMaybe<Order_By>
  mcq_settings_retry_limit?: InputMaybe<Order_By>
  mcq_settings_retry_question_enabled?: InputMaybe<Order_By>
  mcq_settings_show_score?: InputMaybe<Order_By>
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  objectives?: InputMaybe<Order_By>
  pages_aggregate?: InputMaybe<Learning_Module_Revision_Pages_Aggregate_Order_By>
  redirect_url?: InputMaybe<Order_By>
  rich_description?: InputMaybe<Order_By>
  series?: InputMaybe<Learning_Series_Order_By>
  series_id?: InputMaybe<Order_By>
  settings?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  star_rating?: InputMaybe<Learning_Module_Star_Ratings_Order_By>
  status?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  want_page_titles?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_module_revisions */
export type Learning_Module_Revisions_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Learning_Module_Revisions_Prepend_Input = {
  categories?: InputMaybe<Scalars['jsonb']>
  objectives?: InputMaybe<Scalars['jsonb']>
  settings?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "learning_module_revisions" */
export enum Learning_Module_Revisions_Select_Column {
  /** column name */
  CardThumbnailId = 'card_thumbnail_id',
  /** column name */
  Categories = 'categories',
  /** column name */
  CmeAccreditor = 'cme_accreditor',
  /** column name */
  CmeCreditsDescription = 'cme_credits_description',
  /** column name */
  CmeExpiresAfterSeconds = 'cme_expires_after_seconds',
  /** column name */
  CmeExpiresAt = 'cme_expires_at',
  /** column name */
  CmeReference = 'cme_reference',
  /** column name */
  CoverAuthor = 'cover_author',
  /** column name */
  CoverImageId = 'cover_image_id',
  /** column name */
  CoverLogoId = 'cover_logo_id',
  /** column name */
  CoverTheme = 'cover_theme',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditType = 'credit_type',
  /** column name */
  Description = 'description',
  /** column name */
  ExpectedCompletionTimeMinutes = 'expected_completion_time_minutes',
  /** column name */
  FeedbackFormId = 'feedback_form_id',
  /** column name */
  FromLiveEvent = 'from_live_event',
  /** column name */
  Id = 'id',
  /** column name */
  McqSettingsEnabled = 'mcq_settings_enabled',
  /** column name */
  McqSettingsFeedback = 'mcq_settings_feedback',
  /** column name */
  McqSettingsLabels = 'mcq_settings_labels',
  /** column name */
  McqSettingsPassMark = 'mcq_settings_pass_mark',
  /** column name */
  McqSettingsPassMarkType = 'mcq_settings_pass_mark_type',
  /** column name */
  McqSettingsRetry = 'mcq_settings_retry',
  /** column name */
  McqSettingsRetryLimit = 'mcq_settings_retry_limit',
  /** column name */
  McqSettingsRetryQuestionEnabled = 'mcq_settings_retry_question_enabled',
  /** column name */
  McqSettingsShowScore = 'mcq_settings_show_score',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Objectives = 'objectives',
  /** column name */
  RedirectUrl = 'redirect_url',
  /** column name */
  RichDescription = 'rich_description',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  Settings = 'settings',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  WantPageTitles = 'want_page_titles',
}

/** select "learning_module_revisions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "learning_module_revisions" */
export enum Learning_Module_Revisions_Select_Column_Learning_Module_Revisions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FromLiveEvent = 'from_live_event',
  /** column name */
  McqSettingsEnabled = 'mcq_settings_enabled',
  /** column name */
  McqSettingsRetryQuestionEnabled = 'mcq_settings_retry_question_enabled',
  /** column name */
  McqSettingsShowScore = 'mcq_settings_show_score',
  /** column name */
  WantPageTitles = 'want_page_titles',
}

/** select "learning_module_revisions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "learning_module_revisions" */
export enum Learning_Module_Revisions_Select_Column_Learning_Module_Revisions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FromLiveEvent = 'from_live_event',
  /** column name */
  McqSettingsEnabled = 'mcq_settings_enabled',
  /** column name */
  McqSettingsRetryQuestionEnabled = 'mcq_settings_retry_question_enabled',
  /** column name */
  McqSettingsShowScore = 'mcq_settings_show_score',
  /** column name */
  WantPageTitles = 'want_page_titles',
}

/** input type for updating data in table "learning_module_revisions" */
export type Learning_Module_Revisions_Set_Input = {
  card_thumbnail_id?: InputMaybe<Scalars['Int']>
  categories?: InputMaybe<Scalars['jsonb']>
  cme_accreditor?: InputMaybe<Scalars['String']>
  cme_credits_description?: InputMaybe<Scalars['String']>
  cme_expires_after_seconds?: InputMaybe<Scalars['Int']>
  cme_expires_at?: InputMaybe<Scalars['timestamptz']>
  cme_reference?: InputMaybe<Scalars['String']>
  cover_author?: InputMaybe<Scalars['String']>
  cover_image_id?: InputMaybe<Scalars['Int']>
  cover_logo_id?: InputMaybe<Scalars['Int']>
  cover_theme?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  credit_type?: InputMaybe<Scalars['learning_cme_type']>
  description?: InputMaybe<Scalars['String']>
  expected_completion_time_minutes?: InputMaybe<Scalars['Int']>
  feedback_form_id?: InputMaybe<Scalars['uuid']>
  from_live_event?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  mcq_settings_enabled?: InputMaybe<Scalars['Boolean']>
  mcq_settings_feedback?: InputMaybe<Scalars['String']>
  mcq_settings_labels?: InputMaybe<Scalars['String']>
  mcq_settings_pass_mark?: InputMaybe<Scalars['Int']>
  mcq_settings_pass_mark_type?: InputMaybe<Scalars['learning_pass_mark_type']>
  mcq_settings_retry?: InputMaybe<Scalars['learning_can_retry_type']>
  mcq_settings_retry_limit?: InputMaybe<Scalars['numeric']>
  mcq_settings_retry_question_enabled?: InputMaybe<Scalars['Boolean']>
  mcq_settings_show_score?: InputMaybe<Scalars['Boolean']>
  module_id?: InputMaybe<Scalars['uuid']>
  objectives?: InputMaybe<Scalars['jsonb']>
  redirect_url?: InputMaybe<Scalars['String']>
  rich_description?: InputMaybe<Scalars['String']>
  series_id?: InputMaybe<Scalars['uuid']>
  settings?: InputMaybe<Scalars['jsonb']>
  slug?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['learning_module_status']>
  title?: InputMaybe<Scalars['String']>
  want_page_titles?: InputMaybe<Scalars['Boolean']>
}

/** aggregate stddev on columns */
export type Learning_Module_Revisions_Stddev_Fields = {
  __typename?: 'learning_module_revisions_stddev_fields'
  card_thumbnail_id?: Maybe<Scalars['Float']>
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  cover_image_id?: Maybe<Scalars['Float']>
  cover_logo_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  expected_completion_time_minutes?: Maybe<Scalars['Float']>
  mcq_settings_pass_mark?: Maybe<Scalars['Float']>
  mcq_settings_retry_limit?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_module_revisions" */
export type Learning_Module_Revisions_Stddev_Order_By = {
  card_thumbnail_id?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cover_image_id?: InputMaybe<Order_By>
  cover_logo_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  expected_completion_time_minutes?: InputMaybe<Order_By>
  mcq_settings_pass_mark?: InputMaybe<Order_By>
  mcq_settings_retry_limit?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Module_Revisions_Stddev_Pop_Fields = {
  __typename?: 'learning_module_revisions_stddev_pop_fields'
  card_thumbnail_id?: Maybe<Scalars['Float']>
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  cover_image_id?: Maybe<Scalars['Float']>
  cover_logo_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  expected_completion_time_minutes?: Maybe<Scalars['Float']>
  mcq_settings_pass_mark?: Maybe<Scalars['Float']>
  mcq_settings_retry_limit?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_module_revisions" */
export type Learning_Module_Revisions_Stddev_Pop_Order_By = {
  card_thumbnail_id?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cover_image_id?: InputMaybe<Order_By>
  cover_logo_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  expected_completion_time_minutes?: InputMaybe<Order_By>
  mcq_settings_pass_mark?: InputMaybe<Order_By>
  mcq_settings_retry_limit?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Module_Revisions_Stddev_Samp_Fields = {
  __typename?: 'learning_module_revisions_stddev_samp_fields'
  card_thumbnail_id?: Maybe<Scalars['Float']>
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  cover_image_id?: Maybe<Scalars['Float']>
  cover_logo_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  expected_completion_time_minutes?: Maybe<Scalars['Float']>
  mcq_settings_pass_mark?: Maybe<Scalars['Float']>
  mcq_settings_retry_limit?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_module_revisions" */
export type Learning_Module_Revisions_Stddev_Samp_Order_By = {
  card_thumbnail_id?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cover_image_id?: InputMaybe<Order_By>
  cover_logo_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  expected_completion_time_minutes?: InputMaybe<Order_By>
  mcq_settings_pass_mark?: InputMaybe<Order_By>
  mcq_settings_retry_limit?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_module_revisions" */
export type Learning_Module_Revisions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Module_Revisions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Module_Revisions_Stream_Cursor_Value_Input = {
  card_thumbnail_id?: InputMaybe<Scalars['Int']>
  categories?: InputMaybe<Scalars['jsonb']>
  cme_accreditor?: InputMaybe<Scalars['String']>
  cme_credits_description?: InputMaybe<Scalars['String']>
  cme_expires_after_seconds?: InputMaybe<Scalars['Int']>
  cme_expires_at?: InputMaybe<Scalars['timestamptz']>
  cme_reference?: InputMaybe<Scalars['String']>
  cover_author?: InputMaybe<Scalars['String']>
  cover_image_id?: InputMaybe<Scalars['Int']>
  cover_logo_id?: InputMaybe<Scalars['Int']>
  cover_theme?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  credit_type?: InputMaybe<Scalars['learning_cme_type']>
  description?: InputMaybe<Scalars['String']>
  expected_completion_time_minutes?: InputMaybe<Scalars['Int']>
  feedback_form_id?: InputMaybe<Scalars['uuid']>
  from_live_event?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  mcq_settings_enabled?: InputMaybe<Scalars['Boolean']>
  mcq_settings_feedback?: InputMaybe<Scalars['String']>
  mcq_settings_labels?: InputMaybe<Scalars['String']>
  mcq_settings_pass_mark?: InputMaybe<Scalars['Int']>
  mcq_settings_pass_mark_type?: InputMaybe<Scalars['learning_pass_mark_type']>
  mcq_settings_retry?: InputMaybe<Scalars['learning_can_retry_type']>
  mcq_settings_retry_limit?: InputMaybe<Scalars['numeric']>
  mcq_settings_retry_question_enabled?: InputMaybe<Scalars['Boolean']>
  mcq_settings_show_score?: InputMaybe<Scalars['Boolean']>
  module_id?: InputMaybe<Scalars['uuid']>
  objectives?: InputMaybe<Scalars['jsonb']>
  redirect_url?: InputMaybe<Scalars['String']>
  rich_description?: InputMaybe<Scalars['String']>
  series_id?: InputMaybe<Scalars['uuid']>
  settings?: InputMaybe<Scalars['jsonb']>
  slug?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['learning_module_status']>
  title?: InputMaybe<Scalars['String']>
  want_page_titles?: InputMaybe<Scalars['Boolean']>
}

/** aggregate sum on columns */
export type Learning_Module_Revisions_Sum_Fields = {
  __typename?: 'learning_module_revisions_sum_fields'
  card_thumbnail_id?: Maybe<Scalars['Int']>
  cme_expires_after_seconds?: Maybe<Scalars['Int']>
  cover_image_id?: Maybe<Scalars['Int']>
  cover_logo_id?: Maybe<Scalars['Int']>
  created_by?: Maybe<Scalars['Int']>
  expected_completion_time_minutes?: Maybe<Scalars['Int']>
  mcq_settings_pass_mark?: Maybe<Scalars['Int']>
  mcq_settings_retry_limit?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "learning_module_revisions" */
export type Learning_Module_Revisions_Sum_Order_By = {
  card_thumbnail_id?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cover_image_id?: InputMaybe<Order_By>
  cover_logo_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  expected_completion_time_minutes?: InputMaybe<Order_By>
  mcq_settings_pass_mark?: InputMaybe<Order_By>
  mcq_settings_retry_limit?: InputMaybe<Order_By>
}

/** update columns of table "learning_module_revisions" */
export enum Learning_Module_Revisions_Update_Column {
  /** column name */
  CardThumbnailId = 'card_thumbnail_id',
  /** column name */
  Categories = 'categories',
  /** column name */
  CmeAccreditor = 'cme_accreditor',
  /** column name */
  CmeCreditsDescription = 'cme_credits_description',
  /** column name */
  CmeExpiresAfterSeconds = 'cme_expires_after_seconds',
  /** column name */
  CmeExpiresAt = 'cme_expires_at',
  /** column name */
  CmeReference = 'cme_reference',
  /** column name */
  CoverAuthor = 'cover_author',
  /** column name */
  CoverImageId = 'cover_image_id',
  /** column name */
  CoverLogoId = 'cover_logo_id',
  /** column name */
  CoverTheme = 'cover_theme',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CreditType = 'credit_type',
  /** column name */
  Description = 'description',
  /** column name */
  ExpectedCompletionTimeMinutes = 'expected_completion_time_minutes',
  /** column name */
  FeedbackFormId = 'feedback_form_id',
  /** column name */
  FromLiveEvent = 'from_live_event',
  /** column name */
  Id = 'id',
  /** column name */
  McqSettingsEnabled = 'mcq_settings_enabled',
  /** column name */
  McqSettingsFeedback = 'mcq_settings_feedback',
  /** column name */
  McqSettingsLabels = 'mcq_settings_labels',
  /** column name */
  McqSettingsPassMark = 'mcq_settings_pass_mark',
  /** column name */
  McqSettingsPassMarkType = 'mcq_settings_pass_mark_type',
  /** column name */
  McqSettingsRetry = 'mcq_settings_retry',
  /** column name */
  McqSettingsRetryLimit = 'mcq_settings_retry_limit',
  /** column name */
  McqSettingsRetryQuestionEnabled = 'mcq_settings_retry_question_enabled',
  /** column name */
  McqSettingsShowScore = 'mcq_settings_show_score',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Objectives = 'objectives',
  /** column name */
  RedirectUrl = 'redirect_url',
  /** column name */
  RichDescription = 'rich_description',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  Settings = 'settings',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  WantPageTitles = 'want_page_titles',
}

export type Learning_Module_Revisions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Learning_Module_Revisions_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Learning_Module_Revisions_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Learning_Module_Revisions_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Learning_Module_Revisions_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Module_Revisions_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Learning_Module_Revisions_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Module_Revisions_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Module_Revisions_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Module_Revisions_Var_Pop_Fields = {
  __typename?: 'learning_module_revisions_var_pop_fields'
  card_thumbnail_id?: Maybe<Scalars['Float']>
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  cover_image_id?: Maybe<Scalars['Float']>
  cover_logo_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  expected_completion_time_minutes?: Maybe<Scalars['Float']>
  mcq_settings_pass_mark?: Maybe<Scalars['Float']>
  mcq_settings_retry_limit?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_module_revisions" */
export type Learning_Module_Revisions_Var_Pop_Order_By = {
  card_thumbnail_id?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cover_image_id?: InputMaybe<Order_By>
  cover_logo_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  expected_completion_time_minutes?: InputMaybe<Order_By>
  mcq_settings_pass_mark?: InputMaybe<Order_By>
  mcq_settings_retry_limit?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Module_Revisions_Var_Samp_Fields = {
  __typename?: 'learning_module_revisions_var_samp_fields'
  card_thumbnail_id?: Maybe<Scalars['Float']>
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  cover_image_id?: Maybe<Scalars['Float']>
  cover_logo_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  expected_completion_time_minutes?: Maybe<Scalars['Float']>
  mcq_settings_pass_mark?: Maybe<Scalars['Float']>
  mcq_settings_retry_limit?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_module_revisions" */
export type Learning_Module_Revisions_Var_Samp_Order_By = {
  card_thumbnail_id?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cover_image_id?: InputMaybe<Order_By>
  cover_logo_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  expected_completion_time_minutes?: InputMaybe<Order_By>
  mcq_settings_pass_mark?: InputMaybe<Order_By>
  mcq_settings_retry_limit?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Module_Revisions_Variance_Fields = {
  __typename?: 'learning_module_revisions_variance_fields'
  card_thumbnail_id?: Maybe<Scalars['Float']>
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  cover_image_id?: Maybe<Scalars['Float']>
  cover_logo_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  expected_completion_time_minutes?: Maybe<Scalars['Float']>
  mcq_settings_pass_mark?: Maybe<Scalars['Float']>
  mcq_settings_retry_limit?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_module_revisions" */
export type Learning_Module_Revisions_Variance_Order_By = {
  card_thumbnail_id?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cover_image_id?: InputMaybe<Order_By>
  cover_logo_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  expected_completion_time_minutes?: InputMaybe<Order_By>
  mcq_settings_pass_mark?: InputMaybe<Order_By>
  mcq_settings_retry_limit?: InputMaybe<Order_By>
}

/** Boolean expression to compare columns of type "learning_module_sort_order". All fields are combined with logical 'AND'. */
export type Learning_Module_Sort_Order_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_module_sort_order']>
  _gt?: InputMaybe<Scalars['learning_module_sort_order']>
  _gte?: InputMaybe<Scalars['learning_module_sort_order']>
  _in?: InputMaybe<Array<Scalars['learning_module_sort_order']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['learning_module_sort_order']>
  _lte?: InputMaybe<Scalars['learning_module_sort_order']>
  _neq?: InputMaybe<Scalars['learning_module_sort_order']>
  _nin?: InputMaybe<Array<Scalars['learning_module_sort_order']>>
}

/** columns and relationships of "learning_module_star_ratings" */
export type Learning_Module_Star_Ratings = {
  __typename?: 'learning_module_star_ratings'
  last_rated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  module?: Maybe<Learning_Modules>
  module_id?: Maybe<Scalars['uuid']>
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['bigint']>
  rating_total?: Maybe<Scalars['bigint']>
}

/** aggregated selection of "learning_module_star_ratings" */
export type Learning_Module_Star_Ratings_Aggregate = {
  __typename?: 'learning_module_star_ratings_aggregate'
  aggregate?: Maybe<Learning_Module_Star_Ratings_Aggregate_Fields>
  nodes: Array<Learning_Module_Star_Ratings>
}

/** aggregate fields of "learning_module_star_ratings" */
export type Learning_Module_Star_Ratings_Aggregate_Fields = {
  __typename?: 'learning_module_star_ratings_aggregate_fields'
  avg?: Maybe<Learning_Module_Star_Ratings_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Module_Star_Ratings_Max_Fields>
  min?: Maybe<Learning_Module_Star_Ratings_Min_Fields>
  stddev?: Maybe<Learning_Module_Star_Ratings_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Module_Star_Ratings_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Module_Star_Ratings_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Module_Star_Ratings_Sum_Fields>
  var_pop?: Maybe<Learning_Module_Star_Ratings_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Module_Star_Ratings_Var_Samp_Fields>
  variance?: Maybe<Learning_Module_Star_Ratings_Variance_Fields>
}

/** aggregate fields of "learning_module_star_ratings" */
export type Learning_Module_Star_Ratings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Module_Star_Ratings_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Learning_Module_Star_Ratings_Avg_Fields = {
  __typename?: 'learning_module_star_ratings_avg_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_module_star_ratings". All fields are combined with a logical 'AND'. */
export type Learning_Module_Star_Ratings_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Module_Star_Ratings_Bool_Exp>>
  _not?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Module_Star_Ratings_Bool_Exp>>
  last_rated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  rating?: InputMaybe<Numeric_Comparison_Exp>
  rating_count?: InputMaybe<Bigint_Comparison_Exp>
  rating_total?: InputMaybe<Bigint_Comparison_Exp>
}

/** input type for inserting data into table "learning_module_star_ratings" */
export type Learning_Module_Star_Ratings_Insert_Input = {
  last_rated_at?: InputMaybe<Scalars['timestamptz']>
  module?: InputMaybe<Learning_Modules_Obj_Rel_Insert_Input>
  module_id?: InputMaybe<Scalars['uuid']>
  rating?: InputMaybe<Scalars['numeric']>
  rating_count?: InputMaybe<Scalars['bigint']>
  rating_total?: InputMaybe<Scalars['bigint']>
}

/** aggregate max on columns */
export type Learning_Module_Star_Ratings_Max_Fields = {
  __typename?: 'learning_module_star_ratings_max_fields'
  last_rated_at?: Maybe<Scalars['timestamptz']>
  module_id?: Maybe<Scalars['uuid']>
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['bigint']>
  rating_total?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Module_Star_Ratings_Min_Fields = {
  __typename?: 'learning_module_star_ratings_min_fields'
  last_rated_at?: Maybe<Scalars['timestamptz']>
  module_id?: Maybe<Scalars['uuid']>
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['bigint']>
  rating_total?: Maybe<Scalars['bigint']>
}

/** input type for inserting object relation for remote table "learning_module_star_ratings" */
export type Learning_Module_Star_Ratings_Obj_Rel_Insert_Input = {
  data: Learning_Module_Star_Ratings_Insert_Input
}

/** Ordering options when selecting data from "learning_module_star_ratings". */
export type Learning_Module_Star_Ratings_Order_By = {
  last_rated_at?: InputMaybe<Order_By>
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  rating?: InputMaybe<Order_By>
  rating_count?: InputMaybe<Order_By>
  rating_total?: InputMaybe<Order_By>
}

/** select columns of table "learning_module_star_ratings" */
export enum Learning_Module_Star_Ratings_Select_Column {
  /** column name */
  LastRatedAt = 'last_rated_at',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingCount = 'rating_count',
  /** column name */
  RatingTotal = 'rating_total',
}

/** aggregate stddev on columns */
export type Learning_Module_Star_Ratings_Stddev_Fields = {
  __typename?: 'learning_module_star_ratings_stddev_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Module_Star_Ratings_Stddev_Pop_Fields = {
  __typename?: 'learning_module_star_ratings_stddev_pop_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Module_Star_Ratings_Stddev_Samp_Fields = {
  __typename?: 'learning_module_star_ratings_stddev_samp_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_module_star_ratings" */
export type Learning_Module_Star_Ratings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Module_Star_Ratings_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Module_Star_Ratings_Stream_Cursor_Value_Input = {
  last_rated_at?: InputMaybe<Scalars['timestamptz']>
  module_id?: InputMaybe<Scalars['uuid']>
  rating?: InputMaybe<Scalars['numeric']>
  rating_count?: InputMaybe<Scalars['bigint']>
  rating_total?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Learning_Module_Star_Ratings_Sum_Fields = {
  __typename?: 'learning_module_star_ratings_sum_fields'
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['bigint']>
  rating_total?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Module_Star_Ratings_Var_Pop_Fields = {
  __typename?: 'learning_module_star_ratings_var_pop_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Module_Star_Ratings_Var_Samp_Fields = {
  __typename?: 'learning_module_star_ratings_var_samp_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Module_Star_Ratings_Variance_Fields = {
  __typename?: 'learning_module_star_ratings_variance_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** Boolean expression to compare columns of type "learning_module_status". All fields are combined with logical 'AND'. */
export type Learning_Module_Status_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_module_status']>
  _gt?: InputMaybe<Scalars['learning_module_status']>
  _gte?: InputMaybe<Scalars['learning_module_status']>
  _in?: InputMaybe<Array<Scalars['learning_module_status']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['learning_module_status']>
  _lte?: InputMaybe<Scalars['learning_module_status']>
  _neq?: InputMaybe<Scalars['learning_module_status']>
  _nin?: InputMaybe<Array<Scalars['learning_module_status']>>
}

/** columns and relationships of "learning_modules" */
export type Learning_Modules = {
  __typename?: 'learning_modules'
  /** An object relationship */
  author: Users
  author_id: Scalars['Int']
  can_approve?: Maybe<Scalars['Boolean']>
  can_edit?: Maybe<Scalars['Boolean']>
  can_publish?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  change_event_logs: Array<Learning_Module_History>
  /** An aggregate relationship */
  change_event_logs_aggregate: Learning_Module_History_Aggregate
  copied_from_module_id?: Maybe<Scalars['uuid']>
  created_at: Scalars['timestamptz']
  created_by: Scalars['Int']
  /** An object relationship */
  creator: Users
  /** A computed field, executes function "has_user_completed_module" */
  ever_completed?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "count_failed_attempts" */
  failed_attempts?: Maybe<Scalars['Int']>
  guest_access: Scalars['Boolean']
  /** A computed field, executes function "has_user_passed_module" */
  has_passed?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "learning_module_session_user_has_subscription" */
  has_subscription?: Maybe<Scalars['Boolean']>
  id: Scalars['uuid']
  /** An object relationship */
  latest_revision?: Maybe<Learning_Module_Revisions>
  latest_revision_id?: Maybe<Scalars['uuid']>
  position?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['numeric']>
  published_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  published_revision?: Maybe<Learning_Module_Revisions>
  published_revision_id?: Maybe<Scalars['uuid']>
  /** An array relationship */
  rating_response_averages: Array<Learning_Stats_Learner_Module_Rating_Response_Averages>
  /** An aggregate relationship */
  rating_response_averages_aggregate: Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate
  /** An array relationship */
  rating_responses: Array<Learning_Stats_Module_Rating_Responses>
  /** An aggregate relationship */
  rating_responses_aggregate: Learning_Stats_Module_Rating_Responses_Aggregate
  /** An array relationship */
  ratings: Array<Learning_Learner_Module_Ratings>
  /** An aggregate relationship */
  ratings_aggregate: Learning_Learner_Module_Ratings_Aggregate
  /** A computed field, executes function "learning_check_reached_max_attempts" */
  reached_max_attempts?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  reflections: Array<Learning_Learner_Reflections>
  /** An aggregate relationship */
  reflections_aggregate: Learning_Learner_Reflections_Aggregate
  /** An array relationship */
  revisions: Array<Learning_Module_Revisions>
  /** An aggregate relationship */
  revisions_aggregate: Learning_Module_Revisions_Aggregate
  segment_id?: Maybe<Scalars['Int']>
  /** An object relationship */
  segment_member?: Maybe<Segment_User>
  /** An object relationship */
  series: Learning_Series
  series_id: Scalars['uuid']
  /** An object relationship */
  session_stats?: Maybe<Learning_Stats_Sessions_By_Module>
  /** An array relationship */
  sessions: Array<Learning_Learner_Sessions>
  /** An aggregate relationship */
  sessions_aggregate: Learning_Learner_Sessions_Aggregate
  /** An object relationship */
  sessions_stats?: Maybe<Learning_Stats_Sessions_By_Module>
  slug?: Maybe<Scalars['String']>
  /** An object relationship */
  star_rating?: Maybe<Learning_Module_Star_Ratings>
  status: Scalars['learning_module_status']
  /** An object relationship */
  status_event_log?: Maybe<Learning_Module_Event_Log>
  status_event_log_id?: Maybe<Scalars['uuid']>
  /** An array relationship */
  status_event_logs: Array<Learning_Module_Event_Log>
  /** An aggregate relationship */
  status_event_logs_aggregate: Learning_Module_Event_Log_Aggregate
  /** An array relationship */
  subscribers: Array<User_Group_Members>
  /** An aggregate relationship */
  subscribers_aggregate: User_Group_Members_Aggregate
  subscribers_group: Scalars['String']
  /** A computed field, executes function "learning_module_user_subscription_date" */
  subscription_date?: Maybe<Scalars['String']>
  /** An object relationship */
  subscription_settings?: Maybe<Learning_Series_Subscription_Settings>
  template_type?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  updated_by: Scalars['Int']
  /** An object relationship */
  updater: Users
  viewers?: Maybe<Scalars['jsonb']>
  /** An array relationship */
  viewers_group: Array<User_Group_Members>
  /** An aggregate relationship */
  viewers_group_aggregate: User_Group_Members_Aggregate
  visibility_group: Scalars['String']
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesChange_Event_LogsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_History_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_History_Order_By>>
  where?: InputMaybe<Learning_Module_History_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesChange_Event_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_History_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_History_Order_By>>
  where?: InputMaybe<Learning_Module_History_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesRating_Response_AveragesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesRating_Response_Averages_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesRating_ResponsesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Module_Rating_Responses_Order_By>>
  where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesRating_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Module_Rating_Responses_Order_By>>
  where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesRatingsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Module_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Module_Ratings_Order_By>>
  where?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesRatings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Module_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Module_Ratings_Order_By>>
  where?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesReflectionsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Reflections_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Reflections_Order_By>>
  where?: InputMaybe<Learning_Learner_Reflections_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesReflections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Reflections_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Reflections_Order_By>>
  where?: InputMaybe<Learning_Learner_Reflections_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesRevisionsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revisions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revisions_Order_By>>
  where?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesRevisions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revisions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revisions_Order_By>>
  where?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesSessionsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Sessions_Order_By>>
  where?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesSessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Sessions_Order_By>>
  where?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesStatus_Event_LogsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesStatus_Event_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesSubscribersArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesSubscribers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesViewersArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesViewers_GroupArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** columns and relationships of "learning_modules" */
export type Learning_ModulesViewers_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** aggregated selection of "learning_modules" */
export type Learning_Modules_Aggregate = {
  __typename?: 'learning_modules_aggregate'
  aggregate?: Maybe<Learning_Modules_Aggregate_Fields>
  nodes: Array<Learning_Modules>
}

export type Learning_Modules_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Learning_Modules_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Learning_Modules_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Learning_Modules_Aggregate_Bool_Exp_Count>
}

export type Learning_Modules_Aggregate_Bool_Exp_Bool_And = {
  arguments: Learning_Modules_Select_Column_Learning_Modules_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Modules_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Learning_Modules_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Learning_Modules_Select_Column_Learning_Modules_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Modules_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Learning_Modules_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Modules_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Modules_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_modules" */
export type Learning_Modules_Aggregate_Fields = {
  __typename?: 'learning_modules_aggregate_fields'
  avg?: Maybe<Learning_Modules_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Modules_Max_Fields>
  min?: Maybe<Learning_Modules_Min_Fields>
  stddev?: Maybe<Learning_Modules_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Modules_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Modules_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Modules_Sum_Fields>
  var_pop?: Maybe<Learning_Modules_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Modules_Var_Samp_Fields>
  variance?: Maybe<Learning_Modules_Variance_Fields>
}

/** aggregate fields of "learning_modules" */
export type Learning_Modules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Modules_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_modules" */
export type Learning_Modules_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Modules_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Modules_Max_Order_By>
  min?: InputMaybe<Learning_Modules_Min_Order_By>
  stddev?: InputMaybe<Learning_Modules_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Modules_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Modules_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Modules_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Modules_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Modules_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Modules_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Learning_Modules_Append_Input = {
  viewers?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "learning_modules" */
export type Learning_Modules_Arr_Rel_Insert_Input = {
  data: Array<Learning_Modules_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Modules_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Modules_Avg_Fields = {
  __typename?: 'learning_modules_avg_fields'
  author_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  /** A computed field, executes function "count_failed_attempts" */
  failed_attempts?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_modules" */
export type Learning_Modules_Avg_Order_By = {
  author_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_modules". All fields are combined with a logical 'AND'. */
export type Learning_Modules_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Modules_Bool_Exp>>
  _not?: InputMaybe<Learning_Modules_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Modules_Bool_Exp>>
  author?: InputMaybe<Users_Bool_Exp>
  author_id?: InputMaybe<Int_Comparison_Exp>
  can_approve?: InputMaybe<Boolean_Comparison_Exp>
  can_edit?: InputMaybe<Boolean_Comparison_Exp>
  can_publish?: InputMaybe<Boolean_Comparison_Exp>
  change_event_logs?: InputMaybe<Learning_Module_History_Bool_Exp>
  change_event_logs_aggregate?: InputMaybe<Learning_Module_History_Aggregate_Bool_Exp>
  copied_from_module_id?: InputMaybe<Uuid_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  creator?: InputMaybe<Users_Bool_Exp>
  ever_completed?: InputMaybe<Boolean_Comparison_Exp>
  failed_attempts?: InputMaybe<Int_Comparison_Exp>
  guest_access?: InputMaybe<Boolean_Comparison_Exp>
  has_passed?: InputMaybe<Boolean_Comparison_Exp>
  has_subscription?: InputMaybe<Boolean_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  latest_revision?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
  latest_revision_id?: InputMaybe<Uuid_Comparison_Exp>
  position?: InputMaybe<Int_Comparison_Exp>
  price?: InputMaybe<Numeric_Comparison_Exp>
  published_at?: InputMaybe<Timestamptz_Comparison_Exp>
  published_revision?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
  published_revision_id?: InputMaybe<Uuid_Comparison_Exp>
  rating_response_averages?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  rating_response_averages_aggregate?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp>
  rating_responses?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  rating_responses_aggregate?: InputMaybe<Learning_Stats_Module_Rating_Responses_Aggregate_Bool_Exp>
  ratings?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
  ratings_aggregate?: InputMaybe<Learning_Learner_Module_Ratings_Aggregate_Bool_Exp>
  reached_max_attempts?: InputMaybe<Boolean_Comparison_Exp>
  reflections?: InputMaybe<Learning_Learner_Reflections_Bool_Exp>
  reflections_aggregate?: InputMaybe<Learning_Learner_Reflections_Aggregate_Bool_Exp>
  revisions?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
  revisions_aggregate?: InputMaybe<Learning_Module_Revisions_Aggregate_Bool_Exp>
  segment_id?: InputMaybe<Int_Comparison_Exp>
  segment_member?: InputMaybe<Segment_User_Bool_Exp>
  series?: InputMaybe<Learning_Series_Bool_Exp>
  series_id?: InputMaybe<Uuid_Comparison_Exp>
  session_stats?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  sessions?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
  sessions_aggregate?: InputMaybe<Learning_Learner_Sessions_Aggregate_Bool_Exp>
  sessions_stats?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  slug?: InputMaybe<String_Comparison_Exp>
  star_rating?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
  status?: InputMaybe<Learning_Module_Status_Comparison_Exp>
  status_event_log?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
  status_event_log_id?: InputMaybe<Uuid_Comparison_Exp>
  status_event_logs?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
  status_event_logs_aggregate?: InputMaybe<Learning_Module_Event_Log_Aggregate_Bool_Exp>
  subscribers?: InputMaybe<User_Group_Members_Bool_Exp>
  subscribers_aggregate?: InputMaybe<User_Group_Members_Aggregate_Bool_Exp>
  subscribers_group?: InputMaybe<String_Comparison_Exp>
  subscription_date?: InputMaybe<String_Comparison_Exp>
  subscription_settings?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
  template_type?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
  updater?: InputMaybe<Users_Bool_Exp>
  viewers?: InputMaybe<Jsonb_Comparison_Exp>
  viewers_group?: InputMaybe<User_Group_Members_Bool_Exp>
  viewers_group_aggregate?: InputMaybe<User_Group_Members_Aggregate_Bool_Exp>
  visibility_group?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_modules" */
export enum Learning_Modules_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningModulesModuleIdKey = 'learning_modules_module_id_key',
  /** unique or primary key constraint on columns "id" */
  LearningModulesPkey = 'learning_modules_pkey',
  /** unique or primary key constraint on columns "slug", "series_id" */
  LearningModulesSeriesIdSlugKey = 'learning_modules_series_id_slug_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Learning_Modules_Delete_At_Path_Input = {
  viewers?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Learning_Modules_Delete_Elem_Input = {
  viewers?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Learning_Modules_Delete_Key_Input = {
  viewers?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "learning_modules" */
export type Learning_Modules_Inc_Input = {
  author_id?: InputMaybe<Scalars['Int']>
  created_by?: InputMaybe<Scalars['Int']>
  position?: InputMaybe<Scalars['Int']>
  price?: InputMaybe<Scalars['numeric']>
  segment_id?: InputMaybe<Scalars['Int']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_modules" */
export type Learning_Modules_Insert_Input = {
  author?: InputMaybe<Users_Obj_Rel_Insert_Input>
  author_id?: InputMaybe<Scalars['Int']>
  change_event_logs?: InputMaybe<Learning_Module_History_Arr_Rel_Insert_Input>
  copied_from_module_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>
  guest_access?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  latest_revision?: InputMaybe<Learning_Module_Revisions_Obj_Rel_Insert_Input>
  latest_revision_id?: InputMaybe<Scalars['uuid']>
  position?: InputMaybe<Scalars['Int']>
  price?: InputMaybe<Scalars['numeric']>
  published_at?: InputMaybe<Scalars['timestamptz']>
  published_revision?: InputMaybe<Learning_Module_Revisions_Obj_Rel_Insert_Input>
  published_revision_id?: InputMaybe<Scalars['uuid']>
  rating_response_averages?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Arr_Rel_Insert_Input>
  rating_responses?: InputMaybe<Learning_Stats_Module_Rating_Responses_Arr_Rel_Insert_Input>
  ratings?: InputMaybe<Learning_Learner_Module_Ratings_Arr_Rel_Insert_Input>
  reflections?: InputMaybe<Learning_Learner_Reflections_Arr_Rel_Insert_Input>
  revisions?: InputMaybe<Learning_Module_Revisions_Arr_Rel_Insert_Input>
  segment_id?: InputMaybe<Scalars['Int']>
  segment_member?: InputMaybe<Segment_User_Obj_Rel_Insert_Input>
  series?: InputMaybe<Learning_Series_Obj_Rel_Insert_Input>
  series_id?: InputMaybe<Scalars['uuid']>
  session_stats?: InputMaybe<Learning_Stats_Sessions_By_Module_Obj_Rel_Insert_Input>
  sessions?: InputMaybe<Learning_Learner_Sessions_Arr_Rel_Insert_Input>
  sessions_stats?: InputMaybe<Learning_Stats_Sessions_By_Module_Obj_Rel_Insert_Input>
  slug?: InputMaybe<Scalars['String']>
  star_rating?: InputMaybe<Learning_Module_Star_Ratings_Obj_Rel_Insert_Input>
  status?: InputMaybe<Scalars['learning_module_status']>
  status_event_log?: InputMaybe<Learning_Module_Event_Log_Obj_Rel_Insert_Input>
  status_event_log_id?: InputMaybe<Scalars['uuid']>
  status_event_logs?: InputMaybe<Learning_Module_Event_Log_Arr_Rel_Insert_Input>
  subscribers?: InputMaybe<User_Group_Members_Arr_Rel_Insert_Input>
  subscribers_group?: InputMaybe<Scalars['String']>
  subscription_settings?: InputMaybe<Learning_Series_Subscription_Settings_Obj_Rel_Insert_Input>
  template_type?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  updater?: InputMaybe<Users_Obj_Rel_Insert_Input>
  viewers?: InputMaybe<Scalars['jsonb']>
  viewers_group?: InputMaybe<User_Group_Members_Arr_Rel_Insert_Input>
  visibility_group?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Learning_Modules_Max_Fields = {
  __typename?: 'learning_modules_max_fields'
  author_id?: Maybe<Scalars['Int']>
  copied_from_module_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  /** A computed field, executes function "count_failed_attempts" */
  failed_attempts?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  latest_revision_id?: Maybe<Scalars['uuid']>
  position?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['numeric']>
  published_at?: Maybe<Scalars['timestamptz']>
  published_revision_id?: Maybe<Scalars['uuid']>
  segment_id?: Maybe<Scalars['Int']>
  series_id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['learning_module_status']>
  status_event_log_id?: Maybe<Scalars['uuid']>
  subscribers_group?: Maybe<Scalars['String']>
  /** A computed field, executes function "learning_module_user_subscription_date" */
  subscription_date?: Maybe<Scalars['String']>
  template_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
  visibility_group?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "learning_modules" */
export type Learning_Modules_Max_Order_By = {
  author_id?: InputMaybe<Order_By>
  copied_from_module_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  latest_revision_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  published_at?: InputMaybe<Order_By>
  published_revision_id?: InputMaybe<Order_By>
  segment_id?: InputMaybe<Order_By>
  series_id?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  status_event_log_id?: InputMaybe<Order_By>
  subscribers_group?: InputMaybe<Order_By>
  template_type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  visibility_group?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Modules_Min_Fields = {
  __typename?: 'learning_modules_min_fields'
  author_id?: Maybe<Scalars['Int']>
  copied_from_module_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  /** A computed field, executes function "count_failed_attempts" */
  failed_attempts?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  latest_revision_id?: Maybe<Scalars['uuid']>
  position?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['numeric']>
  published_at?: Maybe<Scalars['timestamptz']>
  published_revision_id?: Maybe<Scalars['uuid']>
  segment_id?: Maybe<Scalars['Int']>
  series_id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['learning_module_status']>
  status_event_log_id?: Maybe<Scalars['uuid']>
  subscribers_group?: Maybe<Scalars['String']>
  /** A computed field, executes function "learning_module_user_subscription_date" */
  subscription_date?: Maybe<Scalars['String']>
  template_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
  visibility_group?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "learning_modules" */
export type Learning_Modules_Min_Order_By = {
  author_id?: InputMaybe<Order_By>
  copied_from_module_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  latest_revision_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  published_at?: InputMaybe<Order_By>
  published_revision_id?: InputMaybe<Order_By>
  segment_id?: InputMaybe<Order_By>
  series_id?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  status_event_log_id?: InputMaybe<Order_By>
  subscribers_group?: InputMaybe<Order_By>
  template_type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  visibility_group?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_modules" */
export type Learning_Modules_Mutation_Response = {
  __typename?: 'learning_modules_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Modules>
}

/** input type for inserting object relation for remote table "learning_modules" */
export type Learning_Modules_Obj_Rel_Insert_Input = {
  data: Learning_Modules_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Modules_On_Conflict>
}

/** on_conflict condition type for table "learning_modules" */
export type Learning_Modules_On_Conflict = {
  constraint: Learning_Modules_Constraint
  update_columns?: Array<Learning_Modules_Update_Column>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

/** Ordering options when selecting data from "learning_modules". */
export type Learning_Modules_Order_By = {
  author?: InputMaybe<Users_Order_By>
  author_id?: InputMaybe<Order_By>
  can_approve?: InputMaybe<Order_By>
  can_edit?: InputMaybe<Order_By>
  can_publish?: InputMaybe<Order_By>
  change_event_logs_aggregate?: InputMaybe<Learning_Module_History_Aggregate_Order_By>
  copied_from_module_id?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  creator?: InputMaybe<Users_Order_By>
  ever_completed?: InputMaybe<Order_By>
  failed_attempts?: InputMaybe<Order_By>
  guest_access?: InputMaybe<Order_By>
  has_passed?: InputMaybe<Order_By>
  has_subscription?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  latest_revision?: InputMaybe<Learning_Module_Revisions_Order_By>
  latest_revision_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  published_at?: InputMaybe<Order_By>
  published_revision?: InputMaybe<Learning_Module_Revisions_Order_By>
  published_revision_id?: InputMaybe<Order_By>
  rating_response_averages_aggregate?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Order_By>
  rating_responses_aggregate?: InputMaybe<Learning_Stats_Module_Rating_Responses_Aggregate_Order_By>
  ratings_aggregate?: InputMaybe<Learning_Learner_Module_Ratings_Aggregate_Order_By>
  reached_max_attempts?: InputMaybe<Order_By>
  reflections_aggregate?: InputMaybe<Learning_Learner_Reflections_Aggregate_Order_By>
  revisions_aggregate?: InputMaybe<Learning_Module_Revisions_Aggregate_Order_By>
  segment_id?: InputMaybe<Order_By>
  segment_member?: InputMaybe<Segment_User_Order_By>
  series?: InputMaybe<Learning_Series_Order_By>
  series_id?: InputMaybe<Order_By>
  session_stats?: InputMaybe<Learning_Stats_Sessions_By_Module_Order_By>
  sessions_aggregate?: InputMaybe<Learning_Learner_Sessions_Aggregate_Order_By>
  sessions_stats?: InputMaybe<Learning_Stats_Sessions_By_Module_Order_By>
  slug?: InputMaybe<Order_By>
  star_rating?: InputMaybe<Learning_Module_Star_Ratings_Order_By>
  status?: InputMaybe<Order_By>
  status_event_log?: InputMaybe<Learning_Module_Event_Log_Order_By>
  status_event_log_id?: InputMaybe<Order_By>
  status_event_logs_aggregate?: InputMaybe<Learning_Module_Event_Log_Aggregate_Order_By>
  subscribers_aggregate?: InputMaybe<User_Group_Members_Aggregate_Order_By>
  subscribers_group?: InputMaybe<Order_By>
  subscription_date?: InputMaybe<Order_By>
  subscription_settings?: InputMaybe<Learning_Series_Subscription_Settings_Order_By>
  template_type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  updater?: InputMaybe<Users_Order_By>
  viewers?: InputMaybe<Order_By>
  viewers_group_aggregate?: InputMaybe<User_Group_Members_Aggregate_Order_By>
  visibility_group?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_modules */
export type Learning_Modules_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Learning_Modules_Prepend_Input = {
  viewers?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "learning_modules" */
export enum Learning_Modules_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CopiedFromModuleId = 'copied_from_module_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GuestAccess = 'guest_access',
  /** column name */
  Id = 'id',
  /** column name */
  LatestRevisionId = 'latest_revision_id',
  /** column name */
  Position = 'position',
  /** column name */
  Price = 'price',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  PublishedRevisionId = 'published_revision_id',
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  StatusEventLogId = 'status_event_log_id',
  /** column name */
  SubscribersGroup = 'subscribers_group',
  /** column name */
  TemplateType = 'template_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Viewers = 'viewers',
  /** column name */
  VisibilityGroup = 'visibility_group',
}

/** select "learning_modules_aggregate_bool_exp_bool_and_arguments_columns" columns of table "learning_modules" */
export enum Learning_Modules_Select_Column_Learning_Modules_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  GuestAccess = 'guest_access',
}

/** select "learning_modules_aggregate_bool_exp_bool_or_arguments_columns" columns of table "learning_modules" */
export enum Learning_Modules_Select_Column_Learning_Modules_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  GuestAccess = 'guest_access',
}

/** input type for updating data in table "learning_modules" */
export type Learning_Modules_Set_Input = {
  author_id?: InputMaybe<Scalars['Int']>
  copied_from_module_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  guest_access?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  latest_revision_id?: InputMaybe<Scalars['uuid']>
  position?: InputMaybe<Scalars['Int']>
  price?: InputMaybe<Scalars['numeric']>
  published_at?: InputMaybe<Scalars['timestamptz']>
  published_revision_id?: InputMaybe<Scalars['uuid']>
  segment_id?: InputMaybe<Scalars['Int']>
  series_id?: InputMaybe<Scalars['uuid']>
  slug?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['learning_module_status']>
  status_event_log_id?: InputMaybe<Scalars['uuid']>
  subscribers_group?: InputMaybe<Scalars['String']>
  template_type?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  viewers?: InputMaybe<Scalars['jsonb']>
  visibility_group?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Learning_Modules_Stddev_Fields = {
  __typename?: 'learning_modules_stddev_fields'
  author_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  /** A computed field, executes function "count_failed_attempts" */
  failed_attempts?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_modules" */
export type Learning_Modules_Stddev_Order_By = {
  author_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Modules_Stddev_Pop_Fields = {
  __typename?: 'learning_modules_stddev_pop_fields'
  author_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  /** A computed field, executes function "count_failed_attempts" */
  failed_attempts?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_modules" */
export type Learning_Modules_Stddev_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Modules_Stddev_Samp_Fields = {
  __typename?: 'learning_modules_stddev_samp_fields'
  author_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  /** A computed field, executes function "count_failed_attempts" */
  failed_attempts?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_modules" */
export type Learning_Modules_Stddev_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_modules" */
export type Learning_Modules_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Modules_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Modules_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['Int']>
  copied_from_module_id?: InputMaybe<Scalars['uuid']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  guest_access?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  latest_revision_id?: InputMaybe<Scalars['uuid']>
  position?: InputMaybe<Scalars['Int']>
  price?: InputMaybe<Scalars['numeric']>
  published_at?: InputMaybe<Scalars['timestamptz']>
  published_revision_id?: InputMaybe<Scalars['uuid']>
  segment_id?: InputMaybe<Scalars['Int']>
  series_id?: InputMaybe<Scalars['uuid']>
  slug?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['learning_module_status']>
  status_event_log_id?: InputMaybe<Scalars['uuid']>
  subscribers_group?: InputMaybe<Scalars['String']>
  template_type?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  viewers?: InputMaybe<Scalars['jsonb']>
  visibility_group?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Learning_Modules_Sum_Fields = {
  __typename?: 'learning_modules_sum_fields'
  author_id?: Maybe<Scalars['Int']>
  created_by?: Maybe<Scalars['Int']>
  /** A computed field, executes function "count_failed_attempts" */
  failed_attempts?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['numeric']>
  segment_id?: Maybe<Scalars['Int']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_modules" */
export type Learning_Modules_Sum_Order_By = {
  author_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** update columns of table "learning_modules" */
export enum Learning_Modules_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CopiedFromModuleId = 'copied_from_module_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GuestAccess = 'guest_access',
  /** column name */
  Id = 'id',
  /** column name */
  LatestRevisionId = 'latest_revision_id',
  /** column name */
  Position = 'position',
  /** column name */
  Price = 'price',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  PublishedRevisionId = 'published_revision_id',
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  StatusEventLogId = 'status_event_log_id',
  /** column name */
  SubscribersGroup = 'subscribers_group',
  /** column name */
  TemplateType = 'template_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Viewers = 'viewers',
  /** column name */
  VisibilityGroup = 'visibility_group',
}

export type Learning_Modules_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Learning_Modules_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Learning_Modules_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Learning_Modules_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Learning_Modules_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Modules_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Learning_Modules_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Modules_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Modules_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Modules_Var_Pop_Fields = {
  __typename?: 'learning_modules_var_pop_fields'
  author_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  /** A computed field, executes function "count_failed_attempts" */
  failed_attempts?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_modules" */
export type Learning_Modules_Var_Pop_Order_By = {
  author_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Modules_Var_Samp_Fields = {
  __typename?: 'learning_modules_var_samp_fields'
  author_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  /** A computed field, executes function "count_failed_attempts" */
  failed_attempts?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_modules" */
export type Learning_Modules_Var_Samp_Order_By = {
  author_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Modules_Variance_Fields = {
  __typename?: 'learning_modules_variance_fields'
  author_id?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  /** A computed field, executes function "count_failed_attempts" */
  failed_attempts?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Float']>
  price?: Maybe<Scalars['Float']>
  segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_modules" */
export type Learning_Modules_Variance_Order_By = {
  author_id?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  price?: InputMaybe<Order_By>
  segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** columns and relationships of "learning_pages" */
export type Learning_Pages = {
  __typename?: 'learning_pages'
  body: Scalars['jsonb']
  contains_mcq: Scalars['Boolean']
  contains_poll: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  created_by: Scalars['Int']
  /** An object relationship */
  creator: Users
  id: Scalars['String']
  module_id?: Maybe<Scalars['uuid']>
  /** An array relationship */
  revision_pages: Array<Learning_Module_Revision_Pages>
  /** An aggregate relationship */
  revision_pages_aggregate: Learning_Module_Revision_Pages_Aggregate
  title?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  updated_by: Scalars['Int']
  /** An object relationship */
  updater: Users
}

/** columns and relationships of "learning_pages" */
export type Learning_PagesBodyArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "learning_pages" */
export type Learning_PagesRevision_PagesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revision_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revision_Pages_Order_By>>
  where?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
}

/** columns and relationships of "learning_pages" */
export type Learning_PagesRevision_Pages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revision_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revision_Pages_Order_By>>
  where?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
}

/** aggregated selection of "learning_pages" */
export type Learning_Pages_Aggregate = {
  __typename?: 'learning_pages_aggregate'
  aggregate?: Maybe<Learning_Pages_Aggregate_Fields>
  nodes: Array<Learning_Pages>
}

/** aggregate fields of "learning_pages" */
export type Learning_Pages_Aggregate_Fields = {
  __typename?: 'learning_pages_aggregate_fields'
  avg?: Maybe<Learning_Pages_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Pages_Max_Fields>
  min?: Maybe<Learning_Pages_Min_Fields>
  stddev?: Maybe<Learning_Pages_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Pages_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Pages_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Pages_Sum_Fields>
  var_pop?: Maybe<Learning_Pages_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Pages_Var_Samp_Fields>
  variance?: Maybe<Learning_Pages_Variance_Fields>
}

/** aggregate fields of "learning_pages" */
export type Learning_Pages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Pages_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Learning_Pages_Append_Input = {
  body?: InputMaybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Learning_Pages_Avg_Fields = {
  __typename?: 'learning_pages_avg_fields'
  created_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_pages". All fields are combined with a logical 'AND'. */
export type Learning_Pages_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Pages_Bool_Exp>>
  _not?: InputMaybe<Learning_Pages_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Pages_Bool_Exp>>
  body?: InputMaybe<Jsonb_Comparison_Exp>
  contains_mcq?: InputMaybe<Boolean_Comparison_Exp>
  contains_poll?: InputMaybe<Boolean_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  creator?: InputMaybe<Users_Bool_Exp>
  id?: InputMaybe<String_Comparison_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  revision_pages?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
  revision_pages_aggregate?: InputMaybe<Learning_Module_Revision_Pages_Aggregate_Bool_Exp>
  title?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
  updater?: InputMaybe<Users_Bool_Exp>
}

/** unique or primary key constraints on table "learning_pages" */
export enum Learning_Pages_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningPagesPkey = 'learning_pages_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Learning_Pages_Delete_At_Path_Input = {
  body?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Learning_Pages_Delete_Elem_Input = {
  body?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Learning_Pages_Delete_Key_Input = {
  body?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "learning_pages" */
export type Learning_Pages_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_pages" */
export type Learning_Pages_Insert_Input = {
  body?: InputMaybe<Scalars['jsonb']>
  contains_mcq?: InputMaybe<Scalars['Boolean']>
  contains_poll?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>
  id?: InputMaybe<Scalars['String']>
  module_id?: InputMaybe<Scalars['uuid']>
  revision_pages?: InputMaybe<Learning_Module_Revision_Pages_Arr_Rel_Insert_Input>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  updater?: InputMaybe<Users_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Learning_Pages_Max_Fields = {
  __typename?: 'learning_pages_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** aggregate min on columns */
export type Learning_Pages_Min_Fields = {
  __typename?: 'learning_pages_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** response of any mutation on the table "learning_pages" */
export type Learning_Pages_Mutation_Response = {
  __typename?: 'learning_pages_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Pages>
}

/** input type for inserting object relation for remote table "learning_pages" */
export type Learning_Pages_Obj_Rel_Insert_Input = {
  data: Learning_Pages_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Pages_On_Conflict>
}

/** on_conflict condition type for table "learning_pages" */
export type Learning_Pages_On_Conflict = {
  constraint: Learning_Pages_Constraint
  update_columns?: Array<Learning_Pages_Update_Column>
  where?: InputMaybe<Learning_Pages_Bool_Exp>
}

/** Ordering options when selecting data from "learning_pages". */
export type Learning_Pages_Order_By = {
  body?: InputMaybe<Order_By>
  contains_mcq?: InputMaybe<Order_By>
  contains_poll?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  creator?: InputMaybe<Users_Order_By>
  id?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  revision_pages_aggregate?: InputMaybe<Learning_Module_Revision_Pages_Aggregate_Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  updater?: InputMaybe<Users_Order_By>
}

/** primary key columns input for table: learning_pages */
export type Learning_Pages_Pk_Columns_Input = {
  id: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Learning_Pages_Prepend_Input = {
  body?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "learning_pages" */
export enum Learning_Pages_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  ContainsMcq = 'contains_mcq',
  /** column name */
  ContainsPoll = 'contains_poll',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** input type for updating data in table "learning_pages" */
export type Learning_Pages_Set_Input = {
  body?: InputMaybe<Scalars['jsonb']>
  contains_mcq?: InputMaybe<Scalars['Boolean']>
  contains_poll?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['String']>
  module_id?: InputMaybe<Scalars['uuid']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Learning_Pages_Stddev_Fields = {
  __typename?: 'learning_pages_stddev_fields'
  created_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Pages_Stddev_Pop_Fields = {
  __typename?: 'learning_pages_stddev_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Pages_Stddev_Samp_Fields = {
  __typename?: 'learning_pages_stddev_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_pages" */
export type Learning_Pages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Pages_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Pages_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['jsonb']>
  contains_mcq?: InputMaybe<Scalars['Boolean']>
  contains_poll?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['String']>
  module_id?: InputMaybe<Scalars['uuid']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Learning_Pages_Sum_Fields = {
  __typename?: 'learning_pages_sum_fields'
  created_by?: Maybe<Scalars['Int']>
  updated_by?: Maybe<Scalars['Int']>
}

/** update columns of table "learning_pages" */
export enum Learning_Pages_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  ContainsMcq = 'contains_mcq',
  /** column name */
  ContainsPoll = 'contains_poll',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Learning_Pages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Learning_Pages_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Learning_Pages_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Learning_Pages_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Learning_Pages_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Pages_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Learning_Pages_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Pages_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Pages_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Pages_Var_Pop_Fields = {
  __typename?: 'learning_pages_var_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Pages_Var_Samp_Fields = {
  __typename?: 'learning_pages_var_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Pages_Variance_Fields = {
  __typename?: 'learning_pages_variance_fields'
  created_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** Boolean expression to compare columns of type "learning_pass_mark_type". All fields are combined with logical 'AND'. */
export type Learning_Pass_Mark_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_pass_mark_type']>
  _gt?: InputMaybe<Scalars['learning_pass_mark_type']>
  _gte?: InputMaybe<Scalars['learning_pass_mark_type']>
  _in?: InputMaybe<Array<Scalars['learning_pass_mark_type']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['learning_pass_mark_type']>
  _lte?: InputMaybe<Scalars['learning_pass_mark_type']>
  _neq?: InputMaybe<Scalars['learning_pass_mark_type']>
  _nin?: InputMaybe<Array<Scalars['learning_pass_mark_type']>>
}

/** Boolean expression to compare columns of type "learning_question_scoring_method". All fields are combined with logical 'AND'. */
export type Learning_Question_Scoring_Method_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['learning_question_scoring_method']>
  _gt?: InputMaybe<Scalars['learning_question_scoring_method']>
  _gte?: InputMaybe<Scalars['learning_question_scoring_method']>
  _in?: InputMaybe<Array<Scalars['learning_question_scoring_method']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['learning_question_scoring_method']>
  _lte?: InputMaybe<Scalars['learning_question_scoring_method']>
  _neq?: InputMaybe<Scalars['learning_question_scoring_method']>
  _nin?: InputMaybe<Array<Scalars['learning_question_scoring_method']>>
}

/** columns and relationships of "learning_questions" */
export type Learning_Questions = {
  __typename?: 'learning_questions'
  answers?: Maybe<Scalars['jsonb']>
  feedback?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  mark_per: Scalars['learning_question_scoring_method']
  module_id?: Maybe<Scalars['uuid']>
  page_id: Scalars['String']
  question: Scalars['String']
}

/** columns and relationships of "learning_questions" */
export type Learning_QuestionsAnswersArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "learning_questions" */
export type Learning_Questions_Aggregate = {
  __typename?: 'learning_questions_aggregate'
  aggregate?: Maybe<Learning_Questions_Aggregate_Fields>
  nodes: Array<Learning_Questions>
}

/** aggregate fields of "learning_questions" */
export type Learning_Questions_Aggregate_Fields = {
  __typename?: 'learning_questions_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Learning_Questions_Max_Fields>
  min?: Maybe<Learning_Questions_Min_Fields>
}

/** aggregate fields of "learning_questions" */
export type Learning_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Questions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Learning_Questions_Append_Input = {
  answers?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "learning_questions". All fields are combined with a logical 'AND'. */
export type Learning_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Questions_Bool_Exp>>
  _not?: InputMaybe<Learning_Questions_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Questions_Bool_Exp>>
  answers?: InputMaybe<Jsonb_Comparison_Exp>
  feedback?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  mark_per?: InputMaybe<Learning_Question_Scoring_Method_Comparison_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  page_id?: InputMaybe<String_Comparison_Exp>
  question?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_questions" */
export enum Learning_Questions_Constraint {
  /** unique or primary key constraint on columns "id", "page_id" */
  LearningQuestionsPkey = 'learning_questions_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Learning_Questions_Delete_At_Path_Input = {
  answers?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Learning_Questions_Delete_Elem_Input = {
  answers?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Learning_Questions_Delete_Key_Input = {
  answers?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "learning_questions" */
export type Learning_Questions_Insert_Input = {
  answers?: InputMaybe<Scalars['jsonb']>
  feedback?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  mark_per?: InputMaybe<Scalars['learning_question_scoring_method']>
  module_id?: InputMaybe<Scalars['uuid']>
  page_id?: InputMaybe<Scalars['String']>
  question?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Learning_Questions_Max_Fields = {
  __typename?: 'learning_questions_max_fields'
  feedback?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  mark_per?: Maybe<Scalars['learning_question_scoring_method']>
  module_id?: Maybe<Scalars['uuid']>
  page_id?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Learning_Questions_Min_Fields = {
  __typename?: 'learning_questions_min_fields'
  feedback?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  mark_per?: Maybe<Scalars['learning_question_scoring_method']>
  module_id?: Maybe<Scalars['uuid']>
  page_id?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "learning_questions" */
export type Learning_Questions_Mutation_Response = {
  __typename?: 'learning_questions_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Questions>
}

/** input type for inserting object relation for remote table "learning_questions" */
export type Learning_Questions_Obj_Rel_Insert_Input = {
  data: Learning_Questions_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Questions_On_Conflict>
}

/** on_conflict condition type for table "learning_questions" */
export type Learning_Questions_On_Conflict = {
  constraint: Learning_Questions_Constraint
  update_columns?: Array<Learning_Questions_Update_Column>
  where?: InputMaybe<Learning_Questions_Bool_Exp>
}

/** Ordering options when selecting data from "learning_questions". */
export type Learning_Questions_Order_By = {
  answers?: InputMaybe<Order_By>
  feedback?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  mark_per?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
  question?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_questions */
export type Learning_Questions_Pk_Columns_Input = {
  id: Scalars['uuid']
  page_id: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Learning_Questions_Prepend_Input = {
  answers?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "learning_questions" */
export enum Learning_Questions_Select_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  Id = 'id',
  /** column name */
  MarkPer = 'mark_per',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  PageId = 'page_id',
  /** column name */
  Question = 'question',
}

/** input type for updating data in table "learning_questions" */
export type Learning_Questions_Set_Input = {
  answers?: InputMaybe<Scalars['jsonb']>
  feedback?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  mark_per?: InputMaybe<Scalars['learning_question_scoring_method']>
  module_id?: InputMaybe<Scalars['uuid']>
  page_id?: InputMaybe<Scalars['String']>
  question?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "learning_questions" */
export type Learning_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Questions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Questions_Stream_Cursor_Value_Input = {
  answers?: InputMaybe<Scalars['jsonb']>
  feedback?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['uuid']>
  mark_per?: InputMaybe<Scalars['learning_question_scoring_method']>
  module_id?: InputMaybe<Scalars['uuid']>
  page_id?: InputMaybe<Scalars['String']>
  question?: InputMaybe<Scalars['String']>
}

/** update columns of table "learning_questions" */
export enum Learning_Questions_Update_Column {
  /** column name */
  Answers = 'answers',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  Id = 'id',
  /** column name */
  MarkPer = 'mark_per',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  PageId = 'page_id',
  /** column name */
  Question = 'question',
}

export type Learning_Questions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Learning_Questions_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Learning_Questions_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Learning_Questions_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Learning_Questions_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Learning_Questions_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Questions_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Questions_Bool_Exp
}

/** columns and relationships of "learning_series" */
export type Learning_Series = {
  __typename?: 'learning_series'
  accreditation_when: Scalars['String']
  can_approve?: Maybe<Scalars['Boolean']>
  can_edit?: Maybe<Scalars['Boolean']>
  can_publish?: Maybe<Scalars['Boolean']>
  certificate_content?: Maybe<Scalars['jsonb']>
  certificate_template: Scalars['String']
  cme_accreditor?: Maybe<Scalars['String']>
  cme_credits_description?: Maybe<Scalars['String']>
  cme_expires_after_seconds?: Maybe<Scalars['Int']>
  cme_expires_at?: Maybe<Scalars['timestamptz']>
  cme_reference?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  created_by: Scalars['Int']
  /** An object relationship */
  creator: Users
  description?: Maybe<Scalars['String']>
  discount: Scalars['Boolean']
  /** An array relationship */
  discount_settings: Array<Learning_Series_Discount_Settings>
  /** An aggregate relationship */
  discount_settings_aggregate: Learning_Series_Discount_Settings_Aggregate
  display_author?: Maybe<Scalars['String']>
  /** An object relationship */
  editor?: Maybe<Users>
  editor_id?: Maybe<Scalars['Int']>
  editor_publish: Scalars['Boolean']
  enable_email_notifications: Scalars['Boolean']
  force_completion_order: Scalars['Boolean']
  /** A computed field, executes function "learning_series_session_user_has_subscription" */
  has_subscription?: Maybe<Scalars['Boolean']>
  id: Scalars['uuid']
  /** An object relationship */
  image?: Maybe<Learning_Uploads>
  image_id?: Maybe<Scalars['Int']>
  is_library_admin?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  library?: Maybe<Groups>
  /** An array relationship */
  library_admins: Array<User_Group_Members_Roles>
  /** An aggregate relationship */
  library_admins_aggregate: User_Group_Members_Roles_Aggregate
  library_id: Scalars['String']
  /** An array relationship */
  library_members: Array<User_Group_Members>
  /** An aggregate relationship */
  library_members_aggregate: User_Group_Members_Aggregate
  library_type: Scalars['String']
  /** An array relationship */
  modules: Array<Learning_Modules>
  /** An aggregate relationship */
  modules_aggregate: Learning_Modules_Aggregate
  notification_email?: Maybe<Scalars['String']>
  payments_enabled: Scalars['Boolean']
  /** An object relationship */
  publisher?: Maybe<Users>
  publisher_id?: Maybe<Scalars['Int']>
  rich_description?: Maybe<Scalars['String']>
  series_type?: Maybe<Scalars['String']>
  slug: Scalars['String']
  sort_modules_by: Scalars['learning_module_sort_order']
  /** An object relationship */
  star_rating?: Maybe<Learning_Series_Star_Ratings>
  status: Scalars['String']
  /** An array relationship */
  subscribers: Array<User_Group_Members>
  /** An aggregate relationship */
  subscribers_aggregate: User_Group_Members_Aggregate
  subscribers_group: Scalars['String']
  /** A computed field, executes function "learning_series_session_user_subscription_date" */
  subscription_date?: Maybe<Scalars['String']>
  /** An object relationship */
  subscription_settings?: Maybe<Learning_Series_Subscription_Settings>
  title: Scalars['String']
  updated_at: Scalars['timestamptz']
  updated_by: Scalars['Int']
  /** An object relationship */
  updater: Users
}

/** columns and relationships of "learning_series" */
export type Learning_SeriesCertificate_ContentArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "learning_series" */
export type Learning_SeriesDiscount_SettingsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Discount_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
}

/** columns and relationships of "learning_series" */
export type Learning_SeriesDiscount_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Discount_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
}

/** columns and relationships of "learning_series" */
export type Learning_SeriesLibrary_AdminsArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Roles_Order_By>>
  where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
}

/** columns and relationships of "learning_series" */
export type Learning_SeriesLibrary_Admins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Roles_Order_By>>
  where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
}

/** columns and relationships of "learning_series" */
export type Learning_SeriesLibrary_MembersArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** columns and relationships of "learning_series" */
export type Learning_SeriesLibrary_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** columns and relationships of "learning_series" */
export type Learning_SeriesModulesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Modules_Order_By>>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

/** columns and relationships of "learning_series" */
export type Learning_SeriesModules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Modules_Order_By>>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

/** columns and relationships of "learning_series" */
export type Learning_SeriesSubscribersArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** columns and relationships of "learning_series" */
export type Learning_SeriesSubscribers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** aggregated selection of "learning_series" */
export type Learning_Series_Aggregate = {
  __typename?: 'learning_series_aggregate'
  aggregate?: Maybe<Learning_Series_Aggregate_Fields>
  nodes: Array<Learning_Series>
}

export type Learning_Series_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Learning_Series_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Learning_Series_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Learning_Series_Aggregate_Bool_Exp_Count>
}

export type Learning_Series_Aggregate_Bool_Exp_Bool_And = {
  arguments: Learning_Series_Select_Column_Learning_Series_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Series_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Learning_Series_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Learning_Series_Select_Column_Learning_Series_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Series_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Learning_Series_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Series_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Series_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_series" */
export type Learning_Series_Aggregate_Fields = {
  __typename?: 'learning_series_aggregate_fields'
  avg?: Maybe<Learning_Series_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Series_Max_Fields>
  min?: Maybe<Learning_Series_Min_Fields>
  stddev?: Maybe<Learning_Series_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Series_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Series_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Series_Sum_Fields>
  var_pop?: Maybe<Learning_Series_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Series_Var_Samp_Fields>
  variance?: Maybe<Learning_Series_Variance_Fields>
}

/** aggregate fields of "learning_series" */
export type Learning_Series_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Series_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_series" */
export type Learning_Series_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Series_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Series_Max_Order_By>
  min?: InputMaybe<Learning_Series_Min_Order_By>
  stddev?: InputMaybe<Learning_Series_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Series_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Series_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Series_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Series_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Series_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Series_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Learning_Series_Append_Input = {
  certificate_content?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "learning_series" */
export type Learning_Series_Arr_Rel_Insert_Input = {
  data: Array<Learning_Series_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Series_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Series_Avg_Fields = {
  __typename?: 'learning_series_avg_fields'
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  editor_id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  publisher_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_series" */
export type Learning_Series_Avg_Order_By = {
  cme_expires_after_seconds?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  editor_id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  publisher_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_series". All fields are combined with a logical 'AND'. */
export type Learning_Series_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Series_Bool_Exp>>
  _not?: InputMaybe<Learning_Series_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Series_Bool_Exp>>
  accreditation_when?: InputMaybe<String_Comparison_Exp>
  can_approve?: InputMaybe<Boolean_Comparison_Exp>
  can_edit?: InputMaybe<Boolean_Comparison_Exp>
  can_publish?: InputMaybe<Boolean_Comparison_Exp>
  certificate_content?: InputMaybe<Jsonb_Comparison_Exp>
  certificate_template?: InputMaybe<String_Comparison_Exp>
  cme_accreditor?: InputMaybe<String_Comparison_Exp>
  cme_credits_description?: InputMaybe<String_Comparison_Exp>
  cme_expires_after_seconds?: InputMaybe<Int_Comparison_Exp>
  cme_expires_at?: InputMaybe<Timestamptz_Comparison_Exp>
  cme_reference?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  creator?: InputMaybe<Users_Bool_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  discount?: InputMaybe<Boolean_Comparison_Exp>
  discount_settings?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
  discount_settings_aggregate?: InputMaybe<Learning_Series_Discount_Settings_Aggregate_Bool_Exp>
  display_author?: InputMaybe<String_Comparison_Exp>
  editor?: InputMaybe<Users_Bool_Exp>
  editor_id?: InputMaybe<Int_Comparison_Exp>
  editor_publish?: InputMaybe<Boolean_Comparison_Exp>
  enable_email_notifications?: InputMaybe<Boolean_Comparison_Exp>
  force_completion_order?: InputMaybe<Boolean_Comparison_Exp>
  has_subscription?: InputMaybe<Boolean_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  image?: InputMaybe<Learning_Uploads_Bool_Exp>
  image_id?: InputMaybe<Int_Comparison_Exp>
  is_library_admin?: InputMaybe<Boolean_Comparison_Exp>
  library?: InputMaybe<Groups_Bool_Exp>
  library_admins?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
  library_admins_aggregate?: InputMaybe<User_Group_Members_Roles_Aggregate_Bool_Exp>
  library_id?: InputMaybe<String_Comparison_Exp>
  library_members?: InputMaybe<User_Group_Members_Bool_Exp>
  library_members_aggregate?: InputMaybe<User_Group_Members_Aggregate_Bool_Exp>
  library_type?: InputMaybe<String_Comparison_Exp>
  modules?: InputMaybe<Learning_Modules_Bool_Exp>
  modules_aggregate?: InputMaybe<Learning_Modules_Aggregate_Bool_Exp>
  notification_email?: InputMaybe<String_Comparison_Exp>
  payments_enabled?: InputMaybe<Boolean_Comparison_Exp>
  publisher?: InputMaybe<Users_Bool_Exp>
  publisher_id?: InputMaybe<Int_Comparison_Exp>
  rich_description?: InputMaybe<String_Comparison_Exp>
  series_type?: InputMaybe<String_Comparison_Exp>
  slug?: InputMaybe<String_Comparison_Exp>
  sort_modules_by?: InputMaybe<Learning_Module_Sort_Order_Comparison_Exp>
  star_rating?: InputMaybe<Learning_Series_Star_Ratings_Bool_Exp>
  status?: InputMaybe<String_Comparison_Exp>
  subscribers?: InputMaybe<User_Group_Members_Bool_Exp>
  subscribers_aggregate?: InputMaybe<User_Group_Members_Aggregate_Bool_Exp>
  subscribers_group?: InputMaybe<String_Comparison_Exp>
  subscription_date?: InputMaybe<String_Comparison_Exp>
  subscription_settings?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
  title?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
  updater?: InputMaybe<Users_Bool_Exp>
}

/** unique or primary key constraints on table "learning_series" */
export enum Learning_Series_Constraint {
  /** unique or primary key constraint on columns "library_type", "slug", "library_id" */
  LearningSeriesLibraryTypeLibraryIdSlugKey = 'learning_series_library_type_library_id_slug_key',
  /** unique or primary key constraint on columns "id" */
  LearningSeriesPkey = 'learning_series_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Learning_Series_Delete_At_Path_Input = {
  certificate_content?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Learning_Series_Delete_Elem_Input = {
  certificate_content?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Learning_Series_Delete_Key_Input = {
  certificate_content?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups = {
  __typename?: 'learning_series_discount_groups'
  discount_id: Scalars['uuid']
  /** An object relationship */
  discount_settings: Learning_Series_Discount_Settings
  /** An object relationship */
  group: Groups
  group_id: Scalars['String']
  /** An array relationship */
  group_members: Array<User_Group_Members>
  /** An aggregate relationship */
  group_members_aggregate: User_Group_Members_Aggregate
}

/** columns and relationships of "learning_series_discount_groups" */
export type Learning_Series_Discount_GroupsGroup_MembersArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** columns and relationships of "learning_series_discount_groups" */
export type Learning_Series_Discount_GroupsGroup_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** aggregated selection of "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_Aggregate = {
  __typename?: 'learning_series_discount_groups_aggregate'
  aggregate?: Maybe<Learning_Series_Discount_Groups_Aggregate_Fields>
  nodes: Array<Learning_Series_Discount_Groups>
}

export type Learning_Series_Discount_Groups_Aggregate_Bool_Exp = {
  count?: InputMaybe<Learning_Series_Discount_Groups_Aggregate_Bool_Exp_Count>
}

export type Learning_Series_Discount_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Series_Discount_Groups_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Series_Discount_Groups_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_Aggregate_Fields = {
  __typename?: 'learning_series_discount_groups_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Learning_Series_Discount_Groups_Max_Fields>
  min?: Maybe<Learning_Series_Discount_Groups_Min_Fields>
}

/** aggregate fields of "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Series_Discount_Groups_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Series_Discount_Groups_Max_Order_By>
  min?: InputMaybe<Learning_Series_Discount_Groups_Min_Order_By>
}

/** input type for inserting array relation for remote table "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_Arr_Rel_Insert_Input = {
  data: Array<Learning_Series_Discount_Groups_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Series_Discount_Groups_On_Conflict>
}

/** Boolean expression to filter rows from the table "learning_series_discount_groups". All fields are combined with a logical 'AND'. */
export type Learning_Series_Discount_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Series_Discount_Groups_Bool_Exp>>
  _not?: InputMaybe<Learning_Series_Discount_Groups_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Series_Discount_Groups_Bool_Exp>>
  discount_id?: InputMaybe<Uuid_Comparison_Exp>
  discount_settings?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
  group?: InputMaybe<Groups_Bool_Exp>
  group_id?: InputMaybe<String_Comparison_Exp>
  group_members?: InputMaybe<User_Group_Members_Bool_Exp>
  group_members_aggregate?: InputMaybe<User_Group_Members_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "learning_series_discount_groups" */
export enum Learning_Series_Discount_Groups_Constraint {
  /** unique or primary key constraint on columns "group_id", "discount_id" */
  LearningSeriesDiscountGroupsPkey = 'learning_series_discount_groups_pkey',
}

/** input type for inserting data into table "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_Insert_Input = {
  discount_id?: InputMaybe<Scalars['uuid']>
  discount_settings?: InputMaybe<Learning_Series_Discount_Settings_Obj_Rel_Insert_Input>
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>
  group_id?: InputMaybe<Scalars['String']>
  group_members?: InputMaybe<User_Group_Members_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Learning_Series_Discount_Groups_Max_Fields = {
  __typename?: 'learning_series_discount_groups_max_fields'
  discount_id?: Maybe<Scalars['uuid']>
  group_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_Max_Order_By = {
  discount_id?: InputMaybe<Order_By>
  group_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Series_Discount_Groups_Min_Fields = {
  __typename?: 'learning_series_discount_groups_min_fields'
  discount_id?: Maybe<Scalars['uuid']>
  group_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_Min_Order_By = {
  discount_id?: InputMaybe<Order_By>
  group_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_Mutation_Response = {
  __typename?: 'learning_series_discount_groups_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Series_Discount_Groups>
}

/** on_conflict condition type for table "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_On_Conflict = {
  constraint: Learning_Series_Discount_Groups_Constraint
  update_columns?: Array<Learning_Series_Discount_Groups_Update_Column>
  where?: InputMaybe<Learning_Series_Discount_Groups_Bool_Exp>
}

/** Ordering options when selecting data from "learning_series_discount_groups". */
export type Learning_Series_Discount_Groups_Order_By = {
  discount_id?: InputMaybe<Order_By>
  discount_settings?: InputMaybe<Learning_Series_Discount_Settings_Order_By>
  group?: InputMaybe<Groups_Order_By>
  group_id?: InputMaybe<Order_By>
  group_members_aggregate?: InputMaybe<User_Group_Members_Aggregate_Order_By>
}

/** primary key columns input for table: learning_series_discount_groups */
export type Learning_Series_Discount_Groups_Pk_Columns_Input = {
  discount_id: Scalars['uuid']
  group_id: Scalars['String']
}

/** select columns of table "learning_series_discount_groups" */
export enum Learning_Series_Discount_Groups_Select_Column {
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  GroupId = 'group_id',
}

/** input type for updating data in table "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_Set_Input = {
  discount_id?: InputMaybe<Scalars['uuid']>
  group_id?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "learning_series_discount_groups" */
export type Learning_Series_Discount_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Series_Discount_Groups_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Series_Discount_Groups_Stream_Cursor_Value_Input = {
  discount_id?: InputMaybe<Scalars['uuid']>
  group_id?: InputMaybe<Scalars['String']>
}

/** update columns of table "learning_series_discount_groups" */
export enum Learning_Series_Discount_Groups_Update_Column {
  /** column name */
  DiscountId = 'discount_id',
  /** column name */
  GroupId = 'group_id',
}

export type Learning_Series_Discount_Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Series_Discount_Groups_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Series_Discount_Groups_Bool_Exp
}

/** columns and relationships of "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings = {
  __typename?: 'learning_series_discount_settings'
  created_at: Scalars['timestamptz']
  created_by: Scalars['Int']
  /** An array relationship */
  discount_groups: Array<Learning_Series_Discount_Groups>
  /** An aggregate relationship */
  discount_groups_aggregate: Learning_Series_Discount_Groups_Aggregate
  discount_percentage: Scalars['numeric']
  discount_segment_id?: Maybe<Scalars['numeric']>
  discount_type: Learning_Series_Subscription_Settings_Discount_Type_Enum
  id: Scalars['uuid']
  /** An object relationship */
  learning_series: Learning_Series
  series_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  updated_by: Scalars['Int']
  /** An object relationship */
  user: Users
  /** An object relationship */
  userByUpdatedBy: Users
}

/** columns and relationships of "learning_series_discount_settings" */
export type Learning_Series_Discount_SettingsDiscount_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Discount_Groups_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Groups_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Groups_Bool_Exp>
}

/** columns and relationships of "learning_series_discount_settings" */
export type Learning_Series_Discount_SettingsDiscount_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Discount_Groups_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Groups_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Groups_Bool_Exp>
}

/** aggregated selection of "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Aggregate = {
  __typename?: 'learning_series_discount_settings_aggregate'
  aggregate?: Maybe<Learning_Series_Discount_Settings_Aggregate_Fields>
  nodes: Array<Learning_Series_Discount_Settings>
}

export type Learning_Series_Discount_Settings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Learning_Series_Discount_Settings_Aggregate_Bool_Exp_Count>
}

export type Learning_Series_Discount_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Learning_Series_Discount_Settings_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Aggregate_Fields = {
  __typename?: 'learning_series_discount_settings_aggregate_fields'
  avg?: Maybe<Learning_Series_Discount_Settings_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Series_Discount_Settings_Max_Fields>
  min?: Maybe<Learning_Series_Discount_Settings_Min_Fields>
  stddev?: Maybe<Learning_Series_Discount_Settings_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Series_Discount_Settings_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Series_Discount_Settings_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Series_Discount_Settings_Sum_Fields>
  var_pop?: Maybe<Learning_Series_Discount_Settings_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Series_Discount_Settings_Var_Samp_Fields>
  variance?: Maybe<Learning_Series_Discount_Settings_Variance_Fields>
}

/** aggregate fields of "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Series_Discount_Settings_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Series_Discount_Settings_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Series_Discount_Settings_Max_Order_By>
  min?: InputMaybe<Learning_Series_Discount_Settings_Min_Order_By>
  stddev?: InputMaybe<Learning_Series_Discount_Settings_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Series_Discount_Settings_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Series_Discount_Settings_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Series_Discount_Settings_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Series_Discount_Settings_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Series_Discount_Settings_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Series_Discount_Settings_Variance_Order_By>
}

/** input type for inserting array relation for remote table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Arr_Rel_Insert_Input = {
  data: Array<Learning_Series_Discount_Settings_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Series_Discount_Settings_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Series_Discount_Settings_Avg_Fields = {
  __typename?: 'learning_series_discount_settings_avg_fields'
  created_by?: Maybe<Scalars['Float']>
  discount_percentage?: Maybe<Scalars['Float']>
  discount_segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>
  discount_percentage?: InputMaybe<Order_By>
  discount_segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_series_discount_settings". All fields are combined with a logical 'AND'. */
export type Learning_Series_Discount_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Series_Discount_Settings_Bool_Exp>>
  _not?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Series_Discount_Settings_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  discount_groups?: InputMaybe<Learning_Series_Discount_Groups_Bool_Exp>
  discount_groups_aggregate?: InputMaybe<Learning_Series_Discount_Groups_Aggregate_Bool_Exp>
  discount_percentage?: InputMaybe<Numeric_Comparison_Exp>
  discount_segment_id?: InputMaybe<Numeric_Comparison_Exp>
  discount_type?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Enum_Comparison_Exp>
  id?: InputMaybe<Uuid_Comparison_Exp>
  learning_series?: InputMaybe<Learning_Series_Bool_Exp>
  series_id?: InputMaybe<Uuid_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
  user?: InputMaybe<Users_Bool_Exp>
  userByUpdatedBy?: InputMaybe<Users_Bool_Exp>
}

/** unique or primary key constraints on table "learning_series_discount_settings" */
export enum Learning_Series_Discount_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningSeriesDiscountSettingsPkey = 'learning_series_discount_settings_pkey',
}

/** input type for incrementing numeric columns in table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>
  discount_percentage?: InputMaybe<Scalars['numeric']>
  discount_segment_id?: InputMaybe<Scalars['numeric']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  discount_groups?: InputMaybe<Learning_Series_Discount_Groups_Arr_Rel_Insert_Input>
  discount_percentage?: InputMaybe<Scalars['numeric']>
  discount_segment_id?: InputMaybe<Scalars['numeric']>
  discount_type?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Enum>
  id?: InputMaybe<Scalars['uuid']>
  learning_series?: InputMaybe<Learning_Series_Obj_Rel_Insert_Input>
  series_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>
  userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Learning_Series_Discount_Settings_Max_Fields = {
  __typename?: 'learning_series_discount_settings_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  discount_percentage?: Maybe<Scalars['numeric']>
  discount_segment_id?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['uuid']>
  series_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  discount_percentage?: InputMaybe<Order_By>
  discount_segment_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  series_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Series_Discount_Settings_Min_Fields = {
  __typename?: 'learning_series_discount_settings_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  discount_percentage?: Maybe<Scalars['numeric']>
  discount_segment_id?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['uuid']>
  series_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  discount_percentage?: InputMaybe<Order_By>
  discount_segment_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  series_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Mutation_Response = {
  __typename?: 'learning_series_discount_settings_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Series_Discount_Settings>
}

/** input type for inserting object relation for remote table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Obj_Rel_Insert_Input = {
  data: Learning_Series_Discount_Settings_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Series_Discount_Settings_On_Conflict>
}

/** on_conflict condition type for table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_On_Conflict = {
  constraint: Learning_Series_Discount_Settings_Constraint
  update_columns?: Array<Learning_Series_Discount_Settings_Update_Column>
  where?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
}

/** Ordering options when selecting data from "learning_series_discount_settings". */
export type Learning_Series_Discount_Settings_Order_By = {
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  discount_groups_aggregate?: InputMaybe<Learning_Series_Discount_Groups_Aggregate_Order_By>
  discount_percentage?: InputMaybe<Order_By>
  discount_segment_id?: InputMaybe<Order_By>
  discount_type?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  learning_series?: InputMaybe<Learning_Series_Order_By>
  series_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  user?: InputMaybe<Users_Order_By>
  userByUpdatedBy?: InputMaybe<Users_Order_By>
}

/** primary key columns input for table: learning_series_discount_settings */
export type Learning_Series_Discount_Settings_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "learning_series_discount_settings" */
export enum Learning_Series_Discount_Settings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DiscountPercentage = 'discount_percentage',
  /** column name */
  DiscountSegmentId = 'discount_segment_id',
  /** column name */
  DiscountType = 'discount_type',
  /** column name */
  Id = 'id',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** input type for updating data in table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  discount_percentage?: InputMaybe<Scalars['numeric']>
  discount_segment_id?: InputMaybe<Scalars['numeric']>
  discount_type?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Enum>
  id?: InputMaybe<Scalars['uuid']>
  series_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Learning_Series_Discount_Settings_Stddev_Fields = {
  __typename?: 'learning_series_discount_settings_stddev_fields'
  created_by?: Maybe<Scalars['Float']>
  discount_percentage?: Maybe<Scalars['Float']>
  discount_segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>
  discount_percentage?: InputMaybe<Order_By>
  discount_segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Series_Discount_Settings_Stddev_Pop_Fields = {
  __typename?: 'learning_series_discount_settings_stddev_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  discount_percentage?: Maybe<Scalars['Float']>
  discount_segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>
  discount_percentage?: InputMaybe<Order_By>
  discount_segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Series_Discount_Settings_Stddev_Samp_Fields = {
  __typename?: 'learning_series_discount_settings_stddev_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  discount_percentage?: Maybe<Scalars['Float']>
  discount_segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>
  discount_percentage?: InputMaybe<Order_By>
  discount_segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Series_Discount_Settings_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Series_Discount_Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  discount_percentage?: InputMaybe<Scalars['numeric']>
  discount_segment_id?: InputMaybe<Scalars['numeric']>
  discount_type?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Enum>
  id?: InputMaybe<Scalars['uuid']>
  series_id?: InputMaybe<Scalars['uuid']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Learning_Series_Discount_Settings_Sum_Fields = {
  __typename?: 'learning_series_discount_settings_sum_fields'
  created_by?: Maybe<Scalars['Int']>
  discount_percentage?: Maybe<Scalars['numeric']>
  discount_segment_id?: Maybe<Scalars['numeric']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>
  discount_percentage?: InputMaybe<Order_By>
  discount_segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** update columns of table "learning_series_discount_settings" */
export enum Learning_Series_Discount_Settings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DiscountPercentage = 'discount_percentage',
  /** column name */
  DiscountSegmentId = 'discount_segment_id',
  /** column name */
  DiscountType = 'discount_type',
  /** column name */
  Id = 'id',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Learning_Series_Discount_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Series_Discount_Settings_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Series_Discount_Settings_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Series_Discount_Settings_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Series_Discount_Settings_Var_Pop_Fields = {
  __typename?: 'learning_series_discount_settings_var_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  discount_percentage?: Maybe<Scalars['Float']>
  discount_segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>
  discount_percentage?: InputMaybe<Order_By>
  discount_segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Series_Discount_Settings_Var_Samp_Fields = {
  __typename?: 'learning_series_discount_settings_var_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  discount_percentage?: Maybe<Scalars['Float']>
  discount_segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>
  discount_percentage?: InputMaybe<Order_By>
  discount_segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Series_Discount_Settings_Variance_Fields = {
  __typename?: 'learning_series_discount_settings_variance_fields'
  created_by?: Maybe<Scalars['Float']>
  discount_percentage?: Maybe<Scalars['Float']>
  discount_segment_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_series_discount_settings" */
export type Learning_Series_Discount_Settings_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>
  discount_percentage?: InputMaybe<Order_By>
  discount_segment_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** input type for incrementing numeric columns in table "learning_series" */
export type Learning_Series_Inc_Input = {
  cme_expires_after_seconds?: InputMaybe<Scalars['Int']>
  created_by?: InputMaybe<Scalars['Int']>
  editor_id?: InputMaybe<Scalars['Int']>
  image_id?: InputMaybe<Scalars['Int']>
  publisher_id?: InputMaybe<Scalars['Int']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_series" */
export type Learning_Series_Insert_Input = {
  accreditation_when?: InputMaybe<Scalars['String']>
  certificate_content?: InputMaybe<Scalars['jsonb']>
  certificate_template?: InputMaybe<Scalars['String']>
  cme_accreditor?: InputMaybe<Scalars['String']>
  cme_credits_description?: InputMaybe<Scalars['String']>
  cme_expires_after_seconds?: InputMaybe<Scalars['Int']>
  cme_expires_at?: InputMaybe<Scalars['timestamptz']>
  cme_reference?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>
  description?: InputMaybe<Scalars['String']>
  discount?: InputMaybe<Scalars['Boolean']>
  discount_settings?: InputMaybe<Learning_Series_Discount_Settings_Arr_Rel_Insert_Input>
  display_author?: InputMaybe<Scalars['String']>
  editor?: InputMaybe<Users_Obj_Rel_Insert_Input>
  editor_id?: InputMaybe<Scalars['Int']>
  editor_publish?: InputMaybe<Scalars['Boolean']>
  enable_email_notifications?: InputMaybe<Scalars['Boolean']>
  force_completion_order?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  image?: InputMaybe<Learning_Uploads_Obj_Rel_Insert_Input>
  image_id?: InputMaybe<Scalars['Int']>
  library?: InputMaybe<Groups_Obj_Rel_Insert_Input>
  library_admins?: InputMaybe<User_Group_Members_Roles_Arr_Rel_Insert_Input>
  library_id?: InputMaybe<Scalars['String']>
  library_members?: InputMaybe<User_Group_Members_Arr_Rel_Insert_Input>
  library_type?: InputMaybe<Scalars['String']>
  modules?: InputMaybe<Learning_Modules_Arr_Rel_Insert_Input>
  notification_email?: InputMaybe<Scalars['String']>
  payments_enabled?: InputMaybe<Scalars['Boolean']>
  publisher?: InputMaybe<Users_Obj_Rel_Insert_Input>
  publisher_id?: InputMaybe<Scalars['Int']>
  rich_description?: InputMaybe<Scalars['String']>
  series_type?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  sort_modules_by?: InputMaybe<Scalars['learning_module_sort_order']>
  star_rating?: InputMaybe<Learning_Series_Star_Ratings_Obj_Rel_Insert_Input>
  status?: InputMaybe<Scalars['String']>
  subscribers?: InputMaybe<User_Group_Members_Arr_Rel_Insert_Input>
  subscribers_group?: InputMaybe<Scalars['String']>
  subscription_settings?: InputMaybe<Learning_Series_Subscription_Settings_Obj_Rel_Insert_Input>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  updater?: InputMaybe<Users_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Learning_Series_Max_Fields = {
  __typename?: 'learning_series_max_fields'
  accreditation_when?: Maybe<Scalars['String']>
  certificate_template?: Maybe<Scalars['String']>
  cme_accreditor?: Maybe<Scalars['String']>
  cme_credits_description?: Maybe<Scalars['String']>
  cme_expires_after_seconds?: Maybe<Scalars['Int']>
  cme_expires_at?: Maybe<Scalars['timestamptz']>
  cme_reference?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  display_author?: Maybe<Scalars['String']>
  editor_id?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  image_id?: Maybe<Scalars['Int']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  notification_email?: Maybe<Scalars['String']>
  publisher_id?: Maybe<Scalars['Int']>
  rich_description?: Maybe<Scalars['String']>
  series_type?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  sort_modules_by?: Maybe<Scalars['learning_module_sort_order']>
  status?: Maybe<Scalars['String']>
  subscribers_group?: Maybe<Scalars['String']>
  /** A computed field, executes function "learning_series_session_user_subscription_date" */
  subscription_date?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "learning_series" */
export type Learning_Series_Max_Order_By = {
  accreditation_when?: InputMaybe<Order_By>
  certificate_template?: InputMaybe<Order_By>
  cme_accreditor?: InputMaybe<Order_By>
  cme_credits_description?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cme_expires_at?: InputMaybe<Order_By>
  cme_reference?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  display_author?: InputMaybe<Order_By>
  editor_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  library_id?: InputMaybe<Order_By>
  library_type?: InputMaybe<Order_By>
  notification_email?: InputMaybe<Order_By>
  publisher_id?: InputMaybe<Order_By>
  rich_description?: InputMaybe<Order_By>
  series_type?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  sort_modules_by?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  subscribers_group?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Series_Min_Fields = {
  __typename?: 'learning_series_min_fields'
  accreditation_when?: Maybe<Scalars['String']>
  certificate_template?: Maybe<Scalars['String']>
  cme_accreditor?: Maybe<Scalars['String']>
  cme_credits_description?: Maybe<Scalars['String']>
  cme_expires_after_seconds?: Maybe<Scalars['Int']>
  cme_expires_at?: Maybe<Scalars['timestamptz']>
  cme_reference?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  display_author?: Maybe<Scalars['String']>
  editor_id?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  image_id?: Maybe<Scalars['Int']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  notification_email?: Maybe<Scalars['String']>
  publisher_id?: Maybe<Scalars['Int']>
  rich_description?: Maybe<Scalars['String']>
  series_type?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  sort_modules_by?: Maybe<Scalars['learning_module_sort_order']>
  status?: Maybe<Scalars['String']>
  subscribers_group?: Maybe<Scalars['String']>
  /** A computed field, executes function "learning_series_session_user_subscription_date" */
  subscription_date?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "learning_series" */
export type Learning_Series_Min_Order_By = {
  accreditation_when?: InputMaybe<Order_By>
  certificate_template?: InputMaybe<Order_By>
  cme_accreditor?: InputMaybe<Order_By>
  cme_credits_description?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cme_expires_at?: InputMaybe<Order_By>
  cme_reference?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  display_author?: InputMaybe<Order_By>
  editor_id?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  library_id?: InputMaybe<Order_By>
  library_type?: InputMaybe<Order_By>
  notification_email?: InputMaybe<Order_By>
  publisher_id?: InputMaybe<Order_By>
  rich_description?: InputMaybe<Order_By>
  series_type?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  sort_modules_by?: InputMaybe<Order_By>
  status?: InputMaybe<Order_By>
  subscribers_group?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_series" */
export type Learning_Series_Mutation_Response = {
  __typename?: 'learning_series_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Series>
}

/** input type for inserting object relation for remote table "learning_series" */
export type Learning_Series_Obj_Rel_Insert_Input = {
  data: Learning_Series_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Series_On_Conflict>
}

/** on_conflict condition type for table "learning_series" */
export type Learning_Series_On_Conflict = {
  constraint: Learning_Series_Constraint
  update_columns?: Array<Learning_Series_Update_Column>
  where?: InputMaybe<Learning_Series_Bool_Exp>
}

/** Ordering options when selecting data from "learning_series". */
export type Learning_Series_Order_By = {
  accreditation_when?: InputMaybe<Order_By>
  can_approve?: InputMaybe<Order_By>
  can_edit?: InputMaybe<Order_By>
  can_publish?: InputMaybe<Order_By>
  certificate_content?: InputMaybe<Order_By>
  certificate_template?: InputMaybe<Order_By>
  cme_accreditor?: InputMaybe<Order_By>
  cme_credits_description?: InputMaybe<Order_By>
  cme_expires_after_seconds?: InputMaybe<Order_By>
  cme_expires_at?: InputMaybe<Order_By>
  cme_reference?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  creator?: InputMaybe<Users_Order_By>
  description?: InputMaybe<Order_By>
  discount?: InputMaybe<Order_By>
  discount_settings_aggregate?: InputMaybe<Learning_Series_Discount_Settings_Aggregate_Order_By>
  display_author?: InputMaybe<Order_By>
  editor?: InputMaybe<Users_Order_By>
  editor_id?: InputMaybe<Order_By>
  editor_publish?: InputMaybe<Order_By>
  enable_email_notifications?: InputMaybe<Order_By>
  force_completion_order?: InputMaybe<Order_By>
  has_subscription?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image?: InputMaybe<Learning_Uploads_Order_By>
  image_id?: InputMaybe<Order_By>
  is_library_admin?: InputMaybe<Order_By>
  library?: InputMaybe<Groups_Order_By>
  library_admins_aggregate?: InputMaybe<User_Group_Members_Roles_Aggregate_Order_By>
  library_id?: InputMaybe<Order_By>
  library_members_aggregate?: InputMaybe<User_Group_Members_Aggregate_Order_By>
  library_type?: InputMaybe<Order_By>
  modules_aggregate?: InputMaybe<Learning_Modules_Aggregate_Order_By>
  notification_email?: InputMaybe<Order_By>
  payments_enabled?: InputMaybe<Order_By>
  publisher?: InputMaybe<Users_Order_By>
  publisher_id?: InputMaybe<Order_By>
  rich_description?: InputMaybe<Order_By>
  series_type?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  sort_modules_by?: InputMaybe<Order_By>
  star_rating?: InputMaybe<Learning_Series_Star_Ratings_Order_By>
  status?: InputMaybe<Order_By>
  subscribers_aggregate?: InputMaybe<User_Group_Members_Aggregate_Order_By>
  subscribers_group?: InputMaybe<Order_By>
  subscription_date?: InputMaybe<Order_By>
  subscription_settings?: InputMaybe<Learning_Series_Subscription_Settings_Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  updater?: InputMaybe<Users_Order_By>
}

/** primary key columns input for table: learning_series */
export type Learning_Series_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Learning_Series_Prepend_Input = {
  certificate_content?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "learning_series" */
export enum Learning_Series_Select_Column {
  /** column name */
  AccreditationWhen = 'accreditation_when',
  /** column name */
  CertificateContent = 'certificate_content',
  /** column name */
  CertificateTemplate = 'certificate_template',
  /** column name */
  CmeAccreditor = 'cme_accreditor',
  /** column name */
  CmeCreditsDescription = 'cme_credits_description',
  /** column name */
  CmeExpiresAfterSeconds = 'cme_expires_after_seconds',
  /** column name */
  CmeExpiresAt = 'cme_expires_at',
  /** column name */
  CmeReference = 'cme_reference',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  DisplayAuthor = 'display_author',
  /** column name */
  EditorId = 'editor_id',
  /** column name */
  EditorPublish = 'editor_publish',
  /** column name */
  EnableEmailNotifications = 'enable_email_notifications',
  /** column name */
  ForceCompletionOrder = 'force_completion_order',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  LibraryId = 'library_id',
  /** column name */
  LibraryType = 'library_type',
  /** column name */
  NotificationEmail = 'notification_email',
  /** column name */
  PaymentsEnabled = 'payments_enabled',
  /** column name */
  PublisherId = 'publisher_id',
  /** column name */
  RichDescription = 'rich_description',
  /** column name */
  SeriesType = 'series_type',
  /** column name */
  Slug = 'slug',
  /** column name */
  SortModulesBy = 'sort_modules_by',
  /** column name */
  Status = 'status',
  /** column name */
  SubscribersGroup = 'subscribers_group',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** select "learning_series_aggregate_bool_exp_bool_and_arguments_columns" columns of table "learning_series" */
export enum Learning_Series_Select_Column_Learning_Series_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Discount = 'discount',
  /** column name */
  EditorPublish = 'editor_publish',
  /** column name */
  EnableEmailNotifications = 'enable_email_notifications',
  /** column name */
  ForceCompletionOrder = 'force_completion_order',
  /** column name */
  PaymentsEnabled = 'payments_enabled',
}

/** select "learning_series_aggregate_bool_exp_bool_or_arguments_columns" columns of table "learning_series" */
export enum Learning_Series_Select_Column_Learning_Series_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Discount = 'discount',
  /** column name */
  EditorPublish = 'editor_publish',
  /** column name */
  EnableEmailNotifications = 'enable_email_notifications',
  /** column name */
  ForceCompletionOrder = 'force_completion_order',
  /** column name */
  PaymentsEnabled = 'payments_enabled',
}

/** input type for updating data in table "learning_series" */
export type Learning_Series_Set_Input = {
  accreditation_when?: InputMaybe<Scalars['String']>
  certificate_content?: InputMaybe<Scalars['jsonb']>
  certificate_template?: InputMaybe<Scalars['String']>
  cme_accreditor?: InputMaybe<Scalars['String']>
  cme_credits_description?: InputMaybe<Scalars['String']>
  cme_expires_after_seconds?: InputMaybe<Scalars['Int']>
  cme_expires_at?: InputMaybe<Scalars['timestamptz']>
  cme_reference?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  discount?: InputMaybe<Scalars['Boolean']>
  display_author?: InputMaybe<Scalars['String']>
  editor_id?: InputMaybe<Scalars['Int']>
  editor_publish?: InputMaybe<Scalars['Boolean']>
  enable_email_notifications?: InputMaybe<Scalars['Boolean']>
  force_completion_order?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  image_id?: InputMaybe<Scalars['Int']>
  library_id?: InputMaybe<Scalars['String']>
  library_type?: InputMaybe<Scalars['String']>
  notification_email?: InputMaybe<Scalars['String']>
  payments_enabled?: InputMaybe<Scalars['Boolean']>
  publisher_id?: InputMaybe<Scalars['Int']>
  rich_description?: InputMaybe<Scalars['String']>
  series_type?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  sort_modules_by?: InputMaybe<Scalars['learning_module_sort_order']>
  status?: InputMaybe<Scalars['String']>
  subscribers_group?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** columns and relationships of "learning_series_star_ratings" */
export type Learning_Series_Star_Ratings = {
  __typename?: 'learning_series_star_ratings'
  last_rated_at?: Maybe<Scalars['timestamptz']>
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['numeric']>
  rating_total?: Maybe<Scalars['numeric']>
  /** An object relationship */
  series?: Maybe<Learning_Series>
  series_id?: Maybe<Scalars['uuid']>
}

/** aggregated selection of "learning_series_star_ratings" */
export type Learning_Series_Star_Ratings_Aggregate = {
  __typename?: 'learning_series_star_ratings_aggregate'
  aggregate?: Maybe<Learning_Series_Star_Ratings_Aggregate_Fields>
  nodes: Array<Learning_Series_Star_Ratings>
}

/** aggregate fields of "learning_series_star_ratings" */
export type Learning_Series_Star_Ratings_Aggregate_Fields = {
  __typename?: 'learning_series_star_ratings_aggregate_fields'
  avg?: Maybe<Learning_Series_Star_Ratings_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Series_Star_Ratings_Max_Fields>
  min?: Maybe<Learning_Series_Star_Ratings_Min_Fields>
  stddev?: Maybe<Learning_Series_Star_Ratings_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Series_Star_Ratings_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Series_Star_Ratings_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Series_Star_Ratings_Sum_Fields>
  var_pop?: Maybe<Learning_Series_Star_Ratings_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Series_Star_Ratings_Var_Samp_Fields>
  variance?: Maybe<Learning_Series_Star_Ratings_Variance_Fields>
}

/** aggregate fields of "learning_series_star_ratings" */
export type Learning_Series_Star_Ratings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Series_Star_Ratings_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Learning_Series_Star_Ratings_Avg_Fields = {
  __typename?: 'learning_series_star_ratings_avg_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_series_star_ratings". All fields are combined with a logical 'AND'. */
export type Learning_Series_Star_Ratings_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Series_Star_Ratings_Bool_Exp>>
  _not?: InputMaybe<Learning_Series_Star_Ratings_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Series_Star_Ratings_Bool_Exp>>
  last_rated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  rating?: InputMaybe<Numeric_Comparison_Exp>
  rating_count?: InputMaybe<Numeric_Comparison_Exp>
  rating_total?: InputMaybe<Numeric_Comparison_Exp>
  series?: InputMaybe<Learning_Series_Bool_Exp>
  series_id?: InputMaybe<Uuid_Comparison_Exp>
}

/** input type for inserting data into table "learning_series_star_ratings" */
export type Learning_Series_Star_Ratings_Insert_Input = {
  last_rated_at?: InputMaybe<Scalars['timestamptz']>
  rating?: InputMaybe<Scalars['numeric']>
  rating_count?: InputMaybe<Scalars['numeric']>
  rating_total?: InputMaybe<Scalars['numeric']>
  series?: InputMaybe<Learning_Series_Obj_Rel_Insert_Input>
  series_id?: InputMaybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Learning_Series_Star_Ratings_Max_Fields = {
  __typename?: 'learning_series_star_ratings_max_fields'
  last_rated_at?: Maybe<Scalars['timestamptz']>
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['numeric']>
  rating_total?: Maybe<Scalars['numeric']>
  series_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type Learning_Series_Star_Ratings_Min_Fields = {
  __typename?: 'learning_series_star_ratings_min_fields'
  last_rated_at?: Maybe<Scalars['timestamptz']>
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['numeric']>
  rating_total?: Maybe<Scalars['numeric']>
  series_id?: Maybe<Scalars['uuid']>
}

/** input type for inserting object relation for remote table "learning_series_star_ratings" */
export type Learning_Series_Star_Ratings_Obj_Rel_Insert_Input = {
  data: Learning_Series_Star_Ratings_Insert_Input
}

/** Ordering options when selecting data from "learning_series_star_ratings". */
export type Learning_Series_Star_Ratings_Order_By = {
  last_rated_at?: InputMaybe<Order_By>
  rating?: InputMaybe<Order_By>
  rating_count?: InputMaybe<Order_By>
  rating_total?: InputMaybe<Order_By>
  series?: InputMaybe<Learning_Series_Order_By>
  series_id?: InputMaybe<Order_By>
}

/** select columns of table "learning_series_star_ratings" */
export enum Learning_Series_Star_Ratings_Select_Column {
  /** column name */
  LastRatedAt = 'last_rated_at',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingCount = 'rating_count',
  /** column name */
  RatingTotal = 'rating_total',
  /** column name */
  SeriesId = 'series_id',
}

/** aggregate stddev on columns */
export type Learning_Series_Star_Ratings_Stddev_Fields = {
  __typename?: 'learning_series_star_ratings_stddev_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Series_Star_Ratings_Stddev_Pop_Fields = {
  __typename?: 'learning_series_star_ratings_stddev_pop_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Series_Star_Ratings_Stddev_Samp_Fields = {
  __typename?: 'learning_series_star_ratings_stddev_samp_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_series_star_ratings" */
export type Learning_Series_Star_Ratings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Series_Star_Ratings_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Series_Star_Ratings_Stream_Cursor_Value_Input = {
  last_rated_at?: InputMaybe<Scalars['timestamptz']>
  rating?: InputMaybe<Scalars['numeric']>
  rating_count?: InputMaybe<Scalars['numeric']>
  rating_total?: InputMaybe<Scalars['numeric']>
  series_id?: InputMaybe<Scalars['uuid']>
}

/** aggregate sum on columns */
export type Learning_Series_Star_Ratings_Sum_Fields = {
  __typename?: 'learning_series_star_ratings_sum_fields'
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['numeric']>
  rating_total?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type Learning_Series_Star_Ratings_Var_Pop_Fields = {
  __typename?: 'learning_series_star_ratings_var_pop_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Series_Star_Ratings_Var_Samp_Fields = {
  __typename?: 'learning_series_star_ratings_var_samp_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Series_Star_Ratings_Variance_Fields = {
  __typename?: 'learning_series_star_ratings_variance_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate stddev on columns */
export type Learning_Series_Stddev_Fields = {
  __typename?: 'learning_series_stddev_fields'
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  editor_id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  publisher_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_series" */
export type Learning_Series_Stddev_Order_By = {
  cme_expires_after_seconds?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  editor_id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  publisher_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Series_Stddev_Pop_Fields = {
  __typename?: 'learning_series_stddev_pop_fields'
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  editor_id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  publisher_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_series" */
export type Learning_Series_Stddev_Pop_Order_By = {
  cme_expires_after_seconds?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  editor_id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  publisher_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Series_Stddev_Samp_Fields = {
  __typename?: 'learning_series_stddev_samp_fields'
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  editor_id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  publisher_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_series" */
export type Learning_Series_Stddev_Samp_Order_By = {
  cme_expires_after_seconds?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  editor_id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  publisher_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_series" */
export type Learning_Series_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Series_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Series_Stream_Cursor_Value_Input = {
  accreditation_when?: InputMaybe<Scalars['String']>
  certificate_content?: InputMaybe<Scalars['jsonb']>
  certificate_template?: InputMaybe<Scalars['String']>
  cme_accreditor?: InputMaybe<Scalars['String']>
  cme_credits_description?: InputMaybe<Scalars['String']>
  cme_expires_after_seconds?: InputMaybe<Scalars['Int']>
  cme_expires_at?: InputMaybe<Scalars['timestamptz']>
  cme_reference?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  discount?: InputMaybe<Scalars['Boolean']>
  display_author?: InputMaybe<Scalars['String']>
  editor_id?: InputMaybe<Scalars['Int']>
  editor_publish?: InputMaybe<Scalars['Boolean']>
  enable_email_notifications?: InputMaybe<Scalars['Boolean']>
  force_completion_order?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['uuid']>
  image_id?: InputMaybe<Scalars['Int']>
  library_id?: InputMaybe<Scalars['String']>
  library_type?: InputMaybe<Scalars['String']>
  notification_email?: InputMaybe<Scalars['String']>
  payments_enabled?: InputMaybe<Scalars['Boolean']>
  publisher_id?: InputMaybe<Scalars['Int']>
  rich_description?: InputMaybe<Scalars['String']>
  series_type?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  sort_modules_by?: InputMaybe<Scalars['learning_module_sort_order']>
  status?: InputMaybe<Scalars['String']>
  subscribers_group?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** columns and relationships of "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings = {
  __typename?: 'learning_series_subscription_settings'
  available_as_module: Scalars['Boolean']
  available_as_series: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  created_by: Scalars['Int']
  currency_code: Scalars['String']
  /** An object relationship */
  learning_series: Learning_Series
  module_price: Scalars['numeric']
  series_id: Scalars['uuid']
  series_price: Scalars['numeric']
  /** null means it's a one-off payment */
  subscription_interval?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  updated_by: Scalars['Int']
  /** An object relationship */
  user: Users
  /** An object relationship */
  userByUpdatedBy: Users
}

/** aggregated selection of "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Aggregate = {
  __typename?: 'learning_series_subscription_settings_aggregate'
  aggregate?: Maybe<Learning_Series_Subscription_Settings_Aggregate_Fields>
  nodes: Array<Learning_Series_Subscription_Settings>
}

export type Learning_Series_Subscription_Settings_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Learning_Series_Subscription_Settings_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Learning_Series_Subscription_Settings_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Learning_Series_Subscription_Settings_Aggregate_Bool_Exp_Count>
}

export type Learning_Series_Subscription_Settings_Aggregate_Bool_Exp_Bool_And =
  {
    arguments: Learning_Series_Subscription_Settings_Select_Column_Learning_Series_Subscription_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
    distinct?: InputMaybe<Scalars['Boolean']>
    filter?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
    predicate: Boolean_Comparison_Exp
  }

export type Learning_Series_Subscription_Settings_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Learning_Series_Subscription_Settings_Select_Column_Learning_Series_Subscription_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Learning_Series_Subscription_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Learning_Series_Subscription_Settings_Select_Column>
  >
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Aggregate_Fields = {
  __typename?: 'learning_series_subscription_settings_aggregate_fields'
  avg?: Maybe<Learning_Series_Subscription_Settings_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Series_Subscription_Settings_Max_Fields>
  min?: Maybe<Learning_Series_Subscription_Settings_Min_Fields>
  stddev?: Maybe<Learning_Series_Subscription_Settings_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Series_Subscription_Settings_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Series_Subscription_Settings_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Series_Subscription_Settings_Sum_Fields>
  var_pop?: Maybe<Learning_Series_Subscription_Settings_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Series_Subscription_Settings_Var_Samp_Fields>
  variance?: Maybe<Learning_Series_Subscription_Settings_Variance_Fields>
}

/** aggregate fields of "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Learning_Series_Subscription_Settings_Select_Column>
  >
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Series_Subscription_Settings_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Series_Subscription_Settings_Max_Order_By>
  min?: InputMaybe<Learning_Series_Subscription_Settings_Min_Order_By>
  stddev?: InputMaybe<Learning_Series_Subscription_Settings_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Series_Subscription_Settings_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Series_Subscription_Settings_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Series_Subscription_Settings_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Series_Subscription_Settings_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Series_Subscription_Settings_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Series_Subscription_Settings_Variance_Order_By>
}

/** input type for inserting array relation for remote table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Arr_Rel_Insert_Input = {
  data: Array<Learning_Series_Subscription_Settings_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Series_Subscription_Settings_On_Conflict>
}

/** aggregate avg on columns */
export type Learning_Series_Subscription_Settings_Avg_Fields = {
  __typename?: 'learning_series_subscription_settings_avg_fields'
  created_by?: Maybe<Scalars['Float']>
  module_price?: Maybe<Scalars['Float']>
  series_price?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>
  module_price?: InputMaybe<Order_By>
  series_price?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_series_subscription_settings". All fields are combined with a logical 'AND'. */
export type Learning_Series_Subscription_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Series_Subscription_Settings_Bool_Exp>>
  _not?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Series_Subscription_Settings_Bool_Exp>>
  available_as_module?: InputMaybe<Boolean_Comparison_Exp>
  available_as_series?: InputMaybe<Boolean_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  currency_code?: InputMaybe<String_Comparison_Exp>
  learning_series?: InputMaybe<Learning_Series_Bool_Exp>
  module_price?: InputMaybe<Numeric_Comparison_Exp>
  series_id?: InputMaybe<Uuid_Comparison_Exp>
  series_price?: InputMaybe<Numeric_Comparison_Exp>
  subscription_interval?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
  user?: InputMaybe<Users_Bool_Exp>
  userByUpdatedBy?: InputMaybe<Users_Bool_Exp>
}

/** unique or primary key constraints on table "learning_series_subscription_settings" */
export enum Learning_Series_Subscription_Settings_Constraint {
  /** unique or primary key constraint on columns "series_id" */
  LearningSeriesSubscriptionSettingsPkey = 'learning_series_subscription_settings_pkey',
}

/** enum table for subscription Discount types */
export type Learning_Series_Subscription_Settings_Discount_Type = {
  __typename?: 'learning_series_subscription_settings_discount_type'
  comment: Scalars['String']
  value: Scalars['String']
}

/** aggregated selection of "learning_series_subscription_settings_discount_type" */
export type Learning_Series_Subscription_Settings_Discount_Type_Aggregate = {
  __typename?: 'learning_series_subscription_settings_discount_type_aggregate'
  aggregate?: Maybe<Learning_Series_Subscription_Settings_Discount_Type_Aggregate_Fields>
  nodes: Array<Learning_Series_Subscription_Settings_Discount_Type>
}

/** aggregate fields of "learning_series_subscription_settings_discount_type" */
export type Learning_Series_Subscription_Settings_Discount_Type_Aggregate_Fields =
  {
    __typename?: 'learning_series_subscription_settings_discount_type_aggregate_fields'
    count: Scalars['Int']
    max?: Maybe<Learning_Series_Subscription_Settings_Discount_Type_Max_Fields>
    min?: Maybe<Learning_Series_Subscription_Settings_Discount_Type_Min_Fields>
  }

/** aggregate fields of "learning_series_subscription_settings_discount_type" */
export type Learning_Series_Subscription_Settings_Discount_Type_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Discount_Type_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
  }

/** Boolean expression to filter rows from the table "learning_series_subscription_settings_discount_type". All fields are combined with a logical 'AND'. */
export type Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp = {
  _and?: InputMaybe<
    Array<Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp>
  >
  _not?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp>
  _or?: InputMaybe<
    Array<Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp>
  >
  comment?: InputMaybe<String_Comparison_Exp>
  value?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_series_subscription_settings_discount_type" */
export enum Learning_Series_Subscription_Settings_Discount_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  LearningSeriesSubscriptionSettingsDiscountTypePkey = 'learning_series_subscription_settings_discount_type_pkey',
}

export enum Learning_Series_Subscription_Settings_Discount_Type_Enum {
  /** Free Access */
  FreeAccess = 'free_access',
  /** Paid by partner */
  PaidByPartner = 'paid_by_partner',
  /** Percentage discount */
  Percentage = 'percentage',
}

/** Boolean expression to compare columns of type "learning_series_subscription_settings_discount_type_enum". All fields are combined with logical 'AND'. */
export type Learning_Series_Subscription_Settings_Discount_Type_Enum_Comparison_Exp =
  {
    _eq?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Enum>
    _in?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Discount_Type_Enum>
    >
    _is_null?: InputMaybe<Scalars['Boolean']>
    _neq?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Enum>
    _nin?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Discount_Type_Enum>
    >
  }

/** input type for inserting data into table "learning_series_subscription_settings_discount_type" */
export type Learning_Series_Subscription_Settings_Discount_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Learning_Series_Subscription_Settings_Discount_Type_Max_Fields = {
  __typename?: 'learning_series_subscription_settings_discount_type_max_fields'
  comment?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Learning_Series_Subscription_Settings_Discount_Type_Min_Fields = {
  __typename?: 'learning_series_subscription_settings_discount_type_min_fields'
  comment?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "learning_series_subscription_settings_discount_type" */
export type Learning_Series_Subscription_Settings_Discount_Type_Mutation_Response =
  {
    __typename?: 'learning_series_subscription_settings_discount_type_mutation_response'
    /** number of rows affected by the mutation */
    affected_rows: Scalars['Int']
    /** data from the rows affected by the mutation */
    returning: Array<Learning_Series_Subscription_Settings_Discount_Type>
  }

/** on_conflict condition type for table "learning_series_subscription_settings_discount_type" */
export type Learning_Series_Subscription_Settings_Discount_Type_On_Conflict = {
  constraint: Learning_Series_Subscription_Settings_Discount_Type_Constraint
  update_columns?: Array<Learning_Series_Subscription_Settings_Discount_Type_Update_Column>
  where?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp>
}

/** Ordering options when selecting data from "learning_series_subscription_settings_discount_type". */
export type Learning_Series_Subscription_Settings_Discount_Type_Order_By = {
  comment?: InputMaybe<Order_By>
  value?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_series_subscription_settings_discount_type */
export type Learning_Series_Subscription_Settings_Discount_Type_Pk_Columns_Input =
  {
    value: Scalars['String']
  }

/** select columns of table "learning_series_subscription_settings_discount_type" */
export enum Learning_Series_Subscription_Settings_Discount_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "learning_series_subscription_settings_discount_type" */
export type Learning_Series_Subscription_Settings_Discount_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>
  value?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "learning_series_subscription_settings_discount_type" */
export type Learning_Series_Subscription_Settings_Discount_Type_Stream_Cursor_Input =
  {
    /** Stream column input with initial value */
    initial_value: Learning_Series_Subscription_Settings_Discount_Type_Stream_Cursor_Value_Input
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>
  }

/** Initial value of the column from where the streaming should start */
export type Learning_Series_Subscription_Settings_Discount_Type_Stream_Cursor_Value_Input =
  {
    comment?: InputMaybe<Scalars['String']>
    value?: InputMaybe<Scalars['String']>
  }

/** update columns of table "learning_series_subscription_settings_discount_type" */
export enum Learning_Series_Subscription_Settings_Discount_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Learning_Series_Subscription_Settings_Discount_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp
}

/** input type for incrementing numeric columns in table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>
  module_price?: InputMaybe<Scalars['numeric']>
  series_price?: InputMaybe<Scalars['numeric']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Insert_Input = {
  available_as_module?: InputMaybe<Scalars['Boolean']>
  available_as_series?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  currency_code?: InputMaybe<Scalars['String']>
  learning_series?: InputMaybe<Learning_Series_Obj_Rel_Insert_Input>
  module_price?: InputMaybe<Scalars['numeric']>
  series_id?: InputMaybe<Scalars['uuid']>
  series_price?: InputMaybe<Scalars['numeric']>
  /** null means it's a one-off payment */
  subscription_interval?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>
  userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Learning_Series_Subscription_Settings_Max_Fields = {
  __typename?: 'learning_series_subscription_settings_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  currency_code?: Maybe<Scalars['String']>
  module_price?: Maybe<Scalars['numeric']>
  series_id?: Maybe<Scalars['uuid']>
  series_price?: Maybe<Scalars['numeric']>
  /** null means it's a one-off payment */
  subscription_interval?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  currency_code?: InputMaybe<Order_By>
  module_price?: InputMaybe<Order_By>
  series_id?: InputMaybe<Order_By>
  series_price?: InputMaybe<Order_By>
  /** null means it's a one-off payment */
  subscription_interval?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Series_Subscription_Settings_Min_Fields = {
  __typename?: 'learning_series_subscription_settings_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  currency_code?: Maybe<Scalars['String']>
  module_price?: Maybe<Scalars['numeric']>
  series_id?: Maybe<Scalars['uuid']>
  series_price?: Maybe<Scalars['numeric']>
  /** null means it's a one-off payment */
  subscription_interval?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  currency_code?: InputMaybe<Order_By>
  module_price?: InputMaybe<Order_By>
  series_id?: InputMaybe<Order_By>
  series_price?: InputMaybe<Order_By>
  /** null means it's a one-off payment */
  subscription_interval?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** response of any mutation on the table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Mutation_Response = {
  __typename?: 'learning_series_subscription_settings_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Series_Subscription_Settings>
}

/** input type for inserting object relation for remote table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Obj_Rel_Insert_Input = {
  data: Learning_Series_Subscription_Settings_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Series_Subscription_Settings_On_Conflict>
}

/** on_conflict condition type for table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_On_Conflict = {
  constraint: Learning_Series_Subscription_Settings_Constraint
  update_columns?: Array<Learning_Series_Subscription_Settings_Update_Column>
  where?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
}

/** Ordering options when selecting data from "learning_series_subscription_settings". */
export type Learning_Series_Subscription_Settings_Order_By = {
  available_as_module?: InputMaybe<Order_By>
  available_as_series?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  currency_code?: InputMaybe<Order_By>
  learning_series?: InputMaybe<Learning_Series_Order_By>
  module_price?: InputMaybe<Order_By>
  series_id?: InputMaybe<Order_By>
  series_price?: InputMaybe<Order_By>
  subscription_interval?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  user?: InputMaybe<Users_Order_By>
  userByUpdatedBy?: InputMaybe<Users_Order_By>
}

/** primary key columns input for table: learning_series_subscription_settings */
export type Learning_Series_Subscription_Settings_Pk_Columns_Input = {
  series_id: Scalars['uuid']
}

/** select columns of table "learning_series_subscription_settings" */
export enum Learning_Series_Subscription_Settings_Select_Column {
  /** column name */
  AvailableAsModule = 'available_as_module',
  /** column name */
  AvailableAsSeries = 'available_as_series',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  ModulePrice = 'module_price',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  SeriesPrice = 'series_price',
  /** column name */
  SubscriptionInterval = 'subscription_interval',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** select "learning_series_subscription_settings_aggregate_bool_exp_bool_and_arguments_columns" columns of table "learning_series_subscription_settings" */
export enum Learning_Series_Subscription_Settings_Select_Column_Learning_Series_Subscription_Settings_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AvailableAsModule = 'available_as_module',
  /** column name */
  AvailableAsSeries = 'available_as_series',
}

/** select "learning_series_subscription_settings_aggregate_bool_exp_bool_or_arguments_columns" columns of table "learning_series_subscription_settings" */
export enum Learning_Series_Subscription_Settings_Select_Column_Learning_Series_Subscription_Settings_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AvailableAsModule = 'available_as_module',
  /** column name */
  AvailableAsSeries = 'available_as_series',
}

/** input type for updating data in table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Set_Input = {
  available_as_module?: InputMaybe<Scalars['Boolean']>
  available_as_series?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  currency_code?: InputMaybe<Scalars['String']>
  module_price?: InputMaybe<Scalars['numeric']>
  series_id?: InputMaybe<Scalars['uuid']>
  series_price?: InputMaybe<Scalars['numeric']>
  /** null means it's a one-off payment */
  subscription_interval?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Learning_Series_Subscription_Settings_Stddev_Fields = {
  __typename?: 'learning_series_subscription_settings_stddev_fields'
  created_by?: Maybe<Scalars['Float']>
  module_price?: Maybe<Scalars['Float']>
  series_price?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>
  module_price?: InputMaybe<Order_By>
  series_price?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Series_Subscription_Settings_Stddev_Pop_Fields = {
  __typename?: 'learning_series_subscription_settings_stddev_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  module_price?: Maybe<Scalars['Float']>
  series_price?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>
  module_price?: InputMaybe<Order_By>
  series_price?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Series_Subscription_Settings_Stddev_Samp_Fields = {
  __typename?: 'learning_series_subscription_settings_stddev_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  module_price?: Maybe<Scalars['Float']>
  series_price?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>
  module_price?: InputMaybe<Order_By>
  series_price?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Series_Subscription_Settings_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Series_Subscription_Settings_Stream_Cursor_Value_Input = {
  available_as_module?: InputMaybe<Scalars['Boolean']>
  available_as_series?: InputMaybe<Scalars['Boolean']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  currency_code?: InputMaybe<Scalars['String']>
  module_price?: InputMaybe<Scalars['numeric']>
  series_id?: InputMaybe<Scalars['uuid']>
  series_price?: InputMaybe<Scalars['numeric']>
  /** null means it's a one-off payment */
  subscription_interval?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Learning_Series_Subscription_Settings_Sum_Fields = {
  __typename?: 'learning_series_subscription_settings_sum_fields'
  created_by?: Maybe<Scalars['Int']>
  module_price?: Maybe<Scalars['numeric']>
  series_price?: Maybe<Scalars['numeric']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>
  module_price?: InputMaybe<Order_By>
  series_price?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** update columns of table "learning_series_subscription_settings" */
export enum Learning_Series_Subscription_Settings_Update_Column {
  /** column name */
  AvailableAsModule = 'available_as_module',
  /** column name */
  AvailableAsSeries = 'available_as_series',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  ModulePrice = 'module_price',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  SeriesPrice = 'series_price',
  /** column name */
  SubscriptionInterval = 'subscription_interval',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Learning_Series_Subscription_Settings_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Series_Subscription_Settings_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Series_Subscription_Settings_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Series_Subscription_Settings_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Series_Subscription_Settings_Var_Pop_Fields = {
  __typename?: 'learning_series_subscription_settings_var_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  module_price?: Maybe<Scalars['Float']>
  series_price?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>
  module_price?: InputMaybe<Order_By>
  series_price?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Series_Subscription_Settings_Var_Samp_Fields = {
  __typename?: 'learning_series_subscription_settings_var_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  module_price?: Maybe<Scalars['Float']>
  series_price?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>
  module_price?: InputMaybe<Order_By>
  series_price?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Series_Subscription_Settings_Variance_Fields = {
  __typename?: 'learning_series_subscription_settings_variance_fields'
  created_by?: Maybe<Scalars['Float']>
  module_price?: Maybe<Scalars['Float']>
  series_price?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_series_subscription_settings" */
export type Learning_Series_Subscription_Settings_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>
  module_price?: InputMaybe<Order_By>
  series_price?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate sum on columns */
export type Learning_Series_Sum_Fields = {
  __typename?: 'learning_series_sum_fields'
  cme_expires_after_seconds?: Maybe<Scalars['Int']>
  created_by?: Maybe<Scalars['Int']>
  editor_id?: Maybe<Scalars['Int']>
  image_id?: Maybe<Scalars['Int']>
  publisher_id?: Maybe<Scalars['Int']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "learning_series" */
export type Learning_Series_Sum_Order_By = {
  cme_expires_after_seconds?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  editor_id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  publisher_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** update columns of table "learning_series" */
export enum Learning_Series_Update_Column {
  /** column name */
  AccreditationWhen = 'accreditation_when',
  /** column name */
  CertificateContent = 'certificate_content',
  /** column name */
  CertificateTemplate = 'certificate_template',
  /** column name */
  CmeAccreditor = 'cme_accreditor',
  /** column name */
  CmeCreditsDescription = 'cme_credits_description',
  /** column name */
  CmeExpiresAfterSeconds = 'cme_expires_after_seconds',
  /** column name */
  CmeExpiresAt = 'cme_expires_at',
  /** column name */
  CmeReference = 'cme_reference',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Discount = 'discount',
  /** column name */
  DisplayAuthor = 'display_author',
  /** column name */
  EditorId = 'editor_id',
  /** column name */
  EditorPublish = 'editor_publish',
  /** column name */
  EnableEmailNotifications = 'enable_email_notifications',
  /** column name */
  ForceCompletionOrder = 'force_completion_order',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  LibraryId = 'library_id',
  /** column name */
  LibraryType = 'library_type',
  /** column name */
  NotificationEmail = 'notification_email',
  /** column name */
  PaymentsEnabled = 'payments_enabled',
  /** column name */
  PublisherId = 'publisher_id',
  /** column name */
  RichDescription = 'rich_description',
  /** column name */
  SeriesType = 'series_type',
  /** column name */
  Slug = 'slug',
  /** column name */
  SortModulesBy = 'sort_modules_by',
  /** column name */
  Status = 'status',
  /** column name */
  SubscribersGroup = 'subscribers_group',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Learning_Series_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Learning_Series_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Learning_Series_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Learning_Series_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Learning_Series_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Series_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Learning_Series_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Series_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Series_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Series_Var_Pop_Fields = {
  __typename?: 'learning_series_var_pop_fields'
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  editor_id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  publisher_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_series" */
export type Learning_Series_Var_Pop_Order_By = {
  cme_expires_after_seconds?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  editor_id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  publisher_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Series_Var_Samp_Fields = {
  __typename?: 'learning_series_var_samp_fields'
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  editor_id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  publisher_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_series" */
export type Learning_Series_Var_Samp_Order_By = {
  cme_expires_after_seconds?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  editor_id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  publisher_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Series_Variance_Fields = {
  __typename?: 'learning_series_variance_fields'
  cme_expires_after_seconds?: Maybe<Scalars['Float']>
  created_by?: Maybe<Scalars['Float']>
  editor_id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  publisher_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_series" */
export type Learning_Series_Variance_Order_By = {
  cme_expires_after_seconds?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  editor_id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  publisher_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** columns and relationships of "learning_star_ratings" */
export type Learning_Star_Ratings = {
  __typename?: 'learning_star_ratings'
  last_rated_at?: Maybe<Scalars['timestamptz']>
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['bigint']>
  rating_total?: Maybe<Scalars['bigint']>
}

/** aggregated selection of "learning_star_ratings" */
export type Learning_Star_Ratings_Aggregate = {
  __typename?: 'learning_star_ratings_aggregate'
  aggregate?: Maybe<Learning_Star_Ratings_Aggregate_Fields>
  nodes: Array<Learning_Star_Ratings>
}

/** aggregate fields of "learning_star_ratings" */
export type Learning_Star_Ratings_Aggregate_Fields = {
  __typename?: 'learning_star_ratings_aggregate_fields'
  avg?: Maybe<Learning_Star_Ratings_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Star_Ratings_Max_Fields>
  min?: Maybe<Learning_Star_Ratings_Min_Fields>
  stddev?: Maybe<Learning_Star_Ratings_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Star_Ratings_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Star_Ratings_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Star_Ratings_Sum_Fields>
  var_pop?: Maybe<Learning_Star_Ratings_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Star_Ratings_Var_Samp_Fields>
  variance?: Maybe<Learning_Star_Ratings_Variance_Fields>
}

/** aggregate fields of "learning_star_ratings" */
export type Learning_Star_Ratings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Learning_Star_Ratings_Avg_Fields = {
  __typename?: 'learning_star_ratings_avg_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_star_ratings". All fields are combined with a logical 'AND'. */
export type Learning_Star_Ratings_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Star_Ratings_Bool_Exp>>
  _not?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Star_Ratings_Bool_Exp>>
  last_rated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  rating?: InputMaybe<Numeric_Comparison_Exp>
  rating_count?: InputMaybe<Bigint_Comparison_Exp>
  rating_total?: InputMaybe<Bigint_Comparison_Exp>
}

export type Learning_Star_Ratings_For_Module_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  moduleid?: InputMaybe<Scalars['uuid']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Star_Ratings_In_Library_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  libraryid?: InputMaybe<Scalars['String']>
  librarytype?: InputMaybe<Scalars['String']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Star_Ratings_In_Series_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  seriesid?: InputMaybe<Scalars['uuid']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Learning_Star_Ratings_Max_Fields = {
  __typename?: 'learning_star_ratings_max_fields'
  last_rated_at?: Maybe<Scalars['timestamptz']>
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['bigint']>
  rating_total?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Star_Ratings_Min_Fields = {
  __typename?: 'learning_star_ratings_min_fields'
  last_rated_at?: Maybe<Scalars['timestamptz']>
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['bigint']>
  rating_total?: Maybe<Scalars['bigint']>
}

/** Ordering options when selecting data from "learning_star_ratings". */
export type Learning_Star_Ratings_Order_By = {
  last_rated_at?: InputMaybe<Order_By>
  rating?: InputMaybe<Order_By>
  rating_count?: InputMaybe<Order_By>
  rating_total?: InputMaybe<Order_By>
}

/** select columns of table "learning_star_ratings" */
export enum Learning_Star_Ratings_Select_Column {
  /** column name */
  LastRatedAt = 'last_rated_at',
  /** column name */
  Rating = 'rating',
  /** column name */
  RatingCount = 'rating_count',
  /** column name */
  RatingTotal = 'rating_total',
}

/** aggregate stddev on columns */
export type Learning_Star_Ratings_Stddev_Fields = {
  __typename?: 'learning_star_ratings_stddev_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Star_Ratings_Stddev_Pop_Fields = {
  __typename?: 'learning_star_ratings_stddev_pop_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Star_Ratings_Stddev_Samp_Fields = {
  __typename?: 'learning_star_ratings_stddev_samp_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_star_ratings" */
export type Learning_Star_Ratings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Star_Ratings_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Star_Ratings_Stream_Cursor_Value_Input = {
  last_rated_at?: InputMaybe<Scalars['timestamptz']>
  rating?: InputMaybe<Scalars['numeric']>
  rating_count?: InputMaybe<Scalars['bigint']>
  rating_total?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Learning_Star_Ratings_Sum_Fields = {
  __typename?: 'learning_star_ratings_sum_fields'
  rating?: Maybe<Scalars['numeric']>
  rating_count?: Maybe<Scalars['bigint']>
  rating_total?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Star_Ratings_Var_Pop_Fields = {
  __typename?: 'learning_star_ratings_var_pop_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Star_Ratings_Var_Samp_Fields = {
  __typename?: 'learning_star_ratings_var_samp_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Star_Ratings_Variance_Fields = {
  __typename?: 'learning_star_ratings_variance_fields'
  rating?: Maybe<Scalars['Float']>
  rating_count?: Maybe<Scalars['Float']>
  rating_total?: Maybe<Scalars['Float']>
}

export type Learning_Star_Ratings_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

/** columns and relationships of "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages = {
  __typename?: 'learning_stats_learner_module_rating_response_averages'
  avg_response?: Maybe<Scalars['numeric']>
  median_response?: Maybe<Scalars['float8']>
  /** An object relationship */
  module?: Maybe<Learning_Modules>
  module_id?: Maybe<Scalars['uuid']>
  position?: Maybe<Scalars['Int']>
  question_id?: Maybe<Scalars['uuid']>
  question_module_id?: Maybe<Scalars['uuid']>
  question_series_id?: Maybe<Scalars['uuid']>
  question_text?: Maybe<Scalars['String']>
}

/** aggregated selection of "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate = {
  __typename?: 'learning_stats_learner_module_rating_response_averages_aggregate'
  aggregate?: Maybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Fields>
  nodes: Array<Learning_Stats_Learner_Module_Rating_Response_Averages>
}

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp =
  {
    avg?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Avg>
    corr?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Corr>
    count?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Count>
    covar_samp?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Covar_Samp>
    max?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Max>
    min?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Min>
    stddev_samp?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Stddev_Samp>
    sum?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Sum>
    var_samp?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Var_Samp>
  }

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Avg =
  {
    arguments: Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Avg_Arguments_Columns
    distinct?: InputMaybe<Scalars['Boolean']>
    filter?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
    predicate: Float8_Comparison_Exp
  }

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Corr =
  {
    arguments: Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Corr_Arguments
    distinct?: InputMaybe<Scalars['Boolean']>
    filter?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
    predicate: Float8_Comparison_Exp
  }

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Corr_Arguments =
  {
    X: Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Corr_Arguments_Columns
    Y: Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Corr_Arguments_Columns
  }

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Count =
  {
    arguments?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
    filter?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
    predicate: Int_Comparison_Exp
  }

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Covar_Samp =
  {
    arguments: Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Covar_Samp_Arguments
    distinct?: InputMaybe<Scalars['Boolean']>
    filter?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
    predicate: Float8_Comparison_Exp
  }

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Covar_Samp_Arguments =
  {
    X: Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns
    Y: Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns
  }

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Max =
  {
    arguments: Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Max_Arguments_Columns
    distinct?: InputMaybe<Scalars['Boolean']>
    filter?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
    predicate: Float8_Comparison_Exp
  }

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Min =
  {
    arguments: Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Min_Arguments_Columns
    distinct?: InputMaybe<Scalars['Boolean']>
    filter?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
    predicate: Float8_Comparison_Exp
  }

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Stddev_Samp =
  {
    arguments: Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns
    distinct?: InputMaybe<Scalars['Boolean']>
    filter?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
    predicate: Float8_Comparison_Exp
  }

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Sum =
  {
    arguments: Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Sum_Arguments_Columns
    distinct?: InputMaybe<Scalars['Boolean']>
    filter?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
    predicate: Float8_Comparison_Exp
  }

export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Var_Samp =
  {
    arguments: Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns
    distinct?: InputMaybe<Scalars['Boolean']>
    filter?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
    predicate: Float8_Comparison_Exp
  }

/** aggregate fields of "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Fields =
  {
    __typename?: 'learning_stats_learner_module_rating_response_averages_aggregate_fields'
    avg?: Maybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Avg_Fields>
    count: Scalars['Int']
    max?: Maybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Max_Fields>
    min?: Maybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Min_Fields>
    stddev?: Maybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Fields>
    stddev_pop?: Maybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Pop_Fields>
    stddev_samp?: Maybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Samp_Fields>
    sum?: Maybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Sum_Fields>
    var_pop?: Maybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Var_Pop_Fields>
    var_samp?: Maybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Var_Samp_Fields>
    variance?: Maybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Variance_Fields>
  }

/** aggregate fields of "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
  }

/** order by aggregate values of table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Order_By =
  {
    avg?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Avg_Order_By>
    count?: InputMaybe<Order_By>
    max?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Max_Order_By>
    min?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Min_Order_By>
    stddev?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Order_By>
    stddev_pop?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Pop_Order_By>
    stddev_samp?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Samp_Order_By>
    sum?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Sum_Order_By>
    var_pop?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Var_Pop_Order_By>
    var_samp?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Var_Samp_Order_By>
    variance?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Variance_Order_By>
  }

/** input type for inserting array relation for remote table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Arr_Rel_Insert_Input =
  {
    data: Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Insert_Input>
  }

/** aggregate avg on columns */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Avg_Fields =
  {
    __typename?: 'learning_stats_learner_module_rating_response_averages_avg_fields'
    avg_response?: Maybe<Scalars['Float']>
    median_response?: Maybe<Scalars['Float']>
    position?: Maybe<Scalars['Float']>
  }

/** order by avg() on columns of table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Avg_Order_By =
  {
    avg_response?: InputMaybe<Order_By>
    median_response?: InputMaybe<Order_By>
    position?: InputMaybe<Order_By>
  }

/** Boolean expression to filter rows from the table "learning_stats_learner_module_rating_response_averages". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp = {
  _and?: InputMaybe<
    Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  >
  _not?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  _or?: InputMaybe<
    Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  >
  avg_response?: InputMaybe<Numeric_Comparison_Exp>
  median_response?: InputMaybe<Float8_Comparison_Exp>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  position?: InputMaybe<Int_Comparison_Exp>
  question_id?: InputMaybe<Uuid_Comparison_Exp>
  question_module_id?: InputMaybe<Uuid_Comparison_Exp>
  question_series_id?: InputMaybe<Uuid_Comparison_Exp>
  question_text?: InputMaybe<String_Comparison_Exp>
}

/** input type for inserting data into table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Insert_Input =
  {
    avg_response?: InputMaybe<Scalars['numeric']>
    median_response?: InputMaybe<Scalars['float8']>
    module?: InputMaybe<Learning_Modules_Obj_Rel_Insert_Input>
    module_id?: InputMaybe<Scalars['uuid']>
    position?: InputMaybe<Scalars['Int']>
    question_id?: InputMaybe<Scalars['uuid']>
    question_module_id?: InputMaybe<Scalars['uuid']>
    question_series_id?: InputMaybe<Scalars['uuid']>
    question_text?: InputMaybe<Scalars['String']>
  }

/** aggregate max on columns */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Max_Fields =
  {
    __typename?: 'learning_stats_learner_module_rating_response_averages_max_fields'
    avg_response?: Maybe<Scalars['numeric']>
    median_response?: Maybe<Scalars['float8']>
    module_id?: Maybe<Scalars['uuid']>
    position?: Maybe<Scalars['Int']>
    question_id?: Maybe<Scalars['uuid']>
    question_module_id?: Maybe<Scalars['uuid']>
    question_series_id?: Maybe<Scalars['uuid']>
    question_text?: Maybe<Scalars['String']>
  }

/** order by max() on columns of table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Max_Order_By =
  {
    avg_response?: InputMaybe<Order_By>
    median_response?: InputMaybe<Order_By>
    module_id?: InputMaybe<Order_By>
    position?: InputMaybe<Order_By>
    question_id?: InputMaybe<Order_By>
    question_module_id?: InputMaybe<Order_By>
    question_series_id?: InputMaybe<Order_By>
    question_text?: InputMaybe<Order_By>
  }

/** aggregate min on columns */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Min_Fields =
  {
    __typename?: 'learning_stats_learner_module_rating_response_averages_min_fields'
    avg_response?: Maybe<Scalars['numeric']>
    median_response?: Maybe<Scalars['float8']>
    module_id?: Maybe<Scalars['uuid']>
    position?: Maybe<Scalars['Int']>
    question_id?: Maybe<Scalars['uuid']>
    question_module_id?: Maybe<Scalars['uuid']>
    question_series_id?: Maybe<Scalars['uuid']>
    question_text?: Maybe<Scalars['String']>
  }

/** order by min() on columns of table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Min_Order_By =
  {
    avg_response?: InputMaybe<Order_By>
    median_response?: InputMaybe<Order_By>
    module_id?: InputMaybe<Order_By>
    position?: InputMaybe<Order_By>
    question_id?: InputMaybe<Order_By>
    question_module_id?: InputMaybe<Order_By>
    question_series_id?: InputMaybe<Order_By>
    question_text?: InputMaybe<Order_By>
  }

/** Ordering options when selecting data from "learning_stats_learner_module_rating_response_averages". */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Order_By = {
  avg_response?: InputMaybe<Order_By>
  median_response?: InputMaybe<Order_By>
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  question_module_id?: InputMaybe<Order_By>
  question_series_id?: InputMaybe<Order_By>
  question_text?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_learner_module_rating_response_averages" */
export enum Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column {
  /** column name */
  AvgResponse = 'avg_response',
  /** column name */
  MedianResponse = 'median_response',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Position = 'position',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  QuestionModuleId = 'question_module_id',
  /** column name */
  QuestionSeriesId = 'question_series_id',
  /** column name */
  QuestionText = 'question_text',
}

/** select "learning_stats_learner_module_rating_response_averages_aggregate_bool_exp_avg_arguments_columns" columns of table "learning_stats_learner_module_rating_response_averages" */
export enum Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  MedianResponse = 'median_response',
}

/** select "learning_stats_learner_module_rating_response_averages_aggregate_bool_exp_corr_arguments_columns" columns of table "learning_stats_learner_module_rating_response_averages" */
export enum Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  MedianResponse = 'median_response',
}

/** select "learning_stats_learner_module_rating_response_averages_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "learning_stats_learner_module_rating_response_averages" */
export enum Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  MedianResponse = 'median_response',
}

/** select "learning_stats_learner_module_rating_response_averages_aggregate_bool_exp_max_arguments_columns" columns of table "learning_stats_learner_module_rating_response_averages" */
export enum Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  MedianResponse = 'median_response',
}

/** select "learning_stats_learner_module_rating_response_averages_aggregate_bool_exp_min_arguments_columns" columns of table "learning_stats_learner_module_rating_response_averages" */
export enum Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  MedianResponse = 'median_response',
}

/** select "learning_stats_learner_module_rating_response_averages_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "learning_stats_learner_module_rating_response_averages" */
export enum Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  MedianResponse = 'median_response',
}

/** select "learning_stats_learner_module_rating_response_averages_aggregate_bool_exp_sum_arguments_columns" columns of table "learning_stats_learner_module_rating_response_averages" */
export enum Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  MedianResponse = 'median_response',
}

/** select "learning_stats_learner_module_rating_response_averages_aggregate_bool_exp_var_samp_arguments_columns" columns of table "learning_stats_learner_module_rating_response_averages" */
export enum Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column_Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  MedianResponse = 'median_response',
}

/** aggregate stddev on columns */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Fields =
  {
    __typename?: 'learning_stats_learner_module_rating_response_averages_stddev_fields'
    avg_response?: Maybe<Scalars['Float']>
    median_response?: Maybe<Scalars['Float']>
    position?: Maybe<Scalars['Float']>
  }

/** order by stddev() on columns of table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Order_By =
  {
    avg_response?: InputMaybe<Order_By>
    median_response?: InputMaybe<Order_By>
    position?: InputMaybe<Order_By>
  }

/** aggregate stddev_pop on columns */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Pop_Fields =
  {
    __typename?: 'learning_stats_learner_module_rating_response_averages_stddev_pop_fields'
    avg_response?: Maybe<Scalars['Float']>
    median_response?: Maybe<Scalars['Float']>
    position?: Maybe<Scalars['Float']>
  }

/** order by stddev_pop() on columns of table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Pop_Order_By =
  {
    avg_response?: InputMaybe<Order_By>
    median_response?: InputMaybe<Order_By>
    position?: InputMaybe<Order_By>
  }

/** aggregate stddev_samp on columns */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Samp_Fields =
  {
    __typename?: 'learning_stats_learner_module_rating_response_averages_stddev_samp_fields'
    avg_response?: Maybe<Scalars['Float']>
    median_response?: Maybe<Scalars['Float']>
    position?: Maybe<Scalars['Float']>
  }

/** order by stddev_samp() on columns of table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Stddev_Samp_Order_By =
  {
    avg_response?: InputMaybe<Order_By>
    median_response?: InputMaybe<Order_By>
    position?: InputMaybe<Order_By>
  }

/** Streaming cursor of the table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Stream_Cursor_Input =
  {
    /** Stream column input with initial value */
    initial_value: Learning_Stats_Learner_Module_Rating_Response_Averages_Stream_Cursor_Value_Input
    /** cursor ordering */
    ordering?: InputMaybe<Cursor_Ordering>
  }

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Stream_Cursor_Value_Input =
  {
    avg_response?: InputMaybe<Scalars['numeric']>
    median_response?: InputMaybe<Scalars['float8']>
    module_id?: InputMaybe<Scalars['uuid']>
    position?: InputMaybe<Scalars['Int']>
    question_id?: InputMaybe<Scalars['uuid']>
    question_module_id?: InputMaybe<Scalars['uuid']>
    question_series_id?: InputMaybe<Scalars['uuid']>
    question_text?: InputMaybe<Scalars['String']>
  }

/** aggregate sum on columns */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Sum_Fields =
  {
    __typename?: 'learning_stats_learner_module_rating_response_averages_sum_fields'
    avg_response?: Maybe<Scalars['numeric']>
    median_response?: Maybe<Scalars['float8']>
    position?: Maybe<Scalars['Int']>
  }

/** order by sum() on columns of table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Sum_Order_By =
  {
    avg_response?: InputMaybe<Order_By>
    median_response?: InputMaybe<Order_By>
    position?: InputMaybe<Order_By>
  }

/** aggregate var_pop on columns */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Var_Pop_Fields =
  {
    __typename?: 'learning_stats_learner_module_rating_response_averages_var_pop_fields'
    avg_response?: Maybe<Scalars['Float']>
    median_response?: Maybe<Scalars['Float']>
    position?: Maybe<Scalars['Float']>
  }

/** order by var_pop() on columns of table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Var_Pop_Order_By =
  {
    avg_response?: InputMaybe<Order_By>
    median_response?: InputMaybe<Order_By>
    position?: InputMaybe<Order_By>
  }

/** aggregate var_samp on columns */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Var_Samp_Fields =
  {
    __typename?: 'learning_stats_learner_module_rating_response_averages_var_samp_fields'
    avg_response?: Maybe<Scalars['Float']>
    median_response?: Maybe<Scalars['Float']>
    position?: Maybe<Scalars['Float']>
  }

/** order by var_samp() on columns of table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Var_Samp_Order_By =
  {
    avg_response?: InputMaybe<Order_By>
    median_response?: InputMaybe<Order_By>
    position?: InputMaybe<Order_By>
  }

/** aggregate variance on columns */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Variance_Fields =
  {
    __typename?: 'learning_stats_learner_module_rating_response_averages_variance_fields'
    avg_response?: Maybe<Scalars['Float']>
    median_response?: Maybe<Scalars['Float']>
    position?: Maybe<Scalars['Float']>
  }

/** order by variance() on columns of table "learning_stats_learner_module_rating_response_averages" */
export type Learning_Stats_Learner_Module_Rating_Response_Averages_Variance_Order_By =
  {
    avg_response?: InputMaybe<Order_By>
    median_response?: InputMaybe<Order_By>
    position?: InputMaybe<Order_By>
  }

export type Learning_Stats_Module_Rating_Response_Averages_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

/** columns and relationships of "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses = {
  __typename?: 'learning_stats_module_rating_responses'
  /** An object relationship */
  module?: Maybe<Learning_Modules>
  module_id?: Maybe<Scalars['uuid']>
  position?: Maybe<Scalars['Int']>
  question_id?: Maybe<Scalars['uuid']>
  question_module_id?: Maybe<Scalars['uuid']>
  question_series_id?: Maybe<Scalars['uuid']>
  question_text?: Maybe<Scalars['String']>
  response_1?: Maybe<Scalars['bigint']>
  response_2?: Maybe<Scalars['bigint']>
  response_3?: Maybe<Scalars['bigint']>
  response_4?: Maybe<Scalars['bigint']>
  response_5?: Maybe<Scalars['bigint']>
  total_responses?: Maybe<Scalars['bigint']>
}

/** aggregated selection of "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Aggregate = {
  __typename?: 'learning_stats_module_rating_responses_aggregate'
  aggregate?: Maybe<Learning_Stats_Module_Rating_Responses_Aggregate_Fields>
  nodes: Array<Learning_Stats_Module_Rating_Responses>
}

export type Learning_Stats_Module_Rating_Responses_Aggregate_Bool_Exp = {
  count?: InputMaybe<Learning_Stats_Module_Rating_Responses_Aggregate_Bool_Exp_Count>
}

export type Learning_Stats_Module_Rating_Responses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Learning_Stats_Module_Rating_Responses_Select_Column>
  >
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Aggregate_Fields = {
  __typename?: 'learning_stats_module_rating_responses_aggregate_fields'
  avg?: Maybe<Learning_Stats_Module_Rating_Responses_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Module_Rating_Responses_Max_Fields>
  min?: Maybe<Learning_Stats_Module_Rating_Responses_Min_Fields>
  stddev?: Maybe<Learning_Stats_Module_Rating_Responses_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Module_Rating_Responses_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Module_Rating_Responses_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Module_Rating_Responses_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Module_Rating_Responses_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Module_Rating_Responses_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Module_Rating_Responses_Variance_Fields>
}

/** aggregate fields of "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Learning_Stats_Module_Rating_Responses_Select_Column>
  >
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Aggregate_Order_By = {
  avg?: InputMaybe<Learning_Stats_Module_Rating_Responses_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Learning_Stats_Module_Rating_Responses_Max_Order_By>
  min?: InputMaybe<Learning_Stats_Module_Rating_Responses_Min_Order_By>
  stddev?: InputMaybe<Learning_Stats_Module_Rating_Responses_Stddev_Order_By>
  stddev_pop?: InputMaybe<Learning_Stats_Module_Rating_Responses_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Learning_Stats_Module_Rating_Responses_Stddev_Samp_Order_By>
  sum?: InputMaybe<Learning_Stats_Module_Rating_Responses_Sum_Order_By>
  var_pop?: InputMaybe<Learning_Stats_Module_Rating_Responses_Var_Pop_Order_By>
  var_samp?: InputMaybe<Learning_Stats_Module_Rating_Responses_Var_Samp_Order_By>
  variance?: InputMaybe<Learning_Stats_Module_Rating_Responses_Variance_Order_By>
}

/** input type for inserting array relation for remote table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Arr_Rel_Insert_Input = {
  data: Array<Learning_Stats_Module_Rating_Responses_Insert_Input>
}

/** aggregate avg on columns */
export type Learning_Stats_Module_Rating_Responses_Avg_Fields = {
  __typename?: 'learning_stats_module_rating_responses_avg_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Avg_Order_By = {
  position?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "learning_stats_module_rating_responses". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Module_Rating_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Stats_Module_Rating_Responses_Bool_Exp>>
  _not?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Module_Rating_Responses_Bool_Exp>>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  position?: InputMaybe<Int_Comparison_Exp>
  question_id?: InputMaybe<Uuid_Comparison_Exp>
  question_module_id?: InputMaybe<Uuid_Comparison_Exp>
  question_series_id?: InputMaybe<Uuid_Comparison_Exp>
  question_text?: InputMaybe<String_Comparison_Exp>
  response_1?: InputMaybe<Bigint_Comparison_Exp>
  response_2?: InputMaybe<Bigint_Comparison_Exp>
  response_3?: InputMaybe<Bigint_Comparison_Exp>
  response_4?: InputMaybe<Bigint_Comparison_Exp>
  response_5?: InputMaybe<Bigint_Comparison_Exp>
  total_responses?: InputMaybe<Bigint_Comparison_Exp>
}

export type Learning_Stats_Module_Rating_Responses_For_Module_Within_Range_Args =
  {
    endtime?: InputMaybe<Scalars['timestamptz']>
    moduleid?: InputMaybe<Scalars['uuid']>
    starttime?: InputMaybe<Scalars['timestamptz']>
  }

export type Learning_Stats_Module_Rating_Responses_In_Library_Within_Range_Args =
  {
    endtime?: InputMaybe<Scalars['timestamptz']>
    libraryid?: InputMaybe<Scalars['String']>
    librarytype?: InputMaybe<Scalars['String']>
    starttime?: InputMaybe<Scalars['timestamptz']>
  }

export type Learning_Stats_Module_Rating_Responses_In_Series_Within_Range_Args =
  {
    endtime?: InputMaybe<Scalars['timestamptz']>
    seriesid?: InputMaybe<Scalars['uuid']>
    starttime?: InputMaybe<Scalars['timestamptz']>
  }

/** input type for inserting data into table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Insert_Input = {
  module?: InputMaybe<Learning_Modules_Obj_Rel_Insert_Input>
  module_id?: InputMaybe<Scalars['uuid']>
  position?: InputMaybe<Scalars['Int']>
  question_id?: InputMaybe<Scalars['uuid']>
  question_module_id?: InputMaybe<Scalars['uuid']>
  question_series_id?: InputMaybe<Scalars['uuid']>
  question_text?: InputMaybe<Scalars['String']>
  response_1?: InputMaybe<Scalars['bigint']>
  response_2?: InputMaybe<Scalars['bigint']>
  response_3?: InputMaybe<Scalars['bigint']>
  response_4?: InputMaybe<Scalars['bigint']>
  response_5?: InputMaybe<Scalars['bigint']>
  total_responses?: InputMaybe<Scalars['bigint']>
}

/** aggregate max on columns */
export type Learning_Stats_Module_Rating_Responses_Max_Fields = {
  __typename?: 'learning_stats_module_rating_responses_max_fields'
  module_id?: Maybe<Scalars['uuid']>
  position?: Maybe<Scalars['Int']>
  question_id?: Maybe<Scalars['uuid']>
  question_module_id?: Maybe<Scalars['uuid']>
  question_series_id?: Maybe<Scalars['uuid']>
  question_text?: Maybe<Scalars['String']>
  response_1?: Maybe<Scalars['bigint']>
  response_2?: Maybe<Scalars['bigint']>
  response_3?: Maybe<Scalars['bigint']>
  response_4?: Maybe<Scalars['bigint']>
  response_5?: Maybe<Scalars['bigint']>
  total_responses?: Maybe<Scalars['bigint']>
}

/** order by max() on columns of table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Max_Order_By = {
  module_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  question_module_id?: InputMaybe<Order_By>
  question_series_id?: InputMaybe<Order_By>
  question_text?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Learning_Stats_Module_Rating_Responses_Min_Fields = {
  __typename?: 'learning_stats_module_rating_responses_min_fields'
  module_id?: Maybe<Scalars['uuid']>
  position?: Maybe<Scalars['Int']>
  question_id?: Maybe<Scalars['uuid']>
  question_module_id?: Maybe<Scalars['uuid']>
  question_series_id?: Maybe<Scalars['uuid']>
  question_text?: Maybe<Scalars['String']>
  response_1?: Maybe<Scalars['bigint']>
  response_2?: Maybe<Scalars['bigint']>
  response_3?: Maybe<Scalars['bigint']>
  response_4?: Maybe<Scalars['bigint']>
  response_5?: Maybe<Scalars['bigint']>
  total_responses?: Maybe<Scalars['bigint']>
}

/** order by min() on columns of table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Min_Order_By = {
  module_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  question_module_id?: InputMaybe<Order_By>
  question_series_id?: InputMaybe<Order_By>
  question_text?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

/** Ordering options when selecting data from "learning_stats_module_rating_responses". */
export type Learning_Stats_Module_Rating_Responses_Order_By = {
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  question_module_id?: InputMaybe<Order_By>
  question_series_id?: InputMaybe<Order_By>
  question_text?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_module_rating_responses" */
export enum Learning_Stats_Module_Rating_Responses_Select_Column {
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  Position = 'position',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  QuestionModuleId = 'question_module_id',
  /** column name */
  QuestionSeriesId = 'question_series_id',
  /** column name */
  QuestionText = 'question_text',
  /** column name */
  Response_1 = 'response_1',
  /** column name */
  Response_2 = 'response_2',
  /** column name */
  Response_3 = 'response_3',
  /** column name */
  Response_4 = 'response_4',
  /** column name */
  Response_5 = 'response_5',
  /** column name */
  TotalResponses = 'total_responses',
}

/** aggregate stddev on columns */
export type Learning_Stats_Module_Rating_Responses_Stddev_Fields = {
  __typename?: 'learning_stats_module_rating_responses_stddev_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Stddev_Order_By = {
  position?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Module_Rating_Responses_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_module_rating_responses_stddev_pop_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Stddev_Pop_Order_By = {
  position?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Module_Rating_Responses_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_module_rating_responses_stddev_samp_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Stddev_Samp_Order_By = {
  position?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Module_Rating_Responses_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Module_Rating_Responses_Stream_Cursor_Value_Input = {
  module_id?: InputMaybe<Scalars['uuid']>
  position?: InputMaybe<Scalars['Int']>
  question_id?: InputMaybe<Scalars['uuid']>
  question_module_id?: InputMaybe<Scalars['uuid']>
  question_series_id?: InputMaybe<Scalars['uuid']>
  question_text?: InputMaybe<Scalars['String']>
  response_1?: InputMaybe<Scalars['bigint']>
  response_2?: InputMaybe<Scalars['bigint']>
  response_3?: InputMaybe<Scalars['bigint']>
  response_4?: InputMaybe<Scalars['bigint']>
  response_5?: InputMaybe<Scalars['bigint']>
  total_responses?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Learning_Stats_Module_Rating_Responses_Sum_Fields = {
  __typename?: 'learning_stats_module_rating_responses_sum_fields'
  position?: Maybe<Scalars['Int']>
  response_1?: Maybe<Scalars['bigint']>
  response_2?: Maybe<Scalars['bigint']>
  response_3?: Maybe<Scalars['bigint']>
  response_4?: Maybe<Scalars['bigint']>
  response_5?: Maybe<Scalars['bigint']>
  total_responses?: Maybe<Scalars['bigint']>
}

/** order by sum() on columns of table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Sum_Order_By = {
  position?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Module_Rating_Responses_Var_Pop_Fields = {
  __typename?: 'learning_stats_module_rating_responses_var_pop_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Var_Pop_Order_By = {
  position?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Module_Rating_Responses_Var_Samp_Fields = {
  __typename?: 'learning_stats_module_rating_responses_var_samp_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Var_Samp_Order_By = {
  position?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Learning_Stats_Module_Rating_Responses_Variance_Fields = {
  __typename?: 'learning_stats_module_rating_responses_variance_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "learning_stats_module_rating_responses" */
export type Learning_Stats_Module_Rating_Responses_Variance_Order_By = {
  position?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

export type Learning_Stats_Module_Rating_Responses_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Stats_Most_Active_Libraries_By_Period_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  periodfield?: InputMaybe<Scalars['String']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Stats_Most_Active_Libraries_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Stats_Most_Active_Modules_In_Library_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  libraryid?: InputMaybe<Scalars['String']>
  librarytype?: InputMaybe<Scalars['String']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Stats_Most_Active_Modules_In_Series_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  seriesid?: InputMaybe<Scalars['uuid']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Stats_Most_Active_Modules_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Stats_Most_Active_Series_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Stats_Most_Active_Users_For_Module_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  moduleid?: InputMaybe<Scalars['uuid']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Stats_Most_Active_Users_In_Library_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  libraryid?: InputMaybe<Scalars['String']>
  librarytype?: InputMaybe<Scalars['String']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Stats_Most_Active_Users_In_Series_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  seriesid?: InputMaybe<Scalars['uuid']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Stats_Most_Active_Users_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

/** columns and relationships of "learning_stats_rating_response_averages" */
export type Learning_Stats_Rating_Response_Averages = {
  __typename?: 'learning_stats_rating_response_averages'
  avg_response?: Maybe<Scalars['numeric']>
  median_response?: Maybe<Scalars['float8']>
  position?: Maybe<Scalars['Int']>
  question_id?: Maybe<Scalars['uuid']>
  question_module_id?: Maybe<Scalars['uuid']>
  question_series_id?: Maybe<Scalars['uuid']>
  question_text?: Maybe<Scalars['String']>
}

/** aggregated selection of "learning_stats_rating_response_averages" */
export type Learning_Stats_Rating_Response_Averages_Aggregate = {
  __typename?: 'learning_stats_rating_response_averages_aggregate'
  aggregate?: Maybe<Learning_Stats_Rating_Response_Averages_Aggregate_Fields>
  nodes: Array<Learning_Stats_Rating_Response_Averages>
}

/** aggregate fields of "learning_stats_rating_response_averages" */
export type Learning_Stats_Rating_Response_Averages_Aggregate_Fields = {
  __typename?: 'learning_stats_rating_response_averages_aggregate_fields'
  avg?: Maybe<Learning_Stats_Rating_Response_Averages_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Rating_Response_Averages_Max_Fields>
  min?: Maybe<Learning_Stats_Rating_Response_Averages_Min_Fields>
  stddev?: Maybe<Learning_Stats_Rating_Response_Averages_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Rating_Response_Averages_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Rating_Response_Averages_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Rating_Response_Averages_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Rating_Response_Averages_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Rating_Response_Averages_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Rating_Response_Averages_Variance_Fields>
}

/** aggregate fields of "learning_stats_rating_response_averages" */
export type Learning_Stats_Rating_Response_Averages_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Learning_Stats_Rating_Response_Averages_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
  }

/** aggregate avg on columns */
export type Learning_Stats_Rating_Response_Averages_Avg_Fields = {
  __typename?: 'learning_stats_rating_response_averages_avg_fields'
  avg_response?: Maybe<Scalars['Float']>
  median_response?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_rating_response_averages". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Rating_Response_Averages_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Stats_Rating_Response_Averages_Bool_Exp>>
  _not?: InputMaybe<Learning_Stats_Rating_Response_Averages_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Rating_Response_Averages_Bool_Exp>>
  avg_response?: InputMaybe<Numeric_Comparison_Exp>
  median_response?: InputMaybe<Float8_Comparison_Exp>
  position?: InputMaybe<Int_Comparison_Exp>
  question_id?: InputMaybe<Uuid_Comparison_Exp>
  question_module_id?: InputMaybe<Uuid_Comparison_Exp>
  question_series_id?: InputMaybe<Uuid_Comparison_Exp>
  question_text?: InputMaybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Learning_Stats_Rating_Response_Averages_Max_Fields = {
  __typename?: 'learning_stats_rating_response_averages_max_fields'
  avg_response?: Maybe<Scalars['numeric']>
  median_response?: Maybe<Scalars['float8']>
  position?: Maybe<Scalars['Int']>
  question_id?: Maybe<Scalars['uuid']>
  question_module_id?: Maybe<Scalars['uuid']>
  question_series_id?: Maybe<Scalars['uuid']>
  question_text?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Learning_Stats_Rating_Response_Averages_Min_Fields = {
  __typename?: 'learning_stats_rating_response_averages_min_fields'
  avg_response?: Maybe<Scalars['numeric']>
  median_response?: Maybe<Scalars['float8']>
  position?: Maybe<Scalars['Int']>
  question_id?: Maybe<Scalars['uuid']>
  question_module_id?: Maybe<Scalars['uuid']>
  question_series_id?: Maybe<Scalars['uuid']>
  question_text?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "learning_stats_rating_response_averages". */
export type Learning_Stats_Rating_Response_Averages_Order_By = {
  avg_response?: InputMaybe<Order_By>
  median_response?: InputMaybe<Order_By>
  position?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  question_module_id?: InputMaybe<Order_By>
  question_series_id?: InputMaybe<Order_By>
  question_text?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_rating_response_averages" */
export enum Learning_Stats_Rating_Response_Averages_Select_Column {
  /** column name */
  AvgResponse = 'avg_response',
  /** column name */
  MedianResponse = 'median_response',
  /** column name */
  Position = 'position',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  QuestionModuleId = 'question_module_id',
  /** column name */
  QuestionSeriesId = 'question_series_id',
  /** column name */
  QuestionText = 'question_text',
}

/** aggregate stddev on columns */
export type Learning_Stats_Rating_Response_Averages_Stddev_Fields = {
  __typename?: 'learning_stats_rating_response_averages_stddev_fields'
  avg_response?: Maybe<Scalars['Float']>
  median_response?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Rating_Response_Averages_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_rating_response_averages_stddev_pop_fields'
  avg_response?: Maybe<Scalars['Float']>
  median_response?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Rating_Response_Averages_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_rating_response_averages_stddev_samp_fields'
  avg_response?: Maybe<Scalars['Float']>
  median_response?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_rating_response_averages" */
export type Learning_Stats_Rating_Response_Averages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Rating_Response_Averages_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Rating_Response_Averages_Stream_Cursor_Value_Input =
  {
    avg_response?: InputMaybe<Scalars['numeric']>
    median_response?: InputMaybe<Scalars['float8']>
    position?: InputMaybe<Scalars['Int']>
    question_id?: InputMaybe<Scalars['uuid']>
    question_module_id?: InputMaybe<Scalars['uuid']>
    question_series_id?: InputMaybe<Scalars['uuid']>
    question_text?: InputMaybe<Scalars['String']>
  }

/** aggregate sum on columns */
export type Learning_Stats_Rating_Response_Averages_Sum_Fields = {
  __typename?: 'learning_stats_rating_response_averages_sum_fields'
  avg_response?: Maybe<Scalars['numeric']>
  median_response?: Maybe<Scalars['float8']>
  position?: Maybe<Scalars['Int']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Rating_Response_Averages_Var_Pop_Fields = {
  __typename?: 'learning_stats_rating_response_averages_var_pop_fields'
  avg_response?: Maybe<Scalars['Float']>
  median_response?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Rating_Response_Averages_Var_Samp_Fields = {
  __typename?: 'learning_stats_rating_response_averages_var_samp_fields'
  avg_response?: Maybe<Scalars['Float']>
  median_response?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Rating_Response_Averages_Variance_Fields = {
  __typename?: 'learning_stats_rating_response_averages_variance_fields'
  avg_response?: Maybe<Scalars['Float']>
  median_response?: Maybe<Scalars['Float']>
  position?: Maybe<Scalars['Float']>
}

export type Learning_Stats_Rating_Response_Averages_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

/** columns and relationships of "learning_stats_rating_responses" */
export type Learning_Stats_Rating_Responses = {
  __typename?: 'learning_stats_rating_responses'
  /** An object relationship */
  module?: Maybe<Learning_Modules>
  position?: Maybe<Scalars['Int']>
  question_id?: Maybe<Scalars['uuid']>
  question_module_id?: Maybe<Scalars['uuid']>
  question_series_id?: Maybe<Scalars['uuid']>
  question_text?: Maybe<Scalars['String']>
  response_1?: Maybe<Scalars['bigint']>
  response_2?: Maybe<Scalars['bigint']>
  response_3?: Maybe<Scalars['bigint']>
  response_4?: Maybe<Scalars['bigint']>
  response_5?: Maybe<Scalars['bigint']>
  total_responses?: Maybe<Scalars['bigint']>
}

/** aggregated selection of "learning_stats_rating_responses" */
export type Learning_Stats_Rating_Responses_Aggregate = {
  __typename?: 'learning_stats_rating_responses_aggregate'
  aggregate?: Maybe<Learning_Stats_Rating_Responses_Aggregate_Fields>
  nodes: Array<Learning_Stats_Rating_Responses>
}

/** aggregate fields of "learning_stats_rating_responses" */
export type Learning_Stats_Rating_Responses_Aggregate_Fields = {
  __typename?: 'learning_stats_rating_responses_aggregate_fields'
  avg?: Maybe<Learning_Stats_Rating_Responses_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Rating_Responses_Max_Fields>
  min?: Maybe<Learning_Stats_Rating_Responses_Min_Fields>
  stddev?: Maybe<Learning_Stats_Rating_Responses_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Rating_Responses_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Rating_Responses_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Rating_Responses_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Rating_Responses_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Rating_Responses_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Rating_Responses_Variance_Fields>
}

/** aggregate fields of "learning_stats_rating_responses" */
export type Learning_Stats_Rating_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Stats_Rating_Responses_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Learning_Stats_Rating_Responses_Avg_Fields = {
  __typename?: 'learning_stats_rating_responses_avg_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_rating_responses". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Rating_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Stats_Rating_Responses_Bool_Exp>>
  _not?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Rating_Responses_Bool_Exp>>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  position?: InputMaybe<Int_Comparison_Exp>
  question_id?: InputMaybe<Uuid_Comparison_Exp>
  question_module_id?: InputMaybe<Uuid_Comparison_Exp>
  question_series_id?: InputMaybe<Uuid_Comparison_Exp>
  question_text?: InputMaybe<String_Comparison_Exp>
  response_1?: InputMaybe<Bigint_Comparison_Exp>
  response_2?: InputMaybe<Bigint_Comparison_Exp>
  response_3?: InputMaybe<Bigint_Comparison_Exp>
  response_4?: InputMaybe<Bigint_Comparison_Exp>
  response_5?: InputMaybe<Bigint_Comparison_Exp>
  total_responses?: InputMaybe<Bigint_Comparison_Exp>
}

export type Learning_Stats_Rating_Responses_In_Library_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  libraryid?: InputMaybe<Scalars['String']>
  librarytype?: InputMaybe<Scalars['String']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

export type Learning_Stats_Rating_Responses_In_Series_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  seriesid?: InputMaybe<Scalars['uuid']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Learning_Stats_Rating_Responses_Max_Fields = {
  __typename?: 'learning_stats_rating_responses_max_fields'
  position?: Maybe<Scalars['Int']>
  question_id?: Maybe<Scalars['uuid']>
  question_module_id?: Maybe<Scalars['uuid']>
  question_series_id?: Maybe<Scalars['uuid']>
  question_text?: Maybe<Scalars['String']>
  response_1?: Maybe<Scalars['bigint']>
  response_2?: Maybe<Scalars['bigint']>
  response_3?: Maybe<Scalars['bigint']>
  response_4?: Maybe<Scalars['bigint']>
  response_5?: Maybe<Scalars['bigint']>
  total_responses?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Stats_Rating_Responses_Min_Fields = {
  __typename?: 'learning_stats_rating_responses_min_fields'
  position?: Maybe<Scalars['Int']>
  question_id?: Maybe<Scalars['uuid']>
  question_module_id?: Maybe<Scalars['uuid']>
  question_series_id?: Maybe<Scalars['uuid']>
  question_text?: Maybe<Scalars['String']>
  response_1?: Maybe<Scalars['bigint']>
  response_2?: Maybe<Scalars['bigint']>
  response_3?: Maybe<Scalars['bigint']>
  response_4?: Maybe<Scalars['bigint']>
  response_5?: Maybe<Scalars['bigint']>
  total_responses?: Maybe<Scalars['bigint']>
}

/** Ordering options when selecting data from "learning_stats_rating_responses". */
export type Learning_Stats_Rating_Responses_Order_By = {
  module?: InputMaybe<Learning_Modules_Order_By>
  position?: InputMaybe<Order_By>
  question_id?: InputMaybe<Order_By>
  question_module_id?: InputMaybe<Order_By>
  question_series_id?: InputMaybe<Order_By>
  question_text?: InputMaybe<Order_By>
  response_1?: InputMaybe<Order_By>
  response_2?: InputMaybe<Order_By>
  response_3?: InputMaybe<Order_By>
  response_4?: InputMaybe<Order_By>
  response_5?: InputMaybe<Order_By>
  total_responses?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_rating_responses" */
export enum Learning_Stats_Rating_Responses_Select_Column {
  /** column name */
  Position = 'position',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  QuestionModuleId = 'question_module_id',
  /** column name */
  QuestionSeriesId = 'question_series_id',
  /** column name */
  QuestionText = 'question_text',
  /** column name */
  Response_1 = 'response_1',
  /** column name */
  Response_2 = 'response_2',
  /** column name */
  Response_3 = 'response_3',
  /** column name */
  Response_4 = 'response_4',
  /** column name */
  Response_5 = 'response_5',
  /** column name */
  TotalResponses = 'total_responses',
}

/** aggregate stddev on columns */
export type Learning_Stats_Rating_Responses_Stddev_Fields = {
  __typename?: 'learning_stats_rating_responses_stddev_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Rating_Responses_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_rating_responses_stddev_pop_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Rating_Responses_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_rating_responses_stddev_samp_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_rating_responses" */
export type Learning_Stats_Rating_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Rating_Responses_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Rating_Responses_Stream_Cursor_Value_Input = {
  position?: InputMaybe<Scalars['Int']>
  question_id?: InputMaybe<Scalars['uuid']>
  question_module_id?: InputMaybe<Scalars['uuid']>
  question_series_id?: InputMaybe<Scalars['uuid']>
  question_text?: InputMaybe<Scalars['String']>
  response_1?: InputMaybe<Scalars['bigint']>
  response_2?: InputMaybe<Scalars['bigint']>
  response_3?: InputMaybe<Scalars['bigint']>
  response_4?: InputMaybe<Scalars['bigint']>
  response_5?: InputMaybe<Scalars['bigint']>
  total_responses?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Learning_Stats_Rating_Responses_Sum_Fields = {
  __typename?: 'learning_stats_rating_responses_sum_fields'
  position?: Maybe<Scalars['Int']>
  response_1?: Maybe<Scalars['bigint']>
  response_2?: Maybe<Scalars['bigint']>
  response_3?: Maybe<Scalars['bigint']>
  response_4?: Maybe<Scalars['bigint']>
  response_5?: Maybe<Scalars['bigint']>
  total_responses?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Rating_Responses_Var_Pop_Fields = {
  __typename?: 'learning_stats_rating_responses_var_pop_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Rating_Responses_Var_Samp_Fields = {
  __typename?: 'learning_stats_rating_responses_var_samp_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Rating_Responses_Variance_Fields = {
  __typename?: 'learning_stats_rating_responses_variance_fields'
  position?: Maybe<Scalars['Float']>
  response_1?: Maybe<Scalars['Float']>
  response_2?: Maybe<Scalars['Float']>
  response_3?: Maybe<Scalars['Float']>
  response_4?: Maybe<Scalars['Float']>
  response_5?: Maybe<Scalars['Float']>
  total_responses?: Maybe<Scalars['Float']>
}

export type Learning_Stats_Rating_Responses_Within_Range_Args = {
  endtime?: InputMaybe<Scalars['timestamptz']>
  starttime?: InputMaybe<Scalars['timestamptz']>
}

/** columns and relationships of "learning_stats_sessions_by_library" */
export type Learning_Stats_Sessions_By_Library = {
  __typename?: 'learning_stats_sessions_by_library'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  /** An array relationship */
  library_admins: Array<User_Group_Members_Roles>
  /** An aggregate relationship */
  library_admins_aggregate: User_Group_Members_Roles_Aggregate
  /** An object relationship */
  library_group?: Maybe<Groups>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** columns and relationships of "learning_stats_sessions_by_library" */
export type Learning_Stats_Sessions_By_LibraryLibrary_AdminsArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Roles_Order_By>>
  where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
}

/** columns and relationships of "learning_stats_sessions_by_library" */
export type Learning_Stats_Sessions_By_LibraryLibrary_Admins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Roles_Order_By>>
  where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
}

export type Learning_Stats_Sessions_By_Library_Aggregate = {
  __typename?: 'learning_stats_sessions_by_library_aggregate'
  aggregate?: Maybe<Learning_Stats_Sessions_By_Library_Aggregate_Fields>
  nodes: Array<Learning_Stats_Sessions_By_Library>
}

/** aggregate fields of "learning_stats_sessions_by_library" */
export type Learning_Stats_Sessions_By_Library_Aggregate_Fields = {
  __typename?: 'learning_stats_sessions_by_library_aggregate_fields'
  avg?: Maybe<Learning_Stats_Sessions_By_Library_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Sessions_By_Library_Max_Fields>
  min?: Maybe<Learning_Stats_Sessions_By_Library_Min_Fields>
  stddev?: Maybe<Learning_Stats_Sessions_By_Library_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Sessions_By_Library_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Sessions_By_Library_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Sessions_By_Library_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Sessions_By_Library_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Sessions_By_Library_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Sessions_By_Library_Variance_Fields>
}

/** aggregate fields of "learning_stats_sessions_by_library" */
export type Learning_Stats_Sessions_By_Library_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Learning_Stats_Sessions_By_Library_Avg_Fields = {
  __typename?: 'learning_stats_sessions_by_library_avg_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_sessions_by_library". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Sessions_By_Library_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_Bool_Exp>>
  _not?: InputMaybe<Learning_Stats_Sessions_By_Library_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_Bool_Exp>>
  failed?: InputMaybe<Bigint_Comparison_Exp>
  finished?: InputMaybe<Bigint_Comparison_Exp>
  library_admins?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
  library_admins_aggregate?: InputMaybe<User_Group_Members_Roles_Aggregate_Bool_Exp>
  library_group?: InputMaybe<Groups_Bool_Exp>
  library_id?: InputMaybe<String_Comparison_Exp>
  library_type?: InputMaybe<String_Comparison_Exp>
  no_pass_mark?: InputMaybe<Bigint_Comparison_Exp>
  passed?: InputMaybe<Bigint_Comparison_Exp>
  sessions?: InputMaybe<Bigint_Comparison_Exp>
}

/** columns and relationships of "learning_stats_sessions_by_library_by_module" */
export type Learning_Stats_Sessions_By_Library_By_Module = {
  __typename?: 'learning_stats_sessions_by_library_by_module'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregated selection of "learning_stats_sessions_by_library_by_module" */
export type Learning_Stats_Sessions_By_Library_By_Module_Aggregate = {
  __typename?: 'learning_stats_sessions_by_library_by_module_aggregate'
  aggregate?: Maybe<Learning_Stats_Sessions_By_Library_By_Module_Aggregate_Fields>
  nodes: Array<Learning_Stats_Sessions_By_Library_By_Module>
}

/** aggregate fields of "learning_stats_sessions_by_library_by_module" */
export type Learning_Stats_Sessions_By_Library_By_Module_Aggregate_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_module_aggregate_fields'
  avg?: Maybe<Learning_Stats_Sessions_By_Library_By_Module_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Sessions_By_Library_By_Module_Max_Fields>
  min?: Maybe<Learning_Stats_Sessions_By_Library_By_Module_Min_Fields>
  stddev?: Maybe<Learning_Stats_Sessions_By_Library_By_Module_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Sessions_By_Library_By_Module_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Sessions_By_Library_By_Module_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Sessions_By_Library_By_Module_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Sessions_By_Library_By_Module_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Sessions_By_Library_By_Module_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Sessions_By_Library_By_Module_Variance_Fields>
}

/** aggregate fields of "learning_stats_sessions_by_library_by_module" */
export type Learning_Stats_Sessions_By_Library_By_Module_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_Module_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
  }

/** aggregate avg on columns */
export type Learning_Stats_Sessions_By_Library_By_Module_Avg_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_module_avg_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_sessions_by_library_by_module". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Sessions_By_Library_By_Module_Bool_Exp = {
  _and?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Library_By_Module_Bool_Exp>
  >
  _not?: InputMaybe<Learning_Stats_Sessions_By_Library_By_Module_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_By_Module_Bool_Exp>>
  failed?: InputMaybe<Bigint_Comparison_Exp>
  finished?: InputMaybe<Bigint_Comparison_Exp>
  library_id?: InputMaybe<String_Comparison_Exp>
  library_type?: InputMaybe<String_Comparison_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  no_pass_mark?: InputMaybe<Bigint_Comparison_Exp>
  passed?: InputMaybe<Bigint_Comparison_Exp>
  sessions?: InputMaybe<Bigint_Comparison_Exp>
}

/** aggregate max on columns */
export type Learning_Stats_Sessions_By_Library_By_Module_Max_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_module_max_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Stats_Sessions_By_Library_By_Module_Min_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_module_min_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** Ordering options when selecting data from "learning_stats_sessions_by_library_by_module". */
export type Learning_Stats_Sessions_By_Library_By_Module_Order_By = {
  failed?: InputMaybe<Order_By>
  finished?: InputMaybe<Order_By>
  library_id?: InputMaybe<Order_By>
  library_type?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  no_pass_mark?: InputMaybe<Order_By>
  passed?: InputMaybe<Order_By>
  sessions?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_sessions_by_library_by_module" */
export enum Learning_Stats_Sessions_By_Library_By_Module_Select_Column {
  /** column name */
  Failed = 'failed',
  /** column name */
  Finished = 'finished',
  /** column name */
  LibraryId = 'library_id',
  /** column name */
  LibraryType = 'library_type',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  NoPassMark = 'no_pass_mark',
  /** column name */
  Passed = 'passed',
  /** column name */
  Sessions = 'sessions',
}

/** aggregate stddev on columns */
export type Learning_Stats_Sessions_By_Library_By_Module_Stddev_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_module_stddev_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Sessions_By_Library_By_Module_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_module_stddev_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Sessions_By_Library_By_Module_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_module_stddev_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_sessions_by_library_by_module" */
export type Learning_Stats_Sessions_By_Library_By_Module_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Sessions_By_Library_By_Module_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Sessions_By_Library_By_Module_Stream_Cursor_Value_Input =
  {
    failed?: InputMaybe<Scalars['bigint']>
    finished?: InputMaybe<Scalars['bigint']>
    library_id?: InputMaybe<Scalars['String']>
    library_type?: InputMaybe<Scalars['String']>
    module_id?: InputMaybe<Scalars['uuid']>
    no_pass_mark?: InputMaybe<Scalars['bigint']>
    passed?: InputMaybe<Scalars['bigint']>
    sessions?: InputMaybe<Scalars['bigint']>
  }

/** aggregate sum on columns */
export type Learning_Stats_Sessions_By_Library_By_Module_Sum_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_module_sum_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Sessions_By_Library_By_Module_Var_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_module_var_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Sessions_By_Library_By_Module_Var_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_module_var_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Sessions_By_Library_By_Module_Variance_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_module_variance_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** columns and relationships of "learning_stats_sessions_by_library_by_user" */
export type Learning_Stats_Sessions_By_Library_By_User = {
  __typename?: 'learning_stats_sessions_by_library_by_user'
  /** An object relationship */
  actor?: Maybe<Users>
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  /** An array relationship */
  library_admins: Array<User_Group_Members_Roles>
  /** An aggregate relationship */
  library_admins_aggregate: User_Group_Members_Roles_Aggregate
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** columns and relationships of "learning_stats_sessions_by_library_by_user" */
export type Learning_Stats_Sessions_By_Library_By_UserLibrary_AdminsArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Roles_Order_By>>
  where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
}

/** columns and relationships of "learning_stats_sessions_by_library_by_user" */
export type Learning_Stats_Sessions_By_Library_By_UserLibrary_Admins_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<User_Group_Members_Roles_Order_By>>
    where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
  }

export type Learning_Stats_Sessions_By_Library_By_User_Aggregate = {
  __typename?: 'learning_stats_sessions_by_library_by_user_aggregate'
  aggregate?: Maybe<Learning_Stats_Sessions_By_Library_By_User_Aggregate_Fields>
  nodes: Array<Learning_Stats_Sessions_By_Library_By_User>
}

/** aggregate fields of "learning_stats_sessions_by_library_by_user" */
export type Learning_Stats_Sessions_By_Library_By_User_Aggregate_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_user_aggregate_fields'
  avg?: Maybe<Learning_Stats_Sessions_By_Library_By_User_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Sessions_By_Library_By_User_Max_Fields>
  min?: Maybe<Learning_Stats_Sessions_By_Library_By_User_Min_Fields>
  stddev?: Maybe<Learning_Stats_Sessions_By_Library_By_User_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Sessions_By_Library_By_User_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Sessions_By_Library_By_User_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Sessions_By_Library_By_User_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Sessions_By_Library_By_User_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Sessions_By_Library_By_User_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Sessions_By_Library_By_User_Variance_Fields>
}

/** aggregate fields of "learning_stats_sessions_by_library_by_user" */
export type Learning_Stats_Sessions_By_Library_By_User_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
  }

/** aggregate avg on columns */
export type Learning_Stats_Sessions_By_Library_By_User_Avg_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_user_avg_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_sessions_by_library_by_user". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Sessions_By_Library_By_User_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>>
  _not?: InputMaybe<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>>
  actor?: InputMaybe<Users_Bool_Exp>
  actor_id?: InputMaybe<Int_Comparison_Exp>
  failed?: InputMaybe<Bigint_Comparison_Exp>
  finished?: InputMaybe<Bigint_Comparison_Exp>
  library_admins?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
  library_admins_aggregate?: InputMaybe<User_Group_Members_Roles_Aggregate_Bool_Exp>
  library_id?: InputMaybe<String_Comparison_Exp>
  library_type?: InputMaybe<String_Comparison_Exp>
  no_pass_mark?: InputMaybe<Bigint_Comparison_Exp>
  passed?: InputMaybe<Bigint_Comparison_Exp>
  sessions?: InputMaybe<Bigint_Comparison_Exp>
}

/** aggregate max on columns */
export type Learning_Stats_Sessions_By_Library_By_User_Max_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_user_max_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Stats_Sessions_By_Library_By_User_Min_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_user_min_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** Ordering options when selecting data from "learning_stats_sessions_by_library_by_user". */
export type Learning_Stats_Sessions_By_Library_By_User_Order_By = {
  actor?: InputMaybe<Users_Order_By>
  actor_id?: InputMaybe<Order_By>
  failed?: InputMaybe<Order_By>
  finished?: InputMaybe<Order_By>
  library_admins_aggregate?: InputMaybe<User_Group_Members_Roles_Aggregate_Order_By>
  library_id?: InputMaybe<Order_By>
  library_type?: InputMaybe<Order_By>
  no_pass_mark?: InputMaybe<Order_By>
  passed?: InputMaybe<Order_By>
  sessions?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_sessions_by_library_by_user" */
export enum Learning_Stats_Sessions_By_Library_By_User_Select_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  Failed = 'failed',
  /** column name */
  Finished = 'finished',
  /** column name */
  LibraryId = 'library_id',
  /** column name */
  LibraryType = 'library_type',
  /** column name */
  NoPassMark = 'no_pass_mark',
  /** column name */
  Passed = 'passed',
  /** column name */
  Sessions = 'sessions',
}

/** aggregate stddev on columns */
export type Learning_Stats_Sessions_By_Library_By_User_Stddev_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_user_stddev_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Sessions_By_Library_By_User_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_user_stddev_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Sessions_By_Library_By_User_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_user_stddev_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_sessions_by_library_by_user" */
export type Learning_Stats_Sessions_By_Library_By_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Sessions_By_Library_By_User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Sessions_By_Library_By_User_Stream_Cursor_Value_Input =
  {
    actor_id?: InputMaybe<Scalars['Int']>
    failed?: InputMaybe<Scalars['bigint']>
    finished?: InputMaybe<Scalars['bigint']>
    library_id?: InputMaybe<Scalars['String']>
    library_type?: InputMaybe<Scalars['String']>
    no_pass_mark?: InputMaybe<Scalars['bigint']>
    passed?: InputMaybe<Scalars['bigint']>
    sessions?: InputMaybe<Scalars['bigint']>
  }

/** aggregate sum on columns */
export type Learning_Stats_Sessions_By_Library_By_User_Sum_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_user_sum_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Sessions_By_Library_By_User_Var_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_user_var_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Sessions_By_Library_By_User_Var_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_user_var_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Sessions_By_Library_By_User_Variance_Fields = {
  __typename?: 'learning_stats_sessions_by_library_by_user_variance_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate max on columns */
export type Learning_Stats_Sessions_By_Library_Max_Fields = {
  __typename?: 'learning_stats_sessions_by_library_max_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Stats_Sessions_By_Library_Min_Fields = {
  __typename?: 'learning_stats_sessions_by_library_min_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** Ordering options when selecting data from "learning_stats_sessions_by_library". */
export type Learning_Stats_Sessions_By_Library_Order_By = {
  failed?: InputMaybe<Order_By>
  finished?: InputMaybe<Order_By>
  library_admins_aggregate?: InputMaybe<User_Group_Members_Roles_Aggregate_Order_By>
  library_group?: InputMaybe<Groups_Order_By>
  library_id?: InputMaybe<Order_By>
  library_type?: InputMaybe<Order_By>
  no_pass_mark?: InputMaybe<Order_By>
  passed?: InputMaybe<Order_By>
  sessions?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_sessions_by_library" */
export enum Learning_Stats_Sessions_By_Library_Select_Column {
  /** column name */
  Failed = 'failed',
  /** column name */
  Finished = 'finished',
  /** column name */
  LibraryId = 'library_id',
  /** column name */
  LibraryType = 'library_type',
  /** column name */
  NoPassMark = 'no_pass_mark',
  /** column name */
  Passed = 'passed',
  /** column name */
  Sessions = 'sessions',
}

/** aggregate stddev on columns */
export type Learning_Stats_Sessions_By_Library_Stddev_Fields = {
  __typename?: 'learning_stats_sessions_by_library_stddev_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Sessions_By_Library_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_library_stddev_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Sessions_By_Library_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_library_stddev_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_sessions_by_library" */
export type Learning_Stats_Sessions_By_Library_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Sessions_By_Library_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Sessions_By_Library_Stream_Cursor_Value_Input = {
  failed?: InputMaybe<Scalars['bigint']>
  finished?: InputMaybe<Scalars['bigint']>
  library_id?: InputMaybe<Scalars['String']>
  library_type?: InputMaybe<Scalars['String']>
  no_pass_mark?: InputMaybe<Scalars['bigint']>
  passed?: InputMaybe<Scalars['bigint']>
  sessions?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Learning_Stats_Sessions_By_Library_Sum_Fields = {
  __typename?: 'learning_stats_sessions_by_library_sum_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Sessions_By_Library_Var_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_library_var_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Sessions_By_Library_Var_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_library_var_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Sessions_By_Library_Variance_Fields = {
  __typename?: 'learning_stats_sessions_by_library_variance_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** columns and relationships of "learning_stats_sessions_by_module" */
export type Learning_Stats_Sessions_By_Module = {
  __typename?: 'learning_stats_sessions_by_module'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  /** An object relationship */
  module?: Maybe<Learning_Modules>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

export type Learning_Stats_Sessions_By_Module_Aggregate = {
  __typename?: 'learning_stats_sessions_by_module_aggregate'
  aggregate?: Maybe<Learning_Stats_Sessions_By_Module_Aggregate_Fields>
  nodes: Array<Learning_Stats_Sessions_By_Module>
}

/** aggregate fields of "learning_stats_sessions_by_module" */
export type Learning_Stats_Sessions_By_Module_Aggregate_Fields = {
  __typename?: 'learning_stats_sessions_by_module_aggregate_fields'
  avg?: Maybe<Learning_Stats_Sessions_By_Module_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Sessions_By_Module_Max_Fields>
  min?: Maybe<Learning_Stats_Sessions_By_Module_Min_Fields>
  stddev?: Maybe<Learning_Stats_Sessions_By_Module_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Sessions_By_Module_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Sessions_By_Module_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Sessions_By_Module_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Sessions_By_Module_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Sessions_By_Module_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Sessions_By_Module_Variance_Fields>
}

/** aggregate fields of "learning_stats_sessions_by_module" */
export type Learning_Stats_Sessions_By_Module_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Learning_Stats_Sessions_By_Module_Avg_Fields = {
  __typename?: 'learning_stats_sessions_by_module_avg_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_sessions_by_module". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Sessions_By_Module_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Bool_Exp>>
  _not?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Bool_Exp>>
  failed?: InputMaybe<Bigint_Comparison_Exp>
  finished?: InputMaybe<Bigint_Comparison_Exp>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  no_pass_mark?: InputMaybe<Bigint_Comparison_Exp>
  passed?: InputMaybe<Bigint_Comparison_Exp>
  sessions?: InputMaybe<Bigint_Comparison_Exp>
}

/** columns and relationships of "learning_stats_sessions_by_module_by_user" */
export type Learning_Stats_Sessions_By_Module_By_User = {
  __typename?: 'learning_stats_sessions_by_module_by_user'
  /** An object relationship */
  actor?: Maybe<Users>
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  /** An object relationship */
  module?: Maybe<Learning_Modules>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

export type Learning_Stats_Sessions_By_Module_By_User_Aggregate = {
  __typename?: 'learning_stats_sessions_by_module_by_user_aggregate'
  aggregate?: Maybe<Learning_Stats_Sessions_By_Module_By_User_Aggregate_Fields>
  nodes: Array<Learning_Stats_Sessions_By_Module_By_User>
}

/** aggregate fields of "learning_stats_sessions_by_module_by_user" */
export type Learning_Stats_Sessions_By_Module_By_User_Aggregate_Fields = {
  __typename?: 'learning_stats_sessions_by_module_by_user_aggregate_fields'
  avg?: Maybe<Learning_Stats_Sessions_By_Module_By_User_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Sessions_By_Module_By_User_Max_Fields>
  min?: Maybe<Learning_Stats_Sessions_By_Module_By_User_Min_Fields>
  stddev?: Maybe<Learning_Stats_Sessions_By_Module_By_User_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Sessions_By_Module_By_User_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Sessions_By_Module_By_User_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Sessions_By_Module_By_User_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Sessions_By_Module_By_User_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Sessions_By_Module_By_User_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Sessions_By_Module_By_User_Variance_Fields>
}

/** aggregate fields of "learning_stats_sessions_by_module_by_user" */
export type Learning_Stats_Sessions_By_Module_By_User_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
  }

/** aggregate avg on columns */
export type Learning_Stats_Sessions_By_Module_By_User_Avg_Fields = {
  __typename?: 'learning_stats_sessions_by_module_by_user_avg_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_sessions_by_module_by_user". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Sessions_By_Module_By_User_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>>
  _not?: InputMaybe<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>>
  actor?: InputMaybe<Users_Bool_Exp>
  actor_id?: InputMaybe<Int_Comparison_Exp>
  failed?: InputMaybe<Bigint_Comparison_Exp>
  finished?: InputMaybe<Bigint_Comparison_Exp>
  module?: InputMaybe<Learning_Modules_Bool_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  no_pass_mark?: InputMaybe<Bigint_Comparison_Exp>
  passed?: InputMaybe<Bigint_Comparison_Exp>
  sessions?: InputMaybe<Bigint_Comparison_Exp>
}

/** aggregate max on columns */
export type Learning_Stats_Sessions_By_Module_By_User_Max_Fields = {
  __typename?: 'learning_stats_sessions_by_module_by_user_max_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Stats_Sessions_By_Module_By_User_Min_Fields = {
  __typename?: 'learning_stats_sessions_by_module_by_user_min_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** Ordering options when selecting data from "learning_stats_sessions_by_module_by_user". */
export type Learning_Stats_Sessions_By_Module_By_User_Order_By = {
  actor?: InputMaybe<Users_Order_By>
  actor_id?: InputMaybe<Order_By>
  failed?: InputMaybe<Order_By>
  finished?: InputMaybe<Order_By>
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  no_pass_mark?: InputMaybe<Order_By>
  passed?: InputMaybe<Order_By>
  sessions?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_sessions_by_module_by_user" */
export enum Learning_Stats_Sessions_By_Module_By_User_Select_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  Failed = 'failed',
  /** column name */
  Finished = 'finished',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  NoPassMark = 'no_pass_mark',
  /** column name */
  Passed = 'passed',
  /** column name */
  Sessions = 'sessions',
}

/** aggregate stddev on columns */
export type Learning_Stats_Sessions_By_Module_By_User_Stddev_Fields = {
  __typename?: 'learning_stats_sessions_by_module_by_user_stddev_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Sessions_By_Module_By_User_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_module_by_user_stddev_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Sessions_By_Module_By_User_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_module_by_user_stddev_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_sessions_by_module_by_user" */
export type Learning_Stats_Sessions_By_Module_By_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Sessions_By_Module_By_User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Sessions_By_Module_By_User_Stream_Cursor_Value_Input =
  {
    actor_id?: InputMaybe<Scalars['Int']>
    failed?: InputMaybe<Scalars['bigint']>
    finished?: InputMaybe<Scalars['bigint']>
    module_id?: InputMaybe<Scalars['uuid']>
    no_pass_mark?: InputMaybe<Scalars['bigint']>
    passed?: InputMaybe<Scalars['bigint']>
    sessions?: InputMaybe<Scalars['bigint']>
  }

/** aggregate sum on columns */
export type Learning_Stats_Sessions_By_Module_By_User_Sum_Fields = {
  __typename?: 'learning_stats_sessions_by_module_by_user_sum_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Sessions_By_Module_By_User_Var_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_module_by_user_var_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Sessions_By_Module_By_User_Var_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_module_by_user_var_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Sessions_By_Module_By_User_Variance_Fields = {
  __typename?: 'learning_stats_sessions_by_module_by_user_variance_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** input type for inserting data into table "learning_stats_sessions_by_module" */
export type Learning_Stats_Sessions_By_Module_Insert_Input = {
  failed?: InputMaybe<Scalars['bigint']>
  finished?: InputMaybe<Scalars['bigint']>
  module?: InputMaybe<Learning_Modules_Obj_Rel_Insert_Input>
  module_id?: InputMaybe<Scalars['uuid']>
  no_pass_mark?: InputMaybe<Scalars['bigint']>
  passed?: InputMaybe<Scalars['bigint']>
  sessions?: InputMaybe<Scalars['bigint']>
}

/** aggregate max on columns */
export type Learning_Stats_Sessions_By_Module_Max_Fields = {
  __typename?: 'learning_stats_sessions_by_module_max_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Stats_Sessions_By_Module_Min_Fields = {
  __typename?: 'learning_stats_sessions_by_module_min_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** input type for inserting object relation for remote table "learning_stats_sessions_by_module" */
export type Learning_Stats_Sessions_By_Module_Obj_Rel_Insert_Input = {
  data: Learning_Stats_Sessions_By_Module_Insert_Input
}

/** Ordering options when selecting data from "learning_stats_sessions_by_module". */
export type Learning_Stats_Sessions_By_Module_Order_By = {
  failed?: InputMaybe<Order_By>
  finished?: InputMaybe<Order_By>
  module?: InputMaybe<Learning_Modules_Order_By>
  module_id?: InputMaybe<Order_By>
  no_pass_mark?: InputMaybe<Order_By>
  passed?: InputMaybe<Order_By>
  sessions?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_sessions_by_module" */
export enum Learning_Stats_Sessions_By_Module_Select_Column {
  /** column name */
  Failed = 'failed',
  /** column name */
  Finished = 'finished',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  NoPassMark = 'no_pass_mark',
  /** column name */
  Passed = 'passed',
  /** column name */
  Sessions = 'sessions',
}

/** aggregate stddev on columns */
export type Learning_Stats_Sessions_By_Module_Stddev_Fields = {
  __typename?: 'learning_stats_sessions_by_module_stddev_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Sessions_By_Module_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_module_stddev_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Sessions_By_Module_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_module_stddev_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_sessions_by_module" */
export type Learning_Stats_Sessions_By_Module_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Sessions_By_Module_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Sessions_By_Module_Stream_Cursor_Value_Input = {
  failed?: InputMaybe<Scalars['bigint']>
  finished?: InputMaybe<Scalars['bigint']>
  module_id?: InputMaybe<Scalars['uuid']>
  no_pass_mark?: InputMaybe<Scalars['bigint']>
  passed?: InputMaybe<Scalars['bigint']>
  sessions?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Learning_Stats_Sessions_By_Module_Sum_Fields = {
  __typename?: 'learning_stats_sessions_by_module_sum_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Sessions_By_Module_Var_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_module_var_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Sessions_By_Module_Var_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_module_var_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Sessions_By_Module_Variance_Fields = {
  __typename?: 'learning_stats_sessions_by_module_variance_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** columns and relationships of "learning_stats_sessions_by_period_by_library" */
export type Learning_Stats_Sessions_By_Period_By_Library = {
  __typename?: 'learning_stats_sessions_by_period_by_library'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  /** An object relationship */
  library_group?: Maybe<Groups>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
  time?: Maybe<Scalars['timestamptz']>
}

export type Learning_Stats_Sessions_By_Period_By_Library_Aggregate = {
  __typename?: 'learning_stats_sessions_by_period_by_library_aggregate'
  aggregate?: Maybe<Learning_Stats_Sessions_By_Period_By_Library_Aggregate_Fields>
  nodes: Array<Learning_Stats_Sessions_By_Period_By_Library>
}

/** aggregate fields of "learning_stats_sessions_by_period_by_library" */
export type Learning_Stats_Sessions_By_Period_By_Library_Aggregate_Fields = {
  __typename?: 'learning_stats_sessions_by_period_by_library_aggregate_fields'
  avg?: Maybe<Learning_Stats_Sessions_By_Period_By_Library_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Sessions_By_Period_By_Library_Max_Fields>
  min?: Maybe<Learning_Stats_Sessions_By_Period_By_Library_Min_Fields>
  stddev?: Maybe<Learning_Stats_Sessions_By_Period_By_Library_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Sessions_By_Period_By_Library_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Sessions_By_Period_By_Library_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Sessions_By_Period_By_Library_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Sessions_By_Period_By_Library_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Sessions_By_Period_By_Library_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Sessions_By_Period_By_Library_Variance_Fields>
}

/** aggregate fields of "learning_stats_sessions_by_period_by_library" */
export type Learning_Stats_Sessions_By_Period_By_Library_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
  }

/** aggregate avg on columns */
export type Learning_Stats_Sessions_By_Period_By_Library_Avg_Fields = {
  __typename?: 'learning_stats_sessions_by_period_by_library_avg_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_sessions_by_period_by_library". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp = {
  _and?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>
  >
  _not?: InputMaybe<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>>
  failed?: InputMaybe<Bigint_Comparison_Exp>
  finished?: InputMaybe<Bigint_Comparison_Exp>
  library_group?: InputMaybe<Groups_Bool_Exp>
  library_id?: InputMaybe<String_Comparison_Exp>
  library_type?: InputMaybe<String_Comparison_Exp>
  no_pass_mark?: InputMaybe<Bigint_Comparison_Exp>
  passed?: InputMaybe<Bigint_Comparison_Exp>
  sessions?: InputMaybe<Bigint_Comparison_Exp>
  time?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** aggregate max on columns */
export type Learning_Stats_Sessions_By_Period_By_Library_Max_Fields = {
  __typename?: 'learning_stats_sessions_by_period_by_library_max_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
  time?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Learning_Stats_Sessions_By_Period_By_Library_Min_Fields = {
  __typename?: 'learning_stats_sessions_by_period_by_library_min_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
  time?: Maybe<Scalars['timestamptz']>
}

/** Ordering options when selecting data from "learning_stats_sessions_by_period_by_library". */
export type Learning_Stats_Sessions_By_Period_By_Library_Order_By = {
  failed?: InputMaybe<Order_By>
  finished?: InputMaybe<Order_By>
  library_group?: InputMaybe<Groups_Order_By>
  library_id?: InputMaybe<Order_By>
  library_type?: InputMaybe<Order_By>
  no_pass_mark?: InputMaybe<Order_By>
  passed?: InputMaybe<Order_By>
  sessions?: InputMaybe<Order_By>
  time?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_sessions_by_period_by_library" */
export enum Learning_Stats_Sessions_By_Period_By_Library_Select_Column {
  /** column name */
  Failed = 'failed',
  /** column name */
  Finished = 'finished',
  /** column name */
  LibraryId = 'library_id',
  /** column name */
  LibraryType = 'library_type',
  /** column name */
  NoPassMark = 'no_pass_mark',
  /** column name */
  Passed = 'passed',
  /** column name */
  Sessions = 'sessions',
  /** column name */
  Time = 'time',
}

/** aggregate stddev on columns */
export type Learning_Stats_Sessions_By_Period_By_Library_Stddev_Fields = {
  __typename?: 'learning_stats_sessions_by_period_by_library_stddev_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Sessions_By_Period_By_Library_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_period_by_library_stddev_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Sessions_By_Period_By_Library_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_period_by_library_stddev_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_sessions_by_period_by_library" */
export type Learning_Stats_Sessions_By_Period_By_Library_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Sessions_By_Period_By_Library_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Sessions_By_Period_By_Library_Stream_Cursor_Value_Input =
  {
    failed?: InputMaybe<Scalars['bigint']>
    finished?: InputMaybe<Scalars['bigint']>
    library_id?: InputMaybe<Scalars['String']>
    library_type?: InputMaybe<Scalars['String']>
    no_pass_mark?: InputMaybe<Scalars['bigint']>
    passed?: InputMaybe<Scalars['bigint']>
    sessions?: InputMaybe<Scalars['bigint']>
    time?: InputMaybe<Scalars['timestamptz']>
  }

/** aggregate sum on columns */
export type Learning_Stats_Sessions_By_Period_By_Library_Sum_Fields = {
  __typename?: 'learning_stats_sessions_by_period_by_library_sum_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Sessions_By_Period_By_Library_Var_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_period_by_library_var_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Sessions_By_Period_By_Library_Var_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_period_by_library_var_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Sessions_By_Period_By_Library_Variance_Fields = {
  __typename?: 'learning_stats_sessions_by_period_by_library_variance_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** columns and relationships of "learning_stats_sessions_by_series" */
export type Learning_Stats_Sessions_By_Series = {
  __typename?: 'learning_stats_sessions_by_series'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  /** An object relationship */
  series?: Maybe<Learning_Series>
  series_id?: Maybe<Scalars['uuid']>
  sessions?: Maybe<Scalars['bigint']>
}

export type Learning_Stats_Sessions_By_Series_Aggregate = {
  __typename?: 'learning_stats_sessions_by_series_aggregate'
  aggregate?: Maybe<Learning_Stats_Sessions_By_Series_Aggregate_Fields>
  nodes: Array<Learning_Stats_Sessions_By_Series>
}

/** aggregate fields of "learning_stats_sessions_by_series" */
export type Learning_Stats_Sessions_By_Series_Aggregate_Fields = {
  __typename?: 'learning_stats_sessions_by_series_aggregate_fields'
  avg?: Maybe<Learning_Stats_Sessions_By_Series_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Sessions_By_Series_Max_Fields>
  min?: Maybe<Learning_Stats_Sessions_By_Series_Min_Fields>
  stddev?: Maybe<Learning_Stats_Sessions_By_Series_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Sessions_By_Series_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Sessions_By_Series_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Sessions_By_Series_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Sessions_By_Series_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Sessions_By_Series_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Sessions_By_Series_Variance_Fields>
}

/** aggregate fields of "learning_stats_sessions_by_series" */
export type Learning_Stats_Sessions_By_Series_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Learning_Stats_Sessions_By_Series_Avg_Fields = {
  __typename?: 'learning_stats_sessions_by_series_avg_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_sessions_by_series". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Sessions_By_Series_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_Bool_Exp>>
  _not?: InputMaybe<Learning_Stats_Sessions_By_Series_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_Bool_Exp>>
  failed?: InputMaybe<Bigint_Comparison_Exp>
  finished?: InputMaybe<Bigint_Comparison_Exp>
  no_pass_mark?: InputMaybe<Bigint_Comparison_Exp>
  passed?: InputMaybe<Bigint_Comparison_Exp>
  series?: InputMaybe<Learning_Series_Bool_Exp>
  series_id?: InputMaybe<Uuid_Comparison_Exp>
  sessions?: InputMaybe<Bigint_Comparison_Exp>
}

/** columns and relationships of "learning_stats_sessions_by_series_by_module" */
export type Learning_Stats_Sessions_By_Series_By_Module = {
  __typename?: 'learning_stats_sessions_by_series_by_module'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  series_id?: Maybe<Scalars['uuid']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregated selection of "learning_stats_sessions_by_series_by_module" */
export type Learning_Stats_Sessions_By_Series_By_Module_Aggregate = {
  __typename?: 'learning_stats_sessions_by_series_by_module_aggregate'
  aggregate?: Maybe<Learning_Stats_Sessions_By_Series_By_Module_Aggregate_Fields>
  nodes: Array<Learning_Stats_Sessions_By_Series_By_Module>
}

/** aggregate fields of "learning_stats_sessions_by_series_by_module" */
export type Learning_Stats_Sessions_By_Series_By_Module_Aggregate_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_module_aggregate_fields'
  avg?: Maybe<Learning_Stats_Sessions_By_Series_By_Module_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Sessions_By_Series_By_Module_Max_Fields>
  min?: Maybe<Learning_Stats_Sessions_By_Series_By_Module_Min_Fields>
  stddev?: Maybe<Learning_Stats_Sessions_By_Series_By_Module_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Sessions_By_Series_By_Module_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Sessions_By_Series_By_Module_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Sessions_By_Series_By_Module_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Sessions_By_Series_By_Module_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Sessions_By_Series_By_Module_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Sessions_By_Series_By_Module_Variance_Fields>
}

/** aggregate fields of "learning_stats_sessions_by_series_by_module" */
export type Learning_Stats_Sessions_By_Series_By_Module_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_Module_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
  }

/** aggregate avg on columns */
export type Learning_Stats_Sessions_By_Series_By_Module_Avg_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_module_avg_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_sessions_by_series_by_module". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Sessions_By_Series_By_Module_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_By_Module_Bool_Exp>>
  _not?: InputMaybe<Learning_Stats_Sessions_By_Series_By_Module_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_By_Module_Bool_Exp>>
  failed?: InputMaybe<Bigint_Comparison_Exp>
  finished?: InputMaybe<Bigint_Comparison_Exp>
  module_id?: InputMaybe<Uuid_Comparison_Exp>
  no_pass_mark?: InputMaybe<Bigint_Comparison_Exp>
  passed?: InputMaybe<Bigint_Comparison_Exp>
  series_id?: InputMaybe<Uuid_Comparison_Exp>
  sessions?: InputMaybe<Bigint_Comparison_Exp>
}

/** aggregate max on columns */
export type Learning_Stats_Sessions_By_Series_By_Module_Max_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_module_max_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  series_id?: Maybe<Scalars['uuid']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Stats_Sessions_By_Series_By_Module_Min_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_module_min_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  module_id?: Maybe<Scalars['uuid']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  series_id?: Maybe<Scalars['uuid']>
  sessions?: Maybe<Scalars['bigint']>
}

/** Ordering options when selecting data from "learning_stats_sessions_by_series_by_module". */
export type Learning_Stats_Sessions_By_Series_By_Module_Order_By = {
  failed?: InputMaybe<Order_By>
  finished?: InputMaybe<Order_By>
  module_id?: InputMaybe<Order_By>
  no_pass_mark?: InputMaybe<Order_By>
  passed?: InputMaybe<Order_By>
  series_id?: InputMaybe<Order_By>
  sessions?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_sessions_by_series_by_module" */
export enum Learning_Stats_Sessions_By_Series_By_Module_Select_Column {
  /** column name */
  Failed = 'failed',
  /** column name */
  Finished = 'finished',
  /** column name */
  ModuleId = 'module_id',
  /** column name */
  NoPassMark = 'no_pass_mark',
  /** column name */
  Passed = 'passed',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  Sessions = 'sessions',
}

/** aggregate stddev on columns */
export type Learning_Stats_Sessions_By_Series_By_Module_Stddev_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_module_stddev_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Sessions_By_Series_By_Module_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_module_stddev_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Sessions_By_Series_By_Module_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_module_stddev_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_sessions_by_series_by_module" */
export type Learning_Stats_Sessions_By_Series_By_Module_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Sessions_By_Series_By_Module_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Sessions_By_Series_By_Module_Stream_Cursor_Value_Input =
  {
    failed?: InputMaybe<Scalars['bigint']>
    finished?: InputMaybe<Scalars['bigint']>
    module_id?: InputMaybe<Scalars['uuid']>
    no_pass_mark?: InputMaybe<Scalars['bigint']>
    passed?: InputMaybe<Scalars['bigint']>
    series_id?: InputMaybe<Scalars['uuid']>
    sessions?: InputMaybe<Scalars['bigint']>
  }

/** aggregate sum on columns */
export type Learning_Stats_Sessions_By_Series_By_Module_Sum_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_module_sum_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Sessions_By_Series_By_Module_Var_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_module_var_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Sessions_By_Series_By_Module_Var_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_module_var_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Sessions_By_Series_By_Module_Variance_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_module_variance_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** columns and relationships of "learning_stats_sessions_by_series_by_user" */
export type Learning_Stats_Sessions_By_Series_By_User = {
  __typename?: 'learning_stats_sessions_by_series_by_user'
  /** An object relationship */
  actor?: Maybe<Users>
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  /** An object relationship */
  series?: Maybe<Learning_Series>
  series_id?: Maybe<Scalars['uuid']>
  sessions?: Maybe<Scalars['bigint']>
}

export type Learning_Stats_Sessions_By_Series_By_User_Aggregate = {
  __typename?: 'learning_stats_sessions_by_series_by_user_aggregate'
  aggregate?: Maybe<Learning_Stats_Sessions_By_Series_By_User_Aggregate_Fields>
  nodes: Array<Learning_Stats_Sessions_By_Series_By_User>
}

/** aggregate fields of "learning_stats_sessions_by_series_by_user" */
export type Learning_Stats_Sessions_By_Series_By_User_Aggregate_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_user_aggregate_fields'
  avg?: Maybe<Learning_Stats_Sessions_By_Series_By_User_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Sessions_By_Series_By_User_Max_Fields>
  min?: Maybe<Learning_Stats_Sessions_By_Series_By_User_Min_Fields>
  stddev?: Maybe<Learning_Stats_Sessions_By_Series_By_User_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Sessions_By_Series_By_User_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Sessions_By_Series_By_User_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Sessions_By_Series_By_User_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Sessions_By_Series_By_User_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Sessions_By_Series_By_User_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Sessions_By_Series_By_User_Variance_Fields>
}

/** aggregate fields of "learning_stats_sessions_by_series_by_user" */
export type Learning_Stats_Sessions_By_Series_By_User_Aggregate_FieldsCountArgs =
  {
    columns?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Select_Column>
    >
    distinct?: InputMaybe<Scalars['Boolean']>
  }

/** aggregate avg on columns */
export type Learning_Stats_Sessions_By_Series_By_User_Avg_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_user_avg_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_sessions_by_series_by_user". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Sessions_By_Series_By_User_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>>
  _not?: InputMaybe<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>>
  actor?: InputMaybe<Users_Bool_Exp>
  actor_id?: InputMaybe<Int_Comparison_Exp>
  failed?: InputMaybe<Bigint_Comparison_Exp>
  finished?: InputMaybe<Bigint_Comparison_Exp>
  no_pass_mark?: InputMaybe<Bigint_Comparison_Exp>
  passed?: InputMaybe<Bigint_Comparison_Exp>
  series?: InputMaybe<Learning_Series_Bool_Exp>
  series_id?: InputMaybe<Uuid_Comparison_Exp>
  sessions?: InputMaybe<Bigint_Comparison_Exp>
}

/** aggregate max on columns */
export type Learning_Stats_Sessions_By_Series_By_User_Max_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_user_max_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  series_id?: Maybe<Scalars['uuid']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Stats_Sessions_By_Series_By_User_Min_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_user_min_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  series_id?: Maybe<Scalars['uuid']>
  sessions?: Maybe<Scalars['bigint']>
}

/** Ordering options when selecting data from "learning_stats_sessions_by_series_by_user". */
export type Learning_Stats_Sessions_By_Series_By_User_Order_By = {
  actor?: InputMaybe<Users_Order_By>
  actor_id?: InputMaybe<Order_By>
  failed?: InputMaybe<Order_By>
  finished?: InputMaybe<Order_By>
  no_pass_mark?: InputMaybe<Order_By>
  passed?: InputMaybe<Order_By>
  series?: InputMaybe<Learning_Series_Order_By>
  series_id?: InputMaybe<Order_By>
  sessions?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_sessions_by_series_by_user" */
export enum Learning_Stats_Sessions_By_Series_By_User_Select_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  Failed = 'failed',
  /** column name */
  Finished = 'finished',
  /** column name */
  NoPassMark = 'no_pass_mark',
  /** column name */
  Passed = 'passed',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  Sessions = 'sessions',
}

/** aggregate stddev on columns */
export type Learning_Stats_Sessions_By_Series_By_User_Stddev_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_user_stddev_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Sessions_By_Series_By_User_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_user_stddev_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Sessions_By_Series_By_User_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_user_stddev_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_sessions_by_series_by_user" */
export type Learning_Stats_Sessions_By_Series_By_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Sessions_By_Series_By_User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Sessions_By_Series_By_User_Stream_Cursor_Value_Input =
  {
    actor_id?: InputMaybe<Scalars['Int']>
    failed?: InputMaybe<Scalars['bigint']>
    finished?: InputMaybe<Scalars['bigint']>
    no_pass_mark?: InputMaybe<Scalars['bigint']>
    passed?: InputMaybe<Scalars['bigint']>
    series_id?: InputMaybe<Scalars['uuid']>
    sessions?: InputMaybe<Scalars['bigint']>
  }

/** aggregate sum on columns */
export type Learning_Stats_Sessions_By_Series_By_User_Sum_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_user_sum_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Sessions_By_Series_By_User_Var_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_user_var_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Sessions_By_Series_By_User_Var_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_user_var_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Sessions_By_Series_By_User_Variance_Fields = {
  __typename?: 'learning_stats_sessions_by_series_by_user_variance_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate max on columns */
export type Learning_Stats_Sessions_By_Series_Max_Fields = {
  __typename?: 'learning_stats_sessions_by_series_max_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  series_id?: Maybe<Scalars['uuid']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Stats_Sessions_By_Series_Min_Fields = {
  __typename?: 'learning_stats_sessions_by_series_min_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  series_id?: Maybe<Scalars['uuid']>
  sessions?: Maybe<Scalars['bigint']>
}

/** Ordering options when selecting data from "learning_stats_sessions_by_series". */
export type Learning_Stats_Sessions_By_Series_Order_By = {
  failed?: InputMaybe<Order_By>
  finished?: InputMaybe<Order_By>
  no_pass_mark?: InputMaybe<Order_By>
  passed?: InputMaybe<Order_By>
  series?: InputMaybe<Learning_Series_Order_By>
  series_id?: InputMaybe<Order_By>
  sessions?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_sessions_by_series" */
export enum Learning_Stats_Sessions_By_Series_Select_Column {
  /** column name */
  Failed = 'failed',
  /** column name */
  Finished = 'finished',
  /** column name */
  NoPassMark = 'no_pass_mark',
  /** column name */
  Passed = 'passed',
  /** column name */
  SeriesId = 'series_id',
  /** column name */
  Sessions = 'sessions',
}

/** aggregate stddev on columns */
export type Learning_Stats_Sessions_By_Series_Stddev_Fields = {
  __typename?: 'learning_stats_sessions_by_series_stddev_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Sessions_By_Series_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_series_stddev_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Sessions_By_Series_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_series_stddev_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_sessions_by_series" */
export type Learning_Stats_Sessions_By_Series_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Sessions_By_Series_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Sessions_By_Series_Stream_Cursor_Value_Input = {
  failed?: InputMaybe<Scalars['bigint']>
  finished?: InputMaybe<Scalars['bigint']>
  no_pass_mark?: InputMaybe<Scalars['bigint']>
  passed?: InputMaybe<Scalars['bigint']>
  series_id?: InputMaybe<Scalars['uuid']>
  sessions?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Learning_Stats_Sessions_By_Series_Sum_Fields = {
  __typename?: 'learning_stats_sessions_by_series_sum_fields'
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Sessions_By_Series_Var_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_series_var_pop_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Sessions_By_Series_Var_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_series_var_samp_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Sessions_By_Series_Variance_Fields = {
  __typename?: 'learning_stats_sessions_by_series_variance_fields'
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** columns and relationships of "learning_stats_sessions_by_user" */
export type Learning_Stats_Sessions_By_User = {
  __typename?: 'learning_stats_sessions_by_user'
  /** An object relationship */
  actor?: Maybe<Users>
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

export type Learning_Stats_Sessions_By_User_Aggregate = {
  __typename?: 'learning_stats_sessions_by_user_aggregate'
  aggregate?: Maybe<Learning_Stats_Sessions_By_User_Aggregate_Fields>
  nodes: Array<Learning_Stats_Sessions_By_User>
}

/** aggregate fields of "learning_stats_sessions_by_user" */
export type Learning_Stats_Sessions_By_User_Aggregate_Fields = {
  __typename?: 'learning_stats_sessions_by_user_aggregate_fields'
  avg?: Maybe<Learning_Stats_Sessions_By_User_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Stats_Sessions_By_User_Max_Fields>
  min?: Maybe<Learning_Stats_Sessions_By_User_Min_Fields>
  stddev?: Maybe<Learning_Stats_Sessions_By_User_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Stats_Sessions_By_User_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Stats_Sessions_By_User_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Stats_Sessions_By_User_Sum_Fields>
  var_pop?: Maybe<Learning_Stats_Sessions_By_User_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Stats_Sessions_By_User_Var_Samp_Fields>
  variance?: Maybe<Learning_Stats_Sessions_By_User_Variance_Fields>
}

/** aggregate fields of "learning_stats_sessions_by_user" */
export type Learning_Stats_Sessions_By_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Learning_Stats_Sessions_By_User_Avg_Fields = {
  __typename?: 'learning_stats_sessions_by_user_avg_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_stats_sessions_by_user". All fields are combined with a logical 'AND'. */
export type Learning_Stats_Sessions_By_User_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Bool_Exp>>
  _not?: InputMaybe<Learning_Stats_Sessions_By_User_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Bool_Exp>>
  actor?: InputMaybe<Users_Bool_Exp>
  actor_id?: InputMaybe<Int_Comparison_Exp>
  failed?: InputMaybe<Bigint_Comparison_Exp>
  finished?: InputMaybe<Bigint_Comparison_Exp>
  no_pass_mark?: InputMaybe<Bigint_Comparison_Exp>
  passed?: InputMaybe<Bigint_Comparison_Exp>
  sessions?: InputMaybe<Bigint_Comparison_Exp>
}

/** aggregate max on columns */
export type Learning_Stats_Sessions_By_User_Max_Fields = {
  __typename?: 'learning_stats_sessions_by_user_max_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Learning_Stats_Sessions_By_User_Min_Fields = {
  __typename?: 'learning_stats_sessions_by_user_min_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** Ordering options when selecting data from "learning_stats_sessions_by_user". */
export type Learning_Stats_Sessions_By_User_Order_By = {
  actor?: InputMaybe<Users_Order_By>
  actor_id?: InputMaybe<Order_By>
  failed?: InputMaybe<Order_By>
  finished?: InputMaybe<Order_By>
  no_pass_mark?: InputMaybe<Order_By>
  passed?: InputMaybe<Order_By>
  sessions?: InputMaybe<Order_By>
}

/** select columns of table "learning_stats_sessions_by_user" */
export enum Learning_Stats_Sessions_By_User_Select_Column {
  /** column name */
  ActorId = 'actor_id',
  /** column name */
  Failed = 'failed',
  /** column name */
  Finished = 'finished',
  /** column name */
  NoPassMark = 'no_pass_mark',
  /** column name */
  Passed = 'passed',
  /** column name */
  Sessions = 'sessions',
}

/** aggregate stddev on columns */
export type Learning_Stats_Sessions_By_User_Stddev_Fields = {
  __typename?: 'learning_stats_sessions_by_user_stddev_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Stats_Sessions_By_User_Stddev_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_user_stddev_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Stats_Sessions_By_User_Stddev_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_user_stddev_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_stats_sessions_by_user" */
export type Learning_Stats_Sessions_By_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Stats_Sessions_By_User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Stats_Sessions_By_User_Stream_Cursor_Value_Input = {
  actor_id?: InputMaybe<Scalars['Int']>
  failed?: InputMaybe<Scalars['bigint']>
  finished?: InputMaybe<Scalars['bigint']>
  no_pass_mark?: InputMaybe<Scalars['bigint']>
  passed?: InputMaybe<Scalars['bigint']>
  sessions?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Learning_Stats_Sessions_By_User_Sum_Fields = {
  __typename?: 'learning_stats_sessions_by_user_sum_fields'
  actor_id?: Maybe<Scalars['Int']>
  failed?: Maybe<Scalars['bigint']>
  finished?: Maybe<Scalars['bigint']>
  no_pass_mark?: Maybe<Scalars['bigint']>
  passed?: Maybe<Scalars['bigint']>
  sessions?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Learning_Stats_Sessions_By_User_Var_Pop_Fields = {
  __typename?: 'learning_stats_sessions_by_user_var_pop_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Stats_Sessions_By_User_Var_Samp_Fields = {
  __typename?: 'learning_stats_sessions_by_user_var_samp_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Stats_Sessions_By_User_Variance_Fields = {
  __typename?: 'learning_stats_sessions_by_user_variance_fields'
  actor_id?: Maybe<Scalars['Float']>
  failed?: Maybe<Scalars['Float']>
  finished?: Maybe<Scalars['Float']>
  no_pass_mark?: Maybe<Scalars['Float']>
  passed?: Maybe<Scalars['Float']>
  sessions?: Maybe<Scalars['Float']>
}

/** columns and relationships of "learning_uploads" */
export type Learning_Uploads = {
  __typename?: 'learning_uploads'
  alt_text?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  content_type: Scalars['String']
  created_at: Scalars['timestamptz']
  created_by: Scalars['Int']
  /** An object relationship */
  creator: Users
  description?: Maybe<Scalars['String']>
  file: Scalars['String']
  id: Scalars['Int']
  library_id: Scalars['String']
  library_type: Scalars['String']
  manifest?: Maybe<Scalars['jsonb']>
  manifestPath?: Maybe<Scalars['String']>
  name: Scalars['String']
  path: Scalars['String']
  /** An array relationship */
  series: Array<Learning_Series>
  /** An aggregate relationship */
  series_aggregate: Learning_Series_Aggregate
  updated_at: Scalars['timestamptz']
  updated_by: Scalars['Int']
  /** An object relationship */
  updater: Users
  url?: Maybe<Scalars['String']>
}

/** columns and relationships of "learning_uploads" */
export type Learning_UploadsManifestArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "learning_uploads" */
export type Learning_UploadsSeriesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Order_By>>
  where?: InputMaybe<Learning_Series_Bool_Exp>
}

/** columns and relationships of "learning_uploads" */
export type Learning_UploadsSeries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Order_By>>
  where?: InputMaybe<Learning_Series_Bool_Exp>
}

/** aggregated selection of "learning_uploads" */
export type Learning_Uploads_Aggregate = {
  __typename?: 'learning_uploads_aggregate'
  aggregate?: Maybe<Learning_Uploads_Aggregate_Fields>
  nodes: Array<Learning_Uploads>
}

/** aggregate fields of "learning_uploads" */
export type Learning_Uploads_Aggregate_Fields = {
  __typename?: 'learning_uploads_aggregate_fields'
  avg?: Maybe<Learning_Uploads_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Learning_Uploads_Max_Fields>
  min?: Maybe<Learning_Uploads_Min_Fields>
  stddev?: Maybe<Learning_Uploads_Stddev_Fields>
  stddev_pop?: Maybe<Learning_Uploads_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Learning_Uploads_Stddev_Samp_Fields>
  sum?: Maybe<Learning_Uploads_Sum_Fields>
  var_pop?: Maybe<Learning_Uploads_Var_Pop_Fields>
  var_samp?: Maybe<Learning_Uploads_Var_Samp_Fields>
  variance?: Maybe<Learning_Uploads_Variance_Fields>
}

/** aggregate fields of "learning_uploads" */
export type Learning_Uploads_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Learning_Uploads_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Learning_Uploads_Append_Input = {
  manifest?: InputMaybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Learning_Uploads_Avg_Fields = {
  __typename?: 'learning_uploads_avg_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "learning_uploads". All fields are combined with a logical 'AND'. */
export type Learning_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Learning_Uploads_Bool_Exp>>
  _not?: InputMaybe<Learning_Uploads_Bool_Exp>
  _or?: InputMaybe<Array<Learning_Uploads_Bool_Exp>>
  alt_text?: InputMaybe<String_Comparison_Exp>
  caption?: InputMaybe<String_Comparison_Exp>
  content_type?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  creator?: InputMaybe<Users_Bool_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  file?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Int_Comparison_Exp>
  library_id?: InputMaybe<String_Comparison_Exp>
  library_type?: InputMaybe<String_Comparison_Exp>
  manifest?: InputMaybe<Jsonb_Comparison_Exp>
  manifestPath?: InputMaybe<String_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  path?: InputMaybe<String_Comparison_Exp>
  series?: InputMaybe<Learning_Series_Bool_Exp>
  series_aggregate?: InputMaybe<Learning_Series_Aggregate_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
  updater?: InputMaybe<Users_Bool_Exp>
  url?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "learning_uploads" */
export enum Learning_Uploads_Constraint {
  /** unique or primary key constraint on columns "id" */
  LearningUploadsPkey1 = 'learning_uploads_pkey1',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Learning_Uploads_Delete_At_Path_Input = {
  manifest?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Learning_Uploads_Delete_Elem_Input = {
  manifest?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Learning_Uploads_Delete_Key_Input = {
  manifest?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "learning_uploads" */
export type Learning_Uploads_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "learning_uploads" */
export type Learning_Uploads_Insert_Input = {
  alt_text?: InputMaybe<Scalars['String']>
  caption?: InputMaybe<Scalars['String']>
  content_type?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>
  description?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  library_id?: InputMaybe<Scalars['String']>
  library_type?: InputMaybe<Scalars['String']>
  manifest?: InputMaybe<Scalars['jsonb']>
  manifestPath?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
  series?: InputMaybe<Learning_Series_Arr_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  updater?: InputMaybe<Users_Obj_Rel_Insert_Input>
  url?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Learning_Uploads_Max_Fields = {
  __typename?: 'learning_uploads_max_fields'
  alt_text?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  content_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  file?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  manifestPath?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
  url?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Learning_Uploads_Min_Fields = {
  __typename?: 'learning_uploads_min_fields'
  alt_text?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  content_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  file?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  library_id?: Maybe<Scalars['String']>
  library_type?: Maybe<Scalars['String']>
  manifestPath?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
  url?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "learning_uploads" */
export type Learning_Uploads_Mutation_Response = {
  __typename?: 'learning_uploads_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Learning_Uploads>
}

/** input type for inserting object relation for remote table "learning_uploads" */
export type Learning_Uploads_Obj_Rel_Insert_Input = {
  data: Learning_Uploads_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Learning_Uploads_On_Conflict>
}

/** on_conflict condition type for table "learning_uploads" */
export type Learning_Uploads_On_Conflict = {
  constraint: Learning_Uploads_Constraint
  update_columns?: Array<Learning_Uploads_Update_Column>
  where?: InputMaybe<Learning_Uploads_Bool_Exp>
}

/** Ordering options when selecting data from "learning_uploads". */
export type Learning_Uploads_Order_By = {
  alt_text?: InputMaybe<Order_By>
  caption?: InputMaybe<Order_By>
  content_type?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  creator?: InputMaybe<Users_Order_By>
  description?: InputMaybe<Order_By>
  file?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  library_id?: InputMaybe<Order_By>
  library_type?: InputMaybe<Order_By>
  manifest?: InputMaybe<Order_By>
  manifestPath?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  path?: InputMaybe<Order_By>
  series_aggregate?: InputMaybe<Learning_Series_Aggregate_Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  updater?: InputMaybe<Users_Order_By>
  url?: InputMaybe<Order_By>
}

/** primary key columns input for table: learning_uploads */
export type Learning_Uploads_Pk_Columns_Input = {
  id: Scalars['Int']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Learning_Uploads_Prepend_Input = {
  manifest?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "learning_uploads" */
export enum Learning_Uploads_Select_Column {
  /** column name */
  AltText = 'alt_text',
  /** column name */
  Caption = 'caption',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  LibraryId = 'library_id',
  /** column name */
  LibraryType = 'library_type',
  /** column name */
  Manifest = 'manifest',
  /** column name */
  ManifestPath = 'manifestPath',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "learning_uploads" */
export type Learning_Uploads_Set_Input = {
  alt_text?: InputMaybe<Scalars['String']>
  caption?: InputMaybe<Scalars['String']>
  content_type?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  library_id?: InputMaybe<Scalars['String']>
  library_type?: InputMaybe<Scalars['String']>
  manifest?: InputMaybe<Scalars['jsonb']>
  manifestPath?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  url?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Learning_Uploads_Stddev_Fields = {
  __typename?: 'learning_uploads_stddev_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Learning_Uploads_Stddev_Pop_Fields = {
  __typename?: 'learning_uploads_stddev_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Learning_Uploads_Stddev_Samp_Fields = {
  __typename?: 'learning_uploads_stddev_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "learning_uploads" */
export type Learning_Uploads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Learning_Uploads_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Learning_Uploads_Stream_Cursor_Value_Input = {
  alt_text?: InputMaybe<Scalars['String']>
  caption?: InputMaybe<Scalars['String']>
  content_type?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  library_id?: InputMaybe<Scalars['String']>
  library_type?: InputMaybe<Scalars['String']>
  manifest?: InputMaybe<Scalars['jsonb']>
  manifestPath?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  url?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Learning_Uploads_Sum_Fields = {
  __typename?: 'learning_uploads_sum_fields'
  created_by?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  updated_by?: Maybe<Scalars['Int']>
}

/** update columns of table "learning_uploads" */
export enum Learning_Uploads_Update_Column {
  /** column name */
  AltText = 'alt_text',
  /** column name */
  Caption = 'caption',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  LibraryId = 'library_id',
  /** column name */
  LibraryType = 'library_type',
  /** column name */
  Manifest = 'manifest',
  /** column name */
  ManifestPath = 'manifestPath',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Url = 'url',
}

export type Learning_Uploads_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Learning_Uploads_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Learning_Uploads_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Learning_Uploads_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Learning_Uploads_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Learning_Uploads_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Learning_Uploads_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Learning_Uploads_Set_Input>
  /** filter the rows which have to be updated */
  where: Learning_Uploads_Bool_Exp
}

/** aggregate var_pop on columns */
export type Learning_Uploads_Var_Pop_Fields = {
  __typename?: 'learning_uploads_var_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Learning_Uploads_Var_Samp_Fields = {
  __typename?: 'learning_uploads_var_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Learning_Uploads_Variance_Fields = {
  __typename?: 'learning_uploads_variance_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  /** delete single row from the table: "learning_pages" */
  delete_by_pk_learning_page?: Maybe<Learning_Pages>
  /** delete single row from the table: "learning_modules" */
  delete_by_pk_module?: Maybe<Learning_Modules>
  /** delete single row from the table: "learning_series" */
  delete_by_pk_series?: Maybe<Learning_Series>
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<Categories_Mutation_Response>
  /** delete single row from the table: "categories" */
  delete_categories_by_pk?: Maybe<Categories>
  /** delete data from the table: "form_responses" */
  delete_form_responses?: Maybe<Form_Responses_Mutation_Response>
  /** delete single row from the table: "form_responses" */
  delete_form_responses_by_pk?: Maybe<Form_Responses>
  /** delete data from the table: "forms" */
  delete_forms?: Maybe<Forms_Mutation_Response>
  /** delete single row from the table: "forms" */
  delete_forms_by_pk?: Maybe<Forms>
  /** delete data from the table: "groups" */
  delete_groups?: Maybe<Groups_Mutation_Response>
  /** delete single row from the table: "groups" */
  delete_groups_by_pk?: Maybe<Groups>
  /** delete data from the table: "learning_featured_modules" */
  delete_learning_featured_modules?: Maybe<Learning_Featured_Modules_Mutation_Response>
  /** delete single row from the table: "learning_featured_modules" */
  delete_learning_featured_modules_by_pk?: Maybe<Learning_Featured_Modules>
  /** delete data from the table: "learning_learner_event_log" */
  delete_learning_learner_event_log?: Maybe<Learning_Learner_Event_Log_Mutation_Response>
  /** delete single row from the table: "learning_learner_event_log" */
  delete_learning_learner_event_log_by_pk?: Maybe<Learning_Learner_Event_Log>
  /** delete data from the table: "learning_learner_module_rating_questions" */
  delete_learning_learner_module_rating_questions?: Maybe<Learning_Learner_Module_Rating_Questions_Mutation_Response>
  /** delete single row from the table: "learning_learner_module_rating_questions" */
  delete_learning_learner_module_rating_questions_by_pk?: Maybe<Learning_Learner_Module_Rating_Questions>
  /** delete data from the table: "learning_learner_module_rating_responses" */
  delete_learning_learner_module_rating_responses?: Maybe<Learning_Learner_Module_Rating_Responses_Mutation_Response>
  /** delete single row from the table: "learning_learner_module_rating_responses" */
  delete_learning_learner_module_rating_responses_by_pk?: Maybe<Learning_Learner_Module_Rating_Responses>
  /** delete data from the table: "learning_learner_module_ratings" */
  delete_learning_learner_module_ratings?: Maybe<Learning_Learner_Module_Ratings_Mutation_Response>
  /** delete single row from the table: "learning_learner_module_ratings" */
  delete_learning_learner_module_ratings_by_pk?: Maybe<Learning_Learner_Module_Ratings>
  /** delete data from the table: "learning_learner_module_star_ratings" */
  delete_learning_learner_module_star_ratings?: Maybe<Learning_Learner_Module_Star_Ratings_Mutation_Response>
  /** delete single row from the table: "learning_learner_module_star_ratings" */
  delete_learning_learner_module_star_ratings_by_pk?: Maybe<Learning_Learner_Module_Star_Ratings>
  /** delete data from the table: "learning_learner_reflections" */
  delete_learning_learner_reflections?: Maybe<Learning_Learner_Reflections_Mutation_Response>
  /** delete single row from the table: "learning_learner_reflections" */
  delete_learning_learner_reflections_by_pk?: Maybe<Learning_Learner_Reflections>
  /** delete data from the table: "learning_learner_sessions" */
  delete_learning_learner_sessions?: Maybe<Learning_Learner_Sessions_Mutation_Response>
  /** delete single row from the table: "learning_learner_sessions" */
  delete_learning_learner_sessions_by_pk?: Maybe<Learning_Learner_Sessions>
  /** delete data from the table: "learning_module_event_log" */
  delete_learning_module_event_log?: Maybe<Learning_Module_Event_Log_Mutation_Response>
  /** delete single row from the table: "learning_module_event_log" */
  delete_learning_module_event_log_by_pk?: Maybe<Learning_Module_Event_Log>
  /** delete data from the table: "learning_module_event_transitions" */
  delete_learning_module_event_transitions?: Maybe<Learning_Module_Event_Transitions_Mutation_Response>
  /** delete single row from the table: "learning_module_event_transitions" */
  delete_learning_module_event_transitions_by_pk?: Maybe<Learning_Module_Event_Transitions>
  /** delete data from the table: "learning_module_exists" */
  delete_learning_module_exists?: Maybe<Learning_Module_Exists_Mutation_Response>
  /** delete data from the table: "learning_module_history" */
  delete_learning_module_history?: Maybe<Learning_Module_History_Mutation_Response>
  /** delete single row from the table: "learning_module_history" */
  delete_learning_module_history_by_pk?: Maybe<Learning_Module_History>
  /** delete data from the table: "learning_module_revision_pages" */
  delete_learning_module_revision_pages?: Maybe<Learning_Module_Revision_Pages_Mutation_Response>
  /** delete single row from the table: "learning_module_revision_pages" */
  delete_learning_module_revision_pages_by_pk?: Maybe<Learning_Module_Revision_Pages>
  /** delete data from the table: "learning_module_revisions" */
  delete_learning_module_revisions?: Maybe<Learning_Module_Revisions_Mutation_Response>
  /** delete single row from the table: "learning_module_revisions" */
  delete_learning_module_revisions_by_pk?: Maybe<Learning_Module_Revisions>
  /** delete data from the table: "learning_pages" */
  delete_learning_pages?: Maybe<Learning_Pages_Mutation_Response>
  /** delete data from the table: "learning_questions" */
  delete_learning_questions?: Maybe<Learning_Questions_Mutation_Response>
  /** delete single row from the table: "learning_questions" */
  delete_learning_questions_by_pk?: Maybe<Learning_Questions>
  /** delete data from the table: "learning_series_discount_groups" */
  delete_learning_series_discount_groups?: Maybe<Learning_Series_Discount_Groups_Mutation_Response>
  /** delete single row from the table: "learning_series_discount_groups" */
  delete_learning_series_discount_groups_by_pk?: Maybe<Learning_Series_Discount_Groups>
  /** delete data from the table: "learning_series_discount_settings" */
  delete_learning_series_discount_settings?: Maybe<Learning_Series_Discount_Settings_Mutation_Response>
  /** delete single row from the table: "learning_series_discount_settings" */
  delete_learning_series_discount_settings_by_pk?: Maybe<Learning_Series_Discount_Settings>
  /** delete data from the table: "learning_series_subscription_settings" */
  delete_learning_series_subscription_settings?: Maybe<Learning_Series_Subscription_Settings_Mutation_Response>
  /** delete single row from the table: "learning_series_subscription_settings" */
  delete_learning_series_subscription_settings_by_pk?: Maybe<Learning_Series_Subscription_Settings>
  /** delete data from the table: "learning_series_subscription_settings_discount_type" */
  delete_learning_series_subscription_settings_discount_type?: Maybe<Learning_Series_Subscription_Settings_Discount_Type_Mutation_Response>
  /** delete single row from the table: "learning_series_subscription_settings_discount_type" */
  delete_learning_series_subscription_settings_discount_type_by_pk?: Maybe<Learning_Series_Subscription_Settings_Discount_Type>
  /** delete data from the table: "learning_uploads" */
  delete_learning_uploads?: Maybe<Learning_Uploads_Mutation_Response>
  /** delete single row from the table: "learning_uploads" */
  delete_learning_uploads_by_pk?: Maybe<Learning_Uploads>
  /** delete data from the table: "learning_modules" */
  delete_modules?: Maybe<Learning_Modules_Mutation_Response>
  /** delete data from the table: "page_categories" */
  delete_page_categories?: Maybe<Page_Categories_Mutation_Response>
  /** delete single row from the table: "page_categories" */
  delete_page_categories_by_pk?: Maybe<Page_Categories>
  /** delete data from the table: "pages" */
  delete_pages?: Maybe<Pages_Mutation_Response>
  /** delete single row from the table: "pages" */
  delete_pages_by_pk?: Maybe<Pages>
  /** delete data from the table: "post_categories" */
  delete_post_categories?: Maybe<Post_Categories_Mutation_Response>
  /** delete single row from the table: "post_categories" */
  delete_post_categories_by_pk?: Maybe<Post_Categories>
  /** delete data from the table: "post_comments" */
  delete_post_comments?: Maybe<Post_Comments_Mutation_Response>
  /** delete single row from the table: "post_comments" */
  delete_post_comments_by_pk?: Maybe<Post_Comments>
  /** delete data from the table: "posts" */
  delete_posts?: Maybe<Posts_Mutation_Response>
  /** delete single row from the table: "posts" */
  delete_posts_by_pk?: Maybe<Posts>
  /** delete data from the table: "resources" */
  delete_resources?: Maybe<Resources_Mutation_Response>
  /** delete single row from the table: "resources" */
  delete_resources_by_pk?: Maybe<Resources>
  /** delete data from the table: "segment_user" */
  delete_segment_user?: Maybe<Segment_User_Mutation_Response>
  /** delete single row from the table: "segment_user" */
  delete_segment_user_by_pk?: Maybe<Segment_User>
  /** delete data from the table: "learning_series" */
  delete_series?: Maybe<Learning_Series_Mutation_Response>
  /** delete data from the table: "slugs" */
  delete_slugs?: Maybe<Slugs_Mutation_Response>
  /** delete single row from the table: "slugs" */
  delete_slugs_by_pk?: Maybe<Slugs>
  /** delete data from the table: "user_group_members" */
  delete_user_group_members?: Maybe<User_Group_Members_Mutation_Response>
  /** delete single row from the table: "user_group_members" */
  delete_user_group_members_by_pk?: Maybe<User_Group_Members>
  /** delete data from the table: "user_group_members_roles" */
  delete_user_group_members_roles?: Maybe<User_Group_Members_Roles_Mutation_Response>
  /** delete single row from the table: "user_group_members_roles" */
  delete_user_group_members_roles_by_pk?: Maybe<User_Group_Members_Roles>
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>
  /** insert data into the table: "form_responses" */
  insert_form_responses?: Maybe<Form_Responses_Mutation_Response>
  /** insert a single row into the table: "form_responses" */
  insert_form_responses_one?: Maybe<Form_Responses>
  /** insert data into the table: "forms" */
  insert_forms?: Maybe<Forms_Mutation_Response>
  /** insert a single row into the table: "forms" */
  insert_forms_one?: Maybe<Forms>
  /** insert data into the table: "groups" */
  insert_groups?: Maybe<Groups_Mutation_Response>
  /** insert a single row into the table: "groups" */
  insert_groups_one?: Maybe<Groups>
  /** insert data into the table: "learning_featured_modules" */
  insert_learning_featured_modules?: Maybe<Learning_Featured_Modules_Mutation_Response>
  /** insert a single row into the table: "learning_featured_modules" */
  insert_learning_featured_modules_one?: Maybe<Learning_Featured_Modules>
  /** insert data into the table: "learning_learner_event_log" */
  insert_learning_learner_event_log?: Maybe<Learning_Learner_Event_Log_Mutation_Response>
  /** insert a single row into the table: "learning_learner_event_log" */
  insert_learning_learner_event_log_one?: Maybe<Learning_Learner_Event_Log>
  /** insert data into the table: "learning_learner_module_rating_questions" */
  insert_learning_learner_module_rating_questions?: Maybe<Learning_Learner_Module_Rating_Questions_Mutation_Response>
  /** insert a single row into the table: "learning_learner_module_rating_questions" */
  insert_learning_learner_module_rating_questions_one?: Maybe<Learning_Learner_Module_Rating_Questions>
  /** insert data into the table: "learning_learner_module_rating_responses" */
  insert_learning_learner_module_rating_responses?: Maybe<Learning_Learner_Module_Rating_Responses_Mutation_Response>
  /** insert a single row into the table: "learning_learner_module_rating_responses" */
  insert_learning_learner_module_rating_responses_one?: Maybe<Learning_Learner_Module_Rating_Responses>
  /** insert data into the table: "learning_learner_module_ratings" */
  insert_learning_learner_module_ratings?: Maybe<Learning_Learner_Module_Ratings_Mutation_Response>
  /** insert a single row into the table: "learning_learner_module_ratings" */
  insert_learning_learner_module_ratings_one?: Maybe<Learning_Learner_Module_Ratings>
  /** insert data into the table: "learning_learner_module_star_ratings" */
  insert_learning_learner_module_star_ratings?: Maybe<Learning_Learner_Module_Star_Ratings_Mutation_Response>
  /** insert a single row into the table: "learning_learner_module_star_ratings" */
  insert_learning_learner_module_star_ratings_one?: Maybe<Learning_Learner_Module_Star_Ratings>
  /** insert data into the table: "learning_learner_reflections" */
  insert_learning_learner_reflections?: Maybe<Learning_Learner_Reflections_Mutation_Response>
  /** insert a single row into the table: "learning_learner_reflections" */
  insert_learning_learner_reflections_one?: Maybe<Learning_Learner_Reflections>
  /** insert data into the table: "learning_learner_sessions" */
  insert_learning_learner_sessions?: Maybe<Learning_Learner_Sessions_Mutation_Response>
  /** insert a single row into the table: "learning_learner_sessions" */
  insert_learning_learner_sessions_one?: Maybe<Learning_Learner_Sessions>
  /** insert data into the table: "learning_module_event_log" */
  insert_learning_module_event_log?: Maybe<Learning_Module_Event_Log_Mutation_Response>
  /** insert a single row into the table: "learning_module_event_log" */
  insert_learning_module_event_log_one?: Maybe<Learning_Module_Event_Log>
  /** insert data into the table: "learning_module_event_transitions" */
  insert_learning_module_event_transitions?: Maybe<Learning_Module_Event_Transitions_Mutation_Response>
  /** insert a single row into the table: "learning_module_event_transitions" */
  insert_learning_module_event_transitions_one?: Maybe<Learning_Module_Event_Transitions>
  /** insert data into the table: "learning_module_exists" */
  insert_learning_module_exists?: Maybe<Learning_Module_Exists_Mutation_Response>
  /** insert a single row into the table: "learning_module_exists" */
  insert_learning_module_exists_one?: Maybe<Learning_Module_Exists>
  /** insert data into the table: "learning_module_history" */
  insert_learning_module_history?: Maybe<Learning_Module_History_Mutation_Response>
  /** insert a single row into the table: "learning_module_history" */
  insert_learning_module_history_one?: Maybe<Learning_Module_History>
  /** insert data into the table: "learning_module_revision_pages" */
  insert_learning_module_revision_pages?: Maybe<Learning_Module_Revision_Pages_Mutation_Response>
  /** insert a single row into the table: "learning_module_revision_pages" */
  insert_learning_module_revision_pages_one?: Maybe<Learning_Module_Revision_Pages>
  /** insert data into the table: "learning_module_revisions" */
  insert_learning_module_revisions?: Maybe<Learning_Module_Revisions_Mutation_Response>
  /** insert a single row into the table: "learning_module_revisions" */
  insert_learning_module_revisions_one?: Maybe<Learning_Module_Revisions>
  /** insert a single row into the table: "learning_pages" */
  insert_learning_page?: Maybe<Learning_Pages>
  /** insert data into the table: "learning_pages" */
  insert_learning_pages?: Maybe<Learning_Pages_Mutation_Response>
  /** insert data into the table: "learning_questions" */
  insert_learning_questions?: Maybe<Learning_Questions_Mutation_Response>
  /** insert a single row into the table: "learning_questions" */
  insert_learning_questions_one?: Maybe<Learning_Questions>
  /** insert data into the table: "learning_series_discount_groups" */
  insert_learning_series_discount_groups?: Maybe<Learning_Series_Discount_Groups_Mutation_Response>
  /** insert a single row into the table: "learning_series_discount_groups" */
  insert_learning_series_discount_groups_one?: Maybe<Learning_Series_Discount_Groups>
  /** insert data into the table: "learning_series_discount_settings" */
  insert_learning_series_discount_settings?: Maybe<Learning_Series_Discount_Settings_Mutation_Response>
  /** insert a single row into the table: "learning_series_discount_settings" */
  insert_learning_series_discount_settings_one?: Maybe<Learning_Series_Discount_Settings>
  /** insert data into the table: "learning_series_subscription_settings" */
  insert_learning_series_subscription_settings?: Maybe<Learning_Series_Subscription_Settings_Mutation_Response>
  /** insert data into the table: "learning_series_subscription_settings_discount_type" */
  insert_learning_series_subscription_settings_discount_type?: Maybe<Learning_Series_Subscription_Settings_Discount_Type_Mutation_Response>
  /** insert a single row into the table: "learning_series_subscription_settings_discount_type" */
  insert_learning_series_subscription_settings_discount_type_one?: Maybe<Learning_Series_Subscription_Settings_Discount_Type>
  /** insert a single row into the table: "learning_series_subscription_settings" */
  insert_learning_series_subscription_settings_one?: Maybe<Learning_Series_Subscription_Settings>
  /** insert data into the table: "learning_uploads" */
  insert_learning_uploads?: Maybe<Learning_Uploads_Mutation_Response>
  /** insert a single row into the table: "learning_uploads" */
  insert_learning_uploads_one?: Maybe<Learning_Uploads>
  /** insert a single row into the table: "learning_modules" */
  insert_module?: Maybe<Learning_Modules>
  /** insert data into the table: "learning_modules" */
  insert_modules?: Maybe<Learning_Modules_Mutation_Response>
  /** insert a single row into the table: "learning_series" */
  insert_one_series?: Maybe<Learning_Series>
  /** insert data into the table: "page_categories" */
  insert_page_categories?: Maybe<Page_Categories_Mutation_Response>
  /** insert a single row into the table: "page_categories" */
  insert_page_categories_one?: Maybe<Page_Categories>
  /** insert data into the table: "pages" */
  insert_pages?: Maybe<Pages_Mutation_Response>
  /** insert a single row into the table: "pages" */
  insert_pages_one?: Maybe<Pages>
  /** insert data into the table: "post_categories" */
  insert_post_categories?: Maybe<Post_Categories_Mutation_Response>
  /** insert a single row into the table: "post_categories" */
  insert_post_categories_one?: Maybe<Post_Categories>
  /** insert data into the table: "post_comments" */
  insert_post_comments?: Maybe<Post_Comments_Mutation_Response>
  /** insert a single row into the table: "post_comments" */
  insert_post_comments_one?: Maybe<Post_Comments>
  /** insert data into the table: "posts" */
  insert_posts?: Maybe<Posts_Mutation_Response>
  /** insert a single row into the table: "posts" */
  insert_posts_one?: Maybe<Posts>
  /** insert data into the table: "resources" */
  insert_resources?: Maybe<Resources_Mutation_Response>
  /** insert a single row into the table: "resources" */
  insert_resources_one?: Maybe<Resources>
  /** insert data into the table: "segment_user" */
  insert_segment_user?: Maybe<Segment_User_Mutation_Response>
  /** insert a single row into the table: "segment_user" */
  insert_segment_user_one?: Maybe<Segment_User>
  /** insert data into the table: "learning_series" */
  insert_series?: Maybe<Learning_Series_Mutation_Response>
  /** insert data into the table: "slugs" */
  insert_slugs?: Maybe<Slugs_Mutation_Response>
  /** insert a single row into the table: "slugs" */
  insert_slugs_one?: Maybe<Slugs>
  /** insert data into the table: "user_group_members" */
  insert_user_group_members?: Maybe<User_Group_Members_Mutation_Response>
  /** insert a single row into the table: "user_group_members" */
  insert_user_group_members_one?: Maybe<User_Group_Members>
  /** insert data into the table: "user_group_members_roles" */
  insert_user_group_members_roles?: Maybe<User_Group_Members_Roles_Mutation_Response>
  /** insert a single row into the table: "user_group_members_roles" */
  insert_user_group_members_roles_one?: Maybe<User_Group_Members_Roles>
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>
  /** update single row of the table: "learning_pages" */
  update_by_pk_learning_page?: Maybe<Learning_Pages>
  /** update single row of the table: "learning_modules" */
  update_by_pk_module?: Maybe<Learning_Modules>
  /** update single row of the table: "learning_series" */
  update_by_pk_series?: Maybe<Learning_Series>
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>
  /** update multiples rows of table: "categories" */
  update_categories_many?: Maybe<Array<Maybe<Categories_Mutation_Response>>>
  /** update data of the table: "form_responses" */
  update_form_responses?: Maybe<Form_Responses_Mutation_Response>
  /** update single row of the table: "form_responses" */
  update_form_responses_by_pk?: Maybe<Form_Responses>
  /** update multiples rows of table: "form_responses" */
  update_form_responses_many?: Maybe<
    Array<Maybe<Form_Responses_Mutation_Response>>
  >
  /** update data of the table: "forms" */
  update_forms?: Maybe<Forms_Mutation_Response>
  /** update single row of the table: "forms" */
  update_forms_by_pk?: Maybe<Forms>
  /** update multiples rows of table: "forms" */
  update_forms_many?: Maybe<Array<Maybe<Forms_Mutation_Response>>>
  /** update data of the table: "groups" */
  update_groups?: Maybe<Groups_Mutation_Response>
  /** update single row of the table: "groups" */
  update_groups_by_pk?: Maybe<Groups>
  /** update multiples rows of table: "groups" */
  update_groups_many?: Maybe<Array<Maybe<Groups_Mutation_Response>>>
  /** update data of the table: "learning_featured_modules" */
  update_learning_featured_modules?: Maybe<Learning_Featured_Modules_Mutation_Response>
  /** update single row of the table: "learning_featured_modules" */
  update_learning_featured_modules_by_pk?: Maybe<Learning_Featured_Modules>
  /** update multiples rows of table: "learning_featured_modules" */
  update_learning_featured_modules_many?: Maybe<
    Array<Maybe<Learning_Featured_Modules_Mutation_Response>>
  >
  /** update data of the table: "learning_learner_event_log" */
  update_learning_learner_event_log?: Maybe<Learning_Learner_Event_Log_Mutation_Response>
  /** update single row of the table: "learning_learner_event_log" */
  update_learning_learner_event_log_by_pk?: Maybe<Learning_Learner_Event_Log>
  /** update multiples rows of table: "learning_learner_event_log" */
  update_learning_learner_event_log_many?: Maybe<
    Array<Maybe<Learning_Learner_Event_Log_Mutation_Response>>
  >
  /** update data of the table: "learning_learner_module_rating_questions" */
  update_learning_learner_module_rating_questions?: Maybe<Learning_Learner_Module_Rating_Questions_Mutation_Response>
  /** update single row of the table: "learning_learner_module_rating_questions" */
  update_learning_learner_module_rating_questions_by_pk?: Maybe<Learning_Learner_Module_Rating_Questions>
  /** update multiples rows of table: "learning_learner_module_rating_questions" */
  update_learning_learner_module_rating_questions_many?: Maybe<
    Array<Maybe<Learning_Learner_Module_Rating_Questions_Mutation_Response>>
  >
  /** update data of the table: "learning_learner_module_rating_responses" */
  update_learning_learner_module_rating_responses?: Maybe<Learning_Learner_Module_Rating_Responses_Mutation_Response>
  /** update single row of the table: "learning_learner_module_rating_responses" */
  update_learning_learner_module_rating_responses_by_pk?: Maybe<Learning_Learner_Module_Rating_Responses>
  /** update multiples rows of table: "learning_learner_module_rating_responses" */
  update_learning_learner_module_rating_responses_many?: Maybe<
    Array<Maybe<Learning_Learner_Module_Rating_Responses_Mutation_Response>>
  >
  /** update data of the table: "learning_learner_module_ratings" */
  update_learning_learner_module_ratings?: Maybe<Learning_Learner_Module_Ratings_Mutation_Response>
  /** update single row of the table: "learning_learner_module_ratings" */
  update_learning_learner_module_ratings_by_pk?: Maybe<Learning_Learner_Module_Ratings>
  /** update multiples rows of table: "learning_learner_module_ratings" */
  update_learning_learner_module_ratings_many?: Maybe<
    Array<Maybe<Learning_Learner_Module_Ratings_Mutation_Response>>
  >
  /** update data of the table: "learning_learner_module_star_ratings" */
  update_learning_learner_module_star_ratings?: Maybe<Learning_Learner_Module_Star_Ratings_Mutation_Response>
  /** update single row of the table: "learning_learner_module_star_ratings" */
  update_learning_learner_module_star_ratings_by_pk?: Maybe<Learning_Learner_Module_Star_Ratings>
  /** update multiples rows of table: "learning_learner_module_star_ratings" */
  update_learning_learner_module_star_ratings_many?: Maybe<
    Array<Maybe<Learning_Learner_Module_Star_Ratings_Mutation_Response>>
  >
  /** update data of the table: "learning_learner_reflections" */
  update_learning_learner_reflections?: Maybe<Learning_Learner_Reflections_Mutation_Response>
  /** update single row of the table: "learning_learner_reflections" */
  update_learning_learner_reflections_by_pk?: Maybe<Learning_Learner_Reflections>
  /** update multiples rows of table: "learning_learner_reflections" */
  update_learning_learner_reflections_many?: Maybe<
    Array<Maybe<Learning_Learner_Reflections_Mutation_Response>>
  >
  /** update data of the table: "learning_learner_sessions" */
  update_learning_learner_sessions?: Maybe<Learning_Learner_Sessions_Mutation_Response>
  /** update single row of the table: "learning_learner_sessions" */
  update_learning_learner_sessions_by_pk?: Maybe<Learning_Learner_Sessions>
  /** update multiples rows of table: "learning_learner_sessions" */
  update_learning_learner_sessions_many?: Maybe<
    Array<Maybe<Learning_Learner_Sessions_Mutation_Response>>
  >
  /** update data of the table: "learning_module_event_log" */
  update_learning_module_event_log?: Maybe<Learning_Module_Event_Log_Mutation_Response>
  /** update single row of the table: "learning_module_event_log" */
  update_learning_module_event_log_by_pk?: Maybe<Learning_Module_Event_Log>
  /** update multiples rows of table: "learning_module_event_log" */
  update_learning_module_event_log_many?: Maybe<
    Array<Maybe<Learning_Module_Event_Log_Mutation_Response>>
  >
  /** update data of the table: "learning_module_event_transitions" */
  update_learning_module_event_transitions?: Maybe<Learning_Module_Event_Transitions_Mutation_Response>
  /** update single row of the table: "learning_module_event_transitions" */
  update_learning_module_event_transitions_by_pk?: Maybe<Learning_Module_Event_Transitions>
  /** update multiples rows of table: "learning_module_event_transitions" */
  update_learning_module_event_transitions_many?: Maybe<
    Array<Maybe<Learning_Module_Event_Transitions_Mutation_Response>>
  >
  /** update data of the table: "learning_module_exists" */
  update_learning_module_exists?: Maybe<Learning_Module_Exists_Mutation_Response>
  /** update multiples rows of table: "learning_module_exists" */
  update_learning_module_exists_many?: Maybe<
    Array<Maybe<Learning_Module_Exists_Mutation_Response>>
  >
  /** update data of the table: "learning_module_history" */
  update_learning_module_history?: Maybe<Learning_Module_History_Mutation_Response>
  /** update single row of the table: "learning_module_history" */
  update_learning_module_history_by_pk?: Maybe<Learning_Module_History>
  /** update multiples rows of table: "learning_module_history" */
  update_learning_module_history_many?: Maybe<
    Array<Maybe<Learning_Module_History_Mutation_Response>>
  >
  /** update data of the table: "learning_module_revision_pages" */
  update_learning_module_revision_pages?: Maybe<Learning_Module_Revision_Pages_Mutation_Response>
  /** update single row of the table: "learning_module_revision_pages" */
  update_learning_module_revision_pages_by_pk?: Maybe<Learning_Module_Revision_Pages>
  /** update multiples rows of table: "learning_module_revision_pages" */
  update_learning_module_revision_pages_many?: Maybe<
    Array<Maybe<Learning_Module_Revision_Pages_Mutation_Response>>
  >
  /** update data of the table: "learning_module_revisions" */
  update_learning_module_revisions?: Maybe<Learning_Module_Revisions_Mutation_Response>
  /** update single row of the table: "learning_module_revisions" */
  update_learning_module_revisions_by_pk?: Maybe<Learning_Module_Revisions>
  /** update multiples rows of table: "learning_module_revisions" */
  update_learning_module_revisions_many?: Maybe<
    Array<Maybe<Learning_Module_Revisions_Mutation_Response>>
  >
  /** update multiples rows of table: "learning_modules" */
  update_learning_modules_many?: Maybe<
    Array<Maybe<Learning_Modules_Mutation_Response>>
  >
  /** update data of the table: "learning_pages" */
  update_learning_pages?: Maybe<Learning_Pages_Mutation_Response>
  /** update multiples rows of table: "learning_pages" */
  update_learning_pages_many?: Maybe<
    Array<Maybe<Learning_Pages_Mutation_Response>>
  >
  /** update data of the table: "learning_questions" */
  update_learning_questions?: Maybe<Learning_Questions_Mutation_Response>
  /** update single row of the table: "learning_questions" */
  update_learning_questions_by_pk?: Maybe<Learning_Questions>
  /** update multiples rows of table: "learning_questions" */
  update_learning_questions_many?: Maybe<
    Array<Maybe<Learning_Questions_Mutation_Response>>
  >
  /** update data of the table: "learning_series_discount_groups" */
  update_learning_series_discount_groups?: Maybe<Learning_Series_Discount_Groups_Mutation_Response>
  /** update single row of the table: "learning_series_discount_groups" */
  update_learning_series_discount_groups_by_pk?: Maybe<Learning_Series_Discount_Groups>
  /** update multiples rows of table: "learning_series_discount_groups" */
  update_learning_series_discount_groups_many?: Maybe<
    Array<Maybe<Learning_Series_Discount_Groups_Mutation_Response>>
  >
  /** update data of the table: "learning_series_discount_settings" */
  update_learning_series_discount_settings?: Maybe<Learning_Series_Discount_Settings_Mutation_Response>
  /** update single row of the table: "learning_series_discount_settings" */
  update_learning_series_discount_settings_by_pk?: Maybe<Learning_Series_Discount_Settings>
  /** update multiples rows of table: "learning_series_discount_settings" */
  update_learning_series_discount_settings_many?: Maybe<
    Array<Maybe<Learning_Series_Discount_Settings_Mutation_Response>>
  >
  /** update multiples rows of table: "learning_series" */
  update_learning_series_many?: Maybe<
    Array<Maybe<Learning_Series_Mutation_Response>>
  >
  /** update data of the table: "learning_series_subscription_settings" */
  update_learning_series_subscription_settings?: Maybe<Learning_Series_Subscription_Settings_Mutation_Response>
  /** update single row of the table: "learning_series_subscription_settings" */
  update_learning_series_subscription_settings_by_pk?: Maybe<Learning_Series_Subscription_Settings>
  /** update data of the table: "learning_series_subscription_settings_discount_type" */
  update_learning_series_subscription_settings_discount_type?: Maybe<Learning_Series_Subscription_Settings_Discount_Type_Mutation_Response>
  /** update single row of the table: "learning_series_subscription_settings_discount_type" */
  update_learning_series_subscription_settings_discount_type_by_pk?: Maybe<Learning_Series_Subscription_Settings_Discount_Type>
  /** update multiples rows of table: "learning_series_subscription_settings_discount_type" */
  update_learning_series_subscription_settings_discount_type_many?: Maybe<
    Array<
      Maybe<Learning_Series_Subscription_Settings_Discount_Type_Mutation_Response>
    >
  >
  /** update multiples rows of table: "learning_series_subscription_settings" */
  update_learning_series_subscription_settings_many?: Maybe<
    Array<Maybe<Learning_Series_Subscription_Settings_Mutation_Response>>
  >
  /** update data of the table: "learning_uploads" */
  update_learning_uploads?: Maybe<Learning_Uploads_Mutation_Response>
  /** update single row of the table: "learning_uploads" */
  update_learning_uploads_by_pk?: Maybe<Learning_Uploads>
  /** update multiples rows of table: "learning_uploads" */
  update_learning_uploads_many?: Maybe<
    Array<Maybe<Learning_Uploads_Mutation_Response>>
  >
  /** update data of the table: "learning_modules" */
  update_modules?: Maybe<Learning_Modules_Mutation_Response>
  /** update data of the table: "page_categories" */
  update_page_categories?: Maybe<Page_Categories_Mutation_Response>
  /** update single row of the table: "page_categories" */
  update_page_categories_by_pk?: Maybe<Page_Categories>
  /** update multiples rows of table: "page_categories" */
  update_page_categories_many?: Maybe<
    Array<Maybe<Page_Categories_Mutation_Response>>
  >
  /** update data of the table: "pages" */
  update_pages?: Maybe<Pages_Mutation_Response>
  /** update single row of the table: "pages" */
  update_pages_by_pk?: Maybe<Pages>
  /** update multiples rows of table: "pages" */
  update_pages_many?: Maybe<Array<Maybe<Pages_Mutation_Response>>>
  /** update data of the table: "post_categories" */
  update_post_categories?: Maybe<Post_Categories_Mutation_Response>
  /** update single row of the table: "post_categories" */
  update_post_categories_by_pk?: Maybe<Post_Categories>
  /** update multiples rows of table: "post_categories" */
  update_post_categories_many?: Maybe<
    Array<Maybe<Post_Categories_Mutation_Response>>
  >
  /** update data of the table: "post_comments" */
  update_post_comments?: Maybe<Post_Comments_Mutation_Response>
  /** update single row of the table: "post_comments" */
  update_post_comments_by_pk?: Maybe<Post_Comments>
  /** update multiples rows of table: "post_comments" */
  update_post_comments_many?: Maybe<
    Array<Maybe<Post_Comments_Mutation_Response>>
  >
  /** update data of the table: "posts" */
  update_posts?: Maybe<Posts_Mutation_Response>
  /** update single row of the table: "posts" */
  update_posts_by_pk?: Maybe<Posts>
  /** update multiples rows of table: "posts" */
  update_posts_many?: Maybe<Array<Maybe<Posts_Mutation_Response>>>
  /** update data of the table: "resources" */
  update_resources?: Maybe<Resources_Mutation_Response>
  /** update single row of the table: "resources" */
  update_resources_by_pk?: Maybe<Resources>
  /** update multiples rows of table: "resources" */
  update_resources_many?: Maybe<Array<Maybe<Resources_Mutation_Response>>>
  /** update data of the table: "segment_user" */
  update_segment_user?: Maybe<Segment_User_Mutation_Response>
  /** update single row of the table: "segment_user" */
  update_segment_user_by_pk?: Maybe<Segment_User>
  /** update multiples rows of table: "segment_user" */
  update_segment_user_many?: Maybe<Array<Maybe<Segment_User_Mutation_Response>>>
  /** update data of the table: "learning_series" */
  update_series?: Maybe<Learning_Series_Mutation_Response>
  /** update data of the table: "slugs" */
  update_slugs?: Maybe<Slugs_Mutation_Response>
  /** update single row of the table: "slugs" */
  update_slugs_by_pk?: Maybe<Slugs>
  /** update multiples rows of table: "slugs" */
  update_slugs_many?: Maybe<Array<Maybe<Slugs_Mutation_Response>>>
  /** update data of the table: "user_group_members" */
  update_user_group_members?: Maybe<User_Group_Members_Mutation_Response>
  /** update single row of the table: "user_group_members" */
  update_user_group_members_by_pk?: Maybe<User_Group_Members>
  /** update multiples rows of table: "user_group_members" */
  update_user_group_members_many?: Maybe<
    Array<Maybe<User_Group_Members_Mutation_Response>>
  >
  /** update data of the table: "user_group_members_roles" */
  update_user_group_members_roles?: Maybe<User_Group_Members_Roles_Mutation_Response>
  /** update single row of the table: "user_group_members_roles" */
  update_user_group_members_roles_by_pk?: Maybe<User_Group_Members_Roles>
  /** update multiples rows of table: "user_group_members_roles" */
  update_user_group_members_roles_many?: Maybe<
    Array<Maybe<User_Group_Members_Roles_Mutation_Response>>
  >
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>
}

/** mutation root */
export type Mutation_RootDelete_By_Pk_Learning_PageArgs = {
  id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_By_Pk_ModuleArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_By_Pk_SeriesArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_Form_ResponsesArgs = {
  where: Form_Responses_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Form_Responses_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_FormsArgs = {
  where: Forms_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Forms_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_GroupsArgs = {
  where: Groups_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Groups_By_PkArgs = {
  id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Featured_ModulesArgs = {
  where: Learning_Featured_Modules_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Featured_Modules_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Event_LogArgs = {
  where: Learning_Learner_Event_Log_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Event_Log_By_PkArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Module_Rating_QuestionsArgs = {
  where: Learning_Learner_Module_Rating_Questions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Module_Rating_Questions_By_PkArgs =
  {
    id: Scalars['uuid']
  }

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Module_Rating_ResponsesArgs = {
  where: Learning_Learner_Module_Rating_Responses_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Module_Rating_Responses_By_PkArgs =
  {
    id: Scalars['uuid']
  }

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Module_RatingsArgs = {
  where: Learning_Learner_Module_Ratings_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Module_Ratings_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Module_Star_RatingsArgs = {
  where: Learning_Learner_Module_Star_Ratings_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Module_Star_Ratings_By_PkArgs =
  {
    id: Scalars['uuid']
  }

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_ReflectionsArgs = {
  where: Learning_Learner_Reflections_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Reflections_By_PkArgs = {
  module_id: Scalars['uuid']
  user_id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_SessionsArgs = {
  where: Learning_Learner_Sessions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Learner_Sessions_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Module_Event_LogArgs = {
  where: Learning_Module_Event_Log_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Module_Event_Log_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Module_Event_TransitionsArgs = {
  where: Learning_Module_Event_Transitions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Module_Event_Transitions_By_PkArgs = {
  event: Scalars['learning_module_event']
  state: Scalars['learning_module_status']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Module_ExistsArgs = {
  where: Learning_Module_Exists_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Module_HistoryArgs = {
  where: Learning_Module_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Module_History_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Module_Revision_PagesArgs = {
  where: Learning_Module_Revision_Pages_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Module_Revision_Pages_By_PkArgs = {
  page_id: Scalars['String']
  revision_id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Module_RevisionsArgs = {
  where: Learning_Module_Revisions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Module_Revisions_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Learning_PagesArgs = {
  where: Learning_Pages_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_QuestionsArgs = {
  where: Learning_Questions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Questions_By_PkArgs = {
  id: Scalars['uuid']
  page_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Series_Discount_GroupsArgs = {
  where: Learning_Series_Discount_Groups_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Series_Discount_Groups_By_PkArgs = {
  discount_id: Scalars['uuid']
  group_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Series_Discount_SettingsArgs = {
  where: Learning_Series_Discount_Settings_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Series_Discount_Settings_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Learning_Series_Subscription_SettingsArgs = {
  where: Learning_Series_Subscription_Settings_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Series_Subscription_Settings_By_PkArgs =
  {
    series_id: Scalars['uuid']
  }

/** mutation root */
export type Mutation_RootDelete_Learning_Series_Subscription_Settings_Discount_TypeArgs =
  {
    where: Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp
  }

/** mutation root */
export type Mutation_RootDelete_Learning_Series_Subscription_Settings_Discount_Type_By_PkArgs =
  {
    value: Scalars['String']
  }

/** mutation root */
export type Mutation_RootDelete_Learning_UploadsArgs = {
  where: Learning_Uploads_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Learning_Uploads_By_PkArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_ModulesArgs = {
  where: Learning_Modules_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Page_CategoriesArgs = {
  where: Page_Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Page_Categories_By_PkArgs = {
  category_id: Scalars['Int']
  page_id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_PagesArgs = {
  where: Pages_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Pages_By_PkArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_Post_CategoriesArgs = {
  where: Post_Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Post_Categories_By_PkArgs = {
  category_id: Scalars['Int']
  post_id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_Post_CommentsArgs = {
  where: Post_Comments_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Post_Comments_By_PkArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_PostsArgs = {
  where: Posts_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Posts_By_PkArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_ResourcesArgs = {
  where: Resources_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Resources_By_PkArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_Segment_UserArgs = {
  where: Segment_User_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Segment_User_By_PkArgs = {
  segment_id: Scalars['bigint']
  user_id: Scalars['bigint']
}

/** mutation root */
export type Mutation_RootDelete_SeriesArgs = {
  where: Learning_Series_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_SlugsArgs = {
  where: Slugs_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Slugs_By_PkArgs = {
  prefix: Scalars['String']
  slug: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_Group_MembersArgs = {
  where: User_Group_Members_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Group_Members_By_PkArgs = {
  group_id: Scalars['String']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_Group_Members_RolesArgs = {
  where: User_Group_Members_Roles_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Group_Members_Roles_By_PkArgs = {
  group_id: Scalars['String']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>
  on_conflict?: InputMaybe<Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input
  on_conflict?: InputMaybe<Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Form_ResponsesArgs = {
  objects: Array<Form_Responses_Insert_Input>
  on_conflict?: InputMaybe<Form_Responses_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Form_Responses_OneArgs = {
  object: Form_Responses_Insert_Input
  on_conflict?: InputMaybe<Form_Responses_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_FormsArgs = {
  objects: Array<Forms_Insert_Input>
  on_conflict?: InputMaybe<Forms_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Forms_OneArgs = {
  object: Forms_Insert_Input
  on_conflict?: InputMaybe<Forms_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_GroupsArgs = {
  objects: Array<Groups_Insert_Input>
  on_conflict?: InputMaybe<Groups_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Groups_OneArgs = {
  object: Groups_Insert_Input
  on_conflict?: InputMaybe<Groups_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Featured_ModulesArgs = {
  objects: Array<Learning_Featured_Modules_Insert_Input>
  on_conflict?: InputMaybe<Learning_Featured_Modules_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Featured_Modules_OneArgs = {
  object: Learning_Featured_Modules_Insert_Input
  on_conflict?: InputMaybe<Learning_Featured_Modules_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Event_LogArgs = {
  objects: Array<Learning_Learner_Event_Log_Insert_Input>
  on_conflict?: InputMaybe<Learning_Learner_Event_Log_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Event_Log_OneArgs = {
  object: Learning_Learner_Event_Log_Insert_Input
  on_conflict?: InputMaybe<Learning_Learner_Event_Log_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Module_Rating_QuestionsArgs = {
  objects: Array<Learning_Learner_Module_Rating_Questions_Insert_Input>
  on_conflict?: InputMaybe<Learning_Learner_Module_Rating_Questions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Module_Rating_Questions_OneArgs =
  {
    object: Learning_Learner_Module_Rating_Questions_Insert_Input
    on_conflict?: InputMaybe<Learning_Learner_Module_Rating_Questions_On_Conflict>
  }

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Module_Rating_ResponsesArgs = {
  objects: Array<Learning_Learner_Module_Rating_Responses_Insert_Input>
  on_conflict?: InputMaybe<Learning_Learner_Module_Rating_Responses_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Module_Rating_Responses_OneArgs =
  {
    object: Learning_Learner_Module_Rating_Responses_Insert_Input
    on_conflict?: InputMaybe<Learning_Learner_Module_Rating_Responses_On_Conflict>
  }

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Module_RatingsArgs = {
  objects: Array<Learning_Learner_Module_Ratings_Insert_Input>
  on_conflict?: InputMaybe<Learning_Learner_Module_Ratings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Module_Ratings_OneArgs = {
  object: Learning_Learner_Module_Ratings_Insert_Input
  on_conflict?: InputMaybe<Learning_Learner_Module_Ratings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Module_Star_RatingsArgs = {
  objects: Array<Learning_Learner_Module_Star_Ratings_Insert_Input>
  on_conflict?: InputMaybe<Learning_Learner_Module_Star_Ratings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Module_Star_Ratings_OneArgs = {
  object: Learning_Learner_Module_Star_Ratings_Insert_Input
  on_conflict?: InputMaybe<Learning_Learner_Module_Star_Ratings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_ReflectionsArgs = {
  objects: Array<Learning_Learner_Reflections_Insert_Input>
  on_conflict?: InputMaybe<Learning_Learner_Reflections_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Reflections_OneArgs = {
  object: Learning_Learner_Reflections_Insert_Input
  on_conflict?: InputMaybe<Learning_Learner_Reflections_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_SessionsArgs = {
  objects: Array<Learning_Learner_Sessions_Insert_Input>
  on_conflict?: InputMaybe<Learning_Learner_Sessions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Learner_Sessions_OneArgs = {
  object: Learning_Learner_Sessions_Insert_Input
  on_conflict?: InputMaybe<Learning_Learner_Sessions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_Event_LogArgs = {
  objects: Array<Learning_Module_Event_Log_Insert_Input>
  on_conflict?: InputMaybe<Learning_Module_Event_Log_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_Event_Log_OneArgs = {
  object: Learning_Module_Event_Log_Insert_Input
  on_conflict?: InputMaybe<Learning_Module_Event_Log_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_Event_TransitionsArgs = {
  objects: Array<Learning_Module_Event_Transitions_Insert_Input>
  on_conflict?: InputMaybe<Learning_Module_Event_Transitions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_Event_Transitions_OneArgs = {
  object: Learning_Module_Event_Transitions_Insert_Input
  on_conflict?: InputMaybe<Learning_Module_Event_Transitions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_ExistsArgs = {
  objects: Array<Learning_Module_Exists_Insert_Input>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_Exists_OneArgs = {
  object: Learning_Module_Exists_Insert_Input
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_HistoryArgs = {
  objects: Array<Learning_Module_History_Insert_Input>
  on_conflict?: InputMaybe<Learning_Module_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_History_OneArgs = {
  object: Learning_Module_History_Insert_Input
  on_conflict?: InputMaybe<Learning_Module_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_Revision_PagesArgs = {
  objects: Array<Learning_Module_Revision_Pages_Insert_Input>
  on_conflict?: InputMaybe<Learning_Module_Revision_Pages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_Revision_Pages_OneArgs = {
  object: Learning_Module_Revision_Pages_Insert_Input
  on_conflict?: InputMaybe<Learning_Module_Revision_Pages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_RevisionsArgs = {
  objects: Array<Learning_Module_Revisions_Insert_Input>
  on_conflict?: InputMaybe<Learning_Module_Revisions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Module_Revisions_OneArgs = {
  object: Learning_Module_Revisions_Insert_Input
  on_conflict?: InputMaybe<Learning_Module_Revisions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_PageArgs = {
  object: Learning_Pages_Insert_Input
  on_conflict?: InputMaybe<Learning_Pages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_PagesArgs = {
  objects: Array<Learning_Pages_Insert_Input>
  on_conflict?: InputMaybe<Learning_Pages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_QuestionsArgs = {
  objects: Array<Learning_Questions_Insert_Input>
  on_conflict?: InputMaybe<Learning_Questions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Questions_OneArgs = {
  object: Learning_Questions_Insert_Input
  on_conflict?: InputMaybe<Learning_Questions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Series_Discount_GroupsArgs = {
  objects: Array<Learning_Series_Discount_Groups_Insert_Input>
  on_conflict?: InputMaybe<Learning_Series_Discount_Groups_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Series_Discount_Groups_OneArgs = {
  object: Learning_Series_Discount_Groups_Insert_Input
  on_conflict?: InputMaybe<Learning_Series_Discount_Groups_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Series_Discount_SettingsArgs = {
  objects: Array<Learning_Series_Discount_Settings_Insert_Input>
  on_conflict?: InputMaybe<Learning_Series_Discount_Settings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Series_Discount_Settings_OneArgs = {
  object: Learning_Series_Discount_Settings_Insert_Input
  on_conflict?: InputMaybe<Learning_Series_Discount_Settings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Series_Subscription_SettingsArgs = {
  objects: Array<Learning_Series_Subscription_Settings_Insert_Input>
  on_conflict?: InputMaybe<Learning_Series_Subscription_Settings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Series_Subscription_Settings_Discount_TypeArgs =
  {
    objects: Array<Learning_Series_Subscription_Settings_Discount_Type_Insert_Input>
    on_conflict?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_On_Conflict>
  }

/** mutation root */
export type Mutation_RootInsert_Learning_Series_Subscription_Settings_Discount_Type_OneArgs =
  {
    object: Learning_Series_Subscription_Settings_Discount_Type_Insert_Input
    on_conflict?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_On_Conflict>
  }

/** mutation root */
export type Mutation_RootInsert_Learning_Series_Subscription_Settings_OneArgs =
  {
    object: Learning_Series_Subscription_Settings_Insert_Input
    on_conflict?: InputMaybe<Learning_Series_Subscription_Settings_On_Conflict>
  }

/** mutation root */
export type Mutation_RootInsert_Learning_UploadsArgs = {
  objects: Array<Learning_Uploads_Insert_Input>
  on_conflict?: InputMaybe<Learning_Uploads_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Learning_Uploads_OneArgs = {
  object: Learning_Uploads_Insert_Input
  on_conflict?: InputMaybe<Learning_Uploads_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ModuleArgs = {
  object: Learning_Modules_Insert_Input
  on_conflict?: InputMaybe<Learning_Modules_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ModulesArgs = {
  objects: Array<Learning_Modules_Insert_Input>
  on_conflict?: InputMaybe<Learning_Modules_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_One_SeriesArgs = {
  object: Learning_Series_Insert_Input
  on_conflict?: InputMaybe<Learning_Series_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Page_CategoriesArgs = {
  objects: Array<Page_Categories_Insert_Input>
  on_conflict?: InputMaybe<Page_Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Page_Categories_OneArgs = {
  object: Page_Categories_Insert_Input
  on_conflict?: InputMaybe<Page_Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_PagesArgs = {
  objects: Array<Pages_Insert_Input>
  on_conflict?: InputMaybe<Pages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Pages_OneArgs = {
  object: Pages_Insert_Input
  on_conflict?: InputMaybe<Pages_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Post_CategoriesArgs = {
  objects: Array<Post_Categories_Insert_Input>
  on_conflict?: InputMaybe<Post_Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Post_Categories_OneArgs = {
  object: Post_Categories_Insert_Input
  on_conflict?: InputMaybe<Post_Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Post_CommentsArgs = {
  objects: Array<Post_Comments_Insert_Input>
  on_conflict?: InputMaybe<Post_Comments_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Post_Comments_OneArgs = {
  object: Post_Comments_Insert_Input
  on_conflict?: InputMaybe<Post_Comments_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_PostsArgs = {
  objects: Array<Posts_Insert_Input>
  on_conflict?: InputMaybe<Posts_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Posts_OneArgs = {
  object: Posts_Insert_Input
  on_conflict?: InputMaybe<Posts_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ResourcesArgs = {
  objects: Array<Resources_Insert_Input>
  on_conflict?: InputMaybe<Resources_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Resources_OneArgs = {
  object: Resources_Insert_Input
  on_conflict?: InputMaybe<Resources_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Segment_UserArgs = {
  objects: Array<Segment_User_Insert_Input>
  on_conflict?: InputMaybe<Segment_User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Segment_User_OneArgs = {
  object: Segment_User_Insert_Input
  on_conflict?: InputMaybe<Segment_User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_SeriesArgs = {
  objects: Array<Learning_Series_Insert_Input>
  on_conflict?: InputMaybe<Learning_Series_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_SlugsArgs = {
  objects: Array<Slugs_Insert_Input>
  on_conflict?: InputMaybe<Slugs_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Slugs_OneArgs = {
  object: Slugs_Insert_Input
  on_conflict?: InputMaybe<Slugs_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Group_MembersArgs = {
  objects: Array<User_Group_Members_Insert_Input>
  on_conflict?: InputMaybe<User_Group_Members_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Group_Members_OneArgs = {
  object: User_Group_Members_Insert_Input
  on_conflict?: InputMaybe<User_Group_Members_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Group_Members_RolesArgs = {
  objects: Array<User_Group_Members_Roles_Insert_Input>
  on_conflict?: InputMaybe<User_Group_Members_Roles_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Group_Members_Roles_OneArgs = {
  object: User_Group_Members_Roles_Insert_Input
  on_conflict?: InputMaybe<User_Group_Members_Roles_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>
  on_conflict?: InputMaybe<Users_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input
  on_conflict?: InputMaybe<Users_On_Conflict>
}

/** mutation root */
export type Mutation_RootUpdate_By_Pk_Learning_PageArgs = {
  _append?: InputMaybe<Learning_Pages_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Pages_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Pages_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Pages_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Pages_Inc_Input>
  _prepend?: InputMaybe<Learning_Pages_Prepend_Input>
  _set?: InputMaybe<Learning_Pages_Set_Input>
  pk_columns: Learning_Pages_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_By_Pk_ModuleArgs = {
  _append?: InputMaybe<Learning_Modules_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Modules_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Modules_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Modules_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Modules_Inc_Input>
  _prepend?: InputMaybe<Learning_Modules_Prepend_Input>
  _set?: InputMaybe<Learning_Modules_Set_Input>
  pk_columns: Learning_Modules_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_By_Pk_SeriesArgs = {
  _append?: InputMaybe<Learning_Series_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Series_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Series_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Series_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Series_Inc_Input>
  _prepend?: InputMaybe<Learning_Series_Prepend_Input>
  _set?: InputMaybe<Learning_Series_Set_Input>
  pk_columns: Learning_Series_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _inc?: InputMaybe<Categories_Inc_Input>
  _set?: InputMaybe<Categories_Set_Input>
  where: Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _inc?: InputMaybe<Categories_Inc_Input>
  _set?: InputMaybe<Categories_Set_Input>
  pk_columns: Categories_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Categories_ManyArgs = {
  updates: Array<Categories_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Form_ResponsesArgs = {
  _append?: InputMaybe<Form_Responses_Append_Input>
  _delete_at_path?: InputMaybe<Form_Responses_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Form_Responses_Delete_Elem_Input>
  _delete_key?: InputMaybe<Form_Responses_Delete_Key_Input>
  _inc?: InputMaybe<Form_Responses_Inc_Input>
  _prepend?: InputMaybe<Form_Responses_Prepend_Input>
  _set?: InputMaybe<Form_Responses_Set_Input>
  where: Form_Responses_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Form_Responses_By_PkArgs = {
  _append?: InputMaybe<Form_Responses_Append_Input>
  _delete_at_path?: InputMaybe<Form_Responses_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Form_Responses_Delete_Elem_Input>
  _delete_key?: InputMaybe<Form_Responses_Delete_Key_Input>
  _inc?: InputMaybe<Form_Responses_Inc_Input>
  _prepend?: InputMaybe<Form_Responses_Prepend_Input>
  _set?: InputMaybe<Form_Responses_Set_Input>
  pk_columns: Form_Responses_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Form_Responses_ManyArgs = {
  updates: Array<Form_Responses_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_FormsArgs = {
  _append?: InputMaybe<Forms_Append_Input>
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>
  _inc?: InputMaybe<Forms_Inc_Input>
  _prepend?: InputMaybe<Forms_Prepend_Input>
  _set?: InputMaybe<Forms_Set_Input>
  where: Forms_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Forms_By_PkArgs = {
  _append?: InputMaybe<Forms_Append_Input>
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>
  _inc?: InputMaybe<Forms_Inc_Input>
  _prepend?: InputMaybe<Forms_Prepend_Input>
  _set?: InputMaybe<Forms_Set_Input>
  pk_columns: Forms_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Forms_ManyArgs = {
  updates: Array<Forms_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_GroupsArgs = {
  _append?: InputMaybe<Groups_Append_Input>
  _delete_at_path?: InputMaybe<Groups_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Groups_Delete_Elem_Input>
  _delete_key?: InputMaybe<Groups_Delete_Key_Input>
  _prepend?: InputMaybe<Groups_Prepend_Input>
  _set?: InputMaybe<Groups_Set_Input>
  where: Groups_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Groups_By_PkArgs = {
  _append?: InputMaybe<Groups_Append_Input>
  _delete_at_path?: InputMaybe<Groups_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Groups_Delete_Elem_Input>
  _delete_key?: InputMaybe<Groups_Delete_Key_Input>
  _prepend?: InputMaybe<Groups_Prepend_Input>
  _set?: InputMaybe<Groups_Set_Input>
  pk_columns: Groups_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Groups_ManyArgs = {
  updates: Array<Groups_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Featured_ModulesArgs = {
  _inc?: InputMaybe<Learning_Featured_Modules_Inc_Input>
  _set?: InputMaybe<Learning_Featured_Modules_Set_Input>
  where: Learning_Featured_Modules_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Featured_Modules_By_PkArgs = {
  _inc?: InputMaybe<Learning_Featured_Modules_Inc_Input>
  _set?: InputMaybe<Learning_Featured_Modules_Set_Input>
  pk_columns: Learning_Featured_Modules_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Featured_Modules_ManyArgs = {
  updates: Array<Learning_Featured_Modules_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Event_LogArgs = {
  _append?: InputMaybe<Learning_Learner_Event_Log_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Learner_Event_Log_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Learner_Event_Log_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Learner_Event_Log_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Learner_Event_Log_Inc_Input>
  _prepend?: InputMaybe<Learning_Learner_Event_Log_Prepend_Input>
  _set?: InputMaybe<Learning_Learner_Event_Log_Set_Input>
  where: Learning_Learner_Event_Log_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Event_Log_By_PkArgs = {
  _append?: InputMaybe<Learning_Learner_Event_Log_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Learner_Event_Log_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Learner_Event_Log_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Learner_Event_Log_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Learner_Event_Log_Inc_Input>
  _prepend?: InputMaybe<Learning_Learner_Event_Log_Prepend_Input>
  _set?: InputMaybe<Learning_Learner_Event_Log_Set_Input>
  pk_columns: Learning_Learner_Event_Log_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Event_Log_ManyArgs = {
  updates: Array<Learning_Learner_Event_Log_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_Rating_QuestionsArgs = {
  _inc?: InputMaybe<Learning_Learner_Module_Rating_Questions_Inc_Input>
  _set?: InputMaybe<Learning_Learner_Module_Rating_Questions_Set_Input>
  where: Learning_Learner_Module_Rating_Questions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_Rating_Questions_By_PkArgs =
  {
    _inc?: InputMaybe<Learning_Learner_Module_Rating_Questions_Inc_Input>
    _set?: InputMaybe<Learning_Learner_Module_Rating_Questions_Set_Input>
    pk_columns: Learning_Learner_Module_Rating_Questions_Pk_Columns_Input
  }

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_Rating_Questions_ManyArgs =
  {
    updates: Array<Learning_Learner_Module_Rating_Questions_Updates>
  }

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_Rating_ResponsesArgs = {
  _inc?: InputMaybe<Learning_Learner_Module_Rating_Responses_Inc_Input>
  _set?: InputMaybe<Learning_Learner_Module_Rating_Responses_Set_Input>
  where: Learning_Learner_Module_Rating_Responses_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_Rating_Responses_By_PkArgs =
  {
    _inc?: InputMaybe<Learning_Learner_Module_Rating_Responses_Inc_Input>
    _set?: InputMaybe<Learning_Learner_Module_Rating_Responses_Set_Input>
    pk_columns: Learning_Learner_Module_Rating_Responses_Pk_Columns_Input
  }

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_Rating_Responses_ManyArgs =
  {
    updates: Array<Learning_Learner_Module_Rating_Responses_Updates>
  }

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_RatingsArgs = {
  _inc?: InputMaybe<Learning_Learner_Module_Ratings_Inc_Input>
  _set?: InputMaybe<Learning_Learner_Module_Ratings_Set_Input>
  where: Learning_Learner_Module_Ratings_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_Ratings_By_PkArgs = {
  _inc?: InputMaybe<Learning_Learner_Module_Ratings_Inc_Input>
  _set?: InputMaybe<Learning_Learner_Module_Ratings_Set_Input>
  pk_columns: Learning_Learner_Module_Ratings_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_Ratings_ManyArgs = {
  updates: Array<Learning_Learner_Module_Ratings_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_Star_RatingsArgs = {
  _inc?: InputMaybe<Learning_Learner_Module_Star_Ratings_Inc_Input>
  _set?: InputMaybe<Learning_Learner_Module_Star_Ratings_Set_Input>
  where: Learning_Learner_Module_Star_Ratings_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_Star_Ratings_By_PkArgs =
  {
    _inc?: InputMaybe<Learning_Learner_Module_Star_Ratings_Inc_Input>
    _set?: InputMaybe<Learning_Learner_Module_Star_Ratings_Set_Input>
    pk_columns: Learning_Learner_Module_Star_Ratings_Pk_Columns_Input
  }

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Module_Star_Ratings_ManyArgs =
  {
    updates: Array<Learning_Learner_Module_Star_Ratings_Updates>
  }

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_ReflectionsArgs = {
  _inc?: InputMaybe<Learning_Learner_Reflections_Inc_Input>
  _set?: InputMaybe<Learning_Learner_Reflections_Set_Input>
  where: Learning_Learner_Reflections_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Reflections_By_PkArgs = {
  _inc?: InputMaybe<Learning_Learner_Reflections_Inc_Input>
  _set?: InputMaybe<Learning_Learner_Reflections_Set_Input>
  pk_columns: Learning_Learner_Reflections_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Reflections_ManyArgs = {
  updates: Array<Learning_Learner_Reflections_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_SessionsArgs = {
  _inc?: InputMaybe<Learning_Learner_Sessions_Inc_Input>
  _set?: InputMaybe<Learning_Learner_Sessions_Set_Input>
  where: Learning_Learner_Sessions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Sessions_By_PkArgs = {
  _inc?: InputMaybe<Learning_Learner_Sessions_Inc_Input>
  _set?: InputMaybe<Learning_Learner_Sessions_Set_Input>
  pk_columns: Learning_Learner_Sessions_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Learner_Sessions_ManyArgs = {
  updates: Array<Learning_Learner_Sessions_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Event_LogArgs = {
  _inc?: InputMaybe<Learning_Module_Event_Log_Inc_Input>
  _set?: InputMaybe<Learning_Module_Event_Log_Set_Input>
  where: Learning_Module_Event_Log_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Event_Log_By_PkArgs = {
  _inc?: InputMaybe<Learning_Module_Event_Log_Inc_Input>
  _set?: InputMaybe<Learning_Module_Event_Log_Set_Input>
  pk_columns: Learning_Module_Event_Log_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Event_Log_ManyArgs = {
  updates: Array<Learning_Module_Event_Log_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Event_TransitionsArgs = {
  _set?: InputMaybe<Learning_Module_Event_Transitions_Set_Input>
  where: Learning_Module_Event_Transitions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Event_Transitions_By_PkArgs = {
  _set?: InputMaybe<Learning_Module_Event_Transitions_Set_Input>
  pk_columns: Learning_Module_Event_Transitions_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Event_Transitions_ManyArgs = {
  updates: Array<Learning_Module_Event_Transitions_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_ExistsArgs = {
  _set?: InputMaybe<Learning_Module_Exists_Set_Input>
  where: Learning_Module_Exists_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Exists_ManyArgs = {
  updates: Array<Learning_Module_Exists_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_HistoryArgs = {
  _append?: InputMaybe<Learning_Module_History_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Module_History_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Module_History_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Module_History_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Module_History_Inc_Input>
  _prepend?: InputMaybe<Learning_Module_History_Prepend_Input>
  _set?: InputMaybe<Learning_Module_History_Set_Input>
  where: Learning_Module_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_History_By_PkArgs = {
  _append?: InputMaybe<Learning_Module_History_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Module_History_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Module_History_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Module_History_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Module_History_Inc_Input>
  _prepend?: InputMaybe<Learning_Module_History_Prepend_Input>
  _set?: InputMaybe<Learning_Module_History_Set_Input>
  pk_columns: Learning_Module_History_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_History_ManyArgs = {
  updates: Array<Learning_Module_History_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Revision_PagesArgs = {
  _inc?: InputMaybe<Learning_Module_Revision_Pages_Inc_Input>
  _set?: InputMaybe<Learning_Module_Revision_Pages_Set_Input>
  where: Learning_Module_Revision_Pages_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Revision_Pages_By_PkArgs = {
  _inc?: InputMaybe<Learning_Module_Revision_Pages_Inc_Input>
  _set?: InputMaybe<Learning_Module_Revision_Pages_Set_Input>
  pk_columns: Learning_Module_Revision_Pages_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Revision_Pages_ManyArgs = {
  updates: Array<Learning_Module_Revision_Pages_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_RevisionsArgs = {
  _append?: InputMaybe<Learning_Module_Revisions_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Module_Revisions_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Module_Revisions_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Module_Revisions_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Module_Revisions_Inc_Input>
  _prepend?: InputMaybe<Learning_Module_Revisions_Prepend_Input>
  _set?: InputMaybe<Learning_Module_Revisions_Set_Input>
  where: Learning_Module_Revisions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Revisions_By_PkArgs = {
  _append?: InputMaybe<Learning_Module_Revisions_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Module_Revisions_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Module_Revisions_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Module_Revisions_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Module_Revisions_Inc_Input>
  _prepend?: InputMaybe<Learning_Module_Revisions_Prepend_Input>
  _set?: InputMaybe<Learning_Module_Revisions_Set_Input>
  pk_columns: Learning_Module_Revisions_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Module_Revisions_ManyArgs = {
  updates: Array<Learning_Module_Revisions_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Modules_ManyArgs = {
  updates: Array<Learning_Modules_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_PagesArgs = {
  _append?: InputMaybe<Learning_Pages_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Pages_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Pages_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Pages_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Pages_Inc_Input>
  _prepend?: InputMaybe<Learning_Pages_Prepend_Input>
  _set?: InputMaybe<Learning_Pages_Set_Input>
  where: Learning_Pages_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Pages_ManyArgs = {
  updates: Array<Learning_Pages_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_QuestionsArgs = {
  _append?: InputMaybe<Learning_Questions_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Questions_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Questions_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Questions_Delete_Key_Input>
  _prepend?: InputMaybe<Learning_Questions_Prepend_Input>
  _set?: InputMaybe<Learning_Questions_Set_Input>
  where: Learning_Questions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Questions_By_PkArgs = {
  _append?: InputMaybe<Learning_Questions_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Questions_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Questions_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Questions_Delete_Key_Input>
  _prepend?: InputMaybe<Learning_Questions_Prepend_Input>
  _set?: InputMaybe<Learning_Questions_Set_Input>
  pk_columns: Learning_Questions_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Questions_ManyArgs = {
  updates: Array<Learning_Questions_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Discount_GroupsArgs = {
  _set?: InputMaybe<Learning_Series_Discount_Groups_Set_Input>
  where: Learning_Series_Discount_Groups_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Discount_Groups_By_PkArgs = {
  _set?: InputMaybe<Learning_Series_Discount_Groups_Set_Input>
  pk_columns: Learning_Series_Discount_Groups_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Discount_Groups_ManyArgs = {
  updates: Array<Learning_Series_Discount_Groups_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Discount_SettingsArgs = {
  _inc?: InputMaybe<Learning_Series_Discount_Settings_Inc_Input>
  _set?: InputMaybe<Learning_Series_Discount_Settings_Set_Input>
  where: Learning_Series_Discount_Settings_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Discount_Settings_By_PkArgs = {
  _inc?: InputMaybe<Learning_Series_Discount_Settings_Inc_Input>
  _set?: InputMaybe<Learning_Series_Discount_Settings_Set_Input>
  pk_columns: Learning_Series_Discount_Settings_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Discount_Settings_ManyArgs = {
  updates: Array<Learning_Series_Discount_Settings_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_ManyArgs = {
  updates: Array<Learning_Series_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Subscription_SettingsArgs = {
  _inc?: InputMaybe<Learning_Series_Subscription_Settings_Inc_Input>
  _set?: InputMaybe<Learning_Series_Subscription_Settings_Set_Input>
  where: Learning_Series_Subscription_Settings_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Subscription_Settings_By_PkArgs =
  {
    _inc?: InputMaybe<Learning_Series_Subscription_Settings_Inc_Input>
    _set?: InputMaybe<Learning_Series_Subscription_Settings_Set_Input>
    pk_columns: Learning_Series_Subscription_Settings_Pk_Columns_Input
  }

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Subscription_Settings_Discount_TypeArgs =
  {
    _set?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Set_Input>
    where: Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp
  }

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Subscription_Settings_Discount_Type_By_PkArgs =
  {
    _set?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Set_Input>
    pk_columns: Learning_Series_Subscription_Settings_Discount_Type_Pk_Columns_Input
  }

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Subscription_Settings_Discount_Type_ManyArgs =
  {
    updates: Array<Learning_Series_Subscription_Settings_Discount_Type_Updates>
  }

/** mutation root */
export type Mutation_RootUpdate_Learning_Series_Subscription_Settings_ManyArgs =
  {
    updates: Array<Learning_Series_Subscription_Settings_Updates>
  }

/** mutation root */
export type Mutation_RootUpdate_Learning_UploadsArgs = {
  _append?: InputMaybe<Learning_Uploads_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Uploads_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Uploads_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Uploads_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Uploads_Inc_Input>
  _prepend?: InputMaybe<Learning_Uploads_Prepend_Input>
  _set?: InputMaybe<Learning_Uploads_Set_Input>
  where: Learning_Uploads_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Uploads_By_PkArgs = {
  _append?: InputMaybe<Learning_Uploads_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Uploads_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Uploads_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Uploads_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Uploads_Inc_Input>
  _prepend?: InputMaybe<Learning_Uploads_Prepend_Input>
  _set?: InputMaybe<Learning_Uploads_Set_Input>
  pk_columns: Learning_Uploads_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Learning_Uploads_ManyArgs = {
  updates: Array<Learning_Uploads_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_ModulesArgs = {
  _append?: InputMaybe<Learning_Modules_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Modules_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Modules_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Modules_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Modules_Inc_Input>
  _prepend?: InputMaybe<Learning_Modules_Prepend_Input>
  _set?: InputMaybe<Learning_Modules_Set_Input>
  where: Learning_Modules_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Page_CategoriesArgs = {
  _inc?: InputMaybe<Page_Categories_Inc_Input>
  _set?: InputMaybe<Page_Categories_Set_Input>
  where: Page_Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Page_Categories_By_PkArgs = {
  _inc?: InputMaybe<Page_Categories_Inc_Input>
  _set?: InputMaybe<Page_Categories_Set_Input>
  pk_columns: Page_Categories_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Page_Categories_ManyArgs = {
  updates: Array<Page_Categories_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_PagesArgs = {
  _append?: InputMaybe<Pages_Append_Input>
  _delete_at_path?: InputMaybe<Pages_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Pages_Delete_Elem_Input>
  _delete_key?: InputMaybe<Pages_Delete_Key_Input>
  _inc?: InputMaybe<Pages_Inc_Input>
  _prepend?: InputMaybe<Pages_Prepend_Input>
  _set?: InputMaybe<Pages_Set_Input>
  where: Pages_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Pages_By_PkArgs = {
  _append?: InputMaybe<Pages_Append_Input>
  _delete_at_path?: InputMaybe<Pages_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Pages_Delete_Elem_Input>
  _delete_key?: InputMaybe<Pages_Delete_Key_Input>
  _inc?: InputMaybe<Pages_Inc_Input>
  _prepend?: InputMaybe<Pages_Prepend_Input>
  _set?: InputMaybe<Pages_Set_Input>
  pk_columns: Pages_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Pages_ManyArgs = {
  updates: Array<Pages_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Post_CategoriesArgs = {
  _inc?: InputMaybe<Post_Categories_Inc_Input>
  _set?: InputMaybe<Post_Categories_Set_Input>
  where: Post_Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Post_Categories_By_PkArgs = {
  _inc?: InputMaybe<Post_Categories_Inc_Input>
  _set?: InputMaybe<Post_Categories_Set_Input>
  pk_columns: Post_Categories_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Post_Categories_ManyArgs = {
  updates: Array<Post_Categories_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Post_CommentsArgs = {
  _inc?: InputMaybe<Post_Comments_Inc_Input>
  _set?: InputMaybe<Post_Comments_Set_Input>
  where: Post_Comments_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Post_Comments_By_PkArgs = {
  _inc?: InputMaybe<Post_Comments_Inc_Input>
  _set?: InputMaybe<Post_Comments_Set_Input>
  pk_columns: Post_Comments_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Post_Comments_ManyArgs = {
  updates: Array<Post_Comments_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_PostsArgs = {
  _append?: InputMaybe<Posts_Append_Input>
  _delete_at_path?: InputMaybe<Posts_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Posts_Delete_Elem_Input>
  _delete_key?: InputMaybe<Posts_Delete_Key_Input>
  _inc?: InputMaybe<Posts_Inc_Input>
  _prepend?: InputMaybe<Posts_Prepend_Input>
  _set?: InputMaybe<Posts_Set_Input>
  where: Posts_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Posts_By_PkArgs = {
  _append?: InputMaybe<Posts_Append_Input>
  _delete_at_path?: InputMaybe<Posts_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Posts_Delete_Elem_Input>
  _delete_key?: InputMaybe<Posts_Delete_Key_Input>
  _inc?: InputMaybe<Posts_Inc_Input>
  _prepend?: InputMaybe<Posts_Prepend_Input>
  _set?: InputMaybe<Posts_Set_Input>
  pk_columns: Posts_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Posts_ManyArgs = {
  updates: Array<Posts_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_ResourcesArgs = {
  _append?: InputMaybe<Resources_Append_Input>
  _delete_at_path?: InputMaybe<Resources_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Resources_Delete_Elem_Input>
  _delete_key?: InputMaybe<Resources_Delete_Key_Input>
  _inc?: InputMaybe<Resources_Inc_Input>
  _prepend?: InputMaybe<Resources_Prepend_Input>
  _set?: InputMaybe<Resources_Set_Input>
  where: Resources_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Resources_By_PkArgs = {
  _append?: InputMaybe<Resources_Append_Input>
  _delete_at_path?: InputMaybe<Resources_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Resources_Delete_Elem_Input>
  _delete_key?: InputMaybe<Resources_Delete_Key_Input>
  _inc?: InputMaybe<Resources_Inc_Input>
  _prepend?: InputMaybe<Resources_Prepend_Input>
  _set?: InputMaybe<Resources_Set_Input>
  pk_columns: Resources_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Resources_ManyArgs = {
  updates: Array<Resources_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Segment_UserArgs = {
  _inc?: InputMaybe<Segment_User_Inc_Input>
  _set?: InputMaybe<Segment_User_Set_Input>
  where: Segment_User_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Segment_User_By_PkArgs = {
  _inc?: InputMaybe<Segment_User_Inc_Input>
  _set?: InputMaybe<Segment_User_Set_Input>
  pk_columns: Segment_User_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Segment_User_ManyArgs = {
  updates: Array<Segment_User_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_SeriesArgs = {
  _append?: InputMaybe<Learning_Series_Append_Input>
  _delete_at_path?: InputMaybe<Learning_Series_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Learning_Series_Delete_Elem_Input>
  _delete_key?: InputMaybe<Learning_Series_Delete_Key_Input>
  _inc?: InputMaybe<Learning_Series_Inc_Input>
  _prepend?: InputMaybe<Learning_Series_Prepend_Input>
  _set?: InputMaybe<Learning_Series_Set_Input>
  where: Learning_Series_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_SlugsArgs = {
  _inc?: InputMaybe<Slugs_Inc_Input>
  _set?: InputMaybe<Slugs_Set_Input>
  where: Slugs_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Slugs_By_PkArgs = {
  _inc?: InputMaybe<Slugs_Inc_Input>
  _set?: InputMaybe<Slugs_Set_Input>
  pk_columns: Slugs_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Slugs_ManyArgs = {
  updates: Array<Slugs_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Group_MembersArgs = {
  _set?: InputMaybe<User_Group_Members_Set_Input>
  where: User_Group_Members_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Group_Members_By_PkArgs = {
  _set?: InputMaybe<User_Group_Members_Set_Input>
  pk_columns: User_Group_Members_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Group_Members_ManyArgs = {
  updates: Array<User_Group_Members_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Group_Members_RolesArgs = {
  _set?: InputMaybe<User_Group_Members_Roles_Set_Input>
  where: User_Group_Members_Roles_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Group_Members_Roles_By_PkArgs = {
  _set?: InputMaybe<User_Group_Members_Roles_Set_Input>
  pk_columns: User_Group_Members_Roles_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Group_Members_Roles_ManyArgs = {
  updates: Array<User_Group_Members_Roles_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>
  _set?: InputMaybe<Users_Set_Input>
  where: Users_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>
  _set?: InputMaybe<Users_Set_Input>
  pk_columns: Users_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>
  _gt?: InputMaybe<Scalars['numeric']>
  _gte?: InputMaybe<Scalars['numeric']>
  _in?: InputMaybe<Array<Scalars['numeric']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['numeric']>
  _lte?: InputMaybe<Scalars['numeric']>
  _neq?: InputMaybe<Scalars['numeric']>
  _nin?: InputMaybe<Array<Scalars['numeric']>>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "page_categories" */
export type Page_Categories = {
  __typename?: 'page_categories'
  /** An object relationship */
  category: Categories
  category_id: Scalars['Int']
  /** An object relationship */
  page: Pages
  page_id: Scalars['Int']
}

/** aggregated selection of "page_categories" */
export type Page_Categories_Aggregate = {
  __typename?: 'page_categories_aggregate'
  aggregate?: Maybe<Page_Categories_Aggregate_Fields>
  nodes: Array<Page_Categories>
}

export type Page_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Page_Categories_Aggregate_Bool_Exp_Count>
}

export type Page_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Page_Categories_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Page_Categories_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "page_categories" */
export type Page_Categories_Aggregate_Fields = {
  __typename?: 'page_categories_aggregate_fields'
  avg?: Maybe<Page_Categories_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Page_Categories_Max_Fields>
  min?: Maybe<Page_Categories_Min_Fields>
  stddev?: Maybe<Page_Categories_Stddev_Fields>
  stddev_pop?: Maybe<Page_Categories_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Page_Categories_Stddev_Samp_Fields>
  sum?: Maybe<Page_Categories_Sum_Fields>
  var_pop?: Maybe<Page_Categories_Var_Pop_Fields>
  var_samp?: Maybe<Page_Categories_Var_Samp_Fields>
  variance?: Maybe<Page_Categories_Variance_Fields>
}

/** aggregate fields of "page_categories" */
export type Page_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Page_Categories_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "page_categories" */
export type Page_Categories_Aggregate_Order_By = {
  avg?: InputMaybe<Page_Categories_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Page_Categories_Max_Order_By>
  min?: InputMaybe<Page_Categories_Min_Order_By>
  stddev?: InputMaybe<Page_Categories_Stddev_Order_By>
  stddev_pop?: InputMaybe<Page_Categories_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Page_Categories_Stddev_Samp_Order_By>
  sum?: InputMaybe<Page_Categories_Sum_Order_By>
  var_pop?: InputMaybe<Page_Categories_Var_Pop_Order_By>
  var_samp?: InputMaybe<Page_Categories_Var_Samp_Order_By>
  variance?: InputMaybe<Page_Categories_Variance_Order_By>
}

/** input type for inserting array relation for remote table "page_categories" */
export type Page_Categories_Arr_Rel_Insert_Input = {
  data: Array<Page_Categories_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Page_Categories_On_Conflict>
}

/** aggregate avg on columns */
export type Page_Categories_Avg_Fields = {
  __typename?: 'page_categories_avg_fields'
  category_id?: Maybe<Scalars['Float']>
  page_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "page_categories" */
export type Page_Categories_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "page_categories". All fields are combined with a logical 'AND'. */
export type Page_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Page_Categories_Bool_Exp>>
  _not?: InputMaybe<Page_Categories_Bool_Exp>
  _or?: InputMaybe<Array<Page_Categories_Bool_Exp>>
  category?: InputMaybe<Categories_Bool_Exp>
  category_id?: InputMaybe<Int_Comparison_Exp>
  page?: InputMaybe<Pages_Bool_Exp>
  page_id?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "page_categories" */
export enum Page_Categories_Constraint {
  /** unique or primary key constraint on columns "category_id", "page_id" */
  PageCategoriesPkey = 'page_categories_pkey',
}

/** input type for incrementing numeric columns in table "page_categories" */
export type Page_Categories_Inc_Input = {
  category_id?: InputMaybe<Scalars['Int']>
  page_id?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "page_categories" */
export type Page_Categories_Insert_Input = {
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>
  category_id?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Pages_Obj_Rel_Insert_Input>
  page_id?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Page_Categories_Max_Fields = {
  __typename?: 'page_categories_max_fields'
  category_id?: Maybe<Scalars['Int']>
  page_id?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "page_categories" */
export type Page_Categories_Max_Order_By = {
  category_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Page_Categories_Min_Fields = {
  __typename?: 'page_categories_min_fields'
  category_id?: Maybe<Scalars['Int']>
  page_id?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "page_categories" */
export type Page_Categories_Min_Order_By = {
  category_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "page_categories" */
export type Page_Categories_Mutation_Response = {
  __typename?: 'page_categories_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Page_Categories>
}

/** on_conflict condition type for table "page_categories" */
export type Page_Categories_On_Conflict = {
  constraint: Page_Categories_Constraint
  update_columns?: Array<Page_Categories_Update_Column>
  where?: InputMaybe<Page_Categories_Bool_Exp>
}

/** Ordering options when selecting data from "page_categories". */
export type Page_Categories_Order_By = {
  category?: InputMaybe<Categories_Order_By>
  category_id?: InputMaybe<Order_By>
  page?: InputMaybe<Pages_Order_By>
  page_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: page_categories */
export type Page_Categories_Pk_Columns_Input = {
  category_id: Scalars['Int']
  page_id: Scalars['Int']
}

/** select columns of table "page_categories" */
export enum Page_Categories_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  PageId = 'page_id',
}

/** input type for updating data in table "page_categories" */
export type Page_Categories_Set_Input = {
  category_id?: InputMaybe<Scalars['Int']>
  page_id?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Page_Categories_Stddev_Fields = {
  __typename?: 'page_categories_stddev_fields'
  category_id?: Maybe<Scalars['Float']>
  page_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "page_categories" */
export type Page_Categories_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Page_Categories_Stddev_Pop_Fields = {
  __typename?: 'page_categories_stddev_pop_fields'
  category_id?: Maybe<Scalars['Float']>
  page_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "page_categories" */
export type Page_Categories_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Page_Categories_Stddev_Samp_Fields = {
  __typename?: 'page_categories_stddev_samp_fields'
  category_id?: Maybe<Scalars['Float']>
  page_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "page_categories" */
export type Page_Categories_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "page_categories" */
export type Page_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Page_Categories_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Page_Categories_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['Int']>
  page_id?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Page_Categories_Sum_Fields = {
  __typename?: 'page_categories_sum_fields'
  category_id?: Maybe<Scalars['Int']>
  page_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "page_categories" */
export type Page_Categories_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
}

/** update columns of table "page_categories" */
export enum Page_Categories_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  PageId = 'page_id',
}

export type Page_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Page_Categories_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Page_Categories_Set_Input>
  /** filter the rows which have to be updated */
  where: Page_Categories_Bool_Exp
}

/** aggregate var_pop on columns */
export type Page_Categories_Var_Pop_Fields = {
  __typename?: 'page_categories_var_pop_fields'
  category_id?: Maybe<Scalars['Float']>
  page_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "page_categories" */
export type Page_Categories_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Page_Categories_Var_Samp_Fields = {
  __typename?: 'page_categories_var_samp_fields'
  category_id?: Maybe<Scalars['Float']>
  page_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "page_categories" */
export type Page_Categories_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Page_Categories_Variance_Fields = {
  __typename?: 'page_categories_variance_fields'
  category_id?: Maybe<Scalars['Float']>
  page_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "page_categories" */
export type Page_Categories_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>
  page_id?: InputMaybe<Order_By>
}

/** columns and relationships of "pages" */
export type Pages = {
  __typename?: 'pages'
  body: Scalars['jsonb']
  /** An array relationship */
  categories: Array<Page_Categories>
  /** An aggregate relationship */
  categories_aggregate: Page_Categories_Aggregate
  created_at: Scalars['timestamptz']
  created_by: Scalars['Int']
  /** An object relationship */
  creator: Users
  description?: Maybe<Scalars['String']>
  id: Scalars['Int']
  published: Scalars['Boolean']
  published_at?: Maybe<Scalars['timestamptz']>
  published_by?: Maybe<Scalars['Int']>
  /** An object relationship */
  publisher?: Maybe<Users>
  slug: Scalars['String']
  title: Scalars['String']
  updated_at: Scalars['timestamptz']
  updated_by: Scalars['Int']
  /** An object relationship */
  updater: Users
}

/** columns and relationships of "pages" */
export type PagesBodyArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "pages" */
export type PagesCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Page_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Page_Categories_Order_By>>
  where?: InputMaybe<Page_Categories_Bool_Exp>
}

/** columns and relationships of "pages" */
export type PagesCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Page_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Page_Categories_Order_By>>
  where?: InputMaybe<Page_Categories_Bool_Exp>
}

/** aggregated selection of "pages" */
export type Pages_Aggregate = {
  __typename?: 'pages_aggregate'
  aggregate?: Maybe<Pages_Aggregate_Fields>
  nodes: Array<Pages>
}

/** aggregate fields of "pages" */
export type Pages_Aggregate_Fields = {
  __typename?: 'pages_aggregate_fields'
  avg?: Maybe<Pages_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Pages_Max_Fields>
  min?: Maybe<Pages_Min_Fields>
  stddev?: Maybe<Pages_Stddev_Fields>
  stddev_pop?: Maybe<Pages_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Pages_Stddev_Samp_Fields>
  sum?: Maybe<Pages_Sum_Fields>
  var_pop?: Maybe<Pages_Var_Pop_Fields>
  var_samp?: Maybe<Pages_Var_Samp_Fields>
  variance?: Maybe<Pages_Variance_Fields>
}

/** aggregate fields of "pages" */
export type Pages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pages_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pages_Append_Input = {
  body?: InputMaybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Pages_Avg_Fields = {
  __typename?: 'pages_avg_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "pages". All fields are combined with a logical 'AND'. */
export type Pages_Bool_Exp = {
  _and?: InputMaybe<Array<Pages_Bool_Exp>>
  _not?: InputMaybe<Pages_Bool_Exp>
  _or?: InputMaybe<Array<Pages_Bool_Exp>>
  body?: InputMaybe<Jsonb_Comparison_Exp>
  categories?: InputMaybe<Page_Categories_Bool_Exp>
  categories_aggregate?: InputMaybe<Page_Categories_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  creator?: InputMaybe<Users_Bool_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Int_Comparison_Exp>
  published?: InputMaybe<Boolean_Comparison_Exp>
  published_at?: InputMaybe<Timestamptz_Comparison_Exp>
  published_by?: InputMaybe<Int_Comparison_Exp>
  publisher?: InputMaybe<Users_Bool_Exp>
  slug?: InputMaybe<String_Comparison_Exp>
  title?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
  updater?: InputMaybe<Users_Bool_Exp>
}

/** unique or primary key constraints on table "pages" */
export enum Pages_Constraint {
  /** unique or primary key constraint on columns "id" */
  PagesPkey = 'pages_pkey',
  /** unique or primary key constraint on columns "slug" */
  PagesSlugKey = 'pages_slug_key',
  /** unique or primary key constraint on columns "title" */
  PagesTitleKey = 'pages_title_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pages_Delete_At_Path_Input = {
  body?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pages_Delete_Elem_Input = {
  body?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pages_Delete_Key_Input = {
  body?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "pages" */
export type Pages_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  published_by?: InputMaybe<Scalars['Int']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "pages" */
export type Pages_Insert_Input = {
  body?: InputMaybe<Scalars['jsonb']>
  categories?: InputMaybe<Page_Categories_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  published?: InputMaybe<Scalars['Boolean']>
  published_at?: InputMaybe<Scalars['timestamptz']>
  published_by?: InputMaybe<Scalars['Int']>
  publisher?: InputMaybe<Users_Obj_Rel_Insert_Input>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  updater?: InputMaybe<Users_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Pages_Max_Fields = {
  __typename?: 'pages_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  published_at?: Maybe<Scalars['timestamptz']>
  published_by?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** aggregate min on columns */
export type Pages_Min_Fields = {
  __typename?: 'pages_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  published_at?: Maybe<Scalars['timestamptz']>
  published_by?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** response of any mutation on the table "pages" */
export type Pages_Mutation_Response = {
  __typename?: 'pages_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Pages>
}

/** input type for inserting object relation for remote table "pages" */
export type Pages_Obj_Rel_Insert_Input = {
  data: Pages_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Pages_On_Conflict>
}

/** on_conflict condition type for table "pages" */
export type Pages_On_Conflict = {
  constraint: Pages_Constraint
  update_columns?: Array<Pages_Update_Column>
  where?: InputMaybe<Pages_Bool_Exp>
}

/** Ordering options when selecting data from "pages". */
export type Pages_Order_By = {
  body?: InputMaybe<Order_By>
  categories_aggregate?: InputMaybe<Page_Categories_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  creator?: InputMaybe<Users_Order_By>
  description?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  published?: InputMaybe<Order_By>
  published_at?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  publisher?: InputMaybe<Users_Order_By>
  slug?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  updater?: InputMaybe<Users_Order_By>
}

/** primary key columns input for table: pages */
export type Pages_Pk_Columns_Input = {
  id: Scalars['Int']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pages_Prepend_Input = {
  body?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "pages" */
export enum Pages_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  PublishedBy = 'published_by',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** input type for updating data in table "pages" */
export type Pages_Set_Input = {
  body?: InputMaybe<Scalars['jsonb']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  published?: InputMaybe<Scalars['Boolean']>
  published_at?: InputMaybe<Scalars['timestamptz']>
  published_by?: InputMaybe<Scalars['Int']>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Pages_Stddev_Fields = {
  __typename?: 'pages_stddev_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Pages_Stddev_Pop_Fields = {
  __typename?: 'pages_stddev_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Pages_Stddev_Samp_Fields = {
  __typename?: 'pages_stddev_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "pages" */
export type Pages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pages_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Pages_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['jsonb']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  published?: InputMaybe<Scalars['Boolean']>
  published_at?: InputMaybe<Scalars['timestamptz']>
  published_by?: InputMaybe<Scalars['Int']>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Pages_Sum_Fields = {
  __typename?: 'pages_sum_fields'
  created_by?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  published_by?: Maybe<Scalars['Int']>
  updated_by?: Maybe<Scalars['Int']>
}

/** update columns of table "pages" */
export enum Pages_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  PublishedBy = 'published_by',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Pages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Pages_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Pages_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Pages_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Pages_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Pages_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Pages_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pages_Set_Input>
  /** filter the rows which have to be updated */
  where: Pages_Bool_Exp
}

/** aggregate var_pop on columns */
export type Pages_Var_Pop_Fields = {
  __typename?: 'pages_var_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Pages_Var_Samp_Fields = {
  __typename?: 'pages_var_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Pages_Variance_Fields = {
  __typename?: 'pages_variance_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** columns and relationships of "post_categories" */
export type Post_Categories = {
  __typename?: 'post_categories'
  /** An object relationship */
  category: Categories
  category_id: Scalars['Int']
  /** An object relationship */
  post: Posts
  post_id: Scalars['Int']
}

/** aggregated selection of "post_categories" */
export type Post_Categories_Aggregate = {
  __typename?: 'post_categories_aggregate'
  aggregate?: Maybe<Post_Categories_Aggregate_Fields>
  nodes: Array<Post_Categories>
}

export type Post_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Post_Categories_Aggregate_Bool_Exp_Count>
}

export type Post_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Categories_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Post_Categories_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "post_categories" */
export type Post_Categories_Aggregate_Fields = {
  __typename?: 'post_categories_aggregate_fields'
  avg?: Maybe<Post_Categories_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Post_Categories_Max_Fields>
  min?: Maybe<Post_Categories_Min_Fields>
  stddev?: Maybe<Post_Categories_Stddev_Fields>
  stddev_pop?: Maybe<Post_Categories_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Post_Categories_Stddev_Samp_Fields>
  sum?: Maybe<Post_Categories_Sum_Fields>
  var_pop?: Maybe<Post_Categories_Var_Pop_Fields>
  var_samp?: Maybe<Post_Categories_Var_Samp_Fields>
  variance?: Maybe<Post_Categories_Variance_Fields>
}

/** aggregate fields of "post_categories" */
export type Post_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Categories_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "post_categories" */
export type Post_Categories_Aggregate_Order_By = {
  avg?: InputMaybe<Post_Categories_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Post_Categories_Max_Order_By>
  min?: InputMaybe<Post_Categories_Min_Order_By>
  stddev?: InputMaybe<Post_Categories_Stddev_Order_By>
  stddev_pop?: InputMaybe<Post_Categories_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Post_Categories_Stddev_Samp_Order_By>
  sum?: InputMaybe<Post_Categories_Sum_Order_By>
  var_pop?: InputMaybe<Post_Categories_Var_Pop_Order_By>
  var_samp?: InputMaybe<Post_Categories_Var_Samp_Order_By>
  variance?: InputMaybe<Post_Categories_Variance_Order_By>
}

/** input type for inserting array relation for remote table "post_categories" */
export type Post_Categories_Arr_Rel_Insert_Input = {
  data: Array<Post_Categories_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Categories_On_Conflict>
}

/** aggregate avg on columns */
export type Post_Categories_Avg_Fields = {
  __typename?: 'post_categories_avg_fields'
  category_id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "post_categories" */
export type Post_Categories_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "post_categories". All fields are combined with a logical 'AND'. */
export type Post_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Categories_Bool_Exp>>
  _not?: InputMaybe<Post_Categories_Bool_Exp>
  _or?: InputMaybe<Array<Post_Categories_Bool_Exp>>
  category?: InputMaybe<Categories_Bool_Exp>
  category_id?: InputMaybe<Int_Comparison_Exp>
  post?: InputMaybe<Posts_Bool_Exp>
  post_id?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "post_categories" */
export enum Post_Categories_Constraint {
  /** unique or primary key constraint on columns "category_id", "post_id" */
  PostCategoriesPkey = 'post_categories_pkey',
}

/** input type for incrementing numeric columns in table "post_categories" */
export type Post_Categories_Inc_Input = {
  category_id?: InputMaybe<Scalars['Int']>
  post_id?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "post_categories" */
export type Post_Categories_Insert_Input = {
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>
  category_id?: InputMaybe<Scalars['Int']>
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>
  post_id?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Post_Categories_Max_Fields = {
  __typename?: 'post_categories_max_fields'
  category_id?: Maybe<Scalars['Int']>
  post_id?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "post_categories" */
export type Post_Categories_Max_Order_By = {
  category_id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Post_Categories_Min_Fields = {
  __typename?: 'post_categories_min_fields'
  category_id?: Maybe<Scalars['Int']>
  post_id?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "post_categories" */
export type Post_Categories_Min_Order_By = {
  category_id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "post_categories" */
export type Post_Categories_Mutation_Response = {
  __typename?: 'post_categories_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Post_Categories>
}

/** on_conflict condition type for table "post_categories" */
export type Post_Categories_On_Conflict = {
  constraint: Post_Categories_Constraint
  update_columns?: Array<Post_Categories_Update_Column>
  where?: InputMaybe<Post_Categories_Bool_Exp>
}

/** Ordering options when selecting data from "post_categories". */
export type Post_Categories_Order_By = {
  category?: InputMaybe<Categories_Order_By>
  category_id?: InputMaybe<Order_By>
  post?: InputMaybe<Posts_Order_By>
  post_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: post_categories */
export type Post_Categories_Pk_Columns_Input = {
  category_id: Scalars['Int']
  post_id: Scalars['Int']
}

/** select columns of table "post_categories" */
export enum Post_Categories_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  PostId = 'post_id',
}

/** input type for updating data in table "post_categories" */
export type Post_Categories_Set_Input = {
  category_id?: InputMaybe<Scalars['Int']>
  post_id?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Post_Categories_Stddev_Fields = {
  __typename?: 'post_categories_stddev_fields'
  category_id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "post_categories" */
export type Post_Categories_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Post_Categories_Stddev_Pop_Fields = {
  __typename?: 'post_categories_stddev_pop_fields'
  category_id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "post_categories" */
export type Post_Categories_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Post_Categories_Stddev_Samp_Fields = {
  __typename?: 'post_categories_stddev_samp_fields'
  category_id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "post_categories" */
export type Post_Categories_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "post_categories" */
export type Post_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Categories_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Post_Categories_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['Int']>
  post_id?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Post_Categories_Sum_Fields = {
  __typename?: 'post_categories_sum_fields'
  category_id?: Maybe<Scalars['Int']>
  post_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "post_categories" */
export type Post_Categories_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
}

/** update columns of table "post_categories" */
export enum Post_Categories_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  PostId = 'post_id',
}

export type Post_Categories_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Post_Categories_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Categories_Set_Input>
  /** filter the rows which have to be updated */
  where: Post_Categories_Bool_Exp
}

/** aggregate var_pop on columns */
export type Post_Categories_Var_Pop_Fields = {
  __typename?: 'post_categories_var_pop_fields'
  category_id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "post_categories" */
export type Post_Categories_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Post_Categories_Var_Samp_Fields = {
  __typename?: 'post_categories_var_samp_fields'
  category_id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "post_categories" */
export type Post_Categories_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Post_Categories_Variance_Fields = {
  __typename?: 'post_categories_variance_fields'
  category_id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "post_categories" */
export type Post_Categories_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
}

/** columns and relationships of "post_comments" */
export type Post_Comments = {
  __typename?: 'post_comments'
  body: Scalars['String']
  created_at: Scalars['timestamptz']
  created_by: Scalars['Int']
  /** An object relationship */
  creator: Users
  hidden: Scalars['Boolean']
  id: Scalars['Int']
  /** An object relationship */
  post: Posts
  post_id: Scalars['Int']
  updated_at: Scalars['timestamptz']
  updated_by: Scalars['Int']
  /** An object relationship */
  updater: Users
}

/** aggregated selection of "post_comments" */
export type Post_Comments_Aggregate = {
  __typename?: 'post_comments_aggregate'
  aggregate?: Maybe<Post_Comments_Aggregate_Fields>
  nodes: Array<Post_Comments>
}

export type Post_Comments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Post_Comments_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Post_Comments_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Post_Comments_Aggregate_Bool_Exp_Count>
}

export type Post_Comments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Post_Comments_Select_Column_Post_Comments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Post_Comments_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Post_Comments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Post_Comments_Select_Column_Post_Comments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Post_Comments_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Post_Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Post_Comments_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Post_Comments_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "post_comments" */
export type Post_Comments_Aggregate_Fields = {
  __typename?: 'post_comments_aggregate_fields'
  avg?: Maybe<Post_Comments_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Post_Comments_Max_Fields>
  min?: Maybe<Post_Comments_Min_Fields>
  stddev?: Maybe<Post_Comments_Stddev_Fields>
  stddev_pop?: Maybe<Post_Comments_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Post_Comments_Stddev_Samp_Fields>
  sum?: Maybe<Post_Comments_Sum_Fields>
  var_pop?: Maybe<Post_Comments_Var_Pop_Fields>
  var_samp?: Maybe<Post_Comments_Var_Samp_Fields>
  variance?: Maybe<Post_Comments_Variance_Fields>
}

/** aggregate fields of "post_comments" */
export type Post_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Comments_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "post_comments" */
export type Post_Comments_Aggregate_Order_By = {
  avg?: InputMaybe<Post_Comments_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Post_Comments_Max_Order_By>
  min?: InputMaybe<Post_Comments_Min_Order_By>
  stddev?: InputMaybe<Post_Comments_Stddev_Order_By>
  stddev_pop?: InputMaybe<Post_Comments_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Post_Comments_Stddev_Samp_Order_By>
  sum?: InputMaybe<Post_Comments_Sum_Order_By>
  var_pop?: InputMaybe<Post_Comments_Var_Pop_Order_By>
  var_samp?: InputMaybe<Post_Comments_Var_Samp_Order_By>
  variance?: InputMaybe<Post_Comments_Variance_Order_By>
}

/** input type for inserting array relation for remote table "post_comments" */
export type Post_Comments_Arr_Rel_Insert_Input = {
  data: Array<Post_Comments_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Comments_On_Conflict>
}

/** aggregate avg on columns */
export type Post_Comments_Avg_Fields = {
  __typename?: 'post_comments_avg_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "post_comments" */
export type Post_Comments_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "post_comments". All fields are combined with a logical 'AND'. */
export type Post_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Comments_Bool_Exp>>
  _not?: InputMaybe<Post_Comments_Bool_Exp>
  _or?: InputMaybe<Array<Post_Comments_Bool_Exp>>
  body?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  creator?: InputMaybe<Users_Bool_Exp>
  hidden?: InputMaybe<Boolean_Comparison_Exp>
  id?: InputMaybe<Int_Comparison_Exp>
  post?: InputMaybe<Posts_Bool_Exp>
  post_id?: InputMaybe<Int_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
  updater?: InputMaybe<Users_Bool_Exp>
}

/** unique or primary key constraints on table "post_comments" */
export enum Post_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostCommentsPkey = 'post_comments_pkey',
}

/** input type for incrementing numeric columns in table "post_comments" */
export type Post_Comments_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  post_id?: InputMaybe<Scalars['Int']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "post_comments" */
export type Post_Comments_Insert_Input = {
  body?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>
  hidden?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['Int']>
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>
  post_id?: InputMaybe<Scalars['Int']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  updater?: InputMaybe<Users_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Post_Comments_Max_Fields = {
  __typename?: 'post_comments_max_fields'
  body?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  post_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "post_comments" */
export type Post_Comments_Max_Order_By = {
  body?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Post_Comments_Min_Fields = {
  __typename?: 'post_comments_min_fields'
  body?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  post_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "post_comments" */
export type Post_Comments_Min_Order_By = {
  body?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** response of any mutation on the table "post_comments" */
export type Post_Comments_Mutation_Response = {
  __typename?: 'post_comments_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Post_Comments>
}

/** on_conflict condition type for table "post_comments" */
export type Post_Comments_On_Conflict = {
  constraint: Post_Comments_Constraint
  update_columns?: Array<Post_Comments_Update_Column>
  where?: InputMaybe<Post_Comments_Bool_Exp>
}

/** Ordering options when selecting data from "post_comments". */
export type Post_Comments_Order_By = {
  body?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  creator?: InputMaybe<Users_Order_By>
  hidden?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post?: InputMaybe<Posts_Order_By>
  post_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  updater?: InputMaybe<Users_Order_By>
}

/** primary key columns input for table: post_comments */
export type Post_Comments_Pk_Columns_Input = {
  id: Scalars['Int']
}

/** select columns of table "post_comments" */
export enum Post_Comments_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** select "post_comments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "post_comments" */
export enum Post_Comments_Select_Column_Post_Comments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
}

/** select "post_comments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "post_comments" */
export enum Post_Comments_Select_Column_Post_Comments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
}

/** input type for updating data in table "post_comments" */
export type Post_Comments_Set_Input = {
  body?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  hidden?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['Int']>
  post_id?: InputMaybe<Scalars['Int']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Post_Comments_Stddev_Fields = {
  __typename?: 'post_comments_stddev_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "post_comments" */
export type Post_Comments_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Post_Comments_Stddev_Pop_Fields = {
  __typename?: 'post_comments_stddev_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "post_comments" */
export type Post_Comments_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Post_Comments_Stddev_Samp_Fields = {
  __typename?: 'post_comments_stddev_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "post_comments" */
export type Post_Comments_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "post_comments" */
export type Post_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Comments_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Post_Comments_Stream_Cursor_Value_Input = {
  body?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  hidden?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['Int']>
  post_id?: InputMaybe<Scalars['Int']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Post_Comments_Sum_Fields = {
  __typename?: 'post_comments_sum_fields'
  created_by?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  post_id?: Maybe<Scalars['Int']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "post_comments" */
export type Post_Comments_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** update columns of table "post_comments" */
export enum Post_Comments_Update_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Post_Comments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Post_Comments_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Comments_Set_Input>
  /** filter the rows which have to be updated */
  where: Post_Comments_Bool_Exp
}

/** aggregate var_pop on columns */
export type Post_Comments_Var_Pop_Fields = {
  __typename?: 'post_comments_var_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "post_comments" */
export type Post_Comments_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Post_Comments_Var_Samp_Fields = {
  __typename?: 'post_comments_var_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "post_comments" */
export type Post_Comments_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Post_Comments_Variance_Fields = {
  __typename?: 'post_comments_variance_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  post_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "post_comments" */
export type Post_Comments_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  post_id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** columns and relationships of "posts" */
export type Posts = {
  __typename?: 'posts'
  allow_comments?: Maybe<Scalars['Boolean']>
  attachment_url?: Maybe<Scalars['String']>
  body: Scalars['jsonb']
  /** An array relationship */
  categories: Array<Post_Categories>
  /** An aggregate relationship */
  categories_aggregate: Post_Categories_Aggregate
  /** An array relationship */
  comments: Array<Post_Comments>
  /** An aggregate relationship */
  comments_aggregate: Post_Comments_Aggregate
  created_at: Scalars['timestamptz']
  created_by?: Maybe<Scalars['Int']>
  /** An object relationship */
  creator?: Maybe<Users>
  /** The blog post display date */
  date?: Maybe<Scalars['timestamptz']>
  excerpt?: Maybe<Scalars['String']>
  guid?: Maybe<Scalars['String']>
  id: Scalars['Int']
  /** An object relationship */
  image?: Maybe<Resources>
  image_id?: Maybe<Scalars['Int']>
  published: Scalars['Boolean']
  published_at?: Maybe<Scalars['timestamptz']>
  published_by?: Maybe<Scalars['Int']>
  /** An object relationship */
  publisher?: Maybe<Users>
  /** An array relationship */
  resources: Array<Resource_Dependents>
  /** An aggregate relationship */
  resources_aggregate: Resource_Dependents_Aggregate
  show_feature_image: Scalars['Boolean']
  slug: Scalars['String']
  title: Scalars['String']
  updated_at: Scalars['timestamptz']
  updated_by?: Maybe<Scalars['Int']>
  /** An object relationship */
  updater?: Maybe<Users>
}

/** columns and relationships of "posts" */
export type PostsBodyArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "posts" */
export type PostsCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Post_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Categories_Order_By>>
  where?: InputMaybe<Post_Categories_Bool_Exp>
}

/** columns and relationships of "posts" */
export type PostsCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Categories_Order_By>>
  where?: InputMaybe<Post_Categories_Bool_Exp>
}

/** columns and relationships of "posts" */
export type PostsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Comments_Order_By>>
  where?: InputMaybe<Post_Comments_Bool_Exp>
}

/** columns and relationships of "posts" */
export type PostsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Comments_Order_By>>
  where?: InputMaybe<Post_Comments_Bool_Exp>
}

/** columns and relationships of "posts" */
export type PostsResourcesArgs = {
  distinct_on?: InputMaybe<Array<Resource_Dependents_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resource_Dependents_Order_By>>
  where?: InputMaybe<Resource_Dependents_Bool_Exp>
}

/** columns and relationships of "posts" */
export type PostsResources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Resource_Dependents_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resource_Dependents_Order_By>>
  where?: InputMaybe<Resource_Dependents_Bool_Exp>
}

/** aggregated selection of "posts" */
export type Posts_Aggregate = {
  __typename?: 'posts_aggregate'
  aggregate?: Maybe<Posts_Aggregate_Fields>
  nodes: Array<Posts>
}

export type Posts_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Posts_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<Posts_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<Posts_Aggregate_Bool_Exp_Count>
}

export type Posts_Aggregate_Bool_Exp_Bool_And = {
  arguments: Posts_Select_Column_Posts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Posts_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Posts_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Posts_Select_Column_Posts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Posts_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Posts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Posts_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Posts_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "posts" */
export type Posts_Aggregate_Fields = {
  __typename?: 'posts_aggregate_fields'
  avg?: Maybe<Posts_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Posts_Max_Fields>
  min?: Maybe<Posts_Min_Fields>
  stddev?: Maybe<Posts_Stddev_Fields>
  stddev_pop?: Maybe<Posts_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Posts_Stddev_Samp_Fields>
  sum?: Maybe<Posts_Sum_Fields>
  var_pop?: Maybe<Posts_Var_Pop_Fields>
  var_samp?: Maybe<Posts_Var_Samp_Fields>
  variance?: Maybe<Posts_Variance_Fields>
}

/** aggregate fields of "posts" */
export type Posts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Posts_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "posts" */
export type Posts_Aggregate_Order_By = {
  avg?: InputMaybe<Posts_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Posts_Max_Order_By>
  min?: InputMaybe<Posts_Min_Order_By>
  stddev?: InputMaybe<Posts_Stddev_Order_By>
  stddev_pop?: InputMaybe<Posts_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Posts_Stddev_Samp_Order_By>
  sum?: InputMaybe<Posts_Sum_Order_By>
  var_pop?: InputMaybe<Posts_Var_Pop_Order_By>
  var_samp?: InputMaybe<Posts_Var_Samp_Order_By>
  variance?: InputMaybe<Posts_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Posts_Append_Input = {
  body?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "posts" */
export type Posts_Arr_Rel_Insert_Input = {
  data: Array<Posts_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_On_Conflict>
}

/** aggregate avg on columns */
export type Posts_Avg_Fields = {
  __typename?: 'posts_avg_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "posts" */
export type Posts_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "posts". All fields are combined with a logical 'AND'. */
export type Posts_Bool_Exp = {
  _and?: InputMaybe<Array<Posts_Bool_Exp>>
  _not?: InputMaybe<Posts_Bool_Exp>
  _or?: InputMaybe<Array<Posts_Bool_Exp>>
  allow_comments?: InputMaybe<Boolean_Comparison_Exp>
  attachment_url?: InputMaybe<String_Comparison_Exp>
  body?: InputMaybe<Jsonb_Comparison_Exp>
  categories?: InputMaybe<Post_Categories_Bool_Exp>
  categories_aggregate?: InputMaybe<Post_Categories_Aggregate_Bool_Exp>
  comments?: InputMaybe<Post_Comments_Bool_Exp>
  comments_aggregate?: InputMaybe<Post_Comments_Aggregate_Bool_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  creator?: InputMaybe<Users_Bool_Exp>
  date?: InputMaybe<Timestamptz_Comparison_Exp>
  excerpt?: InputMaybe<String_Comparison_Exp>
  guid?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Int_Comparison_Exp>
  image?: InputMaybe<Resources_Bool_Exp>
  image_id?: InputMaybe<Int_Comparison_Exp>
  published?: InputMaybe<Boolean_Comparison_Exp>
  published_at?: InputMaybe<Timestamptz_Comparison_Exp>
  published_by?: InputMaybe<Int_Comparison_Exp>
  publisher?: InputMaybe<Users_Bool_Exp>
  resources?: InputMaybe<Resource_Dependents_Bool_Exp>
  resources_aggregate?: InputMaybe<Resource_Dependents_Aggregate_Bool_Exp>
  show_feature_image?: InputMaybe<Boolean_Comparison_Exp>
  slug?: InputMaybe<String_Comparison_Exp>
  title?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
  updater?: InputMaybe<Users_Bool_Exp>
}

/** unique or primary key constraints on table "posts" */
export enum Posts_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostsPkey = 'posts_pkey',
  /** unique or primary key constraint on columns "slug" */
  PostsSlugKey = 'posts_slug_key',
  /** unique or primary key constraint on columns "title" */
  PostsTitleKey = 'posts_title_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Posts_Delete_At_Path_Input = {
  body?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Posts_Delete_Elem_Input = {
  body?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Posts_Delete_Key_Input = {
  body?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "posts" */
export type Posts_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  image_id?: InputMaybe<Scalars['Int']>
  published_by?: InputMaybe<Scalars['Int']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "posts" */
export type Posts_Insert_Input = {
  allow_comments?: InputMaybe<Scalars['Boolean']>
  attachment_url?: InputMaybe<Scalars['String']>
  body?: InputMaybe<Scalars['jsonb']>
  categories?: InputMaybe<Post_Categories_Arr_Rel_Insert_Input>
  comments?: InputMaybe<Post_Comments_Arr_Rel_Insert_Input>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>
  /** The blog post display date */
  date?: InputMaybe<Scalars['timestamptz']>
  excerpt?: InputMaybe<Scalars['String']>
  guid?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  image?: InputMaybe<Resources_Obj_Rel_Insert_Input>
  image_id?: InputMaybe<Scalars['Int']>
  published?: InputMaybe<Scalars['Boolean']>
  published_at?: InputMaybe<Scalars['timestamptz']>
  published_by?: InputMaybe<Scalars['Int']>
  publisher?: InputMaybe<Users_Obj_Rel_Insert_Input>
  resources?: InputMaybe<Resource_Dependents_Arr_Rel_Insert_Input>
  show_feature_image?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  updater?: InputMaybe<Users_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Posts_Max_Fields = {
  __typename?: 'posts_max_fields'
  attachment_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  /** The blog post display date */
  date?: Maybe<Scalars['timestamptz']>
  excerpt?: Maybe<Scalars['String']>
  guid?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  image_id?: Maybe<Scalars['Int']>
  published_at?: Maybe<Scalars['timestamptz']>
  published_by?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "posts" */
export type Posts_Max_Order_By = {
  attachment_url?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  /** The blog post display date */
  date?: InputMaybe<Order_By>
  excerpt?: InputMaybe<Order_By>
  guid?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  published_at?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Posts_Min_Fields = {
  __typename?: 'posts_min_fields'
  attachment_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  /** The blog post display date */
  date?: Maybe<Scalars['timestamptz']>
  excerpt?: Maybe<Scalars['String']>
  guid?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  image_id?: Maybe<Scalars['Int']>
  published_at?: Maybe<Scalars['timestamptz']>
  published_by?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "posts" */
export type Posts_Min_Order_By = {
  attachment_url?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  /** The blog post display date */
  date?: InputMaybe<Order_By>
  excerpt?: InputMaybe<Order_By>
  guid?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  published_at?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** response of any mutation on the table "posts" */
export type Posts_Mutation_Response = {
  __typename?: 'posts_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Posts>
}

/** input type for inserting object relation for remote table "posts" */
export type Posts_Obj_Rel_Insert_Input = {
  data: Posts_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_On_Conflict>
}

/** on_conflict condition type for table "posts" */
export type Posts_On_Conflict = {
  constraint: Posts_Constraint
  update_columns?: Array<Posts_Update_Column>
  where?: InputMaybe<Posts_Bool_Exp>
}

/** Ordering options when selecting data from "posts". */
export type Posts_Order_By = {
  allow_comments?: InputMaybe<Order_By>
  attachment_url?: InputMaybe<Order_By>
  body?: InputMaybe<Order_By>
  categories_aggregate?: InputMaybe<Post_Categories_Aggregate_Order_By>
  comments_aggregate?: InputMaybe<Post_Comments_Aggregate_Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  creator?: InputMaybe<Users_Order_By>
  date?: InputMaybe<Order_By>
  excerpt?: InputMaybe<Order_By>
  guid?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image?: InputMaybe<Resources_Order_By>
  image_id?: InputMaybe<Order_By>
  published?: InputMaybe<Order_By>
  published_at?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  publisher?: InputMaybe<Users_Order_By>
  resources_aggregate?: InputMaybe<Resource_Dependents_Aggregate_Order_By>
  show_feature_image?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  updater?: InputMaybe<Users_Order_By>
}

/** primary key columns input for table: posts */
export type Posts_Pk_Columns_Input = {
  id: Scalars['Int']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Posts_Prepend_Input = {
  body?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "posts" */
export enum Posts_Select_Column {
  /** column name */
  AllowComments = 'allow_comments',
  /** column name */
  AttachmentUrl = 'attachment_url',
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Excerpt = 'excerpt',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  PublishedBy = 'published_by',
  /** column name */
  ShowFeatureImage = 'show_feature_image',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** select "posts_aggregate_bool_exp_bool_and_arguments_columns" columns of table "posts" */
export enum Posts_Select_Column_Posts_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllowComments = 'allow_comments',
  /** column name */
  Published = 'published',
  /** column name */
  ShowFeatureImage = 'show_feature_image',
}

/** select "posts_aggregate_bool_exp_bool_or_arguments_columns" columns of table "posts" */
export enum Posts_Select_Column_Posts_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllowComments = 'allow_comments',
  /** column name */
  Published = 'published',
  /** column name */
  ShowFeatureImage = 'show_feature_image',
}

/** input type for updating data in table "posts" */
export type Posts_Set_Input = {
  allow_comments?: InputMaybe<Scalars['Boolean']>
  attachment_url?: InputMaybe<Scalars['String']>
  body?: InputMaybe<Scalars['jsonb']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  /** The blog post display date */
  date?: InputMaybe<Scalars['timestamptz']>
  excerpt?: InputMaybe<Scalars['String']>
  guid?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  image_id?: InputMaybe<Scalars['Int']>
  published?: InputMaybe<Scalars['Boolean']>
  published_at?: InputMaybe<Scalars['timestamptz']>
  published_by?: InputMaybe<Scalars['Int']>
  show_feature_image?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Posts_Stddev_Fields = {
  __typename?: 'posts_stddev_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "posts" */
export type Posts_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Posts_Stddev_Pop_Fields = {
  __typename?: 'posts_stddev_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "posts" */
export type Posts_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Posts_Stddev_Samp_Fields = {
  __typename?: 'posts_stddev_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "posts" */
export type Posts_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "posts" */
export type Posts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Posts_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Posts_Stream_Cursor_Value_Input = {
  allow_comments?: InputMaybe<Scalars['Boolean']>
  attachment_url?: InputMaybe<Scalars['String']>
  body?: InputMaybe<Scalars['jsonb']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  /** The blog post display date */
  date?: InputMaybe<Scalars['timestamptz']>
  excerpt?: InputMaybe<Scalars['String']>
  guid?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  image_id?: InputMaybe<Scalars['Int']>
  published?: InputMaybe<Scalars['Boolean']>
  published_at?: InputMaybe<Scalars['timestamptz']>
  published_by?: InputMaybe<Scalars['Int']>
  show_feature_image?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Posts_Sum_Fields = {
  __typename?: 'posts_sum_fields'
  created_by?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  image_id?: Maybe<Scalars['Int']>
  published_by?: Maybe<Scalars['Int']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "posts" */
export type Posts_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** update columns of table "posts" */
export enum Posts_Update_Column {
  /** column name */
  AllowComments = 'allow_comments',
  /** column name */
  AttachmentUrl = 'attachment_url',
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Excerpt = 'excerpt',
  /** column name */
  Guid = 'guid',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  Published = 'published',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  PublishedBy = 'published_by',
  /** column name */
  ShowFeatureImage = 'show_feature_image',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Posts_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Posts_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Posts_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Posts_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Posts_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Posts_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Posts_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Posts_Set_Input>
  /** filter the rows which have to be updated */
  where: Posts_Bool_Exp
}

/** aggregate var_pop on columns */
export type Posts_Var_Pop_Fields = {
  __typename?: 'posts_var_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "posts" */
export type Posts_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Posts_Var_Samp_Fields = {
  __typename?: 'posts_var_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "posts" */
export type Posts_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Posts_Variance_Fields = {
  __typename?: 'posts_variance_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  image_id?: Maybe<Scalars['Float']>
  published_by?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "posts" */
export type Posts_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  image_id?: InputMaybe<Order_By>
  published_by?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

export type Query_Root = {
  __typename?: 'query_root'
  /** fetch data from the table: "categories" */
  categories: Array<Categories>
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>
  /** fetch data from the table: "form_responses" */
  form_responses: Array<Form_Responses>
  /** fetch aggregated fields from the table: "form_responses" */
  form_responses_aggregate: Form_Responses_Aggregate
  /** fetch data from the table: "form_responses" using primary key columns */
  form_responses_by_pk?: Maybe<Form_Responses>
  /** fetch data from the table: "forms" */
  forms: Array<Forms>
  /** fetch aggregated fields from the table: "forms" */
  forms_aggregate: Forms_Aggregate
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>
  /** fetch data from the table: "groups" */
  groups: Array<Groups>
  /** fetch aggregated fields from the table: "groups" */
  groups_aggregate: Groups_Aggregate
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>
  /** fetch data from the table: "learning_featured_modules" */
  learning_featured_modules: Array<Learning_Featured_Modules>
  /** fetch aggregated fields from the table: "learning_featured_modules" */
  learning_featured_modules_aggregate: Learning_Featured_Modules_Aggregate
  /** fetch data from the table: "learning_featured_modules" using primary key columns */
  learning_featured_modules_by_pk?: Maybe<Learning_Featured_Modules>
  /** fetch data from the table: "learning_learner_event_log" */
  learning_learner_event_log: Array<Learning_Learner_Event_Log>
  /** fetch aggregated fields from the table: "learning_learner_event_log" */
  learning_learner_event_log_aggregate: Learning_Learner_Event_Log_Aggregate
  /** fetch data from the table: "learning_learner_event_log" using primary key columns */
  learning_learner_event_log_by_pk?: Maybe<Learning_Learner_Event_Log>
  /** fetch data from the table: "learning_learner_module_rating_questions" */
  learning_learner_module_rating_questions: Array<Learning_Learner_Module_Rating_Questions>
  /** fetch aggregated fields from the table: "learning_learner_module_rating_questions" */
  learning_learner_module_rating_questions_aggregate: Learning_Learner_Module_Rating_Questions_Aggregate
  /** fetch data from the table: "learning_learner_module_rating_questions" using primary key columns */
  learning_learner_module_rating_questions_by_pk?: Maybe<Learning_Learner_Module_Rating_Questions>
  /** fetch data from the table: "learning_learner_module_rating_responses" */
  learning_learner_module_rating_responses: Array<Learning_Learner_Module_Rating_Responses>
  /** fetch aggregated fields from the table: "learning_learner_module_rating_responses" */
  learning_learner_module_rating_responses_aggregate: Learning_Learner_Module_Rating_Responses_Aggregate
  /** fetch data from the table: "learning_learner_module_rating_responses" using primary key columns */
  learning_learner_module_rating_responses_by_pk?: Maybe<Learning_Learner_Module_Rating_Responses>
  /** fetch data from the table: "learning_learner_module_ratings" */
  learning_learner_module_ratings: Array<Learning_Learner_Module_Ratings>
  /** fetch aggregated fields from the table: "learning_learner_module_ratings" */
  learning_learner_module_ratings_aggregate: Learning_Learner_Module_Ratings_Aggregate
  /** fetch data from the table: "learning_learner_module_ratings" using primary key columns */
  learning_learner_module_ratings_by_pk?: Maybe<Learning_Learner_Module_Ratings>
  /** fetch data from the table: "learning_learner_module_star_ratings" */
  learning_learner_module_star_ratings: Array<Learning_Learner_Module_Star_Ratings>
  /** fetch aggregated fields from the table: "learning_learner_module_star_ratings" */
  learning_learner_module_star_ratings_aggregate: Learning_Learner_Module_Star_Ratings_Aggregate
  /** fetch data from the table: "learning_learner_module_star_ratings" using primary key columns */
  learning_learner_module_star_ratings_by_pk?: Maybe<Learning_Learner_Module_Star_Ratings>
  /** fetch data from the table: "learning_learner_reflections" */
  learning_learner_reflections: Array<Learning_Learner_Reflections>
  /** fetch aggregated fields from the table: "learning_learner_reflections" */
  learning_learner_reflections_aggregate: Learning_Learner_Reflections_Aggregate
  /** fetch data from the table: "learning_learner_reflections" using primary key columns */
  learning_learner_reflections_by_pk?: Maybe<Learning_Learner_Reflections>
  /** fetch data from the table: "learning_learner_session_events" */
  learning_learner_session_events: Array<Learning_Learner_Session_Events>
  /** fetch aggregated fields from the table: "learning_learner_session_events" */
  learning_learner_session_events_aggregate: Learning_Learner_Session_Events_Aggregate
  /** execute function "learning_learner_session_events_by_period_within_range" which returns "learning_learner_session_events" */
  learning_learner_session_events_by_period_within_range: Array<Learning_Learner_Session_Events>
  /** execute function "learning_learner_session_events_by_period_within_range" and query aggregates on result of table type "learning_learner_session_events" */
  learning_learner_session_events_by_period_within_range_aggregate: Learning_Learner_Session_Events_Aggregate
  /** execute function "learning_learner_session_events_within_range" which returns "learning_learner_session_events" */
  learning_learner_session_events_within_range: Array<Learning_Learner_Session_Events>
  /** execute function "learning_learner_session_events_within_range" and query aggregates on result of table type "learning_learner_session_events" */
  learning_learner_session_events_within_range_aggregate: Learning_Learner_Session_Events_Aggregate
  /** fetch data from the table: "learning_learner_session_feedback" */
  learning_learner_session_feedback: Array<Learning_Learner_Session_Feedback>
  /** fetch aggregated fields from the table: "learning_learner_session_feedback" */
  learning_learner_session_feedback_aggregate: Learning_Learner_Session_Feedback_Aggregate
  /** fetch data from the table: "learning_learner_sessions" */
  learning_learner_sessions: Array<Learning_Learner_Sessions>
  /** fetch aggregated fields from the table: "learning_learner_sessions" */
  learning_learner_sessions_aggregate: Learning_Learner_Sessions_Aggregate
  /** fetch data from the table: "learning_learner_sessions" using primary key columns */
  learning_learner_sessions_by_pk?: Maybe<Learning_Learner_Sessions>
  /** fetch data from the table: "learning_module_event_log" */
  learning_module_event_log: Array<Learning_Module_Event_Log>
  /** fetch aggregated fields from the table: "learning_module_event_log" */
  learning_module_event_log_aggregate: Learning_Module_Event_Log_Aggregate
  /** fetch data from the table: "learning_module_event_log" using primary key columns */
  learning_module_event_log_by_pk?: Maybe<Learning_Module_Event_Log>
  /** fetch data from the table: "learning_module_event_transitions" */
  learning_module_event_transitions: Array<Learning_Module_Event_Transitions>
  /** fetch aggregated fields from the table: "learning_module_event_transitions" */
  learning_module_event_transitions_aggregate: Learning_Module_Event_Transitions_Aggregate
  /** fetch data from the table: "learning_module_event_transitions" using primary key columns */
  learning_module_event_transitions_by_pk?: Maybe<Learning_Module_Event_Transitions>
  /** fetch data from the table: "learning_module_exists" */
  learning_module_exists: Array<Learning_Module_Exists>
  /** fetch aggregated fields from the table: "learning_module_exists" */
  learning_module_exists_aggregate: Learning_Module_Exists_Aggregate
  /** fetch data from the table: "learning_module_history" */
  learning_module_history: Array<Learning_Module_History>
  /** fetch aggregated fields from the table: "learning_module_history" */
  learning_module_history_aggregate: Learning_Module_History_Aggregate
  /** fetch data from the table: "learning_module_history" using primary key columns */
  learning_module_history_by_pk?: Maybe<Learning_Module_History>
  /** fetch data from the table: "learning_module_revision_pages" */
  learning_module_revision_pages: Array<Learning_Module_Revision_Pages>
  /** fetch aggregated fields from the table: "learning_module_revision_pages" */
  learning_module_revision_pages_aggregate: Learning_Module_Revision_Pages_Aggregate
  /** fetch data from the table: "learning_module_revision_pages" using primary key columns */
  learning_module_revision_pages_by_pk?: Maybe<Learning_Module_Revision_Pages>
  /** fetch data from the table: "learning_module_revisions" */
  learning_module_revisions: Array<Learning_Module_Revisions>
  /** fetch aggregated fields from the table: "learning_module_revisions" */
  learning_module_revisions_aggregate: Learning_Module_Revisions_Aggregate
  /** fetch data from the table: "learning_module_revisions" using primary key columns */
  learning_module_revisions_by_pk?: Maybe<Learning_Module_Revisions>
  /** fetch data from the table: "learning_module_star_ratings" */
  learning_module_star_ratings: Array<Learning_Module_Star_Ratings>
  /** fetch aggregated fields from the table: "learning_module_star_ratings" */
  learning_module_star_ratings_aggregate: Learning_Module_Star_Ratings_Aggregate
  /** fetch data from the table: "learning_pages" using primary key columns */
  learning_page_by_pk?: Maybe<Learning_Pages>
  /** fetch data from the table: "learning_pages" */
  learning_pages: Array<Learning_Pages>
  /** fetch aggregated fields from the table: "learning_pages" */
  learning_pages_aggregate: Learning_Pages_Aggregate
  /** fetch data from the table: "learning_questions" */
  learning_questions: Array<Learning_Questions>
  /** fetch aggregated fields from the table: "learning_questions" */
  learning_questions_aggregate: Learning_Questions_Aggregate
  /** fetch data from the table: "learning_questions" using primary key columns */
  learning_questions_by_pk?: Maybe<Learning_Questions>
  /** fetch data from the table: "learning_series_discount_groups" */
  learning_series_discount_groups: Array<Learning_Series_Discount_Groups>
  /** fetch aggregated fields from the table: "learning_series_discount_groups" */
  learning_series_discount_groups_aggregate: Learning_Series_Discount_Groups_Aggregate
  /** fetch data from the table: "learning_series_discount_groups" using primary key columns */
  learning_series_discount_groups_by_pk?: Maybe<Learning_Series_Discount_Groups>
  /** fetch data from the table: "learning_series_discount_settings" */
  learning_series_discount_settings: Array<Learning_Series_Discount_Settings>
  /** fetch aggregated fields from the table: "learning_series_discount_settings" */
  learning_series_discount_settings_aggregate: Learning_Series_Discount_Settings_Aggregate
  /** fetch data from the table: "learning_series_discount_settings" using primary key columns */
  learning_series_discount_settings_by_pk?: Maybe<Learning_Series_Discount_Settings>
  /** fetch data from the table: "learning_series_star_ratings" */
  learning_series_star_ratings: Array<Learning_Series_Star_Ratings>
  /** fetch aggregated fields from the table: "learning_series_star_ratings" */
  learning_series_star_ratings_aggregate: Learning_Series_Star_Ratings_Aggregate
  /** An array relationship */
  learning_series_subscription_settings: Array<Learning_Series_Subscription_Settings>
  /** An aggregate relationship */
  learning_series_subscription_settings_aggregate: Learning_Series_Subscription_Settings_Aggregate
  /** fetch data from the table: "learning_series_subscription_settings" using primary key columns */
  learning_series_subscription_settings_by_pk?: Maybe<Learning_Series_Subscription_Settings>
  /** fetch data from the table: "learning_series_subscription_settings_discount_type" */
  learning_series_subscription_settings_discount_type: Array<Learning_Series_Subscription_Settings_Discount_Type>
  /** fetch aggregated fields from the table: "learning_series_subscription_settings_discount_type" */
  learning_series_subscription_settings_discount_type_aggregate: Learning_Series_Subscription_Settings_Discount_Type_Aggregate
  /** fetch data from the table: "learning_series_subscription_settings_discount_type" using primary key columns */
  learning_series_subscription_settings_discount_type_by_pk?: Maybe<Learning_Series_Subscription_Settings_Discount_Type>
  /** fetch data from the table: "learning_star_ratings" */
  learning_star_ratings: Array<Learning_Star_Ratings>
  /** fetch aggregated fields from the table: "learning_star_ratings" */
  learning_star_ratings_aggregate: Learning_Star_Ratings_Aggregate
  /** execute function "learning_star_ratings_for_module_within_range" which returns "learning_module_star_ratings" */
  learning_star_ratings_for_module_within_range: Array<Learning_Module_Star_Ratings>
  /** execute function "learning_star_ratings_for_module_within_range" and query aggregates on result of table type "learning_module_star_ratings" */
  learning_star_ratings_for_module_within_range_aggregate: Learning_Module_Star_Ratings_Aggregate
  /** execute function "learning_star_ratings_in_library_within_range" which returns "learning_star_ratings" */
  learning_star_ratings_in_library_within_range: Array<Learning_Star_Ratings>
  /** execute function "learning_star_ratings_in_library_within_range" and query aggregates on result of table type "learning_star_ratings" */
  learning_star_ratings_in_library_within_range_aggregate: Learning_Star_Ratings_Aggregate
  /** execute function "learning_star_ratings_in_series_within_range" which returns "learning_series_star_ratings" */
  learning_star_ratings_in_series_within_range: Array<Learning_Series_Star_Ratings>
  /** execute function "learning_star_ratings_in_series_within_range" and query aggregates on result of table type "learning_series_star_ratings" */
  learning_star_ratings_in_series_within_range_aggregate: Learning_Series_Star_Ratings_Aggregate
  /** execute function "learning_star_ratings_within_range" which returns "learning_star_ratings" */
  learning_star_ratings_within_range: Array<Learning_Star_Ratings>
  /** execute function "learning_star_ratings_within_range" and query aggregates on result of table type "learning_star_ratings" */
  learning_star_ratings_within_range_aggregate: Learning_Star_Ratings_Aggregate
  /** fetch data from the table: "learning_stats_learner_module_rating_response_averages" */
  learning_stats_learner_module_rating_response_averages: Array<Learning_Stats_Learner_Module_Rating_Response_Averages>
  /** fetch aggregated fields from the table: "learning_stats_learner_module_rating_response_averages" */
  learning_stats_learner_module_rating_response_averages_aggregate: Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate
  /** execute function "learning_stats_module_rating_response_averages_within_range" which returns "learning_stats_learner_module_rating_response_averages" */
  learning_stats_module_rating_response_averages_within_range: Array<Learning_Stats_Learner_Module_Rating_Response_Averages>
  /** execute function "learning_stats_module_rating_response_averages_within_range" and query aggregates on result of table type "learning_stats_learner_module_rating_response_averages" */
  learning_stats_module_rating_response_averages_within_range_aggregate: Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate
  /** fetch data from the table: "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses: Array<Learning_Stats_Module_Rating_Responses>
  /** fetch aggregated fields from the table: "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_aggregate: Learning_Stats_Module_Rating_Responses_Aggregate
  /** execute function "learning_stats_module_rating_responses_for_module_within_range" which returns "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_for_module_within_range: Array<Learning_Stats_Module_Rating_Responses>
  /** execute function "learning_stats_module_rating_responses_for_module_within_range" and query aggregates on result of table type "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_for_module_within_range_aggregate: Learning_Stats_Module_Rating_Responses_Aggregate
  /** execute function "learning_stats_module_rating_responses_in_library_within_range" which returns "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_in_library_within_range: Array<Learning_Stats_Module_Rating_Responses>
  /** execute function "learning_stats_module_rating_responses_in_library_within_range" and query aggregates on result of table type "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_in_library_within_range_aggregate: Learning_Stats_Module_Rating_Responses_Aggregate
  /** execute function "learning_stats_module_rating_responses_in_series_within_range" which returns "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_in_series_within_range: Array<Learning_Stats_Module_Rating_Responses>
  /** execute function "learning_stats_module_rating_responses_in_series_within_range" and query aggregates on result of table type "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_in_series_within_range_aggregate: Learning_Stats_Module_Rating_Responses_Aggregate
  /** execute function "learning_stats_module_rating_responses_within_range" which returns "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_within_range: Array<Learning_Stats_Module_Rating_Responses>
  /** execute function "learning_stats_module_rating_responses_within_range" and query aggregates on result of table type "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_within_range_aggregate: Learning_Stats_Module_Rating_Responses_Aggregate
  /** execute function "learning_stats_most_active_libraries_by_period_within_range" which returns "learning_stats_sessions_by_period_by_library" */
  learning_stats_most_active_libraries_by_period_within_range: Array<Learning_Stats_Sessions_By_Period_By_Library>
  /** execute function "learning_stats_most_active_libraries_by_period_within_range" and query aggregates on result of table type "learning_stats_sessions_by_period_by_library" */
  learning_stats_most_active_libraries_by_period_within_range_aggregate: Learning_Stats_Sessions_By_Period_By_Library_Aggregate
  /** execute function "learning_stats_most_active_libraries_within_range" which returns "learning_stats_sessions_by_library" */
  learning_stats_most_active_libraries_within_range: Array<Learning_Stats_Sessions_By_Library>
  /** execute function "learning_stats_most_active_libraries_within_range" and query aggregates on result of table type "learning_stats_sessions_by_library" */
  learning_stats_most_active_libraries_within_range_aggregate: Learning_Stats_Sessions_By_Library_Aggregate
  /** execute function "learning_stats_most_active_modules_in_library_within_range" which returns "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_in_library_within_range: Array<Learning_Stats_Sessions_By_Module>
  /** execute function "learning_stats_most_active_modules_in_library_within_range" and query aggregates on result of table type "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_in_library_within_range_aggregate: Learning_Stats_Sessions_By_Module_Aggregate
  /** execute function "learning_stats_most_active_modules_in_series_within_range" which returns "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_in_series_within_range: Array<Learning_Stats_Sessions_By_Module>
  /** execute function "learning_stats_most_active_modules_in_series_within_range" and query aggregates on result of table type "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_in_series_within_range_aggregate: Learning_Stats_Sessions_By_Module_Aggregate
  /** execute function "learning_stats_most_active_modules_within_range" which returns "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_within_range: Array<Learning_Stats_Sessions_By_Module>
  /** execute function "learning_stats_most_active_modules_within_range" and query aggregates on result of table type "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_within_range_aggregate: Learning_Stats_Sessions_By_Module_Aggregate
  /** execute function "learning_stats_most_active_series_within_range" which returns "learning_stats_sessions_by_series" */
  learning_stats_most_active_series_within_range: Array<Learning_Stats_Sessions_By_Series>
  /** execute function "learning_stats_most_active_series_within_range" and query aggregates on result of table type "learning_stats_sessions_by_series" */
  learning_stats_most_active_series_within_range_aggregate: Learning_Stats_Sessions_By_Series_Aggregate
  /** execute function "learning_stats_most_active_users_for_module_within_range" which returns "learning_stats_sessions_by_module_by_user" */
  learning_stats_most_active_users_for_module_within_range: Array<Learning_Stats_Sessions_By_Module_By_User>
  /** execute function "learning_stats_most_active_users_for_module_within_range" and query aggregates on result of table type "learning_stats_sessions_by_module_by_user" */
  learning_stats_most_active_users_for_module_within_range_aggregate: Learning_Stats_Sessions_By_Module_By_User_Aggregate
  /** execute function "learning_stats_most_active_users_in_library_within_range" which returns "learning_stats_sessions_by_library_by_user" */
  learning_stats_most_active_users_in_library_within_range: Array<Learning_Stats_Sessions_By_Library_By_User>
  /** execute function "learning_stats_most_active_users_in_library_within_range" and query aggregates on result of table type "learning_stats_sessions_by_library_by_user" */
  learning_stats_most_active_users_in_library_within_range_aggregate: Learning_Stats_Sessions_By_Library_By_User_Aggregate
  /** execute function "learning_stats_most_active_users_in_series_within_range" which returns "learning_stats_sessions_by_series_by_user" */
  learning_stats_most_active_users_in_series_within_range: Array<Learning_Stats_Sessions_By_Series_By_User>
  /** execute function "learning_stats_most_active_users_in_series_within_range" and query aggregates on result of table type "learning_stats_sessions_by_series_by_user" */
  learning_stats_most_active_users_in_series_within_range_aggregate: Learning_Stats_Sessions_By_Series_By_User_Aggregate
  /** execute function "learning_stats_most_active_users_within_range" which returns "learning_stats_sessions_by_user" */
  learning_stats_most_active_users_within_range: Array<Learning_Stats_Sessions_By_User>
  /** execute function "learning_stats_most_active_users_within_range" and query aggregates on result of table type "learning_stats_sessions_by_user" */
  learning_stats_most_active_users_within_range_aggregate: Learning_Stats_Sessions_By_User_Aggregate
  /** fetch data from the table: "learning_stats_rating_response_averages" */
  learning_stats_rating_response_averages: Array<Learning_Stats_Rating_Response_Averages>
  /** fetch aggregated fields from the table: "learning_stats_rating_response_averages" */
  learning_stats_rating_response_averages_aggregate: Learning_Stats_Rating_Response_Averages_Aggregate
  /** execute function "learning_stats_rating_response_averages_within_range" which returns "learning_stats_rating_response_averages" */
  learning_stats_rating_response_averages_within_range: Array<Learning_Stats_Rating_Response_Averages>
  /** execute function "learning_stats_rating_response_averages_within_range" and query aggregates on result of table type "learning_stats_rating_response_averages" */
  learning_stats_rating_response_averages_within_range_aggregate: Learning_Stats_Rating_Response_Averages_Aggregate
  /** fetch data from the table: "learning_stats_rating_responses" */
  learning_stats_rating_responses: Array<Learning_Stats_Rating_Responses>
  /** fetch aggregated fields from the table: "learning_stats_rating_responses" */
  learning_stats_rating_responses_aggregate: Learning_Stats_Rating_Responses_Aggregate
  /** execute function "learning_stats_rating_responses_in_library_within_range" which returns "learning_stats_rating_responses" */
  learning_stats_rating_responses_in_library_within_range: Array<Learning_Stats_Rating_Responses>
  /** execute function "learning_stats_rating_responses_in_library_within_range" and query aggregates on result of table type "learning_stats_rating_responses" */
  learning_stats_rating_responses_in_library_within_range_aggregate: Learning_Stats_Rating_Responses_Aggregate
  /** execute function "learning_stats_rating_responses_in_series_within_range" which returns "learning_stats_rating_responses" */
  learning_stats_rating_responses_in_series_within_range: Array<Learning_Stats_Rating_Responses>
  /** execute function "learning_stats_rating_responses_in_series_within_range" and query aggregates on result of table type "learning_stats_rating_responses" */
  learning_stats_rating_responses_in_series_within_range_aggregate: Learning_Stats_Rating_Responses_Aggregate
  /** execute function "learning_stats_rating_responses_within_range" which returns "learning_stats_rating_responses" */
  learning_stats_rating_responses_within_range: Array<Learning_Stats_Rating_Responses>
  /** execute function "learning_stats_rating_responses_within_range" and query aggregates on result of table type "learning_stats_rating_responses" */
  learning_stats_rating_responses_within_range_aggregate: Learning_Stats_Rating_Responses_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_library" */
  learning_stats_sessions_by_library: Array<Learning_Stats_Sessions_By_Library>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_library" */
  learning_stats_sessions_by_library_aggregate: Learning_Stats_Sessions_By_Library_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_library_by_module" */
  learning_stats_sessions_by_library_by_module: Array<Learning_Stats_Sessions_By_Library_By_Module>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_library_by_module" */
  learning_stats_sessions_by_library_by_module_aggregate: Learning_Stats_Sessions_By_Library_By_Module_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_library_by_user" */
  learning_stats_sessions_by_library_by_user: Array<Learning_Stats_Sessions_By_Library_By_User>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_library_by_user" */
  learning_stats_sessions_by_library_by_user_aggregate: Learning_Stats_Sessions_By_Library_By_User_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_module" */
  learning_stats_sessions_by_module: Array<Learning_Stats_Sessions_By_Module>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_module" */
  learning_stats_sessions_by_module_aggregate: Learning_Stats_Sessions_By_Module_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_module_by_user" */
  learning_stats_sessions_by_module_by_user: Array<Learning_Stats_Sessions_By_Module_By_User>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_module_by_user" */
  learning_stats_sessions_by_module_by_user_aggregate: Learning_Stats_Sessions_By_Module_By_User_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_period_by_library" */
  learning_stats_sessions_by_period_by_library: Array<Learning_Stats_Sessions_By_Period_By_Library>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_period_by_library" */
  learning_stats_sessions_by_period_by_library_aggregate: Learning_Stats_Sessions_By_Period_By_Library_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_series" */
  learning_stats_sessions_by_series: Array<Learning_Stats_Sessions_By_Series>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_series" */
  learning_stats_sessions_by_series_aggregate: Learning_Stats_Sessions_By_Series_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_series_by_module" */
  learning_stats_sessions_by_series_by_module: Array<Learning_Stats_Sessions_By_Series_By_Module>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_series_by_module" */
  learning_stats_sessions_by_series_by_module_aggregate: Learning_Stats_Sessions_By_Series_By_Module_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_series_by_user" */
  learning_stats_sessions_by_series_by_user: Array<Learning_Stats_Sessions_By_Series_By_User>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_series_by_user" */
  learning_stats_sessions_by_series_by_user_aggregate: Learning_Stats_Sessions_By_Series_By_User_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_user" */
  learning_stats_sessions_by_user: Array<Learning_Stats_Sessions_By_User>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_user" */
  learning_stats_sessions_by_user_aggregate: Learning_Stats_Sessions_By_User_Aggregate
  /** fetch data from the table: "learning_uploads" */
  learning_uploads: Array<Learning_Uploads>
  /** fetch aggregated fields from the table: "learning_uploads" */
  learning_uploads_aggregate: Learning_Uploads_Aggregate
  /** fetch data from the table: "learning_uploads" using primary key columns */
  learning_uploads_by_pk?: Maybe<Learning_Uploads>
  /** fetch data from the table: "learning_modules" using primary key columns */
  module_by_pk?: Maybe<Learning_Modules>
  /** An array relationship */
  modules: Array<Learning_Modules>
  /** An aggregate relationship */
  modules_aggregate: Learning_Modules_Aggregate
  /** An array relationship */
  page_categories: Array<Page_Categories>
  /** An aggregate relationship */
  page_categories_aggregate: Page_Categories_Aggregate
  /** fetch data from the table: "page_categories" using primary key columns */
  page_categories_by_pk?: Maybe<Page_Categories>
  /** fetch data from the table: "pages" */
  pages: Array<Pages>
  /** fetch aggregated fields from the table: "pages" */
  pages_aggregate: Pages_Aggregate
  /** fetch data from the table: "pages" using primary key columns */
  pages_by_pk?: Maybe<Pages>
  /** fetch data from the table: "post_categories" */
  post_categories: Array<Post_Categories>
  /** fetch aggregated fields from the table: "post_categories" */
  post_categories_aggregate: Post_Categories_Aggregate
  /** fetch data from the table: "post_categories" using primary key columns */
  post_categories_by_pk?: Maybe<Post_Categories>
  /** fetch data from the table: "post_comments" */
  post_comments: Array<Post_Comments>
  /** fetch aggregated fields from the table: "post_comments" */
  post_comments_aggregate: Post_Comments_Aggregate
  /** fetch data from the table: "post_comments" using primary key columns */
  post_comments_by_pk?: Maybe<Post_Comments>
  /** An array relationship */
  posts: Array<Posts>
  /** An aggregate relationship */
  posts_aggregate: Posts_Aggregate
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>
  /** fetch data from the table: "resource_dependents" */
  resource_dependents: Array<Resource_Dependents>
  /** fetch aggregated fields from the table: "resource_dependents" */
  resource_dependents_aggregate: Resource_Dependents_Aggregate
  /** An array relationship */
  resources: Array<Resources>
  /** An aggregate relationship */
  resources_aggregate: Resources_Aggregate
  /** fetch data from the table: "resources" using primary key columns */
  resources_by_pk?: Maybe<Resources>
  /** execute function "search_modules" which returns "learning_modules" */
  search_modules: Array<Learning_Modules>
  /** execute function "search_modules" and query aggregates on result of table type "learning_modules" */
  search_modules_aggregate: Learning_Modules_Aggregate
  /** fetch data from the table: "segment_user" */
  segment_user: Array<Segment_User>
  /** fetch aggregated fields from the table: "segment_user" */
  segment_user_aggregate: Segment_User_Aggregate
  /** fetch data from the table: "segment_user" using primary key columns */
  segment_user_by_pk?: Maybe<Segment_User>
  /** An array relationship */
  series: Array<Learning_Series>
  /** An aggregate relationship */
  series_aggregate: Learning_Series_Aggregate
  /** fetch data from the table: "learning_series" using primary key columns */
  series_by_pk?: Maybe<Learning_Series>
  /** fetch data from the table: "slugs" */
  slugs: Array<Slugs>
  /** fetch aggregated fields from the table: "slugs" */
  slugs_aggregate: Slugs_Aggregate
  /** fetch data from the table: "slugs" using primary key columns */
  slugs_by_pk?: Maybe<Slugs>
  /** fetch data from the table: "user_group_members" */
  user_group_members: Array<User_Group_Members>
  /** fetch aggregated fields from the table: "user_group_members" */
  user_group_members_aggregate: User_Group_Members_Aggregate
  /** fetch data from the table: "user_group_members" using primary key columns */
  user_group_members_by_pk?: Maybe<User_Group_Members>
  /** fetch data from the table: "user_group_members_roles" */
  user_group_members_roles: Array<User_Group_Members_Roles>
  /** fetch aggregated fields from the table: "user_group_members_roles" */
  user_group_members_roles_aggregate: User_Group_Members_Roles_Aggregate
  /** fetch data from the table: "user_group_members_roles" using primary key columns */
  user_group_members_roles_by_pk?: Maybe<User_Group_Members_Roles>
  /** fetch data from the table: "users" */
  users: Array<Users>
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>
}

export type Query_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Categories_Order_By>>
  where?: InputMaybe<Categories_Bool_Exp>
}

export type Query_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Categories_Order_By>>
  where?: InputMaybe<Categories_Bool_Exp>
}

export type Query_RootCategories_By_PkArgs = {
  id: Scalars['Int']
}

export type Query_RootForm_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Form_Responses_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Form_Responses_Order_By>>
  where?: InputMaybe<Form_Responses_Bool_Exp>
}

export type Query_RootForm_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Responses_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Form_Responses_Order_By>>
  where?: InputMaybe<Form_Responses_Bool_Exp>
}

export type Query_RootForm_Responses_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

export type Query_RootForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

export type Query_RootForms_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Groups_Order_By>>
  where?: InputMaybe<Groups_Bool_Exp>
}

export type Query_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Groups_Order_By>>
  where?: InputMaybe<Groups_Bool_Exp>
}

export type Query_RootGroups_By_PkArgs = {
  id: Scalars['String']
}

export type Query_RootLearning_Featured_ModulesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Featured_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Featured_Modules_Order_By>>
  where?: InputMaybe<Learning_Featured_Modules_Bool_Exp>
}

export type Query_RootLearning_Featured_Modules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Featured_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Featured_Modules_Order_By>>
  where?: InputMaybe<Learning_Featured_Modules_Bool_Exp>
}

export type Query_RootLearning_Featured_Modules_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLearning_Learner_Event_LogArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Learner_Event_Log_Bool_Exp>
}

export type Query_RootLearning_Learner_Event_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Learner_Event_Log_Bool_Exp>
}

export type Query_RootLearning_Learner_Event_Log_By_PkArgs = {
  id: Scalars['Int']
}

export type Query_RootLearning_Learner_Module_Rating_QuestionsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Questions_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Questions_Order_By>
  >
  where?: InputMaybe<Learning_Learner_Module_Rating_Questions_Bool_Exp>
}

export type Query_RootLearning_Learner_Module_Rating_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Questions_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Questions_Order_By>
  >
  where?: InputMaybe<Learning_Learner_Module_Rating_Questions_Bool_Exp>
}

export type Query_RootLearning_Learner_Module_Rating_Questions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLearning_Learner_Module_Rating_ResponsesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Order_By>
  >
  where?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
}

export type Query_RootLearning_Learner_Module_Rating_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Order_By>
  >
  where?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
}

export type Query_RootLearning_Learner_Module_Rating_Responses_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLearning_Learner_Module_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Module_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Module_Ratings_Order_By>>
  where?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
}

export type Query_RootLearning_Learner_Module_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Module_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Module_Ratings_Order_By>>
  where?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
}

export type Query_RootLearning_Learner_Module_Ratings_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLearning_Learner_Module_Star_RatingsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Star_Ratings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Module_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Learner_Module_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Learner_Module_Star_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Star_Ratings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Module_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Learner_Module_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Learner_Module_Star_Ratings_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLearning_Learner_ReflectionsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Reflections_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Reflections_Order_By>>
  where?: InputMaybe<Learning_Learner_Reflections_Bool_Exp>
}

export type Query_RootLearning_Learner_Reflections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Reflections_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Reflections_Order_By>>
  where?: InputMaybe<Learning_Learner_Reflections_Bool_Exp>
}

export type Query_RootLearning_Learner_Reflections_By_PkArgs = {
  module_id: Scalars['uuid']
  user_id: Scalars['Int']
}

export type Query_RootLearning_Learner_Session_EventsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Session_Events_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
  where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
}

export type Query_RootLearning_Learner_Session_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Session_Events_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
  where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
}

export type Query_RootLearning_Learner_Session_Events_By_Period_Within_RangeArgs =
  {
    args: Learning_Learner_Session_Events_By_Period_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Learner_Session_Events_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
    where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
  }

export type Query_RootLearning_Learner_Session_Events_By_Period_Within_Range_AggregateArgs =
  {
    args: Learning_Learner_Session_Events_By_Period_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Learner_Session_Events_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
    where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
  }

export type Query_RootLearning_Learner_Session_Events_Within_RangeArgs = {
  args: Learning_Learner_Session_Events_Within_Range_Args
  distinct_on?: InputMaybe<Array<Learning_Learner_Session_Events_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
  where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
}

export type Query_RootLearning_Learner_Session_Events_Within_Range_AggregateArgs =
  {
    args: Learning_Learner_Session_Events_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Learner_Session_Events_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
    where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
  }

export type Query_RootLearning_Learner_Session_FeedbackArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Session_Feedback_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Session_Feedback_Order_By>>
  where?: InputMaybe<Learning_Learner_Session_Feedback_Bool_Exp>
}

export type Query_RootLearning_Learner_Session_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Session_Feedback_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Session_Feedback_Order_By>>
  where?: InputMaybe<Learning_Learner_Session_Feedback_Bool_Exp>
}

export type Query_RootLearning_Learner_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Sessions_Order_By>>
  where?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
}

export type Query_RootLearning_Learner_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Sessions_Order_By>>
  where?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
}

export type Query_RootLearning_Learner_Sessions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLearning_Module_Event_LogArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
}

export type Query_RootLearning_Module_Event_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
}

export type Query_RootLearning_Module_Event_Log_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLearning_Module_Event_TransitionsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Module_Event_Transitions_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Event_Transitions_Order_By>>
  where?: InputMaybe<Learning_Module_Event_Transitions_Bool_Exp>
}

export type Query_RootLearning_Module_Event_Transitions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Module_Event_Transitions_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Event_Transitions_Order_By>>
  where?: InputMaybe<Learning_Module_Event_Transitions_Bool_Exp>
}

export type Query_RootLearning_Module_Event_Transitions_By_PkArgs = {
  event: Scalars['learning_module_event']
  state: Scalars['learning_module_status']
}

export type Query_RootLearning_Module_ExistsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Exists_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Exists_Order_By>>
  where?: InputMaybe<Learning_Module_Exists_Bool_Exp>
}

export type Query_RootLearning_Module_Exists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Exists_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Exists_Order_By>>
  where?: InputMaybe<Learning_Module_Exists_Bool_Exp>
}

export type Query_RootLearning_Module_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_History_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_History_Order_By>>
  where?: InputMaybe<Learning_Module_History_Bool_Exp>
}

export type Query_RootLearning_Module_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_History_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_History_Order_By>>
  where?: InputMaybe<Learning_Module_History_Bool_Exp>
}

export type Query_RootLearning_Module_History_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLearning_Module_Revision_PagesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revision_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revision_Pages_Order_By>>
  where?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
}

export type Query_RootLearning_Module_Revision_Pages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revision_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revision_Pages_Order_By>>
  where?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
}

export type Query_RootLearning_Module_Revision_Pages_By_PkArgs = {
  page_id: Scalars['String']
  revision_id: Scalars['uuid']
}

export type Query_RootLearning_Module_RevisionsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revisions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revisions_Order_By>>
  where?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
}

export type Query_RootLearning_Module_Revisions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revisions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revisions_Order_By>>
  where?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
}

export type Query_RootLearning_Module_Revisions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLearning_Module_Star_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Module_Star_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Page_By_PkArgs = {
  id: Scalars['String']
}

export type Query_RootLearning_PagesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Pages_Order_By>>
  where?: InputMaybe<Learning_Pages_Bool_Exp>
}

export type Query_RootLearning_Pages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Pages_Order_By>>
  where?: InputMaybe<Learning_Pages_Bool_Exp>
}

export type Query_RootLearning_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Questions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Questions_Order_By>>
  where?: InputMaybe<Learning_Questions_Bool_Exp>
}

export type Query_RootLearning_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Questions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Questions_Order_By>>
  where?: InputMaybe<Learning_Questions_Bool_Exp>
}

export type Query_RootLearning_Questions_By_PkArgs = {
  id: Scalars['uuid']
  page_id: Scalars['String']
}

export type Query_RootLearning_Series_Discount_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Discount_Groups_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Groups_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Groups_Bool_Exp>
}

export type Query_RootLearning_Series_Discount_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Discount_Groups_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Groups_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Groups_Bool_Exp>
}

export type Query_RootLearning_Series_Discount_Groups_By_PkArgs = {
  discount_id: Scalars['uuid']
  group_id: Scalars['String']
}

export type Query_RootLearning_Series_Discount_SettingsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Discount_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
}

export type Query_RootLearning_Series_Discount_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Discount_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
}

export type Query_RootLearning_Series_Discount_Settings_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLearning_Series_Star_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Series_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Series_Star_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Series_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Series_Subscription_SettingsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Subscription_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Subscription_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
}

export type Query_RootLearning_Series_Subscription_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Subscription_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Subscription_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
}

export type Query_RootLearning_Series_Subscription_Settings_By_PkArgs = {
  series_id: Scalars['uuid']
}

export type Query_RootLearning_Series_Subscription_Settings_Discount_TypeArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Discount_Type_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Discount_Type_Order_By>
    >
    where?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp>
  }

export type Query_RootLearning_Series_Subscription_Settings_Discount_Type_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Discount_Type_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Discount_Type_Order_By>
    >
    where?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp>
  }

export type Query_RootLearning_Series_Subscription_Settings_Discount_Type_By_PkArgs =
  {
    value: Scalars['String']
  }

export type Query_RootLearning_Star_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Star_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Star_Ratings_For_Module_Within_RangeArgs = {
  args: Learning_Star_Ratings_For_Module_Within_Range_Args
  distinct_on?: InputMaybe<Array<Learning_Module_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Star_Ratings_For_Module_Within_Range_AggregateArgs =
  {
    args: Learning_Star_Ratings_For_Module_Within_Range_Args
    distinct_on?: InputMaybe<Array<Learning_Module_Star_Ratings_Select_Column>>
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Module_Star_Ratings_Order_By>>
    where?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
  }

export type Query_RootLearning_Star_Ratings_In_Library_Within_RangeArgs = {
  args: Learning_Star_Ratings_In_Library_Within_Range_Args
  distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Star_Ratings_In_Library_Within_Range_AggregateArgs =
  {
    args: Learning_Star_Ratings_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
    where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
  }

export type Query_RootLearning_Star_Ratings_In_Series_Within_RangeArgs = {
  args: Learning_Star_Ratings_In_Series_Within_Range_Args
  distinct_on?: InputMaybe<Array<Learning_Series_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Series_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Star_Ratings_In_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Star_Ratings_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<Array<Learning_Series_Star_Ratings_Select_Column>>
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Series_Star_Ratings_Order_By>>
    where?: InputMaybe<Learning_Series_Star_Ratings_Bool_Exp>
  }

export type Query_RootLearning_Star_Ratings_Within_RangeArgs = {
  args: Learning_Star_Ratings_Within_Range_Args
  distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Star_Ratings_Within_Range_AggregateArgs = {
  args: Learning_Star_Ratings_Within_Range_Args
  distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
}

export type Query_RootLearning_Stats_Learner_Module_Rating_Response_AveragesArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  }

export type Query_RootLearning_Stats_Learner_Module_Rating_Response_Averages_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  }

export type Query_RootLearning_Stats_Module_Rating_Response_Averages_Within_RangeArgs =
  {
    args: Learning_Stats_Module_Rating_Response_Averages_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  }

export type Query_RootLearning_Stats_Module_Rating_Response_Averages_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Module_Rating_Response_Averages_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  }

export type Query_RootLearning_Stats_Module_Rating_ResponsesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Module_Rating_Responses_Order_By>>
  where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
}

export type Query_RootLearning_Stats_Module_Rating_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Module_Rating_Responses_Order_By>>
  where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
}

export type Query_RootLearning_Stats_Module_Rating_Responses_For_Module_Within_RangeArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_For_Module_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Module_Rating_Responses_For_Module_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_For_Module_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Module_Rating_Responses_In_Library_Within_RangeArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Module_Rating_Responses_In_Library_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Module_Rating_Responses_In_Series_Within_RangeArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Module_Rating_Responses_In_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Module_Rating_Responses_Within_RangeArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Module_Rating_Responses_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Libraries_By_Period_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Libraries_By_Period_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Libraries_By_Period_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Libraries_By_Period_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Libraries_Within_RangeArgs = {
  args: Learning_Stats_Most_Active_Libraries_Within_Range_Args
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Library_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Library_Bool_Exp>
}

export type Query_RootLearning_Stats_Most_Active_Libraries_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Libraries_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Modules_In_Library_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Modules_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Modules_In_Library_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Modules_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Modules_In_Series_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Modules_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Modules_In_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Modules_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Modules_Within_RangeArgs = {
  args: Learning_Stats_Most_Active_Modules_Within_Range_Args
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Module_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
}

export type Query_RootLearning_Stats_Most_Active_Modules_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Modules_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Series_Within_RangeArgs = {
  args: Learning_Stats_Most_Active_Series_Within_Range_Args
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Series_Bool_Exp>
}

export type Query_RootLearning_Stats_Most_Active_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Users_For_Module_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Users_For_Module_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Users_For_Module_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Users_For_Module_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Users_In_Library_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Users_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Users_In_Library_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Users_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Users_In_Series_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Users_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Users_In_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Users_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>
  }

export type Query_RootLearning_Stats_Most_Active_Users_Within_RangeArgs = {
  args: Learning_Stats_Most_Active_Users_Within_Range_Args
  distinct_on?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_User_Bool_Exp>
}

export type Query_RootLearning_Stats_Most_Active_Users_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Users_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_User_Bool_Exp>
  }

export type Query_RootLearning_Stats_Rating_Response_AveragesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Rating_Response_Averages_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Rating_Response_Averages_Order_By>>
  where?: InputMaybe<Learning_Stats_Rating_Response_Averages_Bool_Exp>
}

export type Query_RootLearning_Stats_Rating_Response_Averages_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Rating_Response_Averages_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Rating_Response_Averages_Order_By>>
  where?: InputMaybe<Learning_Stats_Rating_Response_Averages_Bool_Exp>
}

export type Query_RootLearning_Stats_Rating_Response_Averages_Within_RangeArgs =
  {
    args: Learning_Stats_Rating_Response_Averages_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Rating_Response_Averages_Bool_Exp>
  }

export type Query_RootLearning_Stats_Rating_Response_Averages_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Rating_Response_Averages_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Rating_Response_Averages_Bool_Exp>
  }

export type Query_RootLearning_Stats_Rating_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Stats_Rating_Responses_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
  where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
}

export type Query_RootLearning_Stats_Rating_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Stats_Rating_Responses_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
  where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
}

export type Query_RootLearning_Stats_Rating_Responses_In_Library_Within_RangeArgs =
  {
    args: Learning_Stats_Rating_Responses_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
    where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Rating_Responses_In_Library_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Rating_Responses_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
    where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Rating_Responses_In_Series_Within_RangeArgs =
  {
    args: Learning_Stats_Rating_Responses_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
    where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Rating_Responses_In_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Rating_Responses_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
    where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Rating_Responses_Within_RangeArgs = {
  args: Learning_Stats_Rating_Responses_Within_Range_Args
  distinct_on?: InputMaybe<Array<Learning_Stats_Rating_Responses_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
  where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
}

export type Query_RootLearning_Stats_Rating_Responses_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Rating_Responses_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
    where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  }

export type Query_RootLearning_Stats_Sessions_By_LibraryArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Library_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Library_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Library_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Library_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Library_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Library_By_ModuleArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Library_By_Module_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Library_By_Module_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_Module_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Library_By_Module_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_Module_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_Module_Bool_Exp>
  }

export type Query_RootLearning_Stats_Sessions_By_Library_By_UserArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Library_By_User_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Library_By_User_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Library_By_User_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>
  }

export type Query_RootLearning_Stats_Sessions_By_ModuleArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Module_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Module_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Module_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Module_By_UserArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Module_By_User_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Module_By_User_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Module_By_User_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>
  }

export type Query_RootLearning_Stats_Sessions_By_Period_By_LibraryArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Period_By_Library_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Period_By_Library_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Period_By_Library_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>
  }

export type Query_RootLearning_Stats_Sessions_By_SeriesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Series_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Series_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Series_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Series_By_ModuleArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_By_Module_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_By_Module_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_Module_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Series_By_Module_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_Module_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_Module_Bool_Exp>
  }

export type Query_RootLearning_Stats_Sessions_By_Series_By_UserArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_By_User_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_By_User_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_Series_By_User_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>
  }

export type Query_RootLearning_Stats_Sessions_By_UserArgs = {
  distinct_on?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_User_Bool_Exp>
}

export type Query_RootLearning_Stats_Sessions_By_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_User_Bool_Exp>
}

export type Query_RootLearning_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Uploads_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Uploads_Order_By>>
  where?: InputMaybe<Learning_Uploads_Bool_Exp>
}

export type Query_RootLearning_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Uploads_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Uploads_Order_By>>
  where?: InputMaybe<Learning_Uploads_Bool_Exp>
}

export type Query_RootLearning_Uploads_By_PkArgs = {
  id: Scalars['Int']
}

export type Query_RootModule_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootModulesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Modules_Order_By>>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

export type Query_RootModules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Modules_Order_By>>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

export type Query_RootPage_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Page_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Page_Categories_Order_By>>
  where?: InputMaybe<Page_Categories_Bool_Exp>
}

export type Query_RootPage_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Page_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Page_Categories_Order_By>>
  where?: InputMaybe<Page_Categories_Bool_Exp>
}

export type Query_RootPage_Categories_By_PkArgs = {
  category_id: Scalars['Int']
  page_id: Scalars['Int']
}

export type Query_RootPagesArgs = {
  distinct_on?: InputMaybe<Array<Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Pages_Order_By>>
  where?: InputMaybe<Pages_Bool_Exp>
}

export type Query_RootPages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Pages_Order_By>>
  where?: InputMaybe<Pages_Bool_Exp>
}

export type Query_RootPages_By_PkArgs = {
  id: Scalars['Int']
}

export type Query_RootPost_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Post_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Categories_Order_By>>
  where?: InputMaybe<Post_Categories_Bool_Exp>
}

export type Query_RootPost_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Categories_Order_By>>
  where?: InputMaybe<Post_Categories_Bool_Exp>
}

export type Query_RootPost_Categories_By_PkArgs = {
  category_id: Scalars['Int']
  post_id: Scalars['Int']
}

export type Query_RootPost_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Comments_Order_By>>
  where?: InputMaybe<Post_Comments_Bool_Exp>
}

export type Query_RootPost_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Comments_Order_By>>
  where?: InputMaybe<Post_Comments_Bool_Exp>
}

export type Query_RootPost_Comments_By_PkArgs = {
  id: Scalars['Int']
}

export type Query_RootPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

export type Query_RootPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

export type Query_RootPosts_By_PkArgs = {
  id: Scalars['Int']
}

export type Query_RootResource_DependentsArgs = {
  distinct_on?: InputMaybe<Array<Resource_Dependents_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resource_Dependents_Order_By>>
  where?: InputMaybe<Resource_Dependents_Bool_Exp>
}

export type Query_RootResource_Dependents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Resource_Dependents_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resource_Dependents_Order_By>>
  where?: InputMaybe<Resource_Dependents_Bool_Exp>
}

export type Query_RootResourcesArgs = {
  distinct_on?: InputMaybe<Array<Resources_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resources_Order_By>>
  where?: InputMaybe<Resources_Bool_Exp>
}

export type Query_RootResources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Resources_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resources_Order_By>>
  where?: InputMaybe<Resources_Bool_Exp>
}

export type Query_RootResources_By_PkArgs = {
  id: Scalars['Int']
}

export type Query_RootSearch_ModulesArgs = {
  args: Search_Modules_Args
  distinct_on?: InputMaybe<Array<Learning_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Modules_Order_By>>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

export type Query_RootSearch_Modules_AggregateArgs = {
  args: Search_Modules_Args
  distinct_on?: InputMaybe<Array<Learning_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Modules_Order_By>>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

export type Query_RootSegment_UserArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Segment_User_Order_By>>
  where?: InputMaybe<Segment_User_Bool_Exp>
}

export type Query_RootSegment_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Segment_User_Order_By>>
  where?: InputMaybe<Segment_User_Bool_Exp>
}

export type Query_RootSegment_User_By_PkArgs = {
  segment_id: Scalars['bigint']
  user_id: Scalars['bigint']
}

export type Query_RootSeriesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Order_By>>
  where?: InputMaybe<Learning_Series_Bool_Exp>
}

export type Query_RootSeries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Order_By>>
  where?: InputMaybe<Learning_Series_Bool_Exp>
}

export type Query_RootSeries_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSlugsArgs = {
  distinct_on?: InputMaybe<Array<Slugs_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Slugs_Order_By>>
  where?: InputMaybe<Slugs_Bool_Exp>
}

export type Query_RootSlugs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Slugs_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Slugs_Order_By>>
  where?: InputMaybe<Slugs_Bool_Exp>
}

export type Query_RootSlugs_By_PkArgs = {
  prefix: Scalars['String']
  slug: Scalars['String']
}

export type Query_RootUser_Group_MembersArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

export type Query_RootUser_Group_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

export type Query_RootUser_Group_Members_By_PkArgs = {
  group_id: Scalars['String']
  user_id: Scalars['String']
}

export type Query_RootUser_Group_Members_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Roles_Order_By>>
  where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
}

export type Query_RootUser_Group_Members_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Roles_Order_By>>
  where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
}

export type Query_RootUser_Group_Members_Roles_By_PkArgs = {
  group_id: Scalars['String']
  user_id: Scalars['String']
}

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int']
}

/** columns and relationships of "resource_dependents" */
export type Resource_Dependents = {
  __typename?: 'resource_dependents'
  id?: Maybe<Scalars['Int']>
  /** An object relationship */
  resource?: Maybe<Resources>
  resource_id?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** aggregated selection of "resource_dependents" */
export type Resource_Dependents_Aggregate = {
  __typename?: 'resource_dependents_aggregate'
  aggregate?: Maybe<Resource_Dependents_Aggregate_Fields>
  nodes: Array<Resource_Dependents>
}

export type Resource_Dependents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Resource_Dependents_Aggregate_Bool_Exp_Count>
}

export type Resource_Dependents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Resource_Dependents_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Resource_Dependents_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "resource_dependents" */
export type Resource_Dependents_Aggregate_Fields = {
  __typename?: 'resource_dependents_aggregate_fields'
  avg?: Maybe<Resource_Dependents_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Resource_Dependents_Max_Fields>
  min?: Maybe<Resource_Dependents_Min_Fields>
  stddev?: Maybe<Resource_Dependents_Stddev_Fields>
  stddev_pop?: Maybe<Resource_Dependents_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Resource_Dependents_Stddev_Samp_Fields>
  sum?: Maybe<Resource_Dependents_Sum_Fields>
  var_pop?: Maybe<Resource_Dependents_Var_Pop_Fields>
  var_samp?: Maybe<Resource_Dependents_Var_Samp_Fields>
  variance?: Maybe<Resource_Dependents_Variance_Fields>
}

/** aggregate fields of "resource_dependents" */
export type Resource_Dependents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Resource_Dependents_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "resource_dependents" */
export type Resource_Dependents_Aggregate_Order_By = {
  avg?: InputMaybe<Resource_Dependents_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Resource_Dependents_Max_Order_By>
  min?: InputMaybe<Resource_Dependents_Min_Order_By>
  stddev?: InputMaybe<Resource_Dependents_Stddev_Order_By>
  stddev_pop?: InputMaybe<Resource_Dependents_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Resource_Dependents_Stddev_Samp_Order_By>
  sum?: InputMaybe<Resource_Dependents_Sum_Order_By>
  var_pop?: InputMaybe<Resource_Dependents_Var_Pop_Order_By>
  var_samp?: InputMaybe<Resource_Dependents_Var_Samp_Order_By>
  variance?: InputMaybe<Resource_Dependents_Variance_Order_By>
}

/** input type for inserting array relation for remote table "resource_dependents" */
export type Resource_Dependents_Arr_Rel_Insert_Input = {
  data: Array<Resource_Dependents_Insert_Input>
}

/** aggregate avg on columns */
export type Resource_Dependents_Avg_Fields = {
  __typename?: 'resource_dependents_avg_fields'
  id?: Maybe<Scalars['Float']>
  resource_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "resource_dependents" */
export type Resource_Dependents_Avg_Order_By = {
  id?: InputMaybe<Order_By>
  resource_id?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "resource_dependents". All fields are combined with a logical 'AND'. */
export type Resource_Dependents_Bool_Exp = {
  _and?: InputMaybe<Array<Resource_Dependents_Bool_Exp>>
  _not?: InputMaybe<Resource_Dependents_Bool_Exp>
  _or?: InputMaybe<Array<Resource_Dependents_Bool_Exp>>
  id?: InputMaybe<Int_Comparison_Exp>
  resource?: InputMaybe<Resources_Bool_Exp>
  resource_id?: InputMaybe<Int_Comparison_Exp>
  slug?: InputMaybe<String_Comparison_Exp>
  title?: InputMaybe<String_Comparison_Exp>
  type?: InputMaybe<String_Comparison_Exp>
}

/** input type for inserting data into table "resource_dependents" */
export type Resource_Dependents_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>
  resource?: InputMaybe<Resources_Obj_Rel_Insert_Input>
  resource_id?: InputMaybe<Scalars['Int']>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Resource_Dependents_Max_Fields = {
  __typename?: 'resource_dependents_max_fields'
  id?: Maybe<Scalars['Int']>
  resource_id?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "resource_dependents" */
export type Resource_Dependents_Max_Order_By = {
  id?: InputMaybe<Order_By>
  resource_id?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Resource_Dependents_Min_Fields = {
  __typename?: 'resource_dependents_min_fields'
  id?: Maybe<Scalars['Int']>
  resource_id?: Maybe<Scalars['Int']>
  slug?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "resource_dependents" */
export type Resource_Dependents_Min_Order_By = {
  id?: InputMaybe<Order_By>
  resource_id?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
}

/** Ordering options when selecting data from "resource_dependents". */
export type Resource_Dependents_Order_By = {
  id?: InputMaybe<Order_By>
  resource?: InputMaybe<Resources_Order_By>
  resource_id?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  title?: InputMaybe<Order_By>
  type?: InputMaybe<Order_By>
}

/** select columns of table "resource_dependents" */
export enum Resource_Dependents_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
}

/** aggregate stddev on columns */
export type Resource_Dependents_Stddev_Fields = {
  __typename?: 'resource_dependents_stddev_fields'
  id?: Maybe<Scalars['Float']>
  resource_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "resource_dependents" */
export type Resource_Dependents_Stddev_Order_By = {
  id?: InputMaybe<Order_By>
  resource_id?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Resource_Dependents_Stddev_Pop_Fields = {
  __typename?: 'resource_dependents_stddev_pop_fields'
  id?: Maybe<Scalars['Float']>
  resource_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "resource_dependents" */
export type Resource_Dependents_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>
  resource_id?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Resource_Dependents_Stddev_Samp_Fields = {
  __typename?: 'resource_dependents_stddev_samp_fields'
  id?: Maybe<Scalars['Float']>
  resource_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "resource_dependents" */
export type Resource_Dependents_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>
  resource_id?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "resource_dependents" */
export type Resource_Dependents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Resource_Dependents_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Resource_Dependents_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>
  resource_id?: InputMaybe<Scalars['Int']>
  slug?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Resource_Dependents_Sum_Fields = {
  __typename?: 'resource_dependents_sum_fields'
  id?: Maybe<Scalars['Int']>
  resource_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "resource_dependents" */
export type Resource_Dependents_Sum_Order_By = {
  id?: InputMaybe<Order_By>
  resource_id?: InputMaybe<Order_By>
}

/** aggregate var_pop on columns */
export type Resource_Dependents_Var_Pop_Fields = {
  __typename?: 'resource_dependents_var_pop_fields'
  id?: Maybe<Scalars['Float']>
  resource_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "resource_dependents" */
export type Resource_Dependents_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>
  resource_id?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Resource_Dependents_Var_Samp_Fields = {
  __typename?: 'resource_dependents_var_samp_fields'
  id?: Maybe<Scalars['Float']>
  resource_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "resource_dependents" */
export type Resource_Dependents_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>
  resource_id?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Resource_Dependents_Variance_Fields = {
  __typename?: 'resource_dependents_variance_fields'
  id?: Maybe<Scalars['Float']>
  resource_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "resource_dependents" */
export type Resource_Dependents_Variance_Order_By = {
  id?: InputMaybe<Order_By>
  resource_id?: InputMaybe<Order_By>
}

/** columns and relationships of "resources" */
export type Resources = {
  __typename?: 'resources'
  alt_text?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  content_type: Scalars['String']
  created_at: Scalars['timestamptz']
  created_by?: Maybe<Scalars['Int']>
  /** An object relationship */
  creator?: Maybe<Users>
  description?: Maybe<Scalars['String']>
  file: Scalars['String']
  id: Scalars['Int']
  manifest?: Maybe<Scalars['jsonb']>
  manifestPath?: Maybe<Scalars['String']>
  name: Scalars['String']
  path: Scalars['String']
  /** An array relationship */
  posts: Array<Posts>
  /** An aggregate relationship */
  posts_aggregate: Posts_Aggregate
  updated_at: Scalars['timestamptz']
  updated_by: Scalars['Int']
  /** An object relationship */
  updater: Users
  url?: Maybe<Scalars['String']>
}

/** columns and relationships of "resources" */
export type ResourcesManifestArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "resources" */
export type ResourcesPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

/** columns and relationships of "resources" */
export type ResourcesPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

/** aggregated selection of "resources" */
export type Resources_Aggregate = {
  __typename?: 'resources_aggregate'
  aggregate?: Maybe<Resources_Aggregate_Fields>
  nodes: Array<Resources>
}

export type Resources_Aggregate_Bool_Exp = {
  count?: InputMaybe<Resources_Aggregate_Bool_Exp_Count>
}

export type Resources_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Resources_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Resources_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "resources" */
export type Resources_Aggregate_Fields = {
  __typename?: 'resources_aggregate_fields'
  avg?: Maybe<Resources_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Resources_Max_Fields>
  min?: Maybe<Resources_Min_Fields>
  stddev?: Maybe<Resources_Stddev_Fields>
  stddev_pop?: Maybe<Resources_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Resources_Stddev_Samp_Fields>
  sum?: Maybe<Resources_Sum_Fields>
  var_pop?: Maybe<Resources_Var_Pop_Fields>
  var_samp?: Maybe<Resources_Var_Samp_Fields>
  variance?: Maybe<Resources_Variance_Fields>
}

/** aggregate fields of "resources" */
export type Resources_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Resources_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "resources" */
export type Resources_Aggregate_Order_By = {
  avg?: InputMaybe<Resources_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Resources_Max_Order_By>
  min?: InputMaybe<Resources_Min_Order_By>
  stddev?: InputMaybe<Resources_Stddev_Order_By>
  stddev_pop?: InputMaybe<Resources_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Resources_Stddev_Samp_Order_By>
  sum?: InputMaybe<Resources_Sum_Order_By>
  var_pop?: InputMaybe<Resources_Var_Pop_Order_By>
  var_samp?: InputMaybe<Resources_Var_Samp_Order_By>
  variance?: InputMaybe<Resources_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Resources_Append_Input = {
  manifest?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "resources" */
export type Resources_Arr_Rel_Insert_Input = {
  data: Array<Resources_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Resources_On_Conflict>
}

/** aggregate avg on columns */
export type Resources_Avg_Fields = {
  __typename?: 'resources_avg_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "resources" */
export type Resources_Avg_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "resources". All fields are combined with a logical 'AND'. */
export type Resources_Bool_Exp = {
  _and?: InputMaybe<Array<Resources_Bool_Exp>>
  _not?: InputMaybe<Resources_Bool_Exp>
  _or?: InputMaybe<Array<Resources_Bool_Exp>>
  alt_text?: InputMaybe<String_Comparison_Exp>
  caption?: InputMaybe<String_Comparison_Exp>
  content_type?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  creator?: InputMaybe<Users_Bool_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  file?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Int_Comparison_Exp>
  manifest?: InputMaybe<Jsonb_Comparison_Exp>
  manifestPath?: InputMaybe<String_Comparison_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  path?: InputMaybe<String_Comparison_Exp>
  posts?: InputMaybe<Posts_Bool_Exp>
  posts_aggregate?: InputMaybe<Posts_Aggregate_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
  updater?: InputMaybe<Users_Bool_Exp>
  url?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "resources" */
export enum Resources_Constraint {
  /** unique or primary key constraint on columns "file" */
  ResourcesFileKey = 'resources_file_key',
  /** unique or primary key constraint on columns "id" */
  ResourcesPkey = 'resources_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Resources_Delete_At_Path_Input = {
  manifest?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Resources_Delete_Elem_Input = {
  manifest?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Resources_Delete_Key_Input = {
  manifest?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "resources" */
export type Resources_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>
  id?: InputMaybe<Scalars['Int']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "resources" */
export type Resources_Insert_Input = {
  alt_text?: InputMaybe<Scalars['String']>
  caption?: InputMaybe<Scalars['String']>
  content_type?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  creator?: InputMaybe<Users_Obj_Rel_Insert_Input>
  description?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  manifest?: InputMaybe<Scalars['jsonb']>
  manifestPath?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
  posts?: InputMaybe<Posts_Arr_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  updater?: InputMaybe<Users_Obj_Rel_Insert_Input>
  url?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Resources_Max_Fields = {
  __typename?: 'resources_max_fields'
  alt_text?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  content_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  file?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  manifestPath?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
  url?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "resources" */
export type Resources_Max_Order_By = {
  alt_text?: InputMaybe<Order_By>
  caption?: InputMaybe<Order_By>
  content_type?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  file?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  manifestPath?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  path?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  url?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Resources_Min_Fields = {
  __typename?: 'resources_min_fields'
  alt_text?: Maybe<Scalars['String']>
  caption?: Maybe<Scalars['String']>
  content_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  file?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  manifestPath?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
  url?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "resources" */
export type Resources_Min_Order_By = {
  alt_text?: InputMaybe<Order_By>
  caption?: InputMaybe<Order_By>
  content_type?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  file?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  manifestPath?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  path?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  url?: InputMaybe<Order_By>
}

/** response of any mutation on the table "resources" */
export type Resources_Mutation_Response = {
  __typename?: 'resources_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Resources>
}

/** input type for inserting object relation for remote table "resources" */
export type Resources_Obj_Rel_Insert_Input = {
  data: Resources_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Resources_On_Conflict>
}

/** on_conflict condition type for table "resources" */
export type Resources_On_Conflict = {
  constraint: Resources_Constraint
  update_columns?: Array<Resources_Update_Column>
  where?: InputMaybe<Resources_Bool_Exp>
}

/** Ordering options when selecting data from "resources". */
export type Resources_Order_By = {
  alt_text?: InputMaybe<Order_By>
  caption?: InputMaybe<Order_By>
  content_type?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  creator?: InputMaybe<Users_Order_By>
  description?: InputMaybe<Order_By>
  file?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  manifest?: InputMaybe<Order_By>
  manifestPath?: InputMaybe<Order_By>
  name?: InputMaybe<Order_By>
  path?: InputMaybe<Order_By>
  posts_aggregate?: InputMaybe<Posts_Aggregate_Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
  updater?: InputMaybe<Users_Order_By>
  url?: InputMaybe<Order_By>
}

/** primary key columns input for table: resources */
export type Resources_Pk_Columns_Input = {
  id: Scalars['Int']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Resources_Prepend_Input = {
  manifest?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "resources" */
export enum Resources_Select_Column {
  /** column name */
  AltText = 'alt_text',
  /** column name */
  Caption = 'caption',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  Manifest = 'manifest',
  /** column name */
  ManifestPath = 'manifestPath',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "resources" */
export type Resources_Set_Input = {
  alt_text?: InputMaybe<Scalars['String']>
  caption?: InputMaybe<Scalars['String']>
  content_type?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  manifest?: InputMaybe<Scalars['jsonb']>
  manifestPath?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  url?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Resources_Stddev_Fields = {
  __typename?: 'resources_stddev_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "resources" */
export type Resources_Stddev_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Resources_Stddev_Pop_Fields = {
  __typename?: 'resources_stddev_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "resources" */
export type Resources_Stddev_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Resources_Stddev_Samp_Fields = {
  __typename?: 'resources_stddev_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "resources" */
export type Resources_Stddev_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "resources" */
export type Resources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Resources_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Resources_Stream_Cursor_Value_Input = {
  alt_text?: InputMaybe<Scalars['String']>
  caption?: InputMaybe<Scalars['String']>
  content_type?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  description?: InputMaybe<Scalars['String']>
  file?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  manifest?: InputMaybe<Scalars['jsonb']>
  manifestPath?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  path?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
  url?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Resources_Sum_Fields = {
  __typename?: 'resources_sum_fields'
  created_by?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  updated_by?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "resources" */
export type Resources_Sum_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** update columns of table "resources" */
export enum Resources_Update_Column {
  /** column name */
  AltText = 'alt_text',
  /** column name */
  Caption = 'caption',
  /** column name */
  ContentType = 'content_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  File = 'file',
  /** column name */
  Id = 'id',
  /** column name */
  Manifest = 'manifest',
  /** column name */
  ManifestPath = 'manifestPath',
  /** column name */
  Name = 'name',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Url = 'url',
}

export type Resources_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Resources_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Resources_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Resources_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Resources_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Resources_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Resources_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Resources_Set_Input>
  /** filter the rows which have to be updated */
  where: Resources_Bool_Exp
}

/** aggregate var_pop on columns */
export type Resources_Var_Pop_Fields = {
  __typename?: 'resources_var_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "resources" */
export type Resources_Var_Pop_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Resources_Var_Samp_Fields = {
  __typename?: 'resources_var_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "resources" */
export type Resources_Var_Samp_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Resources_Variance_Fields = {
  __typename?: 'resources_variance_fields'
  created_by?: Maybe<Scalars['Float']>
  id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "resources" */
export type Resources_Variance_Order_By = {
  created_by?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

export type Search_Modules_Args = {
  search?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "segment_user" */
export type Segment_User = {
  __typename?: 'segment_user'
  segment_id: Scalars['bigint']
  user_id: Scalars['bigint']
}

/** aggregated selection of "segment_user" */
export type Segment_User_Aggregate = {
  __typename?: 'segment_user_aggregate'
  aggregate?: Maybe<Segment_User_Aggregate_Fields>
  nodes: Array<Segment_User>
}

/** aggregate fields of "segment_user" */
export type Segment_User_Aggregate_Fields = {
  __typename?: 'segment_user_aggregate_fields'
  avg?: Maybe<Segment_User_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Segment_User_Max_Fields>
  min?: Maybe<Segment_User_Min_Fields>
  stddev?: Maybe<Segment_User_Stddev_Fields>
  stddev_pop?: Maybe<Segment_User_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Segment_User_Stddev_Samp_Fields>
  sum?: Maybe<Segment_User_Sum_Fields>
  var_pop?: Maybe<Segment_User_Var_Pop_Fields>
  var_samp?: Maybe<Segment_User_Var_Samp_Fields>
  variance?: Maybe<Segment_User_Variance_Fields>
}

/** aggregate fields of "segment_user" */
export type Segment_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Segment_User_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Segment_User_Avg_Fields = {
  __typename?: 'segment_user_avg_fields'
  segment_id?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "segment_user". All fields are combined with a logical 'AND'. */
export type Segment_User_Bool_Exp = {
  _and?: InputMaybe<Array<Segment_User_Bool_Exp>>
  _not?: InputMaybe<Segment_User_Bool_Exp>
  _or?: InputMaybe<Array<Segment_User_Bool_Exp>>
  segment_id?: InputMaybe<Bigint_Comparison_Exp>
  user_id?: InputMaybe<Bigint_Comparison_Exp>
}

/** unique or primary key constraints on table "segment_user" */
export enum Segment_User_Constraint {
  /** unique or primary key constraint on columns "user_id", "segment_id" */
  SegmentUserPkey = 'segment_user_pkey',
}

/** input type for incrementing numeric columns in table "segment_user" */
export type Segment_User_Inc_Input = {
  segment_id?: InputMaybe<Scalars['bigint']>
  user_id?: InputMaybe<Scalars['bigint']>
}

/** input type for inserting data into table "segment_user" */
export type Segment_User_Insert_Input = {
  segment_id?: InputMaybe<Scalars['bigint']>
  user_id?: InputMaybe<Scalars['bigint']>
}

/** aggregate max on columns */
export type Segment_User_Max_Fields = {
  __typename?: 'segment_user_max_fields'
  segment_id?: Maybe<Scalars['bigint']>
  user_id?: Maybe<Scalars['bigint']>
}

/** aggregate min on columns */
export type Segment_User_Min_Fields = {
  __typename?: 'segment_user_min_fields'
  segment_id?: Maybe<Scalars['bigint']>
  user_id?: Maybe<Scalars['bigint']>
}

/** response of any mutation on the table "segment_user" */
export type Segment_User_Mutation_Response = {
  __typename?: 'segment_user_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Segment_User>
}

/** input type for inserting object relation for remote table "segment_user" */
export type Segment_User_Obj_Rel_Insert_Input = {
  data: Segment_User_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Segment_User_On_Conflict>
}

/** on_conflict condition type for table "segment_user" */
export type Segment_User_On_Conflict = {
  constraint: Segment_User_Constraint
  update_columns?: Array<Segment_User_Update_Column>
  where?: InputMaybe<Segment_User_Bool_Exp>
}

/** Ordering options when selecting data from "segment_user". */
export type Segment_User_Order_By = {
  segment_id?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: segment_user */
export type Segment_User_Pk_Columns_Input = {
  segment_id: Scalars['bigint']
  user_id: Scalars['bigint']
}

/** select columns of table "segment_user" */
export enum Segment_User_Select_Column {
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "segment_user" */
export type Segment_User_Set_Input = {
  segment_id?: InputMaybe<Scalars['bigint']>
  user_id?: InputMaybe<Scalars['bigint']>
}

/** aggregate stddev on columns */
export type Segment_User_Stddev_Fields = {
  __typename?: 'segment_user_stddev_fields'
  segment_id?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Segment_User_Stddev_Pop_Fields = {
  __typename?: 'segment_user_stddev_pop_fields'
  segment_id?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Segment_User_Stddev_Samp_Fields = {
  __typename?: 'segment_user_stddev_samp_fields'
  segment_id?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "segment_user" */
export type Segment_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Segment_User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Segment_User_Stream_Cursor_Value_Input = {
  segment_id?: InputMaybe<Scalars['bigint']>
  user_id?: InputMaybe<Scalars['bigint']>
}

/** aggregate sum on columns */
export type Segment_User_Sum_Fields = {
  __typename?: 'segment_user_sum_fields'
  segment_id?: Maybe<Scalars['bigint']>
  user_id?: Maybe<Scalars['bigint']>
}

/** update columns of table "segment_user" */
export enum Segment_User_Update_Column {
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  UserId = 'user_id',
}

export type Segment_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Segment_User_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Segment_User_Set_Input>
  /** filter the rows which have to be updated */
  where: Segment_User_Bool_Exp
}

/** aggregate var_pop on columns */
export type Segment_User_Var_Pop_Fields = {
  __typename?: 'segment_user_var_pop_fields'
  segment_id?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Segment_User_Var_Samp_Fields = {
  __typename?: 'segment_user_var_samp_fields'
  segment_id?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Segment_User_Variance_Fields = {
  __typename?: 'segment_user_variance_fields'
  segment_id?: Maybe<Scalars['Float']>
  user_id?: Maybe<Scalars['Float']>
}

/** columns and relationships of "slugs" */
export type Slugs = {
  __typename?: 'slugs'
  created_at: Scalars['timestamptz']
  created_by: Scalars['Int']
  prefix: Scalars['String']
  slug: Scalars['String']
  target_id: Scalars['Int']
  target_type: Scalars['String']
  updated_at: Scalars['timestamptz']
  updated_by: Scalars['Int']
}

/** aggregated selection of "slugs" */
export type Slugs_Aggregate = {
  __typename?: 'slugs_aggregate'
  aggregate?: Maybe<Slugs_Aggregate_Fields>
  nodes: Array<Slugs>
}

/** aggregate fields of "slugs" */
export type Slugs_Aggregate_Fields = {
  __typename?: 'slugs_aggregate_fields'
  avg?: Maybe<Slugs_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Slugs_Max_Fields>
  min?: Maybe<Slugs_Min_Fields>
  stddev?: Maybe<Slugs_Stddev_Fields>
  stddev_pop?: Maybe<Slugs_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Slugs_Stddev_Samp_Fields>
  sum?: Maybe<Slugs_Sum_Fields>
  var_pop?: Maybe<Slugs_Var_Pop_Fields>
  var_samp?: Maybe<Slugs_Var_Samp_Fields>
  variance?: Maybe<Slugs_Variance_Fields>
}

/** aggregate fields of "slugs" */
export type Slugs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Slugs_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Slugs_Avg_Fields = {
  __typename?: 'slugs_avg_fields'
  created_by?: Maybe<Scalars['Float']>
  target_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "slugs". All fields are combined with a logical 'AND'. */
export type Slugs_Bool_Exp = {
  _and?: InputMaybe<Array<Slugs_Bool_Exp>>
  _not?: InputMaybe<Slugs_Bool_Exp>
  _or?: InputMaybe<Array<Slugs_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  created_by?: InputMaybe<Int_Comparison_Exp>
  prefix?: InputMaybe<String_Comparison_Exp>
  slug?: InputMaybe<String_Comparison_Exp>
  target_id?: InputMaybe<Int_Comparison_Exp>
  target_type?: InputMaybe<String_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  updated_by?: InputMaybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "slugs" */
export enum Slugs_Constraint {
  /** unique or primary key constraint on columns "slug", "prefix" */
  SlugsPkey = 'slugs_pkey',
}

/** input type for incrementing numeric columns in table "slugs" */
export type Slugs_Inc_Input = {
  created_by?: InputMaybe<Scalars['Int']>
  target_id?: InputMaybe<Scalars['Int']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "slugs" */
export type Slugs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  prefix?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  target_id?: InputMaybe<Scalars['Int']>
  target_type?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Slugs_Max_Fields = {
  __typename?: 'slugs_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  prefix?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  target_id?: Maybe<Scalars['Int']>
  target_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** aggregate min on columns */
export type Slugs_Min_Fields = {
  __typename?: 'slugs_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  created_by?: Maybe<Scalars['Int']>
  prefix?: Maybe<Scalars['String']>
  slug?: Maybe<Scalars['String']>
  target_id?: Maybe<Scalars['Int']>
  target_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  updated_by?: Maybe<Scalars['Int']>
}

/** response of any mutation on the table "slugs" */
export type Slugs_Mutation_Response = {
  __typename?: 'slugs_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Slugs>
}

/** on_conflict condition type for table "slugs" */
export type Slugs_On_Conflict = {
  constraint: Slugs_Constraint
  update_columns?: Array<Slugs_Update_Column>
  where?: InputMaybe<Slugs_Bool_Exp>
}

/** Ordering options when selecting data from "slugs". */
export type Slugs_Order_By = {
  created_at?: InputMaybe<Order_By>
  created_by?: InputMaybe<Order_By>
  prefix?: InputMaybe<Order_By>
  slug?: InputMaybe<Order_By>
  target_id?: InputMaybe<Order_By>
  target_type?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  updated_by?: InputMaybe<Order_By>
}

/** primary key columns input for table: slugs */
export type Slugs_Pk_Columns_Input = {
  prefix: Scalars['String']
  slug: Scalars['String']
}

/** select columns of table "slugs" */
export enum Slugs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Prefix = 'prefix',
  /** column name */
  Slug = 'slug',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** input type for updating data in table "slugs" */
export type Slugs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  prefix?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  target_id?: InputMaybe<Scalars['Int']>
  target_type?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Slugs_Stddev_Fields = {
  __typename?: 'slugs_stddev_fields'
  created_by?: Maybe<Scalars['Float']>
  target_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Slugs_Stddev_Pop_Fields = {
  __typename?: 'slugs_stddev_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  target_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Slugs_Stddev_Samp_Fields = {
  __typename?: 'slugs_stddev_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  target_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "slugs" */
export type Slugs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Slugs_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Slugs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  created_by?: InputMaybe<Scalars['Int']>
  prefix?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  target_id?: InputMaybe<Scalars['Int']>
  target_type?: InputMaybe<Scalars['String']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  updated_by?: InputMaybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Slugs_Sum_Fields = {
  __typename?: 'slugs_sum_fields'
  created_by?: Maybe<Scalars['Int']>
  target_id?: Maybe<Scalars['Int']>
  updated_by?: Maybe<Scalars['Int']>
}

/** update columns of table "slugs" */
export enum Slugs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Prefix = 'prefix',
  /** column name */
  Slug = 'slug',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Slugs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Slugs_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Slugs_Set_Input>
  /** filter the rows which have to be updated */
  where: Slugs_Bool_Exp
}

/** aggregate var_pop on columns */
export type Slugs_Var_Pop_Fields = {
  __typename?: 'slugs_var_pop_fields'
  created_by?: Maybe<Scalars['Float']>
  target_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Slugs_Var_Samp_Fields = {
  __typename?: 'slugs_var_samp_fields'
  created_by?: Maybe<Scalars['Float']>
  target_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Slugs_Variance_Fields = {
  __typename?: 'slugs_variance_fields'
  created_by?: Maybe<Scalars['Float']>
  target_id?: Maybe<Scalars['Float']>
  updated_by?: Maybe<Scalars['Float']>
}

export type Subscription_Root = {
  __typename?: 'subscription_root'
  /** fetch data from the table: "categories" */
  categories: Array<Categories>
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>
  /** fetch data from the table in a streaming manner: "categories" */
  categories_stream: Array<Categories>
  /** fetch data from the table: "form_responses" */
  form_responses: Array<Form_Responses>
  /** fetch aggregated fields from the table: "form_responses" */
  form_responses_aggregate: Form_Responses_Aggregate
  /** fetch data from the table: "form_responses" using primary key columns */
  form_responses_by_pk?: Maybe<Form_Responses>
  /** fetch data from the table in a streaming manner: "form_responses" */
  form_responses_stream: Array<Form_Responses>
  /** fetch data from the table: "forms" */
  forms: Array<Forms>
  /** fetch aggregated fields from the table: "forms" */
  forms_aggregate: Forms_Aggregate
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>
  /** fetch data from the table in a streaming manner: "forms" */
  forms_stream: Array<Forms>
  /** fetch data from the table: "groups" */
  groups: Array<Groups>
  /** fetch aggregated fields from the table: "groups" */
  groups_aggregate: Groups_Aggregate
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>
  /** fetch data from the table in a streaming manner: "groups" */
  groups_stream: Array<Groups>
  /** fetch data from the table: "learning_featured_modules" */
  learning_featured_modules: Array<Learning_Featured_Modules>
  /** fetch aggregated fields from the table: "learning_featured_modules" */
  learning_featured_modules_aggregate: Learning_Featured_Modules_Aggregate
  /** fetch data from the table: "learning_featured_modules" using primary key columns */
  learning_featured_modules_by_pk?: Maybe<Learning_Featured_Modules>
  /** fetch data from the table in a streaming manner: "learning_featured_modules" */
  learning_featured_modules_stream: Array<Learning_Featured_Modules>
  /** fetch data from the table: "learning_learner_event_log" */
  learning_learner_event_log: Array<Learning_Learner_Event_Log>
  /** fetch aggregated fields from the table: "learning_learner_event_log" */
  learning_learner_event_log_aggregate: Learning_Learner_Event_Log_Aggregate
  /** fetch data from the table: "learning_learner_event_log" using primary key columns */
  learning_learner_event_log_by_pk?: Maybe<Learning_Learner_Event_Log>
  /** fetch data from the table in a streaming manner: "learning_learner_event_log" */
  learning_learner_event_log_stream: Array<Learning_Learner_Event_Log>
  /** fetch data from the table: "learning_learner_module_rating_questions" */
  learning_learner_module_rating_questions: Array<Learning_Learner_Module_Rating_Questions>
  /** fetch aggregated fields from the table: "learning_learner_module_rating_questions" */
  learning_learner_module_rating_questions_aggregate: Learning_Learner_Module_Rating_Questions_Aggregate
  /** fetch data from the table: "learning_learner_module_rating_questions" using primary key columns */
  learning_learner_module_rating_questions_by_pk?: Maybe<Learning_Learner_Module_Rating_Questions>
  /** fetch data from the table in a streaming manner: "learning_learner_module_rating_questions" */
  learning_learner_module_rating_questions_stream: Array<Learning_Learner_Module_Rating_Questions>
  /** fetch data from the table: "learning_learner_module_rating_responses" */
  learning_learner_module_rating_responses: Array<Learning_Learner_Module_Rating_Responses>
  /** fetch aggregated fields from the table: "learning_learner_module_rating_responses" */
  learning_learner_module_rating_responses_aggregate: Learning_Learner_Module_Rating_Responses_Aggregate
  /** fetch data from the table: "learning_learner_module_rating_responses" using primary key columns */
  learning_learner_module_rating_responses_by_pk?: Maybe<Learning_Learner_Module_Rating_Responses>
  /** fetch data from the table in a streaming manner: "learning_learner_module_rating_responses" */
  learning_learner_module_rating_responses_stream: Array<Learning_Learner_Module_Rating_Responses>
  /** fetch data from the table: "learning_learner_module_ratings" */
  learning_learner_module_ratings: Array<Learning_Learner_Module_Ratings>
  /** fetch aggregated fields from the table: "learning_learner_module_ratings" */
  learning_learner_module_ratings_aggregate: Learning_Learner_Module_Ratings_Aggregate
  /** fetch data from the table: "learning_learner_module_ratings" using primary key columns */
  learning_learner_module_ratings_by_pk?: Maybe<Learning_Learner_Module_Ratings>
  /** fetch data from the table in a streaming manner: "learning_learner_module_ratings" */
  learning_learner_module_ratings_stream: Array<Learning_Learner_Module_Ratings>
  /** fetch data from the table: "learning_learner_module_star_ratings" */
  learning_learner_module_star_ratings: Array<Learning_Learner_Module_Star_Ratings>
  /** fetch aggregated fields from the table: "learning_learner_module_star_ratings" */
  learning_learner_module_star_ratings_aggregate: Learning_Learner_Module_Star_Ratings_Aggregate
  /** fetch data from the table: "learning_learner_module_star_ratings" using primary key columns */
  learning_learner_module_star_ratings_by_pk?: Maybe<Learning_Learner_Module_Star_Ratings>
  /** fetch data from the table in a streaming manner: "learning_learner_module_star_ratings" */
  learning_learner_module_star_ratings_stream: Array<Learning_Learner_Module_Star_Ratings>
  /** fetch data from the table: "learning_learner_reflections" */
  learning_learner_reflections: Array<Learning_Learner_Reflections>
  /** fetch aggregated fields from the table: "learning_learner_reflections" */
  learning_learner_reflections_aggregate: Learning_Learner_Reflections_Aggregate
  /** fetch data from the table: "learning_learner_reflections" using primary key columns */
  learning_learner_reflections_by_pk?: Maybe<Learning_Learner_Reflections>
  /** fetch data from the table in a streaming manner: "learning_learner_reflections" */
  learning_learner_reflections_stream: Array<Learning_Learner_Reflections>
  /** fetch data from the table: "learning_learner_session_events" */
  learning_learner_session_events: Array<Learning_Learner_Session_Events>
  /** fetch aggregated fields from the table: "learning_learner_session_events" */
  learning_learner_session_events_aggregate: Learning_Learner_Session_Events_Aggregate
  /** execute function "learning_learner_session_events_by_period_within_range" which returns "learning_learner_session_events" */
  learning_learner_session_events_by_period_within_range: Array<Learning_Learner_Session_Events>
  /** execute function "learning_learner_session_events_by_period_within_range" and query aggregates on result of table type "learning_learner_session_events" */
  learning_learner_session_events_by_period_within_range_aggregate: Learning_Learner_Session_Events_Aggregate
  /** fetch data from the table in a streaming manner: "learning_learner_session_events" */
  learning_learner_session_events_stream: Array<Learning_Learner_Session_Events>
  /** execute function "learning_learner_session_events_within_range" which returns "learning_learner_session_events" */
  learning_learner_session_events_within_range: Array<Learning_Learner_Session_Events>
  /** execute function "learning_learner_session_events_within_range" and query aggregates on result of table type "learning_learner_session_events" */
  learning_learner_session_events_within_range_aggregate: Learning_Learner_Session_Events_Aggregate
  /** fetch data from the table: "learning_learner_session_feedback" */
  learning_learner_session_feedback: Array<Learning_Learner_Session_Feedback>
  /** fetch aggregated fields from the table: "learning_learner_session_feedback" */
  learning_learner_session_feedback_aggregate: Learning_Learner_Session_Feedback_Aggregate
  /** fetch data from the table in a streaming manner: "learning_learner_session_feedback" */
  learning_learner_session_feedback_stream: Array<Learning_Learner_Session_Feedback>
  /** fetch data from the table: "learning_learner_sessions" */
  learning_learner_sessions: Array<Learning_Learner_Sessions>
  /** fetch aggregated fields from the table: "learning_learner_sessions" */
  learning_learner_sessions_aggregate: Learning_Learner_Sessions_Aggregate
  /** fetch data from the table: "learning_learner_sessions" using primary key columns */
  learning_learner_sessions_by_pk?: Maybe<Learning_Learner_Sessions>
  /** fetch data from the table in a streaming manner: "learning_learner_sessions" */
  learning_learner_sessions_stream: Array<Learning_Learner_Sessions>
  /** fetch data from the table: "learning_module_event_log" */
  learning_module_event_log: Array<Learning_Module_Event_Log>
  /** fetch aggregated fields from the table: "learning_module_event_log" */
  learning_module_event_log_aggregate: Learning_Module_Event_Log_Aggregate
  /** fetch data from the table: "learning_module_event_log" using primary key columns */
  learning_module_event_log_by_pk?: Maybe<Learning_Module_Event_Log>
  /** fetch data from the table in a streaming manner: "learning_module_event_log" */
  learning_module_event_log_stream: Array<Learning_Module_Event_Log>
  /** fetch data from the table: "learning_module_event_transitions" */
  learning_module_event_transitions: Array<Learning_Module_Event_Transitions>
  /** fetch aggregated fields from the table: "learning_module_event_transitions" */
  learning_module_event_transitions_aggregate: Learning_Module_Event_Transitions_Aggregate
  /** fetch data from the table: "learning_module_event_transitions" using primary key columns */
  learning_module_event_transitions_by_pk?: Maybe<Learning_Module_Event_Transitions>
  /** fetch data from the table in a streaming manner: "learning_module_event_transitions" */
  learning_module_event_transitions_stream: Array<Learning_Module_Event_Transitions>
  /** fetch data from the table: "learning_module_exists" */
  learning_module_exists: Array<Learning_Module_Exists>
  /** fetch aggregated fields from the table: "learning_module_exists" */
  learning_module_exists_aggregate: Learning_Module_Exists_Aggregate
  /** fetch data from the table in a streaming manner: "learning_module_exists" */
  learning_module_exists_stream: Array<Learning_Module_Exists>
  /** fetch data from the table: "learning_module_history" */
  learning_module_history: Array<Learning_Module_History>
  /** fetch aggregated fields from the table: "learning_module_history" */
  learning_module_history_aggregate: Learning_Module_History_Aggregate
  /** fetch data from the table: "learning_module_history" using primary key columns */
  learning_module_history_by_pk?: Maybe<Learning_Module_History>
  /** fetch data from the table in a streaming manner: "learning_module_history" */
  learning_module_history_stream: Array<Learning_Module_History>
  /** fetch data from the table: "learning_module_revision_pages" */
  learning_module_revision_pages: Array<Learning_Module_Revision_Pages>
  /** fetch aggregated fields from the table: "learning_module_revision_pages" */
  learning_module_revision_pages_aggregate: Learning_Module_Revision_Pages_Aggregate
  /** fetch data from the table: "learning_module_revision_pages" using primary key columns */
  learning_module_revision_pages_by_pk?: Maybe<Learning_Module_Revision_Pages>
  /** fetch data from the table in a streaming manner: "learning_module_revision_pages" */
  learning_module_revision_pages_stream: Array<Learning_Module_Revision_Pages>
  /** fetch data from the table: "learning_module_revisions" */
  learning_module_revisions: Array<Learning_Module_Revisions>
  /** fetch aggregated fields from the table: "learning_module_revisions" */
  learning_module_revisions_aggregate: Learning_Module_Revisions_Aggregate
  /** fetch data from the table: "learning_module_revisions" using primary key columns */
  learning_module_revisions_by_pk?: Maybe<Learning_Module_Revisions>
  /** fetch data from the table in a streaming manner: "learning_module_revisions" */
  learning_module_revisions_stream: Array<Learning_Module_Revisions>
  /** fetch data from the table: "learning_module_star_ratings" */
  learning_module_star_ratings: Array<Learning_Module_Star_Ratings>
  /** fetch aggregated fields from the table: "learning_module_star_ratings" */
  learning_module_star_ratings_aggregate: Learning_Module_Star_Ratings_Aggregate
  /** fetch data from the table in a streaming manner: "learning_module_star_ratings" */
  learning_module_star_ratings_stream: Array<Learning_Module_Star_Ratings>
  /** fetch data from the table in a streaming manner: "learning_modules" */
  learning_modules_stream: Array<Learning_Modules>
  /** fetch data from the table: "learning_pages" using primary key columns */
  learning_page_by_pk?: Maybe<Learning_Pages>
  /** fetch data from the table: "learning_pages" */
  learning_pages: Array<Learning_Pages>
  /** fetch aggregated fields from the table: "learning_pages" */
  learning_pages_aggregate: Learning_Pages_Aggregate
  /** fetch data from the table in a streaming manner: "learning_pages" */
  learning_pages_stream: Array<Learning_Pages>
  /** fetch data from the table: "learning_questions" */
  learning_questions: Array<Learning_Questions>
  /** fetch aggregated fields from the table: "learning_questions" */
  learning_questions_aggregate: Learning_Questions_Aggregate
  /** fetch data from the table: "learning_questions" using primary key columns */
  learning_questions_by_pk?: Maybe<Learning_Questions>
  /** fetch data from the table in a streaming manner: "learning_questions" */
  learning_questions_stream: Array<Learning_Questions>
  /** fetch data from the table: "learning_series_discount_groups" */
  learning_series_discount_groups: Array<Learning_Series_Discount_Groups>
  /** fetch aggregated fields from the table: "learning_series_discount_groups" */
  learning_series_discount_groups_aggregate: Learning_Series_Discount_Groups_Aggregate
  /** fetch data from the table: "learning_series_discount_groups" using primary key columns */
  learning_series_discount_groups_by_pk?: Maybe<Learning_Series_Discount_Groups>
  /** fetch data from the table in a streaming manner: "learning_series_discount_groups" */
  learning_series_discount_groups_stream: Array<Learning_Series_Discount_Groups>
  /** fetch data from the table: "learning_series_discount_settings" */
  learning_series_discount_settings: Array<Learning_Series_Discount_Settings>
  /** fetch aggregated fields from the table: "learning_series_discount_settings" */
  learning_series_discount_settings_aggregate: Learning_Series_Discount_Settings_Aggregate
  /** fetch data from the table: "learning_series_discount_settings" using primary key columns */
  learning_series_discount_settings_by_pk?: Maybe<Learning_Series_Discount_Settings>
  /** fetch data from the table in a streaming manner: "learning_series_discount_settings" */
  learning_series_discount_settings_stream: Array<Learning_Series_Discount_Settings>
  /** fetch data from the table: "learning_series_star_ratings" */
  learning_series_star_ratings: Array<Learning_Series_Star_Ratings>
  /** fetch aggregated fields from the table: "learning_series_star_ratings" */
  learning_series_star_ratings_aggregate: Learning_Series_Star_Ratings_Aggregate
  /** fetch data from the table in a streaming manner: "learning_series_star_ratings" */
  learning_series_star_ratings_stream: Array<Learning_Series_Star_Ratings>
  /** fetch data from the table in a streaming manner: "learning_series" */
  learning_series_stream: Array<Learning_Series>
  /** An array relationship */
  learning_series_subscription_settings: Array<Learning_Series_Subscription_Settings>
  /** An aggregate relationship */
  learning_series_subscription_settings_aggregate: Learning_Series_Subscription_Settings_Aggregate
  /** fetch data from the table: "learning_series_subscription_settings" using primary key columns */
  learning_series_subscription_settings_by_pk?: Maybe<Learning_Series_Subscription_Settings>
  /** fetch data from the table: "learning_series_subscription_settings_discount_type" */
  learning_series_subscription_settings_discount_type: Array<Learning_Series_Subscription_Settings_Discount_Type>
  /** fetch aggregated fields from the table: "learning_series_subscription_settings_discount_type" */
  learning_series_subscription_settings_discount_type_aggregate: Learning_Series_Subscription_Settings_Discount_Type_Aggregate
  /** fetch data from the table: "learning_series_subscription_settings_discount_type" using primary key columns */
  learning_series_subscription_settings_discount_type_by_pk?: Maybe<Learning_Series_Subscription_Settings_Discount_Type>
  /** fetch data from the table in a streaming manner: "learning_series_subscription_settings_discount_type" */
  learning_series_subscription_settings_discount_type_stream: Array<Learning_Series_Subscription_Settings_Discount_Type>
  /** fetch data from the table in a streaming manner: "learning_series_subscription_settings" */
  learning_series_subscription_settings_stream: Array<Learning_Series_Subscription_Settings>
  /** fetch data from the table: "learning_star_ratings" */
  learning_star_ratings: Array<Learning_Star_Ratings>
  /** fetch aggregated fields from the table: "learning_star_ratings" */
  learning_star_ratings_aggregate: Learning_Star_Ratings_Aggregate
  /** execute function "learning_star_ratings_for_module_within_range" which returns "learning_module_star_ratings" */
  learning_star_ratings_for_module_within_range: Array<Learning_Module_Star_Ratings>
  /** execute function "learning_star_ratings_for_module_within_range" and query aggregates on result of table type "learning_module_star_ratings" */
  learning_star_ratings_for_module_within_range_aggregate: Learning_Module_Star_Ratings_Aggregate
  /** execute function "learning_star_ratings_in_library_within_range" which returns "learning_star_ratings" */
  learning_star_ratings_in_library_within_range: Array<Learning_Star_Ratings>
  /** execute function "learning_star_ratings_in_library_within_range" and query aggregates on result of table type "learning_star_ratings" */
  learning_star_ratings_in_library_within_range_aggregate: Learning_Star_Ratings_Aggregate
  /** execute function "learning_star_ratings_in_series_within_range" which returns "learning_series_star_ratings" */
  learning_star_ratings_in_series_within_range: Array<Learning_Series_Star_Ratings>
  /** execute function "learning_star_ratings_in_series_within_range" and query aggregates on result of table type "learning_series_star_ratings" */
  learning_star_ratings_in_series_within_range_aggregate: Learning_Series_Star_Ratings_Aggregate
  /** fetch data from the table in a streaming manner: "learning_star_ratings" */
  learning_star_ratings_stream: Array<Learning_Star_Ratings>
  /** execute function "learning_star_ratings_within_range" which returns "learning_star_ratings" */
  learning_star_ratings_within_range: Array<Learning_Star_Ratings>
  /** execute function "learning_star_ratings_within_range" and query aggregates on result of table type "learning_star_ratings" */
  learning_star_ratings_within_range_aggregate: Learning_Star_Ratings_Aggregate
  /** fetch data from the table: "learning_stats_learner_module_rating_response_averages" */
  learning_stats_learner_module_rating_response_averages: Array<Learning_Stats_Learner_Module_Rating_Response_Averages>
  /** fetch aggregated fields from the table: "learning_stats_learner_module_rating_response_averages" */
  learning_stats_learner_module_rating_response_averages_aggregate: Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate
  /** fetch data from the table in a streaming manner: "learning_stats_learner_module_rating_response_averages" */
  learning_stats_learner_module_rating_response_averages_stream: Array<Learning_Stats_Learner_Module_Rating_Response_Averages>
  /** execute function "learning_stats_module_rating_response_averages_within_range" which returns "learning_stats_learner_module_rating_response_averages" */
  learning_stats_module_rating_response_averages_within_range: Array<Learning_Stats_Learner_Module_Rating_Response_Averages>
  /** execute function "learning_stats_module_rating_response_averages_within_range" and query aggregates on result of table type "learning_stats_learner_module_rating_response_averages" */
  learning_stats_module_rating_response_averages_within_range_aggregate: Learning_Stats_Learner_Module_Rating_Response_Averages_Aggregate
  /** fetch data from the table: "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses: Array<Learning_Stats_Module_Rating_Responses>
  /** fetch aggregated fields from the table: "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_aggregate: Learning_Stats_Module_Rating_Responses_Aggregate
  /** execute function "learning_stats_module_rating_responses_for_module_within_range" which returns "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_for_module_within_range: Array<Learning_Stats_Module_Rating_Responses>
  /** execute function "learning_stats_module_rating_responses_for_module_within_range" and query aggregates on result of table type "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_for_module_within_range_aggregate: Learning_Stats_Module_Rating_Responses_Aggregate
  /** execute function "learning_stats_module_rating_responses_in_library_within_range" which returns "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_in_library_within_range: Array<Learning_Stats_Module_Rating_Responses>
  /** execute function "learning_stats_module_rating_responses_in_library_within_range" and query aggregates on result of table type "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_in_library_within_range_aggregate: Learning_Stats_Module_Rating_Responses_Aggregate
  /** execute function "learning_stats_module_rating_responses_in_series_within_range" which returns "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_in_series_within_range: Array<Learning_Stats_Module_Rating_Responses>
  /** execute function "learning_stats_module_rating_responses_in_series_within_range" and query aggregates on result of table type "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_in_series_within_range_aggregate: Learning_Stats_Module_Rating_Responses_Aggregate
  /** fetch data from the table in a streaming manner: "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_stream: Array<Learning_Stats_Module_Rating_Responses>
  /** execute function "learning_stats_module_rating_responses_within_range" which returns "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_within_range: Array<Learning_Stats_Module_Rating_Responses>
  /** execute function "learning_stats_module_rating_responses_within_range" and query aggregates on result of table type "learning_stats_module_rating_responses" */
  learning_stats_module_rating_responses_within_range_aggregate: Learning_Stats_Module_Rating_Responses_Aggregate
  /** execute function "learning_stats_most_active_libraries_by_period_within_range" which returns "learning_stats_sessions_by_period_by_library" */
  learning_stats_most_active_libraries_by_period_within_range: Array<Learning_Stats_Sessions_By_Period_By_Library>
  /** execute function "learning_stats_most_active_libraries_by_period_within_range" and query aggregates on result of table type "learning_stats_sessions_by_period_by_library" */
  learning_stats_most_active_libraries_by_period_within_range_aggregate: Learning_Stats_Sessions_By_Period_By_Library_Aggregate
  /** execute function "learning_stats_most_active_libraries_within_range" which returns "learning_stats_sessions_by_library" */
  learning_stats_most_active_libraries_within_range: Array<Learning_Stats_Sessions_By_Library>
  /** execute function "learning_stats_most_active_libraries_within_range" and query aggregates on result of table type "learning_stats_sessions_by_library" */
  learning_stats_most_active_libraries_within_range_aggregate: Learning_Stats_Sessions_By_Library_Aggregate
  /** execute function "learning_stats_most_active_modules_in_library_within_range" which returns "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_in_library_within_range: Array<Learning_Stats_Sessions_By_Module>
  /** execute function "learning_stats_most_active_modules_in_library_within_range" and query aggregates on result of table type "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_in_library_within_range_aggregate: Learning_Stats_Sessions_By_Module_Aggregate
  /** execute function "learning_stats_most_active_modules_in_series_within_range" which returns "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_in_series_within_range: Array<Learning_Stats_Sessions_By_Module>
  /** execute function "learning_stats_most_active_modules_in_series_within_range" and query aggregates on result of table type "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_in_series_within_range_aggregate: Learning_Stats_Sessions_By_Module_Aggregate
  /** execute function "learning_stats_most_active_modules_within_range" which returns "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_within_range: Array<Learning_Stats_Sessions_By_Module>
  /** execute function "learning_stats_most_active_modules_within_range" and query aggregates on result of table type "learning_stats_sessions_by_module" */
  learning_stats_most_active_modules_within_range_aggregate: Learning_Stats_Sessions_By_Module_Aggregate
  /** execute function "learning_stats_most_active_series_within_range" which returns "learning_stats_sessions_by_series" */
  learning_stats_most_active_series_within_range: Array<Learning_Stats_Sessions_By_Series>
  /** execute function "learning_stats_most_active_series_within_range" and query aggregates on result of table type "learning_stats_sessions_by_series" */
  learning_stats_most_active_series_within_range_aggregate: Learning_Stats_Sessions_By_Series_Aggregate
  /** execute function "learning_stats_most_active_users_for_module_within_range" which returns "learning_stats_sessions_by_module_by_user" */
  learning_stats_most_active_users_for_module_within_range: Array<Learning_Stats_Sessions_By_Module_By_User>
  /** execute function "learning_stats_most_active_users_for_module_within_range" and query aggregates on result of table type "learning_stats_sessions_by_module_by_user" */
  learning_stats_most_active_users_for_module_within_range_aggregate: Learning_Stats_Sessions_By_Module_By_User_Aggregate
  /** execute function "learning_stats_most_active_users_in_library_within_range" which returns "learning_stats_sessions_by_library_by_user" */
  learning_stats_most_active_users_in_library_within_range: Array<Learning_Stats_Sessions_By_Library_By_User>
  /** execute function "learning_stats_most_active_users_in_library_within_range" and query aggregates on result of table type "learning_stats_sessions_by_library_by_user" */
  learning_stats_most_active_users_in_library_within_range_aggregate: Learning_Stats_Sessions_By_Library_By_User_Aggregate
  /** execute function "learning_stats_most_active_users_in_series_within_range" which returns "learning_stats_sessions_by_series_by_user" */
  learning_stats_most_active_users_in_series_within_range: Array<Learning_Stats_Sessions_By_Series_By_User>
  /** execute function "learning_stats_most_active_users_in_series_within_range" and query aggregates on result of table type "learning_stats_sessions_by_series_by_user" */
  learning_stats_most_active_users_in_series_within_range_aggregate: Learning_Stats_Sessions_By_Series_By_User_Aggregate
  /** execute function "learning_stats_most_active_users_within_range" which returns "learning_stats_sessions_by_user" */
  learning_stats_most_active_users_within_range: Array<Learning_Stats_Sessions_By_User>
  /** execute function "learning_stats_most_active_users_within_range" and query aggregates on result of table type "learning_stats_sessions_by_user" */
  learning_stats_most_active_users_within_range_aggregate: Learning_Stats_Sessions_By_User_Aggregate
  /** fetch data from the table: "learning_stats_rating_response_averages" */
  learning_stats_rating_response_averages: Array<Learning_Stats_Rating_Response_Averages>
  /** fetch aggregated fields from the table: "learning_stats_rating_response_averages" */
  learning_stats_rating_response_averages_aggregate: Learning_Stats_Rating_Response_Averages_Aggregate
  /** fetch data from the table in a streaming manner: "learning_stats_rating_response_averages" */
  learning_stats_rating_response_averages_stream: Array<Learning_Stats_Rating_Response_Averages>
  /** execute function "learning_stats_rating_response_averages_within_range" which returns "learning_stats_rating_response_averages" */
  learning_stats_rating_response_averages_within_range: Array<Learning_Stats_Rating_Response_Averages>
  /** execute function "learning_stats_rating_response_averages_within_range" and query aggregates on result of table type "learning_stats_rating_response_averages" */
  learning_stats_rating_response_averages_within_range_aggregate: Learning_Stats_Rating_Response_Averages_Aggregate
  /** fetch data from the table: "learning_stats_rating_responses" */
  learning_stats_rating_responses: Array<Learning_Stats_Rating_Responses>
  /** fetch aggregated fields from the table: "learning_stats_rating_responses" */
  learning_stats_rating_responses_aggregate: Learning_Stats_Rating_Responses_Aggregate
  /** execute function "learning_stats_rating_responses_in_library_within_range" which returns "learning_stats_rating_responses" */
  learning_stats_rating_responses_in_library_within_range: Array<Learning_Stats_Rating_Responses>
  /** execute function "learning_stats_rating_responses_in_library_within_range" and query aggregates on result of table type "learning_stats_rating_responses" */
  learning_stats_rating_responses_in_library_within_range_aggregate: Learning_Stats_Rating_Responses_Aggregate
  /** execute function "learning_stats_rating_responses_in_series_within_range" which returns "learning_stats_rating_responses" */
  learning_stats_rating_responses_in_series_within_range: Array<Learning_Stats_Rating_Responses>
  /** execute function "learning_stats_rating_responses_in_series_within_range" and query aggregates on result of table type "learning_stats_rating_responses" */
  learning_stats_rating_responses_in_series_within_range_aggregate: Learning_Stats_Rating_Responses_Aggregate
  /** fetch data from the table in a streaming manner: "learning_stats_rating_responses" */
  learning_stats_rating_responses_stream: Array<Learning_Stats_Rating_Responses>
  /** execute function "learning_stats_rating_responses_within_range" which returns "learning_stats_rating_responses" */
  learning_stats_rating_responses_within_range: Array<Learning_Stats_Rating_Responses>
  /** execute function "learning_stats_rating_responses_within_range" and query aggregates on result of table type "learning_stats_rating_responses" */
  learning_stats_rating_responses_within_range_aggregate: Learning_Stats_Rating_Responses_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_library" */
  learning_stats_sessions_by_library: Array<Learning_Stats_Sessions_By_Library>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_library" */
  learning_stats_sessions_by_library_aggregate: Learning_Stats_Sessions_By_Library_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_library_by_module" */
  learning_stats_sessions_by_library_by_module: Array<Learning_Stats_Sessions_By_Library_By_Module>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_library_by_module" */
  learning_stats_sessions_by_library_by_module_aggregate: Learning_Stats_Sessions_By_Library_By_Module_Aggregate
  /** fetch data from the table in a streaming manner: "learning_stats_sessions_by_library_by_module" */
  learning_stats_sessions_by_library_by_module_stream: Array<Learning_Stats_Sessions_By_Library_By_Module>
  /** fetch data from the table: "learning_stats_sessions_by_library_by_user" */
  learning_stats_sessions_by_library_by_user: Array<Learning_Stats_Sessions_By_Library_By_User>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_library_by_user" */
  learning_stats_sessions_by_library_by_user_aggregate: Learning_Stats_Sessions_By_Library_By_User_Aggregate
  /** fetch data from the table in a streaming manner: "learning_stats_sessions_by_library_by_user" */
  learning_stats_sessions_by_library_by_user_stream: Array<Learning_Stats_Sessions_By_Library_By_User>
  /** fetch data from the table in a streaming manner: "learning_stats_sessions_by_library" */
  learning_stats_sessions_by_library_stream: Array<Learning_Stats_Sessions_By_Library>
  /** fetch data from the table: "learning_stats_sessions_by_module" */
  learning_stats_sessions_by_module: Array<Learning_Stats_Sessions_By_Module>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_module" */
  learning_stats_sessions_by_module_aggregate: Learning_Stats_Sessions_By_Module_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_module_by_user" */
  learning_stats_sessions_by_module_by_user: Array<Learning_Stats_Sessions_By_Module_By_User>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_module_by_user" */
  learning_stats_sessions_by_module_by_user_aggregate: Learning_Stats_Sessions_By_Module_By_User_Aggregate
  /** fetch data from the table in a streaming manner: "learning_stats_sessions_by_module_by_user" */
  learning_stats_sessions_by_module_by_user_stream: Array<Learning_Stats_Sessions_By_Module_By_User>
  /** fetch data from the table in a streaming manner: "learning_stats_sessions_by_module" */
  learning_stats_sessions_by_module_stream: Array<Learning_Stats_Sessions_By_Module>
  /** fetch data from the table: "learning_stats_sessions_by_period_by_library" */
  learning_stats_sessions_by_period_by_library: Array<Learning_Stats_Sessions_By_Period_By_Library>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_period_by_library" */
  learning_stats_sessions_by_period_by_library_aggregate: Learning_Stats_Sessions_By_Period_By_Library_Aggregate
  /** fetch data from the table in a streaming manner: "learning_stats_sessions_by_period_by_library" */
  learning_stats_sessions_by_period_by_library_stream: Array<Learning_Stats_Sessions_By_Period_By_Library>
  /** fetch data from the table: "learning_stats_sessions_by_series" */
  learning_stats_sessions_by_series: Array<Learning_Stats_Sessions_By_Series>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_series" */
  learning_stats_sessions_by_series_aggregate: Learning_Stats_Sessions_By_Series_Aggregate
  /** fetch data from the table: "learning_stats_sessions_by_series_by_module" */
  learning_stats_sessions_by_series_by_module: Array<Learning_Stats_Sessions_By_Series_By_Module>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_series_by_module" */
  learning_stats_sessions_by_series_by_module_aggregate: Learning_Stats_Sessions_By_Series_By_Module_Aggregate
  /** fetch data from the table in a streaming manner: "learning_stats_sessions_by_series_by_module" */
  learning_stats_sessions_by_series_by_module_stream: Array<Learning_Stats_Sessions_By_Series_By_Module>
  /** fetch data from the table: "learning_stats_sessions_by_series_by_user" */
  learning_stats_sessions_by_series_by_user: Array<Learning_Stats_Sessions_By_Series_By_User>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_series_by_user" */
  learning_stats_sessions_by_series_by_user_aggregate: Learning_Stats_Sessions_By_Series_By_User_Aggregate
  /** fetch data from the table in a streaming manner: "learning_stats_sessions_by_series_by_user" */
  learning_stats_sessions_by_series_by_user_stream: Array<Learning_Stats_Sessions_By_Series_By_User>
  /** fetch data from the table in a streaming manner: "learning_stats_sessions_by_series" */
  learning_stats_sessions_by_series_stream: Array<Learning_Stats_Sessions_By_Series>
  /** fetch data from the table: "learning_stats_sessions_by_user" */
  learning_stats_sessions_by_user: Array<Learning_Stats_Sessions_By_User>
  /** fetch aggregated fields from the table: "learning_stats_sessions_by_user" */
  learning_stats_sessions_by_user_aggregate: Learning_Stats_Sessions_By_User_Aggregate
  /** fetch data from the table in a streaming manner: "learning_stats_sessions_by_user" */
  learning_stats_sessions_by_user_stream: Array<Learning_Stats_Sessions_By_User>
  /** fetch data from the table: "learning_uploads" */
  learning_uploads: Array<Learning_Uploads>
  /** fetch aggregated fields from the table: "learning_uploads" */
  learning_uploads_aggregate: Learning_Uploads_Aggregate
  /** fetch data from the table: "learning_uploads" using primary key columns */
  learning_uploads_by_pk?: Maybe<Learning_Uploads>
  /** fetch data from the table in a streaming manner: "learning_uploads" */
  learning_uploads_stream: Array<Learning_Uploads>
  /** fetch data from the table: "learning_modules" using primary key columns */
  module_by_pk?: Maybe<Learning_Modules>
  /** An array relationship */
  modules: Array<Learning_Modules>
  /** An aggregate relationship */
  modules_aggregate: Learning_Modules_Aggregate
  /** An array relationship */
  page_categories: Array<Page_Categories>
  /** An aggregate relationship */
  page_categories_aggregate: Page_Categories_Aggregate
  /** fetch data from the table: "page_categories" using primary key columns */
  page_categories_by_pk?: Maybe<Page_Categories>
  /** fetch data from the table in a streaming manner: "page_categories" */
  page_categories_stream: Array<Page_Categories>
  /** fetch data from the table: "pages" */
  pages: Array<Pages>
  /** fetch aggregated fields from the table: "pages" */
  pages_aggregate: Pages_Aggregate
  /** fetch data from the table: "pages" using primary key columns */
  pages_by_pk?: Maybe<Pages>
  /** fetch data from the table in a streaming manner: "pages" */
  pages_stream: Array<Pages>
  /** fetch data from the table: "post_categories" */
  post_categories: Array<Post_Categories>
  /** fetch aggregated fields from the table: "post_categories" */
  post_categories_aggregate: Post_Categories_Aggregate
  /** fetch data from the table: "post_categories" using primary key columns */
  post_categories_by_pk?: Maybe<Post_Categories>
  /** fetch data from the table in a streaming manner: "post_categories" */
  post_categories_stream: Array<Post_Categories>
  /** fetch data from the table: "post_comments" */
  post_comments: Array<Post_Comments>
  /** fetch aggregated fields from the table: "post_comments" */
  post_comments_aggregate: Post_Comments_Aggregate
  /** fetch data from the table: "post_comments" using primary key columns */
  post_comments_by_pk?: Maybe<Post_Comments>
  /** fetch data from the table in a streaming manner: "post_comments" */
  post_comments_stream: Array<Post_Comments>
  /** An array relationship */
  posts: Array<Posts>
  /** An aggregate relationship */
  posts_aggregate: Posts_Aggregate
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>
  /** fetch data from the table in a streaming manner: "posts" */
  posts_stream: Array<Posts>
  /** fetch data from the table: "resource_dependents" */
  resource_dependents: Array<Resource_Dependents>
  /** fetch aggregated fields from the table: "resource_dependents" */
  resource_dependents_aggregate: Resource_Dependents_Aggregate
  /** fetch data from the table in a streaming manner: "resource_dependents" */
  resource_dependents_stream: Array<Resource_Dependents>
  /** An array relationship */
  resources: Array<Resources>
  /** An aggregate relationship */
  resources_aggregate: Resources_Aggregate
  /** fetch data from the table: "resources" using primary key columns */
  resources_by_pk?: Maybe<Resources>
  /** fetch data from the table in a streaming manner: "resources" */
  resources_stream: Array<Resources>
  /** execute function "search_modules" which returns "learning_modules" */
  search_modules: Array<Learning_Modules>
  /** execute function "search_modules" and query aggregates on result of table type "learning_modules" */
  search_modules_aggregate: Learning_Modules_Aggregate
  /** fetch data from the table: "segment_user" */
  segment_user: Array<Segment_User>
  /** fetch aggregated fields from the table: "segment_user" */
  segment_user_aggregate: Segment_User_Aggregate
  /** fetch data from the table: "segment_user" using primary key columns */
  segment_user_by_pk?: Maybe<Segment_User>
  /** fetch data from the table in a streaming manner: "segment_user" */
  segment_user_stream: Array<Segment_User>
  /** An array relationship */
  series: Array<Learning_Series>
  /** An aggregate relationship */
  series_aggregate: Learning_Series_Aggregate
  /** fetch data from the table: "learning_series" using primary key columns */
  series_by_pk?: Maybe<Learning_Series>
  /** fetch data from the table: "slugs" */
  slugs: Array<Slugs>
  /** fetch aggregated fields from the table: "slugs" */
  slugs_aggregate: Slugs_Aggregate
  /** fetch data from the table: "slugs" using primary key columns */
  slugs_by_pk?: Maybe<Slugs>
  /** fetch data from the table in a streaming manner: "slugs" */
  slugs_stream: Array<Slugs>
  /** fetch data from the table: "user_group_members" */
  user_group_members: Array<User_Group_Members>
  /** fetch aggregated fields from the table: "user_group_members" */
  user_group_members_aggregate: User_Group_Members_Aggregate
  /** fetch data from the table: "user_group_members" using primary key columns */
  user_group_members_by_pk?: Maybe<User_Group_Members>
  /** fetch data from the table: "user_group_members_roles" */
  user_group_members_roles: Array<User_Group_Members_Roles>
  /** fetch aggregated fields from the table: "user_group_members_roles" */
  user_group_members_roles_aggregate: User_Group_Members_Roles_Aggregate
  /** fetch data from the table: "user_group_members_roles" using primary key columns */
  user_group_members_roles_by_pk?: Maybe<User_Group_Members_Roles>
  /** fetch data from the table in a streaming manner: "user_group_members_roles" */
  user_group_members_roles_stream: Array<User_Group_Members_Roles>
  /** fetch data from the table in a streaming manner: "user_group_members" */
  user_group_members_stream: Array<User_Group_Members>
  /** fetch data from the table: "users" */
  users: Array<Users>
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>
}

export type Subscription_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Categories_Order_By>>
  where?: InputMaybe<Categories_Bool_Exp>
}

export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Categories_Order_By>>
  where?: InputMaybe<Categories_Bool_Exp>
}

export type Subscription_RootCategories_By_PkArgs = {
  id: Scalars['Int']
}

export type Subscription_RootCategories_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Categories_Stream_Cursor_Input>>
  where?: InputMaybe<Categories_Bool_Exp>
}

export type Subscription_RootForm_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Form_Responses_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Form_Responses_Order_By>>
  where?: InputMaybe<Form_Responses_Bool_Exp>
}

export type Subscription_RootForm_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Form_Responses_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Form_Responses_Order_By>>
  where?: InputMaybe<Form_Responses_Bool_Exp>
}

export type Subscription_RootForm_Responses_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootForm_Responses_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Form_Responses_Stream_Cursor_Input>>
  where?: InputMaybe<Form_Responses_Bool_Exp>
}

export type Subscription_RootFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

export type Subscription_RootForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Forms_Order_By>>
  where?: InputMaybe<Forms_Bool_Exp>
}

export type Subscription_RootForms_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootForms_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Forms_Stream_Cursor_Input>>
  where?: InputMaybe<Forms_Bool_Exp>
}

export type Subscription_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Groups_Order_By>>
  where?: InputMaybe<Groups_Bool_Exp>
}

export type Subscription_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Groups_Order_By>>
  where?: InputMaybe<Groups_Bool_Exp>
}

export type Subscription_RootGroups_By_PkArgs = {
  id: Scalars['String']
}

export type Subscription_RootGroups_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Groups_Stream_Cursor_Input>>
  where?: InputMaybe<Groups_Bool_Exp>
}

export type Subscription_RootLearning_Featured_ModulesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Featured_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Featured_Modules_Order_By>>
  where?: InputMaybe<Learning_Featured_Modules_Bool_Exp>
}

export type Subscription_RootLearning_Featured_Modules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Featured_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Featured_Modules_Order_By>>
  where?: InputMaybe<Learning_Featured_Modules_Bool_Exp>
}

export type Subscription_RootLearning_Featured_Modules_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLearning_Featured_Modules_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Featured_Modules_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Featured_Modules_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Event_LogArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Learner_Event_Log_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Event_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Learner_Event_Log_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Event_Log_By_PkArgs = {
  id: Scalars['Int']
}

export type Subscription_RootLearning_Learner_Event_Log_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Learner_Event_Log_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Learner_Event_Log_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Module_Rating_QuestionsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Questions_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Questions_Order_By>
  >
  where?: InputMaybe<Learning_Learner_Module_Rating_Questions_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Module_Rating_Questions_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Learner_Module_Rating_Questions_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Learner_Module_Rating_Questions_Order_By>
    >
    where?: InputMaybe<Learning_Learner_Module_Rating_Questions_Bool_Exp>
  }

export type Subscription_RootLearning_Learner_Module_Rating_Questions_By_PkArgs =
  {
    id: Scalars['uuid']
  }

export type Subscription_RootLearning_Learner_Module_Rating_Questions_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Learner_Module_Rating_Questions_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Learner_Module_Rating_Questions_Bool_Exp>
  }

export type Subscription_RootLearning_Learner_Module_Rating_ResponsesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Learner_Module_Rating_Responses_Order_By>
  >
  where?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Module_Rating_Responses_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Learner_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Learner_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Learner_Module_Rating_Responses_By_PkArgs =
  {
    id: Scalars['uuid']
  }

export type Subscription_RootLearning_Learner_Module_Rating_Responses_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Learner_Module_Rating_Responses_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Learner_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Learner_Module_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Module_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Module_Ratings_Order_By>>
  where?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Module_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Module_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Module_Ratings_Order_By>>
  where?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Module_Ratings_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLearning_Learner_Module_Ratings_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Learner_Module_Ratings_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Learner_Module_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Module_Star_RatingsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Module_Star_Ratings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Module_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Learner_Module_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Module_Star_Ratings_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Learner_Module_Star_Ratings_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Learner_Module_Star_Ratings_Order_By>>
    where?: InputMaybe<Learning_Learner_Module_Star_Ratings_Bool_Exp>
  }

export type Subscription_RootLearning_Learner_Module_Star_Ratings_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLearning_Learner_Module_Star_Ratings_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<
    InputMaybe<Learning_Learner_Module_Star_Ratings_Stream_Cursor_Input>
  >
  where?: InputMaybe<Learning_Learner_Module_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Learner_ReflectionsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Reflections_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Reflections_Order_By>>
  where?: InputMaybe<Learning_Learner_Reflections_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Reflections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Reflections_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Reflections_Order_By>>
  where?: InputMaybe<Learning_Learner_Reflections_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Reflections_By_PkArgs = {
  module_id: Scalars['uuid']
  user_id: Scalars['Int']
}

export type Subscription_RootLearning_Learner_Reflections_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Learner_Reflections_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Learner_Reflections_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Session_EventsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Session_Events_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
  where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Session_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Session_Events_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
  where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Session_Events_By_Period_Within_RangeArgs =
  {
    args: Learning_Learner_Session_Events_By_Period_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Learner_Session_Events_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
    where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
  }

export type Subscription_RootLearning_Learner_Session_Events_By_Period_Within_Range_AggregateArgs =
  {
    args: Learning_Learner_Session_Events_By_Period_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Learner_Session_Events_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
    where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
  }

export type Subscription_RootLearning_Learner_Session_Events_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Learner_Session_Events_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Session_Events_Within_RangeArgs =
  {
    args: Learning_Learner_Session_Events_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Learner_Session_Events_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
    where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
  }

export type Subscription_RootLearning_Learner_Session_Events_Within_Range_AggregateArgs =
  {
    args: Learning_Learner_Session_Events_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Learner_Session_Events_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Learner_Session_Events_Order_By>>
    where?: InputMaybe<Learning_Learner_Session_Events_Bool_Exp>
  }

export type Subscription_RootLearning_Learner_Session_FeedbackArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Session_Feedback_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Session_Feedback_Order_By>>
  where?: InputMaybe<Learning_Learner_Session_Feedback_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Session_Feedback_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Learner_Session_Feedback_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Session_Feedback_Order_By>>
  where?: InputMaybe<Learning_Learner_Session_Feedback_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Session_Feedback_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<
    InputMaybe<Learning_Learner_Session_Feedback_Stream_Cursor_Input>
  >
  where?: InputMaybe<Learning_Learner_Session_Feedback_Bool_Exp>
}

export type Subscription_RootLearning_Learner_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Sessions_Order_By>>
  where?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Learner_Sessions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Learner_Sessions_Order_By>>
  where?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
}

export type Subscription_RootLearning_Learner_Sessions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLearning_Learner_Sessions_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Learner_Sessions_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Learner_Sessions_Bool_Exp>
}

export type Subscription_RootLearning_Module_Event_LogArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
}

export type Subscription_RootLearning_Module_Event_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Event_Log_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Event_Log_Order_By>>
  where?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
}

export type Subscription_RootLearning_Module_Event_Log_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLearning_Module_Event_Log_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Module_Event_Log_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Module_Event_Log_Bool_Exp>
}

export type Subscription_RootLearning_Module_Event_TransitionsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Module_Event_Transitions_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Event_Transitions_Order_By>>
  where?: InputMaybe<Learning_Module_Event_Transitions_Bool_Exp>
}

export type Subscription_RootLearning_Module_Event_Transitions_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Module_Event_Transitions_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Event_Transitions_Order_By>>
  where?: InputMaybe<Learning_Module_Event_Transitions_Bool_Exp>
}

export type Subscription_RootLearning_Module_Event_Transitions_By_PkArgs = {
  event: Scalars['learning_module_event']
  state: Scalars['learning_module_status']
}

export type Subscription_RootLearning_Module_Event_Transitions_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<
    InputMaybe<Learning_Module_Event_Transitions_Stream_Cursor_Input>
  >
  where?: InputMaybe<Learning_Module_Event_Transitions_Bool_Exp>
}

export type Subscription_RootLearning_Module_ExistsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Exists_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Exists_Order_By>>
  where?: InputMaybe<Learning_Module_Exists_Bool_Exp>
}

export type Subscription_RootLearning_Module_Exists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Exists_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Exists_Order_By>>
  where?: InputMaybe<Learning_Module_Exists_Bool_Exp>
}

export type Subscription_RootLearning_Module_Exists_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Module_Exists_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Module_Exists_Bool_Exp>
}

export type Subscription_RootLearning_Module_HistoryArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_History_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_History_Order_By>>
  where?: InputMaybe<Learning_Module_History_Bool_Exp>
}

export type Subscription_RootLearning_Module_History_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_History_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_History_Order_By>>
  where?: InputMaybe<Learning_Module_History_Bool_Exp>
}

export type Subscription_RootLearning_Module_History_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLearning_Module_History_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Module_History_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Module_History_Bool_Exp>
}

export type Subscription_RootLearning_Module_Revision_PagesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revision_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revision_Pages_Order_By>>
  where?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
}

export type Subscription_RootLearning_Module_Revision_Pages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revision_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revision_Pages_Order_By>>
  where?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
}

export type Subscription_RootLearning_Module_Revision_Pages_By_PkArgs = {
  page_id: Scalars['String']
  revision_id: Scalars['uuid']
}

export type Subscription_RootLearning_Module_Revision_Pages_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Module_Revision_Pages_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Module_Revision_Pages_Bool_Exp>
}

export type Subscription_RootLearning_Module_RevisionsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revisions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revisions_Order_By>>
  where?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
}

export type Subscription_RootLearning_Module_Revisions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Revisions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Revisions_Order_By>>
  where?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
}

export type Subscription_RootLearning_Module_Revisions_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLearning_Module_Revisions_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Module_Revisions_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Module_Revisions_Bool_Exp>
}

export type Subscription_RootLearning_Module_Star_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Module_Star_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Module_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Module_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Module_Star_Ratings_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Module_Star_Ratings_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Modules_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Modules_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

export type Subscription_RootLearning_Page_By_PkArgs = {
  id: Scalars['String']
}

export type Subscription_RootLearning_PagesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Pages_Order_By>>
  where?: InputMaybe<Learning_Pages_Bool_Exp>
}

export type Subscription_RootLearning_Pages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Pages_Order_By>>
  where?: InputMaybe<Learning_Pages_Bool_Exp>
}

export type Subscription_RootLearning_Pages_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Pages_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Pages_Bool_Exp>
}

export type Subscription_RootLearning_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Questions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Questions_Order_By>>
  where?: InputMaybe<Learning_Questions_Bool_Exp>
}

export type Subscription_RootLearning_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Questions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Questions_Order_By>>
  where?: InputMaybe<Learning_Questions_Bool_Exp>
}

export type Subscription_RootLearning_Questions_By_PkArgs = {
  id: Scalars['uuid']
  page_id: Scalars['String']
}

export type Subscription_RootLearning_Questions_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Questions_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Questions_Bool_Exp>
}

export type Subscription_RootLearning_Series_Discount_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Discount_Groups_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Groups_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Groups_Bool_Exp>
}

export type Subscription_RootLearning_Series_Discount_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Discount_Groups_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Groups_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Groups_Bool_Exp>
}

export type Subscription_RootLearning_Series_Discount_Groups_By_PkArgs = {
  discount_id: Scalars['uuid']
  group_id: Scalars['String']
}

export type Subscription_RootLearning_Series_Discount_Groups_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Series_Discount_Groups_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Series_Discount_Groups_Bool_Exp>
}

export type Subscription_RootLearning_Series_Discount_SettingsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Discount_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
}

export type Subscription_RootLearning_Series_Discount_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Discount_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Discount_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
}

export type Subscription_RootLearning_Series_Discount_Settings_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLearning_Series_Discount_Settings_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<
    InputMaybe<Learning_Series_Discount_Settings_Stream_Cursor_Input>
  >
  where?: InputMaybe<Learning_Series_Discount_Settings_Bool_Exp>
}

export type Subscription_RootLearning_Series_Star_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Series_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Series_Star_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Series_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Series_Star_Ratings_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Series_Star_Ratings_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Series_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Series_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Series_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Series_Bool_Exp>
}

export type Subscription_RootLearning_Series_Subscription_SettingsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Subscription_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Subscription_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
}

export type Subscription_RootLearning_Series_Subscription_Settings_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Series_Subscription_Settings_Order_By>>
    where?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
  }

export type Subscription_RootLearning_Series_Subscription_Settings_By_PkArgs = {
  series_id: Scalars['uuid']
}

export type Subscription_RootLearning_Series_Subscription_Settings_Discount_TypeArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Discount_Type_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Discount_Type_Order_By>
    >
    where?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp>
  }

export type Subscription_RootLearning_Series_Subscription_Settings_Discount_Type_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Discount_Type_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Series_Subscription_Settings_Discount_Type_Order_By>
    >
    where?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp>
  }

export type Subscription_RootLearning_Series_Subscription_Settings_Discount_Type_By_PkArgs =
  {
    value: Scalars['String']
  }

export type Subscription_RootLearning_Series_Subscription_Settings_Discount_Type_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Series_Subscription_Settings_Discount_Type_Bool_Exp>
  }

export type Subscription_RootLearning_Series_Subscription_Settings_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Series_Subscription_Settings_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
  }

export type Subscription_RootLearning_Star_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Star_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Star_Ratings_For_Module_Within_RangeArgs =
  {
    args: Learning_Star_Ratings_For_Module_Within_Range_Args
    distinct_on?: InputMaybe<Array<Learning_Module_Star_Ratings_Select_Column>>
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Module_Star_Ratings_Order_By>>
    where?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
  }

export type Subscription_RootLearning_Star_Ratings_For_Module_Within_Range_AggregateArgs =
  {
    args: Learning_Star_Ratings_For_Module_Within_Range_Args
    distinct_on?: InputMaybe<Array<Learning_Module_Star_Ratings_Select_Column>>
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Module_Star_Ratings_Order_By>>
    where?: InputMaybe<Learning_Module_Star_Ratings_Bool_Exp>
  }

export type Subscription_RootLearning_Star_Ratings_In_Library_Within_RangeArgs =
  {
    args: Learning_Star_Ratings_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
    where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
  }

export type Subscription_RootLearning_Star_Ratings_In_Library_Within_Range_AggregateArgs =
  {
    args: Learning_Star_Ratings_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
    where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
  }

export type Subscription_RootLearning_Star_Ratings_In_Series_Within_RangeArgs =
  {
    args: Learning_Star_Ratings_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<Array<Learning_Series_Star_Ratings_Select_Column>>
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Series_Star_Ratings_Order_By>>
    where?: InputMaybe<Learning_Series_Star_Ratings_Bool_Exp>
  }

export type Subscription_RootLearning_Star_Ratings_In_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Star_Ratings_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<Array<Learning_Series_Star_Ratings_Select_Column>>
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Series_Star_Ratings_Order_By>>
    where?: InputMaybe<Learning_Series_Star_Ratings_Bool_Exp>
  }

export type Subscription_RootLearning_Star_Ratings_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Star_Ratings_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Star_Ratings_Within_RangeArgs = {
  args: Learning_Star_Ratings_Within_Range_Args
  distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
  where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
}

export type Subscription_RootLearning_Star_Ratings_Within_Range_AggregateArgs =
  {
    args: Learning_Star_Ratings_Within_Range_Args
    distinct_on?: InputMaybe<Array<Learning_Star_Ratings_Select_Column>>
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Star_Ratings_Order_By>>
    where?: InputMaybe<Learning_Star_Ratings_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Learner_Module_Rating_Response_AveragesArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Learner_Module_Rating_Response_Averages_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Learner_Module_Rating_Response_Averages_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_Response_Averages_Within_RangeArgs =
  {
    args: Learning_Stats_Module_Rating_Response_Averages_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_Response_Averages_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Module_Rating_Response_Averages_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Learner_Module_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Learner_Module_Rating_Response_Averages_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_ResponsesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Module_Rating_Responses_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Module_Rating_Responses_Order_By>>
  where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Module_Rating_Responses_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_Responses_For_Module_Within_RangeArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_For_Module_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_Responses_For_Module_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_For_Module_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_Responses_In_Library_Within_RangeArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_Responses_In_Library_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_Responses_In_Series_Within_RangeArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_Responses_In_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_Responses_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Stats_Module_Rating_Responses_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_Responses_Within_RangeArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Module_Rating_Responses_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Module_Rating_Responses_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Module_Rating_Responses_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Module_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Libraries_By_Period_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Libraries_By_Period_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Libraries_By_Period_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Libraries_By_Period_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Libraries_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Libraries_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Libraries_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Libraries_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Modules_In_Library_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Modules_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Modules_In_Library_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Modules_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Modules_In_Series_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Modules_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Modules_In_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Modules_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Modules_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Modules_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Modules_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Modules_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Series_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Users_For_Module_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Users_For_Module_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Users_For_Module_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Users_For_Module_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Users_In_Library_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Users_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Users_In_Library_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Users_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Users_In_Series_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Users_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Users_In_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Users_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Users_Within_RangeArgs =
  {
    args: Learning_Stats_Most_Active_Users_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Most_Active_Users_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Most_Active_Users_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Rating_Response_AveragesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Rating_Response_Averages_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Rating_Response_Averages_Order_By>>
  where?: InputMaybe<Learning_Stats_Rating_Response_Averages_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Rating_Response_Averages_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Rating_Response_Averages_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Rating_Response_Averages_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Stats_Rating_Response_Averages_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Stats_Rating_Response_Averages_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Rating_Response_Averages_Within_RangeArgs =
  {
    args: Learning_Stats_Rating_Response_Averages_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Rating_Response_Averages_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Rating_Response_Averages_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Rating_Response_Averages_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Response_Averages_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Rating_Response_Averages_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Rating_Response_Averages_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Rating_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Stats_Rating_Responses_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
  where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Rating_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Stats_Rating_Responses_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
  where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Rating_Responses_In_Library_Within_RangeArgs =
  {
    args: Learning_Stats_Rating_Responses_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
    where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Rating_Responses_In_Library_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Rating_Responses_In_Library_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
    where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Rating_Responses_In_Series_Within_RangeArgs =
  {
    args: Learning_Stats_Rating_Responses_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
    where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Rating_Responses_In_Series_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Rating_Responses_In_Series_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
    where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Rating_Responses_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Stats_Rating_Responses_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Rating_Responses_Within_RangeArgs =
  {
    args: Learning_Stats_Rating_Responses_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
    where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Rating_Responses_Within_Range_AggregateArgs =
  {
    args: Learning_Stats_Rating_Responses_Within_Range_Args
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Rating_Responses_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Rating_Responses_Order_By>>
    where?: InputMaybe<Learning_Stats_Rating_Responses_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_LibraryArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Library_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Library_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_Library_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Library_Order_By>>
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Library_By_ModuleArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_Module_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_Module_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Library_By_Module_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_Module_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_Module_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Library_By_Module_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Stats_Sessions_By_Library_By_Module_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_Module_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Library_By_UserArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Library_By_User_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Library_By_User_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_Library_By_User_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Library_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Library_By_User_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Stats_Sessions_By_Library_By_User_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Library_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Library_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<
    InputMaybe<Learning_Stats_Sessions_By_Library_Stream_Cursor_Input>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Library_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_ModuleArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Module_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_Module_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Module_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Module_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_Module_By_UserArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Module_By_User_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Module_By_User_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_Module_By_User_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Module_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Module_By_User_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Stats_Sessions_By_Module_By_User_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Module_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Module_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<
    InputMaybe<Learning_Stats_Sessions_By_Module_Stream_Cursor_Input>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Module_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_Period_By_LibraryArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Period_By_Library_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Period_By_Library_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Period_By_Library_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Stats_Sessions_By_Period_By_Library_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Period_By_Library_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_SeriesArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Series_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_Series_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_Series_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_Series_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_Series_By_ModuleArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_By_Module_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_By_Module_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_Module_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_Series_By_Module_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_Module_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_Module_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_Module_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Series_By_Module_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Stats_Sessions_By_Series_By_Module_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_Module_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Series_By_UserArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_By_User_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<
    Array<Learning_Stats_Sessions_By_Series_By_User_Order_By>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_Series_By_User_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Select_Column>
    >
    limit?: InputMaybe<Scalars['Int']>
    offset?: InputMaybe<Scalars['Int']>
    order_by?: InputMaybe<
      Array<Learning_Stats_Sessions_By_Series_By_User_Order_By>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Series_By_User_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      InputMaybe<Learning_Stats_Sessions_By_Series_By_User_Stream_Cursor_Input>
    >
    where?: InputMaybe<Learning_Stats_Sessions_By_Series_By_User_Bool_Exp>
  }

export type Subscription_RootLearning_Stats_Sessions_By_Series_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<
    InputMaybe<Learning_Stats_Sessions_By_Series_Stream_Cursor_Input>
  >
  where?: InputMaybe<Learning_Stats_Sessions_By_Series_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_UserArgs = {
  distinct_on?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_User_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Stats_Sessions_By_User_Order_By>>
  where?: InputMaybe<Learning_Stats_Sessions_By_User_Bool_Exp>
}

export type Subscription_RootLearning_Stats_Sessions_By_User_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Stats_Sessions_By_User_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Stats_Sessions_By_User_Bool_Exp>
}

export type Subscription_RootLearning_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Learning_Uploads_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Uploads_Order_By>>
  where?: InputMaybe<Learning_Uploads_Bool_Exp>
}

export type Subscription_RootLearning_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Uploads_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Uploads_Order_By>>
  where?: InputMaybe<Learning_Uploads_Bool_Exp>
}

export type Subscription_RootLearning_Uploads_By_PkArgs = {
  id: Scalars['Int']
}

export type Subscription_RootLearning_Uploads_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Learning_Uploads_Stream_Cursor_Input>>
  where?: InputMaybe<Learning_Uploads_Bool_Exp>
}

export type Subscription_RootModule_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootModulesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Modules_Order_By>>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

export type Subscription_RootModules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Modules_Order_By>>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

export type Subscription_RootPage_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Page_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Page_Categories_Order_By>>
  where?: InputMaybe<Page_Categories_Bool_Exp>
}

export type Subscription_RootPage_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Page_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Page_Categories_Order_By>>
  where?: InputMaybe<Page_Categories_Bool_Exp>
}

export type Subscription_RootPage_Categories_By_PkArgs = {
  category_id: Scalars['Int']
  page_id: Scalars['Int']
}

export type Subscription_RootPage_Categories_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Page_Categories_Stream_Cursor_Input>>
  where?: InputMaybe<Page_Categories_Bool_Exp>
}

export type Subscription_RootPagesArgs = {
  distinct_on?: InputMaybe<Array<Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Pages_Order_By>>
  where?: InputMaybe<Pages_Bool_Exp>
}

export type Subscription_RootPages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pages_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Pages_Order_By>>
  where?: InputMaybe<Pages_Bool_Exp>
}

export type Subscription_RootPages_By_PkArgs = {
  id: Scalars['Int']
}

export type Subscription_RootPages_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Pages_Stream_Cursor_Input>>
  where?: InputMaybe<Pages_Bool_Exp>
}

export type Subscription_RootPost_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Post_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Categories_Order_By>>
  where?: InputMaybe<Post_Categories_Bool_Exp>
}

export type Subscription_RootPost_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Categories_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Categories_Order_By>>
  where?: InputMaybe<Post_Categories_Bool_Exp>
}

export type Subscription_RootPost_Categories_By_PkArgs = {
  category_id: Scalars['Int']
  post_id: Scalars['Int']
}

export type Subscription_RootPost_Categories_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Post_Categories_Stream_Cursor_Input>>
  where?: InputMaybe<Post_Categories_Bool_Exp>
}

export type Subscription_RootPost_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Comments_Order_By>>
  where?: InputMaybe<Post_Comments_Bool_Exp>
}

export type Subscription_RootPost_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Comments_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Post_Comments_Order_By>>
  where?: InputMaybe<Post_Comments_Bool_Exp>
}

export type Subscription_RootPost_Comments_By_PkArgs = {
  id: Scalars['Int']
}

export type Subscription_RootPost_Comments_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Post_Comments_Stream_Cursor_Input>>
  where?: InputMaybe<Post_Comments_Bool_Exp>
}

export type Subscription_RootPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

export type Subscription_RootPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

export type Subscription_RootPosts_By_PkArgs = {
  id: Scalars['Int']
}

export type Subscription_RootPosts_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Posts_Stream_Cursor_Input>>
  where?: InputMaybe<Posts_Bool_Exp>
}

export type Subscription_RootResource_DependentsArgs = {
  distinct_on?: InputMaybe<Array<Resource_Dependents_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resource_Dependents_Order_By>>
  where?: InputMaybe<Resource_Dependents_Bool_Exp>
}

export type Subscription_RootResource_Dependents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Resource_Dependents_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resource_Dependents_Order_By>>
  where?: InputMaybe<Resource_Dependents_Bool_Exp>
}

export type Subscription_RootResource_Dependents_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Resource_Dependents_Stream_Cursor_Input>>
  where?: InputMaybe<Resource_Dependents_Bool_Exp>
}

export type Subscription_RootResourcesArgs = {
  distinct_on?: InputMaybe<Array<Resources_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resources_Order_By>>
  where?: InputMaybe<Resources_Bool_Exp>
}

export type Subscription_RootResources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Resources_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resources_Order_By>>
  where?: InputMaybe<Resources_Bool_Exp>
}

export type Subscription_RootResources_By_PkArgs = {
  id: Scalars['Int']
}

export type Subscription_RootResources_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Resources_Stream_Cursor_Input>>
  where?: InputMaybe<Resources_Bool_Exp>
}

export type Subscription_RootSearch_ModulesArgs = {
  args: Search_Modules_Args
  distinct_on?: InputMaybe<Array<Learning_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Modules_Order_By>>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

export type Subscription_RootSearch_Modules_AggregateArgs = {
  args: Search_Modules_Args
  distinct_on?: InputMaybe<Array<Learning_Modules_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Modules_Order_By>>
  where?: InputMaybe<Learning_Modules_Bool_Exp>
}

export type Subscription_RootSegment_UserArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Segment_User_Order_By>>
  where?: InputMaybe<Segment_User_Bool_Exp>
}

export type Subscription_RootSegment_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Segment_User_Order_By>>
  where?: InputMaybe<Segment_User_Bool_Exp>
}

export type Subscription_RootSegment_User_By_PkArgs = {
  segment_id: Scalars['bigint']
  user_id: Scalars['bigint']
}

export type Subscription_RootSegment_User_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Segment_User_Stream_Cursor_Input>>
  where?: InputMaybe<Segment_User_Bool_Exp>
}

export type Subscription_RootSeriesArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Order_By>>
  where?: InputMaybe<Learning_Series_Bool_Exp>
}

export type Subscription_RootSeries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Learning_Series_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Order_By>>
  where?: InputMaybe<Learning_Series_Bool_Exp>
}

export type Subscription_RootSeries_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSlugsArgs = {
  distinct_on?: InputMaybe<Array<Slugs_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Slugs_Order_By>>
  where?: InputMaybe<Slugs_Bool_Exp>
}

export type Subscription_RootSlugs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Slugs_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Slugs_Order_By>>
  where?: InputMaybe<Slugs_Bool_Exp>
}

export type Subscription_RootSlugs_By_PkArgs = {
  prefix: Scalars['String']
  slug: Scalars['String']
}

export type Subscription_RootSlugs_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Slugs_Stream_Cursor_Input>>
  where?: InputMaybe<Slugs_Bool_Exp>
}

export type Subscription_RootUser_Group_MembersArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

export type Subscription_RootUser_Group_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

export type Subscription_RootUser_Group_Members_By_PkArgs = {
  group_id: Scalars['String']
  user_id: Scalars['String']
}

export type Subscription_RootUser_Group_Members_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Roles_Order_By>>
  where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
}

export type Subscription_RootUser_Group_Members_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Roles_Order_By>>
  where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
}

export type Subscription_RootUser_Group_Members_Roles_By_PkArgs = {
  group_id: Scalars['String']
  user_id: Scalars['String']
}

export type Subscription_RootUser_Group_Members_Roles_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<User_Group_Members_Roles_Stream_Cursor_Input>>
  where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
}

export type Subscription_RootUser_Group_Members_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<User_Group_Members_Stream_Cursor_Input>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Users_Order_By>>
  where?: InputMaybe<Users_Bool_Exp>
}

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int']
}

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>
  where?: InputMaybe<Users_Bool_Exp>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** columns and relationships of "user_group_members" */
export type User_Group_Members = {
  __typename?: 'user_group_members'
  created_at: Scalars['timestamptz']
  expires_at?: Maybe<Scalars['timestamptz']>
  group_id: Scalars['String']
  is_admin: Scalars['Boolean']
  is_moderator: Scalars['Boolean']
  /** An array relationship */
  member_groups: Array<User_Group_Members>
  /** An aggregate relationship */
  member_groups_aggregate: User_Group_Members_Aggregate
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** columns and relationships of "user_group_members" */
export type User_Group_MembersMember_GroupsArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** columns and relationships of "user_group_members" */
export type User_Group_MembersMember_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Members_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<User_Group_Members_Order_By>>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** aggregated selection of "user_group_members" */
export type User_Group_Members_Aggregate = {
  __typename?: 'user_group_members_aggregate'
  aggregate?: Maybe<User_Group_Members_Aggregate_Fields>
  nodes: Array<User_Group_Members>
}

export type User_Group_Members_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Group_Members_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<User_Group_Members_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<User_Group_Members_Aggregate_Bool_Exp_Count>
}

export type User_Group_Members_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Group_Members_Select_Column_User_Group_Members_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Group_Members_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Group_Members_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Group_Members_Select_Column_User_Group_Members_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Group_Members_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Group_Members_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Group_Members_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Group_Members_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_group_members" */
export type User_Group_Members_Aggregate_Fields = {
  __typename?: 'user_group_members_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Group_Members_Max_Fields>
  min?: Maybe<User_Group_Members_Min_Fields>
}

/** aggregate fields of "user_group_members" */
export type User_Group_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Group_Members_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_group_members" */
export type User_Group_Members_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Group_Members_Max_Order_By>
  min?: InputMaybe<User_Group_Members_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_group_members" */
export type User_Group_Members_Arr_Rel_Insert_Input = {
  data: Array<User_Group_Members_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_Group_Members_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_group_members". All fields are combined with a logical 'AND'. */
export type User_Group_Members_Bool_Exp = {
  _and?: InputMaybe<Array<User_Group_Members_Bool_Exp>>
  _not?: InputMaybe<User_Group_Members_Bool_Exp>
  _or?: InputMaybe<Array<User_Group_Members_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  expires_at?: InputMaybe<Timestamptz_Comparison_Exp>
  group_id?: InputMaybe<String_Comparison_Exp>
  is_admin?: InputMaybe<Boolean_Comparison_Exp>
  is_moderator?: InputMaybe<Boolean_Comparison_Exp>
  member_groups?: InputMaybe<User_Group_Members_Bool_Exp>
  member_groups_aggregate?: InputMaybe<User_Group_Members_Aggregate_Bool_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_id?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_group_members" */
export enum User_Group_Members_Constraint {
  /** unique or primary key constraint on columns "user_id", "group_id" */
  UserGroupMembersPkey = 'user_group_members_pkey',
}

/** input type for inserting data into table "user_group_members" */
export type User_Group_Members_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  expires_at?: InputMaybe<Scalars['timestamptz']>
  group_id?: InputMaybe<Scalars['String']>
  is_admin?: InputMaybe<Scalars['Boolean']>
  is_moderator?: InputMaybe<Scalars['Boolean']>
  member_groups?: InputMaybe<User_Group_Members_Arr_Rel_Insert_Input>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Group_Members_Max_Fields = {
  __typename?: 'user_group_members_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  group_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_group_members" */
export type User_Group_Members_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  expires_at?: InputMaybe<Order_By>
  group_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Group_Members_Min_Fields = {
  __typename?: 'user_group_members_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  group_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_group_members" */
export type User_Group_Members_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  expires_at?: InputMaybe<Order_By>
  group_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user_group_members" */
export type User_Group_Members_Mutation_Response = {
  __typename?: 'user_group_members_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Group_Members>
}

/** on_conflict condition type for table "user_group_members" */
export type User_Group_Members_On_Conflict = {
  constraint: User_Group_Members_Constraint
  update_columns?: Array<User_Group_Members_Update_Column>
  where?: InputMaybe<User_Group_Members_Bool_Exp>
}

/** Ordering options when selecting data from "user_group_members". */
export type User_Group_Members_Order_By = {
  created_at?: InputMaybe<Order_By>
  expires_at?: InputMaybe<Order_By>
  group_id?: InputMaybe<Order_By>
  is_admin?: InputMaybe<Order_By>
  is_moderator?: InputMaybe<Order_By>
  member_groups_aggregate?: InputMaybe<User_Group_Members_Aggregate_Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_group_members */
export type User_Group_Members_Pk_Columns_Input = {
  group_id: Scalars['String']
  user_id: Scalars['String']
}

/** columns and relationships of "user_group_members_roles" */
export type User_Group_Members_Roles = {
  __typename?: 'user_group_members_roles'
  created_at: Scalars['timestamptz']
  download_learning_data: Scalars['Boolean']
  edit_details: Scalars['Boolean']
  features: Scalars['Boolean']
  group_id: Scalars['String']
  learning_publisher: Scalars['Boolean']
  manage_admins: Scalars['Boolean']
  manage_members: Scalars['Boolean']
  message_members: Scalars['Boolean']
  page_publisher: Scalars['Boolean']
  report_publisher: Scalars['Boolean']
  subscriptions: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "user_group_members_roles" */
export type User_Group_Members_Roles_Aggregate = {
  __typename?: 'user_group_members_roles_aggregate'
  aggregate?: Maybe<User_Group_Members_Roles_Aggregate_Fields>
  nodes: Array<User_Group_Members_Roles>
}

export type User_Group_Members_Roles_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Group_Members_Roles_Aggregate_Bool_Exp_Bool_And>
  bool_or?: InputMaybe<User_Group_Members_Roles_Aggregate_Bool_Exp_Bool_Or>
  count?: InputMaybe<User_Group_Members_Roles_Aggregate_Bool_Exp_Count>
}

export type User_Group_Members_Roles_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Group_Members_Roles_Select_Column_User_Group_Members_Roles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Group_Members_Roles_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Group_Members_Roles_Select_Column_User_Group_Members_Roles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Group_Members_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_group_members_roles" */
export type User_Group_Members_Roles_Aggregate_Fields = {
  __typename?: 'user_group_members_roles_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Group_Members_Roles_Max_Fields>
  min?: Maybe<User_Group_Members_Roles_Min_Fields>
}

/** aggregate fields of "user_group_members_roles" */
export type User_Group_Members_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Group_Members_Roles_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_group_members_roles" */
export type User_Group_Members_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<User_Group_Members_Roles_Max_Order_By>
  min?: InputMaybe<User_Group_Members_Roles_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_group_members_roles" */
export type User_Group_Members_Roles_Arr_Rel_Insert_Input = {
  data: Array<User_Group_Members_Roles_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<User_Group_Members_Roles_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_group_members_roles". All fields are combined with a logical 'AND'. */
export type User_Group_Members_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<User_Group_Members_Roles_Bool_Exp>>
  _not?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
  _or?: InputMaybe<Array<User_Group_Members_Roles_Bool_Exp>>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  download_learning_data?: InputMaybe<Boolean_Comparison_Exp>
  edit_details?: InputMaybe<Boolean_Comparison_Exp>
  features?: InputMaybe<Boolean_Comparison_Exp>
  group_id?: InputMaybe<String_Comparison_Exp>
  learning_publisher?: InputMaybe<Boolean_Comparison_Exp>
  manage_admins?: InputMaybe<Boolean_Comparison_Exp>
  manage_members?: InputMaybe<Boolean_Comparison_Exp>
  message_members?: InputMaybe<Boolean_Comparison_Exp>
  page_publisher?: InputMaybe<Boolean_Comparison_Exp>
  report_publisher?: InputMaybe<Boolean_Comparison_Exp>
  subscriptions?: InputMaybe<Boolean_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
  user_id?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_group_members_roles" */
export enum User_Group_Members_Roles_Constraint {
  /** unique or primary key constraint on columns "user_id", "group_id" */
  UserGroupMembersRolesPkey = 'user_group_members_roles_pkey',
}

/** input type for inserting data into table "user_group_members_roles" */
export type User_Group_Members_Roles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  download_learning_data?: InputMaybe<Scalars['Boolean']>
  edit_details?: InputMaybe<Scalars['Boolean']>
  features?: InputMaybe<Scalars['Boolean']>
  group_id?: InputMaybe<Scalars['String']>
  learning_publisher?: InputMaybe<Scalars['Boolean']>
  manage_admins?: InputMaybe<Scalars['Boolean']>
  manage_members?: InputMaybe<Scalars['Boolean']>
  message_members?: InputMaybe<Scalars['Boolean']>
  page_publisher?: InputMaybe<Scalars['Boolean']>
  report_publisher?: InputMaybe<Scalars['Boolean']>
  subscriptions?: InputMaybe<Scalars['Boolean']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Group_Members_Roles_Max_Fields = {
  __typename?: 'user_group_members_roles_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  group_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_group_members_roles" */
export type User_Group_Members_Roles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>
  group_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type User_Group_Members_Roles_Min_Fields = {
  __typename?: 'user_group_members_roles_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  group_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_group_members_roles" */
export type User_Group_Members_Roles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>
  group_id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** response of any mutation on the table "user_group_members_roles" */
export type User_Group_Members_Roles_Mutation_Response = {
  __typename?: 'user_group_members_roles_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Group_Members_Roles>
}

/** on_conflict condition type for table "user_group_members_roles" */
export type User_Group_Members_Roles_On_Conflict = {
  constraint: User_Group_Members_Roles_Constraint
  update_columns?: Array<User_Group_Members_Roles_Update_Column>
  where?: InputMaybe<User_Group_Members_Roles_Bool_Exp>
}

/** Ordering options when selecting data from "user_group_members_roles". */
export type User_Group_Members_Roles_Order_By = {
  created_at?: InputMaybe<Order_By>
  download_learning_data?: InputMaybe<Order_By>
  edit_details?: InputMaybe<Order_By>
  features?: InputMaybe<Order_By>
  group_id?: InputMaybe<Order_By>
  learning_publisher?: InputMaybe<Order_By>
  manage_admins?: InputMaybe<Order_By>
  manage_members?: InputMaybe<Order_By>
  message_members?: InputMaybe<Order_By>
  page_publisher?: InputMaybe<Order_By>
  report_publisher?: InputMaybe<Order_By>
  subscriptions?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
  user_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: user_group_members_roles */
export type User_Group_Members_Roles_Pk_Columns_Input = {
  group_id: Scalars['String']
  user_id: Scalars['String']
}

/** select columns of table "user_group_members_roles" */
export enum User_Group_Members_Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DownloadLearningData = 'download_learning_data',
  /** column name */
  EditDetails = 'edit_details',
  /** column name */
  Features = 'features',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  LearningPublisher = 'learning_publisher',
  /** column name */
  ManageAdmins = 'manage_admins',
  /** column name */
  ManageMembers = 'manage_members',
  /** column name */
  MessageMembers = 'message_members',
  /** column name */
  PagePublisher = 'page_publisher',
  /** column name */
  ReportPublisher = 'report_publisher',
  /** column name */
  Subscriptions = 'subscriptions',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "user_group_members_roles_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_group_members_roles" */
export enum User_Group_Members_Roles_Select_Column_User_Group_Members_Roles_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DownloadLearningData = 'download_learning_data',
  /** column name */
  EditDetails = 'edit_details',
  /** column name */
  Features = 'features',
  /** column name */
  LearningPublisher = 'learning_publisher',
  /** column name */
  ManageAdmins = 'manage_admins',
  /** column name */
  ManageMembers = 'manage_members',
  /** column name */
  MessageMembers = 'message_members',
  /** column name */
  PagePublisher = 'page_publisher',
  /** column name */
  ReportPublisher = 'report_publisher',
  /** column name */
  Subscriptions = 'subscriptions',
}

/** select "user_group_members_roles_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_group_members_roles" */
export enum User_Group_Members_Roles_Select_Column_User_Group_Members_Roles_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DownloadLearningData = 'download_learning_data',
  /** column name */
  EditDetails = 'edit_details',
  /** column name */
  Features = 'features',
  /** column name */
  LearningPublisher = 'learning_publisher',
  /** column name */
  ManageAdmins = 'manage_admins',
  /** column name */
  ManageMembers = 'manage_members',
  /** column name */
  MessageMembers = 'message_members',
  /** column name */
  PagePublisher = 'page_publisher',
  /** column name */
  ReportPublisher = 'report_publisher',
  /** column name */
  Subscriptions = 'subscriptions',
}

/** input type for updating data in table "user_group_members_roles" */
export type User_Group_Members_Roles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  download_learning_data?: InputMaybe<Scalars['Boolean']>
  edit_details?: InputMaybe<Scalars['Boolean']>
  features?: InputMaybe<Scalars['Boolean']>
  group_id?: InputMaybe<Scalars['String']>
  learning_publisher?: InputMaybe<Scalars['Boolean']>
  manage_admins?: InputMaybe<Scalars['Boolean']>
  manage_members?: InputMaybe<Scalars['Boolean']>
  message_members?: InputMaybe<Scalars['Boolean']>
  page_publisher?: InputMaybe<Scalars['Boolean']>
  report_publisher?: InputMaybe<Scalars['Boolean']>
  subscriptions?: InputMaybe<Scalars['Boolean']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "user_group_members_roles" */
export type User_Group_Members_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Group_Members_Roles_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Group_Members_Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  download_learning_data?: InputMaybe<Scalars['Boolean']>
  edit_details?: InputMaybe<Scalars['Boolean']>
  features?: InputMaybe<Scalars['Boolean']>
  group_id?: InputMaybe<Scalars['String']>
  learning_publisher?: InputMaybe<Scalars['Boolean']>
  manage_admins?: InputMaybe<Scalars['Boolean']>
  manage_members?: InputMaybe<Scalars['Boolean']>
  message_members?: InputMaybe<Scalars['Boolean']>
  page_publisher?: InputMaybe<Scalars['Boolean']>
  report_publisher?: InputMaybe<Scalars['Boolean']>
  subscriptions?: InputMaybe<Scalars['Boolean']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['String']>
}

/** update columns of table "user_group_members_roles" */
export enum User_Group_Members_Roles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DownloadLearningData = 'download_learning_data',
  /** column name */
  EditDetails = 'edit_details',
  /** column name */
  Features = 'features',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  LearningPublisher = 'learning_publisher',
  /** column name */
  ManageAdmins = 'manage_admins',
  /** column name */
  ManageMembers = 'manage_members',
  /** column name */
  MessageMembers = 'message_members',
  /** column name */
  PagePublisher = 'page_publisher',
  /** column name */
  ReportPublisher = 'report_publisher',
  /** column name */
  Subscriptions = 'subscriptions',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Group_Members_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Group_Members_Roles_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Group_Members_Roles_Bool_Exp
}

/** select columns of table "user_group_members" */
export enum User_Group_Members_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsModerator = 'is_moderator',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "user_group_members_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_group_members" */
export enum User_Group_Members_Select_Column_User_Group_Members_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsModerator = 'is_moderator',
}

/** select "user_group_members_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_group_members" */
export enum User_Group_Members_Select_Column_User_Group_Members_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsModerator = 'is_moderator',
}

/** input type for updating data in table "user_group_members" */
export type User_Group_Members_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  expires_at?: InputMaybe<Scalars['timestamptz']>
  group_id?: InputMaybe<Scalars['String']>
  is_admin?: InputMaybe<Scalars['Boolean']>
  is_moderator?: InputMaybe<Scalars['Boolean']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "user_group_members" */
export type User_Group_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Group_Members_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Group_Members_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>
  expires_at?: InputMaybe<Scalars['timestamptz']>
  group_id?: InputMaybe<Scalars['String']>
  is_admin?: InputMaybe<Scalars['Boolean']>
  is_moderator?: InputMaybe<Scalars['Boolean']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
  user_id?: InputMaybe<Scalars['String']>
}

/** update columns of table "user_group_members" */
export enum User_Group_Members_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsModerator = 'is_moderator',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Group_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Group_Members_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Group_Members_Bool_Exp
}

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users'
  first_seen_at: Scalars['timestamptz']
  id: Scalars['Int']
  last_seen_at?: Maybe<Scalars['timestamptz']>
  /** An array relationship */
  learningSeriesSubscriptionSettingsByUpdatedBy: Array<Learning_Series_Subscription_Settings>
  /** An aggregate relationship */
  learningSeriesSubscriptionSettingsByUpdatedBy_aggregate: Learning_Series_Subscription_Settings_Aggregate
  /** An array relationship */
  learning_series_subscription_settings: Array<Learning_Series_Subscription_Settings>
  /** An aggregate relationship */
  learning_series_subscription_settings_aggregate: Learning_Series_Subscription_Settings_Aggregate
  name: Scalars['String']
  /** An array relationship */
  posts: Array<Posts>
  /** An array relationship */
  postsByPublishedBy: Array<Posts>
  /** An aggregate relationship */
  postsByPublishedBy_aggregate: Posts_Aggregate
  /** An array relationship */
  postsByUpdatedBy: Array<Posts>
  /** An aggregate relationship */
  postsByUpdatedBy_aggregate: Posts_Aggregate
  /** An aggregate relationship */
  posts_aggregate: Posts_Aggregate
  /** An array relationship */
  resources: Array<Resources>
  /** An array relationship */
  resourcesByUpdatedBy: Array<Resources>
  /** An aggregate relationship */
  resourcesByUpdatedBy_aggregate: Resources_Aggregate
  /** An aggregate relationship */
  resources_aggregate: Resources_Aggregate
  site_role?: Maybe<Scalars['String']>
}

/** columns and relationships of "users" */
export type UsersLearningSeriesSubscriptionSettingsByUpdatedByArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Subscription_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Subscription_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersLearningSeriesSubscriptionSettingsByUpdatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Subscription_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Subscription_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersLearning_Series_Subscription_SettingsArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Subscription_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Subscription_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersLearning_Series_Subscription_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Learning_Series_Subscription_Settings_Select_Column>
  >
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Learning_Series_Subscription_Settings_Order_By>>
  where?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersPostsByPublishedByArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersPostsByPublishedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersPostsByUpdatedByArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersPostsByUpdatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Posts_Order_By>>
  where?: InputMaybe<Posts_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersResourcesArgs = {
  distinct_on?: InputMaybe<Array<Resources_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resources_Order_By>>
  where?: InputMaybe<Resources_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersResourcesByUpdatedByArgs = {
  distinct_on?: InputMaybe<Array<Resources_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resources_Order_By>>
  where?: InputMaybe<Resources_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersResourcesByUpdatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Resources_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resources_Order_By>>
  where?: InputMaybe<Resources_Bool_Exp>
}

/** columns and relationships of "users" */
export type UsersResources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Resources_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Resources_Order_By>>
  where?: InputMaybe<Resources_Bool_Exp>
}

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate'
  aggregate?: Maybe<Users_Aggregate_Fields>
  nodes: Array<Users>
}

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields'
  avg?: Maybe<Users_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Users_Max_Fields>
  min?: Maybe<Users_Min_Fields>
  stddev?: Maybe<Users_Stddev_Fields>
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>
  sum?: Maybe<Users_Sum_Fields>
  var_pop?: Maybe<Users_Var_Pop_Fields>
  var_samp?: Maybe<Users_Var_Samp_Fields>
  variance?: Maybe<Users_Variance_Fields>
}

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields'
  id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>
  _not?: InputMaybe<Users_Bool_Exp>
  _or?: InputMaybe<Array<Users_Bool_Exp>>
  first_seen_at?: InputMaybe<Timestamptz_Comparison_Exp>
  id?: InputMaybe<Int_Comparison_Exp>
  last_seen_at?: InputMaybe<Timestamptz_Comparison_Exp>
  learningSeriesSubscriptionSettingsByUpdatedBy?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
  learningSeriesSubscriptionSettingsByUpdatedBy_aggregate?: InputMaybe<Learning_Series_Subscription_Settings_Aggregate_Bool_Exp>
  learning_series_subscription_settings?: InputMaybe<Learning_Series_Subscription_Settings_Bool_Exp>
  learning_series_subscription_settings_aggregate?: InputMaybe<Learning_Series_Subscription_Settings_Aggregate_Bool_Exp>
  name?: InputMaybe<String_Comparison_Exp>
  posts?: InputMaybe<Posts_Bool_Exp>
  postsByPublishedBy?: InputMaybe<Posts_Bool_Exp>
  postsByPublishedBy_aggregate?: InputMaybe<Posts_Aggregate_Bool_Exp>
  postsByUpdatedBy?: InputMaybe<Posts_Bool_Exp>
  postsByUpdatedBy_aggregate?: InputMaybe<Posts_Aggregate_Bool_Exp>
  posts_aggregate?: InputMaybe<Posts_Aggregate_Bool_Exp>
  resources?: InputMaybe<Resources_Bool_Exp>
  resourcesByUpdatedBy?: InputMaybe<Resources_Bool_Exp>
  resourcesByUpdatedBy_aggregate?: InputMaybe<Resources_Aggregate_Bool_Exp>
  resources_aggregate?: InputMaybe<Resources_Aggregate_Bool_Exp>
  site_role?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  first_seen_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['Int']>
  last_seen_at?: InputMaybe<Scalars['timestamptz']>
  learningSeriesSubscriptionSettingsByUpdatedBy?: InputMaybe<Learning_Series_Subscription_Settings_Arr_Rel_Insert_Input>
  learning_series_subscription_settings?: InputMaybe<Learning_Series_Subscription_Settings_Arr_Rel_Insert_Input>
  name?: InputMaybe<Scalars['String']>
  posts?: InputMaybe<Posts_Arr_Rel_Insert_Input>
  postsByPublishedBy?: InputMaybe<Posts_Arr_Rel_Insert_Input>
  postsByUpdatedBy?: InputMaybe<Posts_Arr_Rel_Insert_Input>
  resources?: InputMaybe<Resources_Arr_Rel_Insert_Input>
  resourcesByUpdatedBy?: InputMaybe<Resources_Arr_Rel_Insert_Input>
  site_role?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields'
  first_seen_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['Int']>
  last_seen_at?: Maybe<Scalars['timestamptz']>
  name?: Maybe<Scalars['String']>
  site_role?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields'
  first_seen_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['Int']>
  last_seen_at?: Maybe<Scalars['timestamptz']>
  name?: Maybe<Scalars['String']>
  site_role?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Users>
}

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>
}

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint
  update_columns?: Array<Users_Update_Column>
  where?: InputMaybe<Users_Bool_Exp>
}

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  first_seen_at?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  last_seen_at?: InputMaybe<Order_By>
  learningSeriesSubscriptionSettingsByUpdatedBy_aggregate?: InputMaybe<Learning_Series_Subscription_Settings_Aggregate_Order_By>
  learning_series_subscription_settings_aggregate?: InputMaybe<Learning_Series_Subscription_Settings_Aggregate_Order_By>
  name?: InputMaybe<Order_By>
  postsByPublishedBy_aggregate?: InputMaybe<Posts_Aggregate_Order_By>
  postsByUpdatedBy_aggregate?: InputMaybe<Posts_Aggregate_Order_By>
  posts_aggregate?: InputMaybe<Posts_Aggregate_Order_By>
  resourcesByUpdatedBy_aggregate?: InputMaybe<Resources_Aggregate_Order_By>
  resources_aggregate?: InputMaybe<Resources_Aggregate_Order_By>
  site_role?: InputMaybe<Order_By>
}

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int']
}

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  FirstSeenAt = 'first_seen_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeenAt = 'last_seen_at',
  /** column name */
  Name = 'name',
  /** column name */
  SiteRole = 'site_role',
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  first_seen_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['Int']>
  last_seen_at?: InputMaybe<Scalars['timestamptz']>
  name?: InputMaybe<Scalars['String']>
  site_role?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields'
  id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields'
  id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields'
  id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  first_seen_at?: InputMaybe<Scalars['timestamptz']>
  id?: InputMaybe<Scalars['Int']>
  last_seen_at?: InputMaybe<Scalars['timestamptz']>
  name?: InputMaybe<Scalars['String']>
  site_role?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields'
  id?: Maybe<Scalars['Int']>
}

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  FirstSeenAt = 'first_seen_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeenAt = 'last_seen_at',
  /** column name */
  Name = 'name',
  /** column name */
  SiteRole = 'site_role',
}

export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp
}

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields'
  id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields'
  id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields'
  id?: Maybe<Scalars['Float']>
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

export type FormFieldsFragment = {
  __typename?: 'forms'
  id: any
  creator_id: number
  name: string
  description?: string | null
  fields: any
  is_feedback_form: boolean
  allow_multiple_submissions: boolean
  group_id?: string | null
}

export type FormResponseFieldsFragment = {
  __typename?: 'form_responses'
  id: any
  form_id: any
  actor_id: number
  session_id?: any | null
  response: any
}

export type UpsertFormResponseMutationVariables = Exact<{
  response: Form_Responses_Insert_Input
}>

export type UpsertFormResponseMutation = {
  __typename?: 'mutation_root'
  insert_form_responses_one?: {
    __typename?: 'form_responses'
    id: any
    form_id: any
    actor_id: number
    session_id?: any | null
    response: any
  } | null
}

export type GetFormsQueryVariables = Exact<{ [key: string]: never }>

export type GetFormsQuery = {
  __typename?: 'query_root'
  forms: Array<{
    __typename?: 'forms'
    id: any
    creator_id: number
    name: string
    description?: string | null
    fields: any
    is_feedback_form: boolean
    allow_multiple_submissions: boolean
    group_id?: string | null
    creator: { __typename?: 'users'; id: number; name: string }
  }>
}

export type GetGroupFormsQueryVariables = Exact<{
  groupId: Scalars['String']
}>

export type GetGroupFormsQuery = {
  __typename?: 'query_root'
  forms: Array<{
    __typename?: 'forms'
    id: any
    creator_id: number
    name: string
    description?: string | null
    fields: any
    is_feedback_form: boolean
    allow_multiple_submissions: boolean
    group_id?: string | null
    creator: { __typename?: 'users'; id: number; name: string }
  }>
}

export type GetFormQueryVariables = Exact<{
  id: Scalars['uuid']
}>

export type GetFormQuery = {
  __typename?: 'query_root'
  form?: {
    __typename?: 'forms'
    id: any
    creator_id: number
    name: string
    description?: string | null
    fields: any
    is_feedback_form: boolean
    allow_multiple_submissions: boolean
    group_id?: string | null
  } | null
}

export type GetUserFormResponseQueryVariables = Exact<{
  formId: Scalars['uuid']
  actorId: Scalars['Int']
}>

export type GetUserFormResponseQuery = {
  __typename?: 'query_root'
  form_responses: Array<{
    __typename?: 'form_responses'
    id: any
    form_id: any
    actor_id: number
    session_id?: any | null
    response: any
  }>
}

export type SeriesSubscriptionSettingsFragment = {
  __typename?: 'learning_series_subscription_settings'
  module_price: any
  series_price: any
  available_as_module: boolean
  available_as_series: boolean
  subscription_interval?: string | null
  created_at: any
  created_by: number
  currency_code: string
  updated_at: any
  updated_by: number
}

export type SeriesDiscountSettingsFragment = {
  __typename?: 'learning_series_discount_settings'
  created_at: any
  created_by: number
  discount_percentage: any
  discount_type: Learning_Series_Subscription_Settings_Discount_Type_Enum
  series_id: any
  updated_by: number
  updated_at: any
  discount_groups: Array<{
    __typename?: 'learning_series_discount_groups'
    group_id: string
  }>
}

export type SeriesResponseFragment = {
  __typename?: 'learning_series'
  id: any
  series_type?: string | null
  library_type: string
  library_id: string
  slug: string
  title: string
  display_author?: string | null
  description?: string | null
  rich_description?: string | null
  sort_modules_by: any
  cme_accreditor?: string | null
  payments_enabled: boolean
  has_subscription?: boolean | null
  force_completion_order: boolean
  accreditation_when: string
  library?: {
    __typename?: 'groups'
    id: string
    slug: string
    title: string
    learning_subscriptions_enabled: boolean
  } | null
  image?: { __typename?: 'learning_uploads'; id: number; path: string } | null
  star_rating?: {
    __typename?: 'learning_series_star_ratings'
    rating?: any | null
  } | null
  editor?: { __typename?: 'users'; id: number; name: string } | null
  subscription_settings?: {
    __typename?: 'learning_series_subscription_settings'
    module_price: any
    series_price: any
    available_as_module: boolean
    available_as_series: boolean
    subscription_interval?: string | null
    created_at: any
    created_by: number
    currency_code: string
    updated_at: any
    updated_by: number
  } | null
  module_count: {
    __typename?: 'learning_modules_aggregate'
    aggregate?: {
      __typename?: 'learning_modules_aggregate_fields'
      count: number
    } | null
  }
  completed_count: {
    __typename?: 'learning_modules_aggregate'
    aggregate?: {
      __typename?: 'learning_modules_aggregate_fields'
      count: number
    } | null
  }
  active_subscription: Array<{
    __typename?: 'user_group_members'
    created_at: any
    expires_at?: any | null
  }>
}

export type PageWithPositionFragment = {
  __typename?: 'learning_module_revision_pages'
  position: number
  page: {
    __typename?: 'learning_pages'
    id: string
    title?: string | null
    body: any
  }
}

export type ModuleRevisionResponseFragment = {
  __typename?: 'learning_module_revisions'
  id: any
  module_id: any
  status: any
  slug: string
  title: string
  description?: string | null
  rich_description?: string | null
  cover_image_id?: number | null
  cover_logo_id?: number | null
  card_thumbnail_id?: number | null
  cover_theme?: string | null
  cover_author?: string | null
  expected_completion_time_minutes?: number | null
  want_page_titles: boolean
  mcq_settings_enabled: boolean
  mcq_settings_feedback?: string | null
  mcq_settings_labels: string
  mcq_settings_retry: any
  mcq_settings_retry_limit?: any | null
  mcq_settings_show_score: boolean
  mcq_settings_pass_mark_type: any
  mcq_settings_pass_mark?: number | null
  cme_expires_after_seconds?: number | null
  cme_expires_at?: any | null
  cme_credits_description?: string | null
  cme_reference?: string | null
  cme_accreditor?: string | null
  objectives?: any | null
  settings?: any | null
  contains_mcq?: boolean | null
  feedback_form_id?: any | null
  categories?: any | null
  created_at: any
  redirect_url?: string | null
  module: { __typename?: 'learning_modules'; slug?: string | null }
  cover_image?: {
    __typename?: 'learning_uploads'
    id: number
    path: string
  } | null
  cover_logo?: {
    __typename?: 'learning_uploads'
    id: number
    path: string
  } | null
  card_thumbnail?: {
    __typename?: 'learning_uploads'
    id: number
    path: string
  } | null
  pages: Array<{
    __typename?: 'learning_module_revision_pages'
    position: number
    page: {
      __typename?: 'learning_pages'
      id: string
      title?: string | null
      body: any
    }
  }>
  preview_page: Array<{
    __typename?: 'learning_module_revision_pages'
    page: {
      __typename?: 'learning_pages'
      id: string
      title?: string | null
      body: any
    }
  }>
}

export type ModuleMetaResponseFragment = {
  __typename?: 'learning_modules'
  id: any
  slug?: string | null
  template_type?: string | null
  series_id: any
  status: any
  author_id: number
  position?: number | null
  has_subscription?: boolean | null
  price?: any | null
  ever_completed?: boolean | null
  has_passed?: boolean | null
  failed_attempts?: number | null
  reached_max_attempts?: boolean | null
  can_edit?: boolean | null
  can_publish?: boolean | null
  can_approve?: boolean | null
  author: { __typename?: 'users'; name: string }
  series: {
    __typename?: 'learning_series'
    id: any
    title: string
    slug: string
    series_type?: string | null
    library_type: string
    library_id: string
    sort_modules_by: any
    force_completion_order: boolean
    can_edit?: boolean | null
    payments_enabled: boolean
    has_subscription?: boolean | null
    accreditation_when: string
    certificate_template: string
    certificate_content?: any | null
    cme_expires_after_seconds?: number | null
    cme_expires_at?: any | null
    cme_credits_description?: string | null
    cme_reference?: string | null
    cme_accreditor?: string | null
    library?: {
      __typename?: 'groups'
      id: string
      slug: string
      title: string
      learning_subscriptions_enabled: boolean
    } | null
    subscription_settings?: {
      __typename?: 'learning_series_subscription_settings'
      module_price: any
      series_price: any
      available_as_module: boolean
      available_as_series: boolean
      subscription_interval?: string | null
      created_at: any
      created_by: number
      currency_code: string
      updated_at: any
      updated_by: number
    } | null
  }
  published_revision?: {
    __typename?: 'learning_module_revisions'
    id: any
  } | null
  active_subscription: Array<{
    __typename?: 'user_group_members'
    created_at: any
    expires_at?: any | null
  }>
}

export type ModulePreviewResponseFragment = {
  __typename?: 'learning_modules'
  id: any
  slug?: string | null
  template_type?: string | null
  series_id: any
  status: any
  author_id: number
  position?: number | null
  has_subscription?: boolean | null
  price?: any | null
  ever_completed?: boolean | null
  has_passed?: boolean | null
  failed_attempts?: number | null
  reached_max_attempts?: boolean | null
  can_edit?: boolean | null
  can_publish?: boolean | null
  can_approve?: boolean | null
  revision?: {
    __typename?: 'learning_module_revisions'
    id: any
    module_id: any
    status: any
    slug: string
    title: string
    description?: string | null
    rich_description?: string | null
    cover_image_id?: number | null
    cover_logo_id?: number | null
    card_thumbnail_id?: number | null
    cover_theme?: string | null
    cover_author?: string | null
    expected_completion_time_minutes?: number | null
    want_page_titles: boolean
    mcq_settings_enabled: boolean
    mcq_settings_feedback?: string | null
    mcq_settings_labels: string
    mcq_settings_retry: any
    mcq_settings_retry_limit?: any | null
    mcq_settings_show_score: boolean
    mcq_settings_pass_mark_type: any
    mcq_settings_pass_mark?: number | null
    cme_expires_after_seconds?: number | null
    cme_expires_at?: any | null
    cme_credits_description?: string | null
    cme_reference?: string | null
    cme_accreditor?: string | null
    objectives?: any | null
    settings?: any | null
    contains_mcq?: boolean | null
    feedback_form_id?: any | null
    categories?: any | null
    created_at: any
    redirect_url?: string | null
    module: { __typename?: 'learning_modules'; slug?: string | null }
    cover_image?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    cover_logo?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    card_thumbnail?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    pages: Array<{
      __typename?: 'learning_module_revision_pages'
      position: number
      page: {
        __typename?: 'learning_pages'
        id: string
        title?: string | null
        body: any
      }
    }>
    preview_page: Array<{
      __typename?: 'learning_module_revision_pages'
      page: {
        __typename?: 'learning_pages'
        id: string
        title?: string | null
        body: any
      }
    }>
  } | null
  author: { __typename?: 'users'; name: string }
  series: {
    __typename?: 'learning_series'
    id: any
    title: string
    slug: string
    series_type?: string | null
    library_type: string
    library_id: string
    sort_modules_by: any
    force_completion_order: boolean
    can_edit?: boolean | null
    payments_enabled: boolean
    has_subscription?: boolean | null
    accreditation_when: string
    certificate_template: string
    certificate_content?: any | null
    cme_expires_after_seconds?: number | null
    cme_expires_at?: any | null
    cme_credits_description?: string | null
    cme_reference?: string | null
    cme_accreditor?: string | null
    library?: {
      __typename?: 'groups'
      id: string
      slug: string
      title: string
      learning_subscriptions_enabled: boolean
    } | null
    subscription_settings?: {
      __typename?: 'learning_series_subscription_settings'
      module_price: any
      series_price: any
      available_as_module: boolean
      available_as_series: boolean
      subscription_interval?: string | null
      created_at: any
      created_by: number
      currency_code: string
      updated_at: any
      updated_by: number
    } | null
  }
  published_revision?: {
    __typename?: 'learning_module_revisions'
    id: any
  } | null
  active_subscription: Array<{
    __typename?: 'user_group_members'
    created_at: any
    expires_at?: any | null
  }>
}

export type ModulePublishedResponseFragment = {
  __typename?: 'learning_modules'
  id: any
  slug?: string | null
  template_type?: string | null
  series_id: any
  status: any
  author_id: number
  position?: number | null
  has_subscription?: boolean | null
  price?: any | null
  ever_completed?: boolean | null
  has_passed?: boolean | null
  failed_attempts?: number | null
  reached_max_attempts?: boolean | null
  can_edit?: boolean | null
  can_publish?: boolean | null
  can_approve?: boolean | null
  revision?: {
    __typename?: 'learning_module_revisions'
    id: any
    module_id: any
    status: any
    slug: string
    title: string
    description?: string | null
    rich_description?: string | null
    cover_image_id?: number | null
    cover_logo_id?: number | null
    card_thumbnail_id?: number | null
    cover_theme?: string | null
    cover_author?: string | null
    expected_completion_time_minutes?: number | null
    want_page_titles: boolean
    mcq_settings_enabled: boolean
    mcq_settings_feedback?: string | null
    mcq_settings_labels: string
    mcq_settings_retry: any
    mcq_settings_retry_limit?: any | null
    mcq_settings_show_score: boolean
    mcq_settings_pass_mark_type: any
    mcq_settings_pass_mark?: number | null
    cme_expires_after_seconds?: number | null
    cme_expires_at?: any | null
    cme_credits_description?: string | null
    cme_reference?: string | null
    cme_accreditor?: string | null
    objectives?: any | null
    settings?: any | null
    contains_mcq?: boolean | null
    feedback_form_id?: any | null
    categories?: any | null
    created_at: any
    redirect_url?: string | null
    module: { __typename?: 'learning_modules'; slug?: string | null }
    cover_image?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    cover_logo?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    card_thumbnail?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    pages: Array<{
      __typename?: 'learning_module_revision_pages'
      position: number
      page: {
        __typename?: 'learning_pages'
        id: string
        title?: string | null
        body: any
      }
    }>
    preview_page: Array<{
      __typename?: 'learning_module_revision_pages'
      page: {
        __typename?: 'learning_pages'
        id: string
        title?: string | null
        body: any
      }
    }>
  } | null
  author: { __typename?: 'users'; name: string }
  series: {
    __typename?: 'learning_series'
    id: any
    title: string
    slug: string
    series_type?: string | null
    library_type: string
    library_id: string
    sort_modules_by: any
    force_completion_order: boolean
    can_edit?: boolean | null
    payments_enabled: boolean
    has_subscription?: boolean | null
    accreditation_when: string
    certificate_template: string
    certificate_content?: any | null
    cme_expires_after_seconds?: number | null
    cme_expires_at?: any | null
    cme_credits_description?: string | null
    cme_reference?: string | null
    cme_accreditor?: string | null
    library?: {
      __typename?: 'groups'
      id: string
      slug: string
      title: string
      learning_subscriptions_enabled: boolean
    } | null
    subscription_settings?: {
      __typename?: 'learning_series_subscription_settings'
      module_price: any
      series_price: any
      available_as_module: boolean
      available_as_series: boolean
      subscription_interval?: string | null
      created_at: any
      created_by: number
      currency_code: string
      updated_at: any
      updated_by: number
    } | null
  }
  published_revision?: {
    __typename?: 'learning_module_revisions'
    id: any
  } | null
  active_subscription: Array<{
    __typename?: 'user_group_members'
    created_at: any
    expires_at?: any | null
  }>
}

export type ActiveSessionWithResponsesFragment = {
  __typename?: 'learning_learner_sessions'
  id: any
  started_at: any
  marks?: number | null
  marks_available?: number | null
  active: boolean
  revision: {
    __typename?: 'learning_module_revisions'
    id: any
    module_id: any
    status: any
    slug: string
    title: string
    description?: string | null
    rich_description?: string | null
    cover_image_id?: number | null
    cover_logo_id?: number | null
    card_thumbnail_id?: number | null
    cover_theme?: string | null
    cover_author?: string | null
    expected_completion_time_minutes?: number | null
    want_page_titles: boolean
    mcq_settings_enabled: boolean
    mcq_settings_feedback?: string | null
    mcq_settings_labels: string
    mcq_settings_retry: any
    mcq_settings_retry_limit?: any | null
    mcq_settings_show_score: boolean
    mcq_settings_pass_mark_type: any
    mcq_settings_pass_mark?: number | null
    cme_expires_after_seconds?: number | null
    cme_expires_at?: any | null
    cme_credits_description?: string | null
    cme_reference?: string | null
    cme_accreditor?: string | null
    objectives?: any | null
    settings?: any | null
    contains_mcq?: boolean | null
    feedback_form_id?: any | null
    categories?: any | null
    created_at: any
    redirect_url?: string | null
    module: { __typename?: 'learning_modules'; slug?: string | null }
    cover_image?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    cover_logo?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    card_thumbnail?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    pages: Array<{
      __typename?: 'learning_module_revision_pages'
      position: number
      page: {
        __typename?: 'learning_pages'
        id: string
        title?: string | null
        body: any
      }
    }>
    preview_page: Array<{
      __typename?: 'learning_module_revision_pages'
      page: {
        __typename?: 'learning_pages'
        id: string
        title?: string | null
        body: any
      }
    }>
  }
  responses: Array<{
    __typename?: 'learning_learner_session_feedback'
    question_id?: any | null
    feedback?: string | null
    answers?: any | null
  }>
}

export type FinishedSessionWithResponsesFragment = {
  __typename?: 'learning_learner_sessions'
  status?: string | null
  finished_at?: any | null
  id: any
  started_at: any
  marks?: number | null
  marks_available?: number | null
  active: boolean
  revision: {
    __typename?: 'learning_module_revisions'
    id: any
    module_id: any
    status: any
    slug: string
    title: string
    description?: string | null
    rich_description?: string | null
    cover_image_id?: number | null
    cover_logo_id?: number | null
    card_thumbnail_id?: number | null
    cover_theme?: string | null
    cover_author?: string | null
    expected_completion_time_minutes?: number | null
    want_page_titles: boolean
    mcq_settings_enabled: boolean
    mcq_settings_feedback?: string | null
    mcq_settings_labels: string
    mcq_settings_retry: any
    mcq_settings_retry_limit?: any | null
    mcq_settings_show_score: boolean
    mcq_settings_pass_mark_type: any
    mcq_settings_pass_mark?: number | null
    cme_expires_after_seconds?: number | null
    cme_expires_at?: any | null
    cme_credits_description?: string | null
    cme_reference?: string | null
    cme_accreditor?: string | null
    objectives?: any | null
    settings?: any | null
    contains_mcq?: boolean | null
    feedback_form_id?: any | null
    categories?: any | null
    created_at: any
    redirect_url?: string | null
    module: { __typename?: 'learning_modules'; slug?: string | null }
    cover_image?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    cover_logo?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    card_thumbnail?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    pages: Array<{
      __typename?: 'learning_module_revision_pages'
      position: number
      page: {
        __typename?: 'learning_pages'
        id: string
        title?: string | null
        body: any
      }
    }>
    preview_page: Array<{
      __typename?: 'learning_module_revision_pages'
      page: {
        __typename?: 'learning_pages'
        id: string
        title?: string | null
        body: any
      }
    }>
  }
  responses: Array<{
    __typename?: 'learning_learner_session_feedback'
    question_id?: any | null
    feedback?: string | null
    answers?: any | null
  }>
}

export type ModuleResponseAndCompletionFragment = {
  __typename?: 'learning_modules'
  id: any
  slug?: string | null
  template_type?: string | null
  series_id: any
  status: any
  author_id: number
  position?: number | null
  has_subscription?: boolean | null
  price?: any | null
  ever_completed?: boolean | null
  has_passed?: boolean | null
  failed_attempts?: number | null
  reached_max_attempts?: boolean | null
  can_edit?: boolean | null
  can_publish?: boolean | null
  can_approve?: boolean | null
  reflections: Array<{
    __typename?: 'learning_learner_reflections'
    body: string
  }>
  ratings: Array<{
    __typename?: 'learning_learner_module_ratings'
    responses_aggregate: {
      __typename?: 'learning_learner_module_rating_responses_aggregate'
      aggregate?: {
        __typename?: 'learning_learner_module_rating_responses_aggregate_fields'
        count: number
      } | null
    }
  }>
  latestSession: Array<{
    __typename?: 'learning_learner_sessions'
    last_event_at: any
    marks?: number | null
    marks_available?: number | null
    status?: string | null
    finished_at?: any | null
    id: any
    started_at: any
    active: boolean
    events: Array<{
      __typename?: 'learning_learner_event_log'
      event: any
      time: any
    }>
    revision: {
      __typename?: 'learning_module_revisions'
      id: any
      module_id: any
      status: any
      slug: string
      title: string
      description?: string | null
      rich_description?: string | null
      cover_image_id?: number | null
      cover_logo_id?: number | null
      card_thumbnail_id?: number | null
      cover_theme?: string | null
      cover_author?: string | null
      expected_completion_time_minutes?: number | null
      want_page_titles: boolean
      mcq_settings_enabled: boolean
      mcq_settings_feedback?: string | null
      mcq_settings_labels: string
      mcq_settings_retry: any
      mcq_settings_retry_limit?: any | null
      mcq_settings_show_score: boolean
      mcq_settings_pass_mark_type: any
      mcq_settings_pass_mark?: number | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      objectives?: any | null
      settings?: any | null
      contains_mcq?: boolean | null
      feedback_form_id?: any | null
      categories?: any | null
      created_at: any
      redirect_url?: string | null
      module: { __typename?: 'learning_modules'; slug?: string | null }
      cover_image?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      cover_logo?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      card_thumbnail?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      pages: Array<{
        __typename?: 'learning_module_revision_pages'
        position: number
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
      preview_page: Array<{
        __typename?: 'learning_module_revision_pages'
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
    }
    responses: Array<{
      __typename?: 'learning_learner_session_feedback'
      question_id?: any | null
      feedback?: string | null
      answers?: any | null
    }>
  }>
  everCompleted: Array<{
    __typename?: 'learning_learner_sessions'
    finished_at?: any | null
  }>
  revision?: {
    __typename?: 'learning_module_revisions'
    id: any
    module_id: any
    status: any
    slug: string
    title: string
    description?: string | null
    rich_description?: string | null
    cover_image_id?: number | null
    cover_logo_id?: number | null
    card_thumbnail_id?: number | null
    cover_theme?: string | null
    cover_author?: string | null
    expected_completion_time_minutes?: number | null
    want_page_titles: boolean
    mcq_settings_enabled: boolean
    mcq_settings_feedback?: string | null
    mcq_settings_labels: string
    mcq_settings_retry: any
    mcq_settings_retry_limit?: any | null
    mcq_settings_show_score: boolean
    mcq_settings_pass_mark_type: any
    mcq_settings_pass_mark?: number | null
    cme_expires_after_seconds?: number | null
    cme_expires_at?: any | null
    cme_credits_description?: string | null
    cme_reference?: string | null
    cme_accreditor?: string | null
    objectives?: any | null
    settings?: any | null
    contains_mcq?: boolean | null
    feedback_form_id?: any | null
    categories?: any | null
    created_at: any
    redirect_url?: string | null
    module: { __typename?: 'learning_modules'; slug?: string | null }
    cover_image?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    cover_logo?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    card_thumbnail?: {
      __typename?: 'learning_uploads'
      id: number
      path: string
    } | null
    pages: Array<{
      __typename?: 'learning_module_revision_pages'
      position: number
      page: {
        __typename?: 'learning_pages'
        id: string
        title?: string | null
        body: any
      }
    }>
    preview_page: Array<{
      __typename?: 'learning_module_revision_pages'
      page: {
        __typename?: 'learning_pages'
        id: string
        title?: string | null
        body: any
      }
    }>
  } | null
  author: { __typename?: 'users'; name: string }
  series: {
    __typename?: 'learning_series'
    id: any
    title: string
    slug: string
    series_type?: string | null
    library_type: string
    library_id: string
    sort_modules_by: any
    force_completion_order: boolean
    can_edit?: boolean | null
    payments_enabled: boolean
    has_subscription?: boolean | null
    accreditation_when: string
    certificate_template: string
    certificate_content?: any | null
    cme_expires_after_seconds?: number | null
    cme_expires_at?: any | null
    cme_credits_description?: string | null
    cme_reference?: string | null
    cme_accreditor?: string | null
    library?: {
      __typename?: 'groups'
      id: string
      slug: string
      title: string
      learning_subscriptions_enabled: boolean
    } | null
    subscription_settings?: {
      __typename?: 'learning_series_subscription_settings'
      module_price: any
      series_price: any
      available_as_module: boolean
      available_as_series: boolean
      subscription_interval?: string | null
      created_at: any
      created_by: number
      currency_code: string
      updated_at: any
      updated_by: number
    } | null
  }
  published_revision?: {
    __typename?: 'learning_module_revisions'
    id: any
  } | null
  active_subscription: Array<{
    __typename?: 'user_group_members'
    created_at: any
    expires_at?: any | null
  }>
}

export type SeriesWithModulesFragment = {
  __typename?: 'learning_series'
  id: any
  series_type?: string | null
  library_type: string
  library_id: string
  slug: string
  title: string
  display_author?: string | null
  description?: string | null
  rich_description?: string | null
  sort_modules_by: any
  cme_accreditor?: string | null
  payments_enabled: boolean
  has_subscription?: boolean | null
  force_completion_order: boolean
  accreditation_when: string
  modules: Array<{
    __typename?: 'learning_modules'
    id: any
    slug?: string | null
    template_type?: string | null
    series_id: any
    status: any
    author_id: number
    position?: number | null
    has_subscription?: boolean | null
    price?: any | null
    ever_completed?: boolean | null
    has_passed?: boolean | null
    failed_attempts?: number | null
    reached_max_attempts?: boolean | null
    can_edit?: boolean | null
    can_publish?: boolean | null
    can_approve?: boolean | null
    revision?: {
      __typename?: 'learning_module_revisions'
      id: any
      module_id: any
      status: any
      slug: string
      title: string
      description?: string | null
      rich_description?: string | null
      cover_image_id?: number | null
      cover_logo_id?: number | null
      card_thumbnail_id?: number | null
      cover_theme?: string | null
      cover_author?: string | null
      expected_completion_time_minutes?: number | null
      want_page_titles: boolean
      mcq_settings_enabled: boolean
      mcq_settings_feedback?: string | null
      mcq_settings_labels: string
      mcq_settings_retry: any
      mcq_settings_retry_limit?: any | null
      mcq_settings_show_score: boolean
      mcq_settings_pass_mark_type: any
      mcq_settings_pass_mark?: number | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      objectives?: any | null
      settings?: any | null
      contains_mcq?: boolean | null
      feedback_form_id?: any | null
      categories?: any | null
      created_at: any
      redirect_url?: string | null
      module: { __typename?: 'learning_modules'; slug?: string | null }
      cover_image?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      cover_logo?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      card_thumbnail?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      pages: Array<{
        __typename?: 'learning_module_revision_pages'
        position: number
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
      preview_page: Array<{
        __typename?: 'learning_module_revision_pages'
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
    } | null
    author: { __typename?: 'users'; name: string }
    series: {
      __typename?: 'learning_series'
      id: any
      title: string
      slug: string
      series_type?: string | null
      library_type: string
      library_id: string
      sort_modules_by: any
      force_completion_order: boolean
      can_edit?: boolean | null
      payments_enabled: boolean
      has_subscription?: boolean | null
      accreditation_when: string
      certificate_template: string
      certificate_content?: any | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      library?: {
        __typename?: 'groups'
        id: string
        slug: string
        title: string
        learning_subscriptions_enabled: boolean
      } | null
      subscription_settings?: {
        __typename?: 'learning_series_subscription_settings'
        module_price: any
        series_price: any
        available_as_module: boolean
        available_as_series: boolean
        subscription_interval?: string | null
        created_at: any
        created_by: number
        currency_code: string
        updated_at: any
        updated_by: number
      } | null
    }
    published_revision?: {
      __typename?: 'learning_module_revisions'
      id: any
    } | null
    active_subscription: Array<{
      __typename?: 'user_group_members'
      created_at: any
      expires_at?: any | null
    }>
  }>
  library?: {
    __typename?: 'groups'
    id: string
    slug: string
    title: string
    learning_subscriptions_enabled: boolean
  } | null
  image?: { __typename?: 'learning_uploads'; id: number; path: string } | null
  star_rating?: {
    __typename?: 'learning_series_star_ratings'
    rating?: any | null
  } | null
  editor?: { __typename?: 'users'; id: number; name: string } | null
  subscription_settings?: {
    __typename?: 'learning_series_subscription_settings'
    module_price: any
    series_price: any
    available_as_module: boolean
    available_as_series: boolean
    subscription_interval?: string | null
    created_at: any
    created_by: number
    currency_code: string
    updated_at: any
    updated_by: number
  } | null
  module_count: {
    __typename?: 'learning_modules_aggregate'
    aggregate?: {
      __typename?: 'learning_modules_aggregate_fields'
      count: number
    } | null
  }
  completed_count: {
    __typename?: 'learning_modules_aggregate'
    aggregate?: {
      __typename?: 'learning_modules_aggregate_fields'
      count: number
    } | null
  }
  active_subscription: Array<{
    __typename?: 'user_group_members'
    created_at: any
    expires_at?: any | null
  }>
}

export type SeriesWithModulesAndCompletionFragment = {
  __typename?: 'learning_series'
  id: any
  series_type?: string | null
  library_type: string
  library_id: string
  slug: string
  title: string
  display_author?: string | null
  description?: string | null
  rich_description?: string | null
  sort_modules_by: any
  cme_accreditor?: string | null
  payments_enabled: boolean
  has_subscription?: boolean | null
  force_completion_order: boolean
  accreditation_when: string
  modules: Array<{
    __typename?: 'learning_modules'
    id: any
    slug?: string | null
    template_type?: string | null
    series_id: any
    status: any
    author_id: number
    position?: number | null
    has_subscription?: boolean | null
    price?: any | null
    ever_completed?: boolean | null
    has_passed?: boolean | null
    failed_attempts?: number | null
    reached_max_attempts?: boolean | null
    can_edit?: boolean | null
    can_publish?: boolean | null
    can_approve?: boolean | null
    reflections: Array<{
      __typename?: 'learning_learner_reflections'
      body: string
    }>
    ratings: Array<{
      __typename?: 'learning_learner_module_ratings'
      responses_aggregate: {
        __typename?: 'learning_learner_module_rating_responses_aggregate'
        aggregate?: {
          __typename?: 'learning_learner_module_rating_responses_aggregate_fields'
          count: number
        } | null
      }
    }>
    latestSession: Array<{
      __typename?: 'learning_learner_sessions'
      last_event_at: any
      marks?: number | null
      marks_available?: number | null
      status?: string | null
      finished_at?: any | null
      id: any
      started_at: any
      active: boolean
      events: Array<{
        __typename?: 'learning_learner_event_log'
        event: any
        time: any
      }>
      revision: {
        __typename?: 'learning_module_revisions'
        id: any
        module_id: any
        status: any
        slug: string
        title: string
        description?: string | null
        rich_description?: string | null
        cover_image_id?: number | null
        cover_logo_id?: number | null
        card_thumbnail_id?: number | null
        cover_theme?: string | null
        cover_author?: string | null
        expected_completion_time_minutes?: number | null
        want_page_titles: boolean
        mcq_settings_enabled: boolean
        mcq_settings_feedback?: string | null
        mcq_settings_labels: string
        mcq_settings_retry: any
        mcq_settings_retry_limit?: any | null
        mcq_settings_show_score: boolean
        mcq_settings_pass_mark_type: any
        mcq_settings_pass_mark?: number | null
        cme_expires_after_seconds?: number | null
        cme_expires_at?: any | null
        cme_credits_description?: string | null
        cme_reference?: string | null
        cme_accreditor?: string | null
        objectives?: any | null
        settings?: any | null
        contains_mcq?: boolean | null
        feedback_form_id?: any | null
        categories?: any | null
        created_at: any
        redirect_url?: string | null
        module: { __typename?: 'learning_modules'; slug?: string | null }
        cover_image?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        cover_logo?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        card_thumbnail?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        pages: Array<{
          __typename?: 'learning_module_revision_pages'
          position: number
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
        preview_page: Array<{
          __typename?: 'learning_module_revision_pages'
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
      }
      responses: Array<{
        __typename?: 'learning_learner_session_feedback'
        question_id?: any | null
        feedback?: string | null
        answers?: any | null
      }>
    }>
    everCompleted: Array<{
      __typename?: 'learning_learner_sessions'
      finished_at?: any | null
    }>
    revision?: {
      __typename?: 'learning_module_revisions'
      id: any
      module_id: any
      status: any
      slug: string
      title: string
      description?: string | null
      rich_description?: string | null
      cover_image_id?: number | null
      cover_logo_id?: number | null
      card_thumbnail_id?: number | null
      cover_theme?: string | null
      cover_author?: string | null
      expected_completion_time_minutes?: number | null
      want_page_titles: boolean
      mcq_settings_enabled: boolean
      mcq_settings_feedback?: string | null
      mcq_settings_labels: string
      mcq_settings_retry: any
      mcq_settings_retry_limit?: any | null
      mcq_settings_show_score: boolean
      mcq_settings_pass_mark_type: any
      mcq_settings_pass_mark?: number | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      objectives?: any | null
      settings?: any | null
      contains_mcq?: boolean | null
      feedback_form_id?: any | null
      categories?: any | null
      created_at: any
      redirect_url?: string | null
      module: { __typename?: 'learning_modules'; slug?: string | null }
      cover_image?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      cover_logo?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      card_thumbnail?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      pages: Array<{
        __typename?: 'learning_module_revision_pages'
        position: number
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
      preview_page: Array<{
        __typename?: 'learning_module_revision_pages'
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
    } | null
    author: { __typename?: 'users'; name: string }
    series: {
      __typename?: 'learning_series'
      id: any
      title: string
      slug: string
      series_type?: string | null
      library_type: string
      library_id: string
      sort_modules_by: any
      force_completion_order: boolean
      can_edit?: boolean | null
      payments_enabled: boolean
      has_subscription?: boolean | null
      accreditation_when: string
      certificate_template: string
      certificate_content?: any | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      library?: {
        __typename?: 'groups'
        id: string
        slug: string
        title: string
        learning_subscriptions_enabled: boolean
      } | null
      subscription_settings?: {
        __typename?: 'learning_series_subscription_settings'
        module_price: any
        series_price: any
        available_as_module: boolean
        available_as_series: boolean
        subscription_interval?: string | null
        created_at: any
        created_by: number
        currency_code: string
        updated_at: any
        updated_by: number
      } | null
    }
    published_revision?: {
      __typename?: 'learning_module_revisions'
      id: any
    } | null
    active_subscription: Array<{
      __typename?: 'user_group_members'
      created_at: any
      expires_at?: any | null
    }>
  }>
  library?: {
    __typename?: 'groups'
    id: string
    slug: string
    title: string
    learning_subscriptions_enabled: boolean
  } | null
  image?: { __typename?: 'learning_uploads'; id: number; path: string } | null
  star_rating?: {
    __typename?: 'learning_series_star_ratings'
    rating?: any | null
  } | null
  editor?: { __typename?: 'users'; id: number; name: string } | null
  subscription_settings?: {
    __typename?: 'learning_series_subscription_settings'
    module_price: any
    series_price: any
    available_as_module: boolean
    available_as_series: boolean
    subscription_interval?: string | null
    created_at: any
    created_by: number
    currency_code: string
    updated_at: any
    updated_by: number
  } | null
  module_count: {
    __typename?: 'learning_modules_aggregate'
    aggregate?: {
      __typename?: 'learning_modules_aggregate_fields'
      count: number
    } | null
  }
  completed_count: {
    __typename?: 'learning_modules_aggregate'
    aggregate?: {
      __typename?: 'learning_modules_aggregate_fields'
      count: number
    } | null
  }
  active_subscription: Array<{
    __typename?: 'user_group_members'
    created_at: any
    expires_at?: any | null
  }>
}

export type LibraryContentQueryVariables = Exact<{
  libraryType: Scalars['String']
  libraryID: Scalars['String']
  seriesType: Scalars['String']
  disallowSubscriptions?: InputMaybe<Boolean_Comparison_Exp>
}>

export type LibraryContentQuery = {
  __typename?: 'query_root'
  series: Array<{
    __typename?: 'learning_series'
    id: any
    series_type?: string | null
    library_type: string
    library_id: string
    slug: string
    title: string
    display_author?: string | null
    description?: string | null
    rich_description?: string | null
    sort_modules_by: any
    cme_accreditor?: string | null
    payments_enabled: boolean
    has_subscription?: boolean | null
    force_completion_order: boolean
    accreditation_when: string
    modules: Array<{
      __typename?: 'learning_modules'
      id: any
      slug?: string | null
      template_type?: string | null
      series_id: any
      status: any
      author_id: number
      position?: number | null
      has_subscription?: boolean | null
      price?: any | null
      ever_completed?: boolean | null
      has_passed?: boolean | null
      failed_attempts?: number | null
      reached_max_attempts?: boolean | null
      can_edit?: boolean | null
      can_publish?: boolean | null
      can_approve?: boolean | null
      revision?: {
        __typename?: 'learning_module_revisions'
        id: any
        module_id: any
        status: any
        slug: string
        title: string
        description?: string | null
        rich_description?: string | null
        cover_image_id?: number | null
        cover_logo_id?: number | null
        card_thumbnail_id?: number | null
        cover_theme?: string | null
        cover_author?: string | null
        expected_completion_time_minutes?: number | null
        want_page_titles: boolean
        mcq_settings_enabled: boolean
        mcq_settings_feedback?: string | null
        mcq_settings_labels: string
        mcq_settings_retry: any
        mcq_settings_retry_limit?: any | null
        mcq_settings_show_score: boolean
        mcq_settings_pass_mark_type: any
        mcq_settings_pass_mark?: number | null
        cme_expires_after_seconds?: number | null
        cme_expires_at?: any | null
        cme_credits_description?: string | null
        cme_reference?: string | null
        cme_accreditor?: string | null
        objectives?: any | null
        settings?: any | null
        contains_mcq?: boolean | null
        feedback_form_id?: any | null
        categories?: any | null
        created_at: any
        redirect_url?: string | null
        module: { __typename?: 'learning_modules'; slug?: string | null }
        cover_image?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        cover_logo?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        card_thumbnail?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        pages: Array<{
          __typename?: 'learning_module_revision_pages'
          position: number
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
        preview_page: Array<{
          __typename?: 'learning_module_revision_pages'
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
      } | null
      author: { __typename?: 'users'; name: string }
      series: {
        __typename?: 'learning_series'
        id: any
        title: string
        slug: string
        series_type?: string | null
        library_type: string
        library_id: string
        sort_modules_by: any
        force_completion_order: boolean
        can_edit?: boolean | null
        payments_enabled: boolean
        has_subscription?: boolean | null
        accreditation_when: string
        certificate_template: string
        certificate_content?: any | null
        cme_expires_after_seconds?: number | null
        cme_expires_at?: any | null
        cme_credits_description?: string | null
        cme_reference?: string | null
        cme_accreditor?: string | null
        library?: {
          __typename?: 'groups'
          id: string
          slug: string
          title: string
          learning_subscriptions_enabled: boolean
        } | null
        subscription_settings?: {
          __typename?: 'learning_series_subscription_settings'
          module_price: any
          series_price: any
          available_as_module: boolean
          available_as_series: boolean
          subscription_interval?: string | null
          created_at: any
          created_by: number
          currency_code: string
          updated_at: any
          updated_by: number
        } | null
      }
      published_revision?: {
        __typename?: 'learning_module_revisions'
        id: any
      } | null
      active_subscription: Array<{
        __typename?: 'user_group_members'
        created_at: any
        expires_at?: any | null
      }>
    }>
    library?: {
      __typename?: 'groups'
      id: string
      slug: string
      title: string
      learning_subscriptions_enabled: boolean
    } | null
    image?: { __typename?: 'learning_uploads'; id: number; path: string } | null
    star_rating?: {
      __typename?: 'learning_series_star_ratings'
      rating?: any | null
    } | null
    editor?: { __typename?: 'users'; id: number; name: string } | null
    subscription_settings?: {
      __typename?: 'learning_series_subscription_settings'
      module_price: any
      series_price: any
      available_as_module: boolean
      available_as_series: boolean
      subscription_interval?: string | null
      created_at: any
      created_by: number
      currency_code: string
      updated_at: any
      updated_by: number
    } | null
    module_count: {
      __typename?: 'learning_modules_aggregate'
      aggregate?: {
        __typename?: 'learning_modules_aggregate_fields'
        count: number
      } | null
    }
    completed_count: {
      __typename?: 'learning_modules_aggregate'
      aggregate?: {
        __typename?: 'learning_modules_aggregate_fields'
        count: number
      } | null
    }
    active_subscription: Array<{
      __typename?: 'user_group_members'
      created_at: any
      expires_at?: any | null
    }>
  }>
}

export type LogLearnerSessionStartMutationVariables = Exact<{
  moduleID: Scalars['uuid']
  actorID: Scalars['Int']
}>

export type LogLearnerSessionStartMutation = {
  __typename?: 'mutation_root'
  event?: {
    __typename?: 'learning_learner_event_log'
    id: number
    time: any
    marks?: number | null
    session: {
      __typename?: 'learning_learner_sessions'
      id: any
      started_at: any
      marks?: number | null
      marks_available?: number | null
      active: boolean
      revision: {
        __typename?: 'learning_module_revisions'
        id: any
        module_id: any
        status: any
        slug: string
        title: string
        description?: string | null
        rich_description?: string | null
        cover_image_id?: number | null
        cover_logo_id?: number | null
        card_thumbnail_id?: number | null
        cover_theme?: string | null
        cover_author?: string | null
        expected_completion_time_minutes?: number | null
        want_page_titles: boolean
        mcq_settings_enabled: boolean
        mcq_settings_feedback?: string | null
        mcq_settings_labels: string
        mcq_settings_retry: any
        mcq_settings_retry_limit?: any | null
        mcq_settings_show_score: boolean
        mcq_settings_pass_mark_type: any
        mcq_settings_pass_mark?: number | null
        cme_expires_after_seconds?: number | null
        cme_expires_at?: any | null
        cme_credits_description?: string | null
        cme_reference?: string | null
        cme_accreditor?: string | null
        objectives?: any | null
        settings?: any | null
        contains_mcq?: boolean | null
        feedback_form_id?: any | null
        categories?: any | null
        created_at: any
        redirect_url?: string | null
        module: { __typename?: 'learning_modules'; slug?: string | null }
        cover_image?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        cover_logo?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        card_thumbnail?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        pages: Array<{
          __typename?: 'learning_module_revision_pages'
          position: number
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
        preview_page: Array<{
          __typename?: 'learning_module_revision_pages'
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
      }
      responses: Array<{
        __typename?: 'learning_learner_session_feedback'
        question_id?: any | null
        feedback?: string | null
        answers?: any | null
      }>
    }
  } | null
}

export type LogLearnerSessionFinishMutationVariables = Exact<{
  moduleID: Scalars['uuid']
  actorID: Scalars['Int']
}>

export type LogLearnerSessionFinishMutation = {
  __typename?: 'mutation_root'
  event?: {
    __typename?: 'learning_learner_event_log'
    id: number
    time: any
    marks?: number | null
    session: {
      __typename?: 'learning_learner_sessions'
      status?: string | null
      finished_at?: any | null
      id: any
      started_at: any
      marks?: number | null
      marks_available?: number | null
      active: boolean
      revision: {
        __typename?: 'learning_module_revisions'
        id: any
        module_id: any
        status: any
        slug: string
        title: string
        description?: string | null
        rich_description?: string | null
        cover_image_id?: number | null
        cover_logo_id?: number | null
        card_thumbnail_id?: number | null
        cover_theme?: string | null
        cover_author?: string | null
        expected_completion_time_minutes?: number | null
        want_page_titles: boolean
        mcq_settings_enabled: boolean
        mcq_settings_feedback?: string | null
        mcq_settings_labels: string
        mcq_settings_retry: any
        mcq_settings_retry_limit?: any | null
        mcq_settings_show_score: boolean
        mcq_settings_pass_mark_type: any
        mcq_settings_pass_mark?: number | null
        cme_expires_after_seconds?: number | null
        cme_expires_at?: any | null
        cme_credits_description?: string | null
        cme_reference?: string | null
        cme_accreditor?: string | null
        objectives?: any | null
        settings?: any | null
        contains_mcq?: boolean | null
        feedback_form_id?: any | null
        categories?: any | null
        created_at: any
        redirect_url?: string | null
        module: { __typename?: 'learning_modules'; slug?: string | null }
        cover_image?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        cover_logo?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        card_thumbnail?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        pages: Array<{
          __typename?: 'learning_module_revision_pages'
          position: number
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
        preview_page: Array<{
          __typename?: 'learning_module_revision_pages'
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
      }
      responses: Array<{
        __typename?: 'learning_learner_session_feedback'
        question_id?: any | null
        feedback?: string | null
        answers?: any | null
      }>
    }
  } | null
}

export type LogLearnerSessionAnswerMutationVariables = Exact<{
  moduleID: Scalars['uuid']
  pageID: Scalars['String']
  questionID: Scalars['uuid']
  answers?: InputMaybe<Scalars['jsonb']>
}>

export type LogLearnerSessionAnswerMutation = {
  __typename?: 'mutation_root'
  event?: {
    __typename?: 'learning_learner_event_log'
    id: number
    time: any
    marks?: number | null
    session: {
      __typename?: 'learning_learner_sessions'
      id: any
      started_at: any
      marks?: number | null
      marks_available?: number | null
      active: boolean
      revision: {
        __typename?: 'learning_module_revisions'
        id: any
        module_id: any
        status: any
        slug: string
        title: string
        description?: string | null
        rich_description?: string | null
        cover_image_id?: number | null
        cover_logo_id?: number | null
        card_thumbnail_id?: number | null
        cover_theme?: string | null
        cover_author?: string | null
        expected_completion_time_minutes?: number | null
        want_page_titles: boolean
        mcq_settings_enabled: boolean
        mcq_settings_feedback?: string | null
        mcq_settings_labels: string
        mcq_settings_retry: any
        mcq_settings_retry_limit?: any | null
        mcq_settings_show_score: boolean
        mcq_settings_pass_mark_type: any
        mcq_settings_pass_mark?: number | null
        cme_expires_after_seconds?: number | null
        cme_expires_at?: any | null
        cme_credits_description?: string | null
        cme_reference?: string | null
        cme_accreditor?: string | null
        objectives?: any | null
        settings?: any | null
        contains_mcq?: boolean | null
        feedback_form_id?: any | null
        categories?: any | null
        created_at: any
        redirect_url?: string | null
        module: { __typename?: 'learning_modules'; slug?: string | null }
        cover_image?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        cover_logo?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        card_thumbnail?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        pages: Array<{
          __typename?: 'learning_module_revision_pages'
          position: number
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
        preview_page: Array<{
          __typename?: 'learning_module_revision_pages'
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
      }
      responses: Array<{
        __typename?: 'learning_learner_session_feedback'
        question_id?: any | null
        feedback?: string | null
        answers?: any | null
      }>
    }
  } | null
}

export type LearnerSessionsForModuleQueryVariables = Exact<{
  actorID: Scalars['Int']
  moduleID: Scalars['uuid']
}>

export type LearnerSessionsForModuleQuery = {
  __typename?: 'query_root'
  learning_learner_sessions: Array<{
    __typename?: 'learning_learner_sessions'
    id: any
    status?: string | null
    finished_at?: any | null
    started_at: any
    marks?: number | null
    marks_available?: number | null
    active: boolean
    revision: {
      __typename?: 'learning_module_revisions'
      id: any
      module_id: any
      status: any
      slug: string
      title: string
      description?: string | null
      rich_description?: string | null
      cover_image_id?: number | null
      cover_logo_id?: number | null
      card_thumbnail_id?: number | null
      cover_theme?: string | null
      cover_author?: string | null
      expected_completion_time_minutes?: number | null
      want_page_titles: boolean
      mcq_settings_enabled: boolean
      mcq_settings_feedback?: string | null
      mcq_settings_labels: string
      mcq_settings_retry: any
      mcq_settings_retry_limit?: any | null
      mcq_settings_show_score: boolean
      mcq_settings_pass_mark_type: any
      mcq_settings_pass_mark?: number | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      objectives?: any | null
      settings?: any | null
      contains_mcq?: boolean | null
      feedback_form_id?: any | null
      categories?: any | null
      created_at: any
      redirect_url?: string | null
      module: { __typename?: 'learning_modules'; slug?: string | null }
      cover_image?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      cover_logo?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      card_thumbnail?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      pages: Array<{
        __typename?: 'learning_module_revision_pages'
        position: number
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
      preview_page: Array<{
        __typename?: 'learning_module_revision_pages'
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
    }
    events: Array<{
      __typename?: 'learning_learner_event_log'
      id: number
      time: any
      marks?: number | null
      question_id?: any | null
      event: any
      session: {
        __typename?: 'learning_learner_sessions'
        status?: string | null
        finished_at?: any | null
        id: any
        started_at: any
        marks?: number | null
        marks_available?: number | null
        active: boolean
        revision: {
          __typename?: 'learning_module_revisions'
          id: any
          module_id: any
          status: any
          slug: string
          title: string
          description?: string | null
          rich_description?: string | null
          cover_image_id?: number | null
          cover_logo_id?: number | null
          card_thumbnail_id?: number | null
          cover_theme?: string | null
          cover_author?: string | null
          expected_completion_time_minutes?: number | null
          want_page_titles: boolean
          mcq_settings_enabled: boolean
          mcq_settings_feedback?: string | null
          mcq_settings_labels: string
          mcq_settings_retry: any
          mcq_settings_retry_limit?: any | null
          mcq_settings_show_score: boolean
          mcq_settings_pass_mark_type: any
          mcq_settings_pass_mark?: number | null
          cme_expires_after_seconds?: number | null
          cme_expires_at?: any | null
          cme_credits_description?: string | null
          cme_reference?: string | null
          cme_accreditor?: string | null
          objectives?: any | null
          settings?: any | null
          contains_mcq?: boolean | null
          feedback_form_id?: any | null
          categories?: any | null
          created_at: any
          redirect_url?: string | null
          module: { __typename?: 'learning_modules'; slug?: string | null }
          cover_image?: {
            __typename?: 'learning_uploads'
            id: number
            path: string
          } | null
          cover_logo?: {
            __typename?: 'learning_uploads'
            id: number
            path: string
          } | null
          card_thumbnail?: {
            __typename?: 'learning_uploads'
            id: number
            path: string
          } | null
          pages: Array<{
            __typename?: 'learning_module_revision_pages'
            position: number
            page: {
              __typename?: 'learning_pages'
              id: string
              title?: string | null
              body: any
            }
          }>
          preview_page: Array<{
            __typename?: 'learning_module_revision_pages'
            page: {
              __typename?: 'learning_pages'
              id: string
              title?: string | null
              body: any
            }
          }>
        }
        responses: Array<{
          __typename?: 'learning_learner_session_feedback'
          question_id?: any | null
          feedback?: string | null
          answers?: any | null
        }>
      }
    }>
    responses: Array<{
      __typename?: 'learning_learner_session_feedback'
      question_id?: any | null
      feedback?: string | null
      answers?: any | null
    }>
  }>
}

export type ActiveLearnerSessionQueryVariables = Exact<{
  moduleID: Scalars['uuid']
  actorID: Scalars['Int']
}>

export type ActiveLearnerSessionQuery = {
  __typename?: 'query_root'
  learning_learner_sessions: Array<{
    __typename?: 'learning_learner_sessions'
    id: any
    active: boolean
  }>
}

export type MyLearningQueryVariables = Exact<{
  userID: Scalars['Int']
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}>

export type MyLearningQuery = {
  __typename?: 'query_root'
  authored_modules: Array<{
    __typename?: 'learning_modules'
    id: any
    slug?: string | null
    template_type?: string | null
    series_id: any
    status: any
    author_id: number
    position?: number | null
    has_subscription?: boolean | null
    price?: any | null
    ever_completed?: boolean | null
    has_passed?: boolean | null
    failed_attempts?: number | null
    reached_max_attempts?: boolean | null
    can_edit?: boolean | null
    can_publish?: boolean | null
    can_approve?: boolean | null
    revision?: {
      __typename?: 'learning_module_revisions'
      id: any
      module_id: any
      status: any
      slug: string
      title: string
      description?: string | null
      rich_description?: string | null
      cover_image_id?: number | null
      cover_logo_id?: number | null
      card_thumbnail_id?: number | null
      cover_theme?: string | null
      cover_author?: string | null
      expected_completion_time_minutes?: number | null
      want_page_titles: boolean
      mcq_settings_enabled: boolean
      mcq_settings_feedback?: string | null
      mcq_settings_labels: string
      mcq_settings_retry: any
      mcq_settings_retry_limit?: any | null
      mcq_settings_show_score: boolean
      mcq_settings_pass_mark_type: any
      mcq_settings_pass_mark?: number | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      objectives?: any | null
      settings?: any | null
      contains_mcq?: boolean | null
      feedback_form_id?: any | null
      categories?: any | null
      created_at: any
      redirect_url?: string | null
      module: { __typename?: 'learning_modules'; slug?: string | null }
      cover_image?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      cover_logo?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      card_thumbnail?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      pages: Array<{
        __typename?: 'learning_module_revision_pages'
        position: number
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
      preview_page: Array<{
        __typename?: 'learning_module_revision_pages'
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
    } | null
    author: { __typename?: 'users'; name: string }
    series: {
      __typename?: 'learning_series'
      id: any
      title: string
      slug: string
      series_type?: string | null
      library_type: string
      library_id: string
      sort_modules_by: any
      force_completion_order: boolean
      can_edit?: boolean | null
      payments_enabled: boolean
      has_subscription?: boolean | null
      accreditation_when: string
      certificate_template: string
      certificate_content?: any | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      library?: {
        __typename?: 'groups'
        id: string
        slug: string
        title: string
        learning_subscriptions_enabled: boolean
      } | null
      subscription_settings?: {
        __typename?: 'learning_series_subscription_settings'
        module_price: any
        series_price: any
        available_as_module: boolean
        available_as_series: boolean
        subscription_interval?: string | null
        created_at: any
        created_by: number
        currency_code: string
        updated_at: any
        updated_by: number
      } | null
    }
    published_revision?: {
      __typename?: 'learning_module_revisions'
      id: any
    } | null
    active_subscription: Array<{
      __typename?: 'user_group_members'
      created_at: any
      expires_at?: any | null
    }>
  }>
  in_progress: Array<{
    __typename?: 'learning_learner_sessions'
    id: any
    active: boolean
    last_event_at: any
    finished_at?: any | null
    marks?: number | null
    marks_available?: number | null
    module?: {
      __typename?: 'learning_modules'
      id: any
      slug?: string | null
      template_type?: string | null
      series_id: any
      status: any
      author_id: number
      position?: number | null
      has_subscription?: boolean | null
      price?: any | null
      ever_completed?: boolean | null
      has_passed?: boolean | null
      failed_attempts?: number | null
      reached_max_attempts?: boolean | null
      can_edit?: boolean | null
      can_publish?: boolean | null
      can_approve?: boolean | null
      reflections: Array<{
        __typename?: 'learning_learner_reflections'
        body: string
      }>
      ratings: Array<{
        __typename?: 'learning_learner_module_ratings'
        responses_aggregate: {
          __typename?: 'learning_learner_module_rating_responses_aggregate'
          aggregate?: {
            __typename?: 'learning_learner_module_rating_responses_aggregate_fields'
            count: number
          } | null
        }
      }>
      latestSession: Array<{
        __typename?: 'learning_learner_sessions'
        last_event_at: any
        marks?: number | null
        marks_available?: number | null
        status?: string | null
        finished_at?: any | null
        id: any
        started_at: any
        active: boolean
        events: Array<{
          __typename?: 'learning_learner_event_log'
          event: any
          time: any
        }>
        revision: {
          __typename?: 'learning_module_revisions'
          id: any
          module_id: any
          status: any
          slug: string
          title: string
          description?: string | null
          rich_description?: string | null
          cover_image_id?: number | null
          cover_logo_id?: number | null
          card_thumbnail_id?: number | null
          cover_theme?: string | null
          cover_author?: string | null
          expected_completion_time_minutes?: number | null
          want_page_titles: boolean
          mcq_settings_enabled: boolean
          mcq_settings_feedback?: string | null
          mcq_settings_labels: string
          mcq_settings_retry: any
          mcq_settings_retry_limit?: any | null
          mcq_settings_show_score: boolean
          mcq_settings_pass_mark_type: any
          mcq_settings_pass_mark?: number | null
          cme_expires_after_seconds?: number | null
          cme_expires_at?: any | null
          cme_credits_description?: string | null
          cme_reference?: string | null
          cme_accreditor?: string | null
          objectives?: any | null
          settings?: any | null
          contains_mcq?: boolean | null
          feedback_form_id?: any | null
          categories?: any | null
          created_at: any
          redirect_url?: string | null
          module: { __typename?: 'learning_modules'; slug?: string | null }
          cover_image?: {
            __typename?: 'learning_uploads'
            id: number
            path: string
          } | null
          cover_logo?: {
            __typename?: 'learning_uploads'
            id: number
            path: string
          } | null
          card_thumbnail?: {
            __typename?: 'learning_uploads'
            id: number
            path: string
          } | null
          pages: Array<{
            __typename?: 'learning_module_revision_pages'
            position: number
            page: {
              __typename?: 'learning_pages'
              id: string
              title?: string | null
              body: any
            }
          }>
          preview_page: Array<{
            __typename?: 'learning_module_revision_pages'
            page: {
              __typename?: 'learning_pages'
              id: string
              title?: string | null
              body: any
            }
          }>
        }
        responses: Array<{
          __typename?: 'learning_learner_session_feedback'
          question_id?: any | null
          feedback?: string | null
          answers?: any | null
        }>
      }>
      everCompleted: Array<{
        __typename?: 'learning_learner_sessions'
        finished_at?: any | null
      }>
      revision?: {
        __typename?: 'learning_module_revisions'
        id: any
        module_id: any
        status: any
        slug: string
        title: string
        description?: string | null
        rich_description?: string | null
        cover_image_id?: number | null
        cover_logo_id?: number | null
        card_thumbnail_id?: number | null
        cover_theme?: string | null
        cover_author?: string | null
        expected_completion_time_minutes?: number | null
        want_page_titles: boolean
        mcq_settings_enabled: boolean
        mcq_settings_feedback?: string | null
        mcq_settings_labels: string
        mcq_settings_retry: any
        mcq_settings_retry_limit?: any | null
        mcq_settings_show_score: boolean
        mcq_settings_pass_mark_type: any
        mcq_settings_pass_mark?: number | null
        cme_expires_after_seconds?: number | null
        cme_expires_at?: any | null
        cme_credits_description?: string | null
        cme_reference?: string | null
        cme_accreditor?: string | null
        objectives?: any | null
        settings?: any | null
        contains_mcq?: boolean | null
        feedback_form_id?: any | null
        categories?: any | null
        created_at: any
        redirect_url?: string | null
        module: { __typename?: 'learning_modules'; slug?: string | null }
        cover_image?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        cover_logo?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        card_thumbnail?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        pages: Array<{
          __typename?: 'learning_module_revision_pages'
          position: number
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
        preview_page: Array<{
          __typename?: 'learning_module_revision_pages'
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
      } | null
      author: { __typename?: 'users'; name: string }
      series: {
        __typename?: 'learning_series'
        id: any
        title: string
        slug: string
        series_type?: string | null
        library_type: string
        library_id: string
        sort_modules_by: any
        force_completion_order: boolean
        can_edit?: boolean | null
        payments_enabled: boolean
        has_subscription?: boolean | null
        accreditation_when: string
        certificate_template: string
        certificate_content?: any | null
        cme_expires_after_seconds?: number | null
        cme_expires_at?: any | null
        cme_credits_description?: string | null
        cme_reference?: string | null
        cme_accreditor?: string | null
        library?: {
          __typename?: 'groups'
          id: string
          slug: string
          title: string
          learning_subscriptions_enabled: boolean
        } | null
        subscription_settings?: {
          __typename?: 'learning_series_subscription_settings'
          module_price: any
          series_price: any
          available_as_module: boolean
          available_as_series: boolean
          subscription_interval?: string | null
          created_at: any
          created_by: number
          currency_code: string
          updated_at: any
          updated_by: number
        } | null
      }
      published_revision?: {
        __typename?: 'learning_module_revisions'
        id: any
      } | null
      active_subscription: Array<{
        __typename?: 'user_group_members'
        created_at: any
        expires_at?: any | null
      }>
    } | null
    revision: {
      __typename?: 'learning_module_revisions'
      id: any
      module_id: any
      status: any
      slug: string
      title: string
      description?: string | null
      rich_description?: string | null
      cover_image_id?: number | null
      cover_logo_id?: number | null
      card_thumbnail_id?: number | null
      cover_theme?: string | null
      cover_author?: string | null
      expected_completion_time_minutes?: number | null
      want_page_titles: boolean
      mcq_settings_enabled: boolean
      mcq_settings_feedback?: string | null
      mcq_settings_labels: string
      mcq_settings_retry: any
      mcq_settings_retry_limit?: any | null
      mcq_settings_show_score: boolean
      mcq_settings_pass_mark_type: any
      mcq_settings_pass_mark?: number | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      objectives?: any | null
      settings?: any | null
      contains_mcq?: boolean | null
      feedback_form_id?: any | null
      categories?: any | null
      created_at: any
      redirect_url?: string | null
      module: { __typename?: 'learning_modules'; slug?: string | null }
      cover_image?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      cover_logo?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      card_thumbnail?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      pages: Array<{
        __typename?: 'learning_module_revision_pages'
        position: number
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
      preview_page: Array<{
        __typename?: 'learning_module_revision_pages'
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
    }
  }>
  completed: Array<{
    __typename?: 'learning_learner_sessions'
    id: any
    active: boolean
    last_event_at: any
    finished_at?: any | null
    marks?: number | null
    marks_available?: number | null
    module?: {
      __typename?: 'learning_modules'
      id: any
      slug?: string | null
      template_type?: string | null
      series_id: any
      status: any
      author_id: number
      position?: number | null
      has_subscription?: boolean | null
      price?: any | null
      ever_completed?: boolean | null
      has_passed?: boolean | null
      failed_attempts?: number | null
      reached_max_attempts?: boolean | null
      can_edit?: boolean | null
      can_publish?: boolean | null
      can_approve?: boolean | null
      reflections: Array<{
        __typename?: 'learning_learner_reflections'
        body: string
      }>
      ratings: Array<{
        __typename?: 'learning_learner_module_ratings'
        responses_aggregate: {
          __typename?: 'learning_learner_module_rating_responses_aggregate'
          aggregate?: {
            __typename?: 'learning_learner_module_rating_responses_aggregate_fields'
            count: number
          } | null
        }
      }>
      latestSession: Array<{
        __typename?: 'learning_learner_sessions'
        last_event_at: any
        marks?: number | null
        marks_available?: number | null
        status?: string | null
        finished_at?: any | null
        id: any
        started_at: any
        active: boolean
        events: Array<{
          __typename?: 'learning_learner_event_log'
          event: any
          time: any
        }>
        revision: {
          __typename?: 'learning_module_revisions'
          id: any
          module_id: any
          status: any
          slug: string
          title: string
          description?: string | null
          rich_description?: string | null
          cover_image_id?: number | null
          cover_logo_id?: number | null
          card_thumbnail_id?: number | null
          cover_theme?: string | null
          cover_author?: string | null
          expected_completion_time_minutes?: number | null
          want_page_titles: boolean
          mcq_settings_enabled: boolean
          mcq_settings_feedback?: string | null
          mcq_settings_labels: string
          mcq_settings_retry: any
          mcq_settings_retry_limit?: any | null
          mcq_settings_show_score: boolean
          mcq_settings_pass_mark_type: any
          mcq_settings_pass_mark?: number | null
          cme_expires_after_seconds?: number | null
          cme_expires_at?: any | null
          cme_credits_description?: string | null
          cme_reference?: string | null
          cme_accreditor?: string | null
          objectives?: any | null
          settings?: any | null
          contains_mcq?: boolean | null
          feedback_form_id?: any | null
          categories?: any | null
          created_at: any
          redirect_url?: string | null
          module: { __typename?: 'learning_modules'; slug?: string | null }
          cover_image?: {
            __typename?: 'learning_uploads'
            id: number
            path: string
          } | null
          cover_logo?: {
            __typename?: 'learning_uploads'
            id: number
            path: string
          } | null
          card_thumbnail?: {
            __typename?: 'learning_uploads'
            id: number
            path: string
          } | null
          pages: Array<{
            __typename?: 'learning_module_revision_pages'
            position: number
            page: {
              __typename?: 'learning_pages'
              id: string
              title?: string | null
              body: any
            }
          }>
          preview_page: Array<{
            __typename?: 'learning_module_revision_pages'
            page: {
              __typename?: 'learning_pages'
              id: string
              title?: string | null
              body: any
            }
          }>
        }
        responses: Array<{
          __typename?: 'learning_learner_session_feedback'
          question_id?: any | null
          feedback?: string | null
          answers?: any | null
        }>
      }>
      everCompleted: Array<{
        __typename?: 'learning_learner_sessions'
        finished_at?: any | null
      }>
      revision?: {
        __typename?: 'learning_module_revisions'
        id: any
        module_id: any
        status: any
        slug: string
        title: string
        description?: string | null
        rich_description?: string | null
        cover_image_id?: number | null
        cover_logo_id?: number | null
        card_thumbnail_id?: number | null
        cover_theme?: string | null
        cover_author?: string | null
        expected_completion_time_minutes?: number | null
        want_page_titles: boolean
        mcq_settings_enabled: boolean
        mcq_settings_feedback?: string | null
        mcq_settings_labels: string
        mcq_settings_retry: any
        mcq_settings_retry_limit?: any | null
        mcq_settings_show_score: boolean
        mcq_settings_pass_mark_type: any
        mcq_settings_pass_mark?: number | null
        cme_expires_after_seconds?: number | null
        cme_expires_at?: any | null
        cme_credits_description?: string | null
        cme_reference?: string | null
        cme_accreditor?: string | null
        objectives?: any | null
        settings?: any | null
        contains_mcq?: boolean | null
        feedback_form_id?: any | null
        categories?: any | null
        created_at: any
        redirect_url?: string | null
        module: { __typename?: 'learning_modules'; slug?: string | null }
        cover_image?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        cover_logo?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        card_thumbnail?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        pages: Array<{
          __typename?: 'learning_module_revision_pages'
          position: number
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
        preview_page: Array<{
          __typename?: 'learning_module_revision_pages'
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
      } | null
      author: { __typename?: 'users'; name: string }
      series: {
        __typename?: 'learning_series'
        id: any
        title: string
        slug: string
        series_type?: string | null
        library_type: string
        library_id: string
        sort_modules_by: any
        force_completion_order: boolean
        can_edit?: boolean | null
        payments_enabled: boolean
        has_subscription?: boolean | null
        accreditation_when: string
        certificate_template: string
        certificate_content?: any | null
        cme_expires_after_seconds?: number | null
        cme_expires_at?: any | null
        cme_credits_description?: string | null
        cme_reference?: string | null
        cme_accreditor?: string | null
        library?: {
          __typename?: 'groups'
          id: string
          slug: string
          title: string
          learning_subscriptions_enabled: boolean
        } | null
        subscription_settings?: {
          __typename?: 'learning_series_subscription_settings'
          module_price: any
          series_price: any
          available_as_module: boolean
          available_as_series: boolean
          subscription_interval?: string | null
          created_at: any
          created_by: number
          currency_code: string
          updated_at: any
          updated_by: number
        } | null
      }
      published_revision?: {
        __typename?: 'learning_module_revisions'
        id: any
      } | null
      active_subscription: Array<{
        __typename?: 'user_group_members'
        created_at: any
        expires_at?: any | null
      }>
    } | null
    revision: {
      __typename?: 'learning_module_revisions'
      id: any
      module_id: any
      status: any
      slug: string
      title: string
      description?: string | null
      rich_description?: string | null
      cover_image_id?: number | null
      cover_logo_id?: number | null
      card_thumbnail_id?: number | null
      cover_theme?: string | null
      cover_author?: string | null
      expected_completion_time_minutes?: number | null
      want_page_titles: boolean
      mcq_settings_enabled: boolean
      mcq_settings_feedback?: string | null
      mcq_settings_labels: string
      mcq_settings_retry: any
      mcq_settings_retry_limit?: any | null
      mcq_settings_show_score: boolean
      mcq_settings_pass_mark_type: any
      mcq_settings_pass_mark?: number | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      objectives?: any | null
      settings?: any | null
      contains_mcq?: boolean | null
      feedback_form_id?: any | null
      categories?: any | null
      created_at: any
      redirect_url?: string | null
      module: { __typename?: 'learning_modules'; slug?: string | null }
      cover_image?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      cover_logo?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      card_thumbnail?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      pages: Array<{
        __typename?: 'learning_module_revision_pages'
        position: number
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
      preview_page: Array<{
        __typename?: 'learning_module_revision_pages'
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
    }
  }>
  subscribed: Array<{
    __typename?: 'learning_modules'
    id: any
    slug?: string | null
    template_type?: string | null
    series_id: any
    status: any
    author_id: number
    position?: number | null
    has_subscription?: boolean | null
    price?: any | null
    ever_completed?: boolean | null
    has_passed?: boolean | null
    failed_attempts?: number | null
    reached_max_attempts?: boolean | null
    can_edit?: boolean | null
    can_publish?: boolean | null
    can_approve?: boolean | null
    reflections: Array<{
      __typename?: 'learning_learner_reflections'
      body: string
    }>
    ratings: Array<{
      __typename?: 'learning_learner_module_ratings'
      responses_aggregate: {
        __typename?: 'learning_learner_module_rating_responses_aggregate'
        aggregate?: {
          __typename?: 'learning_learner_module_rating_responses_aggregate_fields'
          count: number
        } | null
      }
    }>
    latestSession: Array<{
      __typename?: 'learning_learner_sessions'
      last_event_at: any
      marks?: number | null
      marks_available?: number | null
      status?: string | null
      finished_at?: any | null
      id: any
      started_at: any
      active: boolean
      events: Array<{
        __typename?: 'learning_learner_event_log'
        event: any
        time: any
      }>
      revision: {
        __typename?: 'learning_module_revisions'
        id: any
        module_id: any
        status: any
        slug: string
        title: string
        description?: string | null
        rich_description?: string | null
        cover_image_id?: number | null
        cover_logo_id?: number | null
        card_thumbnail_id?: number | null
        cover_theme?: string | null
        cover_author?: string | null
        expected_completion_time_minutes?: number | null
        want_page_titles: boolean
        mcq_settings_enabled: boolean
        mcq_settings_feedback?: string | null
        mcq_settings_labels: string
        mcq_settings_retry: any
        mcq_settings_retry_limit?: any | null
        mcq_settings_show_score: boolean
        mcq_settings_pass_mark_type: any
        mcq_settings_pass_mark?: number | null
        cme_expires_after_seconds?: number | null
        cme_expires_at?: any | null
        cme_credits_description?: string | null
        cme_reference?: string | null
        cme_accreditor?: string | null
        objectives?: any | null
        settings?: any | null
        contains_mcq?: boolean | null
        feedback_form_id?: any | null
        categories?: any | null
        created_at: any
        redirect_url?: string | null
        module: { __typename?: 'learning_modules'; slug?: string | null }
        cover_image?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        cover_logo?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        card_thumbnail?: {
          __typename?: 'learning_uploads'
          id: number
          path: string
        } | null
        pages: Array<{
          __typename?: 'learning_module_revision_pages'
          position: number
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
        preview_page: Array<{
          __typename?: 'learning_module_revision_pages'
          page: {
            __typename?: 'learning_pages'
            id: string
            title?: string | null
            body: any
          }
        }>
      }
      responses: Array<{
        __typename?: 'learning_learner_session_feedback'
        question_id?: any | null
        feedback?: string | null
        answers?: any | null
      }>
    }>
    everCompleted: Array<{
      __typename?: 'learning_learner_sessions'
      finished_at?: any | null
    }>
    revision?: {
      __typename?: 'learning_module_revisions'
      id: any
      module_id: any
      status: any
      slug: string
      title: string
      description?: string | null
      rich_description?: string | null
      cover_image_id?: number | null
      cover_logo_id?: number | null
      card_thumbnail_id?: number | null
      cover_theme?: string | null
      cover_author?: string | null
      expected_completion_time_minutes?: number | null
      want_page_titles: boolean
      mcq_settings_enabled: boolean
      mcq_settings_feedback?: string | null
      mcq_settings_labels: string
      mcq_settings_retry: any
      mcq_settings_retry_limit?: any | null
      mcq_settings_show_score: boolean
      mcq_settings_pass_mark_type: any
      mcq_settings_pass_mark?: number | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      objectives?: any | null
      settings?: any | null
      contains_mcq?: boolean | null
      feedback_form_id?: any | null
      categories?: any | null
      created_at: any
      redirect_url?: string | null
      module: { __typename?: 'learning_modules'; slug?: string | null }
      cover_image?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      cover_logo?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      card_thumbnail?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      pages: Array<{
        __typename?: 'learning_module_revision_pages'
        position: number
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
      preview_page: Array<{
        __typename?: 'learning_module_revision_pages'
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
    } | null
    author: { __typename?: 'users'; name: string }
    series: {
      __typename?: 'learning_series'
      id: any
      title: string
      slug: string
      series_type?: string | null
      library_type: string
      library_id: string
      sort_modules_by: any
      force_completion_order: boolean
      can_edit?: boolean | null
      payments_enabled: boolean
      has_subscription?: boolean | null
      accreditation_when: string
      certificate_template: string
      certificate_content?: any | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      library?: {
        __typename?: 'groups'
        id: string
        slug: string
        title: string
        learning_subscriptions_enabled: boolean
      } | null
      subscription_settings?: {
        __typename?: 'learning_series_subscription_settings'
        module_price: any
        series_price: any
        available_as_module: boolean
        available_as_series: boolean
        subscription_interval?: string | null
        created_at: any
        created_by: number
        currency_code: string
        updated_at: any
        updated_by: number
      } | null
    }
    published_revision?: {
      __typename?: 'learning_module_revisions'
      id: any
    } | null
    active_subscription: Array<{
      __typename?: 'user_group_members'
      created_at: any
      expires_at?: any | null
    }>
  }>
  subscribedSeries: Array<{
    __typename?: 'learning_series'
    id: any
    series_type?: string | null
    library_type: string
    library_id: string
    slug: string
    title: string
    display_author?: string | null
    description?: string | null
    rich_description?: string | null
    sort_modules_by: any
    cme_accreditor?: string | null
    payments_enabled: boolean
    has_subscription?: boolean | null
    force_completion_order: boolean
    accreditation_when: string
    library?: {
      __typename?: 'groups'
      id: string
      slug: string
      title: string
      learning_subscriptions_enabled: boolean
    } | null
    image?: { __typename?: 'learning_uploads'; id: number; path: string } | null
    star_rating?: {
      __typename?: 'learning_series_star_ratings'
      rating?: any | null
    } | null
    editor?: { __typename?: 'users'; id: number; name: string } | null
    subscription_settings?: {
      __typename?: 'learning_series_subscription_settings'
      module_price: any
      series_price: any
      available_as_module: boolean
      available_as_series: boolean
      subscription_interval?: string | null
      created_at: any
      created_by: number
      currency_code: string
      updated_at: any
      updated_by: number
    } | null
    module_count: {
      __typename?: 'learning_modules_aggregate'
      aggregate?: {
        __typename?: 'learning_modules_aggregate_fields'
        count: number
      } | null
    }
    completed_count: {
      __typename?: 'learning_modules_aggregate'
      aggregate?: {
        __typename?: 'learning_modules_aggregate_fields'
        count: number
      } | null
    }
    active_subscription: Array<{
      __typename?: 'user_group_members'
      created_at: any
      expires_at?: any | null
    }>
  }>
}

export type LastCompletedLearnerSessionQueryVariables = Exact<{
  moduleID: Scalars['uuid']
  actorID: Scalars['Int']
}>

export type LastCompletedLearnerSessionQuery = {
  __typename?: 'query_root'
  learning_learner_sessions: Array<{
    __typename?: 'learning_learner_sessions'
    id: any
    finished_at?: any | null
    active: boolean
    marks?: number | null
    marks_available?: number | null
    status?: string | null
    revision: {
      __typename?: 'learning_module_revisions'
      id: any
      module_id: any
      status: any
      slug: string
      title: string
      description?: string | null
      rich_description?: string | null
      cover_image_id?: number | null
      cover_logo_id?: number | null
      card_thumbnail_id?: number | null
      cover_theme?: string | null
      cover_author?: string | null
      expected_completion_time_minutes?: number | null
      want_page_titles: boolean
      mcq_settings_enabled: boolean
      mcq_settings_feedback?: string | null
      mcq_settings_labels: string
      mcq_settings_retry: any
      mcq_settings_retry_limit?: any | null
      mcq_settings_show_score: boolean
      mcq_settings_pass_mark_type: any
      mcq_settings_pass_mark?: number | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      objectives?: any | null
      settings?: any | null
      contains_mcq?: boolean | null
      feedback_form_id?: any | null
      categories?: any | null
      created_at: any
      redirect_url?: string | null
      module: { __typename?: 'learning_modules'; slug?: string | null }
      cover_image?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      cover_logo?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      card_thumbnail?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      pages: Array<{
        __typename?: 'learning_module_revision_pages'
        position: number
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
      preview_page: Array<{
        __typename?: 'learning_module_revision_pages'
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
    }
    responses: Array<{
      __typename?: 'learning_learner_session_feedback'
      answers?: any | null
      feedback?: string | null
      module_id?: any | null
      question_id?: any | null
    }>
  }>
}

export type LastCompletedLearnerSessionForSeriesQueryVariables = Exact<{
  seriesID: Scalars['uuid']
  actorID: Scalars['Int']
}>

export type LastCompletedLearnerSessionForSeriesQuery = {
  __typename?: 'query_root'
  learning_learner_sessions: Array<{
    __typename?: 'learning_learner_sessions'
    id: any
    finished_at?: any | null
    active: boolean
    marks?: number | null
    marks_available?: number | null
    status?: string | null
    revision: {
      __typename?: 'learning_module_revisions'
      id: any
      module_id: any
      status: any
      slug: string
      title: string
      description?: string | null
      rich_description?: string | null
      cover_image_id?: number | null
      cover_logo_id?: number | null
      card_thumbnail_id?: number | null
      cover_theme?: string | null
      cover_author?: string | null
      expected_completion_time_minutes?: number | null
      want_page_titles: boolean
      mcq_settings_enabled: boolean
      mcq_settings_feedback?: string | null
      mcq_settings_labels: string
      mcq_settings_retry: any
      mcq_settings_retry_limit?: any | null
      mcq_settings_show_score: boolean
      mcq_settings_pass_mark_type: any
      mcq_settings_pass_mark?: number | null
      cme_expires_after_seconds?: number | null
      cme_expires_at?: any | null
      cme_credits_description?: string | null
      cme_reference?: string | null
      cme_accreditor?: string | null
      objectives?: any | null
      settings?: any | null
      contains_mcq?: boolean | null
      feedback_form_id?: any | null
      categories?: any | null
      created_at: any
      redirect_url?: string | null
      module: { __typename?: 'learning_modules'; slug?: string | null }
      cover_image?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      cover_logo?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      card_thumbnail?: {
        __typename?: 'learning_uploads'
        id: number
        path: string
      } | null
      pages: Array<{
        __typename?: 'learning_module_revision_pages'
        position: number
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
      preview_page: Array<{
        __typename?: 'learning_module_revision_pages'
        page: {
          __typename?: 'learning_pages'
          id: string
          title?: string | null
          body: any
        }
      }>
    }
    responses: Array<{
      __typename?: 'learning_learner_session_feedback'
      answers?: any | null
      feedback?: string | null
      module_id?: any | null
      question_id?: any | null
    }>
  }>
}

export type LearnerModuleReflectionsQueryVariables = Exact<{
  moduleID: Scalars['uuid']
  actorID: Scalars['Int']
}>

export type LearnerModuleReflectionsQuery = {
  __typename?: 'query_root'
  reflections?: {
    __typename?: 'learning_learner_reflections'
    body: string
  } | null
}

export type UpdateLearnerModuleReflectionsMutationVariables = Exact<{
  moduleID: Scalars['uuid']
  actorID: Scalars['Int']
  body: Scalars['String']
}>

export type UpdateLearnerModuleReflectionsMutation = {
  __typename?: 'mutation_root'
  reflections?: {
    __typename?: 'learning_learner_reflections'
    body: string
  } | null
}

export type LearnerRatingQuestionsFragment = {
  __typename?: 'learning_learner_module_rating_questions'
  id: any
  position: number
  question_text: string
  answer_type: string
  series_id?: any | null
  module_id?: any | null
}

export type LearnerModuleRatingQuestionsQueryVariables = Exact<{
  moduleID: Scalars['uuid']
  seriesID: Scalars['uuid']
}>

export type LearnerModuleRatingQuestionsQuery = {
  __typename?: 'query_root'
  module_questions: Array<{
    __typename?: 'learning_learner_module_rating_questions'
    id: any
    position: number
    question_text: string
    answer_type: string
    series_id?: any | null
    module_id?: any | null
  }>
  series_questions: Array<{
    __typename?: 'learning_learner_module_rating_questions'
    id: any
    position: number
    question_text: string
    answer_type: string
    series_id?: any | null
    module_id?: any | null
  }>
  standard_questions: Array<{
    __typename?: 'learning_learner_module_rating_questions'
    id: any
    position: number
    question_text: string
    answer_type: string
    series_id?: any | null
    module_id?: any | null
  }>
}

export type LearnerModuleRatingResponsesQueryVariables = Exact<{
  userID: Scalars['Int']
  moduleID: Scalars['uuid']
  sessionID: Scalars['uuid']
}>

export type LearnerModuleRatingResponsesQuery = {
  __typename?: 'query_root'
  learning_learner_module_rating_responses: Array<{
    __typename?: 'learning_learner_module_rating_responses'
    id: any
    question_id: any
    response_text?: string | null
    response?: number | null
  }>
}

export type UpdateLearnerModuleRatingsMutationVariables = Exact<{
  sessionID: Scalars['uuid']
  moduleID: Scalars['uuid']
  actorID: Scalars['Int']
  responses:
    | Array<Learning_Learner_Module_Rating_Responses_Insert_Input>
    | Learning_Learner_Module_Rating_Responses_Insert_Input
}>

export type UpdateLearnerModuleRatingsMutation = {
  __typename?: 'mutation_root'
  rating?: { __typename?: 'learning_learner_module_ratings'; id: any } | null
}

export type UpdateLearnerModuleStarRatingMutationVariables = Exact<{
  moduleID: Scalars['uuid']
  rating: Scalars['Int']
}>

export type UpdateLearnerModuleStarRatingMutation = {
  __typename?: 'mutation_root'
  rating?: {
    __typename?: 'learning_learner_module_star_ratings'
    id: any
    rating: number
  } | null
}

export type ModulesListForSeriesCompletionQueryVariables = Exact<{
  seriesID: Scalars['uuid']
}>

export type ModulesListForSeriesCompletionQuery = {
  __typename?: 'query_root'
  modules: Array<{
    __typename?: 'learning_modules'
    id: any
    slug?: string | null
    position?: number | null
    ever_completed?: boolean | null
    has_passed?: boolean | null
    series: {
      __typename?: 'learning_series'
      slug: string
      library?: { __typename?: 'groups'; slug: string } | null
    }
    published_revision?: {
      __typename?: 'learning_module_revisions'
      title: string
    } | null
  }>
}

export type ModulesListForSeriesPassQueryVariables = Exact<{
  seriesID: Scalars['uuid']
}>

export type ModulesListForSeriesPassQuery = {
  __typename?: 'query_root'
  modules: Array<{
    __typename?: 'learning_modules'
    id: any
    position?: number | null
    has_passed?: boolean | null
  }>
}

export type AuthorsForFilterQueryVariables = Exact<{ [key: string]: never }>

export type AuthorsForFilterQuery = {
  __typename?: 'query_root'
  modules: Array<{
    __typename?: 'learning_modules'
    published_revision?: {
      __typename?: 'learning_module_revisions'
      cover_author?: string | null
    } | null
  }>
  series: Array<{
    __typename?: 'learning_series'
    display_author?: string | null
  }>
}

export type PageBySlugFragment = {
  __typename?: 'pages'
  id: number
  slug: string
  title: string
  body: any
  published_at?: any | null
  publisher?: { __typename?: 'users'; id: number; name: string } | null
  categories: Array<{
    __typename?: 'page_categories'
    category: {
      __typename?: 'categories'
      domain: string
      name: string
      slug: string
    }
  }>
}

export type PagesBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type PagesBySlugQuery = {
  __typename?: 'query_root'
  results: Array<{
    __typename?: 'pages'
    id: number
    slug: string
    title: string
    body: any
    published_at?: any | null
    publisher?: { __typename?: 'users'; id: number; name: string } | null
    categories: Array<{
      __typename?: 'page_categories'
      category: {
        __typename?: 'categories'
        domain: string
        name: string
        slug: string
      }
    }>
  }>
}

export type PublicPostImageFragment = {
  __typename?: 'resources'
  id: number
  file: string
  name: string
  path: string
  alt_text?: string | null
}

export type PublicPostCategoryFragment = {
  __typename?: 'categories'
  id: number
  name: string
  slug: string
  domain: string
}

export type PublicPostEntryFragment = {
  __typename?: 'posts'
  id: number
  slug: string
  title: string
  date?: any | null
  excerpt?: string | null
  body: any
  published: boolean
  published_at?: any | null
  image?: {
    __typename?: 'resources'
    id: number
    file: string
    name: string
    path: string
    alt_text?: string | null
  } | null
  publisher?: { __typename?: 'users'; id: number; name: string } | null
  categories: Array<{
    __typename?: 'post_categories'
    category: {
      __typename?: 'categories'
      id: number
      name: string
      slug: string
      domain: string
    }
  }>
}

export type PublicPostsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
}>

export type PublicPostsQuery = {
  __typename?: 'query_root'
  count: {
    __typename?: 'posts_aggregate'
    aggregate?: { __typename?: 'posts_aggregate_fields'; count: number } | null
  }
  posts: Array<{
    __typename?: 'posts'
    id: number
    slug: string
    title: string
    date?: any | null
    excerpt?: string | null
    body: any
    published: boolean
    published_at?: any | null
    image?: {
      __typename?: 'resources'
      id: number
      file: string
      name: string
      path: string
      alt_text?: string | null
    } | null
    publisher?: { __typename?: 'users'; id: number; name: string } | null
    categories: Array<{
      __typename?: 'post_categories'
      category: {
        __typename?: 'categories'
        id: number
        name: string
        slug: string
        domain: string
      }
    }>
  }>
}

export type PublicPostsWithCategoryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  categorySlug: Scalars['String']
  categoryType: Scalars['String']
}>

export type PublicPostsWithCategoryQuery = {
  __typename?: 'query_root'
  categories: Array<{
    __typename?: 'categories'
    id: number
    name: string
    slug: string
    domain: string
  }>
  posts: Array<{
    __typename?: 'posts'
    id: number
    slug: string
    title: string
    date?: any | null
    excerpt?: string | null
    body: any
    published: boolean
    published_at?: any | null
    image?: {
      __typename?: 'resources'
      id: number
      file: string
      name: string
      path: string
      alt_text?: string | null
    } | null
    publisher?: { __typename?: 'users'; id: number; name: string } | null
    categories: Array<{
      __typename?: 'post_categories'
      category: {
        __typename?: 'categories'
        id: number
        name: string
        slug: string
        domain: string
      }
    }>
  }>
}

export type PostResponseFragment = {
  __typename?: 'posts'
  id: number
  slug: string
  excerpt?: string | null
  title: string
  date?: any | null
  body: any
  allow_comments?: boolean | null
  published: boolean
  published_at?: any | null
  show_feature_image: boolean
  image?: {
    __typename?: 'resources'
    id: number
    file: string
    name: string
    path: string
    alt_text?: string | null
  } | null
  publisher?: { __typename?: 'users'; id: number; name: string } | null
  categories: Array<{
    __typename?: 'post_categories'
    category: {
      __typename?: 'categories'
      domain: string
      name: string
      slug: string
      posts: Array<{
        __typename?: 'post_categories'
        post: {
          __typename?: 'posts'
          id: number
          slug: string
          title: string
          image?: {
            __typename?: 'resources'
            id: number
            file: string
            name: string
            path: string
            alt_text?: string | null
          } | null
        }
      }>
    }
  }>
  comments: Array<{
    __typename?: 'post_comments'
    id: number
    body: string
    created_at: any
    creator: { __typename?: 'users'; id: number; name: string }
  }>
}

export type PostQueryVariables = Exact<{
  id: Scalars['Int']
}>

export type PostQuery = {
  __typename?: 'query_root'
  result?: {
    __typename?: 'posts'
    id: number
    slug: string
    excerpt?: string | null
    title: string
    date?: any | null
    body: any
    allow_comments?: boolean | null
    published: boolean
    published_at?: any | null
    show_feature_image: boolean
    image?: {
      __typename?: 'resources'
      id: number
      file: string
      name: string
      path: string
      alt_text?: string | null
    } | null
    publisher?: { __typename?: 'users'; id: number; name: string } | null
    categories: Array<{
      __typename?: 'post_categories'
      category: {
        __typename?: 'categories'
        domain: string
        name: string
        slug: string
        posts: Array<{
          __typename?: 'post_categories'
          post: {
            __typename?: 'posts'
            id: number
            slug: string
            title: string
            image?: {
              __typename?: 'resources'
              id: number
              file: string
              name: string
              path: string
              alt_text?: string | null
            } | null
          }
        }>
      }
    }>
    comments: Array<{
      __typename?: 'post_comments'
      id: number
      body: string
      created_at: any
      creator: { __typename?: 'users'; id: number; name: string }
    }>
  } | null
}

export type PostsBySlugQueryVariables = Exact<{
  slug: Scalars['String']
}>

export type PostsBySlugQuery = {
  __typename?: 'query_root'
  results: Array<{
    __typename?: 'posts'
    id: number
    slug: string
    excerpt?: string | null
    title: string
    date?: any | null
    body: any
    allow_comments?: boolean | null
    published: boolean
    published_at?: any | null
    show_feature_image: boolean
    image?: {
      __typename?: 'resources'
      id: number
      file: string
      name: string
      path: string
      alt_text?: string | null
    } | null
    publisher?: { __typename?: 'users'; id: number; name: string } | null
    categories: Array<{
      __typename?: 'post_categories'
      category: {
        __typename?: 'categories'
        domain: string
        name: string
        slug: string
        posts: Array<{
          __typename?: 'post_categories'
          post: {
            __typename?: 'posts'
            id: number
            slug: string
            title: string
            image?: {
              __typename?: 'resources'
              id: number
              file: string
              name: string
              path: string
              alt_text?: string | null
            } | null
          }
        }>
      }
    }>
    comments: Array<{
      __typename?: 'post_comments'
      id: number
      body: string
      created_at: any
      creator: { __typename?: 'users'; id: number; name: string }
    }>
  }>
}

export type AddPostCommentMutationVariables = Exact<{
  user_values: Users_Insert_Input
  values: Post_Comments_Insert_Input
}>

export type AddPostCommentMutation = {
  __typename?: 'mutation_root'
  insert_users?: {
    __typename?: 'users_mutation_response'
    returning: Array<{
      __typename?: 'users'
      id: number
      name: string
      first_seen_at: any
      last_seen_at?: any | null
    }>
  } | null
  insert_post_comments?: {
    __typename?: 'post_comments_mutation_response'
    returning: Array<{
      __typename?: 'post_comments'
      id: number
      body: string
      created_at: any
      post: {
        __typename?: 'posts'
        id: number
        slug: string
        excerpt?: string | null
        title: string
        date?: any | null
        body: any
        allow_comments?: boolean | null
        published: boolean
        published_at?: any | null
        show_feature_image: boolean
        image?: {
          __typename?: 'resources'
          id: number
          file: string
          name: string
          path: string
          alt_text?: string | null
        } | null
        publisher?: { __typename?: 'users'; id: number; name: string } | null
        categories: Array<{
          __typename?: 'post_categories'
          category: {
            __typename?: 'categories'
            domain: string
            name: string
            slug: string
            posts: Array<{
              __typename?: 'post_categories'
              post: {
                __typename?: 'posts'
                id: number
                slug: string
                title: string
                image?: {
                  __typename?: 'resources'
                  id: number
                  file: string
                  name: string
                  path: string
                  alt_text?: string | null
                } | null
              }
            }>
          }
        }>
        comments: Array<{
          __typename?: 'post_comments'
          id: number
          body: string
          created_at: any
          creator: { __typename?: 'users'; id: number; name: string }
        }>
      }
    }>
  } | null
}

export const FormFieldsFragmentDoc = gql`
  fragment FormFields on forms {
    id
    creator_id
    name
    description
    fields
    is_feedback_form
    allow_multiple_submissions
    group_id
  }
`
export const FormResponseFieldsFragmentDoc = gql`
  fragment FormResponseFields on form_responses {
    id
    form_id
    actor_id
    session_id
    response
  }
`
export const SeriesDiscountSettingsFragmentDoc = gql`
  fragment SeriesDiscountSettings on learning_series_discount_settings {
    created_at
    created_by
    discount_percentage
    discount_type
    discount_groups {
      group_id
    }
    series_id
    updated_by
    updated_at
  }
`
export const SeriesSubscriptionSettingsFragmentDoc = gql`
  fragment SeriesSubscriptionSettings on learning_series_subscription_settings {
    module_price
    series_price
    available_as_module
    available_as_series
    subscription_interval
    created_at
    created_by
    currency_code
    updated_at
    updated_by
  }
`
export const ModuleMetaResponseFragmentDoc = gql`
  fragment ModuleMetaResponse on learning_modules {
    id
    slug
    template_type
    series_id
    status
    author_id
    position
    has_subscription
    price
    ever_completed
    has_passed
    failed_attempts
    reached_max_attempts
    author {
      name
    }
    can_edit
    can_publish
    can_approve
    series {
      id
      title
      slug
      series_type
      library_type
      library_id
      library {
        id
        slug
        title
        learning_subscriptions_enabled
      }
      sort_modules_by
      force_completion_order
      can_edit
      payments_enabled
      has_subscription
      accreditation_when
      certificate_template
      certificate_content
      cme_expires_after_seconds
      cme_expires_at
      cme_credits_description
      cme_reference
      cme_accreditor
      subscription_settings {
        ...SeriesSubscriptionSettings
      }
    }
    published_revision {
      id
    }
    active_subscription: subscribers {
      created_at
      expires_at
    }
  }
  ${SeriesSubscriptionSettingsFragmentDoc}
`
export const PageWithPositionFragmentDoc = gql`
  fragment PageWithPosition on learning_module_revision_pages {
    page {
      id
      title
      body
    }
    position
  }
`
export const ModuleRevisionResponseFragmentDoc = gql`
  fragment ModuleRevisionResponse on learning_module_revisions {
    id
    module_id
    module {
      slug
    }
    status
    slug
    title
    description
    rich_description
    cover_image_id
    cover_image {
      id
      path
    }
    cover_logo_id
    cover_logo {
      id
      path
    }
    card_thumbnail_id
    card_thumbnail {
      id
      path
    }
    cover_theme
    cover_author
    expected_completion_time_minutes
    want_page_titles
    mcq_settings_enabled
    mcq_settings_feedback
    mcq_settings_labels
    mcq_settings_retry
    mcq_settings_retry_limit
    mcq_settings_show_score
    mcq_settings_pass_mark_type
    mcq_settings_pass_mark
    cme_expires_after_seconds
    cme_expires_at
    cme_credits_description
    cme_reference
    cme_accreditor
    objectives
    settings
    contains_mcq
    feedback_form_id
    pages(order_by: { position: asc }, where: { is_preview: { _eq: false } }) {
      ...PageWithPosition
    }
    preview_page: pages(where: { is_preview: { _eq: true } }) {
      page {
        id
        title
        body
      }
    }
    categories
    created_at
    redirect_url
  }
  ${PageWithPositionFragmentDoc}
`
export const ModulePreviewResponseFragmentDoc = gql`
  fragment ModulePreviewResponse on learning_modules {
    ...ModuleMetaResponse
    revision: latest_revision {
      ...ModuleRevisionResponse
    }
  }
  ${ModuleMetaResponseFragmentDoc}
  ${ModuleRevisionResponseFragmentDoc}
`
export const SeriesResponseFragmentDoc = gql`
  fragment SeriesResponse on learning_series {
    id
    series_type
    library_type
    library_id
    library {
      id
      slug
      title
      learning_subscriptions_enabled
    }
    slug
    title
    display_author
    description
    rich_description
    image {
      id
      path
    }
    star_rating {
      rating
    }
    editor {
      id
      name
    }
    sort_modules_by
    cme_accreditor
    payments_enabled
    has_subscription
    force_completion_order
    subscription_settings {
      ...SeriesSubscriptionSettings
    }
    accreditation_when
    module_count: modules_aggregate {
      aggregate {
        count
      }
    }
    completed_count: modules_aggregate {
      aggregate {
        count
      }
    }
    active_subscription: subscribers {
      created_at
      expires_at
    }
  }
  ${SeriesSubscriptionSettingsFragmentDoc}
`
export const ModulePublishedResponseFragmentDoc = gql`
  fragment ModulePublishedResponse on learning_modules {
    ...ModuleMetaResponse
    revision: published_revision {
      ...ModuleRevisionResponse
    }
  }
  ${ModuleMetaResponseFragmentDoc}
  ${ModuleRevisionResponseFragmentDoc}
`
export const SeriesWithModulesFragmentDoc = gql`
  fragment SeriesWithModules on learning_series {
    ...SeriesResponse
    modules(
      where: { status: { _in: ["draft", "published"] } }
      order_by: {
        published_revision: { title: asc }
        latest_revision: { title: asc }
      }
    ) {
      ...ModulePublishedResponse
    }
  }
  ${SeriesResponseFragmentDoc}
  ${ModulePublishedResponseFragmentDoc}
`
export const ActiveSessionWithResponsesFragmentDoc = gql`
  fragment ActiveSessionWithResponses on learning_learner_sessions {
    id
    started_at
    marks
    marks_available
    active
    revision {
      ...ModuleRevisionResponse
    }
    responses {
      question_id
      feedback
      answers
    }
  }
  ${ModuleRevisionResponseFragmentDoc}
`
export const FinishedSessionWithResponsesFragmentDoc = gql`
  fragment FinishedSessionWithResponses on learning_learner_sessions {
    ...ActiveSessionWithResponses
    status
    finished_at
  }
  ${ActiveSessionWithResponsesFragmentDoc}
`
export const ModuleResponseAndCompletionFragmentDoc = gql`
  fragment ModuleResponseAndCompletion on learning_modules {
    ...ModulePublishedResponse
    reflections(where: { user_id: { _eq: $userID } }, limit: 1) {
      body
    }
    ratings(where: { user_id: { _eq: $userID } }) {
      responses_aggregate {
        aggregate {
          count
        }
      }
    }
    latestSession: sessions(
      where: {
        actor_id: { _eq: $userID }
        _or: [
          { events: { event: { _eq: "finish" } } }
          { active: { _eq: true } }
        ]
      }
      order_by: { module_id: asc, last_event_at: desc }
      distinct_on: module_id
    ) {
      ...FinishedSessionWithResponses
      events {
        event
        time
      }
      last_event_at
      marks
      marks_available
    }
    everCompleted: sessions(
      where: {
        actor_id: { _eq: $userID }
        events: { event: { _eq: "finish" } }
        active: { _eq: false }
      }
      order_by: { module_id: asc, last_event_at: desc }
      distinct_on: module_id
    ) {
      finished_at
    }
  }
  ${ModulePublishedResponseFragmentDoc}
  ${FinishedSessionWithResponsesFragmentDoc}
`
export const SeriesWithModulesAndCompletionFragmentDoc = gql`
  fragment SeriesWithModulesAndCompletion on learning_series {
    ...SeriesResponse
    modules(
      where: { status: { _in: ["draft", "published"] } }
      order_by: {
        published_revision: { title: asc }
        latest_revision: { title: asc }
      }
    ) {
      ...ModuleResponseAndCompletion
    }
  }
  ${SeriesResponseFragmentDoc}
  ${ModuleResponseAndCompletionFragmentDoc}
`
export const LearnerRatingQuestionsFragmentDoc = gql`
  fragment LearnerRatingQuestions on learning_learner_module_rating_questions {
    id
    position
    question_text
    answer_type
    series_id
    module_id
  }
`
export const PageBySlugFragmentDoc = gql`
  fragment PageBySlug on pages {
    id
    slug
    title
    body
    published_at
    publisher {
      id
      name
    }
    categories {
      category {
        domain
        name
        slug
      }
    }
  }
`
export const PublicPostImageFragmentDoc = gql`
  fragment PublicPostImage on resources {
    id
    file
    name
    path
    alt_text
  }
`
export const PublicPostCategoryFragmentDoc = gql`
  fragment PublicPostCategory on categories {
    id
    name
    slug
    domain
  }
`
export const PublicPostEntryFragmentDoc = gql`
  fragment PublicPostEntry on posts {
    id
    slug
    title
    date
    excerpt
    body
    image {
      ...PublicPostImage
    }
    published
    published_at
    publisher {
      id
      name
    }
    categories {
      category {
        ...PublicPostCategory
      }
    }
  }
  ${PublicPostImageFragmentDoc}
  ${PublicPostCategoryFragmentDoc}
`
export const PostResponseFragmentDoc = gql`
  fragment PostResponse on posts {
    id
    slug
    excerpt
    image {
      ...PublicPostImage
    }
    title
    date
    body
    allow_comments
    published
    published_at
    show_feature_image
    publisher {
      id
      name
    }
    categories {
      category {
        domain
        name
        slug
        posts(limit: 4, order_by: { post: { published_at: desc } }) {
          post {
            id
            slug
            title
            image {
              ...PublicPostImage
            }
          }
        }
      }
    }
    comments(order_by: { created_at: asc }) {
      id
      body
      created_at
      creator {
        id
        name
      }
    }
  }
  ${PublicPostImageFragmentDoc}
`
export const UpsertFormResponseDocument = gql`
  mutation UpsertFormResponse($response: form_responses_insert_input!) {
    insert_form_responses_one(
      object: $response
      on_conflict: {
        constraint: form_responses_pkey
        update_columns: [form_id, actor_id, session_id, response]
      }
    ) {
      ...FormResponseFields
    }
  }
  ${FormResponseFieldsFragmentDoc}
`
export type UpsertFormResponseMutationFn = Apollo.MutationFunction<
  UpsertFormResponseMutation,
  UpsertFormResponseMutationVariables
>

/**
 * __useUpsertFormResponseMutation__
 *
 * To run a mutation, you first call `useUpsertFormResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFormResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFormResponseMutation, { data, loading, error }] = useUpsertFormResponseMutation({
 *   variables: {
 *      response: // value for 'response'
 *   },
 * });
 */
export function useUpsertFormResponseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertFormResponseMutation,
    UpsertFormResponseMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpsertFormResponseMutation,
    UpsertFormResponseMutationVariables
  >(UpsertFormResponseDocument, options)
}
export type UpsertFormResponseMutationHookResult = ReturnType<
  typeof useUpsertFormResponseMutation
>
export type UpsertFormResponseMutationResult =
  Apollo.MutationResult<UpsertFormResponseMutation>
export type UpsertFormResponseMutationOptions = Apollo.BaseMutationOptions<
  UpsertFormResponseMutation,
  UpsertFormResponseMutationVariables
>
export const GetFormsDocument = gql`
  query GetForms {
    forms {
      ...FormFields
      creator {
        id
        name
      }
    }
  }
  ${FormFieldsFragmentDoc}
`

/**
 * __useGetFormsQuery__
 *
 * To run a query within a React component, call `useGetFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFormsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFormsQuery, GetFormsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFormsQuery, GetFormsQueryVariables>(
    GetFormsDocument,
    options
  )
}
export function useGetFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormsQuery,
    GetFormsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFormsQuery, GetFormsQueryVariables>(
    GetFormsDocument,
    options
  )
}
export type GetFormsQueryHookResult = ReturnType<typeof useGetFormsQuery>
export type GetFormsLazyQueryHookResult = ReturnType<
  typeof useGetFormsLazyQuery
>
export type GetFormsQueryResult = Apollo.QueryResult<
  GetFormsQuery,
  GetFormsQueryVariables
>
export const GetGroupFormsDocument = gql`
  query GetGroupForms($groupId: String!) {
    forms(where: { group_id: { _eq: $groupId } }) {
      ...FormFields
      creator {
        id
        name
      }
    }
  }
  ${FormFieldsFragmentDoc}
`

/**
 * __useGetGroupFormsQuery__
 *
 * To run a query within a React component, call `useGetGroupFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupFormsQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetGroupFormsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetGroupFormsQuery,
    GetGroupFormsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetGroupFormsQuery, GetGroupFormsQueryVariables>(
    GetGroupFormsDocument,
    options
  )
}
export function useGetGroupFormsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupFormsQuery,
    GetGroupFormsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetGroupFormsQuery, GetGroupFormsQueryVariables>(
    GetGroupFormsDocument,
    options
  )
}
export type GetGroupFormsQueryHookResult = ReturnType<
  typeof useGetGroupFormsQuery
>
export type GetGroupFormsLazyQueryHookResult = ReturnType<
  typeof useGetGroupFormsLazyQuery
>
export type GetGroupFormsQueryResult = Apollo.QueryResult<
  GetGroupFormsQuery,
  GetGroupFormsQueryVariables
>
export const GetFormDocument = gql`
  query GetForm($id: uuid!) {
    form: forms_by_pk(id: $id) {
      ...FormFields
    }
  }
  ${FormFieldsFragmentDoc}
`

/**
 * __useGetFormQuery__
 *
 * To run a query within a React component, call `useGetFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFormQuery(
  baseOptions: Apollo.QueryHookOptions<GetFormQuery, GetFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFormQuery, GetFormQueryVariables>(
    GetFormDocument,
    options
  )
}
export function useGetFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFormQuery, GetFormQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFormQuery, GetFormQueryVariables>(
    GetFormDocument,
    options
  )
}
export type GetFormQueryHookResult = ReturnType<typeof useGetFormQuery>
export type GetFormLazyQueryHookResult = ReturnType<typeof useGetFormLazyQuery>
export type GetFormQueryResult = Apollo.QueryResult<
  GetFormQuery,
  GetFormQueryVariables
>
export const GetUserFormResponseDocument = gql`
  query GetUserFormResponse($formId: uuid!, $actorId: Int!) {
    form_responses(
      where: { form_id: { _eq: $formId }, actor_id: { _eq: $actorId } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      ...FormResponseFields
    }
  }
  ${FormResponseFieldsFragmentDoc}
`

/**
 * __useGetUserFormResponseQuery__
 *
 * To run a query within a React component, call `useGetUserFormResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFormResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFormResponseQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *      actorId: // value for 'actorId'
 *   },
 * });
 */
export function useGetUserFormResponseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserFormResponseQuery,
    GetUserFormResponseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetUserFormResponseQuery,
    GetUserFormResponseQueryVariables
  >(GetUserFormResponseDocument, options)
}
export function useGetUserFormResponseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserFormResponseQuery,
    GetUserFormResponseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetUserFormResponseQuery,
    GetUserFormResponseQueryVariables
  >(GetUserFormResponseDocument, options)
}
export type GetUserFormResponseQueryHookResult = ReturnType<
  typeof useGetUserFormResponseQuery
>
export type GetUserFormResponseLazyQueryHookResult = ReturnType<
  typeof useGetUserFormResponseLazyQuery
>
export type GetUserFormResponseQueryResult = Apollo.QueryResult<
  GetUserFormResponseQuery,
  GetUserFormResponseQueryVariables
>
export const LibraryContentDocument = gql`
  query LibraryContent(
    $libraryType: String!
    $libraryID: String!
    $seriesType: String!
    $disallowSubscriptions: Boolean_comparison_exp
  ) {
    series(
      order_by: { title: asc }
      where: {
        library_type: { _eq: $libraryType }
        library_id: { _eq: $libraryID }
        series_type: { _eq: $seriesType }
        status: { _eq: "published" }
        payments_enabled: $disallowSubscriptions
      }
    ) {
      ...SeriesWithModules
    }
  }
  ${SeriesWithModulesFragmentDoc}
`

/**
 * __useLibraryContentQuery__
 *
 * To run a query within a React component, call `useLibraryContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useLibraryContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLibraryContentQuery({
 *   variables: {
 *      libraryType: // value for 'libraryType'
 *      libraryID: // value for 'libraryID'
 *      seriesType: // value for 'seriesType'
 *      disallowSubscriptions: // value for 'disallowSubscriptions'
 *   },
 * });
 */
export function useLibraryContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    LibraryContentQuery,
    LibraryContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LibraryContentQuery, LibraryContentQueryVariables>(
    LibraryContentDocument,
    options
  )
}
export function useLibraryContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LibraryContentQuery,
    LibraryContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LibraryContentQuery, LibraryContentQueryVariables>(
    LibraryContentDocument,
    options
  )
}
export type LibraryContentQueryHookResult = ReturnType<
  typeof useLibraryContentQuery
>
export type LibraryContentLazyQueryHookResult = ReturnType<
  typeof useLibraryContentLazyQuery
>
export type LibraryContentQueryResult = Apollo.QueryResult<
  LibraryContentQuery,
  LibraryContentQueryVariables
>
export const LogLearnerSessionStartDocument = gql`
  mutation LogLearnerSessionStart($moduleID: uuid!, $actorID: Int!) {
    event: insert_learning_learner_event_log_one(
      object: { event: "start", module_id: $moduleID, actor_id: $actorID }
    ) {
      id
      time
      marks
      session {
        ...ActiveSessionWithResponses
      }
    }
  }
  ${ActiveSessionWithResponsesFragmentDoc}
`
export type LogLearnerSessionStartMutationFn = Apollo.MutationFunction<
  LogLearnerSessionStartMutation,
  LogLearnerSessionStartMutationVariables
>

/**
 * __useLogLearnerSessionStartMutation__
 *
 * To run a mutation, you first call `useLogLearnerSessionStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogLearnerSessionStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logLearnerSessionStartMutation, { data, loading, error }] = useLogLearnerSessionStartMutation({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *      actorID: // value for 'actorID'
 *   },
 * });
 */
export function useLogLearnerSessionStartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogLearnerSessionStartMutation,
    LogLearnerSessionStartMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LogLearnerSessionStartMutation,
    LogLearnerSessionStartMutationVariables
  >(LogLearnerSessionStartDocument, options)
}
export type LogLearnerSessionStartMutationHookResult = ReturnType<
  typeof useLogLearnerSessionStartMutation
>
export type LogLearnerSessionStartMutationResult =
  Apollo.MutationResult<LogLearnerSessionStartMutation>
export type LogLearnerSessionStartMutationOptions = Apollo.BaseMutationOptions<
  LogLearnerSessionStartMutation,
  LogLearnerSessionStartMutationVariables
>
export const LogLearnerSessionFinishDocument = gql`
  mutation LogLearnerSessionFinish($moduleID: uuid!, $actorID: Int!) {
    event: insert_learning_learner_event_log_one(
      object: { event: "finish", module_id: $moduleID, actor_id: $actorID }
    ) {
      id
      time
      marks
      session {
        ...FinishedSessionWithResponses
      }
    }
  }
  ${FinishedSessionWithResponsesFragmentDoc}
`
export type LogLearnerSessionFinishMutationFn = Apollo.MutationFunction<
  LogLearnerSessionFinishMutation,
  LogLearnerSessionFinishMutationVariables
>

/**
 * __useLogLearnerSessionFinishMutation__
 *
 * To run a mutation, you first call `useLogLearnerSessionFinishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogLearnerSessionFinishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logLearnerSessionFinishMutation, { data, loading, error }] = useLogLearnerSessionFinishMutation({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *      actorID: // value for 'actorID'
 *   },
 * });
 */
export function useLogLearnerSessionFinishMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogLearnerSessionFinishMutation,
    LogLearnerSessionFinishMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LogLearnerSessionFinishMutation,
    LogLearnerSessionFinishMutationVariables
  >(LogLearnerSessionFinishDocument, options)
}
export type LogLearnerSessionFinishMutationHookResult = ReturnType<
  typeof useLogLearnerSessionFinishMutation
>
export type LogLearnerSessionFinishMutationResult =
  Apollo.MutationResult<LogLearnerSessionFinishMutation>
export type LogLearnerSessionFinishMutationOptions = Apollo.BaseMutationOptions<
  LogLearnerSessionFinishMutation,
  LogLearnerSessionFinishMutationVariables
>
export const LogLearnerSessionAnswerDocument = gql`
  mutation LogLearnerSessionAnswer(
    $moduleID: uuid!
    $pageID: String!
    $questionID: uuid!
    $answers: jsonb
  ) {
    event: insert_learning_learner_event_log_one(
      object: {
        event: "answer"
        module_id: $moduleID
        page_id: $pageID
        question_id: $questionID
        answers: $answers
      }
    ) {
      id
      time
      marks
      session {
        ...ActiveSessionWithResponses
      }
    }
  }
  ${ActiveSessionWithResponsesFragmentDoc}
`
export type LogLearnerSessionAnswerMutationFn = Apollo.MutationFunction<
  LogLearnerSessionAnswerMutation,
  LogLearnerSessionAnswerMutationVariables
>

/**
 * __useLogLearnerSessionAnswerMutation__
 *
 * To run a mutation, you first call `useLogLearnerSessionAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogLearnerSessionAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logLearnerSessionAnswerMutation, { data, loading, error }] = useLogLearnerSessionAnswerMutation({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *      pageID: // value for 'pageID'
 *      questionID: // value for 'questionID'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useLogLearnerSessionAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LogLearnerSessionAnswerMutation,
    LogLearnerSessionAnswerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LogLearnerSessionAnswerMutation,
    LogLearnerSessionAnswerMutationVariables
  >(LogLearnerSessionAnswerDocument, options)
}
export type LogLearnerSessionAnswerMutationHookResult = ReturnType<
  typeof useLogLearnerSessionAnswerMutation
>
export type LogLearnerSessionAnswerMutationResult =
  Apollo.MutationResult<LogLearnerSessionAnswerMutation>
export type LogLearnerSessionAnswerMutationOptions = Apollo.BaseMutationOptions<
  LogLearnerSessionAnswerMutation,
  LogLearnerSessionAnswerMutationVariables
>
export const LearnerSessionsForModuleDocument = gql`
  query LearnerSessionsForModule($actorID: Int!, $moduleID: uuid!) {
    learning_learner_sessions(
      where: { actor_id: { _eq: $actorID }, module: { id: { _eq: $moduleID } } }
      order_by: { last_event_at: asc }
    ) {
      id
      ...FinishedSessionWithResponses
      revision {
        ...ModuleRevisionResponse
      }
      events {
        id
        time
        marks
        question_id
        event
        session {
          ...FinishedSessionWithResponses
        }
      }
    }
  }
  ${FinishedSessionWithResponsesFragmentDoc}
  ${ModuleRevisionResponseFragmentDoc}
`

/**
 * __useLearnerSessionsForModuleQuery__
 *
 * To run a query within a React component, call `useLearnerSessionsForModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearnerSessionsForModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearnerSessionsForModuleQuery({
 *   variables: {
 *      actorID: // value for 'actorID'
 *      moduleID: // value for 'moduleID'
 *   },
 * });
 */
export function useLearnerSessionsForModuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    LearnerSessionsForModuleQuery,
    LearnerSessionsForModuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LearnerSessionsForModuleQuery,
    LearnerSessionsForModuleQueryVariables
  >(LearnerSessionsForModuleDocument, options)
}
export function useLearnerSessionsForModuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LearnerSessionsForModuleQuery,
    LearnerSessionsForModuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LearnerSessionsForModuleQuery,
    LearnerSessionsForModuleQueryVariables
  >(LearnerSessionsForModuleDocument, options)
}
export type LearnerSessionsForModuleQueryHookResult = ReturnType<
  typeof useLearnerSessionsForModuleQuery
>
export type LearnerSessionsForModuleLazyQueryHookResult = ReturnType<
  typeof useLearnerSessionsForModuleLazyQuery
>
export type LearnerSessionsForModuleQueryResult = Apollo.QueryResult<
  LearnerSessionsForModuleQuery,
  LearnerSessionsForModuleQueryVariables
>
export const ActiveLearnerSessionDocument = gql`
  query ActiveLearnerSession($moduleID: uuid!, $actorID: Int!) {
    learning_learner_sessions(
      where: {
        active: { _eq: true }
        module_id: { _eq: $moduleID }
        actor_id: { _eq: $actorID }
      }
    ) {
      id
      active
    }
  }
`

/**
 * __useActiveLearnerSessionQuery__
 *
 * To run a query within a React component, call `useActiveLearnerSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveLearnerSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveLearnerSessionQuery({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *      actorID: // value for 'actorID'
 *   },
 * });
 */
export function useActiveLearnerSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveLearnerSessionQuery,
    ActiveLearnerSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ActiveLearnerSessionQuery,
    ActiveLearnerSessionQueryVariables
  >(ActiveLearnerSessionDocument, options)
}
export function useActiveLearnerSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveLearnerSessionQuery,
    ActiveLearnerSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ActiveLearnerSessionQuery,
    ActiveLearnerSessionQueryVariables
  >(ActiveLearnerSessionDocument, options)
}
export type ActiveLearnerSessionQueryHookResult = ReturnType<
  typeof useActiveLearnerSessionQuery
>
export type ActiveLearnerSessionLazyQueryHookResult = ReturnType<
  typeof useActiveLearnerSessionLazyQuery
>
export type ActiveLearnerSessionQueryResult = Apollo.QueryResult<
  ActiveLearnerSessionQuery,
  ActiveLearnerSessionQueryVariables
>
export const MyLearningDocument = gql`
  query MyLearning($userID: Int!, $limit: Int, $offset: Int) {
    authored_modules: modules(
      where: { author_id: { _eq: $userID } }
      limit: $limit
      offset: $offset
    ) {
      ...ModulePreviewResponse
    }
    in_progress: learning_learner_sessions(
      where: {
        actor_id: { _eq: $userID }
        events: { event: { _eq: "start" } }
        active: { _eq: true }
        module: { id: { _is_null: false } }
      }
      order_by: { module_id: asc, last_event_at: desc }
      limit: $limit
      offset: $offset
      distinct_on: module_id
    ) {
      id
      active
      last_event_at
      finished_at
      marks
      marks_available
      module {
        ...ModuleResponseAndCompletion
      }
      revision {
        ...ModuleRevisionResponse
      }
    }
    completed: learning_learner_sessions(
      where: {
        actor_id: { _eq: $userID }
        events: { event: { _eq: "finish" } }
        finished_at: { _is_null: false }
        module: { id: { _is_null: false } }
      }
      order_by: { module_id: asc, last_event_at: desc }
      distinct_on: module_id
      limit: $limit
      offset: $offset
    ) {
      id
      active
      last_event_at
      finished_at
      marks
      marks_available
      module {
        ...ModuleResponseAndCompletion
      }
      revision {
        ...ModuleRevisionResponse
      }
    }
    subscribed: modules(where: { has_subscription: { _eq: true } }) {
      ...ModuleResponseAndCompletion
    }
    subscribedSeries: series(where: { has_subscription: { _eq: true } }) {
      ...SeriesResponse
    }
  }
  ${ModulePreviewResponseFragmentDoc}
  ${ModuleResponseAndCompletionFragmentDoc}
  ${ModuleRevisionResponseFragmentDoc}
  ${SeriesResponseFragmentDoc}
`

/**
 * __useMyLearningQuery__
 *
 * To run a query within a React component, call `useMyLearningQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyLearningQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyLearningQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useMyLearningQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyLearningQuery,
    MyLearningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MyLearningQuery, MyLearningQueryVariables>(
    MyLearningDocument,
    options
  )
}
export function useMyLearningLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyLearningQuery,
    MyLearningQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MyLearningQuery, MyLearningQueryVariables>(
    MyLearningDocument,
    options
  )
}
export type MyLearningQueryHookResult = ReturnType<typeof useMyLearningQuery>
export type MyLearningLazyQueryHookResult = ReturnType<
  typeof useMyLearningLazyQuery
>
export type MyLearningQueryResult = Apollo.QueryResult<
  MyLearningQuery,
  MyLearningQueryVariables
>
export const LastCompletedLearnerSessionDocument = gql`
  query LastCompletedLearnerSession($moduleID: uuid!, $actorID: Int!) {
    learning_learner_sessions(
      limit: 1
      order_by: { finished_at: desc }
      where: {
        module_id: { _eq: $moduleID }
        actor_id: { _eq: $actorID }
        events: { event: { _eq: "finish" } }
      }
    ) {
      id
      finished_at
      active
      marks
      marks_available
      revision {
        ...ModuleRevisionResponse
      }
      status
      responses {
        answers
        feedback
        module_id
        question_id
      }
    }
  }
  ${ModuleRevisionResponseFragmentDoc}
`

/**
 * __useLastCompletedLearnerSessionQuery__
 *
 * To run a query within a React component, call `useLastCompletedLearnerSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastCompletedLearnerSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastCompletedLearnerSessionQuery({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *      actorID: // value for 'actorID'
 *   },
 * });
 */
export function useLastCompletedLearnerSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    LastCompletedLearnerSessionQuery,
    LastCompletedLearnerSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LastCompletedLearnerSessionQuery,
    LastCompletedLearnerSessionQueryVariables
  >(LastCompletedLearnerSessionDocument, options)
}
export function useLastCompletedLearnerSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LastCompletedLearnerSessionQuery,
    LastCompletedLearnerSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LastCompletedLearnerSessionQuery,
    LastCompletedLearnerSessionQueryVariables
  >(LastCompletedLearnerSessionDocument, options)
}
export type LastCompletedLearnerSessionQueryHookResult = ReturnType<
  typeof useLastCompletedLearnerSessionQuery
>
export type LastCompletedLearnerSessionLazyQueryHookResult = ReturnType<
  typeof useLastCompletedLearnerSessionLazyQuery
>
export type LastCompletedLearnerSessionQueryResult = Apollo.QueryResult<
  LastCompletedLearnerSessionQuery,
  LastCompletedLearnerSessionQueryVariables
>
export const LastCompletedLearnerSessionForSeriesDocument = gql`
  query LastCompletedLearnerSessionForSeries($seriesID: uuid!, $actorID: Int!) {
    learning_learner_sessions(
      limit: 1
      order_by: { finished_at: desc }
      where: {
        module: { series_id: { _eq: $seriesID } }
        actor_id: { _eq: $actorID }
        events: { event: { _eq: "finish" } }
      }
    ) {
      id
      finished_at
      active
      marks
      marks_available
      revision {
        ...ModuleRevisionResponse
      }
      status
      responses {
        answers
        feedback
        module_id
        question_id
      }
    }
  }
  ${ModuleRevisionResponseFragmentDoc}
`

/**
 * __useLastCompletedLearnerSessionForSeriesQuery__
 *
 * To run a query within a React component, call `useLastCompletedLearnerSessionForSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastCompletedLearnerSessionForSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastCompletedLearnerSessionForSeriesQuery({
 *   variables: {
 *      seriesID: // value for 'seriesID'
 *      actorID: // value for 'actorID'
 *   },
 * });
 */
export function useLastCompletedLearnerSessionForSeriesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LastCompletedLearnerSessionForSeriesQuery,
    LastCompletedLearnerSessionForSeriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LastCompletedLearnerSessionForSeriesQuery,
    LastCompletedLearnerSessionForSeriesQueryVariables
  >(LastCompletedLearnerSessionForSeriesDocument, options)
}
export function useLastCompletedLearnerSessionForSeriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LastCompletedLearnerSessionForSeriesQuery,
    LastCompletedLearnerSessionForSeriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LastCompletedLearnerSessionForSeriesQuery,
    LastCompletedLearnerSessionForSeriesQueryVariables
  >(LastCompletedLearnerSessionForSeriesDocument, options)
}
export type LastCompletedLearnerSessionForSeriesQueryHookResult = ReturnType<
  typeof useLastCompletedLearnerSessionForSeriesQuery
>
export type LastCompletedLearnerSessionForSeriesLazyQueryHookResult =
  ReturnType<typeof useLastCompletedLearnerSessionForSeriesLazyQuery>
export type LastCompletedLearnerSessionForSeriesQueryResult =
  Apollo.QueryResult<
    LastCompletedLearnerSessionForSeriesQuery,
    LastCompletedLearnerSessionForSeriesQueryVariables
  >
export const LearnerModuleReflectionsDocument = gql`
  query LearnerModuleReflections($moduleID: uuid!, $actorID: Int!) {
    reflections: learning_learner_reflections_by_pk(
      module_id: $moduleID
      user_id: $actorID
    ) {
      body
    }
  }
`

/**
 * __useLearnerModuleReflectionsQuery__
 *
 * To run a query within a React component, call `useLearnerModuleReflectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearnerModuleReflectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearnerModuleReflectionsQuery({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *      actorID: // value for 'actorID'
 *   },
 * });
 */
export function useLearnerModuleReflectionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LearnerModuleReflectionsQuery,
    LearnerModuleReflectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LearnerModuleReflectionsQuery,
    LearnerModuleReflectionsQueryVariables
  >(LearnerModuleReflectionsDocument, options)
}
export function useLearnerModuleReflectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LearnerModuleReflectionsQuery,
    LearnerModuleReflectionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LearnerModuleReflectionsQuery,
    LearnerModuleReflectionsQueryVariables
  >(LearnerModuleReflectionsDocument, options)
}
export type LearnerModuleReflectionsQueryHookResult = ReturnType<
  typeof useLearnerModuleReflectionsQuery
>
export type LearnerModuleReflectionsLazyQueryHookResult = ReturnType<
  typeof useLearnerModuleReflectionsLazyQuery
>
export type LearnerModuleReflectionsQueryResult = Apollo.QueryResult<
  LearnerModuleReflectionsQuery,
  LearnerModuleReflectionsQueryVariables
>
export const UpdateLearnerModuleReflectionsDocument = gql`
  mutation UpdateLearnerModuleReflections(
    $moduleID: uuid!
    $actorID: Int!
    $body: String!
  ) {
    reflections: insert_learning_learner_reflections_one(
      object: { body: $body, user_id: $actorID, module_id: $moduleID }
      on_conflict: {
        constraint: learning_learner_reflections_pkey
        update_columns: body
      }
    ) {
      body
    }
  }
`
export type UpdateLearnerModuleReflectionsMutationFn = Apollo.MutationFunction<
  UpdateLearnerModuleReflectionsMutation,
  UpdateLearnerModuleReflectionsMutationVariables
>

/**
 * __useUpdateLearnerModuleReflectionsMutation__
 *
 * To run a mutation, you first call `useUpdateLearnerModuleReflectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearnerModuleReflectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearnerModuleReflectionsMutation, { data, loading, error }] = useUpdateLearnerModuleReflectionsMutation({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *      actorID: // value for 'actorID'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateLearnerModuleReflectionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLearnerModuleReflectionsMutation,
    UpdateLearnerModuleReflectionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLearnerModuleReflectionsMutation,
    UpdateLearnerModuleReflectionsMutationVariables
  >(UpdateLearnerModuleReflectionsDocument, options)
}
export type UpdateLearnerModuleReflectionsMutationHookResult = ReturnType<
  typeof useUpdateLearnerModuleReflectionsMutation
>
export type UpdateLearnerModuleReflectionsMutationResult =
  Apollo.MutationResult<UpdateLearnerModuleReflectionsMutation>
export type UpdateLearnerModuleReflectionsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLearnerModuleReflectionsMutation,
    UpdateLearnerModuleReflectionsMutationVariables
  >
export const LearnerModuleRatingQuestionsDocument = gql`
  query LearnerModuleRatingQuestions($moduleID: uuid!, $seriesID: uuid!) {
    module_questions: learning_learner_module_rating_questions(
      where: { module_id: { _eq: $moduleID } }
      order_by: { position: asc }
    ) {
      ...LearnerRatingQuestions
    }
    series_questions: learning_learner_module_rating_questions(
      where: { series_id: { _eq: $seriesID } }
      order_by: { position: asc }
    ) {
      ...LearnerRatingQuestions
    }
    standard_questions: learning_learner_module_rating_questions(
      order_by: { position: asc }
    ) {
      ...LearnerRatingQuestions
    }
  }
  ${LearnerRatingQuestionsFragmentDoc}
`

/**
 * __useLearnerModuleRatingQuestionsQuery__
 *
 * To run a query within a React component, call `useLearnerModuleRatingQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearnerModuleRatingQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearnerModuleRatingQuestionsQuery({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *      seriesID: // value for 'seriesID'
 *   },
 * });
 */
export function useLearnerModuleRatingQuestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LearnerModuleRatingQuestionsQuery,
    LearnerModuleRatingQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LearnerModuleRatingQuestionsQuery,
    LearnerModuleRatingQuestionsQueryVariables
  >(LearnerModuleRatingQuestionsDocument, options)
}
export function useLearnerModuleRatingQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LearnerModuleRatingQuestionsQuery,
    LearnerModuleRatingQuestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LearnerModuleRatingQuestionsQuery,
    LearnerModuleRatingQuestionsQueryVariables
  >(LearnerModuleRatingQuestionsDocument, options)
}
export type LearnerModuleRatingQuestionsQueryHookResult = ReturnType<
  typeof useLearnerModuleRatingQuestionsQuery
>
export type LearnerModuleRatingQuestionsLazyQueryHookResult = ReturnType<
  typeof useLearnerModuleRatingQuestionsLazyQuery
>
export type LearnerModuleRatingQuestionsQueryResult = Apollo.QueryResult<
  LearnerModuleRatingQuestionsQuery,
  LearnerModuleRatingQuestionsQueryVariables
>
export const LearnerModuleRatingResponsesDocument = gql`
  query LearnerModuleRatingResponses(
    $userID: Int!
    $moduleID: uuid!
    $sessionID: uuid!
  ) {
    learning_learner_module_rating_responses(
      where: {
        rating: {
          user_id: { _eq: $userID }
          module_id: { _eq: $moduleID }
          session_id: { _eq: $sessionID }
        }
      }
    ) {
      id
      question_id
      response_text
      response
    }
  }
`

/**
 * __useLearnerModuleRatingResponsesQuery__
 *
 * To run a query within a React component, call `useLearnerModuleRatingResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLearnerModuleRatingResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLearnerModuleRatingResponsesQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      moduleID: // value for 'moduleID'
 *      sessionID: // value for 'sessionID'
 *   },
 * });
 */
export function useLearnerModuleRatingResponsesQuery(
  baseOptions: Apollo.QueryHookOptions<
    LearnerModuleRatingResponsesQuery,
    LearnerModuleRatingResponsesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    LearnerModuleRatingResponsesQuery,
    LearnerModuleRatingResponsesQueryVariables
  >(LearnerModuleRatingResponsesDocument, options)
}
export function useLearnerModuleRatingResponsesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LearnerModuleRatingResponsesQuery,
    LearnerModuleRatingResponsesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    LearnerModuleRatingResponsesQuery,
    LearnerModuleRatingResponsesQueryVariables
  >(LearnerModuleRatingResponsesDocument, options)
}
export type LearnerModuleRatingResponsesQueryHookResult = ReturnType<
  typeof useLearnerModuleRatingResponsesQuery
>
export type LearnerModuleRatingResponsesLazyQueryHookResult = ReturnType<
  typeof useLearnerModuleRatingResponsesLazyQuery
>
export type LearnerModuleRatingResponsesQueryResult = Apollo.QueryResult<
  LearnerModuleRatingResponsesQuery,
  LearnerModuleRatingResponsesQueryVariables
>
export const UpdateLearnerModuleRatingsDocument = gql`
  mutation UpdateLearnerModuleRatings(
    $sessionID: uuid!
    $moduleID: uuid!
    $actorID: Int!
    $responses: [learning_learner_module_rating_responses_insert_input!]!
  ) {
    rating: insert_learning_learner_module_ratings_one(
      object: {
        session_id: $sessionID
        module_id: $moduleID
        user_id: $actorID
        responses: { data: $responses }
      }
    ) {
      id
    }
  }
`
export type UpdateLearnerModuleRatingsMutationFn = Apollo.MutationFunction<
  UpdateLearnerModuleRatingsMutation,
  UpdateLearnerModuleRatingsMutationVariables
>

/**
 * __useUpdateLearnerModuleRatingsMutation__
 *
 * To run a mutation, you first call `useUpdateLearnerModuleRatingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearnerModuleRatingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearnerModuleRatingsMutation, { data, loading, error }] = useUpdateLearnerModuleRatingsMutation({
 *   variables: {
 *      sessionID: // value for 'sessionID'
 *      moduleID: // value for 'moduleID'
 *      actorID: // value for 'actorID'
 *      responses: // value for 'responses'
 *   },
 * });
 */
export function useUpdateLearnerModuleRatingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLearnerModuleRatingsMutation,
    UpdateLearnerModuleRatingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLearnerModuleRatingsMutation,
    UpdateLearnerModuleRatingsMutationVariables
  >(UpdateLearnerModuleRatingsDocument, options)
}
export type UpdateLearnerModuleRatingsMutationHookResult = ReturnType<
  typeof useUpdateLearnerModuleRatingsMutation
>
export type UpdateLearnerModuleRatingsMutationResult =
  Apollo.MutationResult<UpdateLearnerModuleRatingsMutation>
export type UpdateLearnerModuleRatingsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLearnerModuleRatingsMutation,
    UpdateLearnerModuleRatingsMutationVariables
  >
export const UpdateLearnerModuleStarRatingDocument = gql`
  mutation UpdateLearnerModuleStarRating($moduleID: uuid!, $rating: Int!) {
    rating: insert_learning_learner_module_star_ratings_one(
      object: { module_id: $moduleID, rating: $rating }
    ) {
      id
      rating
    }
  }
`
export type UpdateLearnerModuleStarRatingMutationFn = Apollo.MutationFunction<
  UpdateLearnerModuleStarRatingMutation,
  UpdateLearnerModuleStarRatingMutationVariables
>

/**
 * __useUpdateLearnerModuleStarRatingMutation__
 *
 * To run a mutation, you first call `useUpdateLearnerModuleStarRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearnerModuleStarRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearnerModuleStarRatingMutation, { data, loading, error }] = useUpdateLearnerModuleStarRatingMutation({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useUpdateLearnerModuleStarRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLearnerModuleStarRatingMutation,
    UpdateLearnerModuleStarRatingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateLearnerModuleStarRatingMutation,
    UpdateLearnerModuleStarRatingMutationVariables
  >(UpdateLearnerModuleStarRatingDocument, options)
}
export type UpdateLearnerModuleStarRatingMutationHookResult = ReturnType<
  typeof useUpdateLearnerModuleStarRatingMutation
>
export type UpdateLearnerModuleStarRatingMutationResult =
  Apollo.MutationResult<UpdateLearnerModuleStarRatingMutation>
export type UpdateLearnerModuleStarRatingMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLearnerModuleStarRatingMutation,
    UpdateLearnerModuleStarRatingMutationVariables
  >
export const ModulesListForSeriesCompletionDocument = gql`
  query ModulesListForSeriesCompletion($seriesID: uuid!) {
    modules: modules(
      where: {
        series_id: { _eq: $seriesID }
        status: { _eq: "published" }
        published_revision_id: { _is_null: false }
      }
      order_by: { position: asc }
    ) {
      id
      slug
      position
      ever_completed
      has_passed
      series {
        library {
          slug
        }
        slug
      }
      published_revision {
        title
      }
    }
  }
`

/**
 * __useModulesListForSeriesCompletionQuery__
 *
 * To run a query within a React component, call `useModulesListForSeriesCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useModulesListForSeriesCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModulesListForSeriesCompletionQuery({
 *   variables: {
 *      seriesID: // value for 'seriesID'
 *   },
 * });
 */
export function useModulesListForSeriesCompletionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModulesListForSeriesCompletionQuery,
    ModulesListForSeriesCompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ModulesListForSeriesCompletionQuery,
    ModulesListForSeriesCompletionQueryVariables
  >(ModulesListForSeriesCompletionDocument, options)
}
export function useModulesListForSeriesCompletionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModulesListForSeriesCompletionQuery,
    ModulesListForSeriesCompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ModulesListForSeriesCompletionQuery,
    ModulesListForSeriesCompletionQueryVariables
  >(ModulesListForSeriesCompletionDocument, options)
}
export type ModulesListForSeriesCompletionQueryHookResult = ReturnType<
  typeof useModulesListForSeriesCompletionQuery
>
export type ModulesListForSeriesCompletionLazyQueryHookResult = ReturnType<
  typeof useModulesListForSeriesCompletionLazyQuery
>
export type ModulesListForSeriesCompletionQueryResult = Apollo.QueryResult<
  ModulesListForSeriesCompletionQuery,
  ModulesListForSeriesCompletionQueryVariables
>
export const ModulesListForSeriesPassDocument = gql`
  query ModulesListForSeriesPass($seriesID: uuid!) {
    modules: modules(
      where: {
        series_id: { _eq: $seriesID }
        status: { _eq: "published" }
        published_revision_id: { _is_null: false }
      }
      order_by: { position: asc }
    ) {
      id
      position
      has_passed
    }
  }
`

/**
 * __useModulesListForSeriesPassQuery__
 *
 * To run a query within a React component, call `useModulesListForSeriesPassQuery` and pass it any options that fit your needs.
 * When your component renders, `useModulesListForSeriesPassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModulesListForSeriesPassQuery({
 *   variables: {
 *      seriesID: // value for 'seriesID'
 *   },
 * });
 */
export function useModulesListForSeriesPassQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModulesListForSeriesPassQuery,
    ModulesListForSeriesPassQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ModulesListForSeriesPassQuery,
    ModulesListForSeriesPassQueryVariables
  >(ModulesListForSeriesPassDocument, options)
}
export function useModulesListForSeriesPassLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModulesListForSeriesPassQuery,
    ModulesListForSeriesPassQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ModulesListForSeriesPassQuery,
    ModulesListForSeriesPassQueryVariables
  >(ModulesListForSeriesPassDocument, options)
}
export type ModulesListForSeriesPassQueryHookResult = ReturnType<
  typeof useModulesListForSeriesPassQuery
>
export type ModulesListForSeriesPassLazyQueryHookResult = ReturnType<
  typeof useModulesListForSeriesPassLazyQuery
>
export type ModulesListForSeriesPassQueryResult = Apollo.QueryResult<
  ModulesListForSeriesPassQuery,
  ModulesListForSeriesPassQueryVariables
>
export const AuthorsForFilterDocument = gql`
  query AuthorsForFilter {
    modules(where: { published_revision_id: { _is_null: false } }) {
      published_revision {
        cover_author
      }
    }
    series(where: { display_author: { _nin: ["null", ""] } }) {
      display_author
    }
  }
`

/**
 * __useAuthorsForFilterQuery__
 *
 * To run a query within a React component, call `useAuthorsForFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorsForFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorsForFilterQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthorsForFilterQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthorsForFilterQuery,
    AuthorsForFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AuthorsForFilterQuery, AuthorsForFilterQueryVariables>(
    AuthorsForFilterDocument,
    options
  )
}
export function useAuthorsForFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthorsForFilterQuery,
    AuthorsForFilterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AuthorsForFilterQuery,
    AuthorsForFilterQueryVariables
  >(AuthorsForFilterDocument, options)
}
export type AuthorsForFilterQueryHookResult = ReturnType<
  typeof useAuthorsForFilterQuery
>
export type AuthorsForFilterLazyQueryHookResult = ReturnType<
  typeof useAuthorsForFilterLazyQuery
>
export type AuthorsForFilterQueryResult = Apollo.QueryResult<
  AuthorsForFilterQuery,
  AuthorsForFilterQueryVariables
>
export const PagesBySlugDocument = gql`
  query PagesBySlug($slug: String!) {
    results: pages(limit: 1, where: { slug: { _eq: $slug } }) {
      ...PageBySlug
    }
  }
  ${PageBySlugFragmentDoc}
`

/**
 * __usePagesBySlugQuery__
 *
 * To run a query within a React component, call `usePagesBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePagesBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    PagesBySlugQuery,
    PagesBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PagesBySlugQuery, PagesBySlugQueryVariables>(
    PagesBySlugDocument,
    options
  )
}
export function usePagesBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PagesBySlugQuery,
    PagesBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PagesBySlugQuery, PagesBySlugQueryVariables>(
    PagesBySlugDocument,
    options
  )
}
export type PagesBySlugQueryHookResult = ReturnType<typeof usePagesBySlugQuery>
export type PagesBySlugLazyQueryHookResult = ReturnType<
  typeof usePagesBySlugLazyQuery
>
export type PagesBySlugQueryResult = Apollo.QueryResult<
  PagesBySlugQuery,
  PagesBySlugQueryVariables
>
export const PublicPostsDocument = gql`
  query PublicPosts($limit: Int, $offset: Int) {
    count: posts_aggregate {
      aggregate {
        count
      }
    }
    posts(
      where: { published: { _eq: true } }
      limit: $limit
      offset: $offset
      order_by: { date: desc }
    ) {
      ...PublicPostEntry
    }
  }
  ${PublicPostEntryFragmentDoc}
`

/**
 * __usePublicPostsQuery__
 *
 * To run a query within a React component, call `usePublicPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicPostsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePublicPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PublicPostsQuery,
    PublicPostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PublicPostsQuery, PublicPostsQueryVariables>(
    PublicPostsDocument,
    options
  )
}
export function usePublicPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicPostsQuery,
    PublicPostsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PublicPostsQuery, PublicPostsQueryVariables>(
    PublicPostsDocument,
    options
  )
}
export type PublicPostsQueryHookResult = ReturnType<typeof usePublicPostsQuery>
export type PublicPostsLazyQueryHookResult = ReturnType<
  typeof usePublicPostsLazyQuery
>
export type PublicPostsQueryResult = Apollo.QueryResult<
  PublicPostsQuery,
  PublicPostsQueryVariables
>
export const PublicPostsWithCategoryDocument = gql`
  query PublicPostsWithCategory(
    $limit: Int
    $categorySlug: String!
    $categoryType: String!
  ) {
    categories(
      where: { domain: { _eq: $categoryType }, slug: { _eq: $categorySlug } }
    ) {
      ...PublicPostCategory
    }
    posts(
      limit: $limit
      order_by: { date: desc }
      where: {
        published: { _eq: true }
        categories: {
          category: {
            domain: { _eq: $categoryType }
            slug: { _eq: $categorySlug }
          }
        }
      }
    ) {
      ...PublicPostEntry
    }
  }
  ${PublicPostCategoryFragmentDoc}
  ${PublicPostEntryFragmentDoc}
`

/**
 * __usePublicPostsWithCategoryQuery__
 *
 * To run a query within a React component, call `usePublicPostsWithCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicPostsWithCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicPostsWithCategoryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      categorySlug: // value for 'categorySlug'
 *      categoryType: // value for 'categoryType'
 *   },
 * });
 */
export function usePublicPostsWithCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicPostsWithCategoryQuery,
    PublicPostsWithCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PublicPostsWithCategoryQuery,
    PublicPostsWithCategoryQueryVariables
  >(PublicPostsWithCategoryDocument, options)
}
export function usePublicPostsWithCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicPostsWithCategoryQuery,
    PublicPostsWithCategoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PublicPostsWithCategoryQuery,
    PublicPostsWithCategoryQueryVariables
  >(PublicPostsWithCategoryDocument, options)
}
export type PublicPostsWithCategoryQueryHookResult = ReturnType<
  typeof usePublicPostsWithCategoryQuery
>
export type PublicPostsWithCategoryLazyQueryHookResult = ReturnType<
  typeof usePublicPostsWithCategoryLazyQuery
>
export type PublicPostsWithCategoryQueryResult = Apollo.QueryResult<
  PublicPostsWithCategoryQuery,
  PublicPostsWithCategoryQueryVariables
>
export const PostDocument = gql`
  query Post($id: Int!) {
    result: posts_by_pk(id: $id) {
      ...PostResponse
    }
  }
  ${PostResponseFragmentDoc}
`

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostQuery(
  baseOptions: Apollo.QueryHookOptions<PostQuery, PostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PostQuery, PostQueryVariables>(PostDocument, options)
}
export function usePostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PostQuery, PostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PostQuery, PostQueryVariables>(
    PostDocument,
    options
  )
}
export type PostQueryHookResult = ReturnType<typeof usePostQuery>
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>
export type PostQueryResult = Apollo.QueryResult<PostQuery, PostQueryVariables>
export const PostsBySlugDocument = gql`
  query PostsBySlug($slug: String!) {
    results: posts(limit: 1, where: { slug: { _eq: $slug } }) {
      ...PostResponse
    }
  }
  ${PostResponseFragmentDoc}
`

/**
 * __usePostsBySlugQuery__
 *
 * To run a query within a React component, call `usePostsBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePostsBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    PostsBySlugQuery,
    PostsBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PostsBySlugQuery, PostsBySlugQueryVariables>(
    PostsBySlugDocument,
    options
  )
}
export function usePostsBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PostsBySlugQuery,
    PostsBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PostsBySlugQuery, PostsBySlugQueryVariables>(
    PostsBySlugDocument,
    options
  )
}
export type PostsBySlugQueryHookResult = ReturnType<typeof usePostsBySlugQuery>
export type PostsBySlugLazyQueryHookResult = ReturnType<
  typeof usePostsBySlugLazyQuery
>
export type PostsBySlugQueryResult = Apollo.QueryResult<
  PostsBySlugQuery,
  PostsBySlugQueryVariables
>
export const AddPostCommentDocument = gql`
  mutation AddPostComment(
    $user_values: users_insert_input!
    $values: post_comments_insert_input!
  ) {
    insert_users(
      objects: [$user_values]
      on_conflict: { constraint: users_pkey, update_columns: [name] }
    ) {
      returning {
        id
        name
        first_seen_at
        last_seen_at
      }
    }
    insert_post_comments(objects: [$values]) {
      returning {
        id
        body
        created_at
        post {
          ...PostResponse
        }
      }
    }
  }
  ${PostResponseFragmentDoc}
`
export type AddPostCommentMutationFn = Apollo.MutationFunction<
  AddPostCommentMutation,
  AddPostCommentMutationVariables
>

/**
 * __useAddPostCommentMutation__
 *
 * To run a mutation, you first call `useAddPostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostCommentMutation, { data, loading, error }] = useAddPostCommentMutation({
 *   variables: {
 *      user_values: // value for 'user_values'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useAddPostCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPostCommentMutation,
    AddPostCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddPostCommentMutation,
    AddPostCommentMutationVariables
  >(AddPostCommentDocument, options)
}
export type AddPostCommentMutationHookResult = ReturnType<
  typeof useAddPostCommentMutation
>
export type AddPostCommentMutationResult =
  Apollo.MutationResult<AddPostCommentMutation>
export type AddPostCommentMutationOptions = Apollo.BaseMutationOptions<
  AddPostCommentMutation,
  AddPostCommentMutationVariables
>
